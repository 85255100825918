import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {Autocomplete, Box, CircularProgress, TextField} from '@mui/material';
import RTKQueryFeedback from '../RTKQueryFeedback';
import {useGetAzureUsersMutation} from '../../../api';


const AzureUsersSearch = forwardRef(({value, onSelectUser}, ref) => {
  const [getUsersByPattern, {data = [], error, isLoading}] = useGetAzureUsersMutation();

  const searchHandler = useDebouncedCallback((pattern) => {
    if (pattern.length >= 3) {
      getUsersByPattern(pattern);
    }
  }, 750);

  return (
    <Box>
      <Autocomplete
        ref={ref}
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        options={data}
        isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
        renderTags={() => null}
        getOptionLabel={(opt) => `${opt.name} (${opt.employeeId})`}
        onInputChange={(_, pattern) => searchHandler(pattern)}
        onChange={(_, user) => onSelectUser(user)}
        filterOptions={(opts, {inputValue = ''}) => {
          return opts.filter((o) => `${o.employeeId} ${o.name}`.toLowerCase().includes(inputValue.toLowerCase()));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            label={t`Employees`}
            placeholder={t`Start typing...`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <RTKQueryFeedback error={error} />
    </Box>
  );
});

AzureUsersSearch.propTypes = {
  onSelectUser: PropTypes.func.isRequired,
  value: PropTypes.object,
};

AzureUsersSearch.defaultProps = {
  value: null
};

export default AzureUsersSearch;
