import {useGetRotationsQuery} from '../../../api';
import {Box} from '@mui/material';
import RotationCard from '../rotation-card/RotationCard';
import RTKQueryFeedback from '../../common/RTKQueryFeedback';
import {useStore} from '../../../store/valtio';
import {determineRotationStates} from '../../../utils/rotation/rotation-utils';

export const RotationList = () => {
  const {rotations: {filters: {airport, pattern, state, operationDay}}} = useStore();
  const {data: rotations = [], error, isFetching} = useGetRotationsQuery({iataCode: airport?.iataCode});

  /** @type {(open: Rotation) => boolean} */
  const rotationsFilter = (rotation) => {
    /** @type RotationState[] */
    const rotationStates = determineRotationStates(rotation);
    const haystack = `${rotation.aircraft.tail}${rotation.inbound?.flightNumber || ''}${rotation.outbound?.flightNumber || ''}`.toLowerCase();
    return (!pattern || haystack.includes(pattern.toLowerCase())) && (!state || rotationStates.includes(state));
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      {rotations.filter(r => r.operationDay === operationDay).filter(rotationsFilter).map((rotation, idx) => (
        <RotationCard key={idx} rotation={rotation} />
      ))}
      <RTKQueryFeedback error={error} isLoading={isFetching} />
    </Box>
  );
};

export default RotationList;
