import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import {useAddOptionalActionMutation, useGetOptionalActionsQuery, useGetRecordsQuery} from '../../../../../api';
import TurnaroundRecordsControl from './resources/turnaround-records-control/TurnaroundRecordsControl';
import TurnaroundTasksSkeleton from './resources/turnaround-records-list/TurnaroundTasksSkeleton';
import TurnaroundRecordsList from './resources/turnaround-records-list/TurnaroundRecordsList';
import {FLIGHT_SHAPE, ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
import TurnaroundSummary from './resources/turnaround-summary/TurnaroundSummary';
import {AuthorizationGuard} from '../../../../../pages/common/guards';
import RTKQueryFeedback from '../../../../common/RTKQueryFeedback';
import {ROLES} from '../../../../../utils/user/user-utils';
import {useMemo, useState} from 'react';

export const RotationViewTurnaround = ({rotation}) => {
  const {hash: rotationHash} = rotation;
  const [selectMode, setSelectMode] = useState(false);
  const {data: tasks = [], error, isLoading} = useGetRecordsQuery(rotationHash);
  const {data: optionalActions = []} = useGetOptionalActionsQuery(rotationHash);
  const [onAddOptionalAction, addOptionalAction] = useAddOptionalActionMutation();
  const filteredOptionalActions = useMemo(() => {
    return optionalActions.filter((optional) => !tasks?.records?.filter((e) => e.action.manuallyAdded).some((record) => record.action.description === optional.description))
  }, [tasks, optionalActions]);

  const handleOnAddOptionalAction = (actionId) => {
    const action = optionalActions.filter((action) => action.id === actionId);
    onAddOptionalAction({rotationHash, action: action[0]});
  };

  return (
    <Stack direction={'column'} spacing={2}>
      <TurnaroundSummary rotation={rotation} />
      {isLoading ? (
        <TurnaroundTasksSkeleton />
      ) : (
        <TurnaroundRecordsList rotationHash={rotation.hash} records={tasks?.records} selectMode={selectMode} setSelectMode={setSelectMode}/>
      )}
      {!isLoading && !selectMode && (
        <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
          <TurnaroundRecordsControl
            optionalActions={filteredOptionalActions}
            onAddOptionalAction={handleOnAddOptionalAction}
          />
        </AuthorizationGuard>
      )}
      <RTKQueryFeedback error={error || addOptionalAction.error} />
    </Stack>
  );
};

RotationViewTurnaround.propTypes = {
  rotation: PropTypes.shape({
    inbound: FLIGHT_SHAPE,
    outbound: FLIGHT_SHAPE
  })
};

RotationViewTurnaround.defaultProps = {
  rotation: ROTATION_SHAPE.isRequired
};

export default RotationViewTurnaround;
