import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {forwardRef, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import {Autocomplete, Box, Chip, CircularProgress, TextField} from '@mui/material';
import RTKQueryFeedback from '../RTKQueryFeedback';
import {useGetAllUsersQuery} from '../../../api';


export const UsersSearch = forwardRef(({ value, onChange }, ref) => {
  const [pattern, setPattern] = useState('');
  const {data = [], error, isLoading} = useGetAllUsersQuery({pattern});
  const [input, setInput] = useState('');

  const onDelete = (employeeId) => {
    onChange(value.filter((v) => v.employeeId !== employeeId));
  };

  const searchHandler = useDebouncedCallback((inputPattern) => {
    if (inputPattern.length >= 3) {
      setPattern(inputPattern);
    }
  }, 750);

  const handleInputChange = (_, pattern) => {
    setInput(pattern);
    searchHandler(pattern);
  };

  return (
    <Box>
      <RTKQueryFeedback error={error} />
      <Autocomplete
        ref={ref}
        multiple
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={data}
        inputValue={input}
        renderTags={() => null}
        value={value}
        isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
        getOptionLabel={(opt) => `${opt.name} (${opt.employeeId})`}
        filterOptions={(opts, {inputValue= ''}) => {
          return opts.filter(o => `${o.employeeId} ${o.name}`.toLowerCase().includes(inputValue.toLowerCase()));
        }}
        onInputChange={handleInputChange}
        onChange={(_, users) => onChange(users)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Employees`}
            placeholder={t`Start typing...`}
            onKeyDown={e => e.key === 'Backspace' && e.stopPropagation()}
            variant={'outlined'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <Box>
        {value.map((v, idx) => (
          <Chip key={idx} label={`${v.name} (${v.employeeId})`} sx={{mt: 1, mr: 1}} onDelete={() => onDelete(v.employeeId)} />
        ))}
      </Box>
    </Box>
  );
});

UsersSearch.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
};

UsersSearch.defaultProps = {
  value: [],
  onChange: () => null
};

export default UsersSearch;
