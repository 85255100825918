import React from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
import {Grid, TextField} from '@mui/material';
import {t} from 'ttag';
import {UsersSearch} from '../../../../components/common/users-search/UsersSearch';

const RoleForm = () => {
  const {control} = useFormContext();

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <Controller
          name={'name'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Name`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
              required
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'description'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Description`}
              color={'primary'}
              variant={'outlined'}
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <Controller
          name={'users'}
          control={control}
          defaultValue={[]}
          render={({field, fieldState: {error}}) => <UsersSearch {...field} />}
        />
      </Grid>
    </Grid>
  );
};

RoleForm.propTypes = {
  formik: PropTypes.object
};

RoleForm.defaultProps = {
  formik: {}
};

export default RoleForm;
