import {DateTime, Duration, Interval} from 'luxon';
import store from '../../store/valtio';
import {snapshot} from 'valtio';

/**
 * @typedef DurationObject
 * @property {number} [years]
 * @property {number} [quarters]
 * @property {number} [months]
 * @property {number} [weeks]
 * @property {number} [days]
 * @property {number} [hours]
 * @property {number} [minutes]
 * @property {number} [seconds]
 * @property {number} [milliseconds]
 */

/** @type {string} - Datetime format */
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
/** @type {string} - Datetime format */
export const DATETIME_FORMAT_NO_SEC = 'yyyy-MM-dd HH:mm';
/** @type {string} - Date format */
export const DATE_FORMAT = 'yyyy-MM-dd';
/** @type {string} - Time format */
export const TIME_FORMAT = 'HH:mm:ss';
/** @type {string} - Time format without seconds */
export const TIME_FORMAT_NO_SEC = 'HH:mm';
/** @type {string} - Duration format */
export const DURATION_FORMAT = 'hh:mm:ss';
/** @type {string} - Duration format without seconds */
export const DURATION_FORMAT_NO_SEC = 'hh:mm';

/**
 * Formats provided datetime as time
 * @param {LocalDatetime} datetime - Ex. '2021-10-03 12:00:00'
 * @param {boolean} [noSeconds]
 * @return {LocalTime | null} - Ex. '12:00:00' or '12:00'
 */
export const formatTime = (datetime, noSeconds = false) => {
  return datetime?.substring(11, noSeconds ? 16 : 19);
}

/**
 * Formats provided datetime as date
 * @param {LocalDatetime} datetime - Ex. '2021-10-03 12:00:00'
 * @return {LocalDate | null} - Ex. '2021-10-03'
 */
export const formatDate = (datetime) => {
  return datetime?.substring(0, 10);
}

/**
 * Formats provided duration as duration with no seconds
 * @param {LocalDuration} duration - Ex. '27:62:120'
 * @return {LocalDuration | null} - Ex. '27:64'
 */
export const formatDurationWithNoSeconds = (duration) => {
  return createDuration(duration).toFormat(DURATION_FORMAT_NO_SEC);
}

/**
 * Current timezone (Ex. 'Atlantic/Azores')
 * @type {function: string}
 */
export const GET_CURRENT_TZ = () => snapshot(store.rotations.filters.airport)?.timezoneId || 'UTC';

/**
 * Sanitizes the provided datetime
 * @param {string} datetime - Ex. '2021-10-03T12:00:00+00:00'
 * @return {string} - Ex. '2021-10-03 12:00:00'
 */
export const sanitizeDatetime = (datetime) => {
  if (typeof datetime !== 'string') throw new Error('sanitizeDatetime: provided datetime should be of type string.');
  return datetime.toUpperCase().substring(0, 19).replace('T', ' ');
};

/**
 * Creates a luxon datetime instance from datetime string
 * @param {string} datetime - Ex. '2021-10-03 12:00:00'
 * @param {string?} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @return {DateTime}
 */
export const createDatetime = (datetime, format = undefined) => {
  const f = format || (datetime?.length > 10 ? DATETIME_FORMAT : DATE_FORMAT);
  return DateTime.fromFormat(sanitizeDatetime(datetime), format || f);
};

/**
 * Validates the provided datetime if it is a valid date / datetime
 * @param {string} datetime - Ex. '2021-10-03T12:00:00+00:00'
 * @param {string?} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @return {boolean}
 */
export const validateDatetime = (datetime, format = undefined) => {
  return createDatetime(datetime, format).isValid;
};

/**
 * Sanitizes Validates the provided datetime if it is a valid date / datetime
 * @param {string} datetime - Ex. '2021-10-03T12:00:00+00:00'
 * @param {string?} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @return {string} - Ex. '2021-10-03 12:00:00'
 */
export const sanitizeAndValidateDatetime = (datetime, format = undefined) => {
  const sanitizedDatetime = sanitizeDatetime(datetime);
  if (!validateDatetime(sanitizedDatetime, format)) throw new Error('Invalid datetime provided.');
  return sanitizedDatetime;
};

/**
 * Convert timezone
 * @param {string} datetime - Ex. '2021-10-03 12:00:00'
 * @param {string} fromTz - Ex. 'Atlantic/Azores'
 * @param {string} toTz - Ex. 'Atlantic/Azores'
 * @param {string} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @returns {string} - Ex. '2021-10-03 12:00:00'
 */
export const convertTz = (datetime, fromTz, toTz, format = DATETIME_FORMAT) => {
  return DateTime.fromFormat(sanitizeAndValidateDatetime(datetime), format, {zone: fromTz}).setZone(toTz).toFormat(format);
};

/**
 * Convert from UTC
 * @param {string} datetime - Ex. '2021-10-03 12:00:00'
 * @param {string} tz - Ex. 'Atlantic/Azores'
 * @param {string} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @returns {string} - Ex. '2021-10-03 12:00:00'
 */
export const fromUTC = (datetime, tz = GET_CURRENT_TZ(), format = DATETIME_FORMAT) => {
  return convertTz(datetime, 'UTC', tz, format);
};

/**
 * Convert to UTC
 * @param {string} datetime - Ex. '2021-10-03 12:00:00'
 * @param {string} tz - Ex. 'Atlantic/Azores'
 * @param {string} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @returns {string} - Ex. '2021-10-03 12:00:00'
 */
export const toUTC = (datetime, tz = GET_CURRENT_TZ(), format = DATETIME_FORMAT) => {
  return convertTz(datetime, tz, 'UTC', format);
};

/**
 * Get current datetime on the provided timezone
 * @param {string} tz - Ex. 'Atlantic/Azores'
 * @param {string} format - Ex. 'yyyy-MM-dd HH:mm:ss'
 * @returns {string} - Ex. '2021-10-03 12:00:00'
 */
export const nowTz = (tz = GET_CURRENT_TZ(), format = DATETIME_FORMAT) => DateTime.now().setZone(tz).toFormat(format);

/**
 * Get current datetime on the provided timezone with zeroized seconds
 * @param {{tz?: string, format?: string}?} - Ex. {format: 'yyyy-MM-dd HH:mm:ss' tz: 'Atlantic/Azores'}
 * @returns {string} - Ex. '2021-10-03 12:00:00'
 */
export const nowTzZeroized = ({tz = GET_CURRENT_TZ(), format = DATETIME_FORMAT} = {}) => {
  return nowTz(tz, 'yyyy-MM-dd HH:mm:00');
}

/**
 * Formats Date instance into 'yyyy-MM-dd HH:mm:ss' format.
 * @param {Date} d
 * @returns {string}
 */
export const dateToYYMMDDHHMMSS = (d) =>
  [d.getFullYear(), String(d.getMonth() + 1).padStart(2, '0'), String(d.getDate()).padStart(2, '0')].join('-') + ' ' +
  [String(d.getHours()).padStart(2, '0'), String(d.getMinutes()).padStart(2, '0'), String(d.getSeconds()).padStart(2, '0')].join(':');

// DURATIONS
/**
 * Creates a luxon duration instance from local duration
 * @param {LocalDuration} duration - Ex. '27:23:00'
 * @return {Duration}
 */
export const createDuration = (duration) => {
  return Duration.fromObject(convertDurationToObject(duration));
}


/**
 * Formats duration into a time format.
 * @param {DurationObject} duration - Ex. {hours: 2, minutes: 32, seconds: 43}
 * @param {string} format - Ex. 'hh:mm:ss'
 * @return {string} - Ex. '02:32:43'
 */
export const formattedDurationFromObj = (duration, format = DURATION_FORMAT) => {
  return Duration.fromObject(duration).toFormat(format);
};

/**
 * Convert duration into a duration object.
 * @param {LocalDuration} duration - Ex. "02:32:43"
 * @return {DurationObject} - Ex. {hours: 2, minutes: 32, seconds: 43}
 */
export const convertDurationToObject = (duration) => {
  const [hours = '0', minutes = '0', seconds = '0'] = duration.split(':');
  return {hours: parseInt(hours), minutes: parseInt(minutes), seconds: parseInt(seconds)};
};

/**
 * Formats two dates interval into a formatted duration.
 * @param {string} start - Ex. '2021-10-03 12:00:00'
 * @param {string} end - Ex. '2021-10-03 13:40:15'
 * @param {string} format - Ex. 'hh:mm:ss'
 * @return {LocalDuration} - Ex. '01:40:15'
 */
export const formattedDurationFromInterval = (start, end, format = DURATION_FORMAT) => {
  return Interval.fromDateTimes(
    DateTime.fromFormat(sanitizeAndValidateDatetime(start), DATETIME_FORMAT),
    DateTime.fromFormat(sanitizeAndValidateDatetime(end), DATETIME_FORMAT)
  ).toDuration().toFormat(format);
};

/**
 * Convert local duration into server duration format.
 * @param {LocalDuration} duration - Ex. '12:00:00'
 * @return {ServerDuration} - Ex. 'PT1H40M'
 */
export const convertDurationToServerFormat = (duration) => {
  const [hStr = '0', mStr = '0', sStr = '0'] = duration.split(':');
  const [fhStr, fmStr] = formattedDurationFromObj({
    hours: parseInt(hStr),
    minutes: parseInt(mStr),
    seconds: parseInt(sStr)
  }).split(':');
  return `PT${parseInt(fhStr)}H${parseInt(fmStr)}M`;
};

/**
 * Convert server duration into local duration format.
 * @param {ServerDuration} serverDuration - Ex. 'PT1H40M'
 * @return LocalDuration
 */
export const convertServerDurationToLocalFormat = (serverDuration) => {
  const [hours, minutes] = serverDuration.replace('PT', '').replace('M', '').split('H');
  return formattedDurationFromObj({hours: parseInt(hours || '0'), minutes: parseInt(minutes || '0')});
};
