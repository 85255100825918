import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {t} from 'ttag';

export const FormEditToggleBtn = ({onToggle, open, openedElement, closedElement, ...rest}) => (
  <Box onClick={(e) => onToggle(!open, e)} sx={styles.btn} {...rest}>
    {open ? openedElement : closedElement}
  </Box>
);

const styles = {
  btn: {
    cursor: 'pointer',
    color: 'primary.light',
    fontSize: '0.875rem',
    fontFamily: 'NeoSansProRegular'
  }
};

FormEditToggleBtn.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  openedElement: PropTypes.any,
  closedElement: PropTypes.any
};

FormEditToggleBtn.defaultProps = {
  open: false,
  onToggle: () => null,
  openedElement: 'x',
  closedElement: t`(edit)`
};

export default FormEditToggleBtn;
