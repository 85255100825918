import PropTypes from 'prop-types';

export const RECORD_VERIFICATION_SHAPE = PropTypes.shape({
    recordId: PropTypes.string,
    startDate: PropTypes.string,
    finishDate: PropTypes.string,
    finished: PropTypes.bool,
    action: PropTypes.shape({
        timeToStd: PropTypes.string,
        estimatedTime: PropTypes.string,
        weight: PropTypes.number,
        description: PropTypes.string,
        kpi: PropTypes.bool,
        applicable: PropTypes.bool,
        mandatory: PropTypes.bool,
        type: PropTypes.oneOf(['VERIFICATION']),
    }).isRequired
});

export const RECORD_CHECKLIST_SHAPE = PropTypes.shape({
    recordId: PropTypes.string,
    startDate: PropTypes.string,
    finishDate: PropTypes.string,
    finished: PropTypes.bool,
    action: PropTypes.shape({
        timeToStd: PropTypes.string,
        estimatedTime: PropTypes.string,
        weight: PropTypes.number,
        description: PropTypes.string,
        kpi: PropTypes.bool,
        applicable: PropTypes.bool,
        mandatory: PropTypes.bool,
        type: PropTypes.oneOf(['CHECKLIST']),
        listItems: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                finished: PropTypes.bool
            })
        ).isRequired
    }).isRequired
});

export const RECORD_TASK_SHAPE = PropTypes.shape({
    recordId: PropTypes.string,
    startDate: PropTypes.string,
    finishDate: PropTypes.string,
    finished: PropTypes.bool,
    action: PropTypes.shape({
        timeToStd: PropTypes.string,
        estimatedTime: PropTypes.string,
        weight: PropTypes.number,
        description: PropTypes.string,
        kpi: PropTypes.bool,
        applicable: PropTypes.bool,
        mandatory: PropTypes.bool,
        type: PropTypes.oneOf(['TASK'])
    }).isRequired
});