import {
  convertDurationToObject,
  createDatetime as dt,
  DATETIME_FORMAT,
  formattedDurationFromInterval
} from '../../../../../../utils/datetime/datetime-utils';

/**
 *
 * @param {LocalDatetime} scheduledTime
 * @param {LocalDatetime} reliableDatetime
 * @param {Delay[]} delays
 * @return {LocalDuration}
 */
export const totalMissingDuration = (scheduledTime, reliableDatetime, delays) => {
  const justificationNeededStart = delays.reduce((endDt, delay) => {
    return dt(endDt).plus(convertDurationToObject(delay.time)).toFormat(DATETIME_FORMAT);
  }, scheduledTime);

  return formattedDurationFromInterval(justificationNeededStart, reliableDatetime);
};
