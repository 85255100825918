import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import RotationViewTabs, {TABS} from '../rotation-view-tabs/RotationViewTabs';
import RotationViewFlight from '../rotation-view-flight/RotationViewFlight';
import {actions} from '../../../../../store/valtio';
import RotationViewTurnaround from '../rotation-view-turnaround/RotationViewTurnaround';

export const RotationViewDialogContent = ({rotation, tab}) => {
  const {inbound, outbound} = rotation;
  const tabsMapper = useCallback(
    (tab) => {
      if (tab.value === TABS.INBOUND.value) return {...tab, show: Boolean(inbound)};
      if (tab.value === TABS.TURNAROUND.value) return {...tab, show: true};
      if (tab.value === TABS.OUTBOUND.value) return {...tab, show: Boolean(outbound)};
      return tab;
    },
    [inbound, outbound]
  );

  return (
    <Box display={'flex'} gap={2} flexDirection={'column'} padding={2}>
      <RotationViewTabs
        rotation={rotation}
        onChange={actions.rotations.rotationView.setTab}
        value={tab}
        tabs={Object.values(TABS).map(tabsMapper)}
      />
      {tab === TABS.INBOUND.value && <RotationViewFlight rotation={rotation} flightDirection={tab} />}
      {tab === TABS.TURNAROUND.value && <RotationViewTurnaround rotation={rotation} />}
      {tab === TABS.OUTBOUND.value && <RotationViewFlight rotation={rotation} flightDirection={tab} />}
    </Box>
  );
};

RotationViewDialogContent.propTypes = {
  tab: PropTypes.oneOf(Object.values(TABS).map(t => t.value)),
  rotation: PropTypes.shape({
    inbound: PropTypes.object,
    outbound: PropTypes.object
  })
};

RotationViewDialogContent.defaultProps = {
  tab: null,
  rotation: null
};

export default RotationViewDialogContent;
