export const MSA_AUTHORITY = process.env.REACT_APP_MSA_AUTHORITY;
export const MSA_CLIENT_ID = process.env.REACT_APP_MSA_CLIENT_ID;
export const MSA_SCOPES = process.env.REACT_APP_MSA_SCOPES?.split(',') || [];
export const AIRPORTS = process.env.REACT_APP_AIRPORTS?.split(',') || [];
export const ENABLE_DEV_TOOLS = process.env.REACT_APP_ENABLE_DEV_TOOLS;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;
