import {determineOperationStatus} from '../../../utils/rotation/rotation-utils';
import useRotationTimerTick from './useRotationTimerTick';

/** @type {(operation: Operation) => OperationStatus} */
export const useOperationAnalyzer = (operation) => {
  useRotationTimerTick();

  return determineOperationStatus(operation);
};

export default useOperationAnalyzer;
