import {convertDatetime, convertDatetimeFromUTC, convertDatetimeToUTC, convertDuration} from './datetime-mappers';

/** @type {(record: Action | ActionCheckList, converter: DatetimeConverter) => Action | ActionCheckList} */
export const datetimeRecordActionMapper = (action, converter) => ({
    ...action,
    timeToStd: action.timeToStd ? convertDatetime(action.timeToStd, converter) : null,
    estimatedTime: action.estimatedTime ? convertDuration(action.estimatedTime) : null,
});

/** @type {(record: Record, converter: DatetimeConverter) => Record} */
export const datetimeRecordMapper = (record, converter) => ({
    ...record,
    startDate: record.startDate ? convertDatetime(record.startDate, converter) : null,
    finishDate: record.finishDate ? convertDatetime(record.finishDate, converter) : null,
    action: datetimeRecordActionMapper(record.action, converter)
});

/** @type {(record: Record) => Record} */
export const datetimeRecordMapperFromUtc = (record) => {
    return datetimeRecordMapper(record, convertDatetimeFromUTC);
};

/** @type {(UpdateRecordDTO) => Record} */
export const datetimeUpdateRecordDTOMapperToUtc = (body) => ({
    startDate: body.startDate ? convertDatetime(body.startDate, convertDatetimeToUTC) : null,
    finishDate: body.finishDate ? convertDatetime(body.finishDate, convertDatetimeToUTC) : null
});