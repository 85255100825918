import {configureStore} from '@reduxjs/toolkit';
import sliceTemplate from './slices/sliceTemplate';
import {ENABLE_DEV_TOOLS} from '../../config/settings';
import api from '../../api';

const store = configureStore({
  reducer: {
    sliceTemplate,
    [api.reducerPath]: api.reducer

  },
  devTools: Boolean(ENABLE_DEV_TOOLS),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

export default store;

