import RotationCardFlight from '../rotation-card-flight/RotationCardFlight';
import {Box} from '@mui/material';
import {t} from 'ttag';
import useRotationAnalyzer from '../../../rotation-hooks/useRotationAnalyzer';
import {actions} from '../../../../../store/valtio';
import RotationCardTurnAround from '../rotation-card-turn-around/RotationCardTurnAround';
import {TABS} from '../../../rotation-view/resources/rotation-view-tabs/RotationViewTabs';
import {ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
const {setRotationAndTab} = actions.rotations.rotationView;

export const RotationCardContent = ({rotation}) => {
  const {inbound, outbound} = rotation;
  const {inbound: inboundStatus, outbound: outboundStatus, turnaroundInterval} = useRotationAnalyzer(rotation);
  const onClickInbound = inbound ? TABS.INBOUND.value : TABS.OUTBOUND.value;
  const onClickTurnaround = TABS.TURNAROUND.value;
  const onClickOutbound = outbound ? TABS.OUTBOUND.value : TABS.INBOUND.value;
  const inboundClickHandler = () => setRotationAndTab(rotation, onClickInbound);
  const turnaroundClickHandler = () => setRotationAndTab(rotation, onClickTurnaround);
  const outboundClickHandler = () => setRotationAndTab(rotation, onClickOutbound);

  return (
    <Box display={'flex'} sx={{cursor: 'pointer'}}>
      <Box flex={{xs: 6, md: 5}} p={3} onClick={inboundClickHandler}>
        {inbound && (
          <RotationCardFlight {...inboundStatus} flight={inbound} flightDirection={'inbound'} label={t`Arrival`} mobileAlign={'flex-start'} />
        )}
      </Box>
      <Box flex={{xs: 0, md: 2}} width={'100%'} onClick={turnaroundClickHandler}>
        <RotationCardTurnAround turnaroundInterval={turnaroundInterval} showTimer={Boolean(inbound && outbound)} />
      </Box>
      <Box flex={{xs: 6, md: 5}} p={3} onClick={outboundClickHandler}>
        {outbound && (
          <RotationCardFlight {...outboundStatus} flight={outbound} flightDirection={'outbound'} label={t`Departure`} mobileAlign={'flex-end'} />
        )}
      </Box>
    </Box>
  );
};

RotationCardContent.propTypes = {
  rotation: ROTATION_SHAPE.isRequired
};

export default RotationCardContent;
