import * as Yup from 'yup';
import {createDatetime as dt} from '../../../../../../utils/datetime/datetime-utils';
import {t} from 'ttag';

export const schema = Yup.object()
  .shape(
    {
      touchDown: Yup.string()
        .required(t`Required field.`)
        .test({
          test: (value) => value && dt(value).isValid,
          message: t`Should be a valid datetime.`
        })
        .when('actualTime', (actualTime, schema) => {
          return schema.test({
            test: (value) => value && actualTime && dt(value) <= dt(actualTime),
            message: t`TOUCH DOWN should be before ATA.`
          });
        }),
      actualTime: Yup.string()
        .required(t`Required field.`)
        .test({
          test: (value) => value && dt(value).isValid,
          message: t`Should be a valid datetime.`
        })
        .when('touchDown', (touchDown, schema) => {
          return schema.test({
            test: (value) => value && touchDown && dt(value) >= dt(touchDown),
            message: t`ATA should be after TOUCH DOWN.`
          });
        })
        .when('$reliableOutbound', (reliableOutbound, schema) => {
          return schema.test({
            test: (value) => reliableOutbound.datetimeType !== 'actualTime' || dt(value) < dt(reliableOutbound.datetime),
            message: t`ATA should be before ATD.`
          })
        })
    },
    ['touchDown', 'actualTime']
  )
  .required();
