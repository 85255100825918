export const type = 'role';

export const roleEndpoints = (builder) => ({
  getRolesByPattern: builder.mutation({
    query: (pattern) => ({
      url: `/roles?pattern=${pattern}&rows=9999`,
      method: 'GET',
    }),
    transformResponse: (data) => data?.data?.data,
  }),

  getAllRoles: builder.query({
    query: () => ({
      url: `/roles?rows=10000`,
      method: 'GET',
    }),
    transformResponse: (data) => data?.data?.data,
    providesTags: (data) => {
      return data
        .map(({roleId}) => ({type, id: roleId}))
        .concat({type, id: 'list'});
    }
  }),

  getRoles: builder.query({
    query: (params) => ({
      url: `/roles`,
      method: 'GET',
      params
    }),
    transformResponse: (data) => data?.data,
    providesTags: ({data}) => {
      return data
        .map(({roleId}) => ({type, id: {roleId}}))
        .concat({type, id: 'list'});
    }
  }),

  getRole: builder.query({
    query: (roleId) => ({
      url: `/role/${roleId}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
    providesTags: ({roleId}) => [{type, id: roleId}]
  }),

  createRole: builder.mutation({
    query: (body) => ({
      url: `/roles`,
      method: 'POST',
      body
    }),
    invalidatesTags: [{type, id: 'list'}]
  }),

  updateRole: builder.mutation({
    query: (body) => ({
      url: `/role/${body.roleId}`,
      method: 'PATCH',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: ({roleId}) => [{type, id: roleId}]
  }),

  deleteRole: builder.mutation({
    query: (body) => ({
      url: `/role/${body.roleId}`,
      method: 'DELETE',
      body
    }),
    invalidatesTags: [{type, id: 'list'}]
  })
});

export default roleEndpoints;
