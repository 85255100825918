import {t} from 'ttag';
import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useDeleteUserMutation} from '../../../../api';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';

const DeleteEmployeeDialog = ({open, onClose, employee}) => {
  const [deleteItem, {data, error, isLoading, isSuccess}] = useDeleteUserMutation();

  const handleSave = () => {
    deleteItem(employee).then((r) => !r.error && onClose());
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        successMessage={t`The item was successfully deleted!`}
      />
      <Dialog title={t`Delete Employee`} variant={'error'} open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Typography variant={'h2'} component={'div'} color={'error'}>
            {t`Delete`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{t`Do you really want to delete this item?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button variant='contained' color='error' onClick={handleSave}>
              {t`Delete`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant={'outlined'} onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteEmployeeDialog.propTypes = {
  employee: PropTypes.shape({
    employeeId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DeleteEmployeeDialog.defaultProps = {
  open: true
};

export default DeleteEmployeeDialog;
