import {t} from 'ttag';
import React, {useState} from 'react';
import {Button} from '@mui/material';
import {CloudDownload} from '@mui/icons-material';
import {EmployeesList} from './index';
import {DeleteEmployeeDialog, EditEmployeeDialog, ImportEmployeeDialog} from './employee-forms';

export const Employees = () => {
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [employeeToImport, setEmployeeToImport] = useState(null);

  return (
    <>
      <EmployeesList
        editHandler={setEmployeeToEdit}
        removeHandler={setEmployeeToDelete}
        actionComponent={
          <Button
            onClick={() => setEmployeeToImport(true)}
            variant={'outlined'}
            color={'secondary'}
            startIcon={<CloudDownload />}
          >{t`IMPORT`}</Button>
        }
      />
      {employeeToImport && <ImportEmployeeDialog onClose={() => setEmployeeToImport(null)} />}
      {employeeToEdit && <EditEmployeeDialog onClose={() => setEmployeeToEdit(null)} employee={employeeToEdit} />}
      {employeeToDelete && (
        <DeleteEmployeeDialog onClose={() => setEmployeeToDelete(null)} employee={employeeToDelete} />
      )}
    </>
  );
};

Employees.propTypes = {};

Employees.defaultProps = {};

export default Employees;
