import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './etd-form-schema';
import DatetimePicker from '../../../../../common/date-time-picker/DatetimePicker';
import {Grid, Stack} from '@mui/material';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import FormActions from '../resources/form-actions/FormActions';
import MVTConfirmation from '../resources/mvt-confirmation/MVTConfirmation';
import React, {useState} from 'react';
import {useCreateEtdMutation} from '../../../../../../api';
import {ROTATION_SHAPE} from '../../../../common/rotation-shapes/rotation-shapes';
import RTKQueryFeedback from '../../../../../common/RTKQueryFeedback';
import {nowTzZeroized} from '../../../../../../utils/datetime/datetime-utils';
import {actions} from '../../../../../../store/valtio';

const ETDForm = ({rotation, onClose}) => {
  const [mvtBody, setMvtBody] = useState(null);
  const [mvtWasSent, setMvtWasSent] = useState(false);
  const [sendMvt, {isLoading, error}] = useCreateEtdMutation();
  const {estimatedTime} = rotation.outbound.departure;
  const {handleSubmit, control} = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      estimatedTime: estimatedTime || nowTzZeroized()
    }
  });

  const submitHandler = handleSubmit((values) => {
    sendMvt({
      params: {preview: mvtBody ? 0 : 1},
      body: {rotationHash: rotation.hash, ...values}
    }).then(({data: {body}}) => {
      if (!mvtBody) {
        setMvtBody(body);
        setMvtWasSent(false);
      } else {
        setMvtBody(null);
        actions.rotations.setNotifiedRotation({
          ...rotation,
          outbound: {
            ...rotation.outbound,
            departure: {
              ...rotation.outbound.departure,
              estimatedTime: values.estimatedTime
            }
          }
        });
        setMvtWasSent(true);
        onClose();
      }
    });
  });

  if (mvtBody) {
    return (
      <>
        <MVTConfirmation message={mvtBody} onSubmit={submitHandler} />
        <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
      </>
    );
  }

  return (
    <Stack spacing={1}>
      <Grid container>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'estimatedTime'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                label={'ETD'}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
              />
            )}
          />
        </Grid>
      </Grid>
      <FormActions btnText={t`Update`} onClick={submitHandler} onClose={onClose} />
      <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
    </Stack>
  );
};

ETDForm.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  onClose: PropTypes.func
};

ETDForm.defaultProps = {
  onClose: () => null
};

export default ETDForm;
