export const type = 'user';

export const userManagementEndpoints = (builder) => ({
  getAllUsers: builder.query({
    query: (params = {}) => ({
      url: '/users',
      method: 'GET',
      params: {
        rows: 10000,
        ...params
      }
    }),
    transformResponse: (data) => data?.data?.data?.data,
    providesTags: (data) => {
      return data.map(({employeeId}) => ({type, id: employeeId})).concat({type, id: 'list'});
    }
  }),

  getUsers: builder.query({
    query: (params) => ({
      url: '/users',
      method: 'GET',
      params
    }),
    transformResponse: (data) => data?.data?.data,
    providesTags: ({data}) => {
      return data.map(({employeeId}) => ({type, id: employeeId})).concat({type, id: 'list'});
    }
  }),

  addUser: builder.mutation({
    query: (user) => ({
      url: '/users',
      method: 'POST',
      body: user
    }),
    transformResponse: (data) => data?.data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  editUser: builder.mutation({
    query: (user) => ({
      url: `/users/${user.employeeId}`,
      method: 'PATCH',
      body: user
    }),
    transformResponse: (data) => data?.data?.data,
    invalidatesTags: ({employeeId}) => [{type, id: employeeId}]
  }),

  getUserPicture: builder.query({
    query: ({employeeId}) => ({
      url: `/user/${employeeId}/picture.jpg`,
      method: 'GET'
    })
  }),

  getUser: builder.query({
    query: (employeeId) => ({
      url: `/user/${encodeURIComponent(employeeId)}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
    providesTags: ({employeeId}) => [{type, id: employeeId}]
  }),

  deleteUser: builder.mutation({
    query: ({userId}) => ({
      url: `/users/${userId}`,
      method: 'DELETE'
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: () => [{type, id: 'list'}]
  }),

  getCurrentUser: builder.query({
    query: (body) => ({
      url: `/user/me`,
      method: 'GET',
      body
    }),
    transformResponse: (data) => ({
      ...data?.data?.data,
      roles: data?.data?.data?.roles.map((r) => r.name)
    })
  }),

  getAzureUsers: builder.mutation({
    query: (pattern) => ({
      url: `/search-user?pattern=${pattern}`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data?.data
  })
});

export default userManagementEndpoints;
