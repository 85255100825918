import {
  convertDurationToServerFormat,
  convertServerDurationToLocalFormat,
  fromUTC,
  toUTC
} from '../../utils/datetime/datetime-utils';

/** @typedef {(datetime: string, isOptional?: boolean) => LocalDatetime} DatetimeConverter */

/** @type {(converter: DatetimeConverter, datetime: string, isOptional: boolean) => LocalDatetime | null} */
export const optionalDatetimeConvert = (converter, datetime, isOptional = false) => {
  if (!isOptional && !datetime) throw new Error('Provided datetime is not truthy neither is marked as optional.');
  if (!datetime) return null;
  return converter(datetime);
};

/** @type {DatetimeConverter} */
export const convertDatetimeFromUTC = (datetime, isOptional = false) => {
  return optionalDatetimeConvert(fromUTC, datetime, isOptional);
};

/** @type {DatetimeConverter} */
export const convertDatetimeToUTC = (datetime, isOptional = false) => {
  return optionalDatetimeConvert(toUTC, datetime, isOptional);
};

/** @type {(datetime: ServerDatetime | LocalDatetime, converter: DatetimeConverter, isOptional?: boolean) => LocalDatetime} */
export const convertDatetime = (datetime, converter, isOptional = false) => {
  return converter(typeof datetime === 'object' ? datetime.date : datetime, isOptional);
};

/** @type {(duration: ServerDuration | LocalDuration) => ServerDuration | LocalDuration | null} */
export const convertDuration = (duration) => {
  return duration?.includes('PT')
    ? convertServerDurationToLocalFormat(duration)
    : convertDurationToServerFormat(duration);
};

