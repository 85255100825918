import React from 'react';
import {useNavigate} from 'react-router-dom';
import logoDesktop from '../../assets/sata-logo.png';
import logoMobile from '../../assets/sata-logo-mobile.png';
import UserMenu from './UserMenu';
import {APP_NAME} from '../../config/settings';
import {actions} from '../../store/valtio';
import {Cached, Menu} from '@mui/icons-material';
import {AppBar as MuiAppBar, Box, IconButton, Toolbar, Typography, useMediaQuery} from '@mui/material';

export const Appbar = () => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const logo = isTablet ? logoMobile : logoDesktop;
  const navigate = useNavigate();

  return (
    <MuiAppBar position={'fixed'} logo={logo} sx={styles.root} elevation={0}>
      <Toolbar sx={styles.toolbar}>
        {isTablet && (
          <IconButton edge={'start'} onClick={actions.app.toggleSideBarOpen}>
            <Menu fontSize={'large'} />
          </IconButton>
        )}
        <Box component={'img'} onClick={() => navigate('/')} sx={styles.image} src={logo} alt={APP_NAME} />
        <Box sx={styles.barHolder}>
          <Box sx={styles.barHolderLeft}>
            <Typography sx={styles.appName} variant='h4'>
              {APP_NAME}
            </Typography>
            <IconButton onClick={() => window.location.reload()}>
              <Cached />
            </IconButton>
          </Box>
          <UserMenu />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

const styles = {
  root: {
    color: 'secondary.main',
    backgroundColor: 'common.white',
    zIndex: (theme) => theme.zIndex.drawer + 1,
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    height: '3.75rem'
  },
  image: {
    height: '2.625rem',
    cursor: 'pointer'
  },
  appName: {
    fontSize: '1.25rem',
    marginLeft: '0.8rem',
    display: {xs: 'none', sm: 'unset'},
    color: 'text.secondary'
  },
  toolbar: {
    minHeight: '3.75rem'
  },
  barHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: 'primary.main'
  },
  barHolderLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
};

export default Appbar;
