import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Stack, Typography} from '@mui/material';
import {t} from 'ttag';

const MVTConfirmationAlert = ({onClick}) => {
  return (
    <Box sx={styles.root}>
      <Stack direction={{xs: 'column', sm: 'row'}} justifyContent={'space-between'}>
        <Typography sx={styles.typography}>
          <strong>{t`Finished:`}</strong> {t`Are you sure, you want to send this message?`}
        </Typography>
        <Button sx={styles.button} variant={'contained'} size={'small'} onClick={onClick}>
          <strong>{t`SEND MVT`}</strong>
        </Button>
      </Stack>
    </Box>
  );
};

MVTConfirmationAlert.propTypes = {
  onClick: PropTypes.func
};

MVTConfirmationAlert.defaultProps = {
  onClick: () => null
};

const styles = {
  root: {
    background: `#ECF5EC 0% 0% no-repeat padding-box`,
    border: (theme) => `1px solid ${theme.palette.success.light}`,
    borderRadius: 1,
    padding: '10px'
  },
  typography: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: 'success.dark',
    opacity: 1,
    margin: '8px 0px'
  },
  button: {
    backgroundColor: 'success.main',
    color: 'common.white',
    '&:hover': {
      backgroundColor: 'success.dark'
    }
  }
};

export default MVTConfirmationAlert;
