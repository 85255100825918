import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, Stack} from '@mui/material';
import RotationViewDialogHeader from '../../../rotation-view-dialog/RotationViewDialogHeader';

const MVTConfirmationDialog = ({message, onClose, onSubmit}) => {
  return (
    <Dialog onClose={onClose} maxWidth={'xs'} open fullWidth>
      <RotationViewDialogHeader title={t`Send MVT`} onClose={onClose} />
      <DialogContent>
        <pre>{message}</pre>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
          <Button onClick={onSubmit} variant={'contained'}>
            {t`Send`}
          </Button>
          <Button onClick={onClose} variant={'outlined'} autoFocus>
            {t`Cancel`}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

MVTConfirmationDialog.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

MVTConfirmationDialog.defaultProps = {
  message: null,
  onClose: () => null,
  onSubmit: () => null
};

export default MVTConfirmationDialog;
