import {datetimeRotationMapperFromUtc} from './utils/rotation-mappers';
import {datetimeRecordMapperFromUtc, datetimeUpdateRecordDTOMapperToUtc} from './utils/record-mappers';

export const type = 'record';

export const recordEndpoints = (builder) => ({
  getRecords: builder.query({
    query: (rotationId) => ({
      url: `/records/${rotationId}`
    }),
    transformResponse: (data) => datetimeRotationMapperFromUtc(data?.data),
    providesTags: (data) => {
      return data.records.map(({recordId}) => ({type, id: recordId})).concat({type, id: 'list'});
    }
  }),

  getOptionalActions: builder.query({
    query: (rotationHash) => ({
      url: `/records/${rotationHash}/optional-actions`
    }),
    transformResponse: (data) => data.data
  }),

  addOptionalAction: builder.mutation({
    query: ({rotationHash, action}) => ({
      url: `/records/${rotationHash}/add`,
      method: 'POST',
      body: action
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  removeAction: builder.mutation({
    query: ({rotationHash, recordId}) => ({
      url: `/records/${rotationHash}/${recordId}`,
      method: 'DELETE'
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  updateAction: builder.mutation({
    query: ({params, body}) => ({
      url: `/record/${params.recordId}/update-action`,
      method: 'PATCH',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  applicableRecords: builder.mutation({
    query: (body) => ({
      url: `/records/applicable`,
      method: 'POST',
      body
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: [{type, id: 'list'}]
  }),

  startAction: builder.mutation({
    query: (recordId) => ({
      url: `/record/${recordId}/start`,
      method: 'GET'
    }),
    transformResponse: (data) => datetimeRecordMapperFromUtc(data?.data),
    invalidatesTags: [{type, id: 'list'}]
  }),

  finishAction: builder.mutation({
    query: (recordId) => ({
      url: `/record/${recordId}/finish`,
      method: 'GET'
    }),
    transformResponse: (data) => datetimeRecordMapperFromUtc(data?.data),
    invalidatesTags: [{type, id: 'list'}]
  }),

  unStartAction: builder.mutation({
    query: (recordId) => ({
      url: `/record/${recordId}/unstart`,
      method: 'GET'
    }),
    transformResponse: (data) => datetimeRecordMapperFromUtc(data?.data),
    invalidatesTags: [{type, id: 'list'}]
  }),

  unFinishAction: builder.mutation({
    query: (recordId) => ({
      url: `/record/${recordId}/unfinish`,
      method: 'GET'
    }),
    transformResponse: (data) => datetimeRecordMapperFromUtc(data?.data),
    invalidatesTags: [{type, id: 'list'}]
  }),

  updateRecord: builder.mutation({
    query: ({params, body}) => ({
      url: `/record/${params.recordId}/update`,
      method: 'PATCH',
      body: datetimeUpdateRecordDTOMapperToUtc(body)
    }),
    transformResponse: (data) => data?.data,
    invalidatesTags: () => [{type, id: 'list'}]
  }),

  updateSandGateBelt: builder.mutation({
    query: ({hash, body}) => ({
      url: `/rotations/${hash}/flight-details`,
      method: 'PATCH',
      body
    }),
    transformResponse: (data) => datetimeRotationMapperFromUtc(data?.data),
    invalidatesTags: () => [{type, id: 'list'}]
  })
});

export default recordEndpoints;
