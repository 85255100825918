import axios from 'axios';
import msalInstance from '../../utils/msal-instance';
import {API_ENDPOINT, MSA_SCOPES} from '../../config/settings';

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await msalInstance.acquireTokenSilent({scopes: MSA_SCOPES});
    } catch (e) {
      token = await msalInstance.acquireTokenRedirect({scopes: MSA_SCOPES});
    }
    config.headers = {
      Authorization: `${token.tokenType} ${token.accessToken}`
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export {axiosInstance};

export default axiosInstance;
