import {
  createDatetime as dt,
  createDuration,
  DURATION_FORMAT,
  formattedDurationFromInterval,
  nowTz
} from '../datetime/datetime-utils';
import {t} from 'ttag';

/** @type {(rotation: Rotation, currentDatetime: LocalDatetime) => RotationStatus}  */
export const determineRotationStatus = (rotation, currentDatetime = nowTz()) => ({
  turnaroundInterval: determineTurnaroundInterval(rotation, currentDatetime),
  states: determineRotationStates(rotation),
  inbound: rotation?.inbound ? determineOperationStatus(rotation.inbound.arrival) : null,
  outbound: rotation?.outbound ? determineOperationStatus(rotation.outbound.departure) : null,
  updatedOn: currentDatetime
});

/** @type {(rotation: Rotation, currentDatetime: LocalDatetime) => TurnaroundInterval}  */
export const determineTurnaroundInterval = (rotation, currentDatetime = nowTz()) => {
  if (rotation?.inbound && rotation?.outbound) {
    const {
      arrival: {actualTime: ATA, scheduledTime: STA}
    } = rotation.inbound;
    const {
      departure: {actualTime: ATD, scheduledTime: STD}
    } = rotation.outbound;
    const C = currentDatetime;

    // PAST
    if (ATA && ATD) {
      if (dt(ATD) > dt(STD))
        return {
          time: formattedDurationFromInterval(STD, ATD),
          color: STATUS_COLORS.DELAYED,
          sign: '+'
        };
      return {
        time: formattedDurationFromInterval(ATA, ATD),
        color: null,
        sign: null
      };
    }

    // RUNNING
    if (ATA && !ATD) {
      if (dt(C) > dt(STD))
        return {
          time: formattedDurationFromInterval(STD, C),
          color: STATUS_COLORS.DELAYED,
          sign: '+'
        };
      if (dt(C) >= dt(STD).minus({minutes: 5}) && dt(C) <= dt(STD))
        return {
          time: formattedDurationFromInterval(C, STD),
          color: STATUS_COLORS.WARNING,
          sign: null
        };
      if (dt(C) < dt(STD).minus({minutes: 5}))
        return {
          time: formattedDurationFromInterval(C, STD),
          color: STATUS_COLORS.ON_TIME,
          sign: null
        };
    }

    // FUTURE
    if (!ATA) {
      const reliableDatetime = determineReliableDatetime(rotation?.inbound?.arrival, [E, S]);
      if(dt(STA) < dt(reliableDatetime?.datetime) || dt(STA) < dt(C)){
        const reliableArrival = determineReliableDatetime(rotation?.inbound?.arrival, [E, S])
        const reliableDeparture = determineReliableDatetime(rotation?.outbound?.departure, [E, S])
        return {
          time: formattedDurationFromInterval(reliableArrival?.datetime, reliableDeparture?.datetime) !== 'Invalid Duration' ? formattedDurationFromInterval(reliableArrival?.datetime, reliableDeparture?.datetime) : t`Waiting Scheduling`,
          color: STATUS_COLORS.DELAYED,
          sign: null
        };
      }

      return {
        time: formattedDurationFromInterval(STA, STD),
        color: null,
        sign: null
      };
    }
  }

  // DEFAULT
  return {
    time: null,
    color: null,
    sign: null
  };
};

/** @type {(operation: Operation) => OperationStatus} */
export const determineOperationStatus = (operation) => {
  // DELAYED
  const delayedPriorityList = operation.estimatedTime && dt(nowTz()) > dt(operation.estimatedTime) ? [A, C] : [A, E, C];
  let reliable = determineReliableDatetime(operation, delayedPriorityList);
  if (reliable && dt(reliable.datetime) > dt(operation.scheduledTime))
    return {
      name: OPERATION_STATUSES.DELAYED,
      datetime: reliable.datetimeType === C ? operation.scheduledTime : reliable.datetime,
      datetimeType: reliable.datetimeType === C ? S : reliable.datetimeType,
      color: STATUS_COLORS.DELAYED
    };

  // AHEAD OF TIME
  reliable = determineReliableDatetime(operation, [A, E]);
  if (reliable && dt(reliable.datetime) < dt(operation.scheduledTime))
    return {
      ...reliable,
      name: OPERATION_STATUSES.AHEAD_OF_TIME,
      color: STATUS_COLORS.AHEAD_OF_TIME
    };

  // ON TIME
  reliable = determineReliableDatetime(operation, [A, E, S]);
  if (reliable && dt(reliable.datetime).equals(dt(operation.scheduledTime)))
    return {
      ...reliable,
      name: OPERATION_STATUSES.ON_TIME,
      color: STATUS_COLORS.ON_TIME
    };
};

/** @type {(rotation: Rotation) => RotationState[]} */
export const determineRotationStates = (rotation) => {
  const {arrival = {}} = rotation?.inbound || {};
  const {departure = {}} = rotation?.outbound || {};
  const {actualTime: ATA, scheduledTime: STA} = arrival;
  const {actualTime: ATD, scheduledTime: STD, delays = []} = departure;
  const rotationStates = [];

  if (ATA && !ATD) rotationStates.push(ROTATION_STATES.RUNNING);
  if (!ATA || ATD) rotationStates.push(ROTATION_STATES.NOT_RUNNING);
  if (
    STD &&
    ATD &&
    dt(ATD) > dt(STD) &&
    createDuration(
      delays.reduce((td, d) => createDuration(td).plus(createDuration(d.time)).toFormat(DURATION_FORMAT), '00:00:00')
    ) < createDuration(formattedDurationFromInterval(STD, ATD))
  )
    rotationStates.push(ROTATION_STATES.DELAYED_WITHOUT_JUSTIFICATION);
  const arrivalDelayedPriority = arrival.estimatedTime && dt(nowTz()) > dt(arrival.estimatedTime) ? [A, C] : [A, E, C];
  const arrivalReliableDatetime = determineReliableDatetime(arrival, arrivalDelayedPriority)?.datetime;
  const departureDelayedPriority =
    departure.estimatedTime && dt(nowTz()) > dt(departure.estimatedTime) ? [A, C] : [A, E, C];
  const departureReliableDatetime = determineReliableDatetime(departure, departureDelayedPriority)?.datetime;
  if (
    (STA && arrivalReliableDatetime && dt(arrivalReliableDatetime) > dt(STA)) ||
    (STD && departureReliableDatetime && dt(departureReliableDatetime) > dt(STD))
  ) {
    rotationStates.push(ROTATION_STATES.DELAYED);
  }

  return rotationStates;
};

/** @type {(rotation: Rotation, flightDirection: FlightDirection) => Flight | null} */
export const determineFlight = (rotation, flightDirection) => {
  return rotation[flightDirection];
};

/** @type {(rotation: Rotation, flightDirection: FlightDirection) => Operation | null} */
export const determineOperation = (rotation, flightDirection) => {
  const flight = determineFlight(rotation, flightDirection);
  if (!flight) throw new Error('Cannot determine operation on a null flight');
  const isOutbound = flightDirection === FLIGHT_DIRECTIONS.OUTBOUND;
  return isOutbound ? flight.departure : flight.arrival;
};

/** @type {(operation: Operation, priorityList: DatetimeType[]) => ReliableDatetime | null} */
export const determineReliableDatetime = (operation, priorityList = [A, E, S]) => {
  for (const datetimeType of priorityList) {
    if (operation[datetimeType] || datetimeType === C) {
      return {
        datetime: datetimeType === C ? nowTz() : operation[datetimeType],
        datetimeType
      };
    }
  }

  return null;
};

/** @type {DatetimeType} */
export const A = 'actualTime';
/** @type {DatetimeType} */
export const E = 'estimatedTime';
/** @type {DatetimeType} */
export const S = 'scheduledTime';
/** @type {DatetimeType} */
export const C = 'currentTime';
/** @type {{[key: string]: DatetimeType}} */
export const DATETIME_TYPES = {A, E, S, C};

/** @type {{[key: string]: StatusColor}} */
export const STATUS_COLORS = {
  AHEAD_OF_TIME: 'flightStatus.aheadOfTime',
  ON_TIME: 'flightStatus.onTime',
  WARNING: 'flightStatus.warning',
  DELAYED: 'flightStatus.delayed',
  BRIEFING_FIRST_WARNING: 'flightStatus.briefingFirstWarning'
};

/** @type {{[key: string]: OperationStatusName}} */
export const OPERATION_STATUSES = {
  DELAYED: 'Delayed',
  AHEAD_OF_TIME: 'Early',
  ON_TIME: 'On Time'
};

/** @type {{[key: string]: RotationState}} */
export const ROTATION_STATES = {
  ALL: '',
  DELAYED: 'Delayed',
  DELAYED_WITHOUT_JUSTIFICATION: 'DelayedWithoutJustification',
  RUNNING: 'Running',
  NOT_RUNNING: 'NotRunning'
};

/** @type {{[key: string]: FlightDirection}} */
export const FLIGHT_DIRECTIONS = {
  INBOUND: 'inbound',
  TURNAROUND: 'turnaround',
  OUTBOUND: 'outbound'
};
