import {
  movementAtaDtoMapper,
  movementAtdDtoMapper,
  movementDelaysDtoMapper,
  movementEtdDtoMapper
} from './utils/movement-mappers';

export const type = 'movement';

export const movementEndpoints = (builder) => ({
  createAta: builder.mutation({
    query: ({body, params}) => ({
      url: '/movements/arrival',
      method: 'POST',
      body: movementAtaDtoMapper(body),
      params
    }),
    transformResponse: (data) => data?.data,
  }),

  createEtd: builder.mutation({
    query: ({body, params}) => ({
      url: '/movements/delay-message',
      method: 'POST',
      body: movementEtdDtoMapper(body),
      params
    }),
    transformResponse: (data) => data?.data,
  }),

  createAtd: builder.mutation({
    query: ({body, params}) => ({
      url: '/movements/departure',
      method: 'POST',
      body: movementAtdDtoMapper(body),
      params
    }),
    transformResponse: (data) => data?.data,
  }),

  createDelays: builder.mutation({
    query: ({body, params}) => ({
      url: '/movements/delayed',
      method: 'POST',
      body: movementDelaysDtoMapper(body),
      params
    }),
    transformResponse: (data) => data?.data,
  }),
});

export default movementEndpoints;
