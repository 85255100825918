import {useEffect} from 'react';
import {actions, useStore} from '../../../store/valtio';


/** @type {(timeout: number) => ({running: boolean, tick: number})} */
export const useRotationTimer = (timeout = 1000) => {
  const {rotations: {timer: {running}}} = useStore();

  useEffect(() => {
    const interval = setInterval(actions.rotations.timer.incTick, timeout);
    if (!running) clearInterval(interval);
    return () => clearInterval(interval);
  }, [running, timeout]);
};

export default useRotationTimer;
