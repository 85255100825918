import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import EmployeeForm from './EmployeeForm';
import {FormProvider, useForm} from 'react-hook-form';
import {employeeValidationSchema} from './employeeValidationSchema';
import {useEditUserMutation, useGetUserQuery} from '../../../../api';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {yupResolver} from '@hookform/resolvers/yup';

const EditEmployeeDialog = ({employee: {employeeId}, open, onClose}) => {
  const {data: employee, error: employeeError, isLoading: employeeIsLoading} = useGetUserQuery(employeeId);
  const [editUser, {data, error, isLoading}] = useEditUserMutation();

  const methods = useForm({
    resolver: yupResolver(employeeValidationSchema),
    defaultValues: employee
  });

  useEffect(() => {
    if (employee) {
      methods.reset(employee);
    }
  }, [employee, methods]);

  const handleSubmit = methods.handleSubmit((values) => {
    editUser({
      ...values,
      roles: values.roles.map((r) => r.name)
    }).then((r) => !r.error && onClose());
  });

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Update`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <EmployeeForm />
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback error={error || employeeError} isLoading={isLoading || employeeIsLoading} data={data} />
    </FormProvider>
  );
};

EditEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditEmployeeDialog.defaultProps = {
  open: true
};

export default EditEmployeeDialog;
