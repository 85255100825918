import {t} from 'ttag';
import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, Box, TextField} from '@mui/material';
import {delays} from './delay-codes';

const DelayCodesSearch = forwardRef(
  ({value, onChange, label, size, error, helperText, required, disableClearable, disabled}, ref) => {
    return (
      <Autocomplete
        ref={ref}
        size={size}
        noOptionsText={t`No options`}
        disabled={disabled}
        options={delays}
        isOptionEqualToValue={(option, value) => option.codeNumeric === value.codeNumeric}
        getOptionLabel={(opt) => `${opt.codeNumeric}`}
        onChange={(_, delayCode) => onChange(delayCode)}
        value={value}
        disableClearable={disableClearable}
        filterOptions={(opts, {inputValue = ''}) => {
          return opts.filter((o) =>
            `${o.codeNumeric} ${o.codeDescription}`.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            label={label}
            helperText={helperText}
            variant={'outlined'}
            placeholder={t`Start typing...`}
          />
        )}
        renderOption={(params, opt) => (
          <Box {...params}>
            <span>
              <strong>{opt.codeNumeric}</strong> {opt.codeDescription}
            </span>
          </Box>
        )}
      />
    );
  }
);

DelayCodesSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    codeNumeric: PropTypes.string,
    codeDescription: PropTypes.string,
  }),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disableClearable: PropTypes.bool
};

DelayCodesSearch.defaultProps = {
  onChange: () => null,
  value: null,
  label: undefined,
  size: 'small',
  error: false,
  helperText: '',
  required: false,
  disableClearable: false
};

export default DelayCodesSearch;
