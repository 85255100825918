import RotationCardDetail from '../common/RotationCardDetail';
import RotationTurnAroundTimer from '../../../common/rotation-turn-around-timer/RotationTurnAroundTimer';
import {STATUS_COLORS} from '../../../../../utils/rotation/rotation-utils';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {t} from 'ttag';

export const RotationCardTurnAround = ({turnaroundInterval, showTimer}) => (
  <Box sx={styles.turnAround(turnaroundInterval.color)}>
    {showTimer && (
      <RotationCardDetail label={t`TURNAROUND`} alignment={'center'} gap={0}>
        <RotationTurnAroundTimer
          {...turnaroundInterval}
          statusColor={turnaroundInterval.color}
          showIcon={Boolean(turnaroundInterval.color)}
        />
      </RotationCardDetail>
    )}
  </Box>
);

RotationCardTurnAround.propTypes = {
  turnaroundInterval: PropTypes.shape({
    sign: PropTypes.string,
    time: PropTypes.string,
    color: PropTypes.oneOf(Object.values(STATUS_COLORS)),
  }).isRequired,
  showTimer: PropTypes.bool,
}

RotationCardTurnAround.defaultProps = {
  turnaroundInterval: null,
  showTimer: false
}

const styles = {
  turnAround: (statusColor) => ({
    backgroundColor: 'background.turnAround',
    height: '100%',
    display: {xs: 'flow-root', md: 'flex'},
    position: 'relative',
    justifyContent: 'center',
    width: {xs: '3px', md: '100%'},
    pt: 3,
    '&:after': {
      content: '" "',
      backgroundColor: statusColor ? statusColor : 'background.turnAround',
      opacity: statusColor ? 1 : 0.8,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      height: '20px',
      width: '100%',
      top: '-20px',
      position: 'absolute'
    }
  }),
  showTimer: false
};

export default RotationCardTurnAround;
