import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './employeesListHelper';
import {useGetUsersQuery} from '../../../api';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {t} from 'ttag';
import {Close} from '@mui/icons-material';
import {Box, CircularProgress, IconButton, TextField} from '@mui/material';

export const EmployeesList = ({removeHandler, editHandler, actionComponent}) => {
  const timeout = useRef();
  const [page, setPage] = useState(1);
  const [pattern, setPattern] = useState('');
  const [patternInput, setPatternInput] = useState('');
  const {data, error, isFetching} = useGetUsersQuery({page, pattern});

  const handlePatternInput = (value) => {
    setPatternInput(value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setPattern(value);
    }, 750);
  };

  return (
    <>
      <RTKQueryFeedback error={error} />
      <Box display={'flex'} justifyContent={'space-between'} py={2}>
        <TextField
          sx={{maxWidth: '20rem'}}
          label={t`Search`}
          placeholder={'Search employee...'}
          onChange={e => handlePatternInput(e.target.value)}
          value={patternInput}
          InputProps={{
            endAdornment: isFetching
              ? <CircularProgress color='inherit' size={20} />
              : (patternInput ? <IconButton onClick={() => handlePatternInput('')}><Close/></IconButton> : null)
          }}
        />
        {actionComponent}
      </Box>
      <DataGrid
        pagination
        page={page - 1}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode={'server'}
        getRowId={(row) => row.userId}
        loading={isFetching}
        columns={columns({editHandler, removeHandler})}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

EmployeesList.propTypes = {
  setOpenEdit: PropTypes.func,
  setOpenRemove: PropTypes.func,
  actionComponent: PropTypes.any
};

EmployeesList.defaultProps = {
  setOpenEdit: () => null,
  setOpenRemove: () => null,
  actionComponent: null
};

export default EmployeesList;
