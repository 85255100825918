import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Box, TableCell, TableRow, useTheme} from '@mui/material';
import CircularStatus from '../../../../../../../common/CircularStatus';
import {formatDurationWithNoSeconds} from '../../../../../../../../utils/datetime/datetime-utils';

export const FlightBriefingRow = ({flightBriefing: {flight, gap, jot, passengers, specialServiceRequests}}) => {
  const theme = useTheme();
  const time = formatDurationWithNoSeconds(flight.scheduleTime).replace(':', 'h');

  const timeStatusColor = useMemo(() => {
    const {onTime, briefingFirstWarning, warning, delayed} = theme.palette.flightStatus;
    const WARNING_THRESHOLD = -5;
    const doubleJot = 2 * jot;
    if (gap >= doubleJot) return onTime;
    if (doubleJot > gap && gap >= jot) return briefingFirstWarning;
    if (jot > gap && gap > WARNING_THRESHOLD) return warning;
    return delayed;
  }, [gap, jot, theme]);

  return (
    <TableRow>
      <TableCell component='th' scope='row'>
        {flight?.flightNumber}
      </TableCell>
      <TableCell align='center'>{flight?.airport}</TableCell>
      <TableCell align='center'>
        <Box sx={classes.flightDate}>
          {time}
          <Box px={1}>
            <CircularStatus color={timeStatusColor} size={'small'} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align='right'>{passengers}</TableCell>
      <TableCell align='left'>{specialServiceRequests.map((e) => `${e.type}:${e.count}`).join(', ')}</TableCell>
    </TableRow>
  );
};

FlightBriefingRow.propTypes = {
  FlightBriefingRow: PropTypes.shape({
    flight: PropTypes.shape({
      airport: PropTypes.string,
      flightNumber: PropTypes.string,
      scheduleTime: PropTypes.string
    }),
    gap: PropTypes.number,
    jot: PropTypes.number,
    passengers: PropTypes.string,
    specialServiceRequests: PropTypes.shape({
      type: PropTypes.string,
      count: PropTypes.number
    })
  })
};

const classes = {
  flightDate: {
    display: 'flex'
  }
};

export default FlightBriefingRow;
