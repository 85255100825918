import {createMsalInstance} from 'sata-azure-auth';
import {MSA_AUTHORITY, MSA_CLIENT_ID} from '../config/settings';


export const msalInstance = createMsalInstance({
  auth: {
    clientId: MSA_CLIENT_ID,
    authority: MSA_AUTHORITY
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
});

export default msalInstance;
