import * as Yup from 'yup';
import {createDatetime as dt, formatTime} from '../../../../../../utils/datetime/datetime-utils';
import {t} from 'ttag';

export const schema = Yup.object()
  .shape(
    {
      actualTime: Yup.string()
        .required(t`Required field.`)
        .test({
          test: (value) => value && dt(value).isValid,
          message: t`Should be a valid datetime.`
        })
        .when('takeOff', (takeOff, schema) => {
          return schema.test({
            test: (value) => value && takeOff && dt(value) <= dt(takeOff),
            message: t`ATD should be before TAKE OFF.`
          });
        })
        .when('$reliableInbound', (reliableInbound, schema) => {
          return schema.test({
            test: (value) => reliableInbound?.datetimeType !== 'actualTime' || dt(value) > dt(reliableInbound.datetime),
            message: t`ATD should be after ATA.`
          });
        }),
      takeOff: Yup.string()
        .required(t`Required field.`)
        .test({
          test: (value) => value && dt(value).isValid,
          message: t`Should be a valid datetime.`
        })
        .when('actualTime', (actualTime, schema) => {
          return schema.test({
            test: (value) => value && actualTime && dt(value) >= dt(actualTime),
            message: t`TAKE OFF should be after ATD.`
          });
        }),
      flightDuration: Yup.string()
        .required(t`Required field.`)
        .test({
          test: (value) => value && dt(value).isValid,
          message: t`Should be a valid duration.`
        })
        .test({
          test: (value) => formatTime(value, true) !== '00:00',
          message: t`Should be a valid duration.`
        }),
      boardedPax: Yup.number(`Should be a number.`)
        .required(t`Required field.`)
        .typeError('Should be a number.')
        .test({
          test: (value) => !isNaN(value) && value >= 0,
          message: t`Should be greater than zero.`
        })
    },
    ['actualTime', 'takeOff']
  )
  .required();
