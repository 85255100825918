import React, {useState} from 'react';
import {Box, Button, Menu, MenuItem, Typography, useMediaQuery} from '@mui/material';
import {AccountCircle, ExpandLess, ExpandMore} from '@mui/icons-material';
import {t} from 'ttag';
import {useAzureAuth} from 'sata-azure-auth';
import {useNavigate} from 'react-router-dom';

const UserMenu = () => {
  const navigate = useNavigate();
  const {account: {name, username: email}} = useAzureAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const expandIcon = anchorEl ? <ExpandLess sx={classes.expandIcon}/> : <ExpandMore sx={classes.expandIcon}/>;

  return (
    <Box boxShadow={open ? 1 : 0} sx={classes.wrapper}>
      <Button
        variant={'text'}
        sx={classes.root}
        startIcon={<AccountCircle/>}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Box display='flex' alignItems='center' width='100%'>
          {!isMobile && (
            <Box display='flex' flexDirection='column' flexGrow='1' justifyContent='flex-start'>
              <Typography component='p' variant='body2' sx={classes.name} noWrap>
                {name}
              </Typography>
              <Typography component='p' variant='caption' sx={classes.email} noWrap>
                {email}
              </Typography>
            </Box>
          )}
          <Box sx={classes.indicator}>{expandIcon}</Box>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        disablePortal
        PaperProps={{
          elevation: 1,
          sx: classes.popover
        }}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClick={handleClose}
      >
        <MenuItem onClick={() => navigate('/logout')}>{t`Logout`}</MenuItem>
      </Menu>
    </Box>
  );
};

const classes = {
  wrapper: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomWidth: 0
  },
  root: {
    margin: 0,
    height: '3.15rem',
    maxWidth: '14.875rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      paddingRight: theme => theme.spacing(1) / 2,
      '& .MuiSvgIcon-root': {
        fontSize: '2.1875rem'
      }
    }
  },
  smallButton: {
    width: 'auto',
    '& .MuiButton-startIcon': {
      paddingRight: 0,
      marginRight: 0
    }
  },
  name: {
    fontSize: '0.9375rem',
    maxWidth: '7.25rem',
    textTransform: 'none',
    paddingRight: theme => theme.spacing(1 / 2),
    color: 'text.primary'
  },
  email: {
    lineHeight: 1,
    maxWidth: '7.25rem',
    textTransform: 'none',
    textAlign: 'left',
    paddingRight: theme => theme.spacing(1 / 2),
    color: 'text.disabled'
  },
  expandIcon: {
    color: 'primary.main'
  },
  indicator: {
    maxHeight: '2.125rem',
    overflow: 'hidden',
    paddingTop: theme => theme.spacing(1) / 2
  },
  popover: {
    maxWidth: '14.7rem',
    width: '14.875rem',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }
};

export default UserMenu;
