import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, Stack} from '@mui/material';
import TurnaroundDialogHeader from './TurnaroundDialogHeader';
import {t} from "ttag";

export const TurnaroundChecklistDialog = ({onClose, title, subTitle, label, finished, children, open, variant, onCheckAll, ...rest}) => {
  if (!open) return null;

  return (
    <Dialog variant={variant} open={open} onClose={onClose} maxWidth={'xs'} fullWidth onBackdropClick="false" {...rest}>
      <TurnaroundDialogHeader title={title} subTitle={subTitle} />
      <DialogContent>
        <Stack spacing={2}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', paddingBottom: '20px'}}>
          <Button color={'primary'} variant={'contained'} onClick={onClose}>
              {t`Confirm`}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

TurnaroundChecklistDialog.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.any,
  onCancel: PropTypes.func
};

TurnaroundChecklistDialog.defaultProps = {
  open: true,
  variant: 'error',
  title: 'Confirmation',
  subTitle: '',
  onCancel: () => null
};

export default TurnaroundChecklistDialog;
