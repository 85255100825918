import {convertDatetimeFromUTC, convertDatetimeToUTC, convertDuration} from './datetime-mappers';
import {datetimeRecordMapper} from './record-mappers';

/** @type {(operation: Operation, converter: DatetimeConverter) => Operation} */
export const datetimeOperationMapper = (operation, converter) => ({
  ...operation,
  actualTime: converter(operation.actualTime, true),
  estimatedTime: converter(operation.estimatedTime, true),
  scheduledTime: converter(operation.scheduledTime),
  demarcationTime: null,
  delays: operation.delays.map((d) => ({...d, time: convertDuration(d.time)}))
});

/** @type {(departure: Departure, converter: DatetimeConverter) => Departure} */
export const datetimeDepartureMapper = (departure, converter) => ({
  ...departure,
  ...datetimeOperationMapper(departure, converter),
  takeOff: converter(departure.takeOff, true),
  demarcationTime: converter(departure.takeOff, true),
});

/** @type {(arrival: Arrival, converter: DatetimeConverter) => Arrival} */
export const datetimeArrivalMapper = (arrival, converter) => ({
  ...arrival,
  ...datetimeOperationMapper(arrival, converter),
  touchDown: converter(arrival.touchDown, true),
  demarcationTime: converter(arrival.touchDown, true),
});

/** @type {(flight: Flight, converter: DatetimeConverter) => Flight} */
export const datetimeFlightMapper = (flight, converter) => ({
  ...flight,
  departure: datetimeDepartureMapper(flight.departure, converter),
  arrival: datetimeArrivalMapper(flight.arrival, converter),
});

/** @type {(turnAround: TurnAround | ServerTurnAround, converter: DatetimeConverter) => TurnAround | ServerTurnAround} */
export const datetimeTurnAroundMapper = (turnAround, converter) => ({
  ...turnAround,
  // start: convertDatetime(turnAround.start, converter),
  // end: convertDatetime(turnAround.end, converter),
  // interval: convertDuration(turnAround.interval)
});

/** @type {(rotation: Rotation, converter: DatetimeConverter) => Rotation} */
export const datetimeRotationMapper = (rotation, converter) => ({
  ...rotation,
  inbound: rotation.inbound ? datetimeFlightMapper(rotation.inbound, converter) : null,
  outbound: rotation.outbound ? datetimeFlightMapper(rotation.outbound, converter) : null,
  // turnAround: datetimeTurnAroundMapper(rotation.turnAround, converter),
  planTurnAroundTime: rotation.planTurnAroundTime ? convertDuration(rotation.planTurnAroundTime) : null,
  records: rotation.records?.map((record) => (datetimeRecordMapper(record, converter)))
});

/** @type {(rotation: Rotation) => Rotation} */
export const datetimeRotationMapperFromUtc = (rotation) => {
  return datetimeRotationMapper(rotation, convertDatetimeFromUTC);
};

/** @type {(rotation: Rotation) => Rotation} */
export const datetimeRotationMapperToUtc = (rotation) => {
  return datetimeRotationMapper(rotation, convertDatetimeToUTC);
};