import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, Button} from '@mui/material';
import PropTypes from 'prop-types';
import {t} from 'ttag';

const ConfirmDialog = ({onConfirm, onCancel, title, children, confirmText, cancelText, open, variant, reverse, ...rest}) => {

  if (!open) return null;

  return (
    <Dialog title={title} variant={variant} open={open} onClose={onCancel} maxWidth={'xs'} fullWidth {...rest}>
      <DialogContent dividers>
        <Box sx={styles.children}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={styles.dialogActions(reverse)}>
          <Box mx={1}>
            <Button variant={'outlined'} onClick={onCancel}>{cancelText}</Button>
          </Box>
          <Box mx={1}>
            <Button variant={'contained'} type={variant} onClick={onConfirm}>{confirmText}</Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  children: {
    padding: 1,
    fontSize:16,
    fontFamily:'NeoSansProMedium'
  },
  dialogActions: (reverse) => ({
    display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row'
  })
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.string,
  children: PropTypes.any,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  reverse: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  open: true,
  variant: 'error',
  title: t`Confirmation`,
  children: t`Are you sure?`,
  confirmText: t`Confirm`,
  cancelText: t`Cancel`,
  onConfirm: () => null,
  onCancel: () => null,
  reverse: false,
};

export default ConfirmDialog;
