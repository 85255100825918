import React from 'react';
import SidebarLink from './layout/main-layout/SidebarLink';
import {FlightLand, Settings} from '@mui/icons-material';
import {AuthorizationGuard} from './pages/common/guards';
import {ROLES} from './utils/user/user-utils';

export const SidebarMenu = () => (
  <>
    <SidebarLink path={'/rotations'} text={'Rotations'} icon={<FlightLand />} />
    <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN]}>
      <SidebarLink path={'/settings'} text={'Settings'} icon={<Settings />} />
    </AuthorizationGuard>
  </>
);

export default SidebarMenu;
