import React from 'react';
import PropTypes from 'prop-types';
import {Stack, Typography} from '@mui/material';
import CircularStatus from '../../../../common/CircularStatus';
import useOperationAnalyzer from '../../../rotation-hooks/useOperationAnalyzer';
import {createDatetime as dt} from '../../../../../utils/datetime/datetime-utils';

export const RotationViewFlightSummaryStatus = ({operation, toggleComponent}) => {
  const operationStatus = useOperationAnalyzer(operation);
  const {actualTime, scheduledTime} = operation;
  return (
    <Stack direction={'row'} gap={0.6}>
      <CircularStatus
        color={operationStatus.color}
        char={operation.delays?.length}
        excludedChars={[0]}
        size={'small'}
      />
      <Typography variant={'body2'} color={operationStatus.color}>
        {operationStatus.name}
      </Typography>
      {(actualTime && dt(actualTime) > dt(scheduledTime)) && toggleComponent}
    </Stack>
  );
};

RotationViewFlightSummaryStatus.propTypes = {
  operation: PropTypes.shape({
    delays: PropTypes.array.isRequired
  }),
  toggleComponent: PropTypes.any,
};

export default RotationViewFlightSummaryStatus;
