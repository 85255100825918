import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Unauthorized, Forbidden, NotFound} from './pages/common/errors';
import Settings, {Employees, Roles} from './pages/settings';
import {Login, Logout} from './pages/common/authentication';
import Page from './pages/common/page/Page';
import Rules from "./pages/settings/rules/Rules";
import AppInitProvider from './utils/providers/AppInitProvider';
import {AuthenticationGuard} from './pages/common/guards';
import Rotations from './pages/rotations/Rotations';
import {ROLES} from './utils/user/user-utils';


export const App = () => (
  <BrowserRouter>
    <AuthenticationGuard>
      <AppInitProvider/>
    </AuthenticationGuard>
    <Routes>
      <Route path={'/'} element={<Navigate to='/rotations'/>} exact />
      <Route path={'/rotations'} element={<Page><Rotations/></Page>} exact />
      <Route path={'/settings'} element={<Navigate to='/settings/rules'/>} exact />
      <Route path={'/settings'} element={<Page userHasSomeOf={[ROLES.ADMIN]}><Settings/></Page>}>
        <Route path={'rules'} element={<Rules/>} />
        <Route path={'employees'} element={<Employees/>} />
        <Route path={'roles'} element={<Roles/>} />
      </Route>
      <Route path={'/401'} element={<Unauthorized/>} exact />
      <Route path={'/403'} element={<Forbidden/>} exact />
      <Route path={'/404'} element={<NotFound/>} exact />
      <Route path={'/auth'} element={<Login/>} exact />
      <Route path={'/logout'} element={<Logout/>} exact />
      <Route path={'*'} element={<Navigate to='/'/>} />
    </Routes>
  </BrowserRouter>
);

export default App;
