export const delays = [
  {
    codeNumeric: '02',
    codeDescription: 'Simultaneous Flights.',
    subDelayCodes: []
  },
  {
    codeNumeric: '03',
    codeDescription: 'NON-COMPLIANCE with Minimum Time Rotation.',
    subDelayCodes: []
  },
  {
    codeNumeric: '04',
    codeDescription: 'Stretcher',
    subDelayCodes: [
      {
        code: '04A',
        description: 'Boarding'
      },
      {
        code: '04B',
        description: 'Deboarding'
      },
      {
        code: '04C',
        description: 'Late arrival of the ambulance'
      }
    ]
  },
  {
    codeNumeric: '05',
    codeDescription: 'Whellchair',
    subDelayCodes: []
  },
  {
    codeNumeric: '06',
    codeDescription: 'No gate/stand availability due to own airline activity',
    subDelayCodes: []
  },
  {
    codeNumeric: '09',
    codeDescription: 'Scheduled ground time less than declared minimum ground time',
    subDelayCodes: []
  },
  {
    codeNumeric: '11',
    codeDescription: 'Late Check-in, acceptance of passengers after deadline',
    subDelayCodes: []
  },
  {
    codeNumeric: '12',
    codeDescription: 'Late Check-in, congestion in check-in area',
    subDelayCodes: []
  },
  {
    codeNumeric: '13',
    codeDescription: 'Check-in error',
    subDelayCodes: [
      {
        code: '13A',
        description: 'Check-In Error by Agent'
      },
      {
        code: '13B',
        description: 'Self Check-In Error'
      }
    ]
  },
  {
    codeNumeric: '14',
    codeDescription: 'Oversales, booking errors',
    subDelayCodes: [
      {
        code: '14A',
        description: 'Oversales / Overbooking'
      },
      {
        code: '14B',
        description: 'Booking Error'
      },
      {
        code: '14C',
        description: 'Voluntary Denied Boarding Passengers'
      }
    ]
  },
  {
    codeNumeric: '15',
    codeDescription: 'Boarding, discrepancies and paging, missing checked-in passenger at gate',
    subDelayCodes: [
      {
        code: '15A',
        description: 'Boarding Error / Slow Boarding'
      },
      {
        code: '15B',
        description: 'Late Boarding Start'
      },
      {
        code: '15C',
        description: 'Late Boarding of VIP Passenger / UMNR / STCR'
      },
      {
        code: '15D',
        description: 'Late Bus Order / Release by Gate'
      },
      {
        code: '15E',
        description: 'Carry on Luggage Denied at gate due to exceeding limits (oversize/weight)'
      },
      {
        code: '15F',
        description: 'Lack of Staff'
      }
    ]
  },
  {
    codeNumeric: '16',
    codeDescription: 'Commercial Publicity, Passenger Convenience, VIP, Press, Ground meals and missing personal items',
    subDelayCodes: [
      {
        code: '16A',
        description: 'VIP / UM Boarding / Late Acceptance Commercial Reasons'
      },
      {
        code: '16B',
        description: 'Airport'
      },
      {
        code: '16C',
        description: 'Illness / Death Passenger'
      },
      {
        code: '16D',
        description: 'Passenger Requested Offload'
      },
      {
        code: '16E',
        description: 'Missing Personal Items'
      },
      {
        code: '16F',
        description: 'Carry on Luggage'
      },
      {
        code: '16G',
        description: 'Carry on Luggage Accommodation on Board'
      },
      {
        code: '16H',
        description: 'High Number of Passenger Missing'
      },
      {
        code: '16I',
        description: 'Slow Pax/Baggage Accommodation on Board'
      }
    ]
  },
  {
    codeNumeric: '17',
    codeDescription: 'Catering order, late or incorrect order given to supplier',
    subDelayCodes: [
      {
        code: '17A',
        description: 'Pax Meals'
      },
      {
        code: '17B',
        description: 'Crew Meals'
      }
    ]
  },
  {
    codeNumeric: '18',
    codeDescription: 'Baggage processing, sorting, etc.',
    subDelayCodes: [
      {
        code: '18A',
        description: 'Late Delivery Local Baggage'
      },
      {
        code: '18B',
        description: 'Late Delivery Transfer Baggage'
      },
      {
        code: '18C',
        description: 'Late Delivery Bulky Baggage'
      },
      {
        code: '18D',
        description: 'Lack of Staff'
      },
      {
        code: '18E',
        description: 'Rushbags Irregularities'
      }
    ]
  },
  {
    codeNumeric: '19',
    codeDescription: 'Reduced Mobility, Boarding/Deboarding of passengers with reduced mobility',
    subDelayCodes: [
      {
        code: '19A',
        description: 'Boarding Late/Slow'
      },
      {
        code: '19B',
        description: 'Deboarding Late/Slow'
      },
      {
        code: '19C',
        description: 'High Number of PRM Boarding'
      },
      {
        code: '19D',
        description: 'High Number of PRM Deboarding'
      },
      {
        code: '19E',
        description: 'Late / Lack / Error of PRM Transport'
      },
      {
        code: '19F',
        description: 'Late / Lack of Ambulance'
      },
      {
        code: '19G',
        description: 'Late / Lack of PRM Staff'
      }
    ]
  },
  {
    codeNumeric: '21',
    codeDescription: 'Documentation, errors, etc.',
    subDelayCodes: [
      {
        code: '21A',
        description: 'Late Cargo documentation'
      },
      {
        code: '21B',
        description: 'Late Load Release'
      },
      {
        code: '21C',
        description: 'Wrong/Late Load Advice'
      },
      {
        code: '21D',
        description: 'Load Control with Incorrect/Late Cargo Information'
      }
    ]
  },
  {
    codeNumeric: '22',
    codeDescription: 'LATE POSITIONING.',
    subDelayCodes: [
      {
        code: '22A',
        description: 'By Cargo Loader'
      },
      {
        code: '22B',
        description: 'Due to Security Issues'
      }
    ]
  },
  {
    codeNumeric: '23',
    codeDescription: 'Late acceptance',
    subDelayCodes: [
      {
        code: '23A',
        description: 'Late Acceptance'
      },
      {
        code: '23B',
        description: 'Special Request Acceptance'
      },
      {
        code: '23C',
        description: 'Late Release by Customs'
      },
      {
        code: '23D',
        description: 'Wrong / Missing Documentation by Shipper'
      },
      {
        code: '23E',
        description: 'Late Positioning due to Security Check-Point'
      }
    ]
  },
  {
    codeNumeric: '24',
    codeDescription: 'Inadequate packing',
    subDelayCodes: [
      {
        code: '24A',
        description: 'Inadequate Packing'
      },
      {
        code: '24B',
        description: 'Incorrect Build-Up'
      }
    ]
  },
  {
    codeNumeric: '25',
    codeDescription: 'Oversales, booking errors',
    subDelayCodes: []
  },
  {
    codeNumeric: '26',
    codeDescription: 'Late preparation in warehouse',
    subDelayCodes: [
      {
        code: '26A',
        description: 'Cargo Lack of Staff'
      },
      {
        code: '26B',
        description: 'Cargo Lack of Equipment'
      },
      {
        code: '26C',
        description: 'Cargo Facilities Limitations'
      },
      {
        code: '26D',
        description: 'Palletising Erros'
      },
      {
        code: '26E',
        description: 'Cargo Volume Limitations'
      }
    ]
  },
  {
    codeNumeric: '27',
    codeDescription: 'Mail Oversales, packing, etc.',
    subDelayCodes: [
      {
        code: '27A',
        description: 'Mail Volume Limitations'
      },
      {
        code: '27B',
        description: 'Load Control with Incorrect/Late Mail Information'
      },
      {
        code: '27C',
        description: 'Wrong / Missing Mail Documentation'
      }
    ]
  },
  {
    codeNumeric: '28',
    codeDescription: 'Mail Late positioning',
    subDelayCodes: []
  },
  {
    codeNumeric: '29',
    codeDescription: 'Mail Late acceptance',
    subDelayCodes: []
  },
  {
    codeNumeric: '31',
    codeDescription: 'Aircraft documentation late or inaccurate, weight and balance (Loadsheet), general declaration, passenger manifest, etc.',
    subDelayCodes: [
      {
        code: '31A',
        description: 'Late / Incorrect Documentation (Handler)'
      },
      {
        code: '31B',
        description: 'Late / Incorrect Loadsheet'
      },
      {
        code: '31C',
        description: 'Repositioning of Passengers / Deadload for Balance Reasons'
      },
      {
        code: '31D',
        description: 'Lack of or Late by Handling Coordinator / Ramp Supervisor'
      },
      {
        code: '31E',
        description: 'Local Issues for CLC stations'
      }
    ]
  },
  {
    codeNumeric: '32',
    codeDescription: 'Loading, Unloading, bulky/special load, cabin load, lack of loading staff',
    subDelayCodes: [
      {
        code: '32A',
        description: 'Bulky, Special Load, Excessive Load'
      },
      {
        code: '32B',
        description: 'Volumetric / Space Problems / Cabin Load'
      },
      {
        code: '32C',
        description: 'Incorrect / Error Loading'
      },
      {
        code: '32D',
        description: 'Late Setup / Removal of a Stretcher'
      },
      {
        code: '32E',
        description: 'Late Loading End'
      },
      {
        code: '32F',
        description: 'Late / Lack of Loading'
      }
    ]
  },
  {
    codeNumeric: '33',
    codeDescription: 'Loading Equipment, lack of or breakdown, e.g. container pallet loader, lack of staff',
    subDelayCodes: []
  },
  {
    codeNumeric: '34',
    codeDescription: 'Servicing Equipment, lack of or breakdown, lack of staff, e.g. steps',
    subDelayCodes: [
      {
        code: '34A',
        description: 'Late / Incorrect Operation'
      },
      {
        code: '34B',
        description: 'Late / Lack of / Breakdown / Incorrect Operation of Pax Stairs or Jetbridge'
      },
      {
        code: '34C',
        description: 'Late / Lack of / Breakdown / Error of Passengers Bus'
      },
      {
        code: '34D',
        description: 'Late / Lack of / breakdown / Error by Water or Toilet Service'
      },
      {
        code: '34E',
        description: 'Late / Lack of / Breakdown / Error of Crew Bus'
      },
      {
        code: '34F',
        description: 'Late / Lack of / Breakdown / Incorrect Operation of Airstarter'
      }
    ]
  },
  {
    codeNumeric: '35',
    codeDescription: 'Aircraft Cleaning',
    subDelayCodes: [
      {
        code: '35A',
        description: 'Cabin Cleaning'
      },
      {
        code: '35B',
        description: 'Late or Lack of Cabin Supplies'
      },
      {
        code: '35C',
        description: 'Additional or Special Cleaning Required'
      },
      {
        code: '35D',
        description: 'Late Cleaning due to A/C Change'
      },
      {
        code: '35E',
        description: 'Lack of Cleaning Staff'
      }
    ]
  },
  {
    codeNumeric: '36',
    codeDescription: 'Fuelling, Defuelling, fuel supplier',
    subDelayCodes: [
      {
        code: '36A',
        description: 'Late / Lack / Breakdown / Error of fuel truck'
      },
      {
        code: '36B',
        description: 'Defuelling'
      },
      {
        code: '36C',
        description: 'Fuelling / Defuelling with Passengers on Board due to Lack of the Necessary Conditions'
      }
    ]
  },
  {
    codeNumeric: '37',
    codeDescription: 'Catering, late delivery or loading',
    subDelayCodes: [
      {
        code: '37A',
        description: 'Late / Slow Catering Service'
      },
      {
        code: '37B',
        description: 'Catering Discrepancies'
      },
      {
        code: '37C',
        description: 'Wrong/ Insufficient / Breakdown Catering Equipment'
      },
      {
        code: '37D',
        description: 'Late Catering due to A/C Change'
      },
      {
        code: '37E',
        description: 'Late / Lack of Catering and Supplier Staff'
      },
      {
        code: '37F',
        description: 'Late / Lack Catering Inflight Services'
      },
      {
        code: '37G',
        description: 'Late / Lack Inflight Products'
      }
    ]
  },
  {
    codeNumeric: '38',
    codeDescription: 'ULD, Containers, pallets, lack of or breakdown',
    subDelayCodes: []
  },
  {
    codeNumeric: '39',
    codeDescription: 'Technical equipment, lack of or breakdown, lack of staff, e.g. pushback',
    subDelayCodes: [
      {
        code: '39A',
        description: 'Late / Lack / Breakdown GPU'
      },
      {
        code: '39B',
        description: 'Late / Lack / Breakdown Push Back / Towbar'
      },
      {
        code: '39C',
        description: 'Late / Lack / Breakdown Air Starter, Air Conditioner, ASU'
      },
      {
        code: '39D',
        description: 'Late/ Lack / Breakdown De-icing Equipment'
      }
    ]
  },
  {
    codeNumeric: '41',
    codeDescription: 'Aircraft defects',
    subDelayCodes: [
      {
        code: '41A',
        description: 'At Home Base'
      },
      {
        code: '41B',
        description: 'At Outside Stations'
      },
      {
        code: '41C',
        description: 'Fault not confirmed'
      }
    ]
  },
  {
    codeNumeric: '42',
    codeDescription: 'Scheduled maintenance, late release',
    subDelayCodes: [
      {
        code: '42A',
        description: 'Late Release due to Minimum Ground Time Rotation'
      },
      {
        code: '42B',
        description: 'Late Release due to Internal Inadequate Processes'
      },
      {
        code: '42C',
        description: 'Late Release due to Procedures Required by MEL'
      },
      {
        code: '42D',
        description: 'Lack of Maintance Staff'
      }
    ]
  },
  {
    codeNumeric: '43',
    codeDescription: 'Non-scheduled maintenance, special checks and / or additional works beyond normal maintenance',
    subDelayCodes: [
      {
        code: '43A',
        description: 'Mandatory Works'
      },
      {
        code: '43B',
        description: 'Replacement of Cabin Items'
      },
      {
        code: '43C',
        description: 'Replacement of Oxigen'
      },
      {
        code: '43D',
        description: 'Servicing'
      }
    ]
  },
  {
    codeNumeric: '44',
    codeDescription: 'Spares and maintenance equipment, lack of or breakdown',
    subDelayCodes: [
      {
        code: '44A',
        description: 'Lack / Breakdown of Spares'
      },
      {
        code: '44B',
        description: 'Lack / Breakdown of Maintenance Equipment'
      }
    ]
  },
  {
    codeNumeric: '45',
    codeDescription: 'AOG (Aircraft on ground for technical reasons) Spares, to be carried to another station',
    subDelayCodes: []
  },
  {
    codeNumeric: '46',
    codeDescription: 'Aircraft change for technical reasons',
    subDelayCodes: [
      {
        code: '46A',
        description: 'Due to Aircraft Defects'
      },
      {
        code: '46B',
        description: 'Due to Late Delivery from Maintenance'
      },
      {
        code: '46C',
        description: 'Due to Lack of Spares'
      }
    ]
  },
  {
    codeNumeric: '47',
    codeDescription: 'Standby aircraft, lack of planned standby aircraft for technical reasons',
    subDelayCodes: []
  },
  {
    codeNumeric: '48',
    codeDescription: 'Scheduled cabin configuration and version adjustment',
    subDelayCodes: []
  },
  {
    codeNumeric: '49',
    codeDescription: 'Operational Impact due to A/C deviation / constraint.',
    subDelayCodes: []
  },
  {
    codeNumeric: '51',
    codeDescription: 'Damage during flight operations, bird or lightning strike, turbulence, heavy or overweight landing',
    subDelayCodes: [
      {
        code: '51A',
        description: 'Bird Trike / Lightning Strike'
      },
      {
        code: '51B',
        description: 'Foreign Object Damage (FOD) / Compartment Contamination'
      },
      {
        code: '51C',
        description: 'Passenger'
      },
      {
        code: '51D',
        description: 'Heavy or Overweight Landing'
      },
      {
        code: '51E',
        description: 'Collision during Taxiing'
      },
      {
        code: '51F',
        description: 'Replacement of Cabin Items'
      },
      {
        code: '51G',
        description: 'Reconfiguration of Cabin'
      }
    ]
  },
  {
    codeNumeric: '52',
    codeDescription: 'Damage during ground operations, collisions (other than during taxiing, loading/offloading damage, contamination, towing, extreme weather conditions',
    subDelayCodes: [
      {
        code: '52A',
        description: 'Collision (other than during taxiing), Towing'
      },
      {
        code: '52B',
        description: 'Damage by Ground Handling Equipment / Staff'
      },
      {
        code: '52C',
        description: 'Damage by Previous Station or Unknown'
      },
      {
        code: '52D',
        description: 'Maintenance and / or Aircraft Change after Damage during Ground Operations by third Party'
      },
      {
        code: '52E',
        description: 'Contamination or Extreme Weather Conditions'
      }
    ]
  },
  {
    codeNumeric: '55',
    codeDescription: 'Departure Control System, Check-in, weight and balance (loadcontrol), computer system error, baggage sorting, gate-reader error or problems',
    subDelayCodes: [
      {
        code: '55A',
        description: 'Check-In System'
      },
      {
        code: '55B',
        description: 'Weight and Balance System'
      },
      {
        code: '55C',
        description: 'Station Control Systems e.g. AIMS'
      },
      {
        code: '55D',
        description: 'Regulatory systems e.g. APIS, baggage messages'
      },
      {
        code: '55E',
        description: 'Local DSI Equipment e.g. PC monitors, PC hardware'
      },
      {
        code: '55F',
        description: 'Gate Reader'
      },
      {
        code: '55G',
        description: 'Network / System Interface'
      }
    ]
  },
  {
    codeNumeric: '56',
    codeDescription: 'Cargo preparation/documentation system',
    subDelayCodes: []
  },
  {
    codeNumeric: '57',
    codeDescription: 'Flight plans',
    subDelayCodes: []
  },
  {
    codeNumeric: '58',
    codeDescription: 'Other computer systems',
    subDelayCodes: [
      {
        code: '58A',
        description: 'Airport Systems'
      },
      {
        code: '58B',
        description: 'Handling Agents Systems'
      },
      {
        code: '58C',
        description: 'Baggage Systems'
      },
      {
        code: '58D',
        description: 'Maintenance Systems'
      },
      {
        code: '58E',
        description: 'EFB - Electronic Flight Bag'
      },
      {
        code: '58F',
        description: 'Communication Break Down'
      }
    ]
  },
  {
    codeNumeric: '61',
    codeDescription: 'Flight plan, late completion or change of flight documentation',
    subDelayCodes: [
      {
        code: '61A',
        description: 'Change / Amendment of Flight Plan Excluding weather reasons'
      },
      {
        code: '61B',
        description: 'Late or Incorrect Flight Dispatch docs'
      },
      {
        code: '61C',
        description: 'EFB Laptop / Paper Charts Missing or Invalid'
      },
      {
        code: '61D',
        description: 'Late Delivery of Flight Documents to Cockpit'
      }
    ]
  },
  {
    codeNumeric: '62',
    codeDescription: 'Operational requirements, fuel, load alteration',
    subDelayCodes: [
      {
        code: '62A',
        description: 'Additional Request of Fuel Truck'
      },
      {
        code: '62B',
        description: 'Load Alterations'
      },
      {
        code: '62C',
        description: 'Late fuel information'
      }
    ]
  },
  {
    codeNumeric: '63',
    codeDescription: 'Late crew boarding or departure procedures',
    subDelayCodes: [
      {
        code: '63A',
        description: 'Cockpit Crew Late Boarding'
      },
      {
        code: '63B',
        description: 'Entire Crew Late Boarding'
      },
      {
        code: '63C',
        description: 'Cockpit Crew or Entire Crew Late Boarding due to Late Crew Bus'
      },
      {
        code: '63D',
        description: 'Late Crew Pick Up'
      },
      {
        code: '63E',
        description: 'Airport Access Congestion / Traffic Jam'
      },
      {
        code: '63F',
        description: 'Crew Scheduling or Crew Control Error'
      },
      {
        code: '63G',
        description: 'Holding / Delaying Pax Boarding by Crew'
      },
      {
        code: '63H',
        description: 'Flight Departure Procedures within Operational Requirements'
      },
      {
        code: '63I',
        description: 'Previous Crew Late Deboarding'
      }
    ]
  },
  {
    codeNumeric: '64',
    codeDescription: 'Flight deck crew shortage, Crew rest',
    subDelayCodes: [
      {
        code: '64A',
        description: 'Awaiting Standby Crew e.g. sickness, travel documents'
      },
      {
        code: '64B',
        description: 'Flight Time Limitations / Minimum Crew Rest'
      },
      {
        code: '64C',
        description: 'Crew Meals'
      }
    ]
  },
  {
    codeNumeric: '65',
    codeDescription: 'Flight deck crew special request or error',
    subDelayCodes: [
      {
        code: '65A',
        description: 'Special Request'
      },
      {
        code: '65B',
        description: 'Not confirm third party intervention'
      }
    ]
  },
  {
    codeNumeric: '66',
    codeDescription: 'Late cabin crew boarding or departure procedures',
    subDelayCodes: [
      {
        code: '66A',
        description: 'Cabin Crew Late Boarding Whenever the Cabin Crew is late onboard'
      },
      {
        code: '66B',
        description: 'Cabin Crew Late Boarding due to Late Crew Bus Only applicable if crew on time at crew bus station according to standard process time'
      },
      {
        code: '66C',
        description: 'Boarding Congestion into Aircraft e.g. slow seating'
      },
      {
        code: '66D',
        description: 'Repositioning of non Excessive Baggage'
      },
      {
        code: '66E',
        description: 'Cabin Departure Preparations with Reduced Cabin Crew Members'
      },
      {
        code: '66F',
        description: 'Cabin Departure Procedures'
      }
    ]
  },
  {
    codeNumeric: '67',
    codeDescription: 'Cabin crew shortage',
    subDelayCodes: [
      {
        code: '67A',
        description: 'Awaiting Standby Crew e.g. sickness, travel documents'
      },
      {
        code: '67B',
        description: 'Flight Time Limitations / Minimum Crew Rest'
      },
      {
        code: '67C',
        description: 'lack of verification Crew meals'
      }
    ]
  },
  {
    codeNumeric: '68',
    codeDescription: 'Cabin crew error or special request',
    subDelayCodes: [
      {
        code: '68A',
        description: 'Incorrect Head Count Whenever there is a wrong head check count'
      },
      {
        code: '68B',
        description: 'Re-order or Late Request'
      },
      {
        code: '68C',
        description: 'Special Request of non Standard Supplies e.g. blankets, pillows'
      },
      {
        code: '68D',
        description: 'Galley / Meal Check In'
      },
      {
        code: '68E',
        description: 'Evacuation slide deployed'
      }
    ]
  },
  {
    codeNumeric: '69',
    codeDescription: 'Captain request for security check, extraordinary',
    subDelayCodes: []
  },
  {
    codeNumeric: '71',
    codeDescription: 'Departure station',
    subDelayCodes: []
  },
  {
    codeNumeric: '72',
    codeDescription: 'Destination station',
    subDelayCodes: []
  },
  {
    codeNumeric: '73',
    codeDescription: 'Enroute or Alternate',
    subDelayCodes: []
  },
  {
    codeNumeric: '75',
    codeDescription: 'De-Icing of aircraft, removal of ice/snow, frost prevention',
    subDelayCodes: [
      {
        code: '75A',
        description: 'De / Anti-icing Deicing on stand'
      },
      {
        code: '75B',
        description: 'Lack / Breakdown of Equipment (if no equipment available)'
      },
      {
        code: '75C',
        description: 'Fan Blade Deicing / Clean Wing Check / Removal of Snow'
      }
    ]
  },
  {
    codeNumeric: '76',
    codeDescription: 'Removal of snow/ice/water/sand from airport/runway',
    subDelayCodes: []
  },
  {
    codeNumeric: '77',
    codeDescription: 'Aircraft ground handling impaired by adverse weather conditions',
    subDelayCodes: []
  },
  {
    codeNumeric: '81',
    codeDescription: 'ATC restriction en-route or capacity',
    subDelayCodes: [
      {
        code: '81A',
        description: 'Weather'
      },
      {
        code: '81B',
        description: 'High demand or capacity'
      },
      {
        code: '81C',
        description: 'ATC Routing'
      }
    ]
  },
  {
    codeNumeric: '82',
    codeDescription: 'ATC restriction due to staff shortage or equipment failure en-route',
    subDelayCodes: [
      {
        code: '82A',
        description: 'Staff shortage'
      },
      {
        code: '82B',
        description: 'Equipment failure'
      },
      {
        code: '82C',
        description: 'Industrial action'
      }
    ]
  },
  {
    codeNumeric: '83',
    codeDescription: 'ATC restriction at destination',
    subDelayCodes: [
      {
        code: '83A',
        description: 'High Demand / Airport facilities, e.g. runway closed'
      },
      {
        code: '83B',
        description: 'Noise abatement / night curfew'
      },
      {
        code: '83C',
        description: 'Staff shortage'
      },
      {
        code: '83D',
        description: 'Equipment failure'
      },
      {
        code: '83E',
        description: 'Industrial action'
      }
    ]
  },
  {
    codeNumeric: '84',
    codeDescription: 'ATC restriction due to weather at destination',
    subDelayCodes: []
  },
  {
    codeNumeric: '85',
    codeDescription: 'Mandatory security',
    subDelayCodes: [
      {
        code: '85A',
        description: 'Security Checkpoints of Crew Security, Extended Waiting Time at Staff  and Servicing / Commodity Checkpoints'
      },
      {
        code: '85B',
        description: 'X-Ray Baggage Scanning'
      },
      {
        code: '85C',
        description: 'Baggage Identification / Profiling / Unloading'
      },
      {
        code: '85D',
        description: 'X-Ray Passenger Scanning'
      },
      {
        code: '85E',
        description: 'Unruly Passenger'
      },
      {
        code: '85F',
        description: 'Passenger Profiling'
      },
      {
        code: '85G',
        description: 'Passenger Security Control'
      },
      {
        code: '85H',
        description: 'Aircraft Security Search'
      },
      {
        code: '85I',
        description: 'Airport / Terminal Security'
      },
      {
        code: '85J',
        description: 'Additional Mandatory Security - Airport Authority'
      }
    ]
  },
  {
    codeNumeric: '86',
    codeDescription: 'Immigration, Customs, Health',
    subDelayCodes: [
      {
        code: '86A',
        description: 'Deboarding Pax Documentation Check'
      },
      {
        code: '86B',
        description: 'Boarding Pax Documentation Check'
      },
      {
        code: '86C',
        description: 'Late / Lack / Error of Documentation-Check Staff'
      },
      {
        code: '86D',
        description: 'Extended Check / Insufficient Travel Documentation / INAD / Deportee by Authorities'
      },
      {
        code: '86E',
        description: 'Inspection by Civil Aviation Authority'
      },
      {
        code: '86F',
        description: 'Disinfection of Aircraft'
      },
      {
        code: '86G',
        description: 'Health (in case of measures required by authorities or airline process)'
      },
      {
        code: '86H',
        description: 'Crew documentation Check'
      }
    ]
  },
  {
    codeNumeric: '87',
    codeDescription: 'Airport Facilities, parking stands, ramp congestion, buildings, gate limitations, …',
    subDelayCodes: [
      {
        code: '87A',
        description: 'Late Arrival or Lack of Follow Me Vehicle'
      },
      {
        code: '87B',
        description: 'Late / Lack of Parking Stands'
      },
      {
        code: '87C',
        description: 'Service road restriction'
      },
      {
        code: '87D',
        description: 'Airfield Electrical System Failure e.g. fixed electrical ground power'
      },
      {
        code: '87E',
        description: 'Jet bridge inoperative'
      },
      {
        code: '87F',
        description: 'Ramp Congestion or Abnormal Stand Access Limitation'
      },
      {
        code: '87G',
        description: 'Passenger Transport System Failure'
      },
      {
        code: '87H',
        description: 'Breakdown of Airport Fueling System'
      },
      {
        code: '87I',
        description: 'No Push Back Clearance due to Infrastructure'
      },
      {
        code: '87J',
        description: 'Baggage Sorting System Down / Slow'
      },
      {
        code: '87K',
        description: 'Gate Limitation / No Gate Available'
      },
      {
        code: '87L',
        description: 'Buildings (also in case of door / elevator/ escalator operation)'
      },
      {
        code: '87M',
        description: 'Lack of check-in counters/baggage sorting'
      }
    ]
  },
  {
    codeNumeric: '88',
    codeDescription: 'Restrictions at airport of destination, airport/runway closed due obstruction, industrial record, staff shortage, political unrest, noise abatement, night curfew, special flights, …',
    subDelayCodes: []
  },
  {
    codeNumeric: '89',
    codeDescription: 'Restrictions at airport of departure, airport/runway closed due obstruction, industrial record, staff shortage, political unrest, noise abatement, night curfew, special flights, start-up and pushback, …',
    subDelayCodes: []
  },
  {
    codeNumeric: '91',
    codeDescription: 'Passenger or Load Connection, awaiting load or passengers from another flight. Protection of stranded passengers onto a new flight.',
    subDelayCodes: []
  },
  {
    codeNumeric: '92',
    codeDescription: 'Through Check-in error',
    subDelayCodes: [
      {
        code: '92A',
        description: 'Through Check-In Error passenger'
      },
      {
        code: '92B',
        description: 'Through check-in error baggage'
      },
      {
        code: '92C',
        description: 'Through check-in error System'
      },
      {
        code: '92D',
        description: 'Incorrect baggage loading at the previous station'
      }
    ]
  },
  {
    codeNumeric: '93',
    codeDescription: 'Aircraft rotation',
    subDelayCodes: []
  },
  {
    codeNumeric: '94',
    codeDescription: 'Cabin crew rotation',
    subDelayCodes: []
  },
  {
    codeNumeric: '95',
    codeDescription: 'Crew rotation (entire or cockpit crew)',
    subDelayCodes: [
      {
        code: '95A',
        description: 'Entire Crew'
      },
      {
        code: '95B',
        description: 'Only Cockpit Crew'
      }
    ]
  },
  {
    codeNumeric: '96',
    codeDescription: 'Operations control, rerouting, diversion, consolidation, aircraft change for reasons other than technical',
    subDelayCodes: [
      {
        code: '96A',
        description: 'Aircraft Change / Equipment change for Reasons other than Technical'
      },
      {
        code: '96B',
        description: 'Secondary technical reason'
      },
      {
        code: '96C',
        description: 'Operations Control, Rerouting, diversion, consolidation'
      }
    ]
  },
  {
    codeNumeric: '97',
    codeDescription: 'Industrial record within own airline',
    subDelayCodes: []
  },
  {
    codeNumeric: '98',
    codeDescription: 'Industrial record outside own airline',
    subDelayCodes: []
  },
  {
    codeNumeric: '99',
    codeDescription: 'Miscellaneous, not elsewhere specified',
    subDelayCodes: []
  }
];

export default delays;
