import LoadAndUnloadSupervisionFile from "../../resources/regulatory_check_load_unload_supervision.pdf";
import PassengerSupervisionFile from "../../resources/regulatory_check_passenger_supervision.pdf";
import TurnaroundSupervisionFile from "../../resources/regulatory_check_turnaround_supervision.pdf";

export const ACTION_ITEMS = {
    LOAD_AND_UNLOAD_SUPERVISION: 'Load/Unload Supervision',
    PASSENGER_SUPERVISION: 'Passenger Supervision',
    TURNAROUND_SUPERVISION: 'Turnaround Supervision',
};

export const regulationCheckFileByActionItemName = (itemName) => {
    switch (itemName) {
        case ACTION_ITEMS.LOAD_AND_UNLOAD_SUPERVISION:
            return LoadAndUnloadSupervisionFile
        case ACTION_ITEMS.PASSENGER_SUPERVISION:
            return PassengerSupervisionFile
        case ACTION_ITEMS.TURNAROUND_SUPERVISION:
            return TurnaroundSupervisionFile
        default:
            return null
    }
}