import React, {useState} from 'react';
import {useGetRolesQuery} from '../../../api';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {columns} from './rolesListHelper';
import {DataGrid} from '@mui/x-data-grid';


export const RolesList = ({editHandler, deleteHandler}) => {
  const [page, setPage] = useState(1);
  const {data = [], error, isFetching} = useGetRolesQuery({page});
  return (
    <>
      <RTKQueryFeedback error={error}/>
      <DataGrid
        pagination
        page={(page - 1)}
        rowCount={data?.count || 0}
        onPageChange={(newPage) => setPage(newPage + 1)}
        paginationMode={'server'}
        getRowId={(row) => row.roleId}
        loading={isFetching}
        columns={columns({editHandler, removeHandler: deleteHandler})}
        rows={data?.data || []}
        autoHeight
        autoPageSize
        pageSize={data?.pagination?.rowsPerPage || 5}
        disableExtendRowFullWidth={true}
      />
    </>
  );
};

RolesList.propTypes = {};

RolesList.defaultProps = {};

export default RolesList;
