import {t} from 'ttag';
import {Box, Dialog, Typography, useMediaQuery} from '@mui/material';
import RotationViewDialogContent from './resources/rotation-view-dialog/RotationViewDialogContent';
import RotationViewDialogHeader from './resources/rotation-view-dialog/RotationViewDialogHeader';
import {actions, useStore} from '../../../store/valtio';

const {resetRotationAndTab} = actions.rotations.rotationView;

export const RotationViewDialogConnected = () => {
  const {
    rotations: {
      rotationView: {rotation, tab}
    }
  } = useStore();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  if (!rotation) return null;
  return (
    <Dialog onClose={resetRotationAndTab} maxWidth={'md'} fullWidth open fullScreen={isTablet}>
      <RotationViewDialogHeader
        title={`${rotation.aircraft?.tail} ${rotation.aircraft?.code}`}
        subTitle={rotation.operationDay}
        onClose={resetRotationAndTab}
      >
        {rotation?.inRecovery && (
          <Box
            ml={1}
            color={'warning.contrastText'}
            borderRadius={1}
            padding={1}
            backgroundColor={'flightStatus.warning'}
          >
            <Typography variant={'h1'} fontSize={14} fontWeight={'bolder'}>{t`IN RECOVERY`}</Typography>
          </Box>
        )}
      </RotationViewDialogHeader>
      <RotationViewDialogContent rotation={rotation} tab={tab} />
    </Dialog>
  );
};

export default RotationViewDialogConnected;
