import {t} from 'ttag';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {Grid, Stack, TextField} from '@mui/material';
import {ROTATION_SHAPE} from '../../../../common/rotation-shapes/rotation-shapes';
import MVTConfirmation from '../resources/mvt-confirmation/MVTConfirmation';
import RTKQueryFeedback from '../../../../../common/RTKQueryFeedback';
import FormActions from '../resources/form-actions/FormActions';
import {useCreateDelaysMutation, useGetCurrentUserQuery} from '../../../../../../api';
import {schemaDelays} from './delays-form-schemas';
import DelaysFormInsertedDelays from './DelaysFormInsertedDelays';
import DelaysFormInsertCode from './DelaysFormInsertCode';
import {hasSomeOf, ROLES} from '../../../../../../utils/user/user-utils';
import {actions} from '../../../../../../store/valtio';
import {A, E, determineReliableDatetime} from '../../../../../../utils/rotation/rotation-utils';

const DelaysForm = ({rotation, onClose}) => {
  const [mvtBody, setMvtBody] = useState(null);
  const [mvtWasSent, setMvtWasSent] = useState(false);
  const [sendMvt, {isLoading, error}] = useCreateDelaysMutation();
  const {data: user} = useGetCurrentUserQuery();
  const readOnly = !hasSomeOf(user, [ROLES.ADMIN, ROLES.SUPERVISOR]);
  const {delays, scheduledTime} = rotation.outbound.departure;
  const {datetime: reliableDatetime} = determineReliableDatetime(rotation.outbound.departure, [A, E]);

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(schemaDelays),
    mode: 'onChange',
    context: {
      reliableDatetime,
      scheduledTime
    },
    defaultValues: {
      delays,
      note: ''
    }
  });

  const submitHandler = handleSubmit((values) => {
    sendMvt({
      params: {preview: mvtBody ? 0 : 1},
      body: {rotationHash: rotation.hash, ...values}
    }).then(({data: {body}}) => {
      if (!mvtBody) {
        setMvtBody(body);
        setMvtWasSent(false);
      } else {
        setMvtBody(null);
        actions.rotations.setNotifiedRotation({
          ...rotation,
          outbound: {
            ...rotation.outbound,
            departure: {
              ...rotation.outbound.departure,
              delays: values.delays
            }
          }
        });
        setMvtWasSent(true);
        onClose();
      }
    });
  });

  if (mvtBody) {
    return (
      <>
        <MVTConfirmation message={mvtBody} onSubmit={submitHandler} />
        <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
      </>
    );
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={2} container>
        <Controller
          name={'delays'}
          control={control}
          render={({field, fieldState: {error}}) => (
            <>
              <Grid xs={12} item>
                <DelaysFormInsertedDelays
                  onRemoveCode={(idx) => field.onChange(field.value.filter((_, i) => i !== idx))}
                  delays={field.value}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid xs={12} item>
                {!readOnly && (
                  <DelaysFormInsertCode
                    totalDelayTimeError={error}
                    rotation={rotation}
                    onSubmit={(dc) => field.onChange(field.value.concat([dc]))}
                    delays={field.value}
                  />
                )}
              </Grid>
            </>
          )}
        />
        {!readOnly && (
          <Grid xs={12} item>
            <Controller
              name={'note'}
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  placeholder={t`Supplementary information…`}
                  size={'small'}
                  rows={4}
                  multiline
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <FormActions btnText={t`Update`} onClick={submitHandler} onClose={onClose} readOnly={readOnly} />
      <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
    </Stack>
  );
};

DelaysForm.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  onClose: PropTypes.func
};

DelaysForm.defaultProps = {
  onClose: () => null
};

export default DelaysForm;
