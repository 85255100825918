import React from 'react';
import PropTypes from 'prop-types';
import {Box, useMediaQuery} from '@mui/material';
import {t} from 'ttag';
import CircularStatus from '../../../../common/CircularStatus';
import RotationCardDetail from '../common/RotationCardDetail';
import {WheelChairIcon} from '../../../../../assets/icons/WheelChairIcon';
import {formatTime} from '../../../../../utils/datetime/datetime-utils';

export const RotationCardFlight = ({
  flight: {
    flightId,
    flightDetails: {economyPaxNumber, executivePaxNumber, paxReducedMobility, paxTransfer, paxTransit, stand, gate, belt}
  },
  flightDirection,
  label,
  datetime,
  datetimeType,
  color,
  mobileAlign
}) => {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const paxCount = economyPaxNumber + executivePaxNumber;
  const align = isTablet ? mobileAlign : 'flex-start';
  const isOutbound = flightDirection === 'outbound';

  return (
    <Box spacing={{xs: 2}} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} id={flightId}>
      <RotationCardDetail label={label} value={formatTime(datetime, true)} alignment={align}>
        <CircularStatus color={color} char={datetimeType} />
      </RotationCardDetail>
      <RotationCardDetail label={isOutbound ? t`STAND/GATE` : t`STAND/BELT`} value={isOutbound ? `${stand || '-'}/${gate || '-'}` : `${stand || '-'}/${belt || '-'}`} alignment={align} />
      <RotationCardDetail label={t`PAX/TRS/TRB`} value={`${paxCount}/${paxTransit}/${paxTransfer}`} alignment={align}>
        {Boolean(paxReducedMobility) && <WheelChairIcon/>}
      </RotationCardDetail>
    </Box>
  );
};

RotationCardFlight.propTypes = {
  flight: PropTypes.shape({
    flightId: PropTypes.string.isRequired,
    flightDetails: PropTypes.shape({
      economyPaxNumber: PropTypes.number.isRequired,
      executivePaxNumber: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  flightDirection: PropTypes.oneOf(['inbound', 'outbound']),
  label: PropTypes.string,
  datetime: PropTypes.string,
  datetimeType: PropTypes.string,
  color: PropTypes.string,
  mobileAlign: PropTypes.oneOf(['flex-start', 'flex-end'])
};

RotationCardFlight.defaultProps = {
  flight: null,
  label: 'flight',
  datetime: '',
  datetimeType: '',
  color: '',
  mobileAlign: 'left'
};

export default RotationCardFlight;
