import useRotationTimerTick from './useRotationTimerTick';
import {getRecordActionStatus} from '../../../utils/turnaround/turnaround-utils';


/** @type {(record: Record) => {TurnaroundRecordAction}} */
export const useRotationTurnaroundRecordAnalyzer = (record) => {
  useRotationTimerTick();

  return getRecordActionStatus(record);
};

export default useRotationTurnaroundRecordAnalyzer;
