import {convertDatetimeToUTC} from './datetime-mappers';
import {convertDurationToServerFormat, createDuration, DURATION_FORMAT} from '../../utils/datetime/datetime-utils';

/** @type {(dto: MovementAtaDTO) => MovementAtaDTO} */
export const movementAtaDtoMapper = (dto) => ({
  ...dto,
  touchDown: convertDatetimeToUTC(dto.touchDown),
  actualTime: convertDatetimeToUTC(dto.actualTime)
});

/** @type {(dto: MovementEtdDTO) => MovementEtdDTO} */
export const movementEtdDtoMapper = (dto) => ({
  ...dto,
  estimatedTime: convertDatetimeToUTC(dto.estimatedTime)
});

/** @type {(dto: MovementAtdDTO) => ServerMovementAtdDTO} */
export const movementAtdDtoMapper = (dto) => ({
  ...dto,
  actualTime: convertDatetimeToUTC(dto.actualTime),
  takeOff: convertDatetimeToUTC(dto.takeOff),
  flightDuration: convertDurationToServerFormat(dto.flightDuration)
});

/** @type {(dto: MovementDelaysDTO) => ServerMovementDelaysDTO} */
export const movementDelaysDtoMapper = (dto) => ({
  ...dto,
  delays: dto.delays.map((dc) => ({
    ...dc,
    time: convertDurationToServerFormat(dc.time)
  })),
  delay: convertDurationToServerFormat(
    dto.delays.reduce(
      (dt, dc) => createDuration(dt).plus(createDuration(dc.time)).toFormat(DURATION_FORMAT),
      '00:00:00'
    )
  )
});
