import React, {useCallback, useMemo, useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {DateTimePicker, LocalizationProvider} from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import {AccessTime, CalendarToday} from '@mui/icons-material';
import {IconButton, Stack, TextField, Typography, useMediaQuery} from '@mui/material';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  DATETIME_FORMAT_NO_SEC,
  TIME_FORMAT_NO_SEC,
  dateToYYMMDDHHMMSS
} from '../../../utils/datetime/datetime-utils';
import {t} from 'ttag';
import DatePicker from 'react-mobile-datepicker';
import './styles.css';

const DatetimePicker = forwardRef(
  ({label, value, onChange, variant, size, openTo, helperText, error, disabled, wheelTimePickerOnMobile}, ref) => {
    const isTablet = useMediaQuery((theme) => theme.breakpoints.between('xs', 'xl'));
    const [open, setOpen] = useState(false);

    const Icon = variant === VARIANTS.TIME_ONLY ? AccessTime : CalendarToday;

    /** @type string */
    const format = useMemo(() => {
      if (variant === VARIANTS.DATE_ONLY) return DATE_FORMAT;
      if (variant === VARIANTS.TIME_ONLY) return TIME_FORMAT_NO_SEC;
      return DATETIME_FORMAT_NO_SEC;
    }, [variant]);

    /** @type {("year" | "month" | "day" | "hours" | "minutes" | "seconds")[]} */
    const views = useMemo(() => {
      if (variant === VARIANTS.DATE_ONLY) return ['year', 'month', 'day'];
      if (variant === VARIANTS.TIME_ONLY) return ['hours', 'minutes'];
      return ['year', 'month', 'day', 'hours', 'minutes'];
    }, [variant]);

    /** @type {(dt: DateTime) => void} */
    const changeHandler = useCallback(
      (dt) => {
        if (dt?.isValid) {
          onChange(dt?.toFormat(DATETIME_FORMAT));
        }
      },
      [onChange]
    );

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Stack ref={ref}>
          <Typography variant={'caption'} fontWeight={'bold'} px={1} color={'secondary.main'}>
            {label}
          </Typography>
          <DateTimePicker
            open={open && (!isTablet || !wheelTimePickerOnMobile)}
            onClose={() => setOpen(false)}
            onOpen={() => !disabled && setOpen(true)}
            TextFieldProps={{onClick: () => setOpen(true), fullWidth: true}}
            inputProps={{style: {cursor: 'pointer'}, readOnly: true}}
            InputAdornmentProps={{sx: {display: 'none'}}}
            value={value ? value.replace(' ', 'T') : value}
            onChange={changeHandler}
            ampmInClock={false}
            ampm={false}
            disabled={disabled}
            showDaysOutsideCurrentMonth
            openTo={openTo ? openTo : variant === VARIANTS.TIME_ONLY ? 'hours' : 'day'}
            inputFormat={format}
            views={views}
            hideTabs={variant !== VARIANTS.DATETIME}
            showToolbar={false}
            DialogProps={{PaperProps: {sx: {'& *:focus': {outline: 'none'}}}}}
            renderInput={(props) => (
              <TextField
                {...props}
                error={error}
                helperText={helperText}
                disabled={disabled}
                size={size}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <IconButton size={size} sx={{padding: 1, marginRight: -1}}>
                      <Icon />
                    </IconButton>
                  )
                }}
              />
            )}
          />
          {open && wheelTimePickerOnMobile && isTablet && (
            <DatePicker
              value={value ? new Date(value.replace(' ', 'T')) : new Date()}
              isOpen={open && isTablet}
              showCaption
              isPopup
              headerFormat={variant === VARIANTS.TIME_ONLY ? 'hh:mm' : 'YYYY-MM-DD hh:mm'}
              dateConfig={{
                hour: {format: 'hh', caption: t`Hour`, step: 1},
                minute: {format: 'mm', caption: t`Min`, step: 1}
              }}
              onChange={(v) => onChange(dateToYYMMDDHHMMSS(v))}
              onSelect={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              confirmText={t`OK`}
              cancelText={false}
              theme={'ios'}
            />
          )}
        </Stack>
      </LocalizationProvider>
    );
  }
);

export const VARIANTS = {
  DATETIME: 'DATETIME',
  DATE_ONLY: 'DATE_ONLY',
  TIME_ONLY: 'TIME_ONLY'
};

DatetimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  size: PropTypes.oneOf(['small', 'medium']),
  wheelTimePickerOnMobile: PropTypes.bool,
  openTo: PropTypes.oneOf(['year', 'day', 'month', 'hours', 'minutes', 'seconds']),
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

DatetimePicker.defaultProps = {
  label: null,
  value: null,
  onChange: () => null,
  variant: VARIANTS.DATETIME,
  size: 'medium',
  wheelTimePickerOnMobile: false,
  openTo: null,
  helperText: '',
  error: false,
  disabled: false
};

export default DatetimePicker;
