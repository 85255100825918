import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import {useState} from 'react';
import {t} from 'ttag';

const STORAGE_KEY = 'dismissedAlertUsers';

const NewVersionAlert = ({user}) => {
  const [openDialog, setOpenDialog] = useState(true);
  const dismissedAlertUsers = localStorage.getItem(STORAGE_KEY) || '';

  if (!user || !openDialog || dismissedAlertUsers.includes(user?.employeeId)) return null;

  const onClose = () => {
    localStorage.setItem(STORAGE_KEY, `${dismissedAlertUsers}${user.employeeId},`);
    setOpenDialog(false);
  }

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby={'new-version'}
      aria-describedby={'new-features'}
      sx={{zIndex: 9999}}
    >
      <DialogContent>
        <DialogContentText>
          {t`Bem-vindo à nova versão do Handling Info. Ouvimos o feedback e melhoramos a aplicação para ir ao encontro do
          mesmo. Foram corrigidas falhas de apresentação dos dados dos voos e cálculos de tempos, identificados na
          versão anterior. Consulte junto da supervisão todas as novidades. O seu feedback é importante!`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t`OK`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewVersionAlert.propTypes = {
  user: PropTypes.shape({
    employeeId: PropTypes.string
  })
};

NewVersionAlert.defaultProps = {
  user: null
};

export default NewVersionAlert;
