import React from 'react';
import PropTypes from 'prop-types';
import {Box, CardHeader} from '@mui/material';
import {FlightLand, FlightTakeoff} from '@mui/icons-material';
import RotationCardHeaderDetail from './RotationCardHeaderDetail';
import {actions} from '../../../../../store/valtio';
import {TABS} from '../../../rotation-view/resources/rotation-view-tabs/RotationViewTabs';
const {setRotationAndTab} = actions.rotations.rotationView;

export const RotationCardHeader = ({rotation}) => {
  const {inbound, outbound, aircraft} = rotation;
  const inboundClickHandler = () => setRotationAndTab(rotation, inbound ? TABS.INBOUND.value : TABS.OUTBOUND.value);
  const turnAroundClickHandler = () => setRotationAndTab(rotation, TABS.TURNAROUND.value);
  const outboundClickHandler = () => setRotationAndTab(rotation, outbound ? TABS.OUTBOUND.value : TABS.INBOUND.value);

  return (
    <CardHeader
      sx={styles.wrapper}
      title={
        <Box display={'flex'}>
          <Box flex={{xs: 6, md: 5}} p={2} sx={styles.leftBox} onClick={inboundClickHandler}>
            {aircraft && <RotationCardHeaderDetail strongTitle={aircraft.tail} title={aircraft.code} />}
            {inbound && (
              <RotationCardHeaderDetail
                icon={<FlightLand />}
                strongTitle={inbound.flightNumber}
                title={inbound.origin.iataCode}
              />
            )}
          </Box>
          <Box flex={{xs: 0, md: 2}} sx={{cursor:'pointer'}} onClick={turnAroundClickHandler} />
          <Box flex={{xs: 6, md: 5}} p={2} sx={styles.rightBox(inbound)} onClick={outboundClickHandler}>
            {outbound && (
              <RotationCardHeaderDetail
                icon={<FlightTakeoff />}
                strongTitle={outbound.flightNumber}
                title={outbound.destination.iataCode}
              />
            )}
          </Box>
        </Box>
      }
    />
  );
};

const styles = {
  wrapper: {
    padding: 0,
    backgroundColor: 'secondary.main',
    color: 'common.white'
  },
  leftBox: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: {xs: 'column', md: 'row'}
  },
  rightBox: (inbound) => ({
    cursor: 'pointer',
    display: 'flex',
    flex: {xs: 6, md: 5},
    alignSelf: inbound ? 'flex-end' : 'flex-start',
    justifyContent: {xs: 'flex-end', md: 'flex-start'}
  })
};

RotationCardHeader.propTypes = {
  rotation: PropTypes.shape({
    aircraft: PropTypes.shape({
      tail: PropTypes.string,
      code: PropTypes.string
    }),
    inbound: PropTypes.shape({
      flightNumber: PropTypes.string,
      origin: PropTypes.shape({
        iataCode: PropTypes.string
      })
    }),
    outbound: PropTypes.shape({
      flightNumber: PropTypes.string,
      destination: PropTypes.shape({
        iataCode: PropTypes.string
      })
    })
  })
};

export default RotationCardHeader;
