import React from 'react';
import PropTypes from 'prop-types';
import {FlightBriefingRow} from './flight-briefing-row/FlightBriefingRow';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import FlightBriefingSkeleton from './FlightBriefingSkeleton';
import {t} from 'ttag';


export const FlightBriefing = ({title, airportColumnTitle, flightBriefing, isLoading}) => {
  if (isLoading) return <FlightBriefingSkeleton title={title} airportColumnTitle={airportColumnTitle} />;
  if (!flightBriefing.length) return null;

  return (
    <TableContainer sx={{root: styles.table}} component={Paper}>
      <Table sx={styles.table} size='small' aria-label='a dense table'>
        <TableHead key={'header'} sx={styles.tableHeader}>
          <TableRow>
            <TableCell width='106' sx={styles.tableCellHeader}>
              {title}
            </TableCell>
            <TableCell width='10' />
            <TableCell width='10' />
            <TableCell width='10' />
            <TableCell sx={styles.tableCellHeader} align='right' />
          </TableRow>
        </TableHead>
        <TableHead key={'subHeader'} sx={styles.tableSubHeader}>
          <TableRow>
            <TableCell sx={styles.tableCellSubHeader}>{t`FLIGHT n.º`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>
              {airportColumnTitle}
            </TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>{t`TIME`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>{t`PAX`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='left'>{t`MORE INFO.`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {flightBriefing?.map((data, idx) => (
            <FlightBriefingRow key={idx} flightBriefing={data} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FlightBriefing.propTypes = {
  title: PropTypes.string,
  airportColumnTitle: PropTypes.string,
  flightBriefing: PropTypes.array,
  isLoading: PropTypes.bool
};

FlightBriefing.defaultProps = {
  title: '',
  airportColumnTitle: '',
  flightBriefing: [],
  isLoading: false
};

export const styles = {
  table: {
    boxShadow: 'unset'
  },
  tableHeader: {
    background: '#002857 0% 0% no-repeat padding-box',
    borderRadius: '8px 8px 0px 0px',
    opacity: 1
  },
  tableSubHeader: {
    background: '#F4F5F8 0% 0% no-repeat padding-box'
  },
  tableCellHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: 'white',
    textTransform: 'uppercase',
    opacity: 1
  },
  tableCellSubHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: 'secondary.main',
    textTransform: 'uppercase',
    opacity: 1
  }
};

export default FlightBriefing;
