import store from './store';
import {airports} from '../../components/common/local-airport-search';

export const actions = {
  app: {
    /** @type {() => null} */
    init: () => null,
    /** @type {() => void} */
    toggleSideBarOpen: () => (store.app.sideBarOpen = !store.app.sideBarOpen),
    /** @type {(open: boolean) => void} */
    setSideBarOpen: (open) => (store.app.sideBarOpen = open)
  },
  rotations: {
    /** @type {(rotation: Rotation) => void} */
    setNotifiedRotation: (rotation) => store.rotations.notifiedRotation = rotation,
    rotationView: {
      /** @type {(rotation: Rotation) => void} */
      setRotation: (rotation) => store.rotations.rotationView.rotation = rotation,
      /** @type {(tab: ("inbound" | "turnaround" | "outbound")) => void} */
      setTab: (tab) => store.rotations.rotationView.tab = tab,
      /** @type {(rotation: Rotation, tab: ("inbound" | "turnaround" | "outbound")) => void} */
      setRotationAndTab: (rotation, tab) => {
        store.rotations.rotationView.tab = tab;
        store.rotations.rotationView.rotation = rotation;
      },
      /** @type {() => void} */
      resetRotationAndTab: () => {
        store.rotations.rotationView.tab = null;
        store.rotations.rotationView.rotation = null;
      },
    },
    timer: {
      /** @type {(open: boolean) => void} */
      setRunning: (running) => store.rotations.timer.running = running,
      /** @type {() => void} */
      toggleRunning: () => store.rotations.timer.running = !store.rotations.timer.running,
      /** @type {(tick: number) => void} */
      setTick: (tick) => store.rotations.timer.tick = tick,
      /** @type {() => void} */
      incTick: () => ++store.rotations.timer.tick,
    },
    filters: {
      /** @type {(airport: Airport) => void} */
      setAirport: (airport) => (store.rotations.filters.airport = airport),
      /** @type {(airport: IataCode) => void} */
      setAirportFromIataCode: (iataCode) => (store.rotations.filters.airport = airports.find(a => a.iataCode === iataCode)),
      /** @type {(pattern: string) => void} */
      setPattern: (pattern) => (store.rotations.filters.pattern = pattern),
      /** @type {(state: RotationState) => void} */
      setState: (state) => (store.rotations.filters.state = state),
      /** @type {(operationDay: LocalDatetime) => void} */
      setOperationDay: (operationDay) => (store.rotations.filters.operationDay = operationDay)
    }
  }
};

export default actions;
