import PropTypes from 'prop-types';
import {Tab, Tabs} from '@mui/material';
import {t} from 'ttag';
import {FLIGHT_DIRECTIONS} from '../../../../../utils/rotation/rotation-utils';
import RotationViewTurnaroundTimer from '../rotation-view-turnaround-timer/RotationViewTurnaroundTimer';

export const RotationViewTabs = ({rotation, onChange, value, tabs}) => {
  return (
    <Tabs
      sx={styles.root}
      value={value}
      variant={'fullWidth'}
      TabIndicatorProps={{sx: {height: '4px'}}}
      onChange={(_, tabSelected) => onChange(tabSelected)}
    >
      {tabs
        .filter((t) => t.show)
        .map((tab, idx) => {
          if (tab.value === FLIGHT_DIRECTIONS.TURNAROUND) {
            return (
              <Tab
                key={idx}
                sx={styles.tab}
                value={tab.value}
                label={<RotationViewTurnaroundTimer rotation={rotation} />}
              />
            );
          }
          return <Tab key={idx} sx={styles.tab} value={tab.value} label={tab.label} />;
        })}
    </Tabs>
  );
};

export const TABS = {
  INBOUND: {label: t`Arrival`, value: FLIGHT_DIRECTIONS.INBOUND, show: false},
  TURNAROUND: {label: t`Turnaround`, value: FLIGHT_DIRECTIONS.TURNAROUND, show: true},
  OUTBOUND: {label: t`Departure`, value: FLIGHT_DIRECTIONS.OUTBOUND, show: false}
};

RotationViewTabs.propTypes = {
  rotation: PropTypes.shape({
    inbound: PropTypes.object,
    outbound: PropTypes.object
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      show: PropTypes.bool
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string
};

RotationViewTabs.defaultProps = {
  rotation: null,
  onChange: () => null,
  value: ''
};

const styles = {
  root: {
    borderBottom: '1px solid',
    borderColor: 'secondary.main'
  },
  tab: {
    fontSize: 18,
    color: 'secondary.light',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: 'secondary.main',
      fontWeight: 'bold'
    }
  }
};

export default RotationViewTabs;
