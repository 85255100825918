import {useEffect} from 'react';
import {actions} from '../../store/valtio';
import {useMediaQuery} from '@mui/material';
import {useGetCurrentUserQuery} from '../../api';
import RTKQueryFeedback from '../../components/common/RTKQueryFeedback';
import {hasEveryOf, ROLES} from '../user/user-utils';
import {ROTATION_STATES} from '../rotation/rotation-utils';
import {ENV} from '../../config/settings';
import NewVersionAlert from '../../components/common/new-version-alert/NewVersionAlert';
import {DateTime} from "luxon";
import {GET_CURRENT_TZ} from "../datetime/datetime-utils";

const AppInitProvider = ({children}) => {
  const {data: user, error, isLoading, isSuccess} = useGetCurrentUserQuery();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  useEffect(() => {
    actions.app.init();
    if (ENV === 'PROD') {
      const timeoutId = setTimeout(() => (window.location.href = '/logout'), 5400000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    actions.app.setSideBarOpen(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    if (user) {
      if (user.iataCode) actions.rotations.filters.setAirportFromIataCode(user.iataCode);
      if (hasEveryOf(user, [ROLES.SUPERVISOR])) actions.rotations.filters.setState(ROTATION_STATES.DELAYED_WITHOUT_JUSTIFICATION);
      actions.rotations.filters.setOperationDay(DateTime.now().setZone(GET_CURRENT_TZ()).toFormat('yyyy-MM-dd'));
    }
  }, [user]);

  return (
    <>
      {isSuccess && children}
      <NewVersionAlert user={user}/>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
    </>
  );
};

export default AppInitProvider;
