import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Button, CardHeader} from '@mui/material';
import {RolesList} from './index';
import {AddRoleDialog, DeleteRoleDialog, EditRoleDialog} from './role-forms';

export const Roles = () => {
  const [roleToAdd, setRoleToAdd] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(false);

  return (
    <>
      <CardHeader
        action={<Button onClick={() => setRoleToAdd({})} variant={'outlined'} color={'secondary'} startIcon={<Add/>}>{t`Add`}</Button>}
      />
      <RolesList editHandler={setRoleToEdit} deleteHandler={setRoleToDelete}/>
      {roleToEdit && <EditRoleDialog onClose={() => setRoleToEdit(false)} role={roleToEdit}/>}
      {roleToAdd && <AddRoleDialog role={roleToAdd} onClose={() => setRoleToAdd(false)}/>}
      {roleToDelete && <DeleteRoleDialog onClose={() => setRoleToDelete(false)} role={roleToDelete}/>}
    </>
  );
};

Roles.propTypes = {};

Roles.defaultProps = {};

export default Roles;
