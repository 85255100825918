import * as Yup from 'yup';
import {createDatetime as dt, createDuration, formatTime} from '../../../../../../utils/datetime/datetime-utils';
import {t} from 'ttag';
import {totalMissingDuration} from './delay-codes-utils';

export const schemaDelays = Yup.object()
    .shape({
      delays: Yup.array()
          .of(Yup.object().shape({
            code: Yup.string(),
            time: Yup.string()
          }))
          .max(3, t`Max 3 delay codes reached.`)
          .test({
            test: (delays, {options: {context: {scheduledTime, reliableDatetime}}}) => {
              return totalMissingDuration(scheduledTime, reliableDatetime, delays) === '00:00:00';
            },
            message: t`You have to justify the remaining.`
          }),
      note: Yup.string()
    })
    .required();

export const schemaAddDelay = Yup.object()
    .shape({
      delayCode: Yup.object()
          .nullable()
          .required(t`Can't be empty.`)
          .shape({
            codeNumeric: Yup.string(),
            codeDescription: Yup.string(),
          }),
      subDelayCode: Yup.object().shape({
        code: Yup.string(),
        description: Yup.string(),
      }).nullable().when('delayCode', (delayCode) => {
            if (delayCode.subDelayCodes.length !== 0) {
              return Yup.object().shape({
                code: Yup.string(),
                description: Yup.string(),
              }).required(t`Can't be empty.`).nullable()
            }
      }),
      delayTime: Yup.string()
          .test({
            test: (value) => value && dt(value).isValid && formatTime(value, true) !== '00:00',
            message: t`Should be a valid duration.`
          })
          .test({
            test: (value, {options: {context: {totalMissing}}}) => {
              return createDuration(formatTime(value)) <= createDuration(totalMissing);
            },
            message: t`Your are justifying more than the remaining delay.`
          })
    })
    .required();
