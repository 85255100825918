import {useState} from 'react';
import PropTypes from 'prop-types';
import useRotationTurnaroundRecordAnalyzer from '../../../../../../rotation-hooks/useRotationTurnaroundRecordAnalyzer';
import TurnaroundCheckListDialogTask from '../common/turnaroundChecklistDialog/TurnaroundCheckListDialogTask';
import TurnaroundActionToggleBtnWithCheckBox from '../../common/TurnaroundActionToggleBtnWithCheckBox';
import TurnaroundChecklistDialog from '../common/turnaroundChecklistDialog/TurnaroundChecklistDialog';
import {RECORD_CHECKLIST_SHAPE} from '../../common/record-shapes/record-shapes';
import TurnaroundActionToggleBtn from '../../common/TurnaroundActionToggleBtn';
import {Typography} from "@mui/material";
import {useGetCurrentUserQuery} from "../../../../../../../../api";
import {hasEveryOf} from "../../../../../../../../utils/user/user-utils";
import {regulationCheckFileByActionItemName} from "../../../../../../../../utils/record/record-utils";

export const TurnaroundActionChecklist = ({
  record,
  selectMode,
  onCheckBoxChange,
  readOnly,
  onFinishAction,
  onCancelFinishAction,
  onUpdateAction,
  onSetShowEditDialog,
  onCheckAll,
  onUnCheckAll
}) => {
  const {data: user} = useGetCurrentUserQuery();
  const {task} = useRotationTurnaroundRecordAnalyzer(record);
  const {time, color, finished, delayedDuration} = task;
  const [open, setOpen] = useState(false);

  if (selectMode && !finished && !record?.action.mandatory) {
    return (
      <TurnaroundActionToggleBtnWithCheckBox
        label={record?.action.description}
        time={time}
        onCheckBoxChange={onCheckBoxChange}
        listItemsLabel={`(${record?.action.listItems.filter((item) => item.finished).length}/${
          record?.action.listItems.length
        })`}
        checked={!record.action.applicable}
        backgroundColor={color}
        selectMode={true}
      />
    );
  }

  const onChange = (itemId) => {
    const action = {
      ...record.action,
      listItems: [
        ...record.action.listItems.map((item) => {
          if (item.itemId !== itemId) {
            return item;
          }
          return {...item, finished: !item.finished};
        })
      ]
    };
    const isEnableToFinishCheckList =
      action.listItems.filter((item) => item.finished).length === action.listItems.length;
    onUpdateAction({params: record, body: {action}});

    if (isEnableToFinishCheckList) {
      onFinishAction();
    }
    if (!isEnableToFinishCheckList && record.finished) {
      onCancelFinishAction();
    }
  };

  return (
    <>
      <TurnaroundActionToggleBtn
        label={record?.action.description}
        time={time}
        listItemsLabel={`(${record?.action.listItems.filter((item) => item.finished).length}/${
          record?.action.listItems.length
        })`}
        backgroundColor={color}
        completed={finished}
        delayedDuration={delayedDuration}
        applicable={record.action.applicable}
        onClick={() => setOpen(true)}
        onEdit={(_, e) => {
          onSetShowEditDialog(true);
          e.stopPropagation();
        }}
        hideEdit
      />
      {open && (
        <TurnaroundChecklistDialog
          title={'Operator Compliance'}
          subTitle={'Regulatory Check - IOSA | ISM | 6.3.2.1'}
          finished={finished}
          onCheckAll={() => !readOnly && (!finished ? onCheckAll() : onUnCheckAll())}
          onClose={() => setOpen(false)}
        >
          {record?.action.listItems.map((item, idx) => (
            <TurnaroundCheckListDialogTask
              title={<Typography variant={'h4'} fontWeight={'bold'}>{item.name}</Typography>}
              key={idx}
              label={<Typography>{item.description}</Typography>}
              isChecked={item.finished}
              file={regulationCheckFileByActionItemName(item.name)}
              onClick={() => !readOnly && onChange(item.itemId)}
              disabled={!hasEveryOf(user, [item.name])}
            />
          ))}
        </TurnaroundChecklistDialog>
      )}
    </>
  );
};

TurnaroundActionChecklist.propTypes = {
  readOnly: PropTypes.bool,
  record: RECORD_CHECKLIST_SHAPE,
  label: PropTypes.string,
  selectMode: PropTypes.bool,
  onCheckBoxChange: PropTypes.func
};

TurnaroundActionChecklist.defaultProps = {
  readOnly: false,
  label: '',
  selectMode: true,
  onCheckBoxChange: () => null
};

export default TurnaroundActionChecklist;
