import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Cancel} from '@mui/icons-material';
import {Grid, IconButton, InputAdornment, TextField} from '@mui/material';
import {formatDurationWithNoSeconds} from '../../../../../../utils/datetime/datetime-utils';

const DelaysFormInsertedDelays = ({delays, onRemoveCode, error, helperText, minEmptyFields, readOnly}) => {
  return (
    <Grid spacing={1} container>
      {Array.from(Array(delays.length > minEmptyFields ? delays.length : minEmptyFields), (_, idx) => {
        const delay = delays?.[idx];
        const hasSubDelayCode = delay?.hasOwnProperty('subDelayCode') && delay?.subDelayCode !== undefined;
        return (
          <Grid key={idx} xs={12} md={4} item>
            <TextField
              size={'small'}
              readOnly={true}
              variant={'outlined'}
              placeholder={t`Add Code`}
              error={Boolean(error)}
              helperText={helperText}
              disabled={!delay}
              value={
                delay && hasSubDelayCode
                    ? `${delay.code} / ${delay.subDelayCode} (${formatDurationWithNoSeconds(delay.time)})`
                    : (delay ? `${delay.code} (${formatDurationWithNoSeconds(delay.time)})` : '')
              }
              sx={delay && styles.delayCode}
              InputProps={{
                endAdornment: delay && !readOnly && (
                  <InputAdornment position={'end'}>
                    <IconButton aria-label={'delete'} onClick={() => onRemoveCode(idx)} className={styles.margin}>
                      <Cancel fontSize={'small'} color={'secondary'} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  root: [styles.textFieldRoot, delay && styles.delayCode],
                  adornedEnd: styles.textFieldRoot
                }
              }}
              fullWidth
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

DelaysFormInsertedDelays.propTypes = {
  delays: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      time: PropTypes.string
    })
  ),
  onRemoveCode: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  minEmptyFields: PropTypes.number,
};

DelaysFormInsertedDelays.defaultProps = {
  delays: [],
  onRemoveCode: () => null,
  error: false,
  helperText: null,
  readOnly: false,
  minEmptyFields: 3
};

const styles = {
  textFieldRoot: {
    paddingRight: 0,
    borderStyle: 'dashed'
  },
  delayCode: {
    '& .MuiInputBase-root': {
      backgroundColor: '#D54C4C',
      color: 'common.white',
      fontSize: 16,
      lineHeight: '22px',
      borderStyle: 'solid'
    }
  },
  input: {
    backgroundColor: 'common.white'
  }
};

export default DelaysFormInsertedDelays;
