import {t} from 'ttag';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import {Box, Card, Stack, Typography} from '@mui/material';
import {ClearTwoTone, DoDisturb} from '@mui/icons-material';
import {formatTime} from '../../../../../../../utils/datetime/datetime-utils';
import {AuthorizationGuard} from '../../../../../../../pages/common/guards';
import FormEditToggleBtn from '../../../common/FormEditToggleBtn';
import {ROLES} from '../../../../../../../utils/user/user-utils';

export const TurnaroundActionToggleBtn = ({
  label,
  time,
  listItemsLabel,
  onClick,
  delayedDuration,
  backgroundColor,
  readOnly,
  onEdit,
  completed,
  applicable,
  manuallyAdded,
  onRemoveAction,
  hideEdit
}) => {
  const color = applicable ? (!backgroundColor ? 'secondary.dark' : 'primary.contrastText') : 'text.disabled';
  return (
    <Card sx={styles.card(backgroundColor, applicable)} elevation={0} onClick={applicable ? onClick : () => null}>
      <Stack direction={!applicable ? 'row' : 'column'} justifyContent={!applicable ? 'space-between' : 'unset'}>
        <Stack position={'relative'}>
          <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
            <Typography sx={styles.label} color={color} variant={'h3'}>
              {label}
            </Typography>
            <Box color={color} height={24}>
              {completed && applicable && <CheckIcon height={24} fontSize={'small'} />}
              {!completed && manuallyAdded && (
                <Box sx={styles.deleteActionBtn} onClick={onRemoveAction}>
                  <ClearTwoTone sx={styles.deleteActionBtnIcon} />
                </Box>
              )}
            </Box>
          </Stack>
          <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
            <Stack direction={'row'} alignItems='center' spacing={1 / 2}>
              <Typography sx={styles.defaultFontSize} color={color}>
                {time ? formatTime(time, true) : '-'}
              </Typography>
              {listItemsLabel && <Typography color={color}>{listItemsLabel}</Typography>}
              {!readOnly && applicable && !hideEdit && (
                <AuthorizationGuard userHasSomeOf={[ROLES.SUPERVISOR, ROLES.ADMIN]}>
                  <FormEditToggleBtn sx={styles.editToggle} onToggle={onEdit} />
                </AuthorizationGuard>
              )}
            </Stack>
            {delayedDuration && applicable && (
              <Typography sx={styles.defaultFontSize} color={color}>
                {delayedDuration}
              </Typography>
            )}
          </Stack>
        </Stack>
        {!applicable && (
          <Box height={'48px'} display={'flex'}>
            <DoDisturb sx={{my: 'auto', color: color}} fontSize={'large'} />
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const styles = {
  card: (backgroundColor, applicable) => ({
    backgroundColor: backgroundColor || 'background.odd',
    border: 'unset',
    paddingX: 1.5,
    paddingY: 0.5,
    paddingRight: 1,
    cursor: applicable ? 'pointer' : 'unset'
  }),
  label: {
    fontSize: '0.875em',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  defaultFontSize: {
    fontSize: 'typography.fontSize'
  },
  editToggle: {
    cursor: 'pointer',
    color: '#00285780',
    fontSize: '0.875rem',
    fontFamily: 'NeoSansProRegular'
  },
  deleteActionBtn: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    borderRadius: 10,
    width: 20,
    height: 20,
    position: 'absolute',
    right: '3px',
    bottom: '13px'
  },
  deleteActionBtnIcon: {
    color: 'primary.contrastText',
    width: 15
  }
};

TurnaroundActionToggleBtn.propTypes = {
  label: PropTypes.string,
  time: PropTypes.string,
  listItemsLabel: PropTypes.string,
  onClick: PropTypes.func,
  delayedDuration: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  completed: PropTypes.bool,
  readOnly: PropTypes.bool,
  manuallyAdded: PropTypes.bool,
  onRemoveAction: PropTypes.func,
  hideEdit: PropTypes.bool,
};

TurnaroundActionToggleBtn.defaultProps = {
  label: t`DOOR OPEN`,
  time: '10:00',
  listItemsLabel: null,
  onClick: () => null,
  delayedDuration: '',
  color: 'secondary.dark',
  backgroundColor: null,
  completed: false,
  readOnly: false,
  manuallyAdded: false,
  onRemoveAction: () => null,
  hideEdit: false
};

export default TurnaroundActionToggleBtn;
