import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {util} from '../../../api';
import {actions, useStore} from '../../../store/valtio';
import {clone} from '../../../store/valtio/store-utils';

export const useRotationUpdater = () => {
  const {rotations: {filters: {airport}, rotationView: {rotation: selectedRotation}, notifiedRotation}} = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    if (notifiedRotation?.hash) {
      const snapshot = clone(notifiedRotation);
      try {
        dispatch(
          util.updateQueryData('getRotations', {iataCode: airport?.iataCode}, (rotations) => {
            let r = rotations?.find((r) => r.hash === snapshot.hash);
            if (r) {
              r.iataCode = snapshot?.iataCode;
              r.canceledOutbound = snapshot?.canceledOutbound;
              r.aircraft = snapshot?.aircraft;
              r.inbound = snapshot?.inbound;
              r.outbound = snapshot?.outbound;
              r.operationDay = snapshot?.operationDay;
              r.turnAround = snapshot?.turnAround;
              if (selectedRotation && selectedRotation.hash === snapshot.hash) {
                actions.rotations.rotationView.setRotation(r);
              }
              console.info(`Rotation ${r.hash} updated!`);
            }
          })
        );
      } catch (e) {
        console.error(e);
      }
      actions.rotations.setNotifiedRotation(null);
    }
  }, [notifiedRotation, airport, selectedRotation, dispatch]);
};

export default useRotationUpdater;
