import {downloadRulesFile} from "./utils/helpers";

export const type = 'rules';

export const rulesManagementEndPoints = (builder) => ({
  getRules: builder.query({
    query: () => ({
      url: `/rules/preview`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data,
    providesTags: () => [{type, id: type}]
  }),

  uploadRules: builder.mutation({
    query: (file) => {
      const formData = new FormData();
      formData.append('file', file);
      return {
        url: `/rules`,
        method: 'POST',
        body: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      };
    },
    transformResponse: (data) => data?.data,
    invalidatesTags: () => [{type, id: type}]
  }),

  getRulesYaml: builder.mutation({
    query: () => ({
      url: `/rules`,
      method: 'GET'
    }),
    transformResponse: (data) => data?.data ? downloadRulesFile(data.data) : null,
    invalidatesTags: () => [{type, id: 'list'}]
  }),
});

export default rulesManagementEndPoints;
