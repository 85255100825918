import {t} from 'ttag';
import {useState} from 'react';
import {Box, Grid, Stack, Typography} from '@mui/material';
import VerticalLabeledSummary from '../../../rotation-view-flight-summary/resources/VerticalLabeledSummary';
import VerticalLabeledTime from '../../../rotation-view-flight-summary/resources/VerticalLabeledTime';
import {A, determineReliableDatetime, E} from '../../../../../../../utils/rotation/rotation-utils';
import {AuthorizationGuard} from '../../../../../../../pages/common/guards';
import VerticalLabeled from '../../../../../../common/VerticalLabeled';
import StandForms from '../turnaround-forms/stand-forms/StandForms';
import FormEditToggleBtn from '../../../common/FormEditToggleBtn';
import {ROLES} from '../../../../../../../utils/user/user-utils';

const getDateTimeTypeLabel = (dateTimeType) => {
  if (dateTimeType === A) return 'AT';
  if (dateTimeType === E) return 'ET';
  return 'ST';
};

export const TurnaroundSummary = ({rotation}) => {
  const [isStandFormOpen, setIsStandFormOpen] = useState(false);
  const {inbound, outbound} = rotation;
  const stand = inbound?.flightDetails.stand || outbound?.flightDetails.stand || null;
  const gate = outbound?.flightDetails.gate || null;
  const belt = inbound?.flightDetails.belt || null;
  const arrival = inbound?.arrival ? determineReliableDatetime(inbound.arrival) : null;
  const departure = outbound?.departure ? determineReliableDatetime(outbound.departure) : null;

  return (
    <Stack>
      <Box backgroundColor={'secondary.main'} color={'secondary.contrastText'} borderRadius={1}>
        <Grid container padding={2} spacing={2}>
          <Grid item flex={1}>
            <VerticalLabeledSummary
              label={t`ARRIVAL`}
              value={
                <Stack>
                  <Typography variant={'body2'}>{inbound?.flightNumber || '-'}</Typography>
                  <Typography variant={'body2'}>
                    {inbound?.origin?.iataCode ? `FROM ${inbound?.origin?.iataCode}` : '-'}
                  </Typography>
                </Stack>
              }
            />
          </Grid>
          <Grid item flex={1}>
            <VerticalLabeled label={t`STAND/BELT`} variant={'caption'} color={'silver'} fontWeight={'bold'} opacity={0.6}>
              <Typography variant={'body2'}>{`${stand || '-'}/${belt || '-'}`}</Typography>
              <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
                <FormEditToggleBtn onToggle={setIsStandFormOpen} open={isStandFormOpen} />
              </AuthorizationGuard>
            </VerticalLabeled>
          </Grid>
          <Grid item flex={1}>
            <VerticalLabeledTime
              label={arrival?.datetimeType ? `${getDateTimeTypeLabel(arrival?.datetimeType)}A` : '-'}
              time={arrival?.datetime}
            />
          </Grid>
          <Grid item flex={1}>
            <VerticalLabeled label={t``} />
            <VerticalLabeledSummary
              label={t`DEPARTURE`}
              value={
                <Stack>
                  <Typography variant={'body2'}>{outbound?.flightNumber || '-'}</Typography>
                  <Typography variant={'body2'}>
                    {outbound?.destination?.iataCode ? `TO ${outbound?.destination?.iataCode}` : '-'}
                  </Typography>
                </Stack>
              }
            />
          </Grid>
          <Grid item flex={1}>
            <VerticalLabeled label={t`STAND/GATE`} variant={'caption'} color={'silver'} fontWeight={'bold'} opacity={0.6}>
              <Typography variant={'body2'}>{`${stand || '-'}/${gate || '-'}`}</Typography>
              <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
                <FormEditToggleBtn onToggle={setIsStandFormOpen} open={isStandFormOpen} />
              </AuthorizationGuard>
            </VerticalLabeled>
          </Grid>
          <Grid item flex={1}>
            <VerticalLabeledTime
              label={departure?.datetimeType ? `${getDateTimeTypeLabel(departure?.datetimeType)}D` : '-'}
              time={departure?.datetime}
            />
          </Grid>
        </Grid>
      </Box>
      {isStandFormOpen && (
        <Box sx={{backgroundColor: 'background.default', p: 1}}>
          <StandForms rotation={rotation} onClose={() => setIsStandFormOpen(false)} />
        </Box>
      )}
    </Stack>
  );
};

TurnaroundSummary.propTypes = {};

TurnaroundSummary.defaultProps = {};

export default TurnaroundSummary;
