import {t} from 'ttag';
import {useState} from 'react';
import {Button, Card, Stack, Typography} from '@mui/material';
import TurnaroundRecordsControlActions from './TurnaroundRecordsControlActions';

export const TurnaroundRecordsControl = ({optionalActions, onAddOptionalAction}) => {
  const [showControlAction, setShowControlAction] = useState(false);

  return (
    <>
      <Card elevation={0}>
        {showControlAction && (
          <Stack sx={styles.controlActionsWrapper}>
            <TurnaroundRecordsControlActions
              unassignedActions={optionalActions}
              onSubmit={onAddOptionalAction}
              onDelete={() => setShowControlAction(false)}
            />
          </Stack>
        )}
        <Stack px={3} py={3} pb={1} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
          <Button size={'small'}>
            <Typography sx={styles.addBtnText} onClick={() => setShowControlAction(true)}>
              {t`+ ADD ACTIVITY`}
            </Typography>
          </Button>
        </Stack>
      </Card>
    </>
  );
};

const styles = {
  controlActionsWrapper: {
    display: 'flex',
    backgroundColor: 'background.babyBlue'
  },
  addBtnText: {
    fontWeight: 'bold',
    fontSize: 'typography.fontSize'
  }
};

TurnaroundRecordsControl.propTypes = {
};

TurnaroundRecordsControl.defaultProps = {
};

export default TurnaroundRecordsControl;
