import {t} from 'ttag';
import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {roleValidationSchema} from './helpers';
import {useCreateRoleMutation} from '../../../../api';
import RoleForm from './RoleForm';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

const AddRoleDialog = ({role, open, onClose}) => {
  const [addRole, {data, error, isLoading}] = useCreateRoleMutation();

  const methods = useForm({
    resolver: yupResolver(roleValidationSchema),
    defaultValues: role
  });

  return (
    <FormProvider {...methods}>
      <RTKQueryFeedback error={error} isLoading={isLoading} data={data}/>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Create`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <RoleForm/>
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={methods.handleSubmit((values) => {
              addRole({
                name: values.name,
                description: values.description,
                users: values.users.map(u => u.employeeId)
              }).then((r) => !r.error && onClose());
            })}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

AddRoleDialog.propTypes = {
  role: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

AddRoleDialog.defaultProps = {
  open: true
};

export default AddRoleDialog;
