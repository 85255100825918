import {useGetCurrentUserQuery} from '../../../api';
import PropTypes from 'prop-types';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {GenericError} from '../errors';
import {hasEveryOf, hasSomeOf} from '../../../utils/user/user-utils';

export const AuthorizationGuard = ({children, userHasSomeOf, userHasEveryOf, redirectToUnauthorized, showErrorView}) => {
  const {data: user, error, isError, isLoading} = useGetCurrentUserQuery();

  if (isLoading || isError) {
    return (
      <>
        {isError && showErrorView && <GenericError />}
        <RTKQueryFeedback isLoading={isLoading} error={error} />
      </>
    );
  }

  if (!(user && hasSomeOf(user, userHasSomeOf) && hasEveryOf(user, userHasEveryOf))) {
    if (redirectToUnauthorized) window.location.href = '/401';
    return null;
  }

  return children;
};

AuthorizationGuard.propTypes = {
  userHasSomeOf: PropTypes.arrayOf(PropTypes.string),
  userHasEveryOf: PropTypes.arrayOf(PropTypes.string),
  redirectToUnauthorized: PropTypes.bool,
  showErrorView: PropTypes.bool
};

AuthorizationGuard.defaultProps = {
  userHasSomeOf: [],
  userHasEveryOf: [],
  redirectToUnauthorized: false,
  showErrorView: false,
};

export default AuthorizationGuard;
