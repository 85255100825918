import {t} from 'ttag';
import PropTypes from 'prop-types';
import {DoDisturb} from '@mui/icons-material';
import {Box, Button, Card, Grid, Stack, Typography} from '@mui/material';

export const TurnaroundSelectToolbar = ({cancelText, confirmText, selectable, onChange, numSelectedItems, onConfirm, onCancel}) => {
  return (
    <Card sx={styles.cardWrapper} elevation={0}>
      <Grid sx={styles.container} spacing={1} container>
        <Grid xs={12} md={3} display={'flex'} item>
          <Stack
            direction={'row'}
            gap={1}
            sx={styles.disturbLabelWrapper}
            alignItems={'center'}
            onClick={() => onChange(true)}
          >
            <DoDisturb sx={{...styles.disturbLabel(selectable), mt: '-5px'}} fontSize={'small'} />
            <Typography sx={styles.disturbLabel(selectable)}>Not Applicable</Typography>
          </Stack>
        </Grid>
        {selectable && (
          <Grid xs={12} md={9} item>
            <Box sx={styles.actionsWrapper}>
              <Box display={'flex'} gap={3}>
                <Button sx={styles.actionBtns} onClick={onCancel}>
                  <Typography sx={styles.actionBtnsText}>{cancelText}</Typography>
                </Button>
                <Button sx={styles.actionBtns} onClick={onConfirm}>
                  <Typography sx={styles.actionBtnsText}>{confirmText}</Typography>
                </Button>
              </Box>
              <Box display={'flex'}>
                <Typography sx={styles.itemsSelectedLabel}>{numSelectedItems} items selected</Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const styles = {
  cardWrapper: {
    borderRadius: 'unset',
    borderBottom: '1px solid black'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 1,
    paddingTop: 1
  },
  disturbLabelWrapper: {
    cursor: 'pointer'
  },
  disturbLabel: (selectable) => ({
    color: !selectable ? 'primary.main' : 'unset'
  }),
  actionBtns: {
    textTransform: 'unset'
  },
  actionBtnsText: {
    fontWeight: 'bold'
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  itemsSelectedLabel: {
    color: 'text.disabled',
    marginY: 'auto'
  }
};

TurnaroundSelectToolbar.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  selectable: PropTypes.bool,
  onChange: PropTypes.func,
  numSelectedItems: PropTypes.number,
  onConfirm: PropTypes.func,
};

TurnaroundSelectToolbar.defaultProps = {
  cancelText: t`Cancel`,
  confirmText: t`Apply`,
  selectable: false,
  onChange: () => null,
  numSelectedItems: 0,
  onConfirm: () => null
};

export default TurnaroundSelectToolbar;
