import React from 'react';
import {Box, Button, Card, CardContent, CardHeader, Input} from '@mui/material';
import {t} from 'ttag';
import {CloudDownload} from '@mui/icons-material';
import {useGetRulesQuery, useUploadRulesMutation, useGetRulesYamlMutation} from '../../../api';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';

export const Rules = () => {
  const {data, error, isFetching} = useGetRulesQuery();
  const [downloadFile, downloadRTK] = useGetRulesYamlMutation();
  const [uploadFile, uploadRTK] = useUploadRulesMutation();

  return (
    <>
      <Card>
        <CardHeader
          action={
            <Button
              variant={'outlined'}
              color={'secondary'}
              startIcon={<CloudDownload />}
              onClick={() => downloadFile()}
            >
              {t`Download`}
            </Button>
          }
        />
        <CardContent>
          <Box sx={styles.box} p={2} mb={2}>
            <pre>{data}</pre>
          </Box>
          <label htmlFor={'contained-button-file'}>
            <Input
              accept={'.yml'}
              type={'file'}
              sx={{display: 'none'}}
              id={'contained-button-file'}
              onChange={(e) => {
                if (e.target.files[0]) {
                  uploadFile(e.target.files[0]);
                }
              }}
            />
            <Button variant={'outlined'} color={'secondary'} component={'span'}>
              {t`Upload`}
            </Button>
          </label>
        </CardContent>
      </Card>
      <RTKQueryFeedback
        error={error || uploadRTK.error || downloadRTK.error}
        isLoading={isFetching || uploadRTK.isLoading || downloadRTK.isLoading}
        isSuccess={uploadRTK.isSuccess}
      />
    </>
  );
};

const styles = {
  paper: {
    textAlign: 'center',
    color: (theme) => theme.palette.text.secondary
  },
  box: {
    backgroundColor: (theme) => theme.palette.background.default
  }
};

export default Rules;
