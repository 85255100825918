import {determineRotationStatus} from '../../../utils/rotation/rotation-utils';
import useRotationTimerTick from './useRotationTimerTick';


/** @type {(rotation: Rotation) => RotationStatus} */
export const useRotationAnalyzer = (rotation) => {
  useRotationTimerTick();

  return determineRotationStatus(rotation);
};

export default useRotationAnalyzer;
