import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Table, TableBody} from '@mui/material';

import {FlightLoadRow} from './flight-load-row/FlightLoadRow';
import {FlightLoadSkeleton} from './FlightLoadSkeleton';

export const FlightLoad = ({load, isLoading}) => {
  if (isLoading) return <FlightLoadSkeleton />;
  if (!load) return null;
  const {
    bagsWeight,
    cargoWeight,
    dangerousGoodsWeight,
    humanRemainsWeight,
    mailWeight,
    totalEconomyPassengers,
    totalExecutivePassengers,
    totalTransferPassengers,
    totalTransitPassengers,
    wheelchair,
    turnaroundCrew
  } = load;
  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Table sx={{root: styles.table}} size='small'>
          <TableBody>
            <FlightLoadRow label={t`EXECUTIVE PAX/TRS/TRB`} value={totalExecutivePassengers} />
            <FlightLoadRow
              label={t`ECONOMY PAX/TRS/TRB`}
              value={`${totalEconomyPassengers}/${totalTransitPassengers}/${totalTransferPassengers}`}
            />
            <FlightLoadRow
              label={t`WCHR/WCHS/WCHC`}
              value={`${wheelchair.ramp}/${wheelchair.stairs}/${wheelchair.cabin}`}
            />
            <FlightLoadRow label={t`BAGGAGE`} value={bagsWeight} />
            <FlightLoadRow label={t`TURN AROUND CREW`} value={turnaroundCrew} />
          </TableBody>
        </Table>
      </Grid>
      <Grid item md={6} xs={12}>
        <Table sx={{root: styles.table}} size='small'>
          <TableBody>
            <FlightLoadRow label={t`TOTAL LOAD`} value={cargoWeight} />
            <FlightLoadRow label={t`DANGER LOAD`} value={dangerousGoodsWeight} />
            <FlightLoadRow label={t`URNS`} value={humanRemainsWeight} />
            <FlightLoadRow label={t`MAIL`} value={mailWeight} />
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

FlightLoad.propTypes = {
  load: PropTypes.shape({
    bagsWeight: PropTypes.number,
    cargoWeight: PropTypes.number,
    dangerousGoodsWeight: PropTypes.number,
    humanRemainsWeight: PropTypes.number,
    mailWeight: PropTypes.number,
    totalEconomyPassengers: PropTypes.number,
    totalExecutivePassengers: PropTypes.number,
    totalTransferPassengers: PropTypes.number,
    totalTransitPassengers: PropTypes.number,
    wheelchair: PropTypes.shape({
      cabin: PropTypes.number,
      ramp: PropTypes.number,
      stairs: PropTypes.number
    })
  }),
  isLoading: PropTypes.bool
};

FlightLoad.defaultProps = {
  load: null,
  isLoading: false
};

const styles = {
  table: {
    boxShadow: 'unset',
    border: 'unset'
  }
};

export default FlightLoad;
