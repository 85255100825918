import {t} from 'ttag';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {Button, Grid, Stack, TextField, Typography} from '@mui/material';
import {ROTATION_SHAPE} from '../../../../common/rotation-shapes/rotation-shapes';
import {schemaAddDelay} from './delays-form-schemas';
import DelayCodesSearch from './resources/delay-codes-search';
import DatetimePicker from '../../../../../common/date-time-picker/DatetimePicker';
import {
  formatDate,
  formatDurationWithNoSeconds,
  formatTime,
  nowTz
} from '../../../../../../utils/datetime/datetime-utils';
import {Add} from '@mui/icons-material';
import {totalMissingDuration} from './delay-codes-utils';
import {determineReliableDatetime} from '../../../../../../utils/rotation/rotation-utils';
import SubDelayCodesSearch from "./resources/sub-delay-codes-search";

const DelaysFormInsertCode = ({rotation, onSubmit, delays, disabled, totalDelayTimeError}) => {
  const {scheduledTime} = rotation.outbound.departure;
  const {datetime: reliableDatetime} = determineReliableDatetime(rotation.outbound.departure);

  const totalMissing = useMemo(() => {
    return totalMissingDuration(scheduledTime, reliableDatetime, delays);
  }, [delays, scheduledTime, reliableDatetime]);

  const {handleSubmit, control, reset, watch} = useForm({
    resolver: yupResolver(schemaAddDelay),
    context: {
      totalMissing
    },
    defaultValues: {
      delayCode: null,
      delayTime: formatDate(nowTz()).concat(' 00:00:00'),
      subDelayCode: null
    }
  });

  const submitHandler = handleSubmit((values) => {
    const code = values.delayCode?.codeNumeric;
    const subDelayCode = values.subDelayCode?.code;
    const time = formatTime(values.delayTime, true);
    onSubmit({code, subDelayCode, time});
    reset();
  });

  const insertedDelay = watch('delayCode');

  return (
      <Grid spacing={1} container>
        <Grid xs={12} sm={4} item>
          <Stack>
            <Typography variant={'caption'} fontWeight={'bold'} px={1} color={'secondary.main'}>
              {t`TOTAL DELAY TIME`}
            </Typography>
            <TextField
                value={formatDurationWithNoSeconds(totalMissing)}
                error={Boolean(totalDelayTimeError)}
                helperText={totalDelayTimeError?.message}
                size={'small'}
                sx={styles.totalDelayTime}
                fullWidth
                disabled
            />
          </Stack>
        </Grid>
        <Grid xs={12} sm={4} item>
          <Stack>
            <Typography variant={'caption'} fontWeight={'bold'} px={1} color={'secondary.main'}>
              {t`DELAY CODE`}
            </Typography>
            <Controller
                name={'delayCode'}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <DelayCodesSearch
                        {...field}
                        size={'small'}
                        type={'number'}
                        error={Boolean(error)}
                        helperText={error?.message}
                        disabled={disabled}
                        fullWidth
                    />
                )}
            />
          </Stack>
        </Grid>
             <Grid xs={12} sm={4} item>
              <Stack>
                <Typography variant={'caption'} fontWeight={'bold'} px={1} color={'secondary.main'}>
                  {t`SUB DELAY CODE`}
                </Typography>
                <Controller
                    name={'subDelayCode'}
                    control={control}
                    render={({field, fieldState: {error}}) => (
                        <SubDelayCodesSearch
                            {...field}
                            delayCodeNumeric={insertedDelay?.codeNumeric}
                            size={'small'}
                            type={'number'}
                            error={Boolean(error)}
                            helperText={error?.message}
                            disabled={disabled || insertedDelay?.length === 0 || insertedDelay === null}
                            fullWidth
                        />
                    )}
                />
              </Stack>
            </Grid>
        <Grid xs={12} sm={4} item>
          <Stack>
            <Controller
                name={'delayTime'}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <DatetimePicker
                        {...field}
                        label={t`DELAY TIME`}
                        size={'small'}
                        openTo={'minutes'}
                        error={Boolean(error)}
                        helperText={error?.message}
                        disabled={disabled}
                        wheelTimePickerOnMobile
                        variant={'TIME_ONLY'}
                    />
                )}
            />
          </Stack>
        </Grid>
        <Grid xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}} item>
          <Button onClick={submitHandler} sx={{minWidth: 'unset'}} variant={'contained'} disabled={disabled}>
            <Add/>
          </Button>
        </Grid>
      </Grid>
  );
};

DelaysFormInsertCode.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  onSubmit: PropTypes.func,
  delays: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        time: PropTypes.string
      })
  ),
  totalDelayTimeError: PropTypes.shape({
    message: PropTypes.string
  }),
  disabled: PropTypes.bool
};

DelaysFormInsertCode.defaultProps = {
  onSubmit: () => null,
  delays: [],
  totalDelayTimeError: null,
  disabled: false,
};

const styles = {
  totalDelayTime: {
    '& .MuiInputBase-root': {
      backgroundColor: '#EDEFF3',
      '&.$Mui-disabled': {
        color: 'common.black'
      }
    }
  }
};

export default DelaysFormInsertCode;
