import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useAddUserMutation} from '../../../../api';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import AzureUsersSearch from '../../../../components/common/azure-users-search/AzureUsersSearch';
import {ROLES} from '../../../../utils/user/user-utils';

const ImportEmployeeDialog = ({open, onClose}) => {
  const [addUser, {data, error, isLoading}] = useAddUserMutation();
  const [employee, setEmployee] = useState(null);

  const handleSave = () => {
    addUser({
      ...employee,
      roles: [ROLES.RAMP_OFFICER],
      iataCode: 'PDL'
    }).then((r) => !r.error && onClose());
  };

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        successMessage={t`It was successfully imported!`}
      />
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Import`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <AzureUsersSearch label={t`Employees`} value={employee} onSelectUser={setEmployee} />
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant='contained' onClick={handleSave} disabled={isLoading || !employee}>
              {t`Add`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImportEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ImportEmployeeDialog.defaultProps = {
  open: true
};

export default ImportEmployeeDialog;
