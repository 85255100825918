import * as Yup from 'yup';
import {t} from 'ttag';
import {createDatetime as dt} from '../../../../../../../../utils/datetime/datetime-utils';

export const schema = Yup.object()
  .shape(
    {
      finishDate: Yup.string().nullable().test({
        test: (value) => value && dt(value).isValid,
        message: t`Should be a valid datetime.`
      })
    },
    ['finishDate']
  )
  .required();
