import React from 'react';
import PropTypes from 'prop-types';
import VerticalLabeled from '../../../../../common/VerticalLabeled';
import {Typography} from '@mui/material';

export const VerticalLabeledSummary = ({label, value}) => {
  return (
    <VerticalLabeled label={label} variant={'caption'} color={'Silver'} fontWeight={'bold'} opacity={.6}>
      {['string', 'number'].includes(typeof value) ? <Typography variant={'body2'}>{value || '-'}</Typography> : value}
    </VerticalLabeled>
  );
};

VerticalLabeledSummary.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

VerticalLabeledSummary.defaultProps = {
  label: '',
  value: ''
};

export default VerticalLabeledSummary;
