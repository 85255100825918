import {datetimeRotationMapperFromUtc} from './utils/rotation-mappers';

export const type = 'rotation';

export const rotationEndpoints = (builder) => ({
  getRotations: builder.query({
    query: (params) => ({
      url: '/rotations',
      method: 'GET',
      params
    }),
    transformResponse: (data) => data?.data.map(datetimeRotationMapperFromUtc),
    providesTags: (data) => {
      return data.map(({hash}) => ({type, id: hash})).concat({type, id: 'list'});
    },
    keepUnusedDataFor: 1,
  }),

  getStationBriefing: builder.query({
    query: ({flightDirection, body}) => ({
      url: `/rotations/${flightDirection}/station-briefing`,
      method: 'POST',
      body
    }),
    transformResponse: (data) => data?.data,
    keepUnusedDataFor: 60,
  }),
});

export default rotationEndpoints;
