import * as Yup from 'yup';
import { t } from 'ttag';

export const employeeValidationSchema = Yup.object().shape({
  name: Yup.string().required(t`Required field.`),
  email: Yup.string()
    .required(t`Required field.`)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i),
  costCenter: Yup.string().nullable(),
  iataCode: Yup.string().nullable().required(t`Required field.`),
  roles: Yup.array().nullable()
});
