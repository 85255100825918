import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {FlightBriefingRowSkeleton} from './flight-briefing-row/FlightBriefingRowSkeleton';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {styles} from './FlightBriefing';

export const FlightBriefingSkeleton = ({title, airportColumnTitle}) => {
  return (
    <TableContainer sx={{root: styles.table}} component={Paper}>
      <Table sx={styles.table} size='small' aria-label='a dense table'>
        <TableHead key={'header'} sx={styles.tableHeader}>
          <TableRow>
            <TableCell width='106' sx={styles.tableCellHeader}>
              {title}
            </TableCell>
            <TableCell width='10' />
            <TableCell width='10' />
            <TableCell width='10' />
            <TableCell sx={styles.tableCellHeader} align='right' />
          </TableRow>
        </TableHead>
        <TableHead key={'subHeader'} sx={styles.tableSubHeader}>
          <TableRow>
            <TableCell sx={styles.tableCellSubHeader}>{t`FLIGHT n.º`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>
              {airportColumnTitle}
            </TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>{t`TIME`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='center'>{t`PAX`}</TableCell>
            <TableCell sx={styles.tableCellSubHeader} align='left'>{t`MORE INFO.`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FlightBriefingRowSkeleton />
          <FlightBriefingRowSkeleton />
          <FlightBriefingRowSkeleton />
          <FlightBriefingRowSkeleton />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

FlightBriefingSkeleton.propTypes = {
  title: PropTypes.string,
  airportColumnTitle: PropTypes.string
};

FlightBriefingSkeleton.defaultProps = {
  title: '',
  airportColumnTitle: ''
};

export default FlightBriefingSkeleton;
