import PropTypes from 'prop-types';
import {DoDisturb, ClearTwoTone} from '@mui/icons-material';
import {Box, Card, Checkbox, Grid, Stack, Typography} from '@mui/material';
import TurnaroundActionToggleBtn from './TurnaroundActionToggleBtn';
export const TurnaroundActionToggleListItem = ({
  label,
  start,
  timer,
  stop,
  onClickStart,
  onClickStop,
  onEdit,
  selectMode,
  isApplicable,
  onCheckBoxChange,
  onRemoveAction,
  checked,
  isMandatory,
  manuallyAdded
}) => {
  return (
    <Grid spacing={1.3} container>
      <Grid xs={12} md={4} item>
        <Card sx={styles.card(selectMode, isApplicable)} elevation={0}>
          <Stack direction={'row'} justifyContent={selectMode ? 'start' : 'space-between'}>
            <Stack direction={'row'} display={'flex'} spacing={selectMode ? 0 : 1}>
              {selectMode && !start.finished && !isMandatory && (
                <Box>
                  <Checkbox sx={{paddingY: 0}} defaultChecked={checked} onChange={onCheckBoxChange} />
                </Box>
              )}
              {!selectMode && !start.finished && manuallyAdded && (
                <Box sx={styles.deleteActionBtn} onClick={onRemoveAction}>
                   <ClearTwoTone sx={styles.deleteActionBtnIcon}/>
                </Box>
              )}
              <Typography sx={styles.label} color={isApplicable ? 'secondary.dark' : 'text.disabled'}>
                {label}
              </Typography>
            </Stack>
            {!isApplicable && !selectMode && (
              <DoDisturb sx={{marginTop: '-5px', color: 'text.disabled'}} fontSize={'large'} />
            )}
          </Stack>
        </Card>
      </Grid>
      <Grid xs={5} md={3} item>
        {!isApplicable && (
          <Card sx={{backgroundColor: 'background.odd'}} elevation={0}>
            <Box height={'56px'} />
          </Card>
        )}
        {isApplicable && (
          <TurnaroundActionToggleBtn
            label={isApplicable && 'start'}
            backgroundColor={isApplicable && start.color}
            completed={start.finished}
            delayedDuration={start.delayedDuration}
            time={isApplicable ? start.time : ''}
            onClick={onClickStart}
            onEdit={onEdit}
            applicable
          />
        )}
      </Grid>
      <Grid xs={2} display={'flex'} item>
        <Box flex={'2'} sx={styles.timer}>
          <Typography sx={{...styles.label, margin: 'auto', color: timer.color, fontWeight: 'inherit'}}>
            {timer.time || '-'}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={5} md={3} item>
        {!isApplicable && (
          <Card sx={{backgroundColor: 'background.odd'}} elevation={0}>
            <Box height={'56px'} />
          </Card>
        )}
        {isApplicable && (
          <TurnaroundActionToggleBtn
            label={isApplicable && 'stop'}
            backgroundColor={isApplicable && stop.color}
            completed={stop.finished}
            delayedDuration={stop.delayedDuration}
            time={stop.time}
            onEdit={onEdit}
            onClick={onClickStop}
            applicable
          />
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  label: {
    fontSize: 'typography.fontSize',
    fontWeight: 'bold',
    height: 14,
    fontFamily: 'NeoSansProMedium'
  },
  card: (selectMode) => ({
    padding: 2,
    paddingLeft: selectMode ? 1 : 2,
    backgroundColor: 'background.odd',
    border: 'unset',
    height: 24
  }),
  timer: {
    display: 'flex',
    justifyContent: 'center'
  },
  deleteActionBtn : {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    borderRadius: 10,
    width: 20,
    height: 20
  },
  deleteActionBtnIcon: {
      color: 'primary.contrastText',
      width: 15
  }
};

TurnaroundActionToggleBtn.propTypes = {
  label: PropTypes.string,
  start: PropTypes.shape({
    time: PropTypes.string,
    color: PropTypes.string,
    finished: PropTypes.bool,
    delayedDuration: PropTypes.string
  }),
  timer: PropTypes.shape({
    time: PropTypes.string,
    color: PropTypes.string
  }),
  stop: PropTypes.shape({
    time: PropTypes.string,
    color: PropTypes.string,
    finished: PropTypes.bool,
    delayedDuration: PropTypes.string
  }),
  onClickStart: PropTypes.func,
  onClickStop: PropTypes.func,
  selectMode: PropTypes.bool,
  isApplicable: PropTypes.bool,
  onCheckBoxChange: PropTypes.func
};

TurnaroundActionToggleBtn.defaultProps = {
  label: '',
  start: {
    time: '',
    color: '',
    finished: false,
    delayedDuration: ''
  },
  timer: {
    time: '',
    color: ''
  },
  stop: {
    time: '',
    color: '',
    finished: false,
    delayedDuration: ''
  },
  onClickStart: () => null,
  onClickStop: () => null,
  selectMode: false,
  isApplicable: true,
  onCheckBoxChange: () => null
};

export default TurnaroundActionToggleBtn;
