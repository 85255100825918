import {t} from 'ttag';
import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, Box, TextField} from '@mui/material';
import {delays} from './delay-codes';

const SubDelayCodesSearch = forwardRef(
  ({value, onChange, label, size, error, helperText, required, disableClearable, disabled, delayCodeNumeric}, ref) => {

    const filteredSubDelays = delays.filter(delay => {
      return delay?.subDelayCodes?.some(subDelay => subDelay.code.includes(delayCodeNumeric));
    }).shift()?.subDelayCodes || [];

    return (
      <Autocomplete
        ref={ref}
        size={size}
        noOptionsText={t`No options`}
        disabled={disabled}
        options={filteredSubDelays}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        getOptionLabel={(opt) => `${opt.code}`}
        onChange={(_, subDelayCode) => onChange(subDelayCode)}
        value={value}
        filterOptions={(opts, {inputValue = ''}) => {
          return opts.filter((o) =>
            `${o.code} ${o.description}`.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            label={label}
            helperText={helperText}
            variant={'outlined'}
            placeholder={t`Start typing...`}
          />
        )}
        renderOption={(params, opt) => (
          <Box {...params}>
            <span>
              <strong>{opt.code}</strong> {opt.description}
            </span>
          </Box>
        )}
      />
    );
  }
);

SubDelayCodesSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  }),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disableClearable: PropTypes.bool,
  delayCodeNumeric: PropTypes.string,
};

SubDelayCodesSearch.defaultProps = {
  onChange: () => null,
  value: null,
  label: undefined,
  size: 'small',
  error: false,
  helperText: '',
  required: false,
  disableClearable: false,
  delayCodeNumeric: '',
};

export default SubDelayCodesSearch;
