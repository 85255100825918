/** @type {{[key: string]: RoleName}} */
export const ROLES = {
  ADMIN: 'Admin',
  RAMP_OFFICER: 'Ramp Officer',
  SUPERVISOR: 'Supervisor',
  OBSERVER: 'Observer'
};

/**
 * Checks if the provided user has some of provided roles
 * @param {User} [user]
 * @param {RoleName[]} [roles]
 * @return boolean
 */
export const hasSomeOf = (user, roles = []) => !roles.length || roles.some((rName) => user?.roles.some((uRole) => uRole.toLowerCase() === rName.toLowerCase()));

/**
 * Checks if the provided user has every provided roles
 * @param {User} [user]
 * @param {RoleName[]} [roles]
 * @return boolean
 */
export const hasEveryOf = (user, roles = []) => !roles.length || roles.every((rName) => user?.roles.some((uRole) => uRole.toLowerCase() === rName.toLowerCase()));