import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, Typography} from '@mui/material';
import {t} from 'ttag';

export const GenericError = ({title, description, imgSrc, children}) => {
  const navigate = useNavigate();

  return (
    <Box sx={classes.root}>
      <Container maxWidth='lg'>
        <Typography align='center' variant={'h1'} fontSize={'2.5rem'} color='textPrimary'>
          {title}
        </Typography>
        <Typography align='center' variant='subtitle2' color='textSecondary'>
          {description}
        </Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <Box component={'img'} sx={classes.image} alt='error view' src={imgSrc} />
        </Box>
        <Box mt={6} display='flex' justifyContent='center'>
          {children || (
            <Button color='primary' onClick={() => navigate('/')} variant='outlined'>
              {t`Home`}
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

GenericError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  children: PropTypes.any,
};

GenericError.defaultProps = {
  title: 'Whoops...',
  description: '',
  imgSrc: `${process.env.PUBLIC_URL}/static/images/undraw_close_tab_uk6g.svg`,
  children: null,
};

const classes = {
  root: {
    backgroundColor: 'background.dark',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme => theme.spacing(3),
    paddingTop: '80px',
    paddingBottom: '80px'
  },
  image: {
    maxWidth: '100%',
    width: '560px',
    maxHeight: '300px',
    height: 'auto'
  }
};

export default GenericError;
