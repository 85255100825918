import React from 'react';
import {Skeleton, TableCell, TableRow} from '@mui/material';

export const FlightBriefingRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
  </TableRow>
);

export default FlightBriefingRowSkeleton;
