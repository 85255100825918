import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
import ATAForm from './form-ata/ATAForm';
import ETDForm from './form-etd/ETDForm';
import ATDForm from './form-atd/ATDForm';
import DelaysForm from './form-delays/DelaysForm';

const RotationViewFlightForms = ({rotation, openedForm, onClose}) => {
  if (!openedForm) return null;

  return (
    <Box sx={{backgroundColor: 'background.default', p: 1}}>
      {openedForm === FORMS.ETD && <ETDForm rotation={rotation} onClose={onClose} />}
      {openedForm === FORMS.ATD && <ATDForm rotation={rotation} onClose={onClose} />}
      {openedForm === FORMS.ATA && <ATAForm rotation={rotation} onClose={onClose} />}
      {openedForm === FORMS.DELAYED && <DelaysForm rotation={rotation} onClose={onClose} />}
    </Box>
  );
};

export const FORMS = {
  ETD: 'ETD',
  ATD: 'ATD',
  ATA: 'ATA',
  DELAYED: 'DELAYED',
  TURNAROUND:'TURNAROUND'
};

RotationViewFlightForms.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  openedForm: PropTypes.oneOfType([PropTypes.oneOf(Object.values(FORMS)), PropTypes.bool]),
  onClose: PropTypes.func
};

RotationViewFlightForms.defaultProps = {
  openedForm: null,
  onClose: () => null
};

export default RotationViewFlightForms;
