import {AzureAuthProvider as AuthProvider} from 'sata-azure-auth';
import {MSA_SCOPES} from '../../config/settings';
import msalInstance from '../msal-instance';

export const AzureAuthProvider = ({children}) => {
  return (
    <AuthProvider instance={msalInstance} scopes={MSA_SCOPES}>
      {children}
    </AuthProvider>
  );
};

export default AzureAuthProvider;