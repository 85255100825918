import {useStore} from '../../../store/valtio';


/** @type {() => number} */
export const useRotationTimerTick = () => {
  const {rotations: {timer: {tick}}} = useStore();

  return tick;
};

export default useRotationTimerTick;
