import {t} from 'ttag';
import {createDatetime, DATE_FORMAT, nowTz} from '../../../utils/datetime/datetime-utils';
import PropTypes from 'prop-types';
import {Box, Tab, Tabs, Typography, useMediaQuery} from '@mui/material';


export const RotationOperationDayTabsFilter = ({onChange, currentDate, value}) => {
  const yesterday = createDatetime(nowTz()).plus({days: -1});
  const today = createDatetime(nowTz());
  const tomorrow = createDatetime(nowTz()).plus({days: 1});
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Tabs
      value={value}
      variant={'fullWidth'}
      onChange={(_, oDay) => onChange(oDay)}
      sx={styles.root}
      orientation={isTablet ? 'vertical' : 'horizontal'}
      TabIndicatorProps={{sx: {height: '4px'}}}
    >
      <Tab sx={styles.tab} value={yesterday.toFormat(DATE_FORMAT)} label={t`Yesterday`} />
      <Tab
        sx={styles.tab}
        value={today.toFormat(DATE_FORMAT)}
        label={
          <Box sx={{display: 'flex', gap: 0.8}}>
            <span>{t`flights`}</span>
            <Typography color={'primary'} fontWeight={'bold'}>
              {today.toFormat('MMM d')}
            </Typography>
          </Box>
        }
      />
      <Tab sx={styles.tab} value={tomorrow.toFormat(DATE_FORMAT)} label={t`Tomorrow`} />
    </Tabs>
  );
};

RotationOperationDayTabsFilter.propTypes = {
  currentDate: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

RotationOperationDayTabsFilter.defaultProps = {
  currentDate: nowTz('UTC', DATE_FORMAT),
  value: nowTz('UTC', DATE_FORMAT),
  onChange: () => {}
};

const styles = {
  root: {
    borderBottom: {xs: 'none', md: '1px solid'},
    borderColor: 'secondary.main'
  },
  tab: {
    fontSize: 18,
    color: 'secondary.light',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      color: 'secondary.main',
      fontWeight: 'bold'
    }
  }
};

export default RotationOperationDayTabsFilter;
