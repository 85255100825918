import React from 'react';
import PropTypes from 'prop-types';
import {Home} from '@mui/icons-material';
import {useMatch, useNavigate} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText, useMediaQuery} from '@mui/material';
import {actions} from '../../store/valtio';

const SidebarLink = ({path, icon, text, itemKey, exact}) => {
  const navigate = useNavigate();
  const matches = useMatch({path, end: exact});
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const clickHandler = () => {
    navigate(path);
    if (!isDesktop) {
      actions.app.setSideBarOpen(false);
    }
  };

  return (
    <ListItem key={itemKey} onClick={clickHandler} sx={matches ? styles.activeButton : {}} button>
      <ListItemIcon sx={styles.icon} children={icon} />
      <ListItemText primary={text} />
    </ListItem>
  );
};

const styles = {
  icon: {
    color: 'secondary.contrastText',
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: theme => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  activeButton: {
    backgroundColor: 'secondary.light',
    borderLeftWidth: '0.250rem',
    borderStyle: 'solid',
    borderLeftColor: 'primary.main',
    paddingLeft: theme => theme.spacing(1) * 1.5,
    '&:hover': {
      backgroundColor: 'secondary.light'
    }
  },
};

SidebarLink.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
  itemKey: PropTypes.any,
};

SidebarLink.defaultProps = {
  exact: false,
  path: '/',
  icon: <Home/>,
  text: 'Link',
};

export default SidebarLink;
