import * as Yup from 'yup';
import {t} from 'ttag';
import {createDatetime as dt} from '../../../../../../../../utils/datetime/datetime-utils';

export const schema = Yup.object()
  .shape(
    {
      startDate: Yup.string().nullable().test({
        test: (value) => value && dt(value).isValid,
        message: t`Should be a valid datetime.`
      }),
      finishDate: Yup.string().nullable()
      .when('startDate', (startDate, schema) => {
          return schema.test({
              test: (value) => !value || (startDate && dt(value) >= dt(startDate)),
              message: t`Finish should be after Start.`
          });
      }),
    },
    ['startDate', 'finishDate']
  )
  .required();
