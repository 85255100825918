import React from 'react';
import PropTypes from 'prop-types';
import {Stack, Typography} from '@mui/material';
import VerticalLabeled from '../../../../../common/VerticalLabeled';
import {formatTime, toUTC} from '../../../../../../utils/datetime/datetime-utils';

export const VerticalLabeledTime = ({label, time, children}) => {
  const local = formatTime(time, true) || '-';
  const utc = time ? `${formatTime(toUTC(time), true)} (UTC)` : '-';
  return (
    <VerticalLabeled label={label} variant={'caption'} color={'silver'} fontWeight={'bold'} opacity={0.6}>
      <Stack>
        <Stack direction={'row'} gap={.6}>
          <Typography variant={'body2'}>{local}</Typography>
          {children}
        </Stack>
        <Typography variant={'body2'}>{utc}</Typography>
      </Stack>
    </VerticalLabeled>
  );
};

VerticalLabeledTime.propTypes = {
  label: PropTypes.string,
  time: PropTypes.string,
  children: PropTypes.any
};

export default VerticalLabeledTime;
