import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Grid, MenuItem, OutlinedInput, Select, Typography} from '@mui/material';

export const TurnaroundRecordsControlActions = ({unassignedActions, onSubmit, onDelete}) => {
  const [actionId, setActionId] = useState('');
  return (
    <Grid sx={styles.container} p={3} gap={1} container>
      <Grid xs={12} md={7.5} item>
        <Select
          value={actionId}
          input={<OutlinedInput />}
          sx={styles.selectField}
          onChange={(e) => setActionId(e.target.value)}
        >
          {unassignedActions.length
              ? unassignedActions.map((r, idx) => (
                <MenuItem key={idx} value={r.id}>
                  {r.description}
                </MenuItem>
              ))
              : <MenuItem value={''} disabled>There are no activities to be added.</MenuItem>
          }
        </Select>
      </Grid>
      <Grid xs={12} md={3} item>
        <Button variant={'contained'} sx={styles.addBtn} onClick={() => actionId && onSubmit(actionId)}>
          <Typography sx={styles.addBtnText}>{t`ADD`}</Typography>
        </Button>
      </Grid>
      <Grid xs={12} md={1} item>
        <Button variant={'outlined'} sx={styles.addBtn} onClick={onDelete}>
          <Typography sx={styles.addBtnText}>x</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    justifyContent: 'center'
  },
  selectField: {
    width: '100%',
    height: 48,
    backgroundColor: 'white'
  },
  addBtn: {
    width: '100%',
    height: '100%'
  },
  addBtnText: {
    fontWeight: 'bold',
    fontSize: 'typography.fontSize'
  }
};

TurnaroundRecordsControlActions.propTypes = {
  unassignedActions: PropTypes.array,
  onSubmit: PropTypes.func,
  onDeleted: PropTypes.func
};

TurnaroundRecordsControlActions.defaultProps = {
  unassignedActions: [],
  onSubmit: () => null,
  onDeleted: () => null
};

export default TurnaroundRecordsControlActions;
