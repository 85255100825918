import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Box, LinearProgress, Typography} from '@mui/material';
import {useAzureAuth, useAzureLogin} from 'sata-azure-auth';
import {useNavigate} from 'react-router-dom';
import logo from '../../../assets/sata-logo.png';

const Login = () => {
  const {isAuthenticated, isInitialised} = useAzureAuth();
  const login = useAzureLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInitialised && !isAuthenticated) {
      login();
    }

    if (isInitialised && isAuthenticated) {
      const redirectTo = window.sessionStorage.getItem('_redirect_');
      const path = redirectTo ? redirectTo : '/';
      navigate(path);
    }
  }, [isAuthenticated, isInitialised, login, navigate]);

  return (
    <Box sx={classes.root}>
      <Typography align='center' variant='h2'>{t`Login, please wait...`}</Typography>
      <Box width='20rem'>
        <LinearProgress />
      </Box>
      <Typography
        component='p'
        sx={classes.caption}
        variant='caption'
      >{t`Contacting Microsoft Azure...`}</Typography>
      <Box mt={10} display='flex' justifyContent='center'>
        <Box
          sx={classes.image}
          component={'img'}
          alt='Under development'
          src={`${process.env.PUBLIC_URL}/static/images/undraw_Secure_server_re_8wsq.svg`}
        />
      </Box>
      <Box mt={2} display='flex' justifyContent='flex-end' width='24rem'>
        <Box
          sx={classes.logo}
          component={'img'}
          alt='SATA'
          src={logo}
        />
      </Box>
    </Box>
  );
};

const classes = {
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme => theme.spacing(3),
    paddingTop: theme => theme.spacing(10)
  },
  caption: {
    justifyContent: 'flex-start',
    marginTop: theme => theme.spacing(1),
    display: 'flex',
    width: '20rem'
  },
  image: {
    maxWidth: '100%',
    width: '35rem',
    maxHeight: '16.75rem',
    height: 'auto'
  },
  logo: {
    width: '9.375rem'
  }
};

export default Login;
