import {t} from 'ttag';
import {Controller, useForm} from 'react-hook-form';
import {Grid, Stack, TextField} from '@mui/material';
import FormActions from '../../../../rotation-view-flight-forms/resources/form-actions/FormActions';
import {useUpdateSandGateBeltMutation} from '../../../../../../../../api';
import RTKQueryFeedback from '../../../../../../../common/RTKQueryFeedback';
import {actions} from '../../../../../../../../store/valtio';

export const StandForms = ({rotation, onClose}) => {
  const [editForm, {isLoading, error}] = useUpdateSandGateBeltMutation();
  const {inbound, outbound} = rotation;

  const {handleSubmit, control} = useForm({
    // resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      stand: inbound?.flightDetails.stand || outbound?.flightDetails.stand || '',
      gate: outbound?.flightDetails.gate || '',
      belt: inbound?.flightDetails.belt || ''
    }
  });

  const submitHandler = handleSubmit((values) => {
    editForm({body: values, hash: rotation.hash}).then(({data}) => {
      if (data) {
        actions.rotations.setNotifiedRotation({
          ...data
        });
        onClose();
      }
    });
  });

  return (
    <Stack spacing={1} mt={2}>
      <Grid spacing={2} container>
        {inbound && (
          <Grid xs={12} sm={inbound && outbound ? 4 : 6} item>
            <Controller
              name={'belt'}
              control={control}
              render={({field, fieldState: {error}}) => (
                <TextField {...field} label={t`BELT`} error={Boolean(error)} helperText={error?.message} fullWidth />
              )}
            />
          </Grid>
        )}
        <Grid xs={12} sm={inbound && outbound ? 4 : 6} item>
          <Controller
            name={'stand'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <TextField {...field} label={t`Stand`} error={Boolean(error)} helperText={error?.message} fullWidth />
            )}
          />
        </Grid>
        {outbound && (
          <Grid xs={12} sm={inbound && outbound ? 4 : 6} item>
            <Controller
              name={'gate'}
              control={control}
              render={({field, fieldState: {error}}) => (
                <TextField {...field} label={t`Gate`} error={Boolean(error)} helperText={error?.message} fullWidth />
              )}
            />
          </Grid>
        )}
      </Grid>
      <FormActions btnText={t`Update`} onClick={submitHandler} onClose={onClose} />
      <RTKQueryFeedback isLoading={isLoading} error={error} />
    </Stack>
  );
};

StandForms.propTypes = {};

StandForms.defaultProps = {};

export default StandForms;
