import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {Feedback} from '@mui/icons-material';
import MainLayout from '../../../layout/MainLayout';
import {AuthenticationGuard, AuthorizationGuard} from '../guards';

const Page = ({children, userHasSomeOf, userHasEveryOf, withMainLayout, withAuthentication}) => {
  const GuardAuthentication = withAuthentication ? AuthenticationGuard : Default;
  const GuardAuthorization = userHasSomeOf.length || userHasEveryOf.length ? AuthorizationGuard : Default;
  const LayoutMain = withMainLayout ? MainLayout : Default;

  return (
    <GuardAuthentication>
      <GuardAuthorization
        userHasSomeOf={userHasSomeOf}
        userHasEveryOf={userHasEveryOf}
        showErrorView
        redirectToUnauthorized
      >
        <LayoutMain>{children}</LayoutMain>
        <Box position={'fixed'} bottom={15} right={15}>
          <Button
            sx={{
              borderRadius: 100,
              minWidth: '100%',
              padding: '16px'
            }}
            variant='contained'
            onClick={() => window.open('https://forms.office.com/r/eGbASSHTYh','','scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no')}
          >
            <Feedback />
          </Button>
        </Box>
      </GuardAuthorization>
    </GuardAuthentication>
  );
};

const Default = ({children}) => children;

Page.propTypes = {
  children: PropTypes.any,
  withMainLayout: PropTypes.bool,
  withAuthentication: PropTypes.bool,
  userHasSomeOf: PropTypes.arrayOf(PropTypes.string),
  userHasEveryOf: PropTypes.arrayOf(PropTypes.string)
};

Page.defaultProps = {
  children: null,
  withMainLayout: true,
  withAuthentication: true,
  userHasSomeOf: [],
  userHasEveryOf: []
};

export default Page;
