import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';

export const RotationPatternFilter = ({value, onChange}) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      size={'small'}
      label={t`Tail or flight number…`}
      sx={{root: styles.label}}
      variant={'outlined'}
      InputProps={{type: 'search'}}
      fullWidth
    />
  );
};

const styles = {
  label: {
    marginTop: 0,
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

RotationPatternFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

RotationPatternFilter.defaultProps = {
  onChange: () => null,
  value: ''
};

export default RotationPatternFilter;
