import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, TextField} from '@mui/material';
import {ROTATION_STATES} from '../../../utils/rotation/rotation-utils';

export const RotationStateFilter = ({value, onChange}) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={t`Filter by`}
      size={'small'}
      variant={'outlined'}
      select
      fullWidth
    >
      <MenuItem value={ROTATION_STATES.ALL} sx={{color: 'black'}}>
        {t`All`}
      </MenuItem>
      <MenuItem value={ROTATION_STATES.DELAYED} sx={{color: 'black'}}>
        {t`Delayed`}
      </MenuItem>
      <MenuItem value={ROTATION_STATES.DELAYED_WITHOUT_JUSTIFICATION} sx={{color: 'black'}}>
        {t`Delayed Without Justification`}
      </MenuItem>
      <MenuItem value={ROTATION_STATES.RUNNING} sx={{color: 'black'}}>
        {t`Running`}
      </MenuItem>
      <MenuItem value={ROTATION_STATES.NOT_RUNNING} sx={{color: 'black'}}>
        {t`Not Running`}
      </MenuItem>
    </TextField>
  );
};

RotationStateFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

RotationStateFilter.defaultProps = {
  onChange: () => null,
  value: ''
};

export default RotationStateFilter;
