import {Card} from '@mui/material';
import RotationCardHeader from './resources/rotation-card-header/RotationCardHeader';
import RotationCardContent from './resources/rotation-card-content/RotationCardContent';
import {ROTATION_SHAPE} from '../common/rotation-shapes/rotation-shapes';

export const RotationCard = ({rotation}) => (
  <Card id={rotation.hash}>
    <RotationCardHeader rotation={rotation} />
    <RotationCardContent rotation={rotation} />
  </Card>
);

RotationCard.propTypes = {
  rotation: ROTATION_SHAPE.isRequired
};

export default RotationCard;
