import React from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

const VerticalLabeled = ({label, labelColor, alignment, children, ...labelProps}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={alignment}>
      <Typography color={labelColor} {...labelProps} >{label}</Typography>
      {children}
    </Box>
  );
};

VerticalLabeled.propTypes = {
  label: PropTypes.string,
  labelColor: PropTypes.string,
  alignment: PropTypes.oneOf(['flex-start', 'center', 'flex-end'])
};

export default VerticalLabeled;
