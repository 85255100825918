import {airports} from '../../components/common/local-airport-search';
import {DateTime} from 'luxon';

export const initialState = {
  app: {
    sideBarOpen: false
  },
  rotations: {
    notifiedRotation: null,
    rotationView: {
      rotation: null,
      tab: null,
    },
    timer: {
      running: true,
      tick: 0
    },
    filters: {
      airport: airports.find((a) => a.iataCode === 'PDL'),
      pattern: '',
      state: '',
      operationDay: DateTime.now().setZone('UTC').toFormat('yyyy-MM-dd')
    }
  }
};

export default initialState;
