import {t} from 'ttag';
import {useState} from 'react';
import {Box, Grid, Stack} from '@mui/material';
import {determineFlight, determineOperation, FLIGHT_DIRECTIONS} from '../../../../../utils/rotation/rotation-utils';
import RotationViewFlightForms, {FORMS} from '../rotation-view-flight-forms/RotationViewFlightForms';
import {FLIGHT_DIRECTION, ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
import RotationViewFlightSummaryStatus from '../common/RotationViewFlightSummaryStatus';
import VerticalLabeledSummary from './resources/VerticalLabeledSummary';
import {AuthorizationGuard} from '../../../../../pages/common/guards';
import VerticalLabeledTime from './resources/VerticalLabeledTime';
import FormEditToggleBtn from '../common/FormEditToggleBtn';
import {ROLES} from '../../../../../utils/user/user-utils';

export const RotationViewFlightSummary = ({rotation, flightDirection}) => {
  const [openedForm, setOpenedForm] = useState(null);
  const flight = determineFlight(rotation, flightDirection);
  const operation = determineOperation(rotation, flightDirection);
  const {scheduledTime, estimatedTime, actualTime, demarcationTime} = operation;
  const isOutbound = flightDirection === FLIGHT_DIRECTIONS.OUTBOUND;
  const canEditActualTime = !isOutbound || (isOutbound && (!rotation?.inbound || rotation.inbound.arrival.actualTime));

  const {
    flightDetails: {stand, gate, belt}
  } = flight;

  return (
    <Stack>
      <Box backgroundColor={'secondary.main'} color={'secondary.contrastText'} borderRadius={1}>
        <Grid container padding={2} spacing={2}>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledSummary label={t`FLIGHT NUMBER`} value={flight.flightNumber} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledSummary
              label={t`FLIGHT LEG`}
              value={`${flight.origin.iataCode}-${flight.destination.iataCode}`}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledSummary
              label={isOutbound ? t`STAND/GATE` : 'STAND/BELT'}
              value={isOutbound ? `${stand || '-'} / ${gate || '-'}` : `${stand || '-'} / ${belt || '-'}`}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledSummary
              label={t`STATUS`}
              value={
                <RotationViewFlightSummaryStatus
                  operation={operation}
                  toggleComponent={
                    isOutbound && (
                      <FormEditToggleBtn
                        onToggle={(o) => setOpenedForm(o && FORMS.DELAYED)}
                        open={openedForm === FORMS.DELAYED}
                      />
                    )
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledTime label={isOutbound ? t`STD` : t`STA`} time={scheduledTime} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledTime label={isOutbound ? t`ETD` : t`ETA`} time={estimatedTime}>
              {isOutbound && (
                <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
                  <FormEditToggleBtn onToggle={(o) => setOpenedForm(o && FORMS.ETD)} open={openedForm === FORMS.ETD} />
                </AuthorizationGuard>
              )}
            </VerticalLabeledTime>
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledTime
              label={isOutbound ? t`ATD` : t`TOUCH DOWN`}
              time={isOutbound ? actualTime : demarcationTime}
            >
              {canEditActualTime && (
                <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
                  <FormEditToggleBtn
                    onToggle={(o) => setOpenedForm(o && (isOutbound ? FORMS.ATD : FORMS.ATA))}
                    open={[FORMS.ATD, FORMS.ATA].includes(openedForm)}
                  />
                </AuthorizationGuard>
              )}
            </VerticalLabeledTime>
          </Grid>
          <Grid item xs={6} sm={3}>
            <VerticalLabeledTime
              label={isOutbound ? t`TAKE OFF` : t`ATA`}
              time={isOutbound ? demarcationTime : actualTime}
            >
              {canEditActualTime && (
                <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER]}>
                  <FormEditToggleBtn
                    onToggle={(o) => setOpenedForm(o && (isOutbound ? FORMS.ATD : FORMS.ATA))}
                    open={[FORMS.ATD, FORMS.ATA].includes(openedForm)}
                  />
                </AuthorizationGuard>
              )}
            </VerticalLabeledTime>
          </Grid>
        </Grid>
      </Box>
      <RotationViewFlightForms rotation={rotation} openedForm={openedForm} onClose={() => setOpenedForm(null)} />
    </Stack>
  );
};

RotationViewFlightSummary.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  flightDirection: FLIGHT_DIRECTION.isRequired
};

export default RotationViewFlightSummary;
