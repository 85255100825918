import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {A, C, E, S} from '../../utils/rotation/rotation-utils';

const CircularStatus = ({color, char, size, excludedChars}) => {
  let initial = !excludedChars.includes(char) && char;
  if (typeof initial === 'string') {
    initial = initial.substring(0, 1);
  }

  return (
    <Box sx={styles.marker(color, size)}>
      <span>{initial}</span>
    </Box>
  );
};

CircularStatus.propTypes = {
  color: PropTypes.string,
  char: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['small', 'medium']),
  excludedChars: PropTypes.arrayOf(PropTypes.oneOf([A, E, S, C, 0]))
};

CircularStatus.defaultProps = {
  color: 'gray',
  char: '',
  size: 'medium',
  excludedChars: [S, C]
};

const styles = {
  marker: (color, size) => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      fontSize: size === 'medium' ? '14px' : '12px',
      fontFamily: 'NeoSansProMedium',
      backgroundColor: color,
      width: size === 'medium' ? '20px' : '16px',
      height: size === 'medium' ? '20px' : '16px',
      lineHeight: size === 'medium' ? '20px' : '16px',
      textTransform: 'uppercase',
      '& span': {
        paddingTop: '2px'
      }
    };
  }
};

export default CircularStatus;
