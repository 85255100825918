import React from 'react';
import {Box, Divider, Stack, Tab, Tabs} from '@mui/material';
import {t} from 'ttag';
import {useLocation, useNavigate, Outlet} from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  return (
    <Stack gap={1}>
      <Box>
        <Tabs
          value={pathname}
          onChange={(_, tab) => navigate(tab)}
          variant={'scrollable'}
          scrollButtons={'auto'}
        >
          <Tab label={t`Rules`} value={`/settings/rules`} />
          <Tab label={t`Employees`} value={`/settings/employees`} />
          <Tab label={t`Roles`} value={`/settings/roles`} />
        </Tabs>
        <Divider/>
      </Box>
      <Outlet/>
    </Stack>
  );
};

export default Settings;
