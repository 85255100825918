import React from 'react';
import PropTypes from 'prop-types';
import {Close} from '@mui/icons-material';
import {Box, DialogTitle, IconButton, Typography} from '@mui/material';

export const RotationViewDialogHeader = ({title, subTitle, children, onClose}) => {
  return (
    <DialogTitle sx={{m: 0, p: 2}}>
      <Box style={{display: 'flex'}}>
        {title && (
          <Typography
            variant={'h6'}
            color={'secondary'}
            sx={{
              textTransform: 'uppercase',
              fontSize: 22
            }}
          >
            <strong>{title}</strong>
          </Typography>
        )}
        &nbsp;
        {subTitle && (
          <Typography
            variant={'h6'}
            color={'secondary.main'}
            sx={{
              fontSize: 20,
              lineHeight: '35px'
            }}
          >
            {subTitle}
          </Typography>
        )}
        {children}
      </Box>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

RotationViewDialogHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClose: PropTypes.func
};

RotationViewDialogHeader.defaultProps = {
  title: '',
  subTitle: '',
  onClose: () => null
};

export default RotationViewDialogHeader;
