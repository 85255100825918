import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';
import {Autocomplete, Box, TextField} from '@mui/material';
import {filteredAirports} from './airports';

export const LocalAirportSearch = forwardRef(
  ({value, onChange, label, size, error, helperText, required, disableClearable}, ref) => {
    return (
      <Autocomplete
        ref={ref}
        size={size}
        noOptionsText={t`No options`}
        options={filteredAirports}
        isOptionEqualToValue={(option, value) => option.iataCode === value.iataCode}
        getOptionLabel={(opt) => `${opt.iataCode}`}
        onChange={(_, airport) => onChange(airport)}
        value={value}
        disableClearable={disableClearable}
        filterOptions={(opts, {inputValue = ''}) => {
          return opts.filter((o) =>
            `${o.iataCode} ${o.name} ${o.country} ${o.city}`.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            error={error}
            label={label}
            helperText={helperText}
            variant={'outlined'}
            placeholder={t`Start typing...`}
          />
        )}
        renderOption={(params, opt) => (
          <Box {...params}>
            <span>
              <strong>{opt.iataCode}</strong> {opt.name}
            </span>
          </Box>
        )}
      />
    );
  }
);

LocalAirportSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    iataCode: PropTypes.string.isRequired
  }),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disableClearable: PropTypes.bool
};

LocalAirportSearch.defaultProps = {
  onChange: () => null,
  value: null,
  label: 'Airports',
  size: 'small',
  error: false,
  helperText: '',
  required: false,
  disableClearable: false
};

export default LocalAirportSearch;
