import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import VerticalLabeled from '../../../../common/VerticalLabeled';

export const RotationCardDetail = ({alignment, label, value, children, gap}) => {
  return (
    <Box sx={styles.detail(alignment)}>
      <VerticalLabeled label={label} labelColor={'secondary'} sx={styles.verticalLabeled} alignment={alignment}>
        <Box display={'flex'} alignment={alignment} gap={gap}>
          <Typography variant='body2' color={'secondary'} sx={styles.typography}>
            {value}
          </Typography>
          {children}
        </Box>
      </VerticalLabeled>
    </Box>
  );
};

RotationCardDetail.propTypes = {
  alignment: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
  gap: PropTypes.number
};

RotationCardDetail.defaultProps = {
  alignment: 'flex-start',
  label: null,
  value: null,
  children: null,
  gap: 1
};

const styles = {
  verticalLabeled: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  typography: {
    opacity: 1,
    fontSize: 16
  },
  detail: (alignment) => ({
    flex: {xs: 12, md: 4},
    display: 'flex',
    justifyContent: alignment
  })
};

export default RotationCardDetail;
