import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Dialog, Grid, Stack} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import TurnaroundActionVerification from './turnaround-action-verification/TurnaroundActionVerification';
import TurnaroundActionChecklist from './turnaround-action-checklist/turnaroundActionChecklist';
import TurnaroundSelectToolbar from '../turnaround-select-toolbar/TurnaroundSelectToolbar';
import {ACTION_TYPES} from '../../../../../../../utils/turnaround/turnaround-utils';
import TurnaroundActionTask from './turnaround-action-task/TurnaroundActionTask';
import {AuthorizationGuard} from '../../../../../../../pages/common/guards';
import {hasSomeOf, ROLES} from '../../../../../../../utils/user/user-utils';
import TurnaroundEditRecordForm from '../common/TurnaroundEditRecordForm';
import RTKQueryFeedback from '../../../../../../common/RTKQueryFeedback';
import ConfirmDialog from '../../../../../../common/ConfirmDialog';
import {
  RECORD_CHECKLIST_SHAPE,
  RECORD_TASK_SHAPE,
  RECORD_VERIFICATION_SHAPE
} from '../common/record-shapes/record-shapes';
import {
  useApplicableRecordsMutation,
  useFinishActionMutation,
  useGetCurrentUserQuery,
  useRemoveActionMutation,
  useStartActionMutation,
  useUnFinishActionMutation,
  useUnStartActionMutation,
  useUpdateActionMutation,
  useUpdateRecordMutation
} from '../../../../../../../api';


export const TurnaroundRecordsList = ({rotationHash, records, selectMode, setSelectMode}) => {
  const {data: user} = useGetCurrentUserQuery();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(null);
  const [toChangeApplicable, setToChangeApplicable] = useState([]);
  const [onApplyApplicable, applyApplicable] = useApplicableRecordsMutation();
  const [onStartAction, startAction] = useStartActionMutation();
  const [onFinishAction, finishAction] = useFinishActionMutation();
  const [onCancelStartAction, cancelStartAction] = useUnStartActionMutation();
  const [onCancelFinishAction, cancelFinishAction] = useUnFinishActionMutation();
  const [onRemoveAction, removeAction] = useRemoveActionMutation();
  const [onUpdateAction, updateAction] = useUpdateActionMutation();
  const [onUpdateRecord, updateRecord] = useUpdateRecordMutation();

  useEffect(() => {
    if (cancelStartAction.isSuccess || cancelFinishAction.isSuccess) {
      setShowConfirmationDialog(null);
    }
  }, [cancelStartAction, cancelFinishAction]);

  useEffect(() => {
    if (updateAction.isSuccess || updateRecord.isSuccess) {
      setShowEditDialog(null);
    }
  }, [updateAction, updateRecord]);

  useEffect(() => {
    if (applyApplicable.isSuccess) {
      setSelectMode(false);
    }
  // eslint-disable-next-line
  }, [applyApplicable]);

  const numSelectedItems = useMemo(() => {
    return (
      toChangeApplicable.filter((e) => !e.applicable).length +
      records.filter(
        (record) => !toChangeApplicable.some((e) => record.recordId === e.recordId) && !record.action.applicable
      ).length
    );
  }, [toChangeApplicable, records]);

  const onChangeApplicable = (record, isNotApplicable) => {
    if (!record.action.applicable === isNotApplicable) {
      return setToChangeApplicable((prevArray) => [...prevArray.filter((e) => e.recordId !== record.recordId)]);
    }

    return setToChangeApplicable((prevArray) => [
      ...prevArray.filter((e) => e.recordId !== record.recordId),
      {recordId: record.recordId, applicable: !isNotApplicable}
    ]);
  };
  const onChangeALL = (record, value) => {
    const action = {
      ...record.action,
      listItems: [
        ...record.action.listItems.map((item) => {
          return {...item, finished: value};
        })
      ]
    };
    onUpdateAction({params: record, body: {action}});
  };

  const onCheckAll = (record) => {
    onChangeALL(record, true);
    onFinishAction(record?.recordId);
  };
  const onUnCheckAll = (record) => {
    onChangeALL(record, false);
    onCancelFinishAction(record?.recordId);
  };

  return (
    <>
      <Stack rowGap={2}>
        <AuthorizationGuard userHasSomeOf={[ROLES.SUPERVISOR, ROLES.ADMIN]}>
          <TurnaroundSelectToolbar
            numSelectedItems={numSelectedItems}
            selectable={selectMode}
            onChange={(state) => setSelectMode(state)}
            onConfirm={() => {onApplyApplicable(toChangeApplicable); setToChangeApplicable([])}}
            onCancel={() => {
              setToChangeApplicable([]);
              setSelectMode(false);
            }}
          />
        </AuthorizationGuard>
        <Grid spacing={2} container>
          {records
            .filter((e) => e.action.type !== ACTION_TYPES.TASK)
            .sort((a, b) => a.action.weight - b.action.weight)
            .map((record, idx) => (
              <Grid key={idx} xs={6} md={3} item>
                {record.action.type === ACTION_TYPES.CHECKLIST ? (
                  <TurnaroundActionChecklist
                    readOnly={!hasSomeOf(user, [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER])}
                    record={record}
                    selectMode={selectMode}
                    onCheckBoxChange={(_, value) => onChangeApplicable(record, value)}
                    onRemoveAction={() => onRemoveAction({rotationHash, recordId: record?.recordId})}
                    onFinishAction={() => onFinishAction(record?.recordId)}
                    onCancelFinishAction={() => setShowConfirmationDialog({type: 'finish', recordId: record?.recordId})}
                    onUpdateAction={(body) => onUpdateAction(body)}
                    onSetShowEditDialog={() => setShowEditDialog(record)}
                    onCheckAll={() => onCheckAll(record)}
                    onUnCheckAll={() => onUnCheckAll(record)}
                  />
                ) : (
                  <TurnaroundActionVerification
                    readOnly={!hasSomeOf(user, [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER])}
                    record={record}
                    selectMode={selectMode}
                    onCheckBoxChange={(_, value) => onChangeApplicable(record, value)}
                    onRemoveAction={() => onRemoveAction({rotationHash, recordId: record?.recordId})}
                    onFinishAction={() => onFinishAction(record?.recordId)}
                    onCancelFinishAction={() => setShowConfirmationDialog({type: 'finish', recordId: record?.recordId})}
                    onSetShowEditDialog={() => setShowEditDialog(record)}
                  />
                )}
              </Grid>
            ))}
        </Grid>
        <Grid spacing={2} marginTop={4} container>
          <Grid xs={12} item>
            <Stack rowGap={2.5}>
              {records
                .filter((record) => record.action.type === ACTION_TYPES.TASK)
                .sort((a, b) => a.action.weight - b.action.weight)
                .map((record, idx) => (
                  <TurnaroundActionTask
                    readOnly={!hasSomeOf(user, [ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.RAMP_OFFICER])}
                    key={idx}
                    rotationHash={rotationHash}
                    record={record}
                    selectMode={selectMode}
                    onCheckBoxChange={(_, value) => onChangeApplicable(record, value)}
                    onStartAction={() => onStartAction(record?.recordId)}
                    onFinishAction={() => onFinishAction(record?.recordId)}
                    onCancelStartAction={(type) => setShowConfirmationDialog({type, recordId: record?.recordId})}
                    onCancelFinishAction={(type) => setShowConfirmationDialog({type, recordId: record?.recordId})}
                    onRemoveAction={() => onRemoveAction({rotationHash, recordId: record?.recordId})}
                    onSetShowEditDialog={() => setShowEditDialog(record)}
                  />
                ))}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {Boolean(showConfirmationDialog) && (
        <ConfirmDialog
          variant={'success'}
          title={t`Confirmation Unselect`}
          onConfirm={() =>
            showConfirmationDialog?.type === 'start'
              ? onCancelStartAction(showConfirmationDialog.recordId)
              : onCancelFinishAction(showConfirmationDialog.recordId)
          }
          onCancel={() => setShowConfirmationDialog(false)}
        >
          {t`Are you sure you want to undo this action?`}
        </ConfirmDialog>
      )}
      {Boolean(showEditDialog) && (
        <AuthorizationGuard userHasSomeOf={[ROLES.SUPERVISOR, ROLES.ADMIN]}>
          <Dialog open>
            <TurnaroundEditRecordForm
              onUpdateRecord={onUpdateRecord}
              record={showEditDialog}
              onClose={() => setShowEditDialog(false)}
              checkAll={() => onCheckAll(showEditDialog)}
            />
          </Dialog>
        </AuthorizationGuard>
      )}
      <RTKQueryFeedback
        error={
          startAction.error ||
          finishAction.error ||
          cancelStartAction.error ||
          cancelFinishAction.error ||
          removeAction.error ||
          applyApplicable.error ||
          updateAction.error
        }
        isLoading={
          startAction.isFetching ||
          finishAction.isFetching ||
          cancelStartAction.isFetching ||
          cancelFinishAction.isFetching ||
          removeAction.isFetching ||
          applyApplicable.isFetching ||
          updateAction.isFetching
        }
      />
    </>
  );
};

TurnaroundRecordsList.propTypes = {
  rotationHash: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(
    PropTypes.shape({
      RECORD_CHECKLIST_SHAPE,
      RECORD_TASK_SHAPE,
      RECORD_VERIFICATION_SHAPE
    })
  ).isRequired
};

TurnaroundRecordsList.defaultProps = {
  records: []
};

export default TurnaroundRecordsList;
