import {Button, Stack} from '@mui/material';
import {Close} from '@mui/icons-material';
import PropTypes from 'prop-types';

const FormActions = ({onClick, onClose, btnText, readOnly}) => {
  return (
    <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
      {!readOnly && (<Button onClick={onClick} variant={'contained'}>{btnText}</Button>)}
      <Button onClick={onClose} sx={{minWidth: 'unset'}} variant={'outlined'}>
        <Close />
      </Button>
    </Stack>
  );
};

FormActions.propTypes = {
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
};

FormActions.defaultProps = {
  btnText: 'Update',
  onClick: () => null,
  onClose: () => null,
  readOnly: false
}

export default FormActions;
