import * as Yup from 'yup';
import {createDatetime as dt} from '../../../../../../utils/datetime/datetime-utils';
import {t} from 'ttag';

export const schema = Yup.object()
  .shape({
    estimatedTime: Yup.string()
      .required(t`Required field.`)
      .test({
        test: (value) => value && dt(value).isValid,
        message: t`Should be a valid datetime.`
      })
  })
  .required();
