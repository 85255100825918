import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './ata-form-schema';
import DatetimePicker from '../../../../../common/date-time-picker/DatetimePicker';
import {Grid, Stack} from '@mui/material';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import FormActions from '../resources/form-actions/FormActions';
import MVTConfirmation from '../resources/mvt-confirmation/MVTConfirmation';
import React, {useState} from 'react';
import {useCreateAtaMutation} from '../../../../../../api';
import {ROTATION_SHAPE} from '../../../../common/rotation-shapes/rotation-shapes';
import RTKQueryFeedback from '../../../../../common/RTKQueryFeedback';
import {nowTzZeroized} from '../../../../../../utils/datetime/datetime-utils';
import {actions} from '../../../../../../store/valtio';
import {determineReliableDatetime} from '../../../../../../utils/rotation/rotation-utils';

const ATAForm = ({rotation, onClose}) => {
  const [mvtBody, setMvtBody] = useState(null);
  const [mvtWasSent, setMvtWasSent] = useState(false);
  const [sendMvt, {isLoading, error}] = useCreateAtaMutation();
  const {actualTime, touchDown} = rotation.inbound.arrival;
  const {handleSubmit, control, trigger} = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    context:{
      reliableOutbound: rotation?.outbound?.departure? determineReliableDatetime(rotation?.outbound?.departure) : null
    },
    defaultValues: {
      actualTime: actualTime || nowTzZeroized(),
      touchDown: touchDown || nowTzZeroized()
    }
  });

  const submitHandler = handleSubmit((values) => {
    sendMvt({
      params: {preview: mvtBody ? 0 : 1},
      body: {rotationHash: rotation.hash, ...values}
    }).then(({data: {body}}) => {
      if (!mvtBody) {
        setMvtBody(body);
        setMvtWasSent(false);
      } else {
        setMvtBody(null);
        actions.rotations.setNotifiedRotation({
          ...rotation,
          inbound: {
            ...rotation.inbound,
            arrival: {
              ...rotation.inbound.arrival,
              actualTime: values.actualTime,
              touchDown: values.touchDown,
              demarcationTime: values.touchDown
            }
          }
        });
        setMvtWasSent(true);
        onClose();
      }
    });
  });

  if (mvtBody) {
    return (
      <>
        <MVTConfirmation message={mvtBody} onSubmit={submitHandler} />
        <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
      </>
    );
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'touchDown'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                onChange={e =>  {
                  field.onChange(e);
                  trigger('actualTime').then();
                }}
                label={t`TOUCH DOWN`}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'actualTime'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                onChange={e =>  {
                  field.onChange(e);
                  trigger('touchDown').then();
                }}
                label={t`ATA`}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
              />
            )}
          />
        </Grid>
      </Grid>
      <FormActions btnText={t`Update`} onClick={submitHandler} onClose={onClose} />
      <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
    </Stack>
  );
};

ATAForm.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  onClose: PropTypes.func
};

ATAForm.defaultProps = {
  onClose: () => null
};

export default ATAForm;
