import React from 'react';
import {Grid, Table, TableBody} from '@mui/material';
import {FlightLoadRowSkeleton} from './flight-load-row/FlightLoadRowSkeleton';

export const FlightLoadSkeleton = () => {
  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Table sx={{root: styles.table}} size='small'>
          <TableBody>
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
          </TableBody>
        </Table>
      </Grid>
      <Grid item md={6} xs={12}>
        <Table sx={{root: styles.table}} size='small'>
          <TableBody>
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
            <FlightLoadRowSkeleton />
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

const styles = {
  table: {
    boxShadow: 'unset',
    border: 'unset'
  }
};

export default FlightLoadSkeleton;
