import {Box} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const RotationCardHeaderDetail = ({icon, strongTitle, title, sxStrongTitle, sxTitle}) => (
  <Box sx={styles.box}>
    {icon}
    <Box sx={styles.box} fontFamily={'NeoSansProRegular'}>
      <Box sx={sxStrongTitle}>{strongTitle}</Box>
      <Box sx={sxTitle}>{title}</Box>
    </Box>
  </Box>
);

const styles = {
  box: {
    display: 'flex',
    gap: 0.5
  }
}

RotationCardHeaderDetail.propTypes = {
  icon: PropTypes.any,
  strongTitle: PropTypes.string,
  title: PropTypes.string,
  sxStrongTitle: PropTypes.object,
  sxTitle: PropTypes.object,
};

RotationCardHeaderDetail.defaultProps = {
  icon: null,
  strongTitle: '',
  title: '',
  sxStrongTitle: {fontWeight: 'bold'},
  sxTitle: {fontWeight: 500},
};

export default RotationCardHeaderDetail;
