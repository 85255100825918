import React from 'react';
import {Box} from '@mui/material';
import Appbar from './main-layout/Appbar';
import Sidebar from './main-layout/Sidebar';


export const MainLayout = ({children}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Appbar/>
      <Sidebar/>
      <Box component={'main'} sx={{ flexGrow: 1, p: 3, marginTop: '3.75rem'}}>
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
