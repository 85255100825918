import {Close} from '@mui/icons-material';
import {DialogContent, IconButton, Typography} from '@mui/material';

export const TurnaroundDialogHeader = ({title, subTitle, onClose, showCloseBtn}) => {
  return (
    <DialogContent sx={{overflow: 'hidden'}}>
      <Typography
        variant={'h6'}
        color={'secondary'}
        sx={{
          textTransform: 'uppercase',
          fontSize: 22
        }}
      >
        <strong>{title}</strong>
      </Typography>
      <Typography
          variant={'body1'}
          color={'secondary'}
          sx={{
              textTransform: 'uppercase',
              fontSize: 16
            }}
      >
          <small>{subTitle}</small>
      </Typography>
      {showCloseBtn && (
          <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
              }}
          >
              <Close />
          </IconButton>
      )}
    </DialogContent>
  );
};

export default TurnaroundDialogHeader;
