import {useEffect} from 'react';
import {useAzureAuth} from 'sata-azure-auth';
import {useLocation, useNavigate} from 'react-router-dom';

export const AuthenticationGuard = ({children}) => {
  const {isInitialised, isAuthenticated} = useAzureAuth();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInitialised && !isAuthenticated && !['/auth', '/logout'].includes(pathname)) {
      window.sessionStorage.setItem('_redirect_', pathname);
      navigate('/auth');
    }
  }, [isInitialised, isAuthenticated, navigate, pathname]);

  if (!isAuthenticated) return null;

  return children;
};

export default AuthenticationGuard;
