import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@mui/material';
import RolesSearch from '../../../../components/common/roles-search/RolesSearch';
import {Controller, useFormContext} from 'react-hook-form';
import {LocalAirportSearch} from '../../../../components/common/local-airport-search';

const EmployeeForm = () => {
  const {control} = useFormContext();

  return (
    <Grid spacing={3} container>
      <Grid xs={12} md={6} item>
        <Controller
          name={'name'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Name`}
              color='primary'
              variant='outlined'
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
              required
              disabled
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'email'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              label={t`Email`}
              color='primary'
              variant='outlined'
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
              required
              disabled
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'costCenter'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              value={field.value || ''}
              label={t`Cost Center`}
              color='primary'
              variant='outlined'
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Controller
          name={'iataCode'}
          control={control}
          defaultValue={''}
          render={({field, fieldState: {error}}) => (
            <LocalAirportSearch
              {...field}
              label={t`Airport`}
              size={'medium'}
              error={Boolean(error)}
              helperText={error?.message}
              value={field.value ? {iataCode: field.value} : null}
              onChange={(a) => field.onChange(a.iataCode)}
              disableClearable
              required
            />
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <Controller
          name={'roles'}
          control={control}
          defaultValue={[]}
          render={({field, fieldState: {error}}) => <RolesSearch {...field} />}
        />
      </Grid>
    </Grid>
  );
};

EmployeeForm.propTypes = {
  formik: PropTypes.object
};

EmployeeForm.defaultProps = {
  formik: {}
};

export default EmployeeForm;
