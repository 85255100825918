import React from 'react';
import {Skeleton, TableCell, TableRow} from '@mui/material';
import {styles} from './FlightLoadRow';

export const FlightLoadRowSkeleton = () => (
  <TableRow sx={styles.tableRow}>
    <TableCell sx={styles.tableCellHeader} component='th' scope='row'>
      <Skeleton />
    </TableCell>
  </TableRow>
);

export default FlightLoadRowSkeleton;
