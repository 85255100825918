import PropTypes from 'prop-types';
import useRotationTurnaroundRecordAnalyzer from '../../../../../../rotation-hooks/useRotationTurnaroundRecordAnalyzer';
import TurnaroundActionToggleBtnWithCheckBox from '../../common/TurnaroundActionToggleBtnWithCheckBox';
import {RECORD_VERIFICATION_SHAPE} from '../../common/record-shapes/record-shapes';
import TurnaroundActionToggleBtn from '../../common/TurnaroundActionToggleBtn';

export const TurnaroundActionVerification = ({
  record,
  selectMode,
  onCheckBoxChange,
  readOnly,
  onRemoveAction,
  onFinishAction,
  onCancelFinishAction,
  onSetShowEditDialog
}) => {
  const {task} = useRotationTurnaroundRecordAnalyzer(record);
  const {time, color, finished, delayedDuration} = task;

  if (selectMode && !finished && !record?.action.mandatory) {
    return (
      <TurnaroundActionToggleBtnWithCheckBox
        label={record?.action.description}
        time={time}
        onCheckBoxChange={onCheckBoxChange}
        color={color}
        checked={!record.action.applicable}
      />
    );
  }

  return (
    <>
      <TurnaroundActionToggleBtn
        label={record?.action.description}
        time={time}
        backgroundColor={color}
        completed={finished}
        delayedDuration={delayedDuration}
        onClick={() => !readOnly && (!finished && record.action.applicable ? onFinishAction() : onCancelFinishAction())}
        onEdit={(_, e) => {
          onSetShowEditDialog(true);
          e.stopPropagation();
        }}
        applicable={record.action.applicable}
        manuallyAdded={record?.action.optional}
        onRemoveAction={(e) => {
          onRemoveAction();
          e.stopPropagation();
        }}
      />
    </>
  );
};

TurnaroundActionVerification.propTypes = {
  readOnly: PropTypes.bool,
  record: RECORD_VERIFICATION_SHAPE,
  selectMode: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onFinishAction: PropTypes.func.isRequired,
  onCancelFinishAction: PropTypes.func.isRequired
};

TurnaroundActionVerification.defaultProps = {
  readOnly: false,
  selectMode: true,
  onCheckBoxChange: () => null
};

export default TurnaroundActionVerification;
