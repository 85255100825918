/** @type {{[key: string]: TaskTypes}} */
import {
  convertDurationToObject,
  createDatetime as dt,
  DATETIME_FORMAT,
  formattedDurationFromInterval,
  nowTz
} from '../datetime/datetime-utils';

export const ACTION_TYPES = {
  CHECKLIST: 'CHECKLIST',
  TASK: 'TASK',
  VERIFICATION: 'VERIFICATION'
};

export const ACTION_STATUSES_COLOR = {
  AHEAD_OF_TIME: 'flightStatus.aheadOfTime',
  ON_TIME: 'flightStatus.onTime',
  WARNING: 'flightStatus.warning',
  DELAYED: 'flightStatus.delayed'
};

export const getRecordVerificationAndChecklistStatus = ({finishDate, action: {timeToStd}}) => {
  let time = null, color = null, delayedDuration = null;

  if (!timeToStd && finishDate) {
    time = finishDate;
    color = ACTION_STATUSES_COLOR.ON_TIME;
  }

  if (timeToStd){
    const currentTime = nowTz();
    const warningTime = dt(timeToStd).plus({minutes: -2});
    if (!finishDate && dt(currentTime) < warningTime) {
      time = timeToStd;
    }
    if (!finishDate && warningTime <= dt(currentTime) && dt(currentTime) <= dt(timeToStd)) {
      time = timeToStd;
      color = ACTION_STATUSES_COLOR.WARNING;
    }
    if (finishDate && dt(timeToStd) >= dt(finishDate)) {
      time = finishDate;
      color = ACTION_STATUSES_COLOR.ON_TIME;
    }
    if ((!finishDate && dt(currentTime) > dt(timeToStd)) || (finishDate && dt(finishDate) > dt(timeToStd))) {
      time = finishDate || timeToStd || null;
      color = ACTION_STATUSES_COLOR.DELAYED;
      delayedDuration = finishDate ? `+${formattedDurationFromInterval(timeToStd, finishDate, 'mm')}` : null;
    }
  }

  return {
    time,
    finished: Boolean(finishDate),
    color,
    delayedDuration
  };
};

export const getRecordTaskStartStatus = ({startDate, action: {timeToStd}}) => {
  let time = null,
    color = null,
    delayedDuration = null;

  if (!timeToStd && startDate) {
    time = startDate;
    color = ACTION_STATUSES_COLOR.ON_TIME;
  }

  if (timeToStd){
    const currentTime = nowTz();
    const warningTime = dt(timeToStd).plus({minutes: -2});
    if (!startDate && dt(currentTime) < warningTime) {
      time = timeToStd;
    } //default
    if (!startDate && warningTime <= dt(currentTime) && dt(currentTime) <= dt(timeToStd)) {
      time = timeToStd;
      color = ACTION_STATUSES_COLOR.WARNING;
    } //warning
    if (startDate && dt(timeToStd) >= dt(startDate)) {
      time = startDate;
      color = ACTION_STATUSES_COLOR.ON_TIME;
    } //onTime
    if ((!startDate && dt(currentTime) > dt(timeToStd)) || (startDate && dt(startDate) > dt(timeToStd))) {
      time = startDate || timeToStd;
      color = ACTION_STATUSES_COLOR.DELAYED;
      delayedDuration = startDate ? `+${formattedDurationFromInterval(timeToStd, startDate, 'mm')}` : null;
    } //Delayed
  }

  return {
    time,
    finished: Boolean(startDate),
    color,
    delayedDuration
  };
};

export const getRecordTaskStopStatus = ({startDate, finishDate, action: {estimatedTime}}) => {
  const currentTime = nowTz();
  let time = null,
    color = null,
    delayedDuration = null;

  if (startDate && estimatedTime) {
    const estimatedFinishTime = dt(startDate).plus(convertDurationToObject(estimatedTime));
    if (startDate && !finishDate && dt(currentTime) <= estimatedFinishTime) {
      color = ACTION_STATUSES_COLOR.AHEAD_OF_TIME;
    } //ahead of time
    if (startDate && finishDate && dt(finishDate) <= estimatedFinishTime) {
      time = finishDate;
      color = ACTION_STATUSES_COLOR.ON_TIME;
    } //onTime
    if (
      (startDate && !finishDate && dt(currentTime) > estimatedFinishTime) ||
      (finishDate && dt(finishDate) > estimatedFinishTime)
    ) {
      time = finishDate || null;
      color = ACTION_STATUSES_COLOR.DELAYED;
      delayedDuration = finishDate
        ? `+${formattedDurationFromInterval(estimatedFinishTime.toFormat(DATETIME_FORMAT), finishDate, 'mm')}`
        : null;
    } //Delayed
  }

  if (startDate && finishDate && !estimatedTime){
    time = finishDate;
    color = ACTION_STATUSES_COLOR.ON_TIME;
  }

  return {
    time,
    finished: Boolean(finishDate),
    color,
    delayedDuration
  };
};

export const getRecordTaskTimerStatus = ({startDate, finishDate, action: {estimatedTime}}) => {
  const currentTime = nowTz();
  let time = null,
    color = null;

  if (startDate && estimatedTime) {
    const warningTime = dt(startDate).plus(convertDurationToObject(estimatedTime)).plus({minutes: -5});
    const estimatedFinishTime = dt(startDate).plus(convertDurationToObject(estimatedTime));
    time = formattedDurationFromInterval(currentTime, estimatedFinishTime.toFormat(DATETIME_FORMAT));
    if (finishDate && dt(finishDate) <= estimatedFinishTime) {
      time = formattedDurationFromInterval(finishDate, estimatedFinishTime.toFormat(DATETIME_FORMAT));
    } //on time
    if (!finishDate && dt(currentTime) >= warningTime && estimatedFinishTime >= dt(currentTime)) {
      color = ACTION_STATUSES_COLOR.WARNING;
    } //warning
    if (
      (finishDate && dt(finishDate) > estimatedFinishTime) ||
      (!finishDate && dt(currentTime) > estimatedFinishTime)
    ) {
      time = formattedDurationFromInterval(
        estimatedFinishTime.toFormat(DATETIME_FORMAT),
        finishDate ? finishDate : currentTime
      );
      color = ACTION_STATUSES_COLOR.DELAYED;
    } //Delayed
  }

  if (startDate && !estimatedTime && (dt(currentTime) > dt(startDate))){
      time = formattedDurationFromInterval(startDate, dt(currentTime).toFormat(DATETIME_FORMAT));

      if(finishDate){
        time = formattedDurationFromInterval(startDate, finishDate);
      }
  }

  return {
    time,
    color
  };
};

export const getRecordActionStatus = (record) => {
  if (record.action.type !== ACTION_TYPES.TASK) {
    return {task: getRecordVerificationAndChecklistStatus(record)};
  }

  return {
    start: getRecordTaskStartStatus(record),
    timer: getRecordTaskTimerStatus(record),
    stop: getRecordTaskStopStatus(record)
  };
};
