import React from 'react';
import {Stack} from '@mui/material';
import {FlightBriefing, FlightLoad} from './index';
import {useStore} from '../../../../../store/valtio';
import {useGetStationBriefingQuery} from '../../../../../api';
import RTKQueryFeedback from '../../../../common/RTKQueryFeedback';
import {determineFlight, FLIGHT_DIRECTIONS} from '../../../../../utils/rotation/rotation-utils';
import {FLIGHT_DIRECTION, ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
import {t} from 'ttag';

export const RotationViewFlightStationBriefing = ({rotation, flightDirection}) => {
  const {flightId} = determineFlight(rotation, flightDirection);
  const {rotations: {filters: {airport: {iataCode}}}} = useStore();
  const {data: stationBriefing, error, isFetching} = useGetStationBriefingQuery({
    flightDirection,
    body: {
      flightId: flightId,
      iataCode: iataCode
    }
  });

  return (
    <Stack gap={2}>
      <FlightLoad isLoading={isFetching} load={stationBriefing?.flightLoad} />
      <FlightBriefing
        isLoading={isFetching}
        flightBriefing={stationBriefing?.flightBriefing}
        title={flightDirection === FLIGHT_DIRECTIONS.INBOUND ? t`Outbound` : t`Inbound`}
        airportColumnTitle={flightDirection === FLIGHT_DIRECTIONS.INBOUND ? t`to` : t`from`}
      />
      <RTKQueryFeedback error={error} />
    </Stack>
  );
};

RotationViewFlightStationBriefing.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  flightDirection: FLIGHT_DIRECTION.isRequired
};

export default RotationViewFlightStationBriefing;
