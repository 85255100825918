import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {forwardRef} from 'react';
import {Autocomplete, Box, Chip, CircularProgress, TextField} from '@mui/material';
import {useGetAllRolesQuery} from '../../../api';
import RTKQueryFeedback from '../RTKQueryFeedback';


export const RolesSearch = forwardRef(({value, onChange}, ref) => {
  const {data = [], error, isLoading} = useGetAllRolesQuery();

  const onDelete = (roleId) => {
    onChange(value.filter((v) => v.roleId !== roleId));
  };

  return (
    <Box>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
      <Autocomplete
        ref={ref}
        multiple
        noOptionsText={t`No options`}
        loadingText={t`Loading...`}
        loading={isLoading}
        disableClearable
        options={data}
        renderTags={() => null}
        value={value}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(opt) => `${opt.name}`}
        onChange={(_, roles) => onChange(roles)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Roles`}
            placeholder={t`Start typing...`}
            onKeyDown={e => e.key === 'Backspace' && e.stopPropagation()}
            variant={'outlined'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      <div>
        {value.map((v, idx) => (
          <Chip key={idx} label={v.name} sx={{mt: 1, mr: 1}} onDelete={() => onDelete(v.roleId)}/>
        ))}
      </div>
    </Box>
  );
});

RolesSearch.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })),
  onChange: PropTypes.func
};

RolesSearch.defaultProps = {
  value: [],
  onChange: () => null
};

export default RolesSearch;
