import {AIRPORTS} from '../../../config/settings';

export const airports = [
  {
    iataCode: 'BBA',
    icaoCode: 'SCBA',
    name: 'Balmaceda',
    country: {code: 'CL', name: 'Chile'},
    city: 'Balmaceda',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -180,
    latitude: -45.916058,
    longitude: -71.689475,
    altitude: 524.8656
  },
  {
    iataCode: 'SCQ',
    icaoCode: 'LEST',
    name: 'Santiago',
    country: {code: 'ES', name: 'Spain'},
    city: 'Santiago',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.896333,
    longitude: -8.415144,
    altitude: 369.7224
  },
  {
    iataCode: 'JRH',
    icaoCode: 'VEJT',
    name: 'Jorhat',
    country: {code: 'IN', name: 'India'},
    city: 'Jorhat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.731528,
    longitude: 94.175536,
    altitude: 94.7928
  },
  {
    iataCode: 'SCU',
    icaoCode: 'MUCU',
    name: 'Santiago De Cuba',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Antonio Maceo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 19.969769,
    longitude: -75.835414,
    altitude: 75.8952
  },
  {
    iataCode: 'SCT',
    icaoCode: 'OYSQ',
    name: 'Socotra',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Socotra Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 12.630672,
    longitude: 53.905778,
    altitude: 44.5008
  },
  {
    iataCode: 'BBG',
    icaoCode: 'NGTU',
    name: 'Butaritari',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Butaritari Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: 3.086521,
    longitude: 172.811465,
    altitude: 1.524
  },
  {
    iataCode: 'JRO',
    icaoCode: 'HTKJ',
    name: 'Kilimanjaro',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Kilimanjaro Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -3.429406,
    longitude: 37.074461,
    altitude: 893.6736
  },
  {
    iataCode: 'SCW',
    icaoCode: 'UUYY',
    name: 'Syktyvkar',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Syktyvkar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 61.64705,
    longitude: 50.84505,
    altitude: 104.2416
  },
  {
    iataCode: 'SCV',
    icaoCode: 'LRSV',
    name: 'Suceava',
    country: {code: 'RO', name: 'Romania'},
    city: 'Stefan Cel Mare',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 47.6875,
    longitude: 26.354056,
    altitude: 419.1
  },
  {
    iataCode: 'BBI',
    icaoCode: 'VEBS',
    name: 'Bhubaneswar',
    country: {code: 'IN', name: 'India'},
    city: 'Bhubaneshwar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 360,
    latitude: 20.244364,
    longitude: 85.817781,
    altitude: 42.0624
  },
  {
    iataCode: 'SCY',
    icaoCode: 'SEST',
    name: 'San Cristóbal',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'San Cristóbal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Galapagos',
    utcOffset: -360,
    latitude: -0.910206,
    longitude: -89.61745,
    altitude: 18.8976
  },
  {
    iataCode: 'SCX',
    icaoCode: 'MM57',
    name: 'Salina Cruz',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Salina Cruz Naval Air Station',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 16.2126,
    longitude: -95.2016,
    altitude: 22.86
  },
  {
    iataCode: 'BBK',
    icaoCode: 'FBKE',
    name: 'Kasane',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Kasane',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -17.832875,
    longitude: 25.1624,
    altitude: 1002.4872
  },
  {
    iataCode: 'BBJ',
    icaoCode: 'EDAB',
    name: 'Bautzen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Bautzen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.193531,
    longitude: 14.519747,
    altitude: 173.1264
  },
  {
    iataCode: 'SCZ',
    icaoCode: 'AGGL',
    name: 'Santa Cruz/graciosa Bay/luova',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Santa Cruz/graciosa Bay/luova Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -10.7203,
    longitude: 165.795,
    altitude: 5.4864
  },
  {
    iataCode: 'BBM',
    icaoCode: 'VDBG',
    name: 'Battambang',
    country: {code: 'KH', name: 'Cambodia'},
    city: 'Battambang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Phnom_Penh',
    utcOffset: 420,
    latitude: 13.0956,
    longitude: 103.224,
    altitude: 17.9832
  },
  {
    iataCode: 'BBO',
    icaoCode: 'HCMI',
    name: 'Berbera',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Berbera',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 10.389167,
    longitude: 44.941106,
    altitude: 9.144
  },
  {
    iataCode: 'BBN',
    icaoCode: 'WBGZ',
    name: 'Bario',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Bario Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.73389,
    longitude: 115.479,
    altitude: 1021.08
  },
  {
    iataCode: 'BBQ',
    icaoCode: 'TAPH',
    name: 'Codrington',
    country: {code: 'AG', name: 'Antigua and Barbuda'},
    city: 'Codrington Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Antigua',
    utcOffset: -240,
    latitude: 17.6358,
    longitude: -61.8286,
    altitude: 4.572
  },
  {
    iataCode: 'SDA',
    icaoCode: 'ORBI',
    name: 'Baghdad',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Baghdad International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 33.262539,
    longitude: 44.234578,
    altitude: 34.7472
  },
  {
    iataCode: 'BBS',
    icaoCode: 'EGLK',
    name: 'Blackbushe',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Blackbushe',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.323889,
    longitude: -0.8475,
    altitude: 99.06
  },
  {
    iataCode: 'SDD',
    icaoCode: 'FNUB',
    name: 'Lubango',
    country: {code: 'AO', name: 'Angola'},
    city: 'Lubango',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -14.924733,
    longitude: 13.575022,
    altitude: 1761.1344
  },
  {
    iataCode: 'BBR',
    icaoCode: 'TFFB',
    name: 'Basse Terre',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'Baillif Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 16.0133,
    longitude: -61.7422,
    altitude: 17.9832
  },
  {
    iataCode: 'BBU',
    icaoCode: 'LRBS',
    name: 'Bucharest',
    country: {code: 'RO', name: 'Romania'},
    city: 'Aurel Vlaicu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 44.503194,
    longitude: 26.102111,
    altitude: 90.5256
  },
  {
    iataCode: 'SDF',
    icaoCode: 'KSDF',
    name: 'Louisville',
    country: {code: 'US', name: 'United States'},
    city: 'Louisville International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Kentucky/Louisville',
    utcOffset: -300,
    latitude: 38.174086,
    longitude: -85.736499,
    altitude: 152.7048
  },
  {
    iataCode: 'BBT',
    icaoCode: 'FEFT',
    name: 'Berberati',
    country: {code: 'CF', name: 'Central African Republic'},
    city: 'Berberati',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bangui',
    utcOffset: 60,
    latitude: 4.221583,
    longitude: 15.786369,
    altitude: 587.9592
  },
  {
    iataCode: 'SDE',
    icaoCode: 'SANE',
    name: 'Santiago Del Estero',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Santiago Del Estero',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -27.765617,
    longitude: -64.310122,
    altitude: 199.9488
  },
  {
    iataCode: 'SDG',
    icaoCode: 'OICS',
    name: 'Sanandaj',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Sanandaj',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 35.245856,
    longitude: 47.009247,
    altitude: 1378.3056
  },
  {
    iataCode: 'SDJ',
    icaoCode: 'RJSS',
    name: 'Sendai',
    country: {code: 'JP', name: 'Japan'},
    city: 'Sendai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 38.139722,
    longitude: 140.916944,
    altitude: 4.572
  },
  {
    iataCode: 'JSA',
    icaoCode: 'VIJR',
    name: 'Jaisalmer',
    country: {code: 'IN', name: 'India'},
    city: 'Jaisalmer',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.888653,
    longitude: 70.864967,
    altitude: 228.9048
  },
  {
    iataCode: 'SDL',
    icaoCode: 'ESNN',
    name: 'Sundsvall',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Sundsvall Harnosand',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 62.528125,
    longitude: 17.443928,
    altitude: 4.8768
  },
  {
    iataCode: 'SDK',
    icaoCode: 'WBKS',
    name: 'Sandakan',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sandakan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 5.900897,
    longitude: 118.059486,
    altitude: 14.0208
  },
  {
    iataCode: 'SDN',
    icaoCode: 'ENSD',
    name: 'Sandane',
    country: {code: 'NO', name: 'Norway'},
    city: 'Anda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 61.83,
    longitude: 6.10583,
    altitude: 59.7408
  },
  {
    iataCode: 'SDM',
    icaoCode: 'KSDM',
    name: 'San Diego',
    country: {code: 'US', name: 'United States'},
    city: 'Brown Field Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 32.572272,
    longitude: -116.980161,
    altitude: 160.3248
  },
  {
    iataCode: 'JSH',
    icaoCode: 'LGST',
    name: 'Sitia',
    country: {code: 'GR', name: 'Greece'},
    city: 'Sitia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 35.216108,
    longitude: 26.101325,
    altitude: 114.6048
  },
  {
    iataCode: 'SDP',
    icaoCode: 'PASD',
    name: 'Sand Point',
    country: {code: 'US', name: 'United States'},
    city: 'Sand Point Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 55.315,
    longitude: -160.523,
    altitude: 6.4008
  },
  {
    iataCode: 'SDR',
    icaoCode: 'LEXJ',
    name: 'Santander',
    country: {code: 'ES', name: 'Spain'},
    city: 'Santander',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 43.427064,
    longitude: -3.820006,
    altitude: 4.8768
  },
  {
    iataCode: 'BCA',
    icaoCode: 'MUBA',
    name: 'Baracoa Playa',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Gustavo Rizo',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.365317,
    longitude: -74.506206,
    altitude: 7.9248
  },
  {
    iataCode: 'JSI',
    icaoCode: 'LGSK',
    name: 'Skiathos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Alexandros Papadiamantis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.1771,
    longitude: 23.503675,
    altitude: 16.4592
  },
  {
    iataCode: 'SDQ',
    icaoCode: 'MDSD',
    name: 'Santo Domingo',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Las Americas Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.429664,
    longitude: -69.668925,
    altitude: 17.9832
  },
  {
    iataCode: 'BCD',
    icaoCode: 'RPVB',
    name: 'Bacolod',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Bacolod',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 10.642511,
    longitude: 122.929617,
    altitude: 7.62
  },
  {
    iataCode: 'SDT',
    icaoCode: 'OPSS',
    name: 'Saidu Sharif',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Saidu Sharif',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 34.813556,
    longitude: 72.352814,
    altitude: 970.1784
  },
  {
    iataCode: 'SDV',
    icaoCode: 'LLSD',
    name: 'Tel-aviv',
    country: {code: 'IL', name: 'Israel'},
    city: 'Sde Dov',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 32.114661,
    longitude: 34.782239,
    altitude: 13.1064
  },
  {
    iataCode: 'JSM',
    icaoCode: 'SAWS',
    name: 'Jose De San Martin',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Jose De San Martin Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -44.016667,
    longitude: -70.466667,
    altitude: 1328.6232
  },
  {
    iataCode: 'SDU',
    icaoCode: 'SBRJ',
    name: 'Rio De Janeiro',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santos Dumont',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.910461,
    longitude: -43.163133,
    altitude: 3.3528
  },
  {
    iataCode: 'SDX',
    icaoCode: 'KSEZ',
    name: 'Sedona',
    country: {code: 'US', name: 'United States'},
    city: 'Sedona',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 34.848628,
    longitude: -111.788472,
    altitude: 1472.184
  },
  {
    iataCode: 'JSR',
    icaoCode: 'VGJR',
    name: 'Jessore',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Jessore',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 23.1838,
    longitude: 89.160833,
    altitude: 6.096
  },
  {
    iataCode: 'SDZ',
    icaoCode: 'EGPM',
    name: 'Scatsta',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Scatsta',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 60.432778,
    longitude: -1.296111,
    altitude: 24.6888
  },
  {
    iataCode: 'BCI',
    icaoCode: 'YBAR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Barcaldine Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.5653,
    longitude: 145.307,
    altitude: 267.6144
  },
  {
    iataCode: 'SDY',
    icaoCode: 'KSDY',
    name: 'Sidney',
    country: {code: 'US', name: 'United States'},
    city: 'Sidney-richland Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 47.706944,
    longitude: -104.1925,
    altitude: 604.7232
  },
  {
    iataCode: 'BCL',
    icaoCode: 'MRBC',
    name: 'Pococi',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Barra Del Colorado Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.768736,
    longitude: -83.585614,
    altitude: 0.9144
  },
  {
    iataCode: 'JST',
    icaoCode: 'KJST',
    name: 'Johnstown',
    country: {code: 'US', name: 'United States'},
    city: 'John Murtha Johnstown-cambria County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.316111,
    longitude: -78.833889,
    altitude: 696.1632
  },
  {
    iataCode: 'BCN',
    icaoCode: 'LEBL',
    name: 'Barcelona',
    country: {code: 'ES', name: 'Spain'},
    city: 'Barcelona',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.297078,
    longitude: 2.078464,
    altitude: 3.6576
  },
  {
    iataCode: 'BCM',
    icaoCode: 'LRBC',
    name: 'Bacau',
    country: {code: 'RO', name: 'Romania'},
    city: 'Bacau',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 46.521946,
    longitude: 26.910278,
    altitude: 185.0136
  },
  {
    iataCode: 'JSU',
    icaoCode: 'BGMQ',
    name: 'Maniitsoq',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Maniitsoq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 65.4125,
    longitude: -52.9394,
    altitude: 27.7368
  },
  {
    iataCode: 'SEA',
    icaoCode: 'KSEA',
    name: 'Seattle',
    country: {code: 'US', name: 'United States'},
    city: 'Seattle Tacoma Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.449,
    longitude: -122.309306,
    altitude: 131.9784
  },
  {
    iataCode: 'BCO',
    icaoCode: 'HABC',
    name: 'Baco',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Baco Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 5.78287,
    longitude: 36.562,
    altitude: 0.0
  },
  {
    iataCode: 'JSY',
    icaoCode: 'LGSO',
    name: 'Syros Island',
    country: {code: 'GR', name: 'Greece'},
    city: 'Syros Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.422792,
    longitude: 24.950936,
    altitude: 71.9328
  },
  {
    iataCode: 'SEB',
    icaoCode: 'HLLS',
    name: 'Sebha',
    country: {code: 'LY', name: 'Libya'},
    city: 'Sebha',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 26.986964,
    longitude: 14.472525,
    altitude: 434.9496
  },
  {
    iataCode: 'BCT',
    icaoCode: 'KBCT',
    name: 'Boca Raton',
    country: {code: 'US', name: 'United States'},
    city: 'Boca Raton',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.3785,
    longitude: -80.107694,
    altitude: 3.9624
  },
  {
    iataCode: 'SEE',
    icaoCode: 'KSEE',
    name: 'El Cajon',
    country: {code: 'US', name: 'United States'},
    city: 'Gillespie',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.826222,
    longitude: -116.972444,
    altitude: 118.2624
  },
  {
    iataCode: 'SEH',
    icaoCode: 'WAJS',
    name: 'Senggeh-papua Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Senggeh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -3.43333,
    longitude: 140.817,
    altitude: 278.5872
  },
  {
    iataCode: 'SEM',
    icaoCode: 'KSEM',
    name: 'Selma',
    country: {code: 'US', name: 'United States'},
    city: 'Craig Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.343947,
    longitude: -86.987803,
    altitude: 50.5968
  },
  {
    iataCode: 'SEN',
    icaoCode: 'EGMC',
    name: 'Southend',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Southend',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.571389,
    longitude: 0.695556,
    altitude: 14.9352
  },
  {
    iataCode: 'BDA',
    icaoCode: 'TXKF',
    name: 'Bermuda',
    country: {code: 'BM', name: 'Bermuda'},
    city: 'Bermuda Intl',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Bermuda',
    utcOffset: -240,
    latitude: 32.364042,
    longitude: -64.678703,
    altitude: 3.6576
  },
  {
    iataCode: 'BDB',
    icaoCode: 'YBUD',
    name: 'Bundaberg',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bundaberg',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -24.903889,
    longitude: 152.318611,
    altitude: 32.6136
  },
  {
    iataCode: 'BDE',
    icaoCode: 'KBDE',
    name: 'Baudette',
    country: {code: 'US', name: 'United States'},
    city: 'Baudette Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.728444,
    longitude: -94.612222,
    altitude: 331.0128
  },
  {
    iataCode: 'SEU',
    icaoCode: 'HTSN',
    name: 'Seronera',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Seronera',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -2.458056,
    longitude: 34.8225,
    altitude: 1548.384
  },
  {
    iataCode: 'BDD',
    icaoCode: 'YBAU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Badu Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 540,
    latitude: -10.15,
    longitude: 141.175,
    altitude: 4.2672
  },
  {
    iataCode: 'BDI',
    icaoCode: 'FSSB',
    name: 'Bird Island',
    country: {code: 'SC', name: 'Seychelles'},
    city: 'Bird Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mahe',
    utcOffset: 240,
    latitude: -3.721389,
    longitude: 55.208611,
    altitude: 3.048
  },
  {
    iataCode: 'SEY',
    icaoCode: 'GQNS',
    name: 'Selibabi',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Selibady',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 15.179692,
    longitude: -12.207272,
    altitude: 79.8576
  },
  {
    iataCode: 'BDH',
    icaoCode: 'OIBL',
    name: 'Bandar Lengeh',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Bandar Lengeh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 26.532,
    longitude: 54.824847,
    altitude: 20.4216
  },
  {
    iataCode: 'BDJ',
    icaoCode: 'WAOO',
    name: 'Banjarmasin',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Syamsudin Noor',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -3.442356,
    longitude: 114.762553,
    altitude: 20.1168
  },
  {
    iataCode: 'JTR',
    icaoCode: 'LGSR',
    name: 'Santorini',
    country: {code: 'GR', name: 'Greece'},
    city: 'Santorini',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.399169,
    longitude: 25.479333,
    altitude: 38.7096
  },
  {
    iataCode: 'SEZ',
    icaoCode: 'FSIA',
    name: 'Mahe',
    country: {code: 'SC', name: 'Seychelles'},
    city: 'Seychelles Intl',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mahe',
    utcOffset: 240,
    latitude: -4.674342,
    longitude: 55.521839,
    altitude: 3.048
  },
  {
    iataCode: 'BDM',
    icaoCode: 'LTBG',
    name: 'Bandirma',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Bandirma',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.317972,
    longitude: 27.977694,
    altitude: 51.816
  },
  {
    iataCode: 'BDL',
    icaoCode: 'KBDL',
    name: 'Windsor Locks',
    country: {code: 'US', name: 'United States'},
    city: 'Bradley Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.938889,
    longitude: -72.683222,
    altitude: 52.7304
  },
  {
    iataCode: 'BDO',
    icaoCode: 'WICC',
    name: 'Bandung',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Husein Sastranegara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.900625,
    longitude: 107.576294,
    altitude: 742.4928
  },
  {
    iataCode: 'BDN',
    icaoCode: 'OPTH',
    name: 'Talhar',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Talhar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 24.841519,
    longitude: 68.838408,
    altitude: 8.5344
  },
  {
    iataCode: 'BDQ',
    icaoCode: 'VABO',
    name: 'Baroda',
    country: {code: 'IN', name: 'India'},
    city: 'Vadodara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.336164,
    longitude: 73.226289,
    altitude: 39.3192
  },
  {
    iataCode: 'JTY',
    icaoCode: 'LGPL',
    name: 'Astypalaia',
    country: {code: 'GR', name: 'Greece'},
    city: 'Astypalaia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.579886,
    longitude: 26.375822,
    altitude: 50.292
  },
  {
    iataCode: 'SFB',
    icaoCode: 'KSFB',
    name: 'Sanford',
    country: {code: 'US', name: 'United States'},
    city: 'Orlando Sanford Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.777639,
    longitude: -81.237489,
    altitude: 16.764
  },
  {
    iataCode: 'BDP',
    icaoCode: 'VNCG',
    name: 'Chandragarhi',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Chandragadhi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 26.570822,
    longitude: 88.079578,
    altitude: 91.44
  },
  {
    iataCode: 'SFA',
    icaoCode: 'DTTX',
    name: 'Sfax',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Thyna',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 34.717953,
    longitude: 10.690972,
    altitude: 25.908
  },
  {
    iataCode: 'BDS',
    icaoCode: 'LIBR',
    name: 'Brindisi',
    country: {code: 'IT', name: 'Italy'},
    city: 'Casale',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.657633,
    longitude: 17.947033,
    altitude: 14.3256
  },
  {
    iataCode: 'SFD',
    icaoCode: 'SVSR',
    name: 'San Fernando De Apure',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'San Fernando De Apure',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 7.883317,
    longitude: -67.444025,
    altitude: 46.9392
  },
  {
    iataCode: 'BDR',
    icaoCode: 'KBDR',
    name: 'Stratford',
    country: {code: 'US', name: 'United States'},
    city: 'Igor I Sikorsky Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.163472,
    longitude: -73.126167,
    altitude: 2.7432
  },
  {
    iataCode: 'SFC',
    icaoCode: 'TFFC',
    name: 'St-françois',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'St-françois Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 16.2578,
    longitude: -61.2625,
    altitude: 3.048
  },
  {
    iataCode: 'BDU',
    icaoCode: 'ENDU',
    name: 'Bardufoss',
    country: {code: 'NO', name: 'Norway'},
    city: 'Bardufoss',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.055758,
    longitude: 18.540356,
    altitude: 76.8096
  },
  {
    iataCode: 'SFF',
    icaoCode: 'KSFF',
    name: 'Spokane',
    country: {code: 'US', name: 'United States'},
    city: 'Felts Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.682819,
    longitude: -117.322558,
    altitude: 595.2744
  },
  {
    iataCode: 'BDT',
    icaoCode: 'FZFD',
    name: 'Gbadolite',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Gbadolite',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: 4.253206,
    longitude: 20.975283,
    altitude: 459.9432
  },
  {
    iataCode: 'SFE',
    icaoCode: 'RPUS',
    name: 'San Fernando',
    country: {code: 'PH', name: 'Philippines'},
    city: 'San Fernando Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 16.595589,
    longitude: 120.303219,
    altitude: 3.9624
  },
  {
    iataCode: 'SFH',
    icaoCode: 'SVSP',
    name: 'San Felipe',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Sub Teniente Nestor Arias',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.278728,
    longitude: -68.755211,
    altitude: 231.9528
  },
  {
    iataCode: 'SFG',
    icaoCode: 'TFFG',
    name: 'St. Martin',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'Grand Case',
    region: '',
    terminal: '',
    timezoneId: 'America/Marigot',
    utcOffset: -240,
    latitude: 18.099914,
    longitude: -63.047197,
    altitude: 2.1336
  },
  {
    iataCode: 'JUB',
    icaoCode: 'HSSJ',
    name: 'Juba',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Juba',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Juba',
    utcOffset: 120,
    latitude: 4.872006,
    longitude: 31.601117,
    altitude: 461.1624
  },
  {
    iataCode: 'SFJ',
    icaoCode: 'BGSF',
    name: 'Sondrestrom',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Sondre Stromfjord',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 67.016969,
    longitude: -50.689325,
    altitude: 50.292
  },
  {
    iataCode: 'SFK',
    icaoCode: 'SNSW',
    name: 'Soure',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Soure Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Belem',
    utcOffset: -180,
    latitude: -0.716944,
    longitude: -48.522778,
    altitude: 10.0584
  },
  {
    iataCode: 'SFN',
    icaoCode: 'SAAV',
    name: 'Santa Fe',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Sauce Viejo',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -31.711666,
    longitude: -60.811668,
    altitude: 17.0688
  },
  {
    iataCode: 'SFO',
    icaoCode: 'KSFO',
    name: 'San Francisco',
    country: {code: 'US', name: 'United States'},
    city: 'San Francisco Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.618972,
    longitude: -122.374889,
    altitude: 3.9624
  },
  {
    iataCode: 'BEB',
    icaoCode: 'EGPL',
    name: 'Benbecula',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Benbecula',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 57.481111,
    longitude: -7.362778,
    altitude: 5.7912
  },
  {
    iataCode: 'JUJ',
    icaoCode: 'SASJ',
    name: 'Jujuy',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Jujuy',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Jujuy',
    utcOffset: -180,
    latitude: -24.392778,
    longitude: -65.097778,
    altitude: 920.1912
  },
  {
    iataCode: 'JUI',
    icaoCode: 'EDWJ',
    name: 'Juist',
    country: {code: 'DE', name: 'Germany'},
    city: 'Verkehrslandeplatz Juist',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.681572,
    longitude: 7.055731,
    altitude: 1.8288
  },
  {
    iataCode: 'SFQ',
    icaoCode: 'LTCH',
    name: 'Sanliurfa',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Sanliurfa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.094261,
    longitude: 38.847103,
    altitude: 452.0184
  },
  {
    iataCode: 'BED',
    icaoCode: 'KBED',
    name: 'Bedford',
    country: {code: 'US', name: 'United States'},
    city: 'Laurence G Hanscom Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.469953,
    longitude: -71.289031,
    altitude: 40.5384
  },
  {
    iataCode: 'JUL',
    icaoCode: 'SPJL',
    name: 'Juliaca',
    country: {code: 'PE', name: 'Peru'},
    city: 'Juliaca',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -15.467103,
    longitude: -70.158169,
    altitude: 3825.8496
  },
  {
    iataCode: 'SFT',
    icaoCode: 'ESNS',
    name: 'Skelleftea',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Skelleftea',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 64.624772,
    longitude: 21.076892,
    altitude: 47.8536
  },
  {
    iataCode: 'SFS',
    icaoCode: 'RPLB',
    name: 'Olongapo City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Subic Bay International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 14.7944,
    longitude: 120.271,
    altitude: 19.5072
  },
  {
    iataCode: 'BEF',
    icaoCode: 'MNBL',
    name: 'Bluefields',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Bluefields',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 11.990961,
    longitude: -83.774086,
    altitude: 12.4968
  },
  {
    iataCode: 'JUM',
    icaoCode: 'VNJL',
    name: 'Jumla',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Jumla',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 29.274167,
    longitude: 82.193333,
    altitude: 2346.96
  },
  {
    iataCode: 'BEG',
    icaoCode: 'LYBE',
    name: 'Beograd',
    country: {code: 'RS', name: 'Serbia'},
    city: 'Beograd',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Belgrade',
    utcOffset: 60,
    latitude: 44.818444,
    longitude: 20.309139,
    altitude: 102.108
  },
  {
    iataCode: 'BEJ',
    icaoCode: 'WALK',
    name: 'Tanjung Redep-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Barau(kalimaru) Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 2.155497,
    longitude: 117.432256,
    altitude: 17.9832
  },
  {
    iataCode: 'SFZ',
    icaoCode: 'KSFZ',
    name: 'Smithfield',
    country: {code: 'US', name: 'United States'},
    city: 'North Central State',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.920764,
    longitude: -71.491381,
    altitude: 134.4168
  },
  {
    iataCode: 'BEI',
    icaoCode: 'HABE',
    name: 'Beica',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Beica Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 9.38639,
    longitude: 34.5219,
    altitude: 1648.968
  },
  {
    iataCode: 'BEL',
    icaoCode: 'SBBE',
    name: 'Belem',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Val De Cans Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Belem',
    utcOffset: -180,
    latitude: -1.37925,
    longitude: -48.476292,
    altitude: 16.4592
  },
  {
    iataCode: 'BEN',
    icaoCode: 'HLLB',
    name: 'Benghazi',
    country: {code: 'LY', name: 'Libya'},
    city: 'Benina',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 32.096786,
    longitude: 20.269472,
    altitude: 131.9784
  },
  {
    iataCode: 'JUV',
    icaoCode: 'BGUK',
    name: 'Upernavik',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Upernavik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -240,
    latitude: 72.7902,
    longitude: -56.1306,
    altitude: 126.1872
  },
  {
    iataCode: 'BEP',
    icaoCode: 'VOBI',
    name: 'Bellary',
    country: {code: 'IN', name: 'India'},
    city: 'Bellary',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 15.162783,
    longitude: 76.882775,
    altitude: 9.144
  },
  {
    iataCode: 'JUZ',
    icaoCode: 'ZSJU',
    name: 'Quzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Quzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 28.9658,
    longitude: 118.899,
    altitude: 0.0
  },
  {
    iataCode: 'SGC',
    icaoCode: 'USRR',
    name: 'Surgut',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Surgut',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 61.343694,
    longitude: 73.401842,
    altitude: 60.96
  },
  {
    iataCode: 'BEQ',
    icaoCode: 'EGXH',
    name: 'Honington',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Honington',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.342611,
    longitude: 0.772939,
    altitude: 53.0352
  },
  {
    iataCode: 'BET',
    icaoCode: 'PABE',
    name: 'Bethel',
    country: {code: 'US', name: 'United States'},
    city: 'Bethel',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.779778,
    longitude: -161.838,
    altitude: 36.8808
  },
  {
    iataCode: 'BES',
    icaoCode: 'LFRB',
    name: 'Brest',
    country: {code: 'FR', name: 'France'},
    city: 'Guipavas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.447911,
    longitude: -4.418539,
    altitude: 99.06
  },
  {
    iataCode: 'SGD',
    icaoCode: 'EKSB',
    name: 'Soenderborg',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Sonderborg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 54.964367,
    longitude: 9.791731,
    altitude: 7.3152
  },
  {
    iataCode: 'BEV',
    icaoCode: 'LLBS',
    name: 'Beer-sheba',
    country: {code: 'IL', name: 'Israel'},
    city: 'Teyman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 31.287003,
    longitude: 34.722953,
    altitude: 199.9488
  },
  {
    iataCode: 'BEU',
    icaoCode: 'YBIE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bedourie Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -24.3461,
    longitude: 139.46,
    altitude: 91.44
  },
  {
    iataCode: 'SGF',
    icaoCode: 'KSGF',
    name: 'Springfield',
    country: {code: 'US', name: 'United States'},
    city: 'Springfield Branson Natl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.245667,
    longitude: -93.388639,
    altitude: 386.4864
  },
  {
    iataCode: 'JVA',
    icaoCode: 'FMMK',
    name: 'Ankavandra',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ankavandra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.8,
    longitude: 45.283,
    altitude: 130.1496
  },
  {
    iataCode: 'BEW',
    icaoCode: 'FQBR',
    name: 'Beira',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Beira',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -19.796419,
    longitude: 34.907556,
    altitude: 10.0584
  },
  {
    iataCode: 'BEZ',
    icaoCode: 'NGBR',
    name: 'Beru Island',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Beru Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: -1.254722,
    longitude: 176.007222,
    altitude: 3.048
  },
  {
    iataCode: 'BEY',
    icaoCode: 'OLBA',
    name: 'Beirut',
    country: {code: 'LB', name: 'Lebanon'},
    city: 'Rafic Hariri Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Beirut',
    utcOffset: 120,
    latitude: 33.820931,
    longitude: 35.488389,
    altitude: 26.5176
  },
  {
    iataCode: 'SGO',
    icaoCode: 'YSGE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'St George Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -28.0497,
    longitude: 148.595,
    altitude: 199.9488
  },
  {
    iataCode: 'SGN',
    icaoCode: 'VVTS',
    name: 'Ho Chi Minh City',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Tansonnhat Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 10.818797,
    longitude: 106.651856,
    altitude: 10.0584
  },
  {
    iataCode: 'SGS',
    icaoCode: 'RPMN',
    name: '',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Sanga Sanga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 5.04699,
    longitude: 119.743,
    altitude: 4.572
  },
  {
    iataCode: 'SGR',
    icaoCode: 'KSGR',
    name: 'Sugar Land',
    country: {code: 'US', name: 'United States'},
    city: 'Sugar Land Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.62225,
    longitude: -95.656528,
    altitude: 24.9936
  },
  {
    iataCode: 'SGU',
    icaoCode: 'KSGU',
    name: 'Saint George',
    country: {code: 'US', name: 'United States'},
    city: 'St George Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.090583,
    longitude: -113.593056,
    altitude: 896.4168
  },
  {
    iataCode: 'BFD',
    icaoCode: 'KBFD',
    name: 'Bradford',
    country: {code: 'US', name: 'United States'},
    city: 'Bradford Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.8031,
    longitude: -78.6401,
    altitude: 653.1864
  },
  {
    iataCode: 'BFF',
    icaoCode: 'KBFF',
    name: 'Scottsbluff',
    country: {code: 'US', name: 'United States'},
    city: 'Western Nebraska Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.874,
    longitude: -103.596,
    altitude: 1209.1416
  },
  {
    iataCode: 'SGV',
    icaoCode: 'SAVS',
    name: 'Sierra Grande',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Sierra Grande',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -41.6,
    longitude: -65.366667,
    altitude: 249.936
  },
  {
    iataCode: 'BFI',
    icaoCode: 'KBFI',
    name: 'Seattle',
    country: {code: 'US', name: 'United States'},
    city: 'Boeing Fld King Co Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.53,
    longitude: -122.301947,
    altitude: 6.4008
  },
  {
    iataCode: 'SGY',
    icaoCode: 'PAGY',
    name: 'Skagway',
    country: {code: 'US', name: 'United States'},
    city: 'Skagway Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 59.4601,
    longitude: -135.316,
    altitude: 13.4112
  },
  {
    iataCode: 'BFH',
    icaoCode: 'SBBI',
    name: 'Curitiba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Bacacheri',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -25.405078,
    longitude: -49.232036,
    altitude: 931.7736
  },
  {
    iataCode: 'BFM',
    icaoCode: 'KBFM',
    name: 'Mobile',
    country: {code: 'US', name: 'United States'},
    city: 'Mobile Downtown',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.626783,
    longitude: -88.068092,
    altitude: 7.9248
  },
  {
    iataCode: 'BFL',
    icaoCode: 'KBFL',
    name: 'Bakersfield',
    country: {code: 'US', name: 'United States'},
    city: 'Meadows Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.433598,
    longitude: -119.05677,
    altitude: 154.5336
  },
  {
    iataCode: 'BFO',
    icaoCode: 'FVCZ',
    name: 'Chiredzi',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Buffalo Range',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -21.008083,
    longitude: 31.57855,
    altitude: 433.1208
  },
  {
    iataCode: 'BFN',
    icaoCode: 'FABL',
    name: 'Bloemfontein',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Bloemfontein Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.092722,
    longitude: 26.302444,
    altitude: 1358.7984
  },
  {
    iataCode: 'SHB',
    icaoCode: 'RJCN',
    name: 'Nakashibetsu',
    country: {code: 'JP', name: 'Japan'},
    city: 'Nakashibetsu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 43.5775,
    longitude: 144.96,
    altitude: 71.3232
  },
  {
    iataCode: 'SHA',
    icaoCode: 'ZSSS',
    name: 'Shanghai',
    country: {code: 'CN', name: 'China'},
    city: 'Hongqiao Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.197875,
    longitude: 121.336319,
    altitude: 3.048
  },
  {
    iataCode: 'BFS',
    icaoCode: 'EGAA',
    name: 'Belfast',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Aldergrove',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.6575,
    longitude: -6.215833,
    altitude: 81.6864
  },
  {
    iataCode: 'SHD',
    icaoCode: 'KSHD',
    name: 'Weyers Cave',
    country: {code: 'US', name: 'United States'},
    city: 'Shenandoah Valley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.263889,
    longitude: -78.896389,
    altitude: 366.0648
  },
  {
    iataCode: 'BFT',
    icaoCode: 'KBFT',
    name: 'Beauford',
    country: {code: 'US', name: 'United States'},
    city: 'Bft County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.41083,
    longitude: -80.635,
    altitude: 152.4
  },
  {
    iataCode: 'SHE',
    icaoCode: 'ZYTX',
    name: 'Shenyang',
    country: {code: 'CN', name: 'China'},
    city: 'Shenyang Taoxian International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 41.3824,
    longitude: 123.2901,
    altitude: 60.3504
  },
  {
    iataCode: 'SHH',
    icaoCode: 'PASH',
    name: 'Shishmaref',
    country: {code: 'US', name: 'United States'},
    city: 'Shishmaref Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 66.2496,
    longitude: -166.089,
    altitude: 3.6576
  },
  {
    iataCode: 'BFV',
    icaoCode: 'VTUO',
    name: 'Buri Ram',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Buri Ram',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 15.229539,
    longitude: 103.253231,
    altitude: 179.832
  },
  {
    iataCode: 'SHG',
    icaoCode: 'PAGH',
    name: 'Shungnak',
    country: {code: 'US', name: 'United States'},
    city: 'Shungnak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.888056,
    longitude: -157.1625,
    altitude: 60.0456
  },
  {
    iataCode: 'SHJ',
    icaoCode: 'OMSJ',
    name: 'Sharjah',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Sharjah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 25.328575,
    longitude: 55.51715,
    altitude: 33.8328
  },
  {
    iataCode: 'BFX',
    icaoCode: 'FKKU',
    name: 'Bafoussam',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Bafoussam',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 5.536919,
    longitude: 10.354583,
    altitude: 1324.9656
  },
  {
    iataCode: 'JWA',
    icaoCode: 'FBJW',
    name: 'Jwaneng',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Jwaneng',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -24.602333,
    longitude: 24.690971,
    altitude: 1188.72
  },
  {
    iataCode: 'SHI',
    icaoCode: 'RORS',
    name: 'Shimojishima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Shimojishima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 24.826667,
    longitude: 125.144722,
    altitude: 16.4592
  },
  {
    iataCode: 'SHL',
    icaoCode: 'VEBI',
    name: 'Shillong',
    country: {code: 'IN', name: 'India'},
    city: 'Shillong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.7036,
    longitude: 91.9787,
    altitude: 886.968
  },
  {
    iataCode: 'SHM',
    icaoCode: 'RJBD',
    name: 'Nanki-shirahama',
    country: {code: 'JP', name: 'Japan'},
    city: 'Nanki Shirahama',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.662222,
    longitude: 135.364444,
    altitude: 90.8304
  },
  {
    iataCode: 'SHP',
    icaoCode: 'ZBSH',
    name: 'Qinhuangdao',
    country: {code: 'CN', name: 'China'},
    city: 'Shanhaiguan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 39.9681,
    longitude: 119.731,
    altitude: 0.0
  },
  {
    iataCode: 'SHO',
    icaoCode: 'RKND',
    name: "Sokch'o",
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Sokcho',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 38.142614,
    longitude: 128.598556,
    altitude: 28.0416
  },
  {
    iataCode: 'SHR',
    icaoCode: 'KSHR',
    name: 'Sheridan',
    country: {code: 'US', name: 'United States'},
    city: 'Sheridan County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.7692,
    longitude: -106.98,
    altitude: 1225.6008
  },
  {
    iataCode: 'BGA',
    icaoCode: 'SKBG',
    name: 'Bucaramanga',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Palonegro',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 7.1265,
    longitude: -73.184778,
    altitude: 1187.8056
  },
  {
    iataCode: 'BGC',
    icaoCode: 'LPBG',
    name: 'Braganca',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Braganca',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Lisbon',
    utcOffset: 0,
    latitude: 41.8578,
    longitude: -6.707125,
    altitude: 683.0568
  },
  {
    iataCode: 'BGF',
    icaoCode: 'FEFF',
    name: 'Bangui',
    country: {code: 'CF', name: 'Central African Republic'},
    city: 'Bangui M Poko',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bangui',
    utcOffset: 60,
    latitude: 4.398475,
    longitude: 18.518786,
    altitude: 368.1984
  },
  {
    iataCode: 'SHV',
    icaoCode: 'KSHV',
    name: 'Shreveport',
    country: {code: 'US', name: 'United States'},
    city: 'Shreveport Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.446629,
    longitude: -93.8256,
    altitude: 78.6384
  },
  {
    iataCode: 'SHX',
    icaoCode: 'PAHX',
    name: 'Shageluk',
    country: {code: 'US', name: 'United States'},
    city: 'Shageluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.692222,
    longitude: -159.569167,
    altitude: 24.0792
  },
  {
    iataCode: 'SHW',
    icaoCode: 'OESH',
    name: 'Sharurah',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Sharurah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 17.466875,
    longitude: 47.121431,
    altitude: 720.2424
  },
  {
    iataCode: 'BGI',
    icaoCode: 'TBPB',
    name: 'Bridgetown',
    country: {code: 'BB', name: 'Barbados'},
    city: 'Grantley Adams Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Barbados',
    utcOffset: -240,
    latitude: 13.074603,
    longitude: -59.492456,
    altitude: 51.5112
  },
  {
    iataCode: 'SHY',
    icaoCode: 'HTSY',
    name: 'Shinyanga',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Shinyanga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -3.667,
    longitude: 33.417,
    altitude: 1158.24
  },
  {
    iataCode: 'BGM',
    icaoCode: 'KBGM',
    name: 'Binghamton',
    country: {code: 'US', name: 'United States'},
    city: 'Greater Binghamton Edwin A Link Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.208689,
    longitude: -75.979839,
    altitude: 498.6528
  },
  {
    iataCode: 'SIA',
    icaoCode: 'ZLSN',
    name: "Xi\\\\'an",
    country: {code: 'CN', name: 'China'},
    city: "Xi\\\\'an Xiguan",
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 34.3767,
    longitude: 109.12,
    altitude: 0.0
  },
  {
    iataCode: 'BGO',
    icaoCode: 'ENBR',
    name: 'Bergen',
    country: {code: 'NO', name: 'Norway'},
    city: 'Flesland',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 60.293386,
    longitude: 5.218142,
    altitude: 51.816
  },
  {
    iataCode: 'BGR',
    icaoCode: 'KBGR',
    name: 'Bangor',
    country: {code: 'US', name: 'United States'},
    city: 'Bangor Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.807444,
    longitude: -68.828139,
    altitude: 58.5216
  },
  {
    iataCode: 'SIC',
    icaoCode: 'LTCM',
    name: 'Sinop',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Sinop Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 42.0158,
    longitude: 35.0664,
    altitude: 6.096
  },
  {
    iataCode: 'SID',
    icaoCode: 'GVAC',
    name: 'Amilcar Cabral',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Amilcar Cabral Intl',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 16.741389,
    longitude: -22.949444,
    altitude: 53.9496
  },
  {
    iataCode: 'SIG',
    icaoCode: 'TJIG',
    name: 'San Juan',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Fernando Luis Ribas Dominicci',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.456828,
    longitude: -66.098139,
    altitude: 3.048
  },
  {
    iataCode: 'SIF',
    icaoCode: 'VNSI',
    name: 'Simara',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Simara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.159456,
    longitude: 84.980122,
    altitude: 137.16
  },
  {
    iataCode: 'BGX',
    icaoCode: 'SBBG',
    name: 'Bage',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Comandante Gustavo Kraemer',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -31.390528,
    longitude: -54.112244,
    altitude: 182.88
  },
  {
    iataCode: 'BGY',
    icaoCode: 'LIME',
    name: 'Bergamo',
    country: {code: 'IT', name: 'Italy'},
    city: 'Bergamo Orio Al Serio',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.673889,
    longitude: 9.704166,
    altitude: 238.3536
  },
  {
    iataCode: 'SIJ',
    icaoCode: 'BISI',
    name: 'Siglufjordur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Siglufjordur',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 66.133333,
    longitude: -18.916667,
    altitude: 3.048
  },
  {
    iataCode: 'SIN',
    icaoCode: 'WSSS',
    name: 'Singapore',
    country: {code: 'SG', name: 'Singapore'},
    city: 'Changi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Singapore',
    utcOffset: 480,
    latitude: 1.350189,
    longitude: 103.994433,
    altitude: 6.7056
  },
  {
    iataCode: 'SIQ',
    icaoCode: 'WIDS',
    name: 'Singkep',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Dabo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -0.479189,
    longitude: 104.579283,
    altitude: 28.956
  },
  {
    iataCode: 'SIP',
    icaoCode: 'UKFF',
    name: 'Simferopol',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Simferopol Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Simferopol',
    utcOffset: 120,
    latitude: 45.052222,
    longitude: 33.975139,
    altitude: 194.7672
  },
  {
    iataCode: 'SIS',
    icaoCode: 'FASS',
    name: 'Sishen',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Sishen',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -27.648606,
    longitude: 22.999278,
    altitude: 1172.8704
  },
  {
    iataCode: 'BHB',
    icaoCode: 'KBHB',
    name: 'Bar Harbor',
    country: {code: 'US', name: 'United States'},
    city: 'Hancock County - Bar Harbor',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.449769,
    longitude: -68.361565,
    altitude: 25.2984
  },
  {
    iataCode: 'SIR',
    icaoCode: 'LSGS',
    name: 'Sion',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Sion',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.219592,
    longitude: 7.326764,
    altitude: 483.108
  },
  {
    iataCode: 'BHE',
    icaoCode: 'NZWB',
    name: 'Woodbourne',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Woodbourne',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -41.518333,
    longitude: 173.870278,
    altitude: 33.2232
  },
  {
    iataCode: 'SIU',
    icaoCode: 'MNSI',
    name: 'Siuna',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Siuna Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 13.716667,
    longitude: -84.776944,
    altitude: 146.304
  },
  {
    iataCode: 'BHD',
    icaoCode: 'EGAC',
    name: 'Belfast',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'City',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.618056,
    longitude: -5.8725,
    altitude: 4.572
  },
  {
    iataCode: 'SIT',
    icaoCode: 'PASI',
    name: 'Sitka',
    country: {code: 'US', name: 'United States'},
    city: 'Sitka Rocky Gutierrez',
    region: '',
    terminal: '',
    timezoneId: 'America/Sitka',
    utcOffset: -540,
    latitude: 57.047138,
    longitude: -135.361611,
    altitude: 6.4008
  },
  {
    iataCode: 'BHG',
    icaoCode: 'MHBL',
    name: 'Brus Laguna',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Brus Laguna Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.7631,
    longitude: -84.5436,
    altitude: 0.6096
  },
  {
    iataCode: 'BHI',
    icaoCode: 'SAZB',
    name: 'Bahia Blanca',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Comandante Espora',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -38.724967,
    longitude: -62.169317,
    altitude: 74.9808
  },
  {
    iataCode: 'BHH',
    icaoCode: 'OEBH',
    name: 'Bisha',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Bisha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 19.98435,
    longitude: 42.620881,
    altitude: 1184.7576
  },
  {
    iataCode: 'BHK',
    icaoCode: 'UTSB',
    name: 'Bukhara',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Bukhara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 39.775,
    longitude: 64.483333,
    altitude: 228.9048
  },
  {
    iataCode: 'BHJ',
    icaoCode: 'VABJ',
    name: 'Bhuj',
    country: {code: 'IN', name: 'India'},
    city: 'Bhuj',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.287828,
    longitude: 69.670147,
    altitude: 81.6864
  },
  {
    iataCode: 'BHM',
    icaoCode: 'KBHM',
    name: 'Birmingham',
    country: {code: 'US', name: 'United States'},
    city: 'Birmingham Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.562942,
    longitude: -86.75355,
    altitude: 196.2912
  },
  {
    iataCode: 'BHO',
    icaoCode: 'VABP',
    name: 'Bhopal',
    country: {code: 'IN', name: 'India'},
    city: 'Bhopal',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.287467,
    longitude: 77.337375,
    altitude: 523.9512
  },
  {
    iataCode: 'BHN',
    icaoCode: 'OYBN',
    name: 'Beihan',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Beihan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 14.781972,
    longitude: 45.720083,
    altitude: 1158.24
  },
  {
    iataCode: 'BHQ',
    icaoCode: 'YBHI',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Broken Hill Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Broken_Hill',
    utcOffset: 540,
    latitude: -32.0014,
    longitude: 141.472,
    altitude: 291.9984
  },
  {
    iataCode: 'BHP',
    icaoCode: 'VNBJ',
    name: 'Bhojpur',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Bhojpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.14743,
    longitude: 87.050819,
    altitude: 1219.2
  },
  {
    iataCode: 'BHS',
    icaoCode: 'YBTH',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bathurst Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.4094,
    longitude: 149.652,
    altitude: 742.188
  },
  {
    iataCode: 'SJD',
    icaoCode: 'MMSD',
    name: 'San Jose Del Cabo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Los Cabos Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 23.15185,
    longitude: -109.721044,
    altitude: 113.9952
  },
  {
    iataCode: 'BHR',
    icaoCode: 'VNBP',
    name: 'Bharatpur',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Bharatpur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.6781,
    longitude: 84.4294,
    altitude: 182.88
  },
  {
    iataCode: 'SJC',
    icaoCode: 'KSJC',
    name: 'San Jose',
    country: {code: 'US', name: 'United States'},
    city: 'Norman Y Mineta San Jose Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.3626,
    longitude: -121.929022,
    altitude: 18.8976
  },
  {
    iataCode: 'BHU',
    icaoCode: 'VABV',
    name: 'Bhaunagar',
    country: {code: 'IN', name: 'India'},
    city: 'Bhavnagar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.752206,
    longitude: 72.185181,
    altitude: 13.4112
  },
  {
    iataCode: 'SJE',
    icaoCode: 'SKSJ',
    name: 'San Jose Del Guaviare',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Jorge E Gonzalez Torres',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.579694,
    longitude: -72.639358,
    altitude: 184.404
  },
  {
    iataCode: 'BHV',
    icaoCode: 'OPBW',
    name: 'Bahawalpur',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Bahawalpur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 29.3481,
    longitude: 71.717981,
    altitude: 119.4816
  },
  {
    iataCode: 'BHY',
    icaoCode: 'ZGBH',
    name: 'Beihai',
    country: {code: 'CN', name: 'China'},
    city: 'Beihai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 21.5394,
    longitude: 109.294,
    altitude: 0.0
  },
  {
    iataCode: 'SJJ',
    icaoCode: 'LQSA',
    name: 'Sarajevo',
    country: {code: 'BA', name: 'Bosnia and Herzegovina'},
    city: 'Sarajevo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sarajevo',
    utcOffset: 60,
    latitude: 43.824583,
    longitude: 18.331467,
    altitude: 520.5984
  },
  {
    iataCode: 'BHX',
    icaoCode: 'EGBB',
    name: 'Birmingham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Birmingham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.453856,
    longitude: -1.748028,
    altitude: 99.6696
  },
  {
    iataCode: 'SJI',
    icaoCode: 'RPVS',
    name: 'San Jose',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Antique',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 10.766044,
    longitude: 121.933439,
    altitude: 7.0104
  },
  {
    iataCode: 'SJK',
    icaoCode: 'SBSJ',
    name: 'Sao Jose Dos Campos',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Professor Urbano Ernesto Stumpf',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.228172,
    longitude: -45.862739,
    altitude: 646.176
  },
  {
    iataCode: 'SJP',
    icaoCode: 'SBSR',
    name: 'Sao Jose Do Rio Preto',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Sao Jose Do Rio Preto',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -20.816567,
    longitude: -49.406511,
    altitude: 543.7632
  },
  {
    iataCode: 'SJO',
    icaoCode: 'MROC',
    name: 'San Jose',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Juan Santamaria Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.993861,
    longitude: -84.208806,
    altitude: 920.8008
  },
  {
    iataCode: 'BIA',
    icaoCode: 'LFKB',
    name: 'Bastia',
    country: {code: 'FR', name: 'France'},
    city: 'Poretta',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 42.552664,
    longitude: 9.483731,
    altitude: 7.9248
  },
  {
    iataCode: 'SJT',
    icaoCode: 'KSJT',
    name: 'San Angelo',
    country: {code: 'US', name: 'United States'},
    city: 'San Angelo Rgnl Mathis Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.35775,
    longitude: -100.496306,
    altitude: 584.9112
  },
  {
    iataCode: 'BIF',
    icaoCode: 'KBIF',
    name: 'El Paso',
    country: {code: 'US', name: 'United States'},
    city: 'Biggs Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 31.849528,
    longitude: -106.380039,
    altitude: 1203.3504
  },
  {
    iataCode: 'SJU',
    icaoCode: 'TJSJ',
    name: 'San Juan',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Luis Munoz Marin Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.439417,
    longitude: -66.001833,
    altitude: 2.7432
  },
  {
    iataCode: 'BIG',
    icaoCode: 'PABI',
    name: 'Delta Junction',
    country: {code: 'US', name: 'United States'},
    city: 'Allen Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.994547,
    longitude: -145.721642,
    altitude: 393.4968
  },
  {
    iataCode: 'SJW',
    icaoCode: 'ZBSJ',
    name: 'Shijiazhuang',
    country: {code: 'CN', name: 'China'},
    city: 'Shijiazhuang Daguocun International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 38.280686,
    longitude: 114.6973,
    altitude: 71.0184
  },
  {
    iataCode: 'SJZ',
    icaoCode: 'LPSJ',
    name: 'São Jorge',
    country: {code: 'PT', name: 'Portugal'},
    city: 'São Jorge',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 38.6655,
    longitude: -28.175817,
    altitude: 94.7928
  },
  {
    iataCode: 'SJY',
    icaoCode: 'EFSI',
    name: 'Seinäjoki / Ilmajoki',
    country: {code: 'FI', name: 'Finland'},
    city: 'Seinäjoki Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 62.6921,
    longitude: 22.8323,
    altitude: 92.0496
  },
  {
    iataCode: 'BIL',
    icaoCode: 'KBIL',
    name: 'Billings',
    country: {code: 'US', name: 'United States'},
    city: 'Billings Logan International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 45.80921,
    longitude: -108.537654,
    altitude: 1113.1296
  },
  {
    iataCode: 'BIK',
    icaoCode: 'WABB',
    name: 'Biak',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Frans Kaisiepo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -1.190017,
    longitude: 136.107997,
    altitude: 14.0208
  },
  {
    iataCode: 'JYV',
    icaoCode: 'EFJY',
    name: 'Jyvaskyla',
    country: {code: 'FI', name: 'Finland'},
    city: 'Jyvaskyla',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 62.399453,
    longitude: 25.678253,
    altitude: 139.9032
  },
  {
    iataCode: 'BIM',
    icaoCode: 'MYBS',
    name: 'Alice Town',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'South Bimini',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.699881,
    longitude: -79.264656,
    altitude: 3.048
  },
  {
    iataCode: 'SKA',
    icaoCode: 'KSKA',
    name: 'Spokane',
    country: {code: 'US', name: 'United States'},
    city: 'Fairchild Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.615058,
    longitude: -117.655772,
    altitude: 750.1128
  },
  {
    iataCode: 'BIO',
    icaoCode: 'LEBB',
    name: 'Bilbao',
    country: {code: 'ES', name: 'Spain'},
    city: 'Bilbao',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 43.301097,
    longitude: -2.910608,
    altitude: 42.0624
  },
  {
    iataCode: 'BIR',
    icaoCode: 'VNVT',
    name: 'Biratnagar',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Biratnagar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 26.481453,
    longitude: 87.264036,
    altitude: 71.9328
  },
  {
    iataCode: 'BIQ',
    icaoCode: 'LFBZ',
    name: 'Biarritz-bayonne',
    country: {code: 'FR', name: 'France'},
    city: 'Anglet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.468419,
    longitude: -1.523325,
    altitude: 74.676
  },
  {
    iataCode: 'SKB',
    icaoCode: 'TKPK',
    name: 'Basse Terre',
    country: {code: 'KN', name: 'Saint Kitts and Nevis'},
    city: 'Robert L Bradshaw',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Kitts',
    utcOffset: -240,
    latitude: 17.311194,
    longitude: -62.718667,
    altitude: 51.816
  },
  {
    iataCode: 'SKE',
    icaoCode: 'ENSN',
    name: 'Skien',
    country: {code: 'NO', name: 'Norway'},
    city: 'Geiteryggen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.185,
    longitude: 9.566944,
    altitude: 141.1224
  },
  {
    iataCode: 'BIS',
    icaoCode: 'KBIS',
    name: 'Bismarck',
    country: {code: 'US', name: 'United States'},
    city: 'Bismarck Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 46.775842,
    longitude: -100.757931,
    altitude: 506.2728
  },
  {
    iataCode: 'SKD',
    icaoCode: 'UTSS',
    name: 'Samarkand',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Samarkand',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 39.700547,
    longitude: 66.983829,
    altitude: 677.8752
  },
  {
    iataCode: 'BIV',
    icaoCode: 'KBIV',
    name: 'Holland',
    country: {code: 'US', name: 'United States'},
    city: 'Tulip City Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.742778,
    longitude: -86.107833,
    altitude: 212.7504
  },
  {
    iataCode: 'SKG',
    icaoCode: 'LGTS',
    name: 'Thessaloniki',
    country: {code: 'GR', name: 'Greece'},
    city: 'Makedonia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 40.519725,
    longitude: 22.97095,
    altitude: 6.7056
  },
  {
    iataCode: 'BIU',
    icaoCode: 'BIBD',
    name: 'Bildudalur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Bildudalur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.641389,
    longitude: -23.546111,
    altitude: 7.9248
  },
  {
    iataCode: 'SKF',
    icaoCode: 'KSKF',
    name: 'San Antonio',
    country: {code: 'US', name: 'United States'},
    city: 'Lackland Afb Kelly Fld Annex',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.384228,
    longitude: -98.581108,
    altitude: 210.6168
  },
  {
    iataCode: 'BIX',
    icaoCode: 'KBIX',
    name: 'Biloxi',
    country: {code: 'US', name: 'United States'},
    city: 'Keesler Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.410425,
    longitude: -88.924433,
    altitude: 10.0584
  },
  {
    iataCode: 'SKH',
    icaoCode: 'VNSK',
    name: 'Surkhet',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Surkhet',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.586,
    longitude: 81.636,
    altitude: 731.52
  },
  {
    iataCode: 'SKK',
    icaoCode: 'PFSH',
    name: 'Shaktoolik',
    country: {code: 'US', name: 'United States'},
    city: 'Shaktoolik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.371111,
    longitude: -161.223889,
    altitude: 7.3152
  },
  {
    iataCode: 'SKO',
    icaoCode: 'DNSO',
    name: 'Sokoto',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Sadiq Abubakar Iii Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 12.916322,
    longitude: 5.207189,
    altitude: 307.848
  },
  {
    iataCode: 'SKN',
    icaoCode: 'ENSK',
    name: 'Stokmarknes',
    country: {code: 'NO', name: 'Norway'},
    city: 'Skagen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 68.580833,
    longitude: 15.026111,
    altitude: 3.3528
  },
  {
    iataCode: 'BJA',
    icaoCode: 'DAAE',
    name: 'Bejaja',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Soummam',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.711997,
    longitude: 5.069922,
    altitude: 6.096
  },
  {
    iataCode: 'JZH',
    icaoCode: 'ZUJZ',
    name: 'Jiuzhaigou',
    country: {code: 'CN', name: 'China'},
    city: 'Jiuzhaigou Huanglong',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 32.857,
    longitude: 103.683,
    altitude: 3447.5928
  },
  {
    iataCode: 'SKP',
    icaoCode: 'LWSK',
    name: 'Skopje',
    country: {code: 'MK', name: 'The Former Yugoslav Republic of Macedonia'},
    city: 'Skopje',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Skopje',
    utcOffset: 60,
    latitude: 41.961622,
    longitude: 21.621381,
    altitude: 238.0488
  },
  {
    iataCode: 'SKS',
    icaoCode: 'EKSP',
    name: 'Skrydstrup',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Skrydstrup',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.225553,
    longitude: 9.263931,
    altitude: 42.9768
  },
  {
    iataCode: 'BJB',
    icaoCode: 'OIMN',
    name: 'Bojnourd',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Bojnourd Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 37.492958,
    longitude: 57.308219,
    altitude: 1066.4952
  },
  {
    iataCode: 'SKU',
    icaoCode: 'LGSY',
    name: 'Skiros',
    country: {code: 'GR', name: 'Greece'},
    city: 'Skiros',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.967553,
    longitude: 24.487228,
    altitude: 13.4112
  },
  {
    iataCode: 'SKT',
    icaoCode: 'OPST',
    name: 'Sialkot',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Sialkot Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 32.5356,
    longitude: 74.3639,
    altitude: 246.888
  },
  {
    iataCode: 'BJF',
    icaoCode: 'ENBS',
    name: 'Batsfjord',
    country: {code: 'NO', name: 'Norway'},
    city: 'Batsfjord',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.600278,
    longitude: 29.6925,
    altitude: 149.352
  },
  {
    iataCode: 'SKV',
    icaoCode: 'HESC',
    name: 'St. Catherine',
    country: {code: 'EG', name: 'Egypt'},
    city: 'St Catherine Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 28.685278,
    longitude: 34.0625,
    altitude: 1331.3664
  },
  {
    iataCode: 'BJI',
    icaoCode: 'KBJI',
    name: 'Bemidji',
    country: {code: 'US', name: 'United States'},
    city: 'Bemidji Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.510722,
    longitude: -94.934722,
    altitude: 423.9768
  },
  {
    iataCode: 'SKY',
    icaoCode: 'KSKY',
    name: 'Sandusky',
    country: {code: 'US', name: 'United States'},
    city: 'Griffing Sandusky',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.433361,
    longitude: -82.652333,
    altitude: 176.784
  },
  {
    iataCode: 'BJH',
    icaoCode: 'VNBG',
    name: 'Bajhang',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Bajhang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 29.53896,
    longitude: 81.185364,
    altitude: 1249.68
  },
  {
    iataCode: 'SKX',
    icaoCode: 'UWPS',
    name: 'Saransk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Saransk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 54.1251,
    longitude: 45.2123,
    altitude: 206.0448
  },
  {
    iataCode: 'SKZ',
    icaoCode: 'OPSK',
    name: 'Sukkur',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Sukkur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 27.721989,
    longitude: 68.791683,
    altitude: 59.7408
  },
  {
    iataCode: 'BJM',
    icaoCode: 'HBBA',
    name: 'Bujumbura',
    country: {code: 'BI', name: 'Burundi'},
    city: 'Bujumbura Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bujumbura',
    utcOffset: 120,
    latitude: -3.324019,
    longitude: 29.318519,
    altitude: 786.9936
  },
  {
    iataCode: 'BJL',
    icaoCode: 'GBYD',
    name: 'Banjul',
    country: {code: 'GM', name: 'Gambia'},
    city: 'Banjul Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Banjul',
    utcOffset: 0,
    latitude: 13.337961,
    longitude: -16.652206,
    altitude: 28.956
  },
  {
    iataCode: 'BJO',
    icaoCode: 'SLBJ',
    name: 'Bermejo',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Bermejo',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -180,
    latitude: -22.773336,
    longitude: -64.312881,
    altitude: 381.0
  },
  {
    iataCode: 'SLA',
    icaoCode: 'SASA',
    name: 'Salta',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Salta',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -24.855978,
    longitude: -65.486169,
    altitude: 1246.0224
  },
  {
    iataCode: 'SLD',
    icaoCode: 'LZSL',
    name: 'Sliac',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'Sliac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 48.637839,
    longitude: 19.134108,
    altitude: 317.9064
  },
  {
    iataCode: 'BJR',
    icaoCode: 'HABD',
    name: 'Bahar Dar',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Bahir Dar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 11.608075,
    longitude: 37.321644,
    altitude: 1821.4848
  },
  {
    iataCode: 'SLC',
    icaoCode: 'KSLC',
    name: 'Salt Lake City',
    country: {code: 'US', name: 'United States'},
    city: 'Salt Lake City Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 40.788389,
    longitude: -111.977772,
    altitude: 1288.3896
  },
  {
    iataCode: 'SLF',
    icaoCode: 'OESL',
    name: 'Sulayel',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Sulayel',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 20.464744,
    longitude: 45.619644,
    altitude: 616.0008
  },
  {
    iataCode: 'SLH',
    icaoCode: 'NVSC',
    name: 'Sola',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Sola Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -13.8517,
    longitude: 167.537,
    altitude: 2.1336
  },
  {
    iataCode: 'BJV',
    icaoCode: 'LTFE',
    name: 'Bodrum',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Bodrum - Milas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.82,
    longitude: 28.204,
    altitude: 5.7912
  },
  {
    iataCode: 'BJX',
    icaoCode: 'MMLO',
    name: 'Del Bajio',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Guanajuato Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.993464,
    longitude: -101.480847,
    altitude: 1815.3888
  },
  {
    iataCode: 'SLI',
    icaoCode: 'FLSW',
    name: 'Solwesi',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Solwesi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -12.1737,
    longitude: 26.3651,
    altitude: 1387.1448
  },
  {
    iataCode: 'SLL',
    icaoCode: 'OOSA',
    name: 'Salalah',
    country: {code: 'OM', name: 'Oman'},
    city: 'Salalah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Muscat',
    utcOffset: 240,
    latitude: 17.038719,
    longitude: 54.091297,
    altitude: 22.2504
  },
  {
    iataCode: 'BJZ',
    icaoCode: 'LEBZ',
    name: 'Badajoz',
    country: {code: 'ES', name: 'Spain'},
    city: 'Talavera La Real',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 38.89125,
    longitude: -6.821333,
    altitude: 185.6232
  },
  {
    iataCode: 'SLK',
    icaoCode: 'KSLK',
    name: 'Saranac Lake',
    country: {code: 'US', name: 'United States'},
    city: 'Adirondack Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.3853,
    longitude: -74.2062,
    altitude: 506.8824
  },
  {
    iataCode: 'SLN',
    icaoCode: 'KSLN',
    name: 'Salina',
    country: {code: 'US', name: 'United States'},
    city: 'Salina Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.791,
    longitude: -97.6522,
    altitude: 392.5824
  },
  {
    iataCode: 'SLM',
    icaoCode: 'LESA',
    name: 'Salamanca',
    country: {code: 'ES', name: 'Spain'},
    city: 'Salamanca',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 40.952117,
    longitude: -5.501986,
    altitude: 790.956
  },
  {
    iataCode: 'SLP',
    icaoCode: 'MMSP',
    name: 'San Luis Potosi',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ponciano Arriaga Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 22.254303,
    longitude: -100.930806,
    altitude: 1839.468
  },
  {
    iataCode: 'BKA',
    icaoCode: 'UUBB',
    name: 'Moscow',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Bykovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.617222,
    longitude: 38.059999,
    altitude: 130.1496
  },
  {
    iataCode: 'SLQ',
    icaoCode: 'PASL',
    name: 'Sleetmute',
    country: {code: 'US', name: 'United States'},
    city: 'Sleetmute Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.700566,
    longitude: -157.165833,
    altitude: 57.912
  },
  {
    iataCode: 'BKD',
    icaoCode: 'KBKD',
    name: 'Breckenridge',
    country: {code: 'US', name: 'United States'},
    city: 'Stephens Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.719047,
    longitude: -98.891,
    altitude: 391.3632
  },
  {
    iataCode: 'BKC',
    icaoCode: 'PABL',
    name: 'Buckland',
    country: {code: 'US', name: 'United States'},
    city: 'Buckland Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 65.981667,
    longitude: -161.149167,
    altitude: 9.4488
  },
  {
    iataCode: 'BKF',
    icaoCode: 'KBKF',
    name: 'Buckley',
    country: {code: 'US', name: 'United States'},
    city: 'Buckley Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.701668,
    longitude: -104.75166,
    altitude: 1725.7776
  },
  {
    iataCode: 'SLV',
    icaoCode: 'VISM',
    name: 'Shimla',
    country: {code: 'IN', name: 'India'},
    city: 'Shimla Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 31.081803,
    longitude: 77.067967,
    altitude: 1545.9456
  },
  {
    iataCode: 'SLU',
    icaoCode: 'TLPC',
    name: 'Castries',
    country: {code: 'LC', name: 'Saint Lucia'},
    city: 'George F L Charles',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Lucia',
    utcOffset: -240,
    latitude: 14.020228,
    longitude: -60.992936,
    altitude: 6.7056
  },
  {
    iataCode: 'BKH',
    icaoCode: 'PHBK',
    name: 'Barking Sands',
    country: {code: 'US', name: 'United States'},
    city: 'Barking Sands Pmrf',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 22.022833,
    longitude: -159.785,
    altitude: 7.0104
  },
  {
    iataCode: 'SLX',
    icaoCode: 'MBSY',
    name: 'Salt Cay',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'Salt Cay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.333,
    longitude: -71.2,
    altitude: 0.9144
  },
  {
    iataCode: 'BKG',
    icaoCode: 'KBBG',
    name: 'Branson',
    country: {code: 'US', name: 'United States'},
    city: 'Branson Llc',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.531994,
    longitude: -93.200556,
    altitude: 396.8496
  },
  {
    iataCode: 'SLW',
    icaoCode: 'MMIO',
    name: 'Saltillo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Plan De Guadalupe Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 25.549497,
    longitude: -100.928669,
    altitude: 1456.3344
  },
  {
    iataCode: 'SLZ',
    icaoCode: 'SBSL',
    name: 'Sao Luis',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Marechal Cunha Machado Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Belem',
    utcOffset: -180,
    latitude: -2.585361,
    longitude: -44.234139,
    altitude: 54.2544
  },
  {
    iataCode: 'BKI',
    icaoCode: 'WBKK',
    name: 'Kota Kinabalu',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kota Kinabalu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 5.937208,
    longitude: 116.051181,
    altitude: 3.048
  },
  {
    iataCode: 'SLY',
    icaoCode: 'USDD',
    name: 'Salekhard',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Salekhard Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 66.590753,
    longitude: 66.611042,
    altitude: 66.4464
  },
  {
    iataCode: 'BKK',
    icaoCode: 'VTBS',
    name: 'Bangkok',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Suvarnabhumi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 13.681108,
    longitude: 100.747283,
    altitude: 1.524
  },
  {
    iataCode: 'BKM',
    icaoCode: 'WBGQ',
    name: 'Bakalalan',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Bakalalan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.974,
    longitude: 115.618,
    altitude: 883.92
  },
  {
    iataCode: 'SMA',
    icaoCode: 'LPAZ',
    name: 'Santa Maria',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Santa Maria',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 36.97139,
    longitude: -25.170639,
    altitude: 93.8784
  },
  {
    iataCode: 'BKO',
    icaoCode: 'GABS',
    name: 'Bamako',
    country: {code: 'ML', name: 'Mali'},
    city: 'Senou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bamako',
    utcOffset: 0,
    latitude: 12.533544,
    longitude: -7.949944,
    altitude: 380.0856
  },
  {
    iataCode: 'BKQ',
    icaoCode: 'YBCK',
    name: 'Blackall',
    country: {code: 'AU', name: 'Australia'},
    city: 'Blackall',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -24.427778,
    longitude: 145.428611,
    altitude: 282.8544
  },
  {
    iataCode: 'SME',
    icaoCode: 'KSME',
    name: 'Somerset',
    country: {code: 'US', name: 'United States'},
    city: 'Lake Cumberland Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.053611,
    longitude: -84.615556,
    altitude: 282.5496
  },
  {
    iataCode: 'BKS',
    icaoCode: 'WIPL',
    name: 'Bengkulu',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Fatmawati Soekarno',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -3.8637,
    longitude: 102.339036,
    altitude: 15.24
  },
  {
    iataCode: 'SMD',
    icaoCode: 'KSMD',
    name: 'Fort Wayne In',
    country: {code: 'US', name: 'United States'},
    city: 'Smith Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 41.143353,
    longitude: -85.152778,
    altitude: 254.2032
  },
  {
    iataCode: 'SMF',
    icaoCode: 'KSMF',
    name: 'Sacramento',
    country: {code: 'US', name: 'United States'},
    city: 'Sacramento Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.695417,
    longitude: -121.590778,
    altitude: 8.2296
  },
  {
    iataCode: 'BKX',
    icaoCode: 'BKX',
    name: 'Brookings',
    country: {code: 'US', name: 'United States'},
    city: 'Brookings Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.3048,
    longitude: -96.8169,
    altitude: 502.3104
  },
  {
    iataCode: 'SMI',
    icaoCode: 'LGSM',
    name: 'Samos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Samos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.689999,
    longitude: 26.911667,
    altitude: 5.7912
  },
  {
    iataCode: 'BKW',
    icaoCode: 'KBKW',
    name: 'Beckley',
    country: {code: 'US', name: 'United States'},
    city: 'Raleigh County Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.7873,
    longitude: -81.1242,
    altitude: 763.2192
  },
  {
    iataCode: 'BKZ',
    icaoCode: 'HTBU',
    name: 'Bukoba',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Bukoba Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -1.3,
    longitude: 31.8,
    altitude: 1141.476
  },
  {
    iataCode: 'SMK',
    icaoCode: 'PAMK',
    name: 'St. Michael',
    country: {code: 'US', name: 'United States'},
    city: 'St. Michael Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 63.49,
    longitude: -162.110278,
    altitude: 29.8704
  },
  {
    iataCode: 'BKY',
    icaoCode: 'FZMA',
    name: 'Bukavu/kavumu',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Bukavu Kavumu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -2.308978,
    longitude: 28.808803,
    altitude: 1719.9864
  },
  {
    iataCode: 'SML',
    icaoCode: 'MYLS',
    name: 'Stella Maris',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Stella Maris',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 23.581444,
    longitude: -75.270475,
    altitude: 3.048
  },
  {
    iataCode: 'SMO',
    icaoCode: 'KSMO',
    name: 'Santa Monica',
    country: {code: 'US', name: 'United States'},
    city: 'Santa Monica Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 34.015833,
    longitude: -118.451306,
    altitude: 53.9496
  },
  {
    iataCode: 'SMN',
    icaoCode: 'KSMN',
    name: 'Salmon',
    country: {code: 'US', name: 'United States'},
    city: 'Lemhi County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 45.123889,
    longitude: -113.881389,
    altitude: 1232.3064
  },
  {
    iataCode: 'BLA',
    icaoCode: 'SVBC',
    name: 'Barcelona',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'General Jose Antonio Anzoategui Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.107139,
    longitude: -64.689161,
    altitude: 7.9248
  },
  {
    iataCode: 'SMQ',
    icaoCode: 'WAOS',
    name: 'Sampit-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sampit(hasan) Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: -2.499194,
    longitude: 112.974992,
    altitude: 15.24
  },
  {
    iataCode: 'SMS',
    icaoCode: 'FMMS',
    name: 'Sainte Marie',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Sainte Marie',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -17.093889,
    longitude: 49.815834,
    altitude: 2.1336
  },
  {
    iataCode: 'SMR',
    icaoCode: 'SKSM',
    name: 'Santa Marta',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Simon Bolivar',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 11.11965,
    longitude: -74.230647,
    altitude: 6.7056
  },
  {
    iataCode: 'BLE',
    icaoCode: 'ESSD',
    name: 'Borlange',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Borlange',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 60.422017,
    longitude: 15.515211,
    altitude: 153.3144
  },
  {
    iataCode: 'BLD',
    icaoCode: 'KBVU',
    name: 'Boulder City',
    country: {code: 'US', name: 'United States'},
    city: 'Boulder City Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.5651,
    longitude: -114.514,
    altitude: 670.8648
  },
  {
    iataCode: 'BLG',
    icaoCode: 'WBGC',
    name: 'Belaga',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Belaga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 2.65,
    longitude: 113.767,
    altitude: 60.96
  },
  {
    iataCode: 'SMW',
    icaoCode: 'GMMA',
    name: 'Smara',
    country: {code: 'EH', name: 'Western Sahara'},
    city: 'Smara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/El_Aaiun',
    utcOffset: 0,
    latitude: 26.7318,
    longitude: -11.6847,
    altitude: 106.68
  },
  {
    iataCode: 'BLF',
    icaoCode: 'BLF',
    name: 'Bluefield',
    country: {code: 'US', name: 'United States'},
    city: 'Mercer County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.2958,
    longitude: -81.2077,
    altitude: 870.8136
  },
  {
    iataCode: 'SMV',
    icaoCode: 'LSZS',
    name: 'Samedan',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Samedan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.534075,
    longitude: 9.884106,
    altitude: 1706.88
  },
  {
    iataCode: 'BLI',
    icaoCode: 'KBLI',
    name: 'Bellingham',
    country: {code: 'US', name: 'United States'},
    city: 'Bellingham Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.792694,
    longitude: -122.537528,
    altitude: 51.816
  },
  {
    iataCode: 'BLH',
    icaoCode: 'KBLH',
    name: 'Blythe',
    country: {code: 'US', name: 'United States'},
    city: 'Blythe Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.619167,
    longitude: -114.716889,
    altitude: 121.6152
  },
  {
    iataCode: 'SMX',
    icaoCode: 'KSMX',
    name: 'Santa Maria',
    country: {code: 'US', name: 'United States'},
    city: 'Santa Maria Pub Cpt G Allan Hancock Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.8989,
    longitude: -120.457,
    altitude: 79.5528
  },
  {
    iataCode: 'BLK',
    icaoCode: 'EGNH',
    name: 'Blackpool',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Blackpool',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.771667,
    longitude: -3.028611,
    altitude: 10.3632
  },
  {
    iataCode: 'BLJ',
    icaoCode: 'DABT',
    name: 'Batna',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Batna Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.752106,
    longitude: 6.308589,
    altitude: 822.0456
  },
  {
    iataCode: 'SMZ',
    icaoCode: 'SMST',
    name: 'Stoelmans Eiland',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Stoelmans Eiland Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 4.35,
    longitude: -54.41667,
    altitude: 3.048
  },
  {
    iataCode: 'BLL',
    icaoCode: 'EKBI',
    name: 'Billund',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Billund',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.740322,
    longitude: 9.151778,
    altitude: 75.2856
  },
  {
    iataCode: 'BLQ',
    icaoCode: 'LIPE',
    name: 'Bologna',
    country: {code: 'IT', name: 'Italy'},
    city: 'Bologna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.535444,
    longitude: 11.288667,
    altitude: 37.4904
  },
  {
    iataCode: 'SNA',
    icaoCode: 'KSNA',
    name: 'Santa Ana',
    country: {code: 'US', name: 'United States'},
    city: 'John Wayne Arpt Orange Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.675667,
    longitude: -117.868222,
    altitude: 17.0688
  },
  {
    iataCode: 'BLR',
    icaoCode: 'VOBG',
    name: 'Bangalore',
    country: {code: 'IN', name: 'India'},
    city: 'Bangalore',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 12.949986,
    longitude: 77.668206,
    altitude: 887.5776
  },
  {
    iataCode: 'SNC',
    icaoCode: 'SESA',
    name: 'Salinas',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'General Ulpiano Paez',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -2.204994,
    longitude: -80.988878,
    altitude: 5.4864
  },
  {
    iataCode: 'BLT',
    icaoCode: 'YBTR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Blackwater Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.6031,
    longitude: 148.807,
    altitude: 200.2536
  },
  {
    iataCode: 'SNE',
    icaoCode: 'GVSN',
    name: 'Sao Nocolau Island',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Preguica',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 16.588356,
    longitude: -24.284656,
    altitude: 203.9112
  },
  {
    iataCode: 'BLV',
    icaoCode: 'KBLV',
    name: 'Belleville',
    country: {code: 'US', name: 'United States'},
    city: 'Scott Afb Midamerica',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.545178,
    longitude: -89.835183,
    altitude: 139.9032
  },
  {
    iataCode: 'BLZ',
    icaoCode: 'FWCL',
    name: 'Blantyre',
    country: {code: 'MW', name: 'Malawi'},
    city: 'Chileka Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Blantyre',
    utcOffset: 120,
    latitude: -15.679053,
    longitude: 34.974014,
    altitude: 778.764
  },
  {
    iataCode: 'SNN',
    icaoCode: 'EINN',
    name: 'Shannon',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Shannon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 52.701978,
    longitude: -8.924817,
    altitude: 14.0208
  },
  {
    iataCode: 'SNP',
    icaoCode: 'PASN',
    name: 'St. Paul Island',
    country: {code: 'US', name: 'United States'},
    city: 'St Paul Island',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -660,
    latitude: 57.167333,
    longitude: -170.220444,
    altitude: 19.2024
  },
  {
    iataCode: 'SNO',
    icaoCode: 'VTUI',
    name: 'Sakon Nakhon',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Sakon Nakhon',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 17.195142,
    longitude: 104.118625,
    altitude: 161.2392
  },
  {
    iataCode: 'SNR',
    icaoCode: 'LFRZ',
    name: 'St.-nazaire',
    country: {code: 'FR', name: 'France'},
    city: 'Montoir',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.312189,
    longitude: -2.149181,
    altitude: 3.9624
  },
  {
    iataCode: 'BMA',
    icaoCode: 'ESSB',
    name: 'Stockholm',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Bromma',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.354372,
    longitude: 17.94165,
    altitude: 14.3256
  },
  {
    iataCode: 'BMD',
    icaoCode: 'FMML',
    name: 'Belo Sur Tsiribihina',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Belo Sur Tsiribihina Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -19.6867,
    longitude: 44.5419,
    altitude: 46.9392
  },
  {
    iataCode: 'BMC',
    icaoCode: 'KBMC',
    name: 'Brigham City',
    country: {code: 'US', name: 'United States'},
    city: 'Brigham City',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.552,
    longitude: -112.062,
    altitude: 1288.9992
  },
  {
    iataCode: 'BME',
    icaoCode: 'YPBR',
    name: 'Broome',
    country: {code: 'AU', name: 'Australia'},
    city: 'Broome',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -17.8,
    longitude: 122.2,
    altitude: 17.0688
  },
  {
    iataCode: 'SNU',
    icaoCode: 'MUSC',
    name: 'Santa Clara',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Abel Santamaria',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.492192,
    longitude: -79.943611,
    altitude: 103.0224
  },
  {
    iataCode: 'BMG',
    icaoCode: 'KBMG',
    name: 'Bloomington',
    country: {code: 'US', name: 'United States'},
    city: 'Monroe County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -240,
    latitude: 39.146021,
    longitude: -86.616681,
    altitude: 257.8608
  },
  {
    iataCode: 'SNW',
    icaoCode: 'VYTD',
    name: 'Thandwe',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Thandwe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 18.460731,
    longitude: 94.300119,
    altitude: 6.096
  },
  {
    iataCode: 'BMI',
    icaoCode: 'KBMI',
    name: 'Bloomington',
    country: {code: 'US', name: 'United States'},
    city: 'Central Illinois Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.477111,
    longitude: -88.915917,
    altitude: 265.4808
  },
  {
    iataCode: 'BMK',
    icaoCode: 'EDWR',
    name: 'Borkum',
    country: {code: 'DE', name: 'Germany'},
    city: 'Borkum',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.5955,
    longitude: 6.709167,
    altitude: 0.9144
  },
  {
    iataCode: 'BMM',
    icaoCode: 'FOOB',
    name: 'Bitam',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Bitam',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: 2.075639,
    longitude: 11.493195,
    altitude: 600.1512
  },
  {
    iataCode: 'BMO',
    icaoCode: 'VYBM',
    name: 'Banmaw',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Banmaw Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 24.269033,
    longitude: 97.246153,
    altitude: 112.776
  },
  {
    iataCode: 'SOC',
    icaoCode: 'WARQ',
    name: 'Solo City',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Adi Sumarmo Wiryokusumo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -7.516089,
    longitude: 110.756892,
    altitude: 128.3208
  },
  {
    iataCode: 'BMQ',
    icaoCode: 'KBMQ',
    name: 'Bamburi',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Bamburi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -3.982689,
    longitude: 39.730897,
    altitude: 15.24
  },
  {
    iataCode: 'SOB',
    icaoCode: 'LHSM',
    name: 'Sármellék',
    country: {code: 'HU', name: 'Hungary'},
    city: 'Sármellék International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Budapest',
    utcOffset: 60,
    latitude: 46.686389,
    longitude: 17.159056,
    altitude: 124.3584
  },
  {
    iataCode: 'SOD',
    icaoCode: 'SDCO',
    name: 'Sorocaba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Sorocaba Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.478,
    longitude: -47.49,
    altitude: 633.0696
  },
  {
    iataCode: 'BMV',
    icaoCode: 'VVBM',
    name: 'Buonmethuot',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Buon Ma Thuot Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 12.668311,
    longitude: 108.120272,
    altitude: 526.9992
  },
  {
    iataCode: 'SOG',
    icaoCode: 'ENSG',
    name: 'Sogndal',
    country: {code: 'NO', name: 'Norway'},
    city: 'Sogndal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 61.1561,
    longitude: 7.13778,
    altitude: 0.0
  },
  {
    iataCode: 'BMU',
    icaoCode: 'WADB',
    name: 'Bima',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Muhammad Salahuddin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.539647,
    longitude: 118.687322,
    altitude: 0.9144
  },
  {
    iataCode: 'SOF',
    icaoCode: 'LBSF',
    name: 'Sofia',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Sofia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 42.695194,
    longitude: 23.406167,
    altitude: 530.9616
  },
  {
    iataCode: 'BMW',
    icaoCode: 'DATM',
    name: 'Bordj Badji Mokhtar',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Bordj Badji Mokhtar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 21.375,
    longitude: 0.923889,
    altitude: 397.1544
  },
  {
    iataCode: 'BMY',
    icaoCode: 'NWWC',
    name: 'Waala',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Belep Islands Airport Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -19.7206,
    longitude: 163.661,
    altitude: 93.2688
  },
  {
    iataCode: 'SOJ',
    icaoCode: 'ENSR',
    name: '',
    country: {code: 'NO', name: 'Norway'},
    city: 'Sorkjosen Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.7868,
    longitude: 20.9594,
    altitude: 4.8768
  },
  {
    iataCode: 'SOM',
    icaoCode: 'SVST',
    name: 'San Tome',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'San Tome',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.945147,
    longitude: -64.151083,
    altitude: 255.1176
  },
  {
    iataCode: 'SON',
    icaoCode: 'NVSS',
    name: 'Santo',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Santo Pekoa International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.505033,
    longitude: 167.219742,
    altitude: 56.0832
  },
  {
    iataCode: 'BNA',
    icaoCode: 'KBNA',
    name: 'Nashville',
    country: {code: 'US', name: 'United States'},
    city: 'Nashville Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.124472,
    longitude: -86.678194,
    altitude: 182.5752
  },
  {
    iataCode: 'SOQ',
    icaoCode: 'WASS',
    name: 'Sorong',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Jefman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -0.926358,
    longitude: 131.121194,
    altitude: 3.048
  },
  {
    iataCode: 'BNE',
    icaoCode: 'YBBN',
    name: 'Brisbane',
    country: {code: 'AU', name: 'Australia'},
    city: 'Brisbane Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -27.384167,
    longitude: 153.1175,
    altitude: 3.9624
  },
  {
    iataCode: 'SOU',
    icaoCode: 'EGHI',
    name: 'Southampton',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Southampton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.950261,
    longitude: -1.356803,
    altitude: 13.4112
  },
  {
    iataCode: 'BND',
    icaoCode: 'OIKB',
    name: 'Bandar Abbas',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Bandar Abbass Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 27.218317,
    longitude: 56.37785,
    altitude: 6.7056
  },
  {
    iataCode: 'SOT',
    icaoCode: 'EFSO',
    name: 'Sodankyla',
    country: {code: 'FI', name: 'Finland'},
    city: 'Sodankyla',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 67.395033,
    longitude: 26.619133,
    altitude: 183.4896
  },
  {
    iataCode: 'SOW',
    icaoCode: 'KSOW',
    name: 'Show Low',
    country: {code: 'US', name: 'United States'},
    city: 'Show Low Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 34.265556,
    longitude: -110.005556,
    altitude: 1955.292
  },
  {
    iataCode: 'BNI',
    icaoCode: 'DNBE',
    name: 'Benin',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Benin',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 6.316981,
    longitude: 5.599503,
    altitude: 78.6384
  },
  {
    iataCode: 'SOY',
    icaoCode: 'EGER',
    name: 'Stronsay',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Stronsay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.1553,
    longitude: -2.64139,
    altitude: 11.8872
  },
  {
    iataCode: 'BNK',
    icaoCode: 'YBNA',
    name: 'Ballina Byron Bay',
    country: {code: 'AU', name: 'Australia'},
    city: 'Ballina Byron Gateway',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -28.833889,
    longitude: 153.5625,
    altitude: 2.1336
  },
  {
    iataCode: 'SOZ',
    icaoCode: 'LFKS',
    name: 'Solenzara',
    country: {code: 'FR', name: 'France'},
    city: 'Solenzara',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 41.924416,
    longitude: 9.406,
    altitude: 8.5344
  },
  {
    iataCode: 'BNN',
    icaoCode: 'ENBN',
    name: 'Bronnoysund',
    country: {code: 'NO', name: 'Norway'},
    city: 'Bronnoy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 65.461111,
    longitude: 12.2175,
    altitude: 7.62
  },
  {
    iataCode: 'SPB',
    icaoCode: 'KSPB',
    name: 'San Luis',
    country: {code: 'US', name: 'United States'},
    city: 'Scappoose Industrial Airpark',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 45.771028,
    longitude: -122.861833,
    altitude: 17.6784
  },
  {
    iataCode: 'BNP',
    icaoCode: 'OPBN',
    name: 'Bannu',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Bannu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 32.9729,
    longitude: 70.5279,
    altitude: 403.86
  },
  {
    iataCode: 'BNS',
    icaoCode: 'SVBI',
    name: 'Barinas',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Barinas',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.619575,
    longitude: -70.220825,
    altitude: 202.9968
  },
  {
    iataCode: 'SPD',
    icaoCode: 'VGSD',
    name: 'Saidpur',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Saidpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 25.759228,
    longitude: 88.908869,
    altitude: 38.1
  },
  {
    iataCode: 'SPC',
    icaoCode: 'GCLA',
    name: 'Santa Cruz De La Palma',
    country: {code: 'ES', name: 'Spain'},
    city: 'La Palma',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.626478,
    longitude: -17.755611,
    altitude: 32.6136
  },
  {
    iataCode: 'SPG',
    icaoCode: 'KSPG',
    name: 'St. Petersburg',
    country: {code: 'US', name: 'United States'},
    city: 'Albert Whitted',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.765111,
    longitude: -82.626972,
    altitude: 2.1336
  },
  {
    iataCode: 'BNX',
    icaoCode: 'LQBK',
    name: 'Banja Luka',
    country: {code: 'BA', name: 'Bosnia and Herzegovina'},
    city: 'Banja Luka International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sarajevo',
    utcOffset: 60,
    latitude: 44.941444,
    longitude: 17.297501,
    altitude: 121.92
  },
  {
    iataCode: 'SPI',
    icaoCode: 'KSPI',
    name: 'Springfield',
    country: {code: 'US', name: 'United States'},
    city: 'Abraham Lincoln Capital',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.8441,
    longitude: -89.677889,
    altitude: 181.9656
  },
  {
    iataCode: 'SPK',
    icaoCode: 'RJCJ',
    name: 'Chitose',
    country: {code: 'JP', name: 'Japan'},
    city: 'Chitose',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 42.794475,
    longitude: 141.666447,
    altitude: 26.5176
  },
  {
    iataCode: 'SPN',
    icaoCode: 'PGSN',
    name: 'Saipan',
    country: {code: 'MP', name: 'Northern Mariana Islands'},
    city: 'Francisco C Ada Saipan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Saipan',
    utcOffset: 600,
    latitude: 15.119003,
    longitude: 145.729356,
    altitude: 65.532
  },
  {
    iataCode: 'SPM',
    icaoCode: 'ETAD',
    name: 'Spangdahlem',
    country: {code: 'DE', name: 'Germany'},
    city: 'Spangdahlem Ab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.972667,
    longitude: 6.6925,
    altitude: 364.8456
  },
  {
    iataCode: 'SPP',
    icaoCode: 'FNME',
    name: 'Menongue',
    country: {code: 'AO', name: 'Angola'},
    city: 'Menongue',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -14.657583,
    longitude: 17.719833,
    altitude: 1362.1512
  },
  {
    iataCode: 'BOB',
    icaoCode: 'NTTB',
    name: 'Bora Bora',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Bora Bora',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.444378,
    longitude: -151.751286,
    altitude: 3.048
  },
  {
    iataCode: 'SPR',
    icaoCode: 'MZ10',
    name: 'San Pedro',
    country: {code: 'BZ', name: 'Belize'},
    city: 'San Pedro',
    region: '',
    terminal: '',
    timezoneId: 'America/Belize',
    utcOffset: -360,
    latitude: 17.913936,
    longitude: -87.971075,
    altitude: 1.2192
  },
  {
    iataCode: 'BOA',
    icaoCode: 'FZAJ',
    name: 'Boma',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Boma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -5.854,
    longitude: 13.064,
    altitude: 7.9248
  },
  {
    iataCode: 'BOD',
    icaoCode: 'LFBD',
    name: 'Bordeaux',
    country: {code: 'FR', name: 'France'},
    city: 'Merignac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.828335,
    longitude: -0.715556,
    altitude: 49.3776
  },
  {
    iataCode: 'BOC',
    icaoCode: 'MPBO',
    name: 'Bocas Del Toro',
    country: {code: 'PA', name: 'Panama'},
    city: 'Bocas Del Toro Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 9.340853,
    longitude: -82.250842,
    altitude: 3.048
  },
  {
    iataCode: 'SPS',
    icaoCode: 'KSPS',
    name: 'Wichita Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Sheppard Afb Wichita Falls Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.988797,
    longitude: -98.491894,
    altitude: 310.5912
  },
  {
    iataCode: 'SPU',
    icaoCode: 'LDSP',
    name: 'Split',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Split',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 43.538944,
    longitude: 16.297964,
    altitude: 24.0792
  },
  {
    iataCode: 'BOH',
    icaoCode: 'EGHH',
    name: 'Bournemouth',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Bournemouth',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.78,
    longitude: -1.8425,
    altitude: 11.5824
  },
  {
    iataCode: 'BOG',
    icaoCode: 'SKBO',
    name: 'Bogota',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Eldorado Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.701594,
    longitude: -74.146947,
    altitude: 2548.4328
  },
  {
    iataCode: 'SPW',
    icaoCode: 'KSPW',
    name: 'Spencer',
    country: {code: 'US', name: 'United States'},
    city: 'Spencer Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.165527,
    longitude: -95.202805,
    altitude: 408.1272
  },
  {
    iataCode: 'BOJ',
    icaoCode: 'LBBG',
    name: 'Bourgas',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Burgas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 42.569583,
    longitude: 27.515236,
    altitude: 41.148
  },
  {
    iataCode: 'BOI',
    icaoCode: 'KBOI',
    name: 'Boise',
    country: {code: 'US', name: 'United States'},
    city: 'Boise Air Terminal',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 43.564361,
    longitude: -116.222861,
    altitude: 875.0808
  },
  {
    iataCode: 'SPY',
    icaoCode: 'DISP',
    name: 'San Pedro',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'San Pedro',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 4.746717,
    longitude: -6.660817,
    altitude: 7.9248
  },
  {
    iataCode: 'BON',
    icaoCode: 'TNCB',
    name: 'Kralendijk',
    country: {code: '', name: 'Netherlands Antilles'},
    city: 'Flamingo',
    region: '',
    terminal: '',
    timezoneId: 'America/Kralendijk',
    utcOffset: -240,
    latitude: 12.131044,
    longitude: -68.268511,
    altitude: 6.096
  },
  {
    iataCode: 'BOM',
    icaoCode: 'VABB',
    name: 'Bombay',
    country: {code: 'IN', name: 'India'},
    city: 'Chhatrapati Shivaji Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 19.088686,
    longitude: 72.867919,
    altitude: 11.2776
  },
  {
    iataCode: 'BOO',
    icaoCode: 'ENBO',
    name: 'Bodo',
    country: {code: 'NO', name: 'Norway'},
    city: 'Bodo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 67.269167,
    longitude: 14.365278,
    altitude: 12.8016
  },
  {
    iataCode: 'BOS',
    icaoCode: 'KBOS',
    name: 'Boston',
    country: {code: 'US', name: 'United States'},
    city: 'General Edward Lawrence Logan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.364347,
    longitude: -71.005181,
    altitude: 5.7912
  },
  {
    iataCode: 'SQG',
    icaoCode: 'WIOS',
    name: 'Sintang-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Susilo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: 0.063619,
    longitude: 111.473428,
    altitude: 29.8704
  },
  {
    iataCode: 'BOU',
    icaoCode: 'LFLD',
    name: 'Bourges',
    country: {code: 'FR', name: 'France'},
    city: 'Bourges',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.058056,
    longitude: 2.370278,
    altitude: 161.2392
  },
  {
    iataCode: 'BOW',
    icaoCode: 'KBOW',
    name: 'Bartow',
    country: {code: 'US', name: 'United States'},
    city: 'Bartow Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -360,
    latitude: 27.9434,
    longitude: -81.7834,
    altitude: 38.1
  },
  {
    iataCode: 'SQH',
    icaoCode: 'VVNS',
    name: 'Son-la',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Na-san Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 21.217,
    longitude: 104.033,
    altitude: 650.1384
  },
  {
    iataCode: 'KAC',
    icaoCode: 'OSKL',
    name: 'Kamishly',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Kamishly Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 37.020625,
    longitude: 41.191394,
    altitude: 451.104
  },
  {
    iataCode: 'BOY',
    icaoCode: 'DFOO',
    name: 'Bobo-dioulasso',
    country: {code: 'BF', name: 'Burkina Faso'},
    city: 'Bobo Dioulasso',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ouagadougou',
    utcOffset: 0,
    latitude: 11.160056,
    longitude: -4.330969,
    altitude: 460.5528
  },
  {
    iataCode: 'KAB',
    icaoCode: 'FVKB',
    name: 'Kariba',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Kariba Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -16.519761,
    longitude: 28.884981,
    altitude: 519.9888
  },
  {
    iataCode: 'KAD',
    icaoCode: 'DNKA',
    name: 'Kaduna',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Kaduna',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 10.696025,
    longitude: 7.320114,
    altitude: 631.8504
  },
  {
    iataCode: 'SQL',
    icaoCode: 'KSQL',
    name: 'San Carlos',
    country: {code: 'US', name: 'United States'},
    city: 'San Carlos Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.511944,
    longitude: -122.249444,
    altitude: 1.524
  },
  {
    iataCode: 'KAG',
    icaoCode: 'RKNN',
    name: 'Kangnung',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Gangneung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.753561,
    longitude: 128.943625,
    altitude: 10.668
  },
  {
    iataCode: 'SQO',
    icaoCode: 'ESUD',
    name: 'Mohed',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Storuman Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 64.960894,
    longitude: 17.696583,
    altitude: 278.892
  },
  {
    iataCode: 'KAI',
    icaoCode: 'SYKA',
    name: 'Kaieteur',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Kaieteur',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 5.167,
    longitude: -59.483,
    altitude: 228.6
  },
  {
    iataCode: 'SQQ',
    icaoCode: 'EYSA',
    name: 'Siauliai',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Siauliai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 55.893886,
    longitude: 23.394975,
    altitude: 135.0264
  },
  {
    iataCode: 'BPC',
    icaoCode: 'FKKV',
    name: 'Bamenda',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Bamenda',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 6.039239,
    longitude: 10.122639,
    altitude: 1239.012
  },
  {
    iataCode: 'KAJ',
    icaoCode: 'EFKI',
    name: 'Kajaani',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kajaani',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 64.285472,
    longitude: 27.692414,
    altitude: 147.2184
  },
  {
    iataCode: 'KAL',
    icaoCode: 'PAKV',
    name: 'Kaltag',
    country: {code: 'US', name: 'United States'},
    city: 'Kaltag Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.3191,
    longitude: -158.741,
    altitude: 56.9976
  },
  {
    iataCode: 'KAO',
    icaoCode: 'EFKS',
    name: 'Kuusamo',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kuusamo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 65.987575,
    longitude: 29.239381,
    altitude: 263.9568
  },
  {
    iataCode: 'BPF',
    icaoCode: 'AGBT',
    name: 'Batuna',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Batuna Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.63,
    longitude: 158.0,
    altitude: 1.524
  },
  {
    iataCode: 'KAN',
    icaoCode: 'DNKN',
    name: 'Kano',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Mallam Aminu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 12.047589,
    longitude: 8.524622,
    altitude: 476.0976
  },
  {
    iataCode: 'KAR',
    icaoCode: 'SYKM',
    name: 'Kamarang',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Kamarang Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 5.865278,
    longitude: -60.614167,
    altitude: 3.048
  },
  {
    iataCode: 'BPM',
    icaoCode: 'OAIX',
    name: 'Kabul',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Bagram Afb',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 240,
    latitude: 34.5646,
    longitude: 69.1554,
    altitude: 1491.996
  },
  {
    iataCode: 'KAU',
    icaoCode: 'EFKA',
    name: 'Kauhava',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kauhava',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 63.127078,
    longitude: 23.051442,
    altitude: 46.0248
  },
  {
    iataCode: 'KAT',
    icaoCode: 'NZKT',
    name: 'Kaitaia',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Kaitaia',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -35.07,
    longitude: 173.285278,
    altitude: 82.296
  },
  {
    iataCode: 'KAW',
    icaoCode: 'VYKT',
    name: 'Kawthoung',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Kawthoung Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 420,
    latitude: 10.049258,
    longitude: 98.538006,
    altitude: 54.864
  },
  {
    iataCode: 'BPN',
    icaoCode: 'WALL',
    name: 'Balikpapan',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sepinggan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -1.268272,
    longitude: 116.894478,
    altitude: 3.6576
  },
  {
    iataCode: 'KAX',
    icaoCode: 'YKBR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kalbarri Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -27.69,
    longitude: 114.262,
    altitude: 47.8536
  },
  {
    iataCode: 'SRA',
    icaoCode: 'SSZR',
    name: 'Santa Rosa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santa Rosa Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -27.9067,
    longitude: -54.5204,
    altitude: 299.9232
  },
  {
    iataCode: 'BPS',
    icaoCode: 'SBPS',
    name: 'Porto Seguro',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Aeroporto De Porto Seguro',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -16.438611,
    longitude: -39.080833,
    altitude: 50.9016
  },
  {
    iataCode: 'BPR',
    icaoCode: 'RPVW',
    name: 'Borongan',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Borongan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.674167,
    longitude: 125.478611,
    altitude: 2.1336
  },
  {
    iataCode: 'BPT',
    icaoCode: 'KBPT',
    name: 'Beaumont',
    country: {code: 'US', name: 'United States'},
    city: 'Southeast Texas Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.950833,
    longitude: -94.020694,
    altitude: 4.572
  },
  {
    iataCode: 'SRE',
    icaoCode: 'SLSU',
    name: 'Sucre',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Juana Azurduy De Padilla',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -19.007083,
    longitude: -65.288747,
    altitude: 2903.8296
  },
  {
    iataCode: 'SRH',
    icaoCode: 'FTTA',
    name: 'Sarh',
    country: {code: 'TD', name: 'Chad'},
    city: 'Sarh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 9.14444,
    longitude: 18.3744,
    altitude: 365.1504
  },
  {
    iataCode: 'SRG',
    icaoCode: 'WARS',
    name: 'Semarang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Achmad Yani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.971447,
    longitude: 110.374122,
    altitude: 3.9624
  },
  {
    iataCode: 'BPY',
    icaoCode: 'FMNQ',
    name: 'Besalampy',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Besalampy',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -16.741945,
    longitude: 44.481388,
    altitude: 38.1
  },
  {
    iataCode: 'SRJ',
    icaoCode: 'SLSB',
    name: 'San Borja',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Capitán Av. German Quiroga G. Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -14.8592,
    longitude: -66.7375,
    altitude: 192.9384
  },
  {
    iataCode: 'BPX',
    icaoCode: 'ZUBD',
    name: 'Bangda',
    country: {code: 'CN', name: 'China'},
    city: 'Qamdo Bangda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 30.5536,
    longitude: 97.1083,
    altitude: 4333.9512
  },
  {
    iataCode: 'SRI',
    icaoCode: 'WALS',
    name: 'Samarinda',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Temindung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -0.484531,
    longitude: 117.157111,
    altitude: 10.0584
  },
  {
    iataCode: 'SRN',
    icaoCode: 'YSRN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Strahan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -42.155,
    longitude: 145.292,
    altitude: 6.096
  },
  {
    iataCode: 'SRP',
    icaoCode: 'ENSO',
    name: 'Stord',
    country: {code: 'NO', name: 'Norway'},
    city: 'Sorstokken',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.791925,
    longitude: 5.34085,
    altitude: 48.768
  },
  {
    iataCode: 'SRR',
    icaoCode: 'KSRR',
    name: 'Ruidoso',
    country: {code: 'US', name: 'United States'},
    city: 'Sierra Blanca Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 33.46285,
    longitude: -105.534751,
    altitude: 2076.9072
  },
  {
    iataCode: 'SRQ',
    icaoCode: 'KSRQ',
    name: 'Sarasota',
    country: {code: 'US', name: 'United States'},
    city: 'Sarasota Bradenton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.395444,
    longitude: -82.554389,
    altitude: 9.144
  },
  {
    iataCode: 'KBL',
    icaoCode: 'OAKB',
    name: 'Kabul',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Kabul Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 34.565853,
    longitude: 69.212328,
    altitude: 1791.3096
  },
  {
    iataCode: 'SRT',
    icaoCode: 'HUSO',
    name: 'Soroti',
    country: {code: 'UG', name: 'Uganda'},
    city: 'Soroti',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kampala',
    utcOffset: 180,
    latitude: 1.727578,
    longitude: 33.622861,
    altitude: 1109.7768
  },
  {
    iataCode: 'BQH',
    icaoCode: 'EGKB',
    name: 'Biggin Hill',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Biggin Hill',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.330833,
    longitude: 0.0325,
    altitude: 182.2704
  },
  {
    iataCode: 'KBP',
    icaoCode: 'UKBB',
    name: 'Kiev',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Boryspil Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 50.345,
    longitude: 30.894722,
    altitude: 130.1496
  },
  {
    iataCode: 'SRX',
    icaoCode: 'HLGD',
    name: 'Sirt',
    country: {code: 'LY', name: 'Libya'},
    city: 'Gardabya Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 31.0635,
    longitude: 16.595,
    altitude: 81.3816
  },
  {
    iataCode: 'KBR',
    icaoCode: 'WMKC',
    name: 'Kota Bahru',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Ismail Petra',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 6.16685,
    longitude: 102.293014,
    altitude: 4.8768
  },
  {
    iataCode: 'SRY',
    icaoCode: 'OINZ',
    name: 'Dasht-e-naz',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Sari Dasht E Naz Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 36.635833,
    longitude: 53.193611,
    altitude: 10.668
  },
  {
    iataCode: 'BQL',
    icaoCode: 'YBOU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Boulia Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -22.9133,
    longitude: 139.9,
    altitude: 165.2016
  },
  {
    iataCode: 'BQK',
    icaoCode: 'KBQK',
    name: 'Brunswick',
    country: {code: 'US', name: 'United States'},
    city: 'Brunswick Golden Isles Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 31.2588,
    longitude: -81.4665,
    altitude: 7.9248
  },
  {
    iataCode: 'KBS',
    icaoCode: 'GFBO',
    name: 'Bo',
    country: {code: 'SL', name: 'Sierra Leone'},
    city: 'Bo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Freetown',
    utcOffset: 0,
    latitude: 7.9444,
    longitude: -11.761,
    altitude: 99.9744
  },
  {
    iataCode: 'BQN',
    icaoCode: 'TJBQ',
    name: 'Aguadilla',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Rafael Hernandez',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.494861,
    longitude: -67.129444,
    altitude: 72.2376
  },
  {
    iataCode: 'KBV',
    icaoCode: 'VTSG',
    name: 'Krabi',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Krabi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 8.095969,
    longitude: 98.988764,
    altitude: 28.3464
  },
  {
    iataCode: 'KBU',
    icaoCode: 'WRBK',
    name: 'Laut Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Stagen Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 480,
    latitude: -3.29472,
    longitude: 116.165,
    altitude: 1.2192
  },
  {
    iataCode: 'SSA',
    icaoCode: 'SBSV',
    name: 'Salvador',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Deputado Luis Eduardo Magalhaes',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -12.910994,
    longitude: -38.331044,
    altitude: 19.5072
  },
  {
    iataCode: 'SSC',
    icaoCode: 'KSSC',
    name: 'Sumter',
    country: {code: 'US', name: 'United States'},
    city: 'Shaw Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.972719,
    longitude: -80.470564,
    altitude: 73.7616
  },
  {
    iataCode: 'KBY',
    icaoCode: 'UWWW',
    name: 'Samara',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kurumoch',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Samara',
    utcOffset: 300,
    latitude: 53.504858,
    longitude: 50.164336,
    altitude: 145.3896
  },
  {
    iataCode: 'BQT',
    icaoCode: 'UMBB',
    name: 'Brest',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Brest',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 52.06,
    longitude: 23.53,
    altitude: 142.6464
  },
  {
    iataCode: 'SSE',
    icaoCode: 'VASL',
    name: 'Sholapur',
    country: {code: 'IN', name: 'India'},
    city: 'Sholapur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 17.627958,
    longitude: 75.934842,
    altitude: 482.8032
  },
  {
    iataCode: 'BQS',
    icaoCode: 'UHBB',
    name: 'Blagoveschensk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ignatyevo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 50.425394,
    longitude: 127.412478,
    altitude: 194.4624
  },
  {
    iataCode: 'SSG',
    icaoCode: 'FGSL',
    name: 'Malabo',
    country: {code: 'GQ', name: 'Equatorial Guinea'},
    city: 'Malabo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Malabo',
    utcOffset: 60,
    latitude: 3.755267,
    longitude: 8.708717,
    altitude: 23.1648
  },
  {
    iataCode: 'BQU',
    icaoCode: 'TVSB',
    name: 'Bequia',
    country: {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    city: 'J F Mitchell Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Vincent',
    utcOffset: -240,
    latitude: 12.988444,
    longitude: -61.262033,
    altitude: 4.572
  },
  {
    iataCode: 'KCA',
    icaoCode: 'ZWKC',
    name: 'Kuqa',
    country: {code: 'CN', name: 'China'},
    city: 'Kuqa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 41.7181,
    longitude: 82.9869,
    altitude: 1074.1152
  },
  {
    iataCode: 'SSJ',
    icaoCode: 'ENST',
    name: 'Sandnessjoen',
    country: {code: 'NO', name: 'Norway'},
    city: 'Stokka',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 65.956828,
    longitude: 12.468944,
    altitude: 17.0688
  },
  {
    iataCode: 'SSN',
    icaoCode: 'RKSM',
    name: 'Seoul East',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Seoul Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.445833,
    longitude: 127.113889,
    altitude: 28.0416
  },
  {
    iataCode: 'BRA',
    icaoCode: 'SNBR',
    name: 'Barreiras',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Barreiras Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -12.083333,
    longitude: -45.0,
    altitude: 413.3088
  },
  {
    iataCode: 'KCH',
    icaoCode: 'WBGG',
    name: 'Kuching',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kuching Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 1.484697,
    longitude: 110.346933,
    altitude: 27.1272
  },
  {
    iataCode: 'BRC',
    icaoCode: 'SAZS',
    name: 'San Carlos De Bariloch',
    country: {code: 'AR', name: 'Argentina'},
    city: 'San Carlos De Bariloche',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -41.151172,
    longitude: -71.157542,
    altitude: 846.1248
  },
  {
    iataCode: 'SSR',
    icaoCode: 'NVSH',
    name: 'Pentecost Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Sara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.4708,
    longitude: 168.152,
    altitude: 0.0
  },
  {
    iataCode: 'BRE',
    icaoCode: 'EDDW',
    name: 'Bremen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Neuenland',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.0475,
    longitude: 8.786667,
    altitude: 4.2672
  },
  {
    iataCode: 'KCM',
    icaoCode: 'LTCN',
    name: '',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Kahramanmaras Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.539,
    longitude: 36.9534,
    altitude: 525.1704
  },
  {
    iataCode: 'BRD',
    icaoCode: 'KBRD',
    name: 'Brainerd',
    country: {code: 'US', name: 'United States'},
    city: 'Brainerd Lakes Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 46.398308,
    longitude: -94.138078,
    altitude: 373.6848
  },
  {
    iataCode: 'SST',
    icaoCode: 'SAZL',
    name: 'Santa Teresita',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Santa Teresita Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -36.542317,
    longitude: -56.721756,
    altitude: 2.7432
  },
  {
    iataCode: 'BRI',
    icaoCode: 'LIBD',
    name: 'Bari',
    country: {code: 'IT', name: 'Italy'},
    city: 'Bari',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 41.138856,
    longitude: 16.760594,
    altitude: 53.9496
  },
  {
    iataCode: 'SSY',
    icaoCode: 'FNBC',
    name: "M'banza-congo",
    country: {code: 'AO', name: 'Angola'},
    city: 'Mbanza Congo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -6.269897,
    longitude: 14.247025,
    altitude: 566.928
  },
  {
    iataCode: 'BRK',
    icaoCode: 'YBKE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bourke Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -30.0392,
    longitude: 145.952,
    altitude: 107.2896
  },
  {
    iataCode: 'SSZ',
    icaoCode: 'SBST',
    name: 'Santos',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Base Aerea De Santos',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.925206,
    longitude: -46.2875,
    altitude: 3.048
  },
  {
    iataCode: 'BRM',
    icaoCode: 'SVBM',
    name: 'Barquisimeto',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Barquisimeto Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.042747,
    longitude: -69.358619,
    altitude: 622.4016
  },
  {
    iataCode: 'BRL',
    icaoCode: 'KBRL',
    name: 'Burlington',
    country: {code: 'US', name: 'United States'},
    city: 'Southeast Iowa Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.7832,
    longitude: -91.1255,
    altitude: 212.7504
  },
  {
    iataCode: 'KCT',
    icaoCode: 'VCCK',
    name: '',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Koggala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 5.99368,
    longitude: 80.3203,
    altitude: 3.048
  },
  {
    iataCode: 'BRO',
    icaoCode: 'KBRO',
    name: 'Brownsville',
    country: {code: 'US', name: 'United States'},
    city: 'Brownsville South Padre Island Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 25.906833,
    longitude: -97.425861,
    altitude: 6.7056
  },
  {
    iataCode: 'BRN',
    icaoCode: 'LSZB',
    name: 'Bern',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Bern Belp',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.9141,
    longitude: 7.497153,
    altitude: 510.2352
  },
  {
    iataCode: 'BRQ',
    icaoCode: 'LKTB',
    name: 'Turany',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Turany',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 49.151269,
    longitude: 16.694433,
    altitude: 237.1344
  },
  {
    iataCode: 'STB',
    icaoCode: 'SVSZ',
    name: 'Santa Barbara',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Santa Barbara Del Zulia',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.974425,
    longitude: -71.943014,
    altitude: 9.7536
  },
  {
    iataCode: 'STA',
    icaoCode: 'EKVJ',
    name: 'Stauning',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Stauning',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.990122,
    longitude: 8.353906,
    altitude: 5.1816
  },
  {
    iataCode: 'BRS',
    icaoCode: 'EGGD',
    name: 'Bristol',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Bristol',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.382669,
    longitude: -2.719089,
    altitude: 189.5856
  },
  {
    iataCode: 'STD',
    icaoCode: 'SVSO',
    name: 'Santo Domingo',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Mayor Buenaventura Vivas',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 7.565111,
    longitude: -72.035125,
    altitude: 330.0984
  },
  {
    iataCode: 'BRR',
    icaoCode: 'EGPR',
    name: 'Barra',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Barra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 57.0228,
    longitude: -7.44306,
    altitude: 1.524
  },
  {
    iataCode: 'KCZ',
    icaoCode: 'RJOK',
    name: 'Kochi',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kochi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.546111,
    longitude: 133.669444,
    altitude: 12.8016
  },
  {
    iataCode: 'STC',
    icaoCode: 'KSTC',
    name: 'Saint Cloud',
    country: {code: 'US', name: 'United States'},
    city: 'Saint Cloud Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.546556,
    longitude: -94.059889,
    altitude: 314.2488
  },
  {
    iataCode: 'BRU',
    icaoCode: 'EBBR',
    name: 'Brussels',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Brussels Natl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 50.901389,
    longitude: 4.484444,
    altitude: 56.0832
  },
  {
    iataCode: 'BRT',
    icaoCode: 'YBTI',
    name: 'Bathurst Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bathurst Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -11.769167,
    longitude: 130.619722,
    altitude: 20.4216
  },
  {
    iataCode: 'BRW',
    icaoCode: 'PABR',
    name: 'Barrow',
    country: {code: 'US', name: 'United States'},
    city: 'Wiley Post Will Rogers Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 71.285446,
    longitude: -156.766003,
    altitude: 13.4112
  },
  {
    iataCode: 'BRV',
    icaoCode: 'EDWB',
    name: 'Bremerhaven',
    country: {code: 'DE', name: 'Germany'},
    city: 'Bremerhaven',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.507081,
    longitude: 8.572878,
    altitude: 3.3528
  },
  {
    iataCode: 'STJ',
    icaoCode: 'KSTJ',
    name: 'Rosecrans',
    country: {code: 'US', name: 'United States'},
    city: 'Rosecrans Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.771944,
    longitude: -94.909706,
    altitude: 251.7648
  },
  {
    iataCode: 'BRX',
    icaoCode: 'MDBH',
    name: 'Barahona',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Maria Montez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.251464,
    longitude: -71.1204,
    altitude: 3.048
  },
  {
    iataCode: 'STI',
    icaoCode: 'MDST',
    name: 'Santiago',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Cibao Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 19.406092,
    longitude: -70.604689,
    altitude: 172.212
  },
  {
    iataCode: 'KDD',
    icaoCode: 'OPKH',
    name: 'Khuzdar',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Khuzdar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 27.7906,
    longitude: 66.6473,
    altitude: 1222.8576
  },
  {
    iataCode: 'STL',
    icaoCode: 'KSTL',
    name: 'St. Louis',
    country: {code: 'US', name: 'United States'},
    city: 'Lambert St Louis Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.748697,
    longitude: -90.370028,
    altitude: 188.3664
  },
  {
    iataCode: 'STN',
    icaoCode: 'EGSS',
    name: 'London',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Stansted',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.885,
    longitude: 0.235,
    altitude: 106.0704
  },
  {
    iataCode: 'STM',
    icaoCode: 'SBSN',
    name: 'Santarem',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santarem',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -2.422431,
    longitude: -54.792789,
    altitude: 60.3504
  },
  {
    iataCode: 'KDH',
    icaoCode: 'OAKN',
    name: 'Kandahar',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Kandahar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 31.505756,
    longitude: 65.847822,
    altitude: 1017.1176
  },
  {
    iataCode: 'BSB',
    icaoCode: 'SBBR',
    name: 'Brasilia',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Presidente Juscelino Kubitschek',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -15.8711,
    longitude: -47.918625,
    altitude: 1060.3992
  },
  {
    iataCode: 'STR',
    icaoCode: 'EDDS',
    name: 'Stuttgart',
    country: {code: 'DE', name: 'Germany'},
    city: 'Stuttgart',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.689878,
    longitude: 9.221964,
    altitude: 388.9248
  },
  {
    iataCode: 'BSA',
    icaoCode: 'HCMF',
    name: 'Bosaso',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Bosaso Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 11.2753,
    longitude: 49.1494,
    altitude: 0.9144
  },
  {
    iataCode: 'KDI',
    icaoCode: 'WAWW',
    name: 'Kendari',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Wolter Monginsidi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -4.081608,
    longitude: 122.418231,
    altitude: 163.9824
  },
  {
    iataCode: 'BSD',
    icaoCode: 'ZPBS',
    name: 'Baoshan',
    country: {code: 'CN', name: 'China'},
    city: 'Baoshan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 25.053333,
    longitude: 99.168333,
    altitude: 152.4
  },
  {
    iataCode: 'KDL',
    icaoCode: 'EEEI',
    name: 'Armari Air Force Base',
    country: {code: 'EE', name: 'Estonia'},
    city: 'Amari',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tallinn',
    utcOffset: 120,
    latitude: 59.260286,
    longitude: 24.208467,
    altitude: 19.812
  },
  {
    iataCode: 'STT',
    icaoCode: 'TIST',
    name: 'St. Thomas',
    country: {code: 'VI', name: 'U.S. Virgin Islands'},
    city: 'Cyril E King',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Thomas',
    utcOffset: -240,
    latitude: 18.337306,
    longitude: -64.973361,
    altitude: 7.0104
  },
  {
    iataCode: 'BSC',
    icaoCode: 'SKBS',
    name: 'Bahia Solano',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Jose Celestino Mutis',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 6.202917,
    longitude: -77.394675,
    altitude: 24.384
  },
  {
    iataCode: 'STS',
    icaoCode: 'KSTS',
    name: 'Santa Rosa',
    country: {code: 'US', name: 'United States'},
    city: 'Charles M Schulz Sonoma Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.508978,
    longitude: -122.81288,
    altitude: 38.1
  },
  {
    iataCode: 'BSF',
    icaoCode: 'PHSF',
    name: 'Bradshaw Field',
    country: {code: 'US', name: 'United States'},
    city: 'Bradshaw Aaf',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 19.760056,
    longitude: -155.553717,
    altitude: 1886.712
  },
  {
    iataCode: 'STV',
    icaoCode: 'VASU',
    name: 'Surat',
    country: {code: 'IN', name: 'India'},
    city: 'Surat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.114061,
    longitude: 72.741792,
    altitude: 4.8768
  },
  {
    iataCode: 'STU',
    icaoCode: 'SBSC',
    name: 'Rio De Janeiro',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santa Cruz',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.93235,
    longitude: -43.719092,
    altitude: 3.048
  },
  {
    iataCode: 'STX',
    icaoCode: 'TISX',
    name: 'St. Criox Island',
    country: {code: 'VI', name: 'U.S. Virgin Islands'},
    city: 'Henry E Rohlsen',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Thomas',
    utcOffset: -240,
    latitude: 17.701889,
    longitude: -64.798556,
    altitude: 22.5552
  },
  {
    iataCode: 'BSG',
    icaoCode: 'FGBT',
    name: 'Bata',
    country: {code: 'GQ', name: 'Equatorial Guinea'},
    city: 'Bata',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Malabo',
    utcOffset: 60,
    latitude: 1.905469,
    longitude: 9.805681,
    altitude: 3.9624
  },
  {
    iataCode: 'KDO',
    icaoCode: 'VRMK',
    name: 'Laamu Atoll',
    country: {code: 'MV', name: 'Maldives'},
    city: 'Kadhdhoo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Maldives',
    utcOffset: 300,
    latitude: 1.85917,
    longitude: 73.5219,
    altitude: 1.2192
  },
  {
    iataCode: 'STW',
    icaoCode: 'URMT',
    name: 'Stavropol',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Shpakovskoye',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 45.109165,
    longitude: 42.112778,
    altitude: 452.9328
  },
  {
    iataCode: 'STZ',
    icaoCode: 'SWST',
    name: 'Santa Terezinha',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Confresa Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -10.47,
    longitude: -50.502778,
    altitude: 198.12
  },
  {
    iataCode: 'STY',
    icaoCode: 'SUSO',
    name: 'Salto',
    country: {code: 'UY', name: 'Uruguay'},
    city: 'Nueva Hesperides Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Montevideo',
    utcOffset: -180,
    latitude: -31.438481,
    longitude: -57.985294,
    altitude: 56.9976
  },
  {
    iataCode: 'BSL',
    icaoCode: 'LFSB',
    name: 'Mulhouse',
    country: {code: 'FR', name: 'France'},
    city: 'Bale Mulhouse',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.589583,
    longitude: 7.529914,
    altitude: 269.748
  },
  {
    iataCode: 'BSK',
    icaoCode: 'DAUB',
    name: 'Biskra',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Biskra',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 34.793289,
    longitude: 5.738231,
    altitude: 88.0872
  },
  {
    iataCode: 'KDV',
    icaoCode: 'NFKD',
    name: 'Vunisea',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Vunisea Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -19.0581,
    longitude: 178.157,
    altitude: 1.8288
  },
  {
    iataCode: 'KDU',
    icaoCode: 'OPSD',
    name: 'Skardu',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Skardu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 35.335508,
    longitude: 75.536047,
    altitude: 2229.9168
  },
  {
    iataCode: 'SUA',
    icaoCode: 'KSUA',
    name: 'Stuart',
    country: {code: 'US', name: 'United States'},
    city: 'Witham Field Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 27.1817,
    longitude: -80.221294,
    altitude: 4.8768
  },
  {
    iataCode: 'BSO',
    icaoCode: 'RPUO',
    name: 'Basco',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Basco Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 20.451322,
    longitude: 121.979883,
    altitude: 88.6968
  },
  {
    iataCode: 'BSR',
    icaoCode: 'ORMM',
    name: 'Basrah',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Basrah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 30.549069,
    longitude: 47.662142,
    altitude: 3.3528
  },
  {
    iataCode: 'SUB',
    icaoCode: 'WARR',
    name: 'Surabaya',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Juanda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -7.379831,
    longitude: 112.786858,
    altitude: 2.7432
  },
  {
    iataCode: 'SUG',
    icaoCode: 'RPMS',
    name: 'Sangley Point',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Surigao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.757567,
    longitude: 125.479328,
    altitude: 6.096
  },
  {
    iataCode: 'BSU',
    icaoCode: 'FZEN',
    name: 'Basankusu',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Basankusu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: 1.22472,
    longitude: 19.7889,
    altitude: 370.9416
  },
  {
    iataCode: 'SUF',
    icaoCode: 'LICA',
    name: 'Lamezia',
    country: {code: 'IT', name: 'Italy'},
    city: 'Lamezia Terme',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 38.905394,
    longitude: 16.242269,
    altitude: 11.8872
  },
  {
    iataCode: 'BSX',
    icaoCode: 'VYPN',
    name: 'Pathein',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Pathein Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 16.815233,
    longitude: 94.779911,
    altitude: 6.096
  },
  {
    iataCode: 'SUI',
    icaoCode: 'UGSS',
    name: 'Sukhumi',
    country: {code: 'GE', name: 'Georgia'},
    city: 'Babusheri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tbilisi',
    utcOffset: 180,
    latitude: 42.87,
    longitude: 41.12,
    altitude: 0.0
  },
  {
    iataCode: 'SUJ',
    icaoCode: 'LRSM',
    name: 'Satu Mare',
    country: {code: 'RO', name: 'Romania'},
    city: 'Satu Mare',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 47.703275,
    longitude: 22.8857,
    altitude: 123.444
  },
  {
    iataCode: 'KED',
    icaoCode: 'GQNK',
    name: 'Kaedi',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Kaedi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 16.159547,
    longitude: -13.507617,
    altitude: 22.86
  },
  {
    iataCode: 'SUL',
    icaoCode: 'OPSU',
    name: 'Sui',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Sui',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 28.645142,
    longitude: 69.176917,
    altitude: 232.5624
  },
  {
    iataCode: 'KEF',
    icaoCode: 'BIKF',
    name: 'Keflavik',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Keflavik Nas',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 63.985,
    longitude: -22.605556,
    altitude: 52.1208
  },
  {
    iataCode: 'SUN',
    icaoCode: 'KSUN',
    name: 'Hailey',
    country: {code: 'US', name: 'United States'},
    city: 'Friedman Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 43.504444,
    longitude: -114.296194,
    altitude: 1621.536
  },
  {
    iataCode: 'SUS',
    icaoCode: 'KSUS',
    name: 'Null',
    country: {code: 'US', name: 'United States'},
    city: 'Spirit Of St Louis',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -420,
    latitude: 38.662119,
    longitude: -90.652044,
    altitude: 141.1224
  },
  {
    iataCode: 'KEJ',
    icaoCode: 'UNEE',
    name: 'Kemorovo',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kemerovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Novokuznetsk',
    utcOffset: 420,
    latitude: 55.270094,
    longitude: 86.107208,
    altitude: 263.0424
  },
  {
    iataCode: 'SUR',
    icaoCode: 'CJV7',
    name: 'Summer Beaver',
    country: {code: 'CA', name: 'Canada'},
    city: 'Summer Beaver Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.7086,
    longitude: -88.5419,
    altitude: 253.5936
  },
  {
    iataCode: 'BTE',
    icaoCode: 'GFBN',
    name: 'Bonthe',
    country: {code: 'SL', name: 'Sierra Leone'},
    city: 'Sherbro International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Freetown',
    utcOffset: 0,
    latitude: 7.53242,
    longitude: -12.5189,
    altitude: 4.2672
  },
  {
    iataCode: 'KEM',
    icaoCode: 'EFKE',
    name: 'Kemi',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kemi Tornio',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 65.781889,
    longitude: 24.5991,
    altitude: 18.5928
  },
  {
    iataCode: 'SUU',
    icaoCode: 'KSUU',
    name: 'Fairfield',
    country: {code: 'US', name: 'United States'},
    city: 'Travis Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.262692,
    longitude: -121.927464,
    altitude: 18.8976
  },
  {
    iataCode: 'KEL',
    icaoCode: 'EDHK',
    name: 'Kiel',
    country: {code: 'DE', name: 'Germany'},
    city: 'Kiel Holtenau',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 54.3795,
    longitude: 10.145167,
    altitude: 30.7848
  },
  {
    iataCode: 'KEN',
    icaoCode: 'GFKE',
    name: 'Kenema',
    country: {code: 'SL', name: 'Sierra Leone'},
    city: 'Kenema Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Freetown',
    utcOffset: 0,
    latitude: 7.89129,
    longitude: -11.1766,
    altitude: 147.828
  },
  {
    iataCode: 'SUV',
    icaoCode: 'NFNA',
    name: 'Nausori',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Nausori Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -18.043267,
    longitude: 178.559228,
    altitude: 5.1816
  },
  {
    iataCode: 'BTI',
    icaoCode: 'PABA',
    name: 'Barter Island',
    country: {code: 'US', name: 'United States'},
    city: 'Barter Island Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.133989,
    longitude: -143.581867,
    altitude: 0.6096
  },
  {
    iataCode: 'BTH',
    icaoCode: 'WIDD',
    name: 'Batam',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Hang Nadim',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 1.121028,
    longitude: 104.118753,
    altitude: 38.4048
  },
  {
    iataCode: 'KEP',
    icaoCode: 'VNNG',
    name: 'Nepalgunj',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Nepalgunj Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.103633,
    longitude: 81.667006,
    altitude: 164.592
  },
  {
    iataCode: 'SUX',
    icaoCode: 'KSUX',
    name: 'Sioux City',
    country: {code: 'US', name: 'United States'},
    city: 'Sioux Gateway Col Bud Day Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.402603,
    longitude: -96.384367,
    altitude: 334.6704
  },
  {
    iataCode: 'BTK',
    icaoCode: 'UIBB',
    name: 'Bratsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Bratsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Irkutsk',
    utcOffset: 480,
    latitude: 56.370556,
    longitude: 101.698331,
    altitude: 490.728
  },
  {
    iataCode: 'BTJ',
    icaoCode: 'WITT',
    name: 'Banda Aceh',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sultan Iskandarmuda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 5.523522,
    longitude: 95.420372,
    altitude: 19.812
  },
  {
    iataCode: 'KER',
    icaoCode: 'OIKK',
    name: 'Kerman',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Kerman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 30.274444,
    longitude: 56.951111,
    altitude: 1749.8568
  },
  {
    iataCode: 'BTM',
    icaoCode: 'KBTM',
    name: 'Butte',
    country: {code: 'US', name: 'United States'},
    city: 'Bert Mooney Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 45.954806,
    longitude: -112.497472,
    altitude: 1691.64
  },
  {
    iataCode: 'KET',
    icaoCode: 'VYKG',
    name: 'Kengtung',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Kengtung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 21.301611,
    longitude: 99.635997,
    altitude: 852.8304
  },
  {
    iataCode: 'KEV',
    icaoCode: 'EFHA',
    name: 'Halli',
    country: {code: 'FI', name: 'Finland'},
    city: 'Halli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.85605,
    longitude: 24.7866,
    altitude: 145.9992
  },
  {
    iataCode: 'SVB',
    icaoCode: 'FMNS',
    name: 'Sambava',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Sambava',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -14.278611,
    longitude: 50.174721,
    altitude: 6.096
  },
  {
    iataCode: 'SVA',
    icaoCode: 'PASA',
    name: 'Savoonga',
    country: {code: 'US', name: 'United States'},
    city: 'Savoonga Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 63.6864,
    longitude: -170.493,
    altitude: 16.1544
  },
  {
    iataCode: 'BTS',
    icaoCode: 'LZIB',
    name: 'Bratislava',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'M R Stefanik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 48.170167,
    longitude: 17.212667,
    altitude: 132.8928
  },
  {
    iataCode: 'SVD',
    icaoCode: 'TVSV',
    name: 'Kingstown',
    country: {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    city: 'E T Joshua',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Vincent',
    utcOffset: -240,
    latitude: 13.144306,
    longitude: -61.210861,
    altitude: 20.1168
  },
  {
    iataCode: 'BTR',
    icaoCode: 'KBTR',
    name: 'Baton Rouge',
    country: {code: 'US', name: 'United States'},
    city: 'Baton Rouge Metro Ryan Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.533167,
    longitude: -91.149639,
    altitude: 21.336
  },
  {
    iataCode: 'SVC',
    icaoCode: 'SVC',
    name: 'Silver City',
    country: {code: 'US', name: 'United States'},
    city: 'Grant County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.6365,
    longitude: -108.156,
    altitude: 1659.9408
  },
  {
    iataCode: 'BTU',
    icaoCode: 'WBGB',
    name: 'Bintulu',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Bintulu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.12385,
    longitude: 113.020472,
    altitude: 22.5552
  },
  {
    iataCode: 'BTT',
    icaoCode: 'PABT',
    name: 'Bettles',
    country: {code: 'US', name: 'United States'},
    city: 'Bettles',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.913944,
    longitude: -151.529056,
    altitude: 196.2912
  },
  {
    iataCode: 'BTV',
    icaoCode: 'KBTV',
    name: 'Burlington',
    country: {code: 'US', name: 'United States'},
    city: 'Burlington Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.471861,
    longitude: -73.153278,
    altitude: 102.108
  },
  {
    iataCode: 'SVG',
    icaoCode: 'ENZV',
    name: 'Stavanger',
    country: {code: 'NO', name: 'Norway'},
    city: 'Sola',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 58.876778,
    longitude: 5.637856,
    altitude: 8.8392
  },
  {
    iataCode: 'SVJ',
    icaoCode: 'ENSH',
    name: 'Svolvær',
    country: {code: 'NO', name: 'Norway'},
    city: 'Svolvær Helle Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 68.2433,
    longitude: 14.6692,
    altitude: 8.2296
  },
  {
    iataCode: 'KFA',
    icaoCode: 'GQNF',
    name: 'Kiffa',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Kiffa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 16.589983,
    longitude: -11.406208,
    altitude: 128.9304
  },
  {
    iataCode: 'SVI',
    icaoCode: 'SKSV',
    name: 'San Vincente De Caguan',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Eduardo Falla Solano',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.152175,
    longitude: -74.76635,
    altitude: 280.416
  },
  {
    iataCode: 'SVL',
    icaoCode: 'EFSA',
    name: 'Savonlinna',
    country: {code: 'FI', name: 'Finland'},
    city: 'Savonlinna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.943064,
    longitude: 28.945136,
    altitude: 94.7928
  },
  {
    iataCode: 'SVN',
    icaoCode: 'KSVN',
    name: 'Hunter Aaf',
    country: {code: 'US', name: 'United States'},
    city: 'Hunter Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.01,
    longitude: -81.145683,
    altitude: 12.8016
  },
  {
    iataCode: 'SVP',
    icaoCode: 'FNKU',
    name: 'Kuito',
    country: {code: 'AO', name: 'Angola'},
    city: 'Kuito',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -12.404633,
    longitude: 16.947414,
    altitude: 1712.3664
  },
  {
    iataCode: 'KFG',
    icaoCode: 'YKKG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kalkgurung Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -17.4319,
    longitude: 130.808,
    altitude: 196.9008
  },
  {
    iataCode: 'SVO',
    icaoCode: 'UUEE',
    name: 'Moscow',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Sheremetyevo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.972642,
    longitude: 37.414589,
    altitude: 189.5856
  },
  {
    iataCode: 'BUA',
    icaoCode: 'AYBK',
    name: 'Buka Island',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Buka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.42232,
    longitude: 154.673,
    altitude: 3.3528
  },
  {
    iataCode: 'SVQ',
    icaoCode: 'LEZL',
    name: 'Sevilla',
    country: {code: 'ES', name: 'Spain'},
    city: 'Sevilla',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 37.418,
    longitude: -5.893106,
    altitude: 33.8328
  },
  {
    iataCode: 'BUD',
    icaoCode: 'LHBP',
    name: 'Budapest',
    country: {code: 'HU', name: 'Hungary'},
    city: 'Ferihegy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Budapest',
    utcOffset: 60,
    latitude: 47.436933,
    longitude: 19.255592,
    altitude: 150.876
  },
  {
    iataCode: 'BUC',
    icaoCode: 'YBKT',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Burketown Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -17.7486,
    longitude: 139.534,
    altitude: 6.4008
  },
  {
    iataCode: 'BUF',
    icaoCode: 'KBUF',
    name: 'Buffalo',
    country: {code: 'US', name: 'United States'},
    city: 'Buffalo Niagara Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.940525,
    longitude: -78.732167,
    altitude: 220.6752
  },
  {
    iataCode: 'SVU',
    icaoCode: 'NFNS',
    name: 'Savusavu',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Savusavu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -16.8028,
    longitude: 179.341,
    altitude: 5.1816
  },
  {
    iataCode: 'KFP',
    icaoCode: 'PAKF',
    name: 'False Pass',
    country: {code: 'US', name: 'United States'},
    city: 'False Pass Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 54.8475,
    longitude: -163.410278,
    altitude: 6.096
  },
  {
    iataCode: 'SVX',
    icaoCode: 'USSS',
    name: 'Sverdlovsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Koltsovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 56.743108,
    longitude: 60.802728,
    altitude: 232.8672
  },
  {
    iataCode: 'BUG',
    icaoCode: 'FNBG',
    name: 'Benguela',
    country: {code: 'AO', name: 'Angola'},
    city: 'Benguela',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -12.609025,
    longitude: 13.403711,
    altitude: 35.9664
  },
  {
    iataCode: 'SVW',
    icaoCode: 'PASV',
    name: 'Sparrevohn',
    country: {code: 'US', name: 'United States'},
    city: 'Sparrevohn Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.097369,
    longitude: -155.574228,
    altitude: 482.4984
  },
  {
    iataCode: 'SVZ',
    icaoCode: 'SVSA',
    name: 'San Antonio',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'San Antonio Del Tachira',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 7.840831,
    longitude: -72.439742,
    altitude: 399.8976
  },
  {
    iataCode: 'BUI',
    icaoCode: 'WAJB',
    name: 'Bokondini-papua Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Bokondini Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -3.58365,
    longitude: 138.533,
    altitude: 2774.8992
  },
  {
    iataCode: 'BUN',
    icaoCode: 'SKBU',
    name: 'Buenaventura',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Gerardo Tobar Lopez',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 3.819628,
    longitude: -76.989767,
    altitude: 14.6304
  },
  {
    iataCode: 'SWA',
    icaoCode: 'ZGOW',
    name: 'Shantou',
    country: {code: 'CN', name: 'China'},
    city: 'Wai Sha Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 23.4,
    longitude: 116.683,
    altitude: 0.0
  },
  {
    iataCode: 'BUO',
    icaoCode: 'HCMV',
    name: 'Burao',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Burao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 9.517,
    longitude: 45.567,
    altitude: 1036.32
  },
  {
    iataCode: 'BUR',
    icaoCode: 'KBUR',
    name: 'Burbank',
    country: {code: 'US', name: 'United States'},
    city: 'Bob Hope',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.200667,
    longitude: -118.358667,
    altitude: 237.1344
  },
  {
    iataCode: 'BUQ',
    icaoCode: 'FVBU',
    name: 'Bulawayo',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'J M Nkomo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -20.017431,
    longitude: 28.617869,
    altitude: 1328.6232
  },
  {
    iataCode: 'BUS',
    icaoCode: 'UGSB',
    name: 'Batumi',
    country: {code: 'GE', name: 'Georgia'},
    city: 'Batumi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tbilisi',
    utcOffset: 120,
    latitude: 41.610278,
    longitude: 41.599694,
    altitude: 32.004
  },
  {
    iataCode: 'SWF',
    icaoCode: 'KSWF',
    name: 'Newburgh',
    country: {code: 'US', name: 'United States'},
    city: 'Stewart Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.504094,
    longitude: -74.104839,
    altitude: 149.6568
  },
  {
    iataCode: 'BUX',
    icaoCode: 'FZKA',
    name: 'Bunia',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Bunia',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: 1.565719,
    longitude: 30.220833,
    altitude: 1232.916
  },
  {
    iataCode: 'KGA',
    icaoCode: 'FZUA',
    name: 'Kananga',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kananga',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -5.900055,
    longitude: 22.469166,
    altitude: 651.9672
  },
  {
    iataCode: 'BUZ',
    icaoCode: 'OIBB',
    name: 'Bushehr',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Bushehr',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 28.944811,
    longitude: 50.834637,
    altitude: 20.7264
  },
  {
    iataCode: 'KGC',
    icaoCode: 'YKSC',
    name: 'Kingscote',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kingscote Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -35.713889,
    longitude: 137.521389,
    altitude: 7.3152
  },
  {
    iataCode: 'SWJ',
    icaoCode: 'NVSX',
    name: 'Malekula Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Southwest Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.495,
    longitude: 167.438,
    altitude: 0.0
  },
  {
    iataCode: 'KGE',
    icaoCode: 'AGKG',
    name: 'Kagau Island',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Kagau Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -7.333,
    longitude: 157.583,
    altitude: 0.0
  },
  {
    iataCode: 'KGD',
    icaoCode: 'UMKK',
    name: 'Kaliningrad',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Khrabrovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kaliningrad',
    utcOffset: 120,
    latitude: 54.89005,
    longitude: 20.592633,
    altitude: 12.8016
  },
  {
    iataCode: 'KGG',
    icaoCode: 'GOTK',
    name: 'Kedougou',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Kedougou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 12.572292,
    longitude: -12.220333,
    altitude: 178.0032
  },
  {
    iataCode: 'KGF',
    icaoCode: 'UAKK',
    name: 'Karaganda',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Sary-arka',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 300,
    latitude: 49.670833,
    longitude: 73.334444,
    altitude: 537.972
  },
  {
    iataCode: 'BVA',
    icaoCode: 'LFOB',
    name: 'Beauvais',
    country: {code: 'FR', name: 'France'},
    city: 'Tille',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.454444,
    longitude: 2.112778,
    altitude: 109.4232
  },
  {
    iataCode: 'KGI',
    icaoCode: 'YPKG',
    name: 'Kalgoorlie',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kalgoorlie Boulder',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -30.789444,
    longitude: 121.461667,
    altitude: 366.6744
  },
  {
    iataCode: 'SWQ',
    icaoCode: 'WADS',
    name: 'Sumbawa Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sumbawa Besar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.489039,
    longitude: 117.412119,
    altitude: 4.8768
  },
  {
    iataCode: 'SWP',
    icaoCode: 'FYSM',
    name: 'Swakopmund',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Swakopmund Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -22.6619,
    longitude: 14.5681,
    altitude: 63.0936
  },
  {
    iataCode: 'BVC',
    icaoCode: 'GVBA',
    name: 'Boa Vista',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Rabil',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 16.136531,
    longitude: -22.888897,
    altitude: 21.0312
  },
  {
    iataCode: 'KGK',
    icaoCode: 'PAJZ',
    name: 'Koliganek',
    country: {code: 'US', name: 'United States'},
    city: 'Koliganek Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.726667,
    longitude: -157.259444,
    altitude: 81.9912
  },
  {
    iataCode: 'SWS',
    icaoCode: 'EGFH',
    name: 'Swansea',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Swansea',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.605333,
    longitude: -4.067833,
    altitude: 91.1352
  },
  {
    iataCode: 'BVB',
    icaoCode: 'SBBV',
    name: 'Boa Vista',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Boa Vista',
    region: '',
    terminal: '',
    timezoneId: 'America/Boa_Vista',
    utcOffset: -240,
    latitude: 2.846311,
    longitude: -60.690069,
    altitude: 84.1248
  },
  {
    iataCode: 'KGJ',
    icaoCode: 'FWKA',
    name: 'Karonga',
    country: {code: 'MW', name: 'Malawi'},
    city: 'Karonga',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Blantyre',
    utcOffset: 120,
    latitude: -9.953569,
    longitude: 33.893022,
    altitude: 537.972
  },
  {
    iataCode: 'BVE',
    icaoCode: 'LFBV',
    name: 'Brive',
    country: {code: 'FR', name: 'France'},
    city: 'La Roche',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.150833,
    longitude: 1.469167,
    altitude: 115.5192
  },
  {
    iataCode: 'KGL',
    icaoCode: 'HRYR',
    name: 'Kigali',
    country: {code: 'RW', name: 'Rwanda'},
    city: 'Kigali Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kigali',
    utcOffset: 120,
    latitude: -1.968628,
    longitude: 30.13945,
    altitude: 1481.0232
  },
  {
    iataCode: 'SWT',
    icaoCode: 'UNSS',
    name: 'Strezhevoy',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Strezhevoy',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Novosibirsk',
    utcOffset: 360,
    latitude: 60.716667,
    longitude: 77.65,
    altitude: 49.9872
  },
  {
    iataCode: 'BVG',
    icaoCode: 'ENBV',
    name: 'Berlevag',
    country: {code: 'NO', name: 'Norway'},
    city: 'Berlevag',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.866667,
    longitude: 29.0,
    altitude: 13.1064
  },
  {
    iataCode: 'BVI',
    icaoCode: 'YBDV',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Birdsville Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -25.8975,
    longitude: 139.348,
    altitude: 48.4632
  },
  {
    iataCode: 'BVH',
    icaoCode: 'SBVH',
    name: 'Vilhena',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Vilhena',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -12.694375,
    longitude: -60.098269,
    altitude: 615.0864
  },
  {
    iataCode: 'KGP',
    icaoCode: 'USRK',
    name: 'Kogalym',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kogalym International',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 62.18,
    longitude: 74.53,
    altitude: 67.056
  },
  {
    iataCode: 'SWX',
    icaoCode: 'FBSW',
    name: 'Shakawe',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Shakawe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -18.3739,
    longitude: 21.8326,
    altitude: 1029.9192
  },
  {
    iataCode: 'KGS',
    icaoCode: 'LGKO',
    name: 'Kos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.793335,
    longitude: 27.091667,
    altitude: 125.5776
  },
  {
    iataCode: 'SXB',
    icaoCode: 'LFST',
    name: 'Strassbourg',
    country: {code: 'FR', name: 'France'},
    city: 'Entzheim',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.538319,
    longitude: 7.628233,
    altitude: 153.924
  },
  {
    iataCode: 'BVS',
    icaoCode: 'SNVS',
    name: 'Breves',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Breves Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santarem',
    utcOffset: -180,
    latitude: -1.681944,
    longitude: -50.48,
    altitude: 39.9288
  },
  {
    iataCode: 'SXF',
    icaoCode: 'EDDB',
    name: 'Berlin',
    country: {code: 'DE', name: 'Germany'},
    city: 'Schonefeld',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.380001,
    longitude: 13.5225,
    altitude: 47.8536
  },
  {
    iataCode: 'KHD',
    icaoCode: 'OICK',
    name: 'Khorram Abad',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Khoram Abad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 33.435378,
    longitude: 48.282889,
    altitude: 1152.7536
  },
  {
    iataCode: 'SXL',
    icaoCode: 'EISG',
    name: 'Sligo',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Sligo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 54.280214,
    longitude: -8.599206,
    altitude: 3.3528
  },
  {
    iataCode: 'KHE',
    icaoCode: 'UKOH',
    name: 'Kherson',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Chernobayevka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Simferopol',
    utcOffset: 120,
    latitude: 46.6758,
    longitude: 32.5064,
    altitude: 45.1104
  },
  {
    iataCode: 'SXM',
    icaoCode: 'TNCM',
    name: 'Philipsburg',
    country: {code: '', name: 'Netherlands Antilles'},
    city: 'Princess Juliana Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lower_Princes',
    utcOffset: -240,
    latitude: 18.040953,
    longitude: -63.1089,
    altitude: 4.2672
  },
  {
    iataCode: 'KHH',
    icaoCode: 'RCKH',
    name: 'Kaohsiung',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Kaohsiung Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.577094,
    longitude: 120.350006,
    altitude: 9.4488
  },
  {
    iataCode: 'KHG',
    icaoCode: 'ZWSH',
    name: 'Kashi',
    country: {code: 'CN', name: 'China'},
    city: 'Kashi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kashgar',
    utcOffset: 480,
    latitude: 39.542922,
    longitude: 76.019956,
    altitude: 1380.4392
  },
  {
    iataCode: 'BWB',
    icaoCode: 'YBWX',
    name: 'Barrow Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Barrow Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -20.798,
    longitude: 115.406,
    altitude: 7.62
  },
  {
    iataCode: 'SXR',
    icaoCode: 'VISR',
    name: 'Srinagar',
    country: {code: 'IN', name: 'India'},
    city: 'Srinagar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 33.987139,
    longitude: 74.77425,
    altitude: 1654.7592
  },
  {
    iataCode: 'BWA',
    icaoCode: 'VNBW',
    name: 'Bhairawa',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Bhairahawa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.505703,
    longitude: 83.41625,
    altitude: 109.1184
  },
  {
    iataCode: 'KHI',
    icaoCode: 'OPKC',
    name: 'Karachi',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Jinnah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 24.906547,
    longitude: 67.160797,
    altitude: 30.48
  },
  {
    iataCode: 'BWF',
    icaoCode: 'EGNL',
    name: 'Barrow Island',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Walney Island',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.131167,
    longitude: -3.263667,
    altitude: 52.7304
  },
  {
    iataCode: 'KHN',
    icaoCode: 'ZSCN',
    name: 'Nanchang',
    country: {code: 'CN', name: 'China'},
    city: 'Changbei Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 28.865,
    longitude: 115.9,
    altitude: 43.5864
  },
  {
    iataCode: 'BWE',
    icaoCode: 'EDVE',
    name: 'Braunschweig',
    country: {code: 'DE', name: 'Germany'},
    city: 'Braunschweig Wolfsburg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.319167,
    longitude: 10.556111,
    altitude: 89.916
  },
  {
    iataCode: 'KHM',
    icaoCode: 'VYKI',
    name: 'Khamti',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Khamti',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 25.988333,
    longitude: 95.674444,
    altitude: 1828.8
  },
  {
    iataCode: 'BWI',
    icaoCode: 'KBWI',
    name: 'Baltimore',
    country: {code: 'US', name: 'United States'},
    city: 'Baltimore Washington Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.175361,
    longitude: -76.668333,
    altitude: 44.5008
  },
  {
    iataCode: 'BWK',
    icaoCode: 'LDSB',
    name: 'Brac',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Brac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 43.285719,
    longitude: 16.679719,
    altitude: 541.3248
  },
  {
    iataCode: 'KHS',
    icaoCode: 'OOKB',
    name: 'Khasab',
    country: {code: 'OM', name: 'Oman'},
    city: 'Khasab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Muscat',
    utcOffset: 240,
    latitude: 26.170986,
    longitude: 56.240569,
    altitude: 30.48
  },
  {
    iataCode: 'BWN',
    icaoCode: 'WBSB',
    name: 'Brunei',
    country: {code: 'BN', name: 'Brunei Darussalam'},
    city: 'Brunei Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Brunei',
    utcOffset: 480,
    latitude: 4.9442,
    longitude: 114.928353,
    altitude: 22.2504
  },
  {
    iataCode: 'KHV',
    icaoCode: 'UHHH',
    name: 'Khabarovsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Novy',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vladivostok',
    utcOffset: 600,
    latitude: 48.528044,
    longitude: 135.188361,
    altitude: 74.3712
  },
  {
    iataCode: 'SYA',
    icaoCode: 'PASY',
    name: 'Shemya',
    country: {code: 'US', name: 'United States'},
    city: 'Eareckson As',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -600,
    latitude: 52.712275,
    longitude: 174.11362,
    altitude: 29.8704
  },
  {
    iataCode: 'BWO',
    icaoCode: 'UWSB',
    name: 'Balakovo',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Balakovo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 240,
    latitude: 51.8583,
    longitude: 47.7456,
    altitude: 28.956
  },
  {
    iataCode: 'BWT',
    icaoCode: 'YWYY',
    name: 'Burnie',
    country: {code: 'AU', name: 'Australia'},
    city: 'Wynyard Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -40.9989,
    longitude: 145.731,
    altitude: 18.8976
  },
  {
    iataCode: 'SYD',
    icaoCode: 'YSSY',
    name: 'Sydney',
    country: {code: 'AU', name: 'Australia'},
    city: 'Sydney Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.946111,
    longitude: 151.177222,
    altitude: 6.4008
  },
  {
    iataCode: 'BWU',
    icaoCode: 'YSBK',
    name: 'Sydney',
    country: {code: 'AU', name: 'Australia'},
    city: 'Sydney Bankstown',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.924444,
    longitude: 150.988333,
    altitude: 8.8392
  },
  {
    iataCode: 'KIA',
    icaoCode: 'PSKA',
    name: 'Kaieteur Falls',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Kaieteur International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 5.163333,
    longitude: -59.483333,
    altitude: 28.956
  },
  {
    iataCode: 'SYH',
    icaoCode: 'VNSB',
    name: 'Syangboche',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Syangboche',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.811187,
    longitude: 86.712661,
    altitude: 3751.7832
  },
  {
    iataCode: 'SYM',
    icaoCode: 'ZPSM',
    name: 'Simao',
    country: {code: 'CN', name: 'China'},
    city: 'Simao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 22.7933,
    longitude: 100.959,
    altitude: 0.0
  },
  {
    iataCode: 'KID',
    icaoCode: 'ESMK',
    name: 'Kristianstad',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kristianstad',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 55.921686,
    longitude: 14.085536,
    altitude: 23.1648
  },
  {
    iataCode: 'SYO',
    icaoCode: 'RJSY',
    name: 'Shonai',
    country: {code: 'JP', name: 'Japan'},
    city: 'Shonai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 38.812222,
    longitude: 139.787222,
    altitude: 26.2128
  },
  {
    iataCode: 'KIF',
    icaoCode: 'CNM5',
    name: 'Kingfisher Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kingfisher Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -300,
    latitude: 53.0125,
    longitude: -89.8553,
    altitude: 263.9568
  },
  {
    iataCode: 'SYQ',
    icaoCode: 'MRPV',
    name: 'San Jose',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Tobias Bolanos International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.957053,
    longitude: -84.139797,
    altitude: 1001.8776
  },
  {
    iataCode: 'KIH',
    icaoCode: 'OIBK',
    name: 'Kish Island',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Kish Island',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 26.526156,
    longitude: 53.980211,
    altitude: 30.7848
  },
  {
    iataCode: 'KIK',
    icaoCode: 'ORKK',
    name: 'Kirkuk',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Kirkuk Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 35.17,
    longitude: 44.3483,
    altitude: 323.3928
  },
  {
    iataCode: 'BXB',
    icaoCode: 'WASO',
    name: 'Babo',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Babo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -2.532242,
    longitude: 133.438894,
    altitude: 3.048
  },
  {
    iataCode: 'KIJ',
    icaoCode: 'RJSN',
    name: 'Niigata',
    country: {code: 'JP', name: 'Japan'},
    city: 'Niigata',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 37.5711,
    longitude: 139.0646,
    altitude: 0.3048
  },
  {
    iataCode: 'SYR',
    icaoCode: 'KSYR',
    name: 'Syracuse',
    country: {code: 'US', name: 'United States'},
    city: 'Syracuse Hancock Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.111187,
    longitude: -76.106311,
    altitude: 128.3208
  },
  {
    iataCode: 'BXE',
    icaoCode: 'GOTB',
    name: 'Bakel',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Bakel',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 14.847256,
    longitude: -12.468264,
    altitude: 29.8704
  },
  {
    iataCode: 'KIM',
    icaoCode: 'FAKM',
    name: 'Kimberley',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Kimberley',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.802834,
    longitude: 24.765167,
    altitude: 1203.96
  },
  {
    iataCode: 'SYU',
    icaoCode: 'YWBS',
    name: 'Sue Islet',
    country: {code: 'AU', name: 'Australia'},
    city: 'Warraber Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -10.2083,
    longitude: 142.825,
    altitude: 0.9144
  },
  {
    iataCode: 'KIO',
    icaoCode: 'Q51',
    name: 'Kili Island',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Kili Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 5.64452,
    longitude: 169.12,
    altitude: 1.524
  },
  {
    iataCode: 'SYW',
    icaoCode: 'OPSN',
    name: 'Sehwan Sharif',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Sehwan Sharif Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 26.4731,
    longitude: 67.7172,
    altitude: 36.8808
  },
  {
    iataCode: 'KIN',
    icaoCode: 'MKJP',
    name: 'Kingston',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Norman Manley Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 17.935667,
    longitude: -76.7875,
    altitude: 3.048
  },
  {
    iataCode: 'SYY',
    icaoCode: 'EGPO',
    name: 'Stornoway',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Stornoway',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 58.215556,
    longitude: -6.331111,
    altitude: 7.9248
  },
  {
    iataCode: 'SYX',
    icaoCode: 'ZJSY',
    name: 'Sanya',
    country: {code: 'CN', name: 'China'},
    city: 'Phoenix International',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 18.302897,
    longitude: 109.412272,
    altitude: 28.0416
  },
  {
    iataCode: 'KIS',
    icaoCode: 'HKKI',
    name: 'Kisumu',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Kisumu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -0.086139,
    longitude: 34.728892,
    altitude: 1157.0208
  },
  {
    iataCode: 'KIR',
    icaoCode: 'EIKY',
    name: 'Kerry',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Kerry',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 52.180878,
    longitude: -9.523783,
    altitude: 34.1376
  },
  {
    iataCode: 'SYZ',
    icaoCode: 'OISS',
    name: 'Shiraz',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Shiraz Shahid Dastghaib Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 29.539242,
    longitude: 52.589786,
    altitude: 1499.616
  },
  {
    iataCode: 'KIT',
    icaoCode: 'LGKC',
    name: 'Kithira',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kithira',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.274258,
    longitude: 23.016978,
    altitude: 318.516
  },
  {
    iataCode: 'KIW',
    icaoCode: 'FLSO',
    name: 'Southdowns',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Southdowns',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -12.900469,
    longitude: 28.149858,
    altitude: 1263.396
  },
  {
    iataCode: 'BXN',
    icaoCode: 'LTBV',
    name: 'Bodrum',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Imsik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.140144,
    longitude: 27.669717,
    altitude: 61.5696
  },
  {
    iataCode: 'KIV',
    icaoCode: 'LUKK',
    name: 'Kichinau Fir/acc/com',
    country: {code: 'MD', name: 'Republic of Moldova'},
    city: 'Chisinau Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Chisinau',
    utcOffset: 120,
    latitude: 46.927744,
    longitude: 28.930978,
    altitude: 121.6152
  },
  {
    iataCode: 'SZB',
    icaoCode: 'WMSA',
    name: 'Kuala Lumpur',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Abdul Aziz Shah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 3.130583,
    longitude: 101.549333,
    altitude: 27.432
  },
  {
    iataCode: 'KIX',
    icaoCode: 'RJBB',
    name: 'Osaka',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kansai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.434722,
    longitude: 135.244167,
    altitude: 14.9352
  },
  {
    iataCode: 'SZA',
    icaoCode: 'FNSO',
    name: 'Soyo',
    country: {code: 'AO', name: 'Angola'},
    city: 'Soyo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -6.141086,
    longitude: 12.371764,
    altitude: 4.572
  },
  {
    iataCode: 'BXS',
    icaoCode: 'KBXS',
    name: 'Borrego Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Borrego Valley Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 33.259028,
    longitude: -116.320972,
    altitude: 158.496
  },
  {
    iataCode: 'BXR',
    icaoCode: 'OIKM',
    name: 'Bam',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Bam Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 29.084169,
    longitude: 58.450042,
    altitude: 984.8088
  },
  {
    iataCode: 'BXU',
    icaoCode: 'RPME',
    name: 'Butuan',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Butuan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 8.951322,
    longitude: 125.477972,
    altitude: 42.9768
  },
  {
    iataCode: 'SZF',
    icaoCode: 'LTFH',
    name: 'Samsun',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Samsun-çarşamba Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 41.2545,
    longitude: 36.5671,
    altitude: 5.4864
  },
  {
    iataCode: 'SZG',
    icaoCode: 'LOWS',
    name: 'Salzburg',
    country: {code: 'AT', name: 'Austria'},
    city: 'Salzburg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 47.793304,
    longitude: 13.004333,
    altitude: 430.0728
  },
  {
    iataCode: 'KJA',
    icaoCode: 'UNKL',
    name: 'Krasnoyarsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Emelyanovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 56.18,
    longitude: 92.475,
    altitude: 286.512
  },
  {
    iataCode: 'SZL',
    icaoCode: 'KSZL',
    name: 'Knobnoster',
    country: {code: 'US', name: 'United States'},
    city: 'Whiteman Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.730306,
    longitude: -93.547864,
    altitude: 265.176
  },
  {
    iataCode: 'SZK',
    icaoCode: 'FASZ',
    name: 'Skukuza',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Skukuza',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -24.960944,
    longitude: 31.588731,
    altitude: 310.896
  },
  {
    iataCode: 'BYC',
    icaoCode: 'SLYA',
    name: 'Yacuiba',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Yacuiba',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -180,
    latitude: -21.960925,
    longitude: -63.651669,
    altitude: 644.9568
  },
  {
    iataCode: 'SZS',
    icaoCode: 'NZRC',
    name: 'Stewart Island',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Ryans Creek Aerodrome',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -46.899693,
    longitude: 168.101592,
    altitude: 30.48
  },
  {
    iataCode: 'BYH',
    icaoCode: 'KBYH',
    name: 'Blytheville',
    country: {code: 'US', name: 'United States'},
    city: 'Arkansas Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.964347,
    longitude: -89.943956,
    altitude: 77.4192
  },
  {
    iataCode: 'SZX',
    icaoCode: 'ZGSZ',
    name: 'Shenzhen',
    country: {code: 'CN', name: 'China'},
    city: 'Baoan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 22.639258,
    longitude: 113.810664,
    altitude: 3.9624
  },
  {
    iataCode: 'SZW',
    icaoCode: 'EDOP',
    name: 'Parchim',
    country: {code: 'DE', name: 'Germany'},
    city: 'Schwerin Parchim',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.426997,
    longitude: 11.783436,
    altitude: 50.5968
  },
  {
    iataCode: 'SZZ',
    icaoCode: 'EPSC',
    name: 'Szczechin',
    country: {code: 'PL', name: 'Poland'},
    city: 'Goleniow',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 53.584731,
    longitude: 14.902206,
    altitude: 46.9392
  },
  {
    iataCode: 'BYK',
    icaoCode: 'DIBK',
    name: 'Bouake',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Bouake',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 7.7388,
    longitude: -5.073667,
    altitude: 374.904
  },
  {
    iataCode: 'BYN',
    icaoCode: 'ZMBH',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Bayankhongor Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    latitude: 46.1633,
    longitude: 100.704,
    altitude: 1854.708
  },
  {
    iataCode: 'BYM',
    icaoCode: 'MUBY',
    name: 'Bayamo',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Carlos Manuel De Cespedes',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.396331,
    longitude: -76.621494,
    altitude: 61.8744
  },
  {
    iataCode: 'BYS',
    icaoCode: 'KBYS',
    name: 'Fort Irwin',
    country: {code: 'US', name: 'United States'},
    city: 'Bicycle Lake Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.280531,
    longitude: -116.630031,
    altitude: 716.28
  },
  {
    iataCode: 'BYU',
    icaoCode: 'EDQD',
    name: 'Bayreuth',
    country: {code: 'DE', name: 'Germany'},
    city: 'Bayreuth',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.984428,
    longitude: 11.638569,
    altitude: 487.9848
  },
  {
    iataCode: 'KKA',
    icaoCode: 'PAKK',
    name: 'Koyuk',
    country: {code: 'US', name: 'United States'},
    city: 'Koyuk Alfred Adams Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 64.939444,
    longitude: -161.154167,
    altitude: 46.9392
  },
  {
    iataCode: 'KKC',
    icaoCode: 'VTUK',
    name: 'Khon Kaen',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Khon Kaen',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 16.466628,
    longitude: 102.783661,
    altitude: 204.216
  },
  {
    iataCode: 'KKE',
    icaoCode: 'NZKK',
    name: 'Kerikeri',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Kerikeri',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -35.262779,
    longitude: 173.911944,
    altitude: 149.9616
  },
  {
    iataCode: 'BZA',
    icaoCode: 'MNBZ',
    name: 'Bonanza',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Bonanza Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 14.041667,
    longitude: -84.630556,
    altitude: 181.9656
  },
  {
    iataCode: 'KKH',
    icaoCode: 'PADY',
    name: 'Kongiganak',
    country: {code: 'US', name: 'United States'},
    city: 'Kongiganak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 59.960833,
    longitude: -162.881111,
    altitude: 9.144
  },
  {
    iataCode: 'KKJ',
    icaoCode: 'RJFR',
    name: 'Kitakyushu',
    country: {code: 'JP', name: 'Japan'},
    city: 'New Kitakyushu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.845942,
    longitude: 131.034689,
    altitude: 6.4008
  },
  {
    iataCode: 'BZE',
    icaoCode: 'MZBZ',
    name: 'Belize City',
    country: {code: 'BZ', name: 'Belize'},
    city: 'Philip S W Goldson Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Belize',
    utcOffset: -360,
    latitude: 17.539144,
    longitude: -88.308203,
    altitude: 4.572
  },
  {
    iataCode: 'BZG',
    icaoCode: 'EPBY',
    name: 'Bydgoszcz',
    country: {code: 'PL', name: 'Poland'},
    city: 'Bydgoszcz Ignacy Jan Paderewski Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 53.0968,
    longitude: 17.9777,
    altitude: 71.628
  },
  {
    iataCode: 'KKN',
    icaoCode: 'ENKR',
    name: 'Kirkenes',
    country: {code: 'NO', name: 'Norway'},
    city: 'Hoybuktmoen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.725781,
    longitude: 29.891295,
    altitude: 86.2584
  },
  {
    iataCode: 'BZI',
    icaoCode: 'LTBF',
    name: 'Balikesir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Balikesir',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.619258,
    longitude: 27.925958,
    altitude: 103.632
  },
  {
    iataCode: 'BZK',
    icaoCode: 'UUBP',
    name: 'Bryansk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Bryansk',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 53.214194,
    longitude: 34.176447,
    altitude: 202.0824
  },
  {
    iataCode: 'KKR',
    icaoCode: 'NTGK',
    name: 'Kaukura Atoll',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Kaukura',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -15.663333,
    longitude: -146.884769,
    altitude: 3.3528
  },
  {
    iataCode: 'BZL',
    icaoCode: 'VGBR',
    name: 'Barisal',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Barisal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 22.801,
    longitude: 90.3012,
    altitude: 7.0104
  },
  {
    iataCode: 'BZO',
    icaoCode: 'LIPB',
    name: 'Bolzano',
    country: {code: 'IT', name: 'Italy'},
    city: 'Bolzano',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 46.460194,
    longitude: 11.326383,
    altitude: 240.4872
  },
  {
    iataCode: 'KKW',
    icaoCode: 'FZCA',
    name: 'Kikwit',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kikwit',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -5.035767,
    longitude: 18.785631,
    altitude: 479.1456
  },
  {
    iataCode: 'BZN',
    icaoCode: 'KBZN',
    name: 'Bozeman',
    country: {code: 'US', name: 'United States'},
    city: 'Gallatin Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 45.777643,
    longitude: -111.160151,
    altitude: 1371.6
  },
  {
    iataCode: 'KKX',
    icaoCode: 'RJKI',
    name: 'Kikai',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kikai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 28.321389,
    longitude: 129.928056,
    altitude: 4.572
  },
  {
    iataCode: 'BZR',
    icaoCode: 'LFMU',
    name: 'Beziers',
    country: {code: 'FR', name: 'France'},
    city: 'Vias',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.323522,
    longitude: 3.353903,
    altitude: 17.0688
  },
  {
    iataCode: 'BZV',
    icaoCode: 'FCBB',
    name: 'Brazzaville',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Maya Maya',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Brazzaville',
    utcOffset: 60,
    latitude: -4.2517,
    longitude: 15.253031,
    altitude: 319.4304
  },
  {
    iataCode: 'KLD',
    icaoCode: 'UUEM',
    name: 'Tver',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Migalovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 56.824736,
    longitude: 35.757678,
    altitude: 142.9512
  },
  {
    iataCode: 'BZZ',
    icaoCode: 'EGVN',
    name: 'Brize Norton',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Brize Norton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.749964,
    longitude: -1.583617,
    altitude: 87.7824
  },
  {
    iataCode: 'KLC',
    icaoCode: 'GOOK',
    name: 'Kaolack',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Kaolack',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 14.146881,
    longitude: -16.051297,
    altitude: 7.9248
  },
  {
    iataCode: 'KLH',
    icaoCode: 'VAKP',
    name: 'Kolhapur',
    country: {code: 'IN', name: 'India'},
    city: 'Kolhapur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 16.664658,
    longitude: 74.289353,
    altitude: 608.3808
  },
  {
    iataCode: 'KLG',
    icaoCode: 'PALG',
    name: 'Kalskag',
    country: {code: 'US', name: 'United States'},
    city: 'Kalskag Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.5363,
    longitude: -160.341,
    altitude: 16.764
  },
  {
    iataCode: 'KLN',
    icaoCode: 'PALB',
    name: 'Larsen Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Larsen Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 57.535,
    longitude: -153.976667,
    altitude: 26.5176
  },
  {
    iataCode: 'KLO',
    icaoCode: 'RPVK',
    name: 'Kalibo',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Kalibo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.679431,
    longitude: 122.376294,
    altitude: 4.2672
  },
  {
    iataCode: 'KLR',
    icaoCode: 'ESMQ',
    name: 'Kalkmar',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kalmar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 56.685531,
    longitude: 16.287578,
    altitude: 5.1816
  },
  {
    iataCode: 'KLV',
    icaoCode: 'LKKV',
    name: 'Karlovy Vary',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Karlovy Vary',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 50.202978,
    longitude: 12.914983,
    altitude: 606.2472
  },
  {
    iataCode: 'KLU',
    icaoCode: 'LOWK',
    name: 'Klagenfurt',
    country: {code: 'AT', name: 'Austria'},
    city: 'Woerthersee International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 46.642514,
    longitude: 14.337739,
    altitude: 137.7696
  },
  {
    iataCode: 'KLX',
    icaoCode: 'LGKL',
    name: 'Kalamata',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kalamata',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.068319,
    longitude: 22.025525,
    altitude: 7.9248
  },
  {
    iataCode: 'KLW',
    icaoCode: 'PAKW',
    name: 'Klawock',
    country: {code: 'US', name: 'United States'},
    city: 'Klawock Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 55.579167,
    longitude: -133.076111,
    altitude: 24.384
  },
  {
    iataCode: 'KLZ',
    icaoCode: 'FAKZ',
    name: 'Kleinsee',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Kleinsee',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.688403,
    longitude: 17.094006,
    altitude: 82.296
  },
  {
    iataCode: 'KMA',
    icaoCode: 'AYKM',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Kerema Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -7.96361,
    longitude: 145.771,
    altitude: 3.048
  },
  {
    iataCode: 'KME',
    icaoCode: 'HRZA',
    name: 'Kamembe',
    country: {code: 'RW', name: 'Rwanda'},
    city: 'Kamembe',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kigali',
    utcOffset: 120,
    latitude: -2.462242,
    longitude: 28.90795,
    altitude: 1582.5216
  },
  {
    iataCode: 'KMG',
    icaoCode: 'ZPPP',
    name: 'Kunming',
    country: {code: 'CN', name: 'China'},
    city: 'Wujiaba',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 24.992364,
    longitude: 102.743536,
    altitude: 1894.9416
  },
  {
    iataCode: 'KMI',
    icaoCode: 'RJFM',
    name: 'Miyazaki',
    country: {code: 'JP', name: 'Japan'},
    city: 'Miyazaki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 31.877222,
    longitude: 131.448611,
    altitude: 6.096
  },
  {
    iataCode: 'KMJ',
    icaoCode: 'RJFT',
    name: 'Kumamoto',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kumamoto',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 32.837319,
    longitude: 130.85505,
    altitude: 195.6816
  },
  {
    iataCode: 'KMO',
    icaoCode: 'PAMB',
    name: 'Manokotak',
    country: {code: 'US', name: 'United States'},
    city: 'Manokotak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 58.990278,
    longitude: -159.05,
    altitude: 15.5448
  },
  {
    iataCode: 'KMN',
    icaoCode: 'FZSA',
    name: 'Kamina Base',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kamina Base',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -8.642025,
    longitude: 25.252897,
    altitude: 1079.9064
  },
  {
    iataCode: 'KMQ',
    icaoCode: 'RJNK',
    name: 'Kanazawa',
    country: {code: 'JP', name: 'Japan'},
    city: 'Komatsu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 36.394611,
    longitude: 136.406544,
    altitude: 10.9728
  },
  {
    iataCode: 'KMS',
    icaoCode: 'DGSI',
    name: '',
    country: {code: 'GH', name: 'Ghana'},
    city: 'Kumasi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Accra',
    utcOffset: 0,
    latitude: 6.71456,
    longitude: -1.59082,
    altitude: 287.1216
  },
  {
    iataCode: 'KMU',
    icaoCode: 'HCMK',
    name: 'Kismayu',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Kisimayu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: -0.377353,
    longitude: 42.459233,
    altitude: 14.9352
  },
  {
    iataCode: 'KMW',
    icaoCode: 'UUBD',
    name: 'Kostroma',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Sokerkino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 57.7961,
    longitude: 41.0204,
    altitude: 135.9408
  },
  {
    iataCode: 'KMV',
    icaoCode: 'VYKL',
    name: 'Kalemyo',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Kalay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 23.188811,
    longitude: 94.051094,
    altitude: 152.0952
  },
  {
    iataCode: 'KND',
    icaoCode: 'FZOA',
    name: 'Kindu',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kindu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -2.919178,
    longitude: 25.915361,
    altitude: 496.824
  },
  {
    iataCode: 'KNC',
    icaoCode: 'ZSJA',
    name: 'Jian',
    country: {code: 'CN', name: 'China'},
    city: 'Jinggangshan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 26.8575,
    longitude: 114.737222,
    altitude: 0.0
  },
  {
    iataCode: 'KNF',
    icaoCode: 'EGYM',
    name: 'Marham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Marham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.648353,
    longitude: 0.550692,
    altitude: 22.86
  },
  {
    iataCode: 'KNH',
    icaoCode: 'RCBS',
    name: 'Kinmen',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Shang Yi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 24.427892,
    longitude: 118.359197,
    altitude: 28.3464
  },
  {
    iataCode: 'KNG',
    icaoCode: 'WASK',
    name: 'Kaimana',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Kaimana',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -3.644517,
    longitude: 133.695553,
    altitude: 5.7912
  },
  {
    iataCode: 'KNP',
    icaoCode: 'FNCP',
    name: 'Kapanda',
    country: {code: 'AO', name: 'Angola'},
    city: 'Kapanda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -9.771944,
    longitude: 15.456111,
    altitude: 152.4
  },
  {
    iataCode: 'KNQ',
    icaoCode: 'NWWD',
    name: 'Kone',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Kone',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -21.053428,
    longitude: 164.837806,
    altitude: 7.0104
  },
  {
    iataCode: 'KNS',
    icaoCode: 'YKII',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'King Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Currie',
    utcOffset: 600,
    latitude: -39.8775,
    longitude: 143.878,
    altitude: 40.2336
  },
  {
    iataCode: 'KNU',
    icaoCode: 'VIKA',
    name: 'Kanpur',
    country: {code: 'IN', name: 'India'},
    city: 'Kanpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.441444,
    longitude: 80.364864,
    altitude: 125.2728
  },
  {
    iataCode: 'KNX',
    icaoCode: 'YPKU',
    name: 'Kununurra',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kununurra',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -15.778056,
    longitude: 128.7075,
    altitude: 44.196
  },
  {
    iataCode: 'KNW',
    icaoCode: 'PANW',
    name: 'New Stuyahok',
    country: {code: 'US', name: 'United States'},
    city: 'New Stuyahok Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.4499,
    longitude: -157.328,
    altitude: 92.0496
  },
  {
    iataCode: 'KOA',
    icaoCode: 'PHKO',
    name: 'Kona',
    country: {code: 'US', name: 'United States'},
    city: 'Kona Intl At Keahole',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 19.738767,
    longitude: -156.045631,
    altitude: 14.3256
  },
  {
    iataCode: 'KOC',
    icaoCode: 'NWWK',
    name: 'Koumac',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Koumac',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -20.546314,
    longitude: 164.255625,
    altitude: 12.8016
  },
  {
    iataCode: 'KOE',
    icaoCode: 'WATT',
    name: 'Kupang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'El Tari',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -10.171583,
    longitude: 123.671136,
    altitude: 102.108
  },
  {
    iataCode: 'KOI',
    icaoCode: 'EGPA',
    name: 'Kirkwall',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Kirkwall',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 58.957778,
    longitude: -2.905,
    altitude: 15.24
  },
  {
    iataCode: 'KOK',
    icaoCode: 'EFKK',
    name: 'Kruunupyy',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kruunupyy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 63.721172,
    longitude: 23.143131,
    altitude: 25.6032
  },
  {
    iataCode: 'KOJ',
    icaoCode: 'RJFK',
    name: 'Kagoshima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kagoshima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 31.803397,
    longitude: 130.719408,
    altitude: 276.1488
  },
  {
    iataCode: 'KOP',
    icaoCode: 'VTUW',
    name: 'Nakhon Phanom',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Nakhon Phanom',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 17.383794,
    longitude: 104.643022,
    altitude: 178.9176
  },
  {
    iataCode: 'KOS',
    icaoCode: 'VDSV',
    name: 'Sihanoukville',
    country: {code: 'KH', name: 'Cambodia'},
    city: 'Sihanoukville',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Phnom_Penh',
    utcOffset: 420,
    latitude: 10.579686,
    longitude: 103.636828,
    altitude: 10.0584
  },
  {
    iataCode: 'KOU',
    icaoCode: 'FOGK',
    name: 'Koulamoutou',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Koulamoutou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -1.18461,
    longitude: 12.4413,
    altitude: 326.136
  },
  {
    iataCode: 'KOT',
    icaoCode: 'PFKO',
    name: 'Kotlik',
    country: {code: 'US', name: 'United States'},
    city: 'Kotlik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 63.030556,
    longitude: -163.532778,
    altitude: 4.572
  },
  {
    iataCode: 'KOW',
    icaoCode: 'ZSGZ',
    name: 'Ganzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Ganzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 25.8258,
    longitude: 114.912,
    altitude: 0.0
  },
  {
    iataCode: 'KOV',
    icaoCode: 'UACK',
    name: 'Kokshetau',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Kokshetau Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 53.3291,
    longitude: 69.5946,
    altitude: 274.32
  },
  {
    iataCode: 'TAB',
    icaoCode: 'TTCP',
    name: 'Scarborough',
    country: {code: 'TT', name: 'Trinidad and Tobago'},
    city: 'Crown Point',
    region: '',
    terminal: '',
    timezoneId: 'America/Port_of_Spain',
    utcOffset: -240,
    latitude: 11.149658,
    longitude: -60.832194,
    altitude: 11.5824
  },
  {
    iataCode: 'TAC',
    icaoCode: 'RPVA',
    name: 'Tacloban',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Daniel Z Romualdez',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.227628,
    longitude: 125.027758,
    altitude: 3.048
  },
  {
    iataCode: 'TAF',
    icaoCode: 'DAOL',
    name: 'Oran',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Tafaraoui',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.542444,
    longitude: -0.532278,
    altitude: 111.8616
  },
  {
    iataCode: 'TAE',
    icaoCode: 'RKTN',
    name: 'Taegu',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Daegu Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.894108,
    longitude: 128.658856,
    altitude: 35.3568
  },
  {
    iataCode: 'TAG',
    icaoCode: 'RPVT',
    name: 'Tagbilaran',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Tagbilaran',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.664081,
    longitude: 123.853247,
    altitude: 11.5824
  },
  {
    iataCode: 'TAI',
    icaoCode: 'OYTZ',
    name: 'Taiz',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Taiz Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 13.685964,
    longitude: 44.139056,
    altitude: 1474.6224
  },
  {
    iataCode: 'KPC',
    icaoCode: 'PAPC',
    name: 'Port Clarence',
    country: {code: 'US', name: 'United States'},
    city: 'Port Clarence Coast Guard Station',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 65.2537,
    longitude: -166.859,
    altitude: 3.048
  },
  {
    iataCode: 'TAK',
    icaoCode: 'RJOT',
    name: 'Takamatsu',
    country: {code: 'JP', name: 'Japan'},
    city: 'Takamatsu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.214167,
    longitude: 134.015556,
    altitude: 185.0136
  },
  {
    iataCode: 'TAM',
    icaoCode: 'MMTM',
    name: 'Tampico',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Francisco Javier Mina Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 22.29645,
    longitude: -97.865931,
    altitude: 24.384
  },
  {
    iataCode: 'TAP',
    icaoCode: 'MMTP',
    name: 'Tapachula',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tapachula Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 14.794339,
    longitude: -92.370025,
    altitude: 29.5656
  },
  {
    iataCode: 'TAO',
    icaoCode: 'ZSQD',
    name: 'Qingdao',
    country: {code: 'CN', name: 'China'},
    city: 'Liuting',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 36.266108,
    longitude: 120.374436,
    altitude: 10.0584
  },
  {
    iataCode: 'TAR',
    icaoCode: 'LIBG',
    name: 'Grottaglie',
    country: {code: 'IT', name: 'Italy'},
    city: 'Grottaglie',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.517514,
    longitude: 17.403212,
    altitude: 65.532
  },
  {
    iataCode: 'TAT',
    icaoCode: 'LZTT',
    name: 'Poprad',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'Tatry',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 49.073594,
    longitude: 20.241142,
    altitude: 718.1088
  },
  {
    iataCode: 'TAS',
    icaoCode: 'UTTT',
    name: 'Tashkent',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Yuzhny',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tashkent',
    utcOffset: 300,
    latitude: 41.257861,
    longitude: 69.281186,
    altitude: 431.9016
  },
  {
    iataCode: 'KPN',
    icaoCode: 'PAKI',
    name: 'Kipnuk',
    country: {code: 'US', name: 'United States'},
    city: 'Kipnuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 59.933056,
    longitude: -164.030556,
    altitude: 3.3528
  },
  {
    iataCode: 'KPO',
    icaoCode: 'RKTH',
    name: 'Pohang',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Pohang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.987858,
    longitude: 129.420486,
    altitude: 21.336
  },
  {
    iataCode: 'TAZ',
    icaoCode: 'UTAT',
    name: 'Dasoguz',
    country: {code: 'TM', name: 'Turkmenistan'},
    city: 'Dasoguz Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ashgabat',
    utcOffset: 300,
    latitude: 41.764722,
    longitude: 59.833056,
    altitude: 152.4
  },
  {
    iataCode: 'TAY',
    icaoCode: 'EETU',
    name: 'Tartu-ulenurme',
    country: {code: 'EE', name: 'Estonia'},
    city: 'Tartu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tallinn',
    utcOffset: 120,
    latitude: 58.307461,
    longitude: 26.690428,
    altitude: 66.7512
  },
  {
    iataCode: 'KPV',
    icaoCode: 'PAPE',
    name: 'Perryville',
    country: {code: 'US', name: 'United States'},
    city: 'Perryville Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 55.906667,
    longitude: -159.160833,
    altitude: 8.8392
  },
  {
    iataCode: 'TBB',
    icaoCode: 'VVTH',
    name: 'Tuy Hoa',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Dong Tac',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 13.04955,
    longitude: 109.333706,
    altitude: 6.096
  },
  {
    iataCode: 'TBG',
    icaoCode: 'AYTB',
    name: 'Tabubil',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Tabubil Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.27861,
    longitude: 141.226,
    altitude: 478.536
  },
  {
    iataCode: 'TBF',
    icaoCode: 'NGTE',
    name: 'Tabiteuea North',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Tabiteuea North',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 720,
    latitude: -1.224469,
    longitude: 174.775614,
    altitude: 2.1336
  },
  {
    iataCode: 'KQA',
    icaoCode: 'KQA',
    name: 'Akutan',
    country: {code: 'US', name: 'United States'},
    city: 'Akutan Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 54.1325,
    longitude: -165.785,
    altitude: 0.0
  },
  {
    iataCode: 'TBH',
    icaoCode: 'RPVU',
    name: 'Romblon',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Romblon Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 12.311,
    longitude: 122.085,
    altitude: 3.048
  },
  {
    iataCode: 'TBJ',
    icaoCode: 'DTKA',
    name: 'Tabarka',
    country: {code: 'TN', name: 'Tunisia'},
    city: '7 Novembre',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 36.978333,
    longitude: 8.876389,
    altitude: 0.0
  },
  {
    iataCode: 'TBO',
    icaoCode: 'HTTB',
    name: 'Tabora',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Tabora Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -5.07639,
    longitude: 32.8333,
    altitude: 1178.9664
  },
  {
    iataCode: 'TBN',
    icaoCode: 'KTBN',
    name: 'Fort Leonardwood',
    country: {code: 'US', name: 'United States'},
    city: 'Waynesville Rgnl Arpt At Forney Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.741631,
    longitude: -92.140736,
    altitude: 353.2632
  },
  {
    iataCode: 'TBP',
    icaoCode: 'SPME',
    name: 'Tumbes',
    country: {code: 'PE', name: 'Peru'},
    city: 'Pedro Canga',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -3.552528,
    longitude: -80.381356,
    altitude: 35.052
  },
  {
    iataCode: 'CAC',
    icaoCode: 'SBCA',
    name: 'Cascavel',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Cascavel',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -25.000339,
    longitude: -53.500764,
    altitude: 753.7704
  },
  {
    iataCode: 'TBS',
    icaoCode: 'UGTB',
    name: 'Tbilisi',
    country: {code: 'GE', name: 'Georgia'},
    city: 'Tbilisi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tbilisi',
    utcOffset: 240,
    latitude: 41.669167,
    longitude: 44.954722,
    altitude: 494.9952
  },
  {
    iataCode: 'CAB',
    icaoCode: 'FNCA',
    name: 'Cabinda',
    country: {code: 'AO', name: 'Angola'},
    city: 'Cabinda',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -5.596992,
    longitude: 12.188353,
    altitude: 20.1168
  },
  {
    iataCode: 'CAE',
    icaoCode: 'KCAE',
    name: 'Colombia',
    country: {code: 'US', name: 'United States'},
    city: 'Columbia Metropolitan',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.938833,
    longitude: -81.119528,
    altitude: 71.9328
  },
  {
    iataCode: 'TBU',
    icaoCode: 'NFTF',
    name: 'Tongatapu',
    country: {code: 'TO', name: 'Tonga'},
    city: 'Fua Amotu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tongatapu',
    utcOffset: 780,
    latitude: -21.241214,
    longitude: -175.149644,
    altitude: 38.4048
  },
  {
    iataCode: 'TBT',
    icaoCode: 'SBTT',
    name: 'Tabatinga',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Tabatinga',
    region: '',
    terminal: '',
    timezoneId: 'America/Porto_Velho',
    utcOffset: -240,
    latitude: -4.255669,
    longitude: -69.935828,
    altitude: 85.0392
  },
  {
    iataCode: 'CAG',
    icaoCode: 'LIEE',
    name: 'Cagliari',
    country: {code: 'IT', name: 'Italy'},
    city: 'Elmas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 39.251469,
    longitude: 9.054283,
    altitude: 3.9624
  },
  {
    iataCode: 'TBW',
    icaoCode: 'UUOT',
    name: 'Tambow',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Tambow',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 52.81,
    longitude: 41.48,
    altitude: 38.4048
  },
  {
    iataCode: 'CAI',
    icaoCode: 'HECA',
    name: 'Cairo',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Cairo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 30.121944,
    longitude: 31.405556,
    altitude: 116.4336
  },
  {
    iataCode: 'CAH',
    icaoCode: 'VVCM',
    name: 'Ca Mau',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Ca Mau',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 9.188049,
    longitude: 105.174721,
    altitude: 15.24
  },
  {
    iataCode: 'CAJ',
    icaoCode: 'SVCN',
    name: 'Canaima',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Canaima',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 6.231989,
    longitude: -62.854433,
    altitude: 441.96
  },
  {
    iataCode: 'TBZ',
    icaoCode: 'OITT',
    name: 'Tabriz',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Tabriz Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 38.133889,
    longitude: 46.235,
    altitude: 1359.1032
  },
  {
    iataCode: 'CAL',
    icaoCode: 'EGEC',
    name: 'Campbeltown',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Campbeltown Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.4372,
    longitude: -5.68639,
    altitude: 12.8016
  },
  {
    iataCode: 'CAN',
    icaoCode: 'ZGGG',
    name: 'Guangzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Baiyun Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 23.392436,
    longitude: 113.298786,
    altitude: 15.24
  },
  {
    iataCode: 'CAQ',
    icaoCode: 'SKCU',
    name: 'Caucasia',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Juan H. White',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 7.968333,
    longitude: -75.198333,
    altitude: 53.0352
  },
  {
    iataCode: 'TCB',
    icaoCode: 'MYAT',
    name: 'Treasure Cay',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Treasure Cay',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 26.745336,
    longitude: -77.391269,
    altitude: 2.4384
  },
  {
    iataCode: 'CAP',
    icaoCode: 'MTCH',
    name: 'Cap Haitien',
    country: {code: 'HT', name: 'Haiti'},
    city: 'Cap Haitien Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Port-au-Prince',
    utcOffset: -300,
    latitude: 19.732989,
    longitude: -72.194739,
    altitude: 3.048
  },
  {
    iataCode: 'TCA',
    icaoCode: 'YTNK',
    name: 'Tennant Creek',
    country: {code: 'AU', name: 'Australia'},
    city: 'Tennant Creek Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -19.6344,
    longitude: 134.183,
    altitude: 376.7328
  },
  {
    iataCode: 'CAR',
    icaoCode: 'KCAR',
    name: 'Caribou',
    country: {code: 'US', name: 'United States'},
    city: 'Caribou Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 46.8715,
    longitude: -68.017917,
    altitude: 190.8048
  },
  {
    iataCode: 'TCC',
    icaoCode: 'KTCC',
    name: 'Tucumcari',
    country: {code: 'US', name: 'United States'},
    city: 'Tucumcari Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.182777,
    longitude: -103.603186,
    altitude: 1239.012
  },
  {
    iataCode: 'CAU',
    icaoCode: 'SNRU',
    name: 'Caruaru',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Caruaru Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Recife',
    utcOffset: -180,
    latitude: -8.28239,
    longitude: -36.0135,
    altitude: 576.3768
  },
  {
    iataCode: 'CAT',
    icaoCode: 'MYCB',
    name: 'Cat Island',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'New Bight Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.315292,
    longitude: -75.452331,
    altitude: 1.524
  },
  {
    iataCode: 'TCE',
    icaoCode: 'LRTC',
    name: 'Tulcea',
    country: {code: 'RO', name: 'Romania'},
    city: 'Cataloi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 45.062486,
    longitude: 28.714311,
    altitude: 60.96
  },
  {
    iataCode: 'CAW',
    icaoCode: 'SBCP',
    name: 'Campos',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Bartolomeu Lisandro',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.698333,
    longitude: -41.301669,
    altitude: 17.3736
  },
  {
    iataCode: 'TCH',
    icaoCode: 'FOOT',
    name: 'Tchibanga',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Tchibanga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -2.85,
    longitude: 11.017,
    altitude: 81.9912
  },
  {
    iataCode: 'TCG',
    icaoCode: 'ZWTC',
    name: 'Tacheng',
    country: {code: 'CN', name: 'China'},
    city: 'Tacheng Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 46.6725,
    longitude: 83.3408,
    altitude: 0.0
  },
  {
    iataCode: 'CAY',
    icaoCode: 'SOCA',
    name: 'Cayenne',
    country: {code: 'GF', name: 'French Guiana'},
    city: 'Rochambeau',
    region: '',
    terminal: '',
    timezoneId: 'America/Cayenne',
    utcOffset: -180,
    latitude: 4.819808,
    longitude: -52.360447,
    altitude: 7.9248
  },
  {
    iataCode: 'KRB',
    icaoCode: 'YKMB',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Karumba Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -17.4567,
    longitude: 140.83,
    altitude: 1.524
  },
  {
    iataCode: 'CAX',
    icaoCode: 'EGNC',
    name: 'Carlisle',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Carlisle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.9375,
    longitude: -2.809167,
    altitude: 57.912
  },
  {
    iataCode: 'TCL',
    icaoCode: 'KTCL',
    name: 'Tuscaloosa Al',
    country: {code: 'US', name: 'United States'},
    city: 'Tuscaloosa Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.220627,
    longitude: -87.611403,
    altitude: 51.816
  },
  {
    iataCode: 'CAZ',
    icaoCode: 'YCBA',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cobar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -31.5383,
    longitude: 145.794,
    altitude: 220.6752
  },
  {
    iataCode: 'KRF',
    icaoCode: 'ESNK',
    name: 'Kramfors',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kramfors Solleftea',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 63.048597,
    longitude: 17.768856,
    altitude: 10.3632
  },
  {
    iataCode: 'TCN',
    icaoCode: 'MMHC',
    name: 'Tehuacan',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tehuacan',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 18.497189,
    longitude: -97.419942,
    altitude: 1679.1432
  },
  {
    iataCode: 'TCM',
    icaoCode: 'KTCM',
    name: 'Tacoma',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Chord Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.137678,
    longitude: -122.476475,
    altitude: 98.1456
  },
  {
    iataCode: 'TCP',
    icaoCode: 'HETB',
    name: 'Taba',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Taba Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 29.587778,
    longitude: 34.778056,
    altitude: 736.092
  },
  {
    iataCode: 'TCO',
    icaoCode: 'SKCO',
    name: 'Tumaco',
    country: {code: 'CO', name: 'Colombia'},
    city: 'La Florida',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 1.814417,
    longitude: -78.749228,
    altitude: 2.4384
  },
  {
    iataCode: 'CBB',
    icaoCode: 'SLCB',
    name: 'Cochabamba',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Jorge Wilsterman',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -17.421058,
    longitude: -66.177114,
    altitude: 2548.128
  },
  {
    iataCode: 'KRI',
    icaoCode: 'AYKK',
    name: 'Kikori',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Kikori Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -7.42438,
    longitude: 144.25,
    altitude: 15.24
  },
  {
    iataCode: 'TCQ',
    icaoCode: 'SPTN',
    name: 'Tacna',
    country: {code: 'PE', name: 'Peru'},
    city: 'Coronel Carlos Ciriani Santa Rosa Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -18.053333,
    longitude: -70.275833,
    altitude: 468.7824
  },
  {
    iataCode: 'KRL',
    icaoCode: 'ZWKL',
    name: 'Korla',
    country: {code: 'CN', name: 'China'},
    city: 'Korla Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 41.6978,
    longitude: 86.1289,
    altitude: 0.0
  },
  {
    iataCode: 'KRK',
    icaoCode: 'EPKK',
    name: 'Krakow',
    country: {code: 'PL', name: 'Poland'},
    city: 'Balice',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 50.077731,
    longitude: 19.784836,
    altitude: 241.0968
  },
  {
    iataCode: 'TCS',
    icaoCode: 'KTCS',
    name: 'Truth Or Consequences',
    country: {code: 'US', name: 'United States'},
    city: 'Truth Or Consequences Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 33.236944,
    longitude: -107.27175,
    altitude: 1479.1944
  },
  {
    iataCode: 'KRN',
    icaoCode: 'ESNQ',
    name: 'Kiruna',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kiruna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 67.821986,
    longitude: 20.336764,
    altitude: 459.6384
  },
  {
    iataCode: 'CBH',
    icaoCode: 'DAOR',
    name: 'Béchar',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Béchar Boudghene Ben Ali Lotfi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 31.6457,
    longitude: -2.26986,
    altitude: 811.0728
  },
  {
    iataCode: 'KRP',
    icaoCode: 'EKKA',
    name: 'Karup',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Karup',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 56.297458,
    longitude: 9.124628,
    altitude: 51.816
  },
  {
    iataCode: 'CBG',
    icaoCode: 'EGSC',
    name: 'Cambridge',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Cambridge',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.205,
    longitude: 0.175,
    altitude: 14.3256
  },
  {
    iataCode: 'KRO',
    icaoCode: 'USUU',
    name: 'Kurgan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kurgan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 55.4753,
    longitude: 65.4156,
    altitude: 73.152
  },
  {
    iataCode: 'KRR',
    icaoCode: 'URKK',
    name: 'Krasnodar',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Pashkovskiy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 45.034689,
    longitude: 39.170539,
    altitude: 35.9664
  },
  {
    iataCode: 'CBL',
    icaoCode: 'SVCB',
    name: 'Ciudad Bolivar',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Ciudad Bolivar',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.121898,
    longitude: -63.537353,
    altitude: 60.0456
  },
  {
    iataCode: 'KRT',
    icaoCode: 'HSSS',
    name: 'Khartoum',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Khartoum',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 15.589497,
    longitude: 32.553161,
    altitude: 385.572
  },
  {
    iataCode: 'KRS',
    icaoCode: 'ENCN',
    name: 'Kristiansand',
    country: {code: 'NO', name: 'Norway'},
    city: 'Kjevik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 58.204214,
    longitude: 8.085369,
    altitude: 17.3736
  },
  {
    iataCode: 'CBN',
    icaoCode: 'WICD',
    name: 'Cirebon',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Penggung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.756144,
    longitude: 108.539672,
    altitude: 27.1272
  },
  {
    iataCode: 'CBM',
    icaoCode: 'KCBM',
    name: 'Colombus',
    country: {code: 'US', name: 'United States'},
    city: 'Columbus Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.643833,
    longitude: -88.443833,
    altitude: 66.7512
  },
  {
    iataCode: 'CBO',
    icaoCode: 'RPMC',
    name: 'Cotabato',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Cotabato',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 7.165242,
    longitude: 124.209619,
    altitude: 57.6072
  },
  {
    iataCode: 'KRW',
    icaoCode: 'UTAK',
    name: 'Krasnovodsk',
    country: {code: 'TM', name: 'Turkmenistan'},
    city: 'Turkmenbashi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ashgabat',
    utcOffset: 300,
    latitude: 40.063333,
    longitude: 53.007222,
    altitude: 85.0392
  },
  {
    iataCode: 'CBR',
    icaoCode: 'YSCB',
    name: 'Canberra',
    country: {code: 'AU', name: 'Australia'},
    city: 'Canberra',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -35.306944,
    longitude: 149.195,
    altitude: 574.8528
  },
  {
    iataCode: 'KRZ',
    icaoCode: 'FZBT',
    name: 'Kiri',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Basango Mboliasa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -1.435,
    longitude: 19.024,
    altitude: 308.7624
  },
  {
    iataCode: 'CBQ',
    icaoCode: 'DNCA',
    name: 'Calabar',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Calabar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 4.976019,
    longitude: 8.347197,
    altitude: 64.008
  },
  {
    iataCode: 'KRY',
    icaoCode: 'ZWKM',
    name: 'Karamay',
    country: {code: 'CN', name: 'China'},
    city: 'Karamay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 45.617,
    longitude: 84.883,
    altitude: 0.0
  },
  {
    iataCode: 'CBT',
    icaoCode: 'FNCT',
    name: 'Catumbela',
    country: {code: 'AO', name: 'Angola'},
    city: 'Catumbela Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -12.4792,
    longitude: 13.4869,
    altitude: 0.0
  },
  {
    iataCode: 'TDD',
    icaoCode: 'SLTR',
    name: 'Trinidad',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Tte Av Jorge Henrich Arauz',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -14.818739,
    longitude: -64.918019,
    altitude: 155.1432
  },
  {
    iataCode: 'CBV',
    icaoCode: 'MGCB',
    name: 'Coban',
    country: {code: 'GT', name: 'Guatemala'},
    city: 'Coban',
    region: '',
    terminal: '',
    timezoneId: 'America/Guatemala',
    utcOffset: -360,
    latitude: 15.468958,
    longitude: -90.406742,
    altitude: 1322.5272
  },
  {
    iataCode: 'TDG',
    icaoCode: 'RPMW',
    name: '',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Tandag Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.07211,
    longitude: 126.171,
    altitude: 4.8768
  },
  {
    iataCode: 'KSA',
    icaoCode: 'PTSA',
    name: 'Kosrae',
    country: {code: 'FM', name: 'Federated States of Micronesia'},
    city: 'Kosrae',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Kosrae',
    utcOffset: 720,
    latitude: 5.356975,
    longitude: 162.958386,
    altitude: 3.3528
  },
  {
    iataCode: 'KSC',
    icaoCode: 'LZKZ',
    name: 'Kosice',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'Kosice',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 48.663055,
    longitude: 21.241112,
    altitude: 230.124
  },
  {
    iataCode: 'TDK',
    icaoCode: 'UAAT',
    name: 'Taldykorgan',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Taldykorgan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 45.016667,
    longitude: 78.366667,
    altitude: 600.1512
  },
  {
    iataCode: 'KSD',
    icaoCode: 'ESOK',
    name: '',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Karlstad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.4447,
    longitude: 13.3374,
    altitude: 107.2896
  },
  {
    iataCode: 'TDL',
    icaoCode: 'SAZT',
    name: 'Tandil',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Tandil',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -37.237392,
    longitude: -59.227922,
    altitude: 174.9552
  },
  {
    iataCode: 'KSF',
    icaoCode: 'EDVK',
    name: 'Kassel',
    country: {code: 'DE', name: 'Germany'},
    city: 'Kassel Calden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.408394,
    longitude: 9.377631,
    altitude: 276.7584
  },
  {
    iataCode: 'KSH',
    icaoCode: 'OICC',
    name: 'Bakhtaran',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Shahid Ashrafi Esfahani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 34.345853,
    longitude: 47.158128,
    altitude: 1310.9448
  },
  {
    iataCode: 'CCC',
    icaoCode: 'MUOC',
    name: 'Cayo Coco',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Cayo Coco Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.5132,
    longitude: -78.511,
    altitude: 1.8288
  },
  {
    iataCode: 'KSK',
    icaoCode: 'ESKK',
    name: 'Karlskoga',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Karlskoga',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.345867,
    longitude: 14.495922,
    altitude: 121.92
  },
  {
    iataCode: 'KSJ',
    icaoCode: 'LGKS',
    name: 'Kasos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kasos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 35.421358,
    longitude: 26.910047,
    altitude: 10.668
  },
  {
    iataCode: 'KSM',
    icaoCode: 'PASM',
    name: "St Mary's",
    country: {code: 'US', name: 'United States'},
    city: 'St Marys Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 62.0605,
    longitude: -163.302,
    altitude: 94.7928
  },
  {
    iataCode: 'KSL',
    icaoCode: 'HSKA',
    name: 'Kassala',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Kassala',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 15.387494,
    longitude: 36.328842,
    altitude: 509.3208
  },
  {
    iataCode: 'KSO',
    icaoCode: 'LGKA',
    name: 'Kastoria',
    country: {code: 'GR', name: 'Greece'},
    city: 'Aristotelis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 40.446294,
    longitude: 21.282186,
    altitude: 660.5016
  },
  {
    iataCode: 'CCF',
    icaoCode: 'LFMK',
    name: 'Carcassonne',
    country: {code: 'FR', name: 'France'},
    city: 'Salvaza',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.215978,
    longitude: 2.306317,
    altitude: 131.9784
  },
  {
    iataCode: 'KSN',
    icaoCode: 'UAUU',
    name: 'Kostanay',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Kostanay West Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Qyzylorda',
    utcOffset: 360,
    latitude: 53.206944,
    longitude: 63.550278,
    altitude: 181.356
  },
  {
    iataCode: 'KSQ',
    icaoCode: 'UTSL',
    name: 'Khanabad',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Karshi Khanabad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 38.8336,
    longitude: 65.9215,
    altitude: 416.052
  },
  {
    iataCode: 'CCH',
    icaoCode: 'SCCC',
    name: 'Chile Chico',
    country: {code: 'CL', name: 'Chile'},
    city: 'Chile Chico',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -180,
    latitude: -46.583341,
    longitude: -71.687405,
    altitude: 326.136
  },
  {
    iataCode: 'TDX',
    icaoCode: 'VTBO',
    name: 'Trat',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Trat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 12.274572,
    longitude: 102.318958,
    altitude: 32.004
  },
  {
    iataCode: 'CCK',
    icaoCode: 'YPCC',
    name: '',
    country: {code: 'CC', name: 'Cocos (Keeling) Islands'},
    city: 'Cocos Keeling Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Cocos',
    utcOffset: 360,
    latitude: -12.1883,
    longitude: 96.8339,
    altitude: 3.048
  },
  {
    iataCode: 'CCJ',
    icaoCode: 'VOCL',
    name: 'Calicut',
    country: {code: 'IN', name: 'India'},
    city: 'Calicut',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 11.136839,
    longitude: 75.9553,
    altitude: 104.2416
  },
  {
    iataCode: 'KSU',
    icaoCode: 'ENKB',
    name: 'Kristiansund',
    country: {code: 'NO', name: 'Norway'},
    city: 'Kvernberget',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 63.111781,
    longitude: 7.824522,
    altitude: 62.1792
  },
  {
    iataCode: 'CCN',
    icaoCode: 'OACC',
    name: 'Chaghcharan',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Chaghcharan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 240,
    latitude: 34.526667,
    longitude: 65.271667,
    altitude: 2250.3384
  },
  {
    iataCode: 'KSY',
    icaoCode: 'LTCF',
    name: 'Kars',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Kars Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.562222,
    longitude: 43.115002,
    altitude: 1794.9672
  },
  {
    iataCode: 'TEB',
    icaoCode: 'KTEB',
    name: 'Teterboro',
    country: {code: 'US', name: 'United States'},
    city: 'Teterboro',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.850103,
    longitude: -74.060837,
    altitude: 2.7432
  },
  {
    iataCode: 'CCP',
    icaoCode: 'SCIE',
    name: 'Concepcion',
    country: {code: 'CL', name: 'Chile'},
    city: 'Carriel Sur Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -36.77265,
    longitude: -73.063106,
    altitude: 7.9248
  },
  {
    iataCode: 'TEA',
    icaoCode: 'MHTE',
    name: 'Tela',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Tela',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.775864,
    longitude: -87.475847,
    altitude: 2.1336
  },
  {
    iataCode: 'CCS',
    icaoCode: 'SVMI',
    name: 'Caracas',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Simon Bolivar Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.603117,
    longitude: -66.990583,
    altitude: 71.628
  },
  {
    iataCode: 'TED',
    icaoCode: 'EKTS',
    name: 'Thisted',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Thisted',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 57.0688,
    longitude: 8.705225,
    altitude: 7.0104
  },
  {
    iataCode: 'KSZ',
    icaoCode: 'ULKK',
    name: 'Kotlas',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kotlas Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 61.2358,
    longitude: 46.6975,
    altitude: 56.0832
  },
  {
    iataCode: 'CCU',
    icaoCode: 'VECC',
    name: 'Calcutta',
    country: {code: 'IN', name: 'India'},
    city: 'Netaji Subhash Chandra Bose Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.654739,
    longitude: 88.446722,
    altitude: 4.8768
  },
  {
    iataCode: 'TEE',
    icaoCode: 'DABS',
    name: 'Tebessa',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Cheikh Larbi Tebessi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.431611,
    longitude: 8.120717,
    altitude: 811.0728
  },
  {
    iataCode: 'CCV',
    icaoCode: 'NVSF',
    name: 'Craig Cove',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Craig Cove Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.265,
    longitude: 167.924,
    altitude: 21.0312
  },
  {
    iataCode: 'KTA',
    icaoCode: 'YPKA',
    name: 'Karratha',
    country: {code: 'AU', name: 'Australia'},
    city: 'Karratha',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -20.712222,
    longitude: 116.773333,
    altitude: 8.8392
  },
  {
    iataCode: 'KTD',
    icaoCode: 'RORK',
    name: 'Kitadaito',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kitadaito',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 25.944722,
    longitude: 131.326944,
    altitude: 24.384
  },
  {
    iataCode: 'CCZ',
    icaoCode: 'MYBC',
    name: 'Chub Cay',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Chub Cay',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.417108,
    longitude: -77.88085,
    altitude: 1.524
  },
  {
    iataCode: 'KTF',
    icaoCode: 'NZTK',
    name: 'Takaka',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Takaka Aerodrome',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -40.85,
    longitude: 172.8,
    altitude: 152.4
  },
  {
    iataCode: 'TEN',
    icaoCode: 'ZUTR',
    name: 'Tongren',
    country: {code: 'CN', name: 'China'},
    city: 'Tongren',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 27.884,
    longitude: 109.31,
    altitude: 0.0
  },
  {
    iataCode: 'KTE',
    icaoCode: 'WMKE',
    name: 'Kerteh',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kerteh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 4.537222,
    longitude: 103.426756,
    altitude: 5.4864
  },
  {
    iataCode: 'KTG',
    icaoCode: 'WIOK',
    name: 'Ketapang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Rahadi Usman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: -1.816639,
    longitude: 109.963483,
    altitude: 14.0208
  },
  {
    iataCode: 'CDB',
    icaoCode: 'PACD',
    name: 'Cold Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Cold Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 55.206061,
    longitude: -162.725436,
    altitude: 29.2608
  },
  {
    iataCode: 'TER',
    icaoCode: 'LPLA',
    name: 'Terceira',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Lajes',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 38.761842,
    longitude: -27.090797,
    altitude: 54.864
  },
  {
    iataCode: 'TEQ',
    icaoCode: 'LTBU',
    name: 'Çorlu',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Tekirdağ Çorlu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 41.13825,
    longitude: 27.919094,
    altitude: 174.9552
  },
  {
    iataCode: 'KTL',
    icaoCode: 'HKKT',
    name: 'Kitale',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Kitale',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 0.971989,
    longitude: 34.958556,
    altitude: 1850.136
  },
  {
    iataCode: 'TET',
    icaoCode: 'FQTT',
    name: 'Tete',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Tete Chingodzi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -16.104817,
    longitude: 33.640181,
    altitude: 160.02
  },
  {
    iataCode: 'CDC',
    icaoCode: 'KCDC',
    name: 'Cedar City',
    country: {code: 'US', name: 'United States'},
    city: 'Cedar City Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.700967,
    longitude: -113.098847,
    altitude: 1713.5856
  },
  {
    iataCode: 'KTN',
    icaoCode: 'PAKT',
    name: 'Ketchikan',
    country: {code: 'US', name: 'United States'},
    city: 'Ketchikan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Sitka',
    utcOffset: -540,
    latitude: 55.355556,
    longitude: -131.71375,
    altitude: 26.8224
  },
  {
    iataCode: 'KTM',
    icaoCode: 'VNKT',
    name: 'Kathmandu',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Tribhuvan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.696583,
    longitude: 85.3591,
    altitude: 1338.072
  },
  {
    iataCode: 'TEU',
    icaoCode: 'NZMO',
    name: 'Manapouri',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Manapouri',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -45.533056,
    longitude: 167.65,
    altitude: 209.3976
  },
  {
    iataCode: 'KTP',
    icaoCode: 'MKTP',
    name: 'Kingston',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Tinson Pen',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 17.988558,
    longitude: -76.823761,
    altitude: 4.8768
  },
  {
    iataCode: 'TEX',
    icaoCode: 'KTEX',
    name: 'Telluride',
    country: {code: 'US', name: 'United States'},
    city: 'Telluride',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.953759,
    longitude: -107.90848,
    altitude: 2766.9744
  },
  {
    iataCode: 'CDG',
    icaoCode: 'LFPG',
    name: 'Paris',
    country: {code: 'FR', name: 'France'},
    city: 'Charles De Gaulle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.012779,
    longitude: 2.55,
    altitude: 119.4816
  },
  {
    iataCode: 'CDJ',
    icaoCode: 'SBAA',
    name: 'Conceicao Do Araguaia',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Conceicao Do Araguaia',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -8.348347,
    longitude: -49.301528,
    altitude: 199.0344
  },
  {
    iataCode: 'KTR',
    icaoCode: 'YPTN',
    name: 'Katherine',
    country: {code: 'AU', name: 'Australia'},
    city: 'Tindal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -14.5211,
    longitude: 132.378,
    altitude: 135.0264
  },
  {
    iataCode: 'TEZ',
    icaoCode: 'VETZ',
    name: '',
    country: {code: 'IN', name: 'India'},
    city: 'Tezpur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.7091,
    longitude: 92.7847,
    altitude: 73.152
  },
  {
    iataCode: 'KTT',
    icaoCode: 'EFKT',
    name: 'Kittila',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kittila',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 67.701022,
    longitude: 24.84685,
    altitude: 196.2912
  },
  {
    iataCode: 'KTS',
    icaoCode: 'PFKT',
    name: 'Brevig Mission',
    country: {code: 'US', name: 'United States'},
    city: 'Brevig Mission Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 65.331389,
    longitude: -166.465833,
    altitude: 10.668
  },
  {
    iataCode: 'KTU',
    icaoCode: 'VIKO',
    name: 'Kota',
    country: {code: 'IN', name: 'India'},
    city: 'Kota',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.160219,
    longitude: 75.845631,
    altitude: 273.1008
  },
  {
    iataCode: 'CDP',
    icaoCode: 'VOCP',
    name: 'Cuddapah',
    country: {code: 'IN', name: 'India'},
    city: 'Cuddapah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 14.509961,
    longitude: 78.772833,
    altitude: 131.064
  },
  {
    iataCode: 'KTW',
    icaoCode: 'EPKT',
    name: 'Katowice',
    country: {code: 'PL', name: 'Poland'},
    city: 'Pyrzowice',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 50.474253,
    longitude: 19.080019,
    altitude: 303.276
  },
  {
    iataCode: 'CDR',
    icaoCode: 'KCDR',
    name: 'Chadron',
    country: {code: 'US', name: 'United States'},
    city: 'Chadron Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 42.8375,
    longitude: -103.095556,
    altitude: 1004.9256
  },
  {
    iataCode: 'CDS',
    icaoCode: 'KCDS',
    name: 'Childress',
    country: {code: 'US', name: 'United States'},
    city: 'Childress Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.433781,
    longitude: -100.287992,
    altitude: 595.5792
  },
  {
    iataCode: 'CDV',
    icaoCode: 'PACV',
    name: 'Cordova',
    country: {code: 'US', name: 'United States'},
    city: 'Merle K Mudhole Smith',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.491778,
    longitude: -145.477556,
    altitude: 14.6304
  },
  {
    iataCode: 'CDU',
    icaoCode: 'YSCN',
    name: 'Camden',
    country: {code: 'AU', name: 'Australia'},
    city: 'Camden',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.040278,
    longitude: 150.687222,
    altitude: 70.104
  },
  {
    iataCode: 'TFF',
    icaoCode: 'SBTF',
    name: 'Tefe',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Tefe',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -3.382944,
    longitude: -64.724056,
    altitude: 56.0832
  },
  {
    iataCode: 'KUA',
    icaoCode: 'WMKD',
    name: 'Kuantan',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kuantan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 3.775389,
    longitude: 103.209056,
    altitude: 17.6784
  },
  {
    iataCode: 'KUC',
    icaoCode: 'NGKT',
    name: 'Kuria',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Kuria Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: 0.228611,
    longitude: 173.410556,
    altitude: 3.048
  },
  {
    iataCode: 'KUD',
    icaoCode: 'WBKT',
    name: 'Kudat',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kudat Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 6.9225,
    longitude: 116.836,
    altitude: 3.048
  },
  {
    iataCode: 'KUG',
    icaoCode: 'YKUB',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kubin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 540,
    latitude: -10.225,
    longitude: 142.218,
    altitude: 4.572
  },
  {
    iataCode: 'TFN',
    icaoCode: 'GCXO',
    name: 'Tenerife',
    country: {code: 'ES', name: 'Spain'},
    city: 'Tenerife Norte',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.482653,
    longitude: -16.341536,
    altitude: 631.8504
  },
  {
    iataCode: 'KUH',
    icaoCode: 'RJCK',
    name: '',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kushiro Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 43.041,
    longitude: 144.193,
    altitude: 99.6696
  },
  {
    iataCode: 'CEC',
    icaoCode: 'KCEC',
    name: 'Crescent City',
    country: {code: 'US', name: 'United States'},
    city: 'Del Norte County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 41.7802,
    longitude: -124.237,
    altitude: 17.3736
  },
  {
    iataCode: 'KUK',
    icaoCode: 'PFKA',
    name: 'Kasigluk',
    country: {code: 'US', name: 'United States'},
    city: 'Kasigluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 60.873333,
    longitude: -162.524444,
    altitude: 12.192
  },
  {
    iataCode: 'TFS',
    icaoCode: 'GCTS',
    name: 'Tenerife',
    country: {code: 'ES', name: 'Spain'},
    city: 'Tenerife Sur',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.044475,
    longitude: -16.572489,
    altitude: 63.7032
  },
  {
    iataCode: 'CEB',
    icaoCode: 'RPVM',
    name: 'Cebu',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Mactan Cebu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 10.307542,
    longitude: 123.979439,
    altitude: 9.4488
  },
  {
    iataCode: 'CEE',
    icaoCode: 'ULBC',
    name: 'Cherepovets',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Cherepovets Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 59.2736,
    longitude: 38.0158,
    altitude: 114.9096
  },
  {
    iataCode: 'KUM',
    icaoCode: 'RJFC',
    name: 'Yakushima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yakushima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 30.385569,
    longitude: 130.659017,
    altitude: 37.7952
  },
  {
    iataCode: 'CED',
    icaoCode: 'YCDU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Ceduna Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -32.1306,
    longitude: 133.71,
    altitude: 23.4696
  },
  {
    iataCode: 'KUL',
    icaoCode: 'WMKK',
    name: 'Kuala Lumpur',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Kuala Lumpur Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 2.745578,
    longitude: 101.709917,
    altitude: 21.0312
  },
  {
    iataCode: 'CEG',
    icaoCode: 'EGNR',
    name: 'Hawarden',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Hawarden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.178056,
    longitude: -2.977778,
    altitude: 13.716
  },
  {
    iataCode: 'KUO',
    icaoCode: 'EFKU',
    name: 'Kuopio',
    country: {code: 'FI', name: 'Finland'},
    city: 'Kuopio',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 63.00715,
    longitude: 27.797756,
    altitude: 98.4504
  },
  {
    iataCode: 'CEF',
    icaoCode: 'KCEF',
    name: 'Chicopee Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Westover Arb Metropolitan',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.194014,
    longitude: -72.534783,
    altitude: 73.4568
  },
  {
    iataCode: 'KUN',
    icaoCode: 'EYKA',
    name: 'Kaunas',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Kaunas Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 54.963919,
    longitude: 24.084778,
    altitude: 78.0288
  },
  {
    iataCode: 'CEI',
    icaoCode: 'VTCT',
    name: 'Chiang Rai',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Chiang Rai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 19.952342,
    longitude: 99.882928,
    altitude: 390.144
  },
  {
    iataCode: 'CEK',
    icaoCode: 'USCC',
    name: 'Chelyabinsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Balandino',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 55.305836,
    longitude: 61.503333,
    altitude: 234.3912
  },
  {
    iataCode: 'CEJ',
    icaoCode: 'UKRR',
    name: 'Chernigov',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Chernigov',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 51.24,
    longitude: 31.09,
    altitude: 135.9408
  },
  {
    iataCode: 'CEM',
    icaoCode: 'PACE',
    name: 'Central',
    country: {code: 'US', name: 'United States'},
    city: 'Central Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 65.573889,
    longitude: -144.780833,
    altitude: 285.5976
  },
  {
    iataCode: 'KUU',
    icaoCode: 'VIBR',
    name: 'Kulu',
    country: {code: 'IN', name: 'India'},
    city: 'Kullu Manali',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 31.876706,
    longitude: 77.154367,
    altitude: 1089.0504
  },
  {
    iataCode: 'KUT',
    icaoCode: 'UGKO',
    name: 'Kutaisi',
    country: {code: 'GE', name: 'Georgia'},
    city: 'Kopitnari',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tbilisi',
    utcOffset: 240,
    latitude: 42.176653,
    longitude: 42.482583,
    altitude: 67.9704
  },
  {
    iataCode: 'CEN',
    icaoCode: 'MMCN',
    name: 'Ciudad Obregon',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ciudad Obregon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 27.392639,
    longitude: -109.833111,
    altitude: 62.484
  },
  {
    iataCode: 'KUV',
    icaoCode: 'RKJK',
    name: 'Kunsan',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Kunsan Air Base',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.903756,
    longitude: 126.615906,
    altitude: 8.8392
  },
  {
    iataCode: 'CEQ',
    icaoCode: 'LFMD',
    name: 'Cannes',
    country: {code: 'FR', name: 'France'},
    city: 'Mandelieu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.54205,
    longitude: 6.953478,
    altitude: 3.9624
  },
  {
    iataCode: 'CES',
    icaoCode: 'YCNK',
    name: 'Cessnock',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cessnock Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.7875,
    longitude: 151.342,
    altitude: 64.3128
  },
  {
    iataCode: 'TGD',
    icaoCode: 'LYPG',
    name: 'Podgorica',
    country: {code: 'ME', name: 'Montenegro'},
    city: 'Podgorica',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Podgorica',
    utcOffset: 60,
    latitude: 42.359392,
    longitude: 19.251894,
    altitude: 42.9768
  },
  {
    iataCode: 'CER',
    icaoCode: 'LFRC',
    name: 'Cherbourg',
    country: {code: 'FR', name: 'France'},
    city: 'Maupertus',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.650106,
    longitude: -1.470281,
    altitude: 139.9032
  },
  {
    iataCode: 'TGC',
    icaoCode: 'WBTM',
    name: 'Tanjung Manis',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Tanjung Manis Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 2.17784,
    longitude: 111.202,
    altitude: 4.572
  },
  {
    iataCode: 'CET',
    icaoCode: 'LFOU',
    name: 'Cholet',
    country: {code: 'FR', name: 'France'},
    city: 'Le Pontreau',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.082136,
    longitude: -0.877064,
    altitude: 135.0264
  },
  {
    iataCode: 'CEW',
    icaoCode: 'KCEW',
    name: 'Crestview',
    country: {code: 'US', name: 'United States'},
    city: 'Bob Sikes',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.778833,
    longitude: -86.522111,
    altitude: 64.9224
  },
  {
    iataCode: 'TGH',
    icaoCode: 'NVST',
    name: 'Tongoa Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Tongoa Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.8911,
    longitude: 168.551,
    altitude: 135.0264
  },
  {
    iataCode: 'TGG',
    icaoCode: 'WMKN',
    name: 'Kuala Terengganu',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Mahmud',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 5.382639,
    longitude: 103.10336,
    altitude: 6.4008
  },
  {
    iataCode: 'KVB',
    icaoCode: 'ESGR',
    name: 'Skovde',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Skovde',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.4564,
    longitude: 13.972672,
    altitude: 98.7552
  },
  {
    iataCode: 'TGJ',
    icaoCode: 'NWWA',
    name: 'Tiga',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Tiga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -21.0961,
    longitude: 167.804,
    altitude: 39.0144
  },
  {
    iataCode: 'KVA',
    icaoCode: 'LGKV',
    name: 'Kavala',
    country: {code: 'GR', name: 'Greece'},
    city: 'Megas Alexandros Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 40.913306,
    longitude: 24.619223,
    altitude: 5.4864
  },
  {
    iataCode: 'KVD',
    icaoCode: 'UBBG',
    name: 'Ganja',
    country: {code: 'AZ', name: 'Azerbaijan'},
    city: 'Ganja Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baku',
    utcOffset: 240,
    latitude: 40.7377,
    longitude: 46.3176,
    altitude: 330.0984
  },
  {
    iataCode: 'CEZ',
    icaoCode: 'KCEZ',
    name: 'Cortez',
    country: {code: 'US', name: 'United States'},
    city: 'Cortez Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.303,
    longitude: -108.628056,
    altitude: 1803.8064
  },
  {
    iataCode: 'KVC',
    icaoCode: 'PAVC',
    name: 'King Cove',
    country: {code: 'US', name: 'United States'},
    city: 'King Cove Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 55.1163,
    longitude: -162.266,
    altitude: 47.244
  },
  {
    iataCode: 'TGM',
    icaoCode: 'LRTM',
    name: 'Tirgu Mures',
    country: {code: 'RO', name: 'Romania'},
    city: 'Transilvania Targu Mures',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 46.467714,
    longitude: 24.412525,
    altitude: 293.5224
  },
  {
    iataCode: 'KVG',
    icaoCode: 'AYKV',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Kavieng Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -2.5794,
    longitude: 150.808,
    altitude: 2.1336
  },
  {
    iataCode: 'TGO',
    icaoCode: 'ZBTL',
    name: 'Tongliao',
    country: {code: 'CN', name: 'China'},
    city: 'Tongliao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 43.5567,
    longitude: 122.2,
    altitude: 0.0
  },
  {
    iataCode: 'CFB',
    icaoCode: 'SBCB',
    name: 'Cabo Frio',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Cabo Frio International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.921667,
    longitude: -42.074167,
    altitude: 4.2672
  },
  {
    iataCode: 'TGR',
    icaoCode: 'DAUK',
    name: 'Touggourt',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Sidi Mahdi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 33.067803,
    longitude: 6.088672,
    altitude: 85.0392
  },
  {
    iataCode: 'CFD',
    icaoCode: 'KCFD',
    name: 'Bryan',
    country: {code: 'US', name: 'United States'},
    city: 'Coulter Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.715694,
    longitude: -96.331361,
    altitude: 111.8616
  },
  {
    iataCode: 'KVL',
    icaoCode: 'PAVL',
    name: 'Kivalina',
    country: {code: 'US', name: 'United States'},
    city: 'Kivalina Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 67.7362,
    longitude: -164.563,
    altitude: 3.9624
  },
  {
    iataCode: 'TGT',
    icaoCode: 'HTTG',
    name: 'Tanga',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Tanga',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -5.092358,
    longitude: 39.071158,
    altitude: 39.3192
  },
  {
    iataCode: 'KVK',
    icaoCode: 'ULMK',
    name: 'Apatity',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kirovsk-apatity Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 67.4633,
    longitude: 33.5883,
    altitude: 156.972
  },
  {
    iataCode: 'CFE',
    icaoCode: 'LFLC',
    name: 'Clermont Ferrand',
    country: {code: 'FR', name: 'France'},
    city: 'Auvergne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.786661,
    longitude: 3.169169,
    altitude: 332.232
  },
  {
    iataCode: 'TGU',
    icaoCode: 'MHTG',
    name: 'Tegucigalpa',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Toncontin Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 14.060883,
    longitude: -87.217197,
    altitude: 1004.0112
  },
  {
    iataCode: 'CFG',
    icaoCode: 'MUCF',
    name: 'Cienfuegos',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Jaime Gonzalez',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.15,
    longitude: -80.414167,
    altitude: 31.0896
  },
  {
    iataCode: 'TGZ',
    icaoCode: 'MMTG',
    name: 'Tuxtla Gutierrez',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Angel Albino Corzo',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 16.561822,
    longitude: -93.026081,
    altitude: 454.4568
  },
  {
    iataCode: 'CFN',
    icaoCode: 'EIDL',
    name: 'Dongloe',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Donegal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 55.044192,
    longitude: -8.341,
    altitude: 9.144
  },
  {
    iataCode: 'KVX',
    icaoCode: 'USKK',
    name: 'Kirov',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Pobedilovo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 240,
    latitude: 58.5033,
    longitude: 49.3483,
    altitude: 145.9992
  },
  {
    iataCode: 'CFR',
    icaoCode: 'LFRK',
    name: 'Caen',
    country: {code: 'FR', name: 'France'},
    city: 'Carpiquet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.173333,
    longitude: -0.45,
    altitude: 78.0288
  },
  {
    iataCode: 'THE',
    icaoCode: 'SBTE',
    name: 'Teresina',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Senador Petronio Portella',
    region: '',
    terminal: '',
    timezoneId: 'America/Fortaleza',
    utcOffset: -180,
    latitude: -5.059942,
    longitude: -42.823478,
    altitude: 66.7512
  },
  {
    iataCode: 'CFS',
    icaoCode: 'YSCH',
    name: "Coff's Harbour",
    country: {code: 'AU', name: 'Australia'},
    city: 'Coffs Harbour',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -30.320556,
    longitude: 153.116389,
    altitude: 5.4864
  },
  {
    iataCode: 'THG',
    icaoCode: 'YTNG',
    name: 'Biloela',
    country: {code: 'AU', name: 'Australia'},
    city: 'Thangool',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -24.493889,
    longitude: 150.576111,
    altitude: 196.2912
  },
  {
    iataCode: 'CFU',
    icaoCode: 'LGKR',
    name: 'Kerkyra/corfu',
    country: {code: 'GR', name: 'Greece'},
    city: 'Ioannis Kapodistrias Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.601944,
    longitude: 19.911667,
    altitude: 1.8288
  },
  {
    iataCode: 'THF',
    icaoCode: 'EDDI',
    name: 'Berlin',
    country: {code: 'DE', name: 'Germany'},
    city: 'Tempelhof',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.473025,
    longitude: 13.403944,
    altitude: 50.9016
  },
  {
    iataCode: 'KWA',
    icaoCode: 'PKWA',
    name: 'Kwajalein',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Bucholz Aaf',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Kwajalein',
    utcOffset: 720,
    latitude: 8.720122,
    longitude: 167.731661,
    altitude: 2.7432
  },
  {
    iataCode: 'KWE',
    icaoCode: 'ZUGY',
    name: 'Guiyang',
    country: {code: 'CN', name: 'China'},
    city: 'Longdongbao',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 26.538522,
    longitude: 106.800703,
    altitude: 1138.7328
  },
  {
    iataCode: 'THL',
    icaoCode: 'VYTL',
    name: 'Tachilek',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Tachileik',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 420,
    latitude: 20.483831,
    longitude: 99.935353,
    altitude: 390.144
  },
  {
    iataCode: 'KWG',
    icaoCode: 'UKDR',
    name: '',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Lozuvatka International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 48.0433,
    longitude: 33.21,
    altitude: 124.3584
  },
  {
    iataCode: 'THO',
    icaoCode: 'BITN',
    name: 'Thorshofn',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Thorshofn Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 66.2185,
    longitude: -15.3356,
    altitude: 19.812
  },
  {
    iataCode: 'THN',
    icaoCode: 'ESGT',
    name: 'Trollhattan',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Trollhattan Vanersborg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.318056,
    longitude: 12.345,
    altitude: 41.7576
  },
  {
    iataCode: 'KWI',
    icaoCode: 'OKBK',
    name: 'Kuwait',
    country: {code: 'KW', name: 'Kuwait'},
    city: 'Kuwait Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuwait',
    utcOffset: 180,
    latitude: 29.226567,
    longitude: 47.968928,
    altitude: 62.7888
  },
  {
    iataCode: 'KWK',
    icaoCode: 'PAGG',
    name: 'Kwigillingok',
    country: {code: 'US', name: 'United States'},
    city: 'Kwigillingok Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 59.876389,
    longitude: -163.168611,
    altitude: 5.4864
  },
  {
    iataCode: 'THS',
    icaoCode: 'VTPO',
    name: 'Sukhothai',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Sukhothai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 17.237992,
    longitude: 99.818183,
    altitude: 54.5592
  },
  {
    iataCode: 'CGB',
    icaoCode: 'SBCY',
    name: 'Cuiaba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Marechal Rondon',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -15.652931,
    longitude: -56.116719,
    altitude: 188.0616
  },
  {
    iataCode: 'KWJ',
    icaoCode: 'RKJJ',
    name: 'Kwangju',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Gwangju',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.126389,
    longitude: 126.808889,
    altitude: 11.8872
  },
  {
    iataCode: 'THR',
    icaoCode: 'OIII',
    name: 'Teheran',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Mehrabad Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 35.689167,
    longitude: 51.313416,
    altitude: 1207.6176
  },
  {
    iataCode: 'KWM',
    icaoCode: 'YKOW',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Kowanyama Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -15.4856,
    longitude: 141.751,
    altitude: 10.668
  },
  {
    iataCode: 'THU',
    icaoCode: 'BGTL',
    name: 'Thule',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Thule Air Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Thule',
    utcOffset: -240,
    latitude: 76.531203,
    longitude: -68.703161,
    altitude: 76.5048
  },
  {
    iataCode: 'CGD',
    icaoCode: 'ZGCD',
    name: 'Changde',
    country: {code: 'CN', name: 'China'},
    city: 'Changde Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 28.9189,
    longitude: 111.64,
    altitude: 0.0
  },
  {
    iataCode: 'KWL',
    icaoCode: 'ZGKL',
    name: 'Guilin',
    country: {code: 'CN', name: 'China'},
    city: 'Liangjiang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 25.218106,
    longitude: 110.039197,
    altitude: 173.736
  },
  {
    iataCode: 'CGF',
    icaoCode: 'KCGF',
    name: 'Richmond Heights',
    country: {code: 'US', name: 'United States'},
    city: 'Cuyahoga County',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.565124,
    longitude: -81.486356,
    altitude: 267.9192
  },
  {
    iataCode: 'KWN',
    icaoCode: 'PAQH',
    name: 'Quinhagak',
    country: {code: 'US', name: 'United States'},
    city: 'Quinhagak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.755,
    longitude: -161.845278,
    altitude: 12.8016
  },
  {
    iataCode: 'CGI',
    icaoCode: 'KCGI',
    name: 'Cape Girardeau',
    country: {code: 'US', name: 'United States'},
    city: 'Cape Girardeau Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.2253,
    longitude: -89.5708,
    altitude: 104.2416
  },
  {
    iataCode: 'CGH',
    icaoCode: 'SBSP',
    name: 'Sao Paulo',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Congonhas',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.626692,
    longitude: -46.655375,
    altitude: 801.9288
  },
  {
    iataCode: 'CGK',
    icaoCode: 'WIII',
    name: 'Jakarta',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Soekarno Hatta Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.125567,
    longitude: 106.655897,
    altitude: 10.3632
  },
  {
    iataCode: 'THZ',
    icaoCode: 'DRRT',
    name: 'Tahoua',
    country: {code: 'NE', name: 'Niger'},
    city: 'Tahoua',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Niamey',
    utcOffset: 60,
    latitude: 14.875658,
    longitude: 5.265358,
    altitude: 385.8768
  },
  {
    iataCode: 'CGM',
    icaoCode: 'RPMH',
    name: '',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Camiguin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.25352,
    longitude: 124.707,
    altitude: 16.1544
  },
  {
    iataCode: 'KWT',
    icaoCode: 'PFKW',
    name: 'Kwethluk',
    country: {code: 'US', name: 'United States'},
    city: 'Kwethluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.790278,
    longitude: -161.443611,
    altitude: 9.144
  },
  {
    iataCode: 'CGO',
    icaoCode: 'ZHCC',
    name: 'Zhengzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Xinzheng',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 34.519672,
    longitude: 113.840889,
    altitude: 150.876
  },
  {
    iataCode: 'CGN',
    icaoCode: 'EDDK',
    name: 'Cologne',
    country: {code: 'DE', name: 'Germany'},
    city: 'Koln Bonn',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.865917,
    longitude: 7.142744,
    altitude: 92.0496
  },
  {
    iataCode: 'CGQ',
    icaoCode: 'ZYCC',
    name: 'Changchun',
    country: {code: 'CN', name: 'China'},
    city: 'Changchun',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 43.5412,
    longitude: 125.1201,
    altitude: 69.1896
  },
  {
    iataCode: 'CGP',
    icaoCode: 'VGEG',
    name: 'Chittagong',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Shah Amanat Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 22.249611,
    longitude: 91.813286,
    altitude: 3.6576
  },
  {
    iataCode: 'TIA',
    icaoCode: 'LATI',
    name: 'Tirana',
    country: {code: 'AL', name: 'Albania'},
    city: 'Tirana Rinas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tirane',
    utcOffset: 60,
    latitude: 41.414742,
    longitude: 19.720561,
    altitude: 38.4048
  },
  {
    iataCode: 'TID',
    icaoCode: 'DAOB',
    name: 'Tiaret',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Bou Chekif',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.341136,
    longitude: 1.463147,
    altitude: 989.076
  },
  {
    iataCode: 'CGR',
    icaoCode: 'SBCG',
    name: 'Campo Grande',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Campo Grande',
    region: '',
    terminal: '',
    timezoneId: 'America/Campo_Grande',
    utcOffset: -240,
    latitude: -20.468667,
    longitude: -54.6725,
    altitude: 558.6984
  },
  {
    iataCode: 'KWZ',
    icaoCode: 'FZQM',
    name: 'Kolwezi',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kolwezi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -10.765886,
    longitude: 25.505714,
    altitude: 1526.1336
  },
  {
    iataCode: 'TIF',
    icaoCode: 'OETF',
    name: 'Taif',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Taif',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 21.483418,
    longitude: 40.544334,
    altitude: 1477.6704
  },
  {
    iataCode: 'TIE',
    icaoCode: 'HATP',
    name: 'Tippi',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Tippi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 7.117,
    longitude: 35.383,
    altitude: 335.28
  },
  {
    iataCode: 'TIH',
    icaoCode: 'NTGC',
    name: 'Tikehau',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Tikehau',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -15.119617,
    longitude: -148.230697,
    altitude: 1.8288
  },
  {
    iataCode: 'CGY',
    icaoCode: 'RPML',
    name: 'Ladag',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Cagayan De Oro',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 8.415619,
    longitude: 124.611219,
    altitude: 183.1848
  },
  {
    iataCode: 'TIJ',
    icaoCode: 'MMTJ',
    name: 'Tijuana',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Abelardo L Rodriguez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tijuana',
    utcOffset: -480,
    latitude: 32.541064,
    longitude: -116.970158,
    altitude: 149.0472
  },
  {
    iataCode: 'TII',
    icaoCode: 'OATN',
    name: 'Tarin Kowt',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Tarin Kowt Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 240,
    latitude: 32.605278,
    longitude: 65.864167,
    altitude: 1066.8
  },
  {
    iataCode: 'TIK',
    icaoCode: 'KTIK',
    name: 'Oklahoma City',
    country: {code: 'US', name: 'United States'},
    city: 'Tinker Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.414739,
    longitude: -97.386633,
    altitude: 393.4968
  },
  {
    iataCode: 'KXF',
    icaoCode: 'NFNO',
    name: 'Koro Island',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Koro Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.3458,
    longitude: 179.422,
    altitude: 109.1184
  },
  {
    iataCode: 'TIN',
    icaoCode: 'DAOF',
    name: 'Tindouf',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Tindouf',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 27.700372,
    longitude: -8.167103,
    altitude: 442.8744
  },
  {
    iataCode: 'TIM',
    icaoCode: 'WABP',
    name: 'Timika',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Moses Kilangin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -4.528275,
    longitude: 136.887375,
    altitude: 31.3944
  },
  {
    iataCode: 'TIP',
    icaoCode: 'HLLT',
    name: 'Tripoli',
    country: {code: 'LY', name: 'Libya'},
    city: 'Tripoli Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 32.663544,
    longitude: 13.159011,
    altitude: 80.1624
  },
  {
    iataCode: 'TIR',
    icaoCode: 'VOTP',
    name: 'Tirupeti',
    country: {code: 'IN', name: 'India'},
    city: 'Tirupati',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 13.632492,
    longitude: 79.543256,
    altitude: 106.68
  },
  {
    iataCode: 'CHA',
    icaoCode: 'KCHA',
    name: 'Chattanooga',
    country: {code: 'US', name: 'United States'},
    city: 'Lovell Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.035278,
    longitude: -85.203808,
    altitude: 208.1784
  },
  {
    iataCode: 'TIQ',
    icaoCode: 'PGWT',
    name: 'West Tinian',
    country: {code: 'MP', name: 'Northern Mariana Islands'},
    city: 'Tinian Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Saipan',
    utcOffset: 600,
    latitude: 14.999203,
    longitude: 145.61935,
    altitude: 82.6008
  },
  {
    iataCode: 'CHC',
    icaoCode: 'NZCH',
    name: 'Christchurch',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Christchurch Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -43.489358,
    longitude: 172.532225,
    altitude: 37.4904
  },
  {
    iataCode: 'KXK',
    icaoCode: 'UHKK',
    name: 'Komsomolsk-on-amur',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Komsomolsk-on-amur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vladivostok',
    utcOffset: 600,
    latitude: 50.4094,
    longitude: 136.934,
    altitude: 28.0416
  },
  {
    iataCode: 'TIV',
    icaoCode: 'LYTV',
    name: 'Tivat',
    country: {code: 'ME', name: 'Montenegro'},
    city: 'Tivat',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Podgorica',
    utcOffset: 60,
    latitude: 42.404664,
    longitude: 18.723286,
    altitude: 6.096
  },
  {
    iataCode: 'TIU',
    icaoCode: 'NZTU',
    name: 'Timaru',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Timaru',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -44.302778,
    longitude: 171.225278,
    altitude: 27.1272
  },
  {
    iataCode: 'CHH',
    icaoCode: 'SPPY',
    name: 'Chachapoyas',
    country: {code: 'PE', name: 'Peru'},
    city: 'Chachapoyas',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -6.201806,
    longitude: -77.856064,
    altitude: 2539.8984
  },
  {
    iataCode: 'CHG',
    icaoCode: 'ZYCY',
    name: 'Chaoyang',
    country: {code: 'CN', name: 'China'},
    city: 'Chaoyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 41.5381,
    longitude: 120.435,
    altitude: 0.0
  },
  {
    iataCode: 'TIZ',
    icaoCode: 'AYTA',
    name: 'Tari',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Tari Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.845,
    longitude: 142.948,
    altitude: 1676.4
  },
  {
    iataCode: 'TIY',
    icaoCode: 'GQND',
    name: 'Tidjikja',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Tidjikja',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 18.570103,
    longitude: -11.423547,
    altitude: 401.1168
  },
  {
    iataCode: 'CHM',
    icaoCode: 'SPEO',
    name: 'Chimbote',
    country: {code: 'PE', name: 'Peru'},
    city: 'Teniente Jaime A De Montreuil Morales',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -9.149614,
    longitude: -78.52385,
    altitude: 21.0312
  },
  {
    iataCode: 'TJA',
    icaoCode: 'SLTJ',
    name: 'Tarija',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Capitan Oriel Lea Plaza',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -21.555736,
    longitude: -64.701325,
    altitude: 1854.4032
  },
  {
    iataCode: 'CHO',
    icaoCode: 'KCHO',
    name: 'Charlottesville Va',
    country: {code: 'US', name: 'United States'},
    city: 'Charlottesville-albemarle',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.138639,
    longitude: -78.452861,
    altitude: 194.7672
  },
  {
    iataCode: 'CHR',
    icaoCode: 'LFLX',
    name: 'Chateauroux',
    country: {code: 'FR', name: 'France'},
    city: 'Deols',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.862194,
    longitude: 1.730667,
    altitude: 161.2392
  },
  {
    iataCode: 'CHQ',
    icaoCode: 'LGSA',
    name: 'Chania',
    country: {code: 'GR', name: 'Greece'},
    city: 'Souda',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 35.531747,
    longitude: 24.149678,
    altitude: 149.352
  },
  {
    iataCode: 'CHT',
    icaoCode: 'NZCI',
    name: 'Chatham Island',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Chatham Islands',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Chatham',
    utcOffset: 780,
    latitude: -43.81,
    longitude: -176.457222,
    altitude: 13.1064
  },
  {
    iataCode: 'CHS',
    icaoCode: 'KCHS',
    name: 'Charleston',
    country: {code: 'US', name: 'United States'},
    city: 'Charleston Afb Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.898647,
    longitude: -80.040528,
    altitude: 13.716
  },
  {
    iataCode: 'TJG',
    icaoCode: 'WAON',
    name: 'Tanjung-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Warukin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 480,
    latitude: -2.21656,
    longitude: 115.436,
    altitude: 60.0456
  },
  {
    iataCode: 'CHU',
    icaoCode: 'PACH',
    name: 'Chuathbaluk',
    country: {code: 'US', name: 'United States'},
    city: 'Chuathbaluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.579167,
    longitude: -159.215556,
    altitude: 74.0664
  },
  {
    iataCode: 'CHX',
    icaoCode: 'MPCH',
    name: 'Changuinola',
    country: {code: 'PA', name: 'Panama'},
    city: 'Cap Manuel Nino Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 9.458636,
    longitude: -82.516806,
    altitude: 5.7912
  },
  {
    iataCode: 'KYA',
    icaoCode: 'LTAN',
    name: 'Konya',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Konya',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.979,
    longitude: 32.561861,
    altitude: 1030.5288
  },
  {
    iataCode: 'TJH',
    icaoCode: 'RJBT',
    name: 'Toyooka',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tajima Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.512778,
    longitude: 134.786944,
    altitude: 176.1744
  },
  {
    iataCode: 'CHY',
    icaoCode: 'AGGC',
    name: 'Choiseul Bay',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Choiseul Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -6.711944,
    longitude: 156.396111,
    altitude: 1.524
  },
  {
    iataCode: 'TJM',
    icaoCode: 'USTR',
    name: 'Tyumen',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Roschino',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 57.189567,
    longitude: 65.3243,
    altitude: 115.2144
  },
  {
    iataCode: 'KYD',
    icaoCode: 'RCLY',
    name: 'Lanyu',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Lanyu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.028842,
    longitude: 121.533642,
    altitude: 13.4112
  },
  {
    iataCode: 'CIA',
    icaoCode: 'LIRA',
    name: 'Rome',
    country: {code: 'IT', name: 'Italy'},
    city: 'Ciampino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 41.799361,
    longitude: 12.594936,
    altitude: 130.1496
  },
  {
    iataCode: 'TJQ',
    icaoCode: 'WIOD',
    name: 'Tanjung Pandan',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'H As Hanandjoeddin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -2.745722,
    longitude: 107.754917,
    altitude: 49.9872
  },
  {
    iataCode: 'CIC',
    icaoCode: 'KCIC',
    name: 'Chico',
    country: {code: 'US', name: 'United States'},
    city: 'Chico Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 39.795383,
    longitude: -121.858422,
    altitude: 72.5424
  },
  {
    iataCode: 'KYK',
    icaoCode: 'PAKY',
    name: 'Karluk',
    country: {code: 'US', name: 'United States'},
    city: 'Karuluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 57.566944,
    longitude: -154.450278,
    altitude: 41.7576
  },
  {
    iataCode: 'TJS',
    icaoCode: 'WALG',
    name: 'Tanjung Selor-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Tanjung Harapan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 2.83641,
    longitude: 117.374,
    altitude: 3.048
  },
  {
    iataCode: 'TJU',
    icaoCode: 'UTDK',
    name: 'Kulyab',
    country: {code: 'TJ', name: 'Tajikistan'},
    city: 'Kulob Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dushanbe',
    utcOffset: 300,
    latitude: 37.981667,
    longitude: 69.799444,
    altitude: 698.9064
  },
  {
    iataCode: 'CID',
    icaoCode: 'KCID',
    name: 'Cedar Rapids',
    country: {code: 'US', name: 'United States'},
    city: 'Cedar Rapids',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.884694,
    longitude: -91.710806,
    altitude: 264.8712
  },
  {
    iataCode: 'CIF',
    icaoCode: 'ZBCF',
    name: 'Chifeng',
    country: {code: 'CN', name: 'China'},
    city: 'Chifeng Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 42.235,
    longitude: 118.908,
    altitude: 0.0
  },
  {
    iataCode: 'CIH',
    icaoCode: 'ZBCZ',
    name: 'Changzhi',
    country: {code: 'CN', name: 'China'},
    city: 'Changzhi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 36.2475,
    longitude: 113.126,
    altitude: 0.0
  },
  {
    iataCode: 'KYP',
    icaoCode: 'VYKP',
    name: 'Kyaukpyu',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Kyaukpyu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 19.426447,
    longitude: 93.534836,
    altitude: 6.096
  },
  {
    iataCode: 'CIK',
    icaoCode: 'PACI',
    name: 'Chalkyitsik',
    country: {code: 'US', name: 'United States'},
    city: 'Chalkyitsik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.645,
    longitude: -143.74,
    altitude: 165.8112
  },
  {
    iataCode: 'KYS',
    icaoCode: 'GAKY',
    name: 'Kayes',
    country: {code: 'ML', name: 'Mali'},
    city: 'Kayes Dag Dag',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bamako',
    utcOffset: 0,
    latitude: 14.481233,
    longitude: -11.404397,
    altitude: 49.9872
  },
  {
    iataCode: 'CIJ',
    icaoCode: 'SLCO',
    name: 'Cobija',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Heroes Del Acre',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -11.040436,
    longitude: -68.782972,
    altitude: 271.8816
  },
  {
    iataCode: 'KYU',
    icaoCode: 'PFKU',
    name: 'Koyukuk',
    country: {code: 'US', name: 'United States'},
    city: 'Koyukuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.875833,
    longitude: -157.730556,
    altitude: 45.4152
  },
  {
    iataCode: 'CIP',
    icaoCode: 'FLCP',
    name: 'Chipata',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Chipata Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -13.5583,
    longitude: 32.5872,
    altitude: 1024.128
  },
  {
    iataCode: 'TKA',
    icaoCode: 'PATK',
    name: 'Talkeetna',
    country: {code: 'US', name: 'United States'},
    city: 'Talkeetna',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.3205,
    longitude: -150.093694,
    altitude: 109.1184
  },
  {
    iataCode: 'CIS',
    icaoCode: 'PCIS',
    name: 'Canton Island',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Canton',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Enderbury',
    utcOffset: -660,
    latitude: -2.768122,
    longitude: -171.710394,
    altitude: 2.7432
  },
  {
    iataCode: 'TKD',
    icaoCode: 'DGTK',
    name: 'Takoradi',
    country: {code: 'GH', name: 'Ghana'},
    city: 'Takoradi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Accra',
    utcOffset: 0,
    latitude: 4.896056,
    longitude: -1.774756,
    altitude: 6.4008
  },
  {
    iataCode: 'KYZ',
    icaoCode: 'UNKY',
    name: 'Kyzyl',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kyzyl Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 51.6694,
    longitude: 94.4006,
    altitude: 647.0904
  },
  {
    iataCode: 'TKC',
    icaoCode: 'FKKC',
    name: 'Tiko',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Tiko',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 4.089192,
    longitude: 9.360528,
    altitude: 46.0248
  },
  {
    iataCode: 'CIU',
    icaoCode: 'KCIU',
    name: 'Sault Ste Marie',
    country: {code: 'US', name: 'United States'},
    city: 'Chippewa County International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 46.2508,
    longitude: -84.4724,
    altitude: 243.84
  },
  {
    iataCode: 'CIT',
    icaoCode: 'UAII',
    name: 'Chimkent',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Shymkent',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 42.364167,
    longitude: 69.478889,
    altitude: 422.148
  },
  {
    iataCode: 'CIW',
    icaoCode: 'TVSC',
    name: 'Canouan Island',
    country: {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    city: 'Canouan',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Vincent',
    utcOffset: -240,
    latitude: 12.699042,
    longitude: -61.342431,
    altitude: 3.3528
  },
  {
    iataCode: 'TKG',
    icaoCode: 'WICT',
    name: 'Bandar Lampung-sumatra Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Radin Inten Ii (Branti) Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -5.242339,
    longitude: 105.178939,
    altitude: 85.9536
  },
  {
    iataCode: 'CIX',
    icaoCode: 'SPHI',
    name: 'Chiclayo',
    country: {code: 'PE', name: 'Peru'},
    city: 'Capt Jose A Quinones Gonzales Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -6.787475,
    longitude: -79.828097,
    altitude: 29.5656
  },
  {
    iataCode: 'CIZ',
    icaoCode: 'SWKO',
    name: 'Coari',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Coari Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -4.085,
    longitude: -63.140833,
    altitude: 10.0584
  },
  {
    iataCode: 'TKK',
    icaoCode: 'PTKK',
    name: 'Chuuk',
    country: {code: 'FM', name: 'Federated States of Micronesia'},
    city: 'Chuuk Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Chuuk',
    utcOffset: 600,
    latitude: 7.461869,
    longitude: 151.843006,
    altitude: 3.3528
  },
  {
    iataCode: 'TKN',
    icaoCode: 'RJKN',
    name: 'Tokunoshima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tokunoshima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 27.836381,
    longitude: 128.881253,
    altitude: 5.1816
  },
  {
    iataCode: 'TKP',
    icaoCode: 'NTGT',
    name: 'Takapoto',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Takapoto',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.709544,
    longitude: -145.245814,
    altitude: 3.6576
  },
  {
    iataCode: 'CJB',
    icaoCode: 'VOCB',
    name: 'Coimbatore',
    country: {code: 'IN', name: 'India'},
    city: 'Coimbatore',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 11.030031,
    longitude: 77.043383,
    altitude: 403.5552
  },
  {
    iataCode: 'CJA',
    icaoCode: 'SPJR',
    name: 'Cajamarca',
    country: {code: 'PE', name: 'Peru'},
    city: 'Mayor General Fap Armando Revoredo Iglesias Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -7.139183,
    longitude: -78.4894,
    altitude: 2676.4488
  },
  {
    iataCode: 'KZI',
    icaoCode: 'LGKZ',
    name: 'Kozani',
    country: {code: 'GR', name: 'Greece'},
    city: 'Filippos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 40.28611,
    longitude: 21.840834,
    altitude: 627.5832
  },
  {
    iataCode: 'TKQ',
    icaoCode: 'HTKA',
    name: 'Kigoma',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Kigoma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -4.883,
    longitude: 29.633,
    altitude: 822.96
  },
  {
    iataCode: 'CJC',
    icaoCode: 'SCCF',
    name: 'Calama',
    country: {code: 'CL', name: 'Chile'},
    city: 'El Loa',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -22.498175,
    longitude: -68.903575,
    altitude: 2299.1064
  },
  {
    iataCode: 'TKS',
    icaoCode: 'RJOS',
    name: 'Tokushima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tokushima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.132808,
    longitude: 134.606639,
    altitude: 7.9248
  },
  {
    iataCode: 'KZN',
    icaoCode: 'UWKD',
    name: 'Kazan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kazan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.606186,
    longitude: 49.278728,
    altitude: 125.2728
  },
  {
    iataCode: 'TKU',
    icaoCode: 'EFTU',
    name: 'Turku',
    country: {code: 'FI', name: 'Finland'},
    city: 'Turku',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 60.514142,
    longitude: 22.262808,
    altitude: 49.0728
  },
  {
    iataCode: 'TKX',
    icaoCode: 'NTKR',
    name: 'Takaroa',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Takaroa',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.455781,
    longitude: -145.024542,
    altitude: 3.9624
  },
  {
    iataCode: 'KZO',
    icaoCode: 'UAOO',
    name: 'Kzyl-orda',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Kzyl-orda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Qyzylorda',
    utcOffset: 360,
    latitude: 44.709,
    longitude: 65.591,
    altitude: 0.0
  },
  {
    iataCode: 'CJJ',
    icaoCode: 'RKTU',
    name: 'Chongju',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Cheongju International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 36.7166,
    longitude: 127.499119,
    altitude: 58.2168
  },
  {
    iataCode: 'CJL',
    icaoCode: 'OPCH',
    name: 'Chitral',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Chitral Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 35.886592,
    longitude: 71.800578,
    altitude: 1499.616
  },
  {
    iataCode: 'KZS',
    icaoCode: 'LGKJ',
    name: 'Kastelorizo',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kastelorizo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.127777,
    longitude: 29.566656,
    altitude: 144.4752
  },
  {
    iataCode: 'CJM',
    icaoCode: 'VTSE',
    name: 'Chumphon',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Chumphon',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 10.7112,
    longitude: 99.361706,
    altitude: 5.4864
  },
  {
    iataCode: 'TLA',
    icaoCode: 'PATE',
    name: 'Teller',
    country: {code: 'US', name: 'United States'},
    city: 'Teller Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 65.240278,
    longitude: -166.339444,
    altitude: 89.6112
  },
  {
    iataCode: 'TLC',
    icaoCode: 'MMTO',
    name: 'Toluca',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Licenciado Adolfo Lopez Mateos Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.337072,
    longitude: -99.566008,
    altitude: 2580.4368
  },
  {
    iataCode: 'TLE',
    icaoCode: 'FMST',
    name: 'Toliara',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Toliara',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -23.383369,
    longitude: 43.728453,
    altitude: 8.8392
  },
  {
    iataCode: 'CJS',
    icaoCode: 'MMCS',
    name: 'Ciudad Juarez',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Abraham Gonzalez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Ojinaga',
    utcOffset: -360,
    latitude: 31.636133,
    longitude: -106.428667,
    altitude: 1189.9392
  },
  {
    iataCode: 'TLD',
    icaoCode: 'FBTL',
    name: 'Tuli Lodge',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Limpopo Valley Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -22.1892,
    longitude: 29.1269,
    altitude: 540.1056
  },
  {
    iataCode: 'CJU',
    icaoCode: 'RKPC',
    name: 'Cheju',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Jeju Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 33.511306,
    longitude: 126.493028,
    altitude: 35.9664
  },
  {
    iataCode: 'TLH',
    icaoCode: 'KTLH',
    name: 'Tallahassee',
    country: {code: 'US', name: 'United States'},
    city: 'Tallahassee Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.396528,
    longitude: -84.350333,
    altitude: 24.6888
  },
  {
    iataCode: 'TLJ',
    icaoCode: 'PATL',
    name: 'Tatalina',
    country: {code: 'US', name: 'United States'},
    city: 'Tatalina Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.894369,
    longitude: -155.976525,
    altitude: 293.8272
  },
  {
    iataCode: 'TLM',
    icaoCode: 'DAON',
    name: 'Tlemcen',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Zenata',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.016667,
    longitude: -1.45,
    altitude: 248.1072
  },
  {
    iataCode: 'TLL',
    icaoCode: 'EETN',
    name: 'Tallinn-ulemiste International',
    country: {code: 'EE', name: 'Estonia'},
    city: 'Tallinn',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tallinn',
    utcOffset: 120,
    latitude: 59.413317,
    longitude: 24.832844,
    altitude: 39.9288
  },
  {
    iataCode: 'TLN',
    icaoCode: 'LFTH',
    name: 'Hyeres',
    country: {code: 'FR', name: 'France'},
    city: 'Le Palyvestre',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.0973,
    longitude: 6.14603,
    altitude: 2.1336
  },
  {
    iataCode: 'CKC',
    icaoCode: 'UKKE',
    name: 'Cherkassy',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Cherkassy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 49.416666,
    longitude: 32.1333,
    altitude: 34.7472
  },
  {
    iataCode: 'TLS',
    icaoCode: 'LFBO',
    name: 'Toulouse',
    country: {code: 'FR', name: 'France'},
    city: 'Blagnac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.629075,
    longitude: 1.363819,
    altitude: 152.0952
  },
  {
    iataCode: 'CKB',
    icaoCode: 'KCKB',
    name: 'Clarksburg',
    country: {code: 'US', name: 'United States'},
    city: 'Harrison Marion Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.2966,
    longitude: -80.2281,
    altitude: 370.9416
  },
  {
    iataCode: 'TLU',
    icaoCode: 'SKTL',
    name: 'Tolu',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Golfo De Morrosquillo Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 9.511944,
    longitude: -75.586389,
    altitude: 3.048
  },
  {
    iataCode: 'CKG',
    icaoCode: 'ZUCK',
    name: 'Chongqing',
    country: {code: 'CN', name: 'China'},
    city: 'Jiangbei',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 29.719217,
    longitude: 106.641678,
    altitude: 416.052
  },
  {
    iataCode: 'TLV',
    icaoCode: 'LLBG',
    name: 'Tel-aviv',
    country: {code: 'IL', name: 'Israel'},
    city: 'Ben Gurion',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 32.011389,
    longitude: 34.886667,
    altitude: 41.148
  },
  {
    iataCode: 'CKH',
    icaoCode: 'UESO',
    name: 'Chokurdah',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Chokurdakh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Magadan',
    utcOffset: 660,
    latitude: 70.6231,
    longitude: 147.902,
    altitude: 46.0248
  },
  {
    iataCode: 'TMB',
    icaoCode: 'KTMB',
    name: 'Kendall-tamiami',
    country: {code: 'US', name: 'United States'},
    city: 'Kendall Tamiami Executive',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 25.647889,
    longitude: -80.432777,
    altitude: 2.4384
  },
  {
    iataCode: 'CKS',
    icaoCode: 'SBCJ',
    name: 'Parauapebas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Carajas Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -6.11781,
    longitude: -50.0035,
    altitude: 629.1072
  },
  {
    iataCode: 'TMC',
    icaoCode: 'WADT',
    name: 'Waikabubak-sumba Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Tambolaka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -9.409717,
    longitude: 119.244494,
    altitude: 49.0728
  },
  {
    iataCode: 'TME',
    icaoCode: 'SKTM',
    name: 'Tame',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Tame',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 6.451081,
    longitude: -71.760261,
    altitude: 320.04
  },
  {
    iataCode: 'TMG',
    icaoCode: 'WBKM',
    name: 'Tomanggong',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Tomanggong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 5.4,
    longitude: 118.65,
    altitude: 7.9248
  },
  {
    iataCode: 'CKY',
    icaoCode: 'GUCY',
    name: 'Conakry',
    country: {code: 'GN', name: 'Guinea'},
    city: 'Conakry',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Conakry',
    utcOffset: 0,
    latitude: 9.576889,
    longitude: -13.611961,
    altitude: 21.9456
  },
  {
    iataCode: 'TMJ',
    icaoCode: 'UTST',
    name: 'Termez',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Termez Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 37.286667,
    longitude: 67.31,
    altitude: 313.0296
  },
  {
    iataCode: 'TMI',
    icaoCode: 'VNTR',
    name: 'Tumling Tar',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Tumling Tar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.315,
    longitude: 87.193333,
    altitude: 518.16
  },
  {
    iataCode: 'TML',
    icaoCode: 'DGLE',
    name: 'Tamale',
    country: {code: 'GH', name: 'Ghana'},
    city: 'Tamale',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Accra',
    utcOffset: 0,
    latitude: 9.557192,
    longitude: -0.863214,
    altitude: 168.5544
  },
  {
    iataCode: 'CKZ',
    icaoCode: 'LTBH',
    name: 'Canakkale',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Canakkale Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.137722,
    longitude: 26.426777,
    altitude: 7.0104
  },
  {
    iataCode: 'TMN',
    icaoCode: 'NGTM',
    name: 'Tamana',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Tamana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: -2.5,
    longitude: 175.983333,
    altitude: 3.048
  },
  {
    iataCode: 'TMM',
    icaoCode: 'FMMT',
    name: 'Toamasina',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Toamasina',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.109517,
    longitude: 49.392536,
    altitude: 6.7056
  },
  {
    iataCode: 'TMP',
    icaoCode: 'EFTP',
    name: 'Tampere',
    country: {code: 'FI', name: 'Finland'},
    city: 'Tampere Pirkkala',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.414147,
    longitude: 23.604392,
    altitude: 118.872
  },
  {
    iataCode: 'TMR',
    icaoCode: 'DAAT',
    name: 'Tamanrasset',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Tamanrasset',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 22.811461,
    longitude: 5.451075,
    altitude: 1377.0864
  },
  {
    iataCode: 'CLD',
    icaoCode: 'KCRQ',
    name: 'Carlsbad',
    country: {code: 'US', name: 'United States'},
    city: 'Mcclellan-palomar Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.0742,
    longitude: -117.1648,
    altitude: 99.9744
  },
  {
    iataCode: 'TMS',
    icaoCode: 'FPST',
    name: 'Sao Tome',
    country: {code: 'ST', name: 'Sao Tome and Principe'},
    city: 'Sao Tome Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Sao_Tome',
    utcOffset: 0,
    latitude: 0.378175,
    longitude: 6.712153,
    altitude: 10.0584
  },
  {
    iataCode: 'CLE',
    icaoCode: 'KCLE',
    name: 'Cleveland',
    country: {code: 'US', name: 'United States'},
    city: 'Cleveland Hopkins Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.411689,
    longitude: -81.849794,
    altitude: 241.0968
  },
  {
    iataCode: 'TMU',
    icaoCode: 'MRTR',
    name: 'Nicoya',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Tambor Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.73852,
    longitude: -85.0138,
    altitude: 10.0584
  },
  {
    iataCode: 'TMX',
    icaoCode: 'DAUT',
    name: 'Timimoun',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Timimoun',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 29.237119,
    longitude: 0.276033,
    altitude: 313.0296
  },
  {
    iataCode: 'TMW',
    icaoCode: 'YSTW',
    name: 'Tamworth',
    country: {code: 'AU', name: 'Australia'},
    city: 'Tamworth',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -31.083889,
    longitude: 150.846667,
    altitude: 406.6032
  },
  {
    iataCode: 'CLJ',
    icaoCode: 'LRCL',
    name: 'Cluj-napoca',
    country: {code: 'RO', name: 'Romania'},
    city: 'Cluj Napoca',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 46.785167,
    longitude: 23.686167,
    altitude: 315.7728
  },
  {
    iataCode: 'CLL',
    icaoCode: 'KCLL',
    name: 'College Station',
    country: {code: 'US', name: 'United States'},
    city: 'Easterwood Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.588583,
    longitude: -96.363833,
    altitude: 97.8408
  },
  {
    iataCode: 'CLN',
    icaoCode: 'SBCI',
    name: 'Carolina',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Carolina',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -7.320444,
    longitude: -47.458667,
    altitude: 172.212
  },
  {
    iataCode: 'CLM',
    icaoCode: 'KCLM',
    name: 'Port Angeles',
    country: {code: 'US', name: 'United States'},
    city: 'William R Fairchild International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.1202,
    longitude: -123.5,
    altitude: 88.6968
  },
  {
    iataCode: 'TNA',
    icaoCode: 'ZSJN',
    name: 'Jinan',
    country: {code: 'CN', name: 'China'},
    city: 'Jinan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 36.857214,
    longitude: 117.215992,
    altitude: 23.1648
  },
  {
    iataCode: 'CLO',
    icaoCode: 'SKCL',
    name: 'Cali',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Alfonso Bonilla Aragon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 3.543222,
    longitude: -76.381583,
    altitude: 963.7776
  },
  {
    iataCode: 'TNC',
    icaoCode: 'PATC',
    name: 'Tin City',
    country: {code: 'US', name: 'United States'},
    city: 'Tin City Lrrs Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 65.563056,
    longitude: -167.921667,
    altitude: 81.9912
  },
  {
    iataCode: 'CLQ',
    icaoCode: 'MMIA',
    name: 'Colima',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Colima',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.277011,
    longitude: -103.577397,
    altitude: 751.9416
  },
  {
    iataCode: 'CLT',
    icaoCode: 'KCLT',
    name: 'Charlotte',
    country: {code: 'US', name: 'United States'},
    city: 'Charlotte Douglas Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.214,
    longitude: -80.943139,
    altitude: 227.9904
  },
  {
    iataCode: 'TNE',
    icaoCode: 'RJFG',
    name: 'Tanegashima',
    country: {code: 'JP', name: 'Japan'},
    city: 'New Tanegashima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 30.605067,
    longitude: 130.991231,
    altitude: 234.0864
  },
  {
    iataCode: 'TND',
    icaoCode: 'MUTD',
    name: 'Trinidad',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Alberto Delgado Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 21.788461,
    longitude: -79.997203,
    altitude: 38.1
  },
  {
    iataCode: 'CLV',
    icaoCode: 'SBCN',
    name: 'Caldas Novas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Caldas Novas',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -17.7267,
    longitude: -48.6114,
    altitude: 684.8856
  },
  {
    iataCode: 'TNG',
    icaoCode: 'GMTT',
    name: 'Tanger',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Ibn Batouta',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 35.726917,
    longitude: -5.916889,
    altitude: 18.8976
  },
  {
    iataCode: 'TNF',
    icaoCode: 'LFPN',
    name: 'Toussous-le-noble',
    country: {code: 'FR', name: 'France'},
    city: 'Toussus Le Noble',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.751922,
    longitude: 2.106189,
    altitude: 163.9824
  },
  {
    iataCode: 'TNI',
    icaoCode: 'VIST',
    name: 'Satna',
    country: {code: 'IN', name: 'India'},
    city: 'Satna',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.562319,
    longitude: 80.854933,
    altitude: 323.088
  },
  {
    iataCode: 'CLY',
    icaoCode: 'LFKC',
    name: 'Calvi',
    country: {code: 'FR', name: 'France'},
    city: 'Saint Catherine',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 42.530753,
    longitude: 8.793189,
    altitude: 63.7032
  },
  {
    iataCode: 'TNJ',
    icaoCode: 'WIDN',
    name: 'Tanjung Pinang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Kijang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 0.922683,
    longitude: 104.532311,
    altitude: 15.8496
  },
  {
    iataCode: 'TNL',
    icaoCode: 'UKLT',
    name: 'Ternopol',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Ternopol',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Uzhgorod',
    utcOffset: 120,
    latitude: 49.31,
    longitude: 25.42,
    altitude: 327.0504
  },
  {
    iataCode: 'TNO',
    icaoCode: 'MRCV',
    name: 'Nicoya',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Cabo Velas Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.3557,
    longitude: -85.852892,
    altitude: 10.0584
  },
  {
    iataCode: 'TNN',
    icaoCode: 'RCNN',
    name: 'Tainan',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Tainan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.950361,
    longitude: 120.205778,
    altitude: 19.2024
  },
  {
    iataCode: 'CMA',
    icaoCode: 'YCMU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cunnamulla Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -28.03,
    longitude: 145.622,
    altitude: 192.024
  },
  {
    iataCode: 'CMB',
    icaoCode: 'VCBI',
    name: 'Colombo',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Bandaranaike Intl Colombo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 7.180756,
    longitude: 79.884117,
    altitude: 9.144
  },
  {
    iataCode: 'TNR',
    icaoCode: 'FMMI',
    name: 'Antananarivo',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ivato',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.79695,
    longitude: 47.478806,
    altitude: 1279.5504
  },
  {
    iataCode: 'CME',
    icaoCode: 'MMCE',
    name: 'Ciudad Del Carmen',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ciudad Del Carmen Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 18.653739,
    longitude: -91.799017,
    altitude: 3.048
  },
  {
    iataCode: 'TNT',
    icaoCode: 'KTNT',
    name: 'Miami',
    country: {code: 'US', name: 'United States'},
    city: 'Dade Collier Training And Transition',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 25.861806,
    longitude: -80.897,
    altitude: 3.9624
  },
  {
    iataCode: 'CMG',
    icaoCode: 'SBCR',
    name: 'Corumba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Corumba Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Campo_Grande',
    utcOffset: -240,
    latitude: -19.011931,
    longitude: -57.673053,
    altitude: 140.5128
  },
  {
    iataCode: 'CMF',
    icaoCode: 'LFLB',
    name: 'Chambery',
    country: {code: 'FR', name: 'France'},
    city: 'Aix Les Bains',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.63805,
    longitude: 5.880225,
    altitude: 237.4392
  },
  {
    iataCode: 'CMI',
    icaoCode: 'KCMI',
    name: 'Champaign',
    country: {code: 'US', name: 'United States'},
    city: 'Champaign',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.03925,
    longitude: -88.278056,
    altitude: 229.8192
  },
  {
    iataCode: 'CMH',
    icaoCode: 'KCMH',
    name: 'Columbus',
    country: {code: 'US', name: 'United States'},
    city: 'Port Columbus Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.997972,
    longitude: -82.891889,
    altitude: 248.412
  },
  {
    iataCode: 'TNX',
    icaoCode: 'KTNX',
    name: 'Tonopah',
    country: {code: 'US', name: 'United States'},
    city: 'Tonopah Test Range',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.798836,
    longitude: -116.78075,
    altitude: 1691.3352
  },
  {
    iataCode: 'CMK',
    icaoCode: 'FWCM',
    name: 'Club Makokola',
    country: {code: 'MW', name: 'Malawi'},
    city: 'Club Makokola Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Blantyre',
    utcOffset: 120,
    latitude: -14.3069,
    longitude: 35.1325,
    altitude: 483.7176
  },
  {
    iataCode: 'CMJ',
    icaoCode: 'RCCM',
    name: 'Cimei',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Cimei Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 23.266667,
    longitude: 119.666667,
    altitude: 19.2024
  },
  {
    iataCode: 'CMN',
    icaoCode: 'GMMN',
    name: 'Casablanca',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Mohammed V Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 33.367467,
    longitude: -7.589967,
    altitude: 199.9488
  },
  {
    iataCode: 'TOB',
    icaoCode: 'HLGN',
    name: 'Tobruk',
    country: {code: 'LY', name: 'Libya'},
    city: 'Gamal Abdel Nasser Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 31.861,
    longitude: 23.907,
    altitude: 158.1912
  },
  {
    iataCode: 'CMP',
    icaoCode: 'SNKE',
    name: 'Santana Do Araguaia',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Campo Alegre Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santarem',
    utcOffset: -180,
    latitude: -9.505,
    longitude: -50.625,
    altitude: 160.02
  },
  {
    iataCode: 'TOA',
    icaoCode: 'KTOA',
    name: 'Torrance',
    country: {code: 'US', name: 'United States'},
    city: 'Zamperini Field Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.803392,
    longitude: -118.339611,
    altitude: 30.7848
  },
  {
    iataCode: 'TOD',
    icaoCode: 'WMBT',
    name: 'Tioman',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Pulau Tioman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 2.818183,
    longitude: 104.160019,
    altitude: 4.572
  },
  {
    iataCode: 'CMR',
    icaoCode: 'LFGA',
    name: 'Colmar',
    country: {code: 'FR', name: 'France'},
    city: 'Houssen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.109853,
    longitude: 7.359011,
    altitude: 191.4144
  },
  {
    iataCode: 'CMU',
    icaoCode: 'AYCH',
    name: 'Kundiawa',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Chimbu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.02429,
    longitude: 144.971,
    altitude: 1516.0752
  },
  {
    iataCode: 'TOF',
    icaoCode: 'UNTT',
    name: 'Tomsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Tomsk Bogashevo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Novosibirsk',
    utcOffset: 420,
    latitude: 56.380278,
    longitude: 85.208333,
    altitude: 181.9656
  },
  {
    iataCode: 'TOE',
    icaoCode: 'DTTZ',
    name: 'Tozeur',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Nefta',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 33.939722,
    longitude: 8.110556,
    altitude: 87.4776
  },
  {
    iataCode: 'CMW',
    icaoCode: 'MUCM',
    name: 'Camaguey',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Ignacio Agramonte Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 21.420428,
    longitude: -77.847433,
    altitude: 125.8824
  },
  {
    iataCode: 'TOH',
    icaoCode: 'NVSD',
    name: 'Loh/linua',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Torres Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -13.328,
    longitude: 166.638,
    altitude: 0.0
  },
  {
    iataCode: 'TOG',
    icaoCode: 'PATG',
    name: 'Togiak Village',
    country: {code: 'US', name: 'United States'},
    city: 'Togiak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.0528,
    longitude: -160.397,
    altitude: 6.4008
  },
  {
    iataCode: 'TOJ',
    icaoCode: 'LETO',
    name: 'Madrid',
    country: {code: 'ES', name: 'Spain'},
    city: 'Torrejon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 40.496747,
    longitude: -3.445872,
    altitude: 617.5248
  },
  {
    iataCode: 'CMX',
    icaoCode: 'KCMX',
    name: 'Hancock',
    country: {code: 'US', name: 'United States'},
    city: 'Houghton County Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 47.1684,
    longitude: -88.4891,
    altitude: 333.756
  },
  {
    iataCode: 'TOL',
    icaoCode: 'KTOL',
    name: 'Toledo',
    country: {code: 'US', name: 'United States'},
    city: 'Toledo',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.586806,
    longitude: -83.807833,
    altitude: 208.4832
  },
  {
    iataCode: 'TOM',
    icaoCode: 'GATB',
    name: 'Tombouctou',
    country: {code: 'ML', name: 'Mali'},
    city: 'Tombouctou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bamako',
    utcOffset: 0,
    latitude: 16.730458,
    longitude: -3.007583,
    altitude: 263.0424
  },
  {
    iataCode: 'CNB',
    icaoCode: 'YCNM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Coonamble Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -30.9833,
    longitude: 148.376,
    altitude: 184.0992
  },
  {
    iataCode: 'CND',
    icaoCode: 'LRCK',
    name: 'Constanta',
    country: {code: 'RO', name: 'Romania'},
    city: 'Mihail Kogalniceanu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 44.362222,
    longitude: 28.488333,
    altitude: 107.5944
  },
  {
    iataCode: 'TOT',
    icaoCode: 'SMCO',
    name: 'Totness',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Totness Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.865833,
    longitude: -56.3275,
    altitude: 3.048
  },
  {
    iataCode: 'CNC',
    icaoCode: 'YCCT',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Coconut Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -10.05,
    longitude: 143.07,
    altitude: 0.9144
  },
  {
    iataCode: 'TOS',
    icaoCode: 'ENTC',
    name: 'Tromso',
    country: {code: 'NO', name: 'Norway'},
    city: 'Langnes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.683333,
    longitude: 18.918919,
    altitude: 9.4488
  },
  {
    iataCode: 'CNF',
    icaoCode: 'SBCF',
    name: 'Belo Horizonte',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Tancredo Neves Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -19.63375,
    longitude: -43.968856,
    altitude: 827.532
  },
  {
    iataCode: 'TOU',
    icaoCode: 'NWWU',
    name: 'Touho',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Touho',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -20.790028,
    longitude: 165.259486,
    altitude: 3.048
  },
  {
    iataCode: 'CNG',
    icaoCode: 'LFBG',
    name: 'Cognac',
    country: {code: 'FR', name: 'France'},
    city: 'Chateaubernard',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.658333,
    longitude: -0.3175,
    altitude: 31.0896
  },
  {
    iataCode: 'TOW',
    icaoCode: 'SBTD',
    name: 'Toledo',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Toledo Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -24.6863,
    longitude: -53.6975,
    altitude: 561.7464
  },
  {
    iataCode: 'CNJ',
    icaoCode: 'YCCY',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cloncurry Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.6686,
    longitude: 140.504,
    altitude: 187.7568
  },
  {
    iataCode: 'TOY',
    icaoCode: 'RJNT',
    name: 'Toyama',
    country: {code: 'JP', name: 'Japan'},
    city: 'Toyama',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 36.648333,
    longitude: 137.1875,
    altitude: 28.956
  },
  {
    iataCode: 'CNL',
    icaoCode: 'EKSN',
    name: 'Sindal',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Sindal Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 57.503525,
    longitude: 10.229372,
    altitude: 28.0416
  },
  {
    iataCode: 'CNN',
    icaoCode: 'UELL',
    name: 'Chulman',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Chulman Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 56.9139,
    longitude: 124.914,
    altitude: 857.0976
  },
  {
    iataCode: 'CNM',
    icaoCode: 'KCNM',
    name: 'Carlsbad',
    country: {code: 'US', name: 'United States'},
    city: 'Cavern City Air Terminal',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.337472,
    longitude: -104.263278,
    altitude: 1004.316
  },
  {
    iataCode: 'CNP',
    icaoCode: 'BGCO',
    name: 'Neerlerit Inaat',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Neerlerit Inaat Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -60,
    latitude: 70.7433,
    longitude: -22.6606,
    altitude: 13.716
  },
  {
    iataCode: 'TPA',
    icaoCode: 'KTPA',
    name: 'Tampa',
    country: {code: 'US', name: 'United States'},
    city: 'Tampa Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.975472,
    longitude: -82.53325,
    altitude: 7.9248
  },
  {
    iataCode: 'TPC',
    icaoCode: 'SETR',
    name: 'Tarapoa',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Tarapoa',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -0.122956,
    longitude: -76.33775,
    altitude: 248.1072
  },
  {
    iataCode: 'CNQ',
    icaoCode: 'SARC',
    name: 'Corrientes',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Corrientes',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -27.445503,
    longitude: -58.761864,
    altitude: 61.8744
  },
  {
    iataCode: 'TPE',
    icaoCode: 'RCTP',
    name: 'Taipei',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Taoyuan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 25.077731,
    longitude: 121.232822,
    altitude: 32.3088
  },
  {
    iataCode: 'CNS',
    icaoCode: 'YBCS',
    name: 'Cairns',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cairns Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -16.885833,
    longitude: 145.755278,
    altitude: 3.048
  },
  {
    iataCode: 'CNX',
    icaoCode: 'VTCC',
    name: 'Chiang Mai',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Chiang Mai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 18.766847,
    longitude: 98.962644,
    altitude: 315.7728
  },
  {
    iataCode: 'CNW',
    icaoCode: 'KCNW',
    name: 'Waco',
    country: {code: 'US', name: 'United States'},
    city: 'Tstc Waco',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.637831,
    longitude: -97.074139,
    altitude: 143.256
  },
  {
    iataCode: 'CNY',
    icaoCode: 'KCNY',
    name: 'Moab',
    country: {code: 'US', name: 'United States'},
    city: 'Canyonlands Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.755,
    longitude: -109.754722,
    altitude: 1388.364
  },
  {
    iataCode: 'TPJ',
    icaoCode: 'VNTJ',
    name: 'Taplejung',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Taplejung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.35,
    longitude: 84.667,
    altitude: 1219.2
  },
  {
    iataCode: 'TPL',
    icaoCode: 'KTPL',
    name: 'Temple',
    country: {code: 'US', name: 'United States'},
    city: 'Draughon Miller Central Texas Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.1525,
    longitude: -97.407778,
    altitude: 207.8736
  },
  {
    iataCode: 'TPQ',
    icaoCode: 'MMEP',
    name: 'Tepic',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tepic',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -360,
    latitude: 21.419453,
    longitude: -104.842581,
    altitude: 920.496
  },
  {
    iataCode: 'TPP',
    icaoCode: 'SPST',
    name: 'Tarapoto',
    country: {code: 'PE', name: 'Peru'},
    city: 'Cadete Guillermo Del Castillo Paredes',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -6.508742,
    longitude: -76.373247,
    altitude: 264.8712
  },
  {
    iataCode: 'COC',
    icaoCode: 'SAAC',
    name: 'Concordia',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Comodoro Pierrestegui',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -31.296944,
    longitude: -57.996631,
    altitude: 34.1376
  },
  {
    iataCode: 'TPS',
    icaoCode: 'LICT',
    name: 'Trapani',
    country: {code: 'IT', name: 'Italy'},
    city: 'Trapani Birgi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 37.911403,
    longitude: 12.487961,
    altitude: 7.3152
  },
  {
    iataCode: 'COD',
    icaoCode: 'KCOD',
    name: 'Cody',
    country: {code: 'US', name: 'United States'},
    city: 'Yellowstone Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.520194,
    longitude: -109.023806,
    altitude: 1555.0896
  },
  {
    iataCode: 'COG',
    icaoCode: 'SKCD',
    name: 'Condoto',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Mandinga Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 5.071667,
    longitude: -76.676389,
    altitude: 3.048
  },
  {
    iataCode: 'COF',
    icaoCode: 'KCOF',
    name: 'Coco Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Patrick Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.234922,
    longitude: -80.610125,
    altitude: 2.4384
  },
  {
    iataCode: 'COH',
    icaoCode: 'VECO',
    name: 'Cooch-behar',
    country: {code: 'IN', name: 'India'},
    city: 'Cooch Behar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.330508,
    longitude: 89.467203,
    altitude: 42.0624
  },
  {
    iataCode: 'COK',
    icaoCode: 'VOCC',
    name: 'Cochin',
    country: {code: 'IN', name: 'India'},
    city: 'Cochin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 9.947386,
    longitude: 76.273081,
    altitude: 2.4384
  },
  {
    iataCode: 'COO',
    icaoCode: 'DBBB',
    name: 'Cotonou',
    country: {code: 'BJ', name: 'Benin'},
    city: 'Cadjehoun',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Porto-Novo',
    utcOffset: 60,
    latitude: 6.357228,
    longitude: 2.384353,
    altitude: 5.7912
  },
  {
    iataCode: 'COQ',
    icaoCode: 'ZMCD',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Choibalsan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Choibalsan',
    utcOffset: 480,
    latitude: 48.1357,
    longitude: 114.646,
    altitude: 748.8936
  },
  {
    iataCode: 'COS',
    icaoCode: 'KCOS',
    name: 'Colorado Springs',
    country: {code: 'US', name: 'United States'},
    city: 'City Of Colorado Springs Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.805805,
    longitude: -104.700778,
    altitude: 1885.7976
  },
  {
    iataCode: 'COR',
    icaoCode: 'SACO',
    name: 'Cordoba',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Ambrosio L V Taravella',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -31.323619,
    longitude: -64.207953,
    altitude: 488.8992
  },
  {
    iataCode: 'COU',
    icaoCode: 'KCOU',
    name: 'Columbia',
    country: {code: 'US', name: 'United States'},
    city: 'Columbia Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.818094,
    longitude: -92.219631,
    altitude: 270.9672
  },
  {
    iataCode: 'COT',
    icaoCode: 'KCOT',
    name: 'Cotulla',
    country: {code: 'US', name: 'United States'},
    city: 'Cotulla Lasalle Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 28.456694,
    longitude: -99.220294,
    altitude: 144.4752
  },
  {
    iataCode: 'COX',
    icaoCode: 'MYAK',
    name: 'Andros',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Congo Town Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.158933,
    longitude: -77.589758,
    altitude: 4.572
  },
  {
    iataCode: 'LAA',
    icaoCode: 'KLAA',
    name: 'Lamar',
    country: {code: 'US', name: 'United States'},
    city: 'Lamar Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.069694,
    longitude: -102.6885,
    altitude: 1129.5888
  },
  {
    iataCode: 'LAD',
    icaoCode: 'FNLU',
    name: 'Luanda',
    country: {code: 'AO', name: 'Angola'},
    city: 'Luanda 4 De Fevereiro',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -8.858375,
    longitude: 13.231178,
    altitude: 74.0664
  },
  {
    iataCode: 'COZ',
    icaoCode: 'MDCZ',
    name: 'Constanza',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Constanza Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.907626,
    longitude: -70.719852,
    altitude: 1198.1688
  },
  {
    iataCode: 'LAE',
    icaoCode: 'AYNZ',
    name: 'Nadzab',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Nadzab',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.569828,
    longitude: 146.726242,
    altitude: 72.8472
  },
  {
    iataCode: 'CPA',
    icaoCode: 'GLCP',
    name: 'Greenville',
    country: {code: 'LR', name: 'Liberia'},
    city: 'Cape Palmas Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Monrovia',
    utcOffset: 0,
    latitude: 4.37902,
    longitude: -7.69695,
    altitude: 152.4
  },
  {
    iataCode: 'LAI',
    icaoCode: 'LFRO',
    name: 'Lannion',
    country: {code: 'FR', name: 'France'},
    city: 'Lannion',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.754378,
    longitude: -3.471656,
    altitude: 88.392
  },
  {
    iataCode: 'CPD',
    icaoCode: 'YCBP',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Coober Pedy Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -29.04,
    longitude: 134.721,
    altitude: 225.552
  },
  {
    iataCode: 'LAL',
    icaoCode: 'KLAL',
    name: 'Lakeland',
    country: {code: 'US', name: 'United States'},
    city: 'Lakeland Linder Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 27.988917,
    longitude: -82.018556,
    altitude: 43.2816
  },
  {
    iataCode: 'CPC',
    icaoCode: 'SAZY',
    name: 'San Martin Des Andes',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Aviador C Campos',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -40.075383,
    longitude: -71.137294,
    altitude: 783.0312
  },
  {
    iataCode: 'LAK',
    icaoCode: 'CYKD',
    name: 'Aklavik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Aklavik',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 68.223333,
    longitude: -135.005833,
    altitude: 7.0104
  },
  {
    iataCode: 'LAN',
    icaoCode: 'KLAN',
    name: 'Lansing',
    country: {code: 'US', name: 'United States'},
    city: 'Capital City',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.7787,
    longitude: -84.587357,
    altitude: 262.4328
  },
  {
    iataCode: 'CPE',
    icaoCode: 'MMCP',
    name: 'Campeche',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ingeniero Alberto Acuna Ongay Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 19.816794,
    longitude: -90.500314,
    altitude: 10.3632
  },
  {
    iataCode: 'LAM',
    icaoCode: 'KLAM',
    name: 'Los Alamos',
    country: {code: 'US', name: 'United States'},
    city: 'Los Alamos Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 35.879802,
    longitude: -106.269415,
    altitude: 2185.7208
  },
  {
    iataCode: 'CPH',
    icaoCode: 'EKCH',
    name: 'Copenhagen',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Kastrup',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.617917,
    longitude: 12.655972,
    altitude: 5.1816
  },
  {
    iataCode: 'LAP',
    icaoCode: 'MMLP',
    name: 'La Paz',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Manuel Marquez De Leon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 24.072694,
    longitude: -110.362475,
    altitude: 21.0312
  },
  {
    iataCode: 'LAO',
    icaoCode: 'RPLI',
    name: 'Laoag',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Laoag Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 18.178092,
    longitude: 120.531522,
    altitude: 7.62
  },
  {
    iataCode: 'LAR',
    icaoCode: 'KLAR',
    name: 'Laramie',
    country: {code: 'US', name: 'United States'},
    city: 'Laramie Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.3121,
    longitude: -105.675,
    altitude: 2220.1632
  },
  {
    iataCode: 'LAQ',
    icaoCode: 'HLLQ',
    name: "Al Bayda'",
    country: {code: 'LY', name: 'Libya'},
    city: 'La Abraq Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 32.788673,
    longitude: 21.964333,
    altitude: 657.4536
  },
  {
    iataCode: 'LAS',
    icaoCode: 'KLAS',
    name: 'Las Vegas',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Carran Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.080056,
    longitude: -115.15225,
    altitude: 652.5768
  },
  {
    iataCode: 'LAU',
    icaoCode: 'HKLU',
    name: 'Lamu',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Lamu Manda',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -2.252417,
    longitude: 40.913097,
    altitude: 6.096
  },
  {
    iataCode: 'LAX',
    icaoCode: 'KLAX',
    name: 'Los Angeles',
    country: {code: 'US', name: 'United States'},
    city: 'Los Angeles Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.942536,
    longitude: -118.408075,
    altitude: 38.4048
  },
  {
    iataCode: 'CPO',
    icaoCode: 'SCHA',
    name: 'Copiapo',
    country: {code: 'CL', name: 'Chile'},
    city: 'Copiapo',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -27.0,
    longitude: -70.0,
    altitude: 304.8
  },
  {
    iataCode: 'LAW',
    icaoCode: 'KLAW',
    name: 'Lawton',
    country: {code: 'US', name: 'United States'},
    city: 'Lawton-fort Sill Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.567714,
    longitude: -98.416637,
    altitude: 338.328
  },
  {
    iataCode: 'CPR',
    icaoCode: 'KCPR',
    name: 'Casper',
    country: {code: 'US', name: 'United States'},
    city: 'Natrona Co Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 42.908,
    longitude: -106.464417,
    altitude: 1629.7656
  },
  {
    iataCode: 'LAZ',
    icaoCode: 'SBLP',
    name: 'Bom Jesus Da Lapa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Bom Jesus Da Lapa',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -13.262086,
    longitude: -43.408114,
    altitude: 443.1792
  },
  {
    iataCode: 'TRC',
    icaoCode: 'MMTC',
    name: 'Torreon',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Torreon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 25.568278,
    longitude: -103.410583,
    altitude: 1124.1024
  },
  {
    iataCode: 'CPQ',
    icaoCode: 'SDAM',
    name: 'Campinas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Amarais Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -240,
    latitude: -22.8592,
    longitude: -47.1082,
    altitude: 612.0384
  },
  {
    iataCode: 'LAY',
    icaoCode: 'FALY',
    name: 'Ladysmith',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Ladysmith',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.581667,
    longitude: 29.749722,
    altitude: 1081.4304
  },
  {
    iataCode: 'CPT',
    icaoCode: 'FACT',
    name: 'Cape Town',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Cape Town Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -33.964806,
    longitude: 18.601667,
    altitude: 46.0248
  },
  {
    iataCode: 'TRE',
    icaoCode: 'EGPU',
    name: 'Tiree',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Tiree',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 56.499167,
    longitude: -6.869167,
    altitude: 11.5824
  },
  {
    iataCode: 'TRD',
    icaoCode: 'ENVA',
    name: 'Trondheim',
    country: {code: 'NO', name: 'Norway'},
    city: 'Vaernes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 63.457556,
    longitude: 10.92425,
    altitude: 17.0688
  },
  {
    iataCode: 'CPV',
    icaoCode: 'SBKG',
    name: 'Campina Grande',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Presidente Joao Suassuna',
    region: '',
    terminal: '',
    timezoneId: 'America/Recife',
    utcOffset: -180,
    latitude: -7.269917,
    longitude: -35.896364,
    altitude: 501.7008
  },
  {
    iataCode: 'TRG',
    icaoCode: 'NZTG',
    name: 'Tauranga',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Tauranga',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -37.671944,
    longitude: 176.19611,
    altitude: 3.9624
  },
  {
    iataCode: 'TRF',
    icaoCode: 'ENTO',
    name: 'Sandefjord',
    country: {code: 'NO', name: 'Norway'},
    city: 'Torp',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.186703,
    longitude: 10.258628,
    altitude: 87.1728
  },
  {
    iataCode: 'CPX',
    icaoCode: 'TJCP',
    name: 'Culebra Island',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Culebra Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.3127,
    longitude: -65.3034,
    altitude: 3.6576
  },
  {
    iataCode: 'LBA',
    icaoCode: 'EGNM',
    name: 'Leeds',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Leeds Bradford',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.865897,
    longitude: -1.660569,
    altitude: 207.5688
  },
  {
    iataCode: 'TRI',
    icaoCode: 'KTRI',
    name: 'Bristol',
    country: {code: 'US', name: 'United States'},
    city: 'Tri-cities Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.475222,
    longitude: -82.407417,
    altitude: 462.9912
  },
  {
    iataCode: 'LBC',
    icaoCode: 'EDHL',
    name: 'Luebeck',
    country: {code: 'DE', name: 'Germany'},
    city: 'Lubeck Blankensee',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.805367,
    longitude: 10.719222,
    altitude: 16.1544
  },
  {
    iataCode: 'TRK',
    icaoCode: 'WALR',
    name: 'Taraken',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Juwata',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 3.326694,
    longitude: 117.565569,
    altitude: 6.096
  },
  {
    iataCode: 'LBB',
    icaoCode: 'KLBB',
    name: 'Lubbock',
    country: {code: 'US', name: 'United States'},
    city: 'Lubbock Preston Smith Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.663639,
    longitude: -101.822778,
    altitude: 1000.3536
  },
  {
    iataCode: 'LBE',
    icaoCode: 'KLBE',
    name: 'Latrobe',
    country: {code: 'US', name: 'United States'},
    city: 'Arnold Palmer Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.2759,
    longitude: -79.4048,
    altitude: 361.188
  },
  {
    iataCode: 'TRM',
    icaoCode: 'KTRM',
    name: 'Palm Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Jacqueline Cochran Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 33.626666,
    longitude: -116.159667,
    altitude: 0.0
  },
  {
    iataCode: 'LBD',
    icaoCode: 'UTDL',
    name: 'Khudzhand',
    country: {code: 'TJ', name: 'Tajikistan'},
    city: 'Khudzhand Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dushanbe',
    utcOffset: 300,
    latitude: 40.2154,
    longitude: 69.6947,
    altitude: 441.96
  },
  {
    iataCode: 'LBG',
    icaoCode: 'LFPB',
    name: 'Paris',
    country: {code: 'FR', name: 'France'},
    city: 'Le Bourget',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.969444,
    longitude: 2.441389,
    altitude: 66.4464
  },
  {
    iataCode: 'TRO',
    icaoCode: 'YTRE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Taree Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -31.8886,
    longitude: 152.514,
    altitude: 11.5824
  },
  {
    iataCode: 'LBF',
    icaoCode: 'KLBF',
    name: 'North Platte',
    country: {code: 'US', name: 'United States'},
    city: 'North Platte Regional Airport Lee Bird Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.1262,
    longitude: -100.684,
    altitude: 846.1248
  },
  {
    iataCode: 'TRN',
    icaoCode: 'LIMF',
    name: 'Torino',
    country: {code: 'IT', name: 'Italy'},
    city: 'Torino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.200761,
    longitude: 7.649631,
    altitude: 301.4472
  },
  {
    iataCode: 'LBI',
    icaoCode: 'LFCI',
    name: 'Albi',
    country: {code: 'FR', name: 'France'},
    city: 'Le Sequestre',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.913887,
    longitude: 2.113056,
    altitude: 171.9072
  },
  {
    iataCode: 'TRS',
    icaoCode: 'LIPQ',
    name: 'Ronchi De Legionari',
    country: {code: 'IT', name: 'Italy'},
    city: 'Ronchi Dei Legionari',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.8275,
    longitude: 13.472222,
    altitude: 11.2776
  },
  {
    iataCode: 'LBJ',
    icaoCode: 'WATO',
    name: 'Labuhan Bajo',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Mutiara Ii',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.486656,
    longitude: 119.88905,
    altitude: 20.1168
  },
  {
    iataCode: 'TRR',
    icaoCode: 'VCCT',
    name: 'Trinciomalee',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'China Bay',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 8.538514,
    longitude: 81.181853,
    altitude: 1.8288
  },
  {
    iataCode: 'TRU',
    icaoCode: 'SPRU',
    name: 'Trujillo',
    country: {code: 'PE', name: 'Peru'},
    city: 'Capitan Carlos Martinez De Pinillos',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -8.081411,
    longitude: -79.108761,
    altitude: 32.3088
  },
  {
    iataCode: 'CQD',
    icaoCode: 'OIFS',
    name: '',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Shahre Kord Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 32.2972,
    longitude: 50.8422,
    altitude: 2049.1704
  },
  {
    iataCode: 'LBL',
    icaoCode: 'KLBL',
    name: 'Liberal',
    country: {code: 'US', name: 'United States'},
    city: 'Liberal Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.044222,
    longitude: -100.95986,
    altitude: 879.348
  },
  {
    iataCode: 'TRW',
    icaoCode: 'NGTA',
    name: 'Tarawa',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Bonriki Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 720,
    latitude: 1.381636,
    longitude: 173.147036,
    altitude: 2.7432
  },
  {
    iataCode: 'CQF',
    icaoCode: 'LFAC',
    name: 'Calais',
    country: {code: 'FR', name: 'France'},
    city: 'Calais Dunkerque',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 50.962097,
    longitude: 1.954764,
    altitude: 3.6576
  },
  {
    iataCode: 'TRV',
    icaoCode: 'VOTV',
    name: 'Trivandrum',
    country: {code: 'IN', name: 'India'},
    city: 'Thiruvananthapuram Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 8.482122,
    longitude: 76.920114,
    altitude: 4.572
  },
  {
    iataCode: 'LBQ',
    icaoCode: 'FOGR',
    name: 'Lambarene',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Lambarene',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -0.704389,
    longitude: 10.245722,
    altitude: 24.9936
  },
  {
    iataCode: 'LBS',
    icaoCode: 'NFNL',
    name: 'Lambasa',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Labasa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -16.466749,
    longitude: 179.33986,
    altitude: 13.4112
  },
  {
    iataCode: 'LBR',
    icaoCode: 'SWLB',
    name: 'Labrea',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Labrea Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -7.258889,
    longitude: -64.797778,
    altitude: 68.58
  },
  {
    iataCode: 'TRZ',
    icaoCode: 'VOTR',
    name: 'Tiruchchirappalli',
    country: {code: 'IN', name: 'India'},
    city: 'Tiruchirappalli',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 10.765364,
    longitude: 78.709722,
    altitude: 87.7824
  },
  {
    iataCode: 'CQM',
    icaoCode: 'LERL',
    name: 'Ciudad Real',
    country: {code: 'ES', name: 'Spain'},
    city: 'Ciudad Real Central Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 38.856389,
    longitude: -3.97,
    altitude: 193.8528
  },
  {
    iataCode: 'LBU',
    icaoCode: 'WBKL',
    name: 'Labuan',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Labuan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 5.300683,
    longitude: 115.250181,
    altitude: 30.7848
  },
  {
    iataCode: 'LBW',
    icaoCode: 'WRLB',
    name: 'Long Bawan-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Long Bawan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 3.867,
    longitude: 115.683,
    altitude: 1094.232
  },
  {
    iataCode: 'LBV',
    icaoCode: 'FOOL',
    name: 'Libreville',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Leon M Ba',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: 0.4586,
    longitude: 9.412283,
    altitude: 11.8872
  },
  {
    iataCode: 'LBX',
    icaoCode: 'RPLU',
    name: 'Lubang',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Lubang Community Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 13.855833,
    longitude: 121.105833,
    altitude: 7.62
  },
  {
    iataCode: 'TSA',
    icaoCode: 'RCSS',
    name: 'Taipei',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Sungshan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 25.069722,
    longitude: 121.5525,
    altitude: 5.4864
  },
  {
    iataCode: 'LBZ',
    icaoCode: 'FNLK',
    name: 'Lucapa',
    country: {code: 'AO', name: 'Angola'},
    city: 'Lucapa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -8.443056,
    longitude: 20.732222,
    altitude: 152.4
  },
  {
    iataCode: 'TSF',
    icaoCode: 'LIPH',
    name: 'Treviso',
    country: {code: 'IT', name: 'Italy'},
    city: 'Treviso',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.6484,
    longitude: 12.194422,
    altitude: 17.9832
  },
  {
    iataCode: 'TSE',
    icaoCode: 'UACC',
    name: 'Tselinograd',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Astana Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 51.022222,
    longitude: 71.466944,
    altitude: 355.092
  },
  {
    iataCode: 'TSH',
    icaoCode: 'FZUK',
    name: 'Tshikapa',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Tshikapa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -6.43833,
    longitude: 20.7947,
    altitude: 486.156
  },
  {
    iataCode: 'TSJ',
    icaoCode: 'RJDT',
    name: 'Tsushima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tsushima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.284889,
    longitude: 129.33055,
    altitude: 64.9224
  },
  {
    iataCode: 'LCA',
    icaoCode: 'LCLK',
    name: 'Larnaca',
    country: {code: 'CY', name: 'Cyprus'},
    city: 'Larnaca',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Nicosia',
    utcOffset: 120,
    latitude: 34.875117,
    longitude: 33.62485,
    altitude: 2.4384
  },
  {
    iataCode: 'TSL',
    icaoCode: 'MMTN',
    name: 'Tamuin',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tamuin',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 22.038292,
    longitude: -98.806503,
    altitude: 49.9872
  },
  {
    iataCode: 'LCC',
    icaoCode: 'LIBN',
    name: 'Lecce',
    country: {code: 'IT', name: 'Italy'},
    city: 'Lecce',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.239228,
    longitude: 18.133325,
    altitude: 47.5488
  },
  {
    iataCode: 'TSN',
    icaoCode: 'ZBTJ',
    name: 'Tianjin',
    country: {code: 'CN', name: 'China'},
    city: 'Binhai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 39.124353,
    longitude: 117.346183,
    altitude: 3.048
  },
  {
    iataCode: 'LCE',
    icaoCode: 'MHLC',
    name: 'La Ceiba',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Goloson Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.742481,
    longitude: -86.853036,
    altitude: 14.9352
  },
  {
    iataCode: 'LCH',
    icaoCode: 'KLCH',
    name: 'Lake Charles',
    country: {code: 'US', name: 'United States'},
    city: 'Lake Charles Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.126112,
    longitude: -93.223335,
    altitude: 4.572
  },
  {
    iataCode: 'LCG',
    icaoCode: 'LECO',
    name: 'La Coruna',
    country: {code: 'ES', name: 'Spain'},
    city: 'A Coruna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 43.302061,
    longitude: -8.377256,
    altitude: 99.3648
  },
  {
    iataCode: 'TSO',
    icaoCode: 'EGHT',
    name: 'Tresco',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Tresco Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 49.945556,
    longitude: -6.331389,
    altitude: 6.096
  },
  {
    iataCode: 'LCJ',
    icaoCode: 'EPLL',
    name: 'Lodz',
    country: {code: 'PL', name: 'Poland'},
    city: 'Łódź Władysław Reymont Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 51.721881,
    longitude: 19.398133,
    altitude: 184.0992
  },
  {
    iataCode: 'TSR',
    icaoCode: 'LRTR',
    name: 'Timisoara',
    country: {code: 'RO', name: 'Romania'},
    city: 'Traian Vuia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 45.809861,
    longitude: 21.337861,
    altitude: 106.0704
  },
  {
    iataCode: 'CRA',
    icaoCode: 'LRCV',
    name: 'Craiova',
    country: {code: 'RO', name: 'Romania'},
    city: 'Craiova',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 44.318139,
    longitude: 23.888611,
    altitude: 190.8048
  },
  {
    iataCode: 'CRD',
    icaoCode: 'SAVC',
    name: 'Comodoro Rivadavia',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Comodoro Rivadavia',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -45.785347,
    longitude: -67.465508,
    altitude: 57.912
  },
  {
    iataCode: 'LCL',
    icaoCode: 'MULM',
    name: 'La Coloma',
    country: {code: 'CU', name: 'Cuba'},
    city: 'La Coloma',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.336261,
    longitude: -83.642111,
    altitude: 39.9288
  },
  {
    iataCode: 'TST',
    icaoCode: 'VTST',
    name: 'Trang',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Trang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 7.508744,
    longitude: 99.616578,
    altitude: 20.4216
  },
  {
    iataCode: 'CRC',
    icaoCode: 'SKGO',
    name: 'Cartago',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Santa Ana Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.758181,
    longitude: -75.955753,
    altitude: 907.9992
  },
  {
    iataCode: 'LCK',
    icaoCode: 'KLCK',
    name: 'Columbus',
    country: {code: 'US', name: 'United States'},
    city: 'Rickenbacker Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.813786,
    longitude: -82.927822,
    altitude: 226.7712
  },
  {
    iataCode: 'TSS',
    icaoCode: 'NONE',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'East 34th Street Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.7425,
    longitude: -73.971944,
    altitude: 3.048
  },
  {
    iataCode: 'TSV',
    icaoCode: 'YBTL',
    name: 'Townsville',
    country: {code: 'AU', name: 'Australia'},
    city: 'Townsville',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -19.2525,
    longitude: 146.765278,
    altitude: 5.4864
  },
  {
    iataCode: 'TSU',
    icaoCode: 'NGTS',
    name: 'Tabiteuea',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Tabiteuea South Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: -1.35,
    longitude: 174.8,
    altitude: 3.048
  },
  {
    iataCode: 'CRI',
    icaoCode: 'MYCI',
    name: 'Colonel Hill',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Colonel Hill Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 22.745561,
    longitude: -74.182353,
    altitude: 1.524
  },
  {
    iataCode: 'LCQ',
    icaoCode: 'KLCQ',
    name: 'Lake City',
    country: {code: 'US', name: 'United States'},
    city: 'Lake City Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.181944,
    longitude: -82.576944,
    altitude: 61.2648
  },
  {
    iataCode: 'CRL',
    icaoCode: 'EBCI',
    name: 'Charleroi',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Brussels South',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 50.459197,
    longitude: 4.453817,
    altitude: 187.1472
  },
  {
    iataCode: 'CRK',
    icaoCode: 'RPLC',
    name: 'Angeles City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Diosdado Macapagal International',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 15.185833,
    longitude: 120.560278,
    altitude: 147.5232
  },
  {
    iataCode: 'CRM',
    icaoCode: 'RPVF',
    name: 'Catarman',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Catarman National Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 12.502417,
    longitude: 124.635778,
    altitude: 1.8288
  },
  {
    iataCode: 'CRP',
    icaoCode: 'KCRP',
    name: 'Corpus Christi',
    country: {code: 'US', name: 'United States'},
    city: 'Corpus Christi Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 27.770361,
    longitude: -97.501222,
    altitude: 13.4112
  },
  {
    iataCode: 'TTA',
    icaoCode: 'GMAT',
    name: 'Tan Tan',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Plage Blanche',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 28.448194,
    longitude: -11.161347,
    altitude: 199.0344
  },
  {
    iataCode: 'CRQ',
    icaoCode: 'SBCV',
    name: 'Caravelas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Caravelas',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -17.652283,
    longitude: -39.253069,
    altitude: 10.9728
  },
  {
    iataCode: 'LCY',
    icaoCode: 'EGLC',
    name: 'London',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'City',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.505278,
    longitude: 0.055278,
    altitude: 5.7912
  },
  {
    iataCode: 'TTB',
    icaoCode: 'LIET',
    name: 'Tortoli',
    country: {code: 'IT', name: 'Italy'},
    city: 'Tortoli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 39.918761,
    longitude: 9.682981,
    altitude: 7.0104
  },
  {
    iataCode: 'TTE',
    icaoCode: 'WAMT',
    name: 'Ternate',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sultan Babullah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: 0.831414,
    longitude: 127.381486,
    altitude: 14.9352
  },
  {
    iataCode: 'CRV',
    icaoCode: 'LIBC',
    name: 'Crotone',
    country: {code: 'IT', name: 'Italy'},
    city: 'Crotone',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 38.997225,
    longitude: 17.080169,
    altitude: 158.8008
  },
  {
    iataCode: 'TTG',
    icaoCode: 'SAST',
    name: 'Tartagal',
    country: {code: 'AR', name: 'Argentina'},
    city: 'General Enrique Mosconi Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -22.619167,
    longitude: -63.793189,
    altitude: 448.6656
  },
  {
    iataCode: 'CRW',
    icaoCode: 'KCRW',
    name: 'Charleston',
    country: {code: 'US', name: 'United States'},
    city: 'Yeager',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.373147,
    longitude: -81.593189,
    altitude: 299.0088
  },
  {
    iataCode: 'TTH',
    icaoCode: 'OOTH',
    name: 'Thumrait',
    country: {code: 'OM', name: 'Oman'},
    city: 'Thumrait',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Muscat',
    utcOffset: 240,
    latitude: 17.666,
    longitude: 54.024612,
    altitude: 478.536
  },
  {
    iataCode: 'LDB',
    icaoCode: 'SBLO',
    name: 'Londrina',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Londrina',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.333625,
    longitude: -51.130072,
    altitude: 569.0616
  },
  {
    iataCode: 'TTJ',
    icaoCode: 'RJOR',
    name: 'Tottori',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tottori',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.530069,
    longitude: 134.166553,
    altitude: 19.812
  },
  {
    iataCode: 'LDE',
    icaoCode: 'LFBT',
    name: 'Tarbes',
    country: {code: 'FR', name: 'France'},
    city: 'Lourdes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.178675,
    longitude: -0.006439,
    altitude: 384.048
  },
  {
    iataCode: 'LDG',
    icaoCode: 'ULAL',
    name: 'Arkhangelsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Leshukonskoye Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 64.895833,
    longitude: 45.722778,
    altitude: 67.056
  },
  {
    iataCode: 'TTN',
    icaoCode: 'KTTN',
    name: 'Trenton',
    country: {code: 'US', name: 'United States'},
    city: 'Trenton Mercer',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.276692,
    longitude: -74.813469,
    altitude: 64.9224
  },
  {
    iataCode: 'CSA',
    icaoCode: 'EGEY',
    name: 'Colonsay',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Colonsay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 56.0575,
    longitude: -6.243056,
    altitude: 13.4112
  },
  {
    iataCode: 'LDI',
    icaoCode: 'HTLI',
    name: 'Lindi',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Kikwetu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -9.85111,
    longitude: 39.7578,
    altitude: 30.48
  },
  {
    iataCode: 'TTQ',
    icaoCode: 'MRAO',
    name: 'Roxana',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Aerotortuguero Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.569,
    longitude: -83.5148,
    altitude: 24.9936
  },
  {
    iataCode: 'LDH',
    icaoCode: 'YLHI',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lord Howe Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lord_Howe',
    utcOffset: 600,
    latitude: -31.5383,
    longitude: 159.077,
    altitude: 1.524
  },
  {
    iataCode: 'CSC',
    icaoCode: 'MRCA',
    name: 'Guapiles',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Codela Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -420,
    latitude: 10.414,
    longitude: -85.0917,
    altitude: 99.9744
  },
  {
    iataCode: 'LDK',
    icaoCode: 'ESGL',
    name: 'Lidkoping',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Lidkoping',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.465522,
    longitude: 13.174414,
    altitude: 60.96
  },
  {
    iataCode: 'TTS',
    icaoCode: 'FMNT',
    name: 'Tsaratanana',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Tsaratanana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -16.75,
    longitude: 47.617,
    altitude: 327.0504
  },
  {
    iataCode: 'CSB',
    icaoCode: 'LRCS',
    name: 'Caransebes',
    country: {code: 'RO', name: 'Romania'},
    city: 'Caransebes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 45.42,
    longitude: 22.253333,
    altitude: 263.9568
  },
  {
    iataCode: 'TTU',
    icaoCode: 'GMTN',
    name: 'Tetouan',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Saniat Rmel',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 35.594333,
    longitude: -5.320019,
    altitude: 3.048
  },
  {
    iataCode: 'TTT',
    icaoCode: 'RCFN',
    name: 'Fengnin',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Fengnin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.754986,
    longitude: 121.101681,
    altitude: 43.5864
  },
  {
    iataCode: 'CSG',
    icaoCode: 'KCSG',
    name: 'Columbus',
    country: {code: 'US', name: 'United States'},
    city: 'Columbus Metropolitan Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.516333,
    longitude: -84.938861,
    altitude: 121.0056
  },
  {
    iataCode: 'CSF',
    icaoCode: 'LFPC',
    name: 'Creil',
    country: {code: 'FR', name: 'France'},
    city: 'Creil',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.253547,
    longitude: 2.519139,
    altitude: 88.6968
  },
  {
    iataCode: 'LDN',
    icaoCode: 'VNLD',
    name: 'Lamidanda',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Lamidanda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.253117,
    longitude: 86.670044,
    altitude: 1249.68
  },
  {
    iataCode: 'CSH',
    icaoCode: 'ULAS',
    name: 'Solovetsky Islands',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Solovki Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 65.03,
    longitude: 35.7333,
    altitude: 0.0
  },
  {
    iataCode: 'CSK',
    icaoCode: 'GOGS',
    name: 'Cap Skiring',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Cap Skiring',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 12.4102,
    longitude: -16.746125,
    altitude: 15.8496
  },
  {
    iataCode: 'LDU',
    icaoCode: 'WBKD',
    name: 'Lahad Datu',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Lahad Datu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 5.032247,
    longitude: 118.324036,
    altitude: 13.716
  },
  {
    iataCode: 'LDY',
    icaoCode: 'EGAE',
    name: 'Londonderry',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Londonderry Eglinton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.042778,
    longitude: -7.161111,
    altitude: 6.7056
  },
  {
    iataCode: 'TUB',
    icaoCode: 'NTAT',
    name: 'Tubuai',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Tubuai',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -23.365353,
    longitude: -149.524072,
    altitude: 2.1336
  },
  {
    iataCode: 'TUA',
    icaoCode: 'SETU',
    name: 'Tulcan',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Teniente Coronel Luis A Mantilla',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: 0.809506,
    longitude: -77.708056,
    altitude: 2941.0152
  },
  {
    iataCode: 'TUD',
    icaoCode: 'GOTT',
    name: 'Tambacounda',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Tambacounda',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 13.736817,
    longitude: -13.653122,
    altitude: 49.0728
  },
  {
    iataCode: 'TUC',
    icaoCode: 'SANT',
    name: 'Tucuman',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Teniente Benjamin Matienzo',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Tucuman',
    utcOffset: -180,
    latitude: -26.840861,
    longitude: -65.104944,
    altitude: 455.676
  },
  {
    iataCode: 'TUF',
    icaoCode: 'LFOT',
    name: 'Tours',
    country: {code: 'FR', name: 'France'},
    city: 'Val De Loire',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.432222,
    longitude: 0.727606,
    altitude: 108.8136
  },
  {
    iataCode: 'TUG',
    icaoCode: 'RPUT',
    name: 'Tuguegarao',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Tuguegarao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 17.638311,
    longitude: 121.730614,
    altitude: 21.336
  },
  {
    iataCode: 'CSY',
    icaoCode: 'UWKS',
    name: 'Cheboksary',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Cheboksary Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 56.0903,
    longitude: 47.3473,
    altitude: 170.0784
  },
  {
    iataCode: 'LEB',
    icaoCode: 'KLEB',
    name: 'Lebanon',
    country: {code: 'US', name: 'United States'},
    city: 'Lebanon Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.6261,
    longitude: -72.3042,
    altitude: 183.7944
  },
  {
    iataCode: 'CSX',
    icaoCode: 'ZGHA',
    name: 'Changcha',
    country: {code: 'CN', name: 'China'},
    city: 'Huanghua',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 28.189158,
    longitude: 113.219633,
    altitude: 66.1416
  },
  {
    iataCode: 'LEA',
    icaoCode: 'YPLM',
    name: 'Learmonth',
    country: {code: 'AU', name: 'Australia'},
    city: 'Learmonth',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -22.235556,
    longitude: 114.088611,
    altitude: 5.7912
  },
  {
    iataCode: 'TUI',
    icaoCode: 'OETR',
    name: 'Turaif',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Turaif',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 31.692683,
    longitude: 38.7312,
    altitude: 854.3544
  },
  {
    iataCode: 'LED',
    icaoCode: 'ULLI',
    name: 'St. Petersburg',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Pulkovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 59.800292,
    longitude: 30.262503,
    altitude: 23.7744
  },
  {
    iataCode: 'TUL',
    icaoCode: 'KTUL',
    name: 'Tulsa',
    country: {code: 'US', name: 'United States'},
    city: 'Tulsa Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.198389,
    longitude: -95.888111,
    altitude: 206.3496
  },
  {
    iataCode: 'LEC',
    icaoCode: 'SBLE',
    name: 'Lençóis',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Chapada Diamantina Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -12.4823,
    longitude: -41.277,
    altitude: 510.8448
  },
  {
    iataCode: 'TUK',
    icaoCode: 'OPTU',
    name: 'Turbat',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Turbat International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.986369,
    longitude: 63.030167,
    altitude: 151.7904
  },
  {
    iataCode: 'TUN',
    icaoCode: 'DTTA',
    name: 'Tunis',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Carthage',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 36.851033,
    longitude: 10.227217,
    altitude: 6.7056
  },
  {
    iataCode: 'LEH',
    icaoCode: 'LFOH',
    name: 'Le Havre',
    country: {code: 'FR', name: 'France'},
    city: 'Octeville',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.533889,
    longitude: 0.088056,
    altitude: 95.0976
  },
  {
    iataCode: 'TUP',
    icaoCode: 'KTUP',
    name: 'Tupelo',
    country: {code: 'US', name: 'United States'},
    city: 'Tupelo Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.2681,
    longitude: -88.7699,
    altitude: 105.4608
  },
  {
    iataCode: 'TUO',
    icaoCode: 'NZAP',
    name: 'Taupo',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Taupo',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -38.739723,
    longitude: 176.084444,
    altitude: 406.908
  },
  {
    iataCode: 'CTB',
    icaoCode: 'KCTB',
    name: 'Cutbank',
    country: {code: 'US', name: 'United States'},
    city: 'Cut Bank Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 48.608353,
    longitude: -112.376144,
    altitude: 1174.6992
  },
  {
    iataCode: 'LEJ',
    icaoCode: 'EDDP',
    name: 'Leipzig',
    country: {code: 'DE', name: 'Germany'},
    city: 'Leipzig Halle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.432447,
    longitude: 12.241633,
    altitude: 141.732
  },
  {
    iataCode: 'TUR',
    icaoCode: 'SBTU',
    name: 'Tucurui',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Tucurui',
    region: '',
    terminal: '',
    timezoneId: 'America/Belem',
    utcOffset: -180,
    latitude: -3.786008,
    longitude: -49.720267,
    altitude: 252.984
  },
  {
    iataCode: 'CTA',
    icaoCode: 'LICC',
    name: 'Catania',
    country: {code: 'IT', name: 'Italy'},
    city: 'Catania Fontanarossa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 37.466781,
    longitude: 15.0664,
    altitude: 11.8872
  },
  {
    iataCode: 'LEI',
    icaoCode: 'LEAM',
    name: 'Almeria',
    country: {code: 'ES', name: 'Spain'},
    city: 'Almeria',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 36.843936,
    longitude: -2.370097,
    altitude: 21.336
  },
  {
    iataCode: 'CTD',
    icaoCode: 'MPCE',
    name: 'Chitré',
    country: {code: 'PA', name: 'Panama'},
    city: 'Alonso Valderrama Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 7.98784,
    longitude: -80.4097,
    altitude: 10.0584
  },
  {
    iataCode: 'LEL',
    icaoCode: 'YLEV',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lake Evella Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.4989,
    longitude: 135.806,
    altitude: 78.0288
  },
  {
    iataCode: 'CTC',
    icaoCode: 'SANC',
    name: 'Catamarca',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Catamarca',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -28.593214,
    longitude: -65.750925,
    altitude: 463.9056
  },
  {
    iataCode: 'LEK',
    icaoCode: 'GULB',
    name: 'Labe',
    country: {code: 'GN', name: 'Guinea'},
    city: 'Labe',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Conakry',
    utcOffset: 0,
    latitude: 11.326058,
    longitude: -12.28685,
    altitude: 1035.1008
  },
  {
    iataCode: 'TUS',
    icaoCode: 'KTUS',
    name: 'Tucson',
    country: {code: 'US', name: 'United States'},
    city: 'Tucson Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 32.116083,
    longitude: -110.941028,
    altitude: 805.5864
  },
  {
    iataCode: 'LEN',
    icaoCode: 'LELN',
    name: 'Leon',
    country: {code: 'ES', name: 'Spain'},
    city: 'Leon Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.589,
    longitude: -5.655556,
    altitude: 916.2288
  },
  {
    iataCode: 'TUV',
    icaoCode: 'SVTC',
    name: 'Tucupita',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Tucupita',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.088994,
    longitude: -62.094175,
    altitude: 32.004
  },
  {
    iataCode: 'TUU',
    icaoCode: 'OETB',
    name: 'Tabuk',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Tabuk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 28.365417,
    longitude: 36.618889,
    altitude: 777.5448
  },
  {
    iataCode: 'CTG',
    icaoCode: 'SKCG',
    name: 'Cartagena',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Rafael Nunez',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 10.442381,
    longitude: -75.512961,
    altitude: 1.2192
  },
  {
    iataCode: 'LER',
    icaoCode: 'YLST',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Leinster Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -27.8433,
    longitude: 120.703,
    altitude: 497.1288
  },
  {
    iataCode: 'LEQ',
    icaoCode: 'EGHC',
    name: "Land's End",
    country: {code: 'GB', name: 'United Kingdom'},
    city: "Land's End / St. Just Airport",
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.1028,
    longitude: -5.67056,
    altitude: 122.2248
  },
  {
    iataCode: 'CTL',
    icaoCode: 'YBCV',
    name: 'Charlieville',
    country: {code: 'AU', name: 'Australia'},
    city: 'Charleville',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -26.413334,
    longitude: 146.2625,
    altitude: 305.7144
  },
  {
    iataCode: 'LET',
    icaoCode: 'SKLT',
    name: 'Leticia',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Alfredo Vasquez Cobo',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -240,
    latitude: -4.193549,
    longitude: -69.943163,
    altitude: 84.4296
  },
  {
    iataCode: 'CTN',
    icaoCode: 'YCKN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cooktown Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -15.4447,
    longitude: 145.184,
    altitude: 7.9248
  },
  {
    iataCode: 'LEV',
    icaoCode: 'NFNB',
    name: 'Levuka',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Levuka Airfield',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.68333,
    longitude: 178.83333,
    altitude: 3.3528
  },
  {
    iataCode: 'CTM',
    icaoCode: 'MMCM',
    name: 'Chetumal',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Chetumal Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 18.504667,
    longitude: -88.326847,
    altitude: 11.8872
  },
  {
    iataCode: 'LEU',
    icaoCode: 'LESU',
    name: 'Seo De Urgel',
    country: {code: 'ES', name: 'Spain'},
    city: 'Seo De Urgel',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.338611,
    longitude: 1.409167,
    altitude: 800.1
  },
  {
    iataCode: 'LEX',
    icaoCode: 'KLEX',
    name: 'Lexington Ky',
    country: {code: 'US', name: 'United States'},
    city: 'Blue Grass',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.0365,
    longitude: -84.605889,
    altitude: 298.3992
  },
  {
    iataCode: 'TVA',
    icaoCode: 'FMMR',
    name: 'Morafenobe',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Morafenobe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -17.85,
    longitude: 44.917,
    altitude: 227.9904
  },
  {
    iataCode: 'TVC',
    icaoCode: 'KTVC',
    name: 'Traverse City',
    country: {code: 'US', name: 'United States'},
    city: 'Cherry Capital Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 44.741445,
    longitude: -85.582235,
    altitude: 190.1952
  },
  {
    iataCode: 'CTT',
    icaoCode: 'LFMQ',
    name: 'Le Castellet',
    country: {code: 'FR', name: 'France'},
    city: 'Le Castellet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.252506,
    longitude: 5.785189,
    altitude: 423.9768
  },
  {
    iataCode: 'CTS',
    icaoCode: 'RJCC',
    name: 'Sapporo',
    country: {code: 'JP', name: 'Japan'},
    city: 'New Chitose',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 42.7752,
    longitude: 141.692283,
    altitude: 24.9936
  },
  {
    iataCode: 'CTU',
    icaoCode: 'ZUUU',
    name: 'Chengdu',
    country: {code: 'CN', name: 'China'},
    city: 'Shuangliu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 30.578528,
    longitude: 103.947086,
    altitude: 495.3
  },
  {
    iataCode: 'TVF',
    icaoCode: 'KTVF',
    name: 'Thief River Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Thief River Falls',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.065556,
    longitude: -96.185,
    altitude: 340.1568
  },
  {
    iataCode: 'TVL',
    icaoCode: 'KTVL',
    name: 'South Lake Tahoe',
    country: {code: 'US', name: 'United States'},
    city: 'Lake Tahoe Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.893889,
    longitude: -119.995278,
    altitude: 2604.2112
  },
  {
    iataCode: 'CUA',
    icaoCode: 'MMDA',
    name: 'Ciudad Constitución',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ciudad Constitución Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 25.0538,
    longitude: -111.615,
    altitude: 64.9224
  },
  {
    iataCode: 'LFI',
    icaoCode: 'KLFI',
    name: 'Hampton',
    country: {code: 'US', name: 'United States'},
    city: 'Langley Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.082881,
    longitude: -76.360547,
    altitude: 3.3528
  },
  {
    iataCode: 'CUC',
    icaoCode: 'SKCC',
    name: 'Cucuta',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Camilo Daza',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -240,
    latitude: 7.927567,
    longitude: -72.511547,
    altitude: 334.0608
  },
  {
    iataCode: 'LFK',
    icaoCode: 'KLFK',
    name: 'Lufkin',
    country: {code: 'US', name: 'United States'},
    city: 'Angelina Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.234014,
    longitude: -94.75,
    altitude: 90.2208
  },
  {
    iataCode: 'CUE',
    icaoCode: 'SECU',
    name: 'Cuenca',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Mariscal Lamar',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -2.889467,
    longitude: -78.984397,
    altitude: 2531.6688
  },
  {
    iataCode: 'TVU',
    icaoCode: 'NFNM',
    name: 'Matei',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Matei Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -16.6906,
    longitude: -179.877,
    altitude: 18.288
  },
  {
    iataCode: 'CUF',
    icaoCode: 'LIMZ',
    name: 'Levaldigi',
    country: {code: 'IT', name: 'Italy'},
    city: 'Levaldigi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.547019,
    longitude: 7.623217,
    altitude: 386.1816
  },
  {
    iataCode: 'TVY',
    icaoCode: 'VYDW',
    name: 'Dawei',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Dawei Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 14.103886,
    longitude: 98.203636,
    altitude: 25.6032
  },
  {
    iataCode: 'LFR',
    icaoCode: 'SVLF',
    name: 'La Fria',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'La Fria',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.239167,
    longitude: -72.271028,
    altitude: 98.4504
  },
  {
    iataCode: 'CUM',
    icaoCode: 'SVCU',
    name: 'Cumana',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Antonio Jose De Sucre',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.450333,
    longitude: -64.130472,
    altitude: 7.62
  },
  {
    iataCode: 'CUL',
    icaoCode: 'MMCL',
    name: 'Culiacan',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Culiacan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 24.764547,
    longitude: -107.474717,
    altitude: 32.9184
  },
  {
    iataCode: 'LFT',
    icaoCode: 'KLFT',
    name: 'Lafayette',
    country: {code: 'US', name: 'United States'},
    city: 'Lafayette Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.205278,
    longitude: -91.987611,
    altitude: 13.1064
  },
  {
    iataCode: 'LFW',
    icaoCode: 'DXXX',
    name: 'Lome',
    country: {code: 'TG', name: 'Togo'},
    city: 'Gnassingbe Eyadema Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lome',
    utcOffset: 60,
    latitude: 6.165611,
    longitude: 1.254511,
    altitude: 21.9456
  },
  {
    iataCode: 'CUN',
    icaoCode: 'MMUN',
    name: 'Cancun',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Cancun Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Cancun',
    utcOffset: -360,
    latitude: 21.036528,
    longitude: -86.877083,
    altitude: 6.096
  },
  {
    iataCode: 'CUQ',
    icaoCode: 'YCOE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Coen Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -13.7608,
    longitude: 143.114,
    altitude: 162.1536
  },
  {
    iataCode: 'TWB',
    icaoCode: 'YTWB',
    name: 'Tawoomba',
    country: {code: 'AU', name: 'Australia'},
    city: 'Tawoomba',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -27.542778,
    longitude: 151.916389,
    altitude: 635.8128
  },
  {
    iataCode: 'CUP',
    icaoCode: 'SVCP',
    name: 'Carupano',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'General Jose Francisco Bermudez',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.660014,
    longitude: -63.261681,
    altitude: 10.0584
  },
  {
    iataCode: 'CUR',
    icaoCode: 'TNCC',
    name: 'Willemstad',
    country: {code: '', name: 'Netherlands Antilles'},
    city: 'Hato',
    region: '',
    terminal: '',
    timezoneId: 'America/Curacao',
    utcOffset: -240,
    latitude: 12.188853,
    longitude: -68.959803,
    altitude: 8.8392
  },
  {
    iataCode: 'CUU',
    icaoCode: 'MMCU',
    name: 'Chihuahua',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General R Fierro Villalobos Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chihuahua',
    utcOffset: -360,
    latitude: 28.702875,
    longitude: -105.964567,
    altitude: 1360.0176
  },
  {
    iataCode: 'TWF',
    icaoCode: 'KTWF',
    name: 'Twin Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Magic Valley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 42.481803,
    longitude: -114.487733,
    altitude: 1265.2248
  },
  {
    iataCode: 'LGB',
    icaoCode: 'KLGB',
    name: 'Long Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Long Beach',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.817722,
    longitude: -118.151611,
    altitude: 18.288
  },
  {
    iataCode: 'LGA',
    icaoCode: 'KLGA',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'La Guardia',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.777245,
    longitude: -73.872608,
    altitude: 6.7056
  },
  {
    iataCode: 'CUZ',
    icaoCode: 'SPZO',
    name: 'Cuzco',
    country: {code: 'PE', name: 'Peru'},
    city: 'Teniente Alejandro Velasco Astete Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -13.535722,
    longitude: -71.938781,
    altitude: 3310.128
  },
  {
    iataCode: 'LGG',
    icaoCode: 'EBLG',
    name: 'Liege',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Liege',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 50.637417,
    longitude: 5.443222,
    altitude: 200.8632
  },
  {
    iataCode: 'LGI',
    icaoCode: 'MYLD',
    name: "Dead Man's Cay",
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Deadmans Cay',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 23.179014,
    longitude: -75.093597,
    altitude: 2.7432
  },
  {
    iataCode: 'LGL',
    icaoCode: 'WBGF',
    name: 'Long Datih',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Long Lellang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.421,
    longitude: 115.154,
    altitude: 426.72
  },
  {
    iataCode: 'LGK',
    icaoCode: 'WMKL',
    name: 'Pulau',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Langkawi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 6.329728,
    longitude: 99.728667,
    altitude: 8.8392
  },
  {
    iataCode: 'CVF',
    icaoCode: 'LFLJ',
    name: 'Courcheval',
    country: {code: 'FR', name: 'France'},
    city: 'Courchevel Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.3967,
    longitude: 6.63472,
    altitude: 2008.0224
  },
  {
    iataCode: 'TWU',
    icaoCode: 'WBKW',
    name: 'Tawau',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Tawau',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 4.313369,
    longitude: 118.121953,
    altitude: 17.3736
  },
  {
    iataCode: 'LGP',
    icaoCode: 'RPLP',
    name: 'Legazpi',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Legazpi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 13.157064,
    longitude: 123.746247,
    altitude: 20.1168
  },
  {
    iataCode: 'CVG',
    icaoCode: 'KCVG',
    name: 'Cincinnati',
    country: {code: 'US', name: 'United States'},
    city: 'Cincinnati Northern Kentucky Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.048836,
    longitude: -84.667822,
    altitude: 273.1008
  },
  {
    iataCode: 'LGO',
    icaoCode: 'EDWL',
    name: 'Langeoog',
    country: {code: 'DE', name: 'Germany'},
    city: 'Langeoog Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: -60,
    latitude: 53.7425,
    longitude: 7.49778,
    altitude: 2.1336
  },
  {
    iataCode: 'CVJ',
    icaoCode: 'MMCB',
    name: 'Cuernavaca',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Mariano Matamoros',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 18.834764,
    longitude: -99.2613,
    altitude: 1303.6296
  },
  {
    iataCode: 'LGQ',
    icaoCode: 'SELA',
    name: 'Lago Agrio',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Nueva Loja Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: 0.093056,
    longitude: -76.8675,
    altitude: 298.704
  },
  {
    iataCode: 'LGS',
    icaoCode: 'SAMM',
    name: 'Malargue',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Malargue',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Mendoza',
    utcOffset: -180,
    latitude: -35.493597,
    longitude: -69.574267,
    altitude: 1427.3784
  },
  {
    iataCode: 'CVN',
    icaoCode: 'KCVN',
    name: 'Clovis',
    country: {code: 'US', name: 'United States'},
    city: 'Clovis Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 34.425139,
    longitude: -103.079278,
    altitude: 1285.0368
  },
  {
    iataCode: 'CVM',
    icaoCode: 'MMCV',
    name: 'Ciudad Victoria',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Pedro Jose Mendez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 23.703336,
    longitude: -98.956486,
    altitude: 231.9528
  },
  {
    iataCode: 'LGU',
    icaoCode: 'KLGU',
    name: 'Logan',
    country: {code: 'US', name: 'United States'},
    city: 'Logan-cache',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.791,
    longitude: -111.852,
    altitude: 1358.4936
  },
  {
    iataCode: 'TXA',
    icaoCode: 'MMTA',
    name: 'Tlaxcala',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tlaxcala',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.537964,
    longitude: -98.173467,
    altitude: 2508.1992
  },
  {
    iataCode: 'LGW',
    icaoCode: 'EGKK',
    name: 'London',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Gatwick',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.148056,
    longitude: -0.190278,
    altitude: 61.5696
  },
  {
    iataCode: 'CVQ',
    icaoCode: 'YCAR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Carnarvon Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -24.8806,
    longitude: 113.672,
    altitude: 3.9624
  },
  {
    iataCode: 'CVT',
    icaoCode: 'EGBE',
    name: 'Coventry',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Coventry',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.369722,
    longitude: -1.479722,
    altitude: 81.3816
  },
  {
    iataCode: 'CVS',
    icaoCode: 'KCVS',
    name: 'Clovis',
    country: {code: 'US', name: 'United States'},
    city: 'Cannon Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 34.382775,
    longitude: -103.322147,
    altitude: 1309.116
  },
  {
    iataCode: 'CVU',
    icaoCode: 'LPCR',
    name: 'Corvo',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Corvo',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 39.6715,
    longitude: -31.1136,
    altitude: 0.0
  },
  {
    iataCode: 'LHC',
    icaoCode: 'SPBC',
    name: 'Caballococha',
    country: {code: 'PE', name: 'Peru'},
    city: 'Caballococha Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -3.916858,
    longitude: -70.508225,
    altitude: 99.9744
  },
  {
    iataCode: 'TXK',
    icaoCode: 'KTXK',
    name: 'Texarkana',
    country: {code: 'US', name: 'United States'},
    city: 'Texarkana Rgnl Webb Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.453722,
    longitude: -93.991028,
    altitude: 118.872
  },
  {
    iataCode: 'LHE',
    icaoCode: 'OPLA',
    name: 'Lahore',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Allama Iqbal Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 31.521564,
    longitude: 74.403594,
    altitude: 217.0176
  },
  {
    iataCode: 'TXL',
    icaoCode: 'EDDT',
    name: 'Berlin',
    country: {code: 'DE', name: 'Germany'},
    city: 'Tegel',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.559686,
    longitude: 13.287711,
    altitude: 37.1856
  },
  {
    iataCode: 'LHG',
    icaoCode: 'YLRD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lightning Ridge Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -29.4567,
    longitude: 147.984,
    altitude: 164.592
  },
  {
    iataCode: 'TXN',
    icaoCode: 'ZSTX',
    name: 'Huangshan',
    country: {code: 'CN', name: 'China'},
    city: 'Tunxi International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.7333,
    longitude: 118.256,
    altitude: 0.0
  },
  {
    iataCode: 'CWA',
    icaoCode: 'KCWA',
    name: 'Wassau',
    country: {code: 'US', name: 'United States'},
    city: 'Central Wisconsin',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.772726,
    longitude: -89.646635,
    altitude: 256.032
  },
  {
    iataCode: 'CWC',
    icaoCode: 'UKLN',
    name: 'Chernovtsk',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Chernivtsi International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Uzhgorod',
    utcOffset: 120,
    latitude: 48.259322,
    longitude: 25.980831,
    altitude: 251.7648
  },
  {
    iataCode: 'CWB',
    icaoCode: 'SBCT',
    name: 'Curitiba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Afonso Pena',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -25.528475,
    longitude: -49.175775,
    altitude: 910.7424
  },
  {
    iataCode: 'LHS',
    icaoCode: 'SAVH',
    name: 'Las Heras',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Las Heras Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -46.533056,
    longitude: -68.951111,
    altitude: 329.7936
  },
  {
    iataCode: 'LHR',
    icaoCode: 'EGLL',
    name: 'London',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Heathrow',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.4775,
    longitude: -0.461389,
    altitude: 25.2984
  },
  {
    iataCode: 'CWL',
    icaoCode: 'EGFF',
    name: 'Cardiff',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Cardiff',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.396667,
    longitude: -3.343333,
    altitude: 67.056
  },
  {
    iataCode: 'LHW',
    icaoCode: 'ZLAN',
    name: 'Lanzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Lanzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 36.117,
    longitude: 103.617,
    altitude: 0.0
  },
  {
    iataCode: 'LHX',
    icaoCode: 'KLHX',
    name: 'La Junta',
    country: {code: 'US', name: 'United States'},
    city: 'La Junta Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.049719,
    longitude: -103.509431,
    altitude: 1291.7424
  },
  {
    iataCode: 'TYF',
    icaoCode: 'ESST',
    name: 'Torsby',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Torsby Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 60.157622,
    longitude: 12.991269,
    altitude: 119.7864
  },
  {
    iataCode: 'LID',
    icaoCode: 'EHVB',
    name: 'Valkenburg',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Valkenburg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 52.166139,
    longitude: 4.417944,
    altitude: 0.0
  },
  {
    iataCode: 'TYL',
    icaoCode: 'SPYL',
    name: 'Talara',
    country: {code: 'PE', name: 'Peru'},
    city: 'Capitan Montes',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -4.576639,
    longitude: -81.254139,
    altitude: 85.9536
  },
  {
    iataCode: 'LIF',
    icaoCode: 'NWWL',
    name: 'Lifou',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Lifou',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -20.7748,
    longitude: 167.239864,
    altitude: 28.0416
  },
  {
    iataCode: 'TYN',
    icaoCode: 'ZBYN',
    name: 'Taiyuan',
    country: {code: 'CN', name: 'China'},
    city: 'Wusu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 37.746897,
    longitude: 112.628428,
    altitude: 784.86
  },
  {
    iataCode: 'LIH',
    icaoCode: 'PHLI',
    name: 'Lihue',
    country: {code: 'US', name: 'United States'},
    city: 'Lihue',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.975983,
    longitude: -159.338958,
    altitude: 46.6344
  },
  {
    iataCode: 'LIG',
    icaoCode: 'LFBL',
    name: 'Limoges',
    country: {code: 'FR', name: 'France'},
    city: 'Bellegarde',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.862778,
    longitude: 1.179444,
    altitude: 396.24
  },
  {
    iataCode: 'CXB',
    icaoCode: 'VGCB',
    name: "Cox's Bazar",
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Coxs Bazar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 21.452194,
    longitude: 91.963889,
    altitude: 3.6576
  },
  {
    iataCode: 'TYR',
    icaoCode: 'KTYR',
    name: 'Tyler',
    country: {code: 'US', name: 'United States'},
    city: 'Tyler Pounds Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.354139,
    longitude: -95.402386,
    altitude: 165.8112
  },
  {
    iataCode: 'LIL',
    icaoCode: 'LFQQ',
    name: 'Lille',
    country: {code: 'FR', name: 'France'},
    city: 'Lesquin',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 50.561942,
    longitude: 3.089444,
    altitude: 47.8536
  },
  {
    iataCode: 'TYS',
    icaoCode: 'KTYS',
    name: 'Knoxville',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Ghee Tyson',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.810972,
    longitude: -83.994028,
    altitude: 299.0088
  },
  {
    iataCode: 'LIN',
    icaoCode: 'LIML',
    name: 'Milan',
    country: {code: 'IT', name: 'Italy'},
    city: 'Linate',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.445103,
    longitude: 9.276739,
    altitude: 107.5944
  },
  {
    iataCode: 'LIM',
    icaoCode: 'SPIM',
    name: 'Lima',
    country: {code: 'PE', name: 'Peru'},
    city: 'Jorge Chavez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -12.021889,
    longitude: -77.114319,
    altitude: 34.4424
  },
  {
    iataCode: 'CXH',
    icaoCode: 'CAQ3',
    name: 'Vancouver',
    country: {code: 'CA', name: 'Canada'},
    city: 'Vancouver Coal Harbour',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.289722,
    longitude: -123.115833,
    altitude: 0.0
  },
  {
    iataCode: 'LIP',
    icaoCode: 'SBLN',
    name: 'Lins',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Lins',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.664039,
    longitude: -49.730519,
    altitude: 480.06
  },
  {
    iataCode: 'LIO',
    icaoCode: 'MRLM',
    name: 'Limon',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Limon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.957961,
    longitude: -83.022006,
    altitude: 2.1336
  },
  {
    iataCode: 'CXJ',
    icaoCode: 'SBCX',
    name: 'Caxias Do Sul',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Campo Dos Bugres',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -29.197064,
    longitude: -51.187536,
    altitude: 753.4656
  },
  {
    iataCode: 'LIR',
    icaoCode: 'MRLB',
    name: 'Liberia',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Daniel Oduber Quiros Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.593289,
    longitude: -85.544408,
    altitude: 82.296
  },
  {
    iataCode: 'CXI',
    icaoCode: 'PLCH',
    name: 'Kiritimati',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Christmas Island',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Kiritimati',
    utcOffset: -720,
    latitude: 1.986161,
    longitude: -157.349778,
    altitude: 1.524
  },
  {
    iataCode: 'LIQ',
    icaoCode: 'FZGA',
    name: 'Lisala',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Lisala',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: 2.170658,
    longitude: 21.496906,
    altitude: 459.9432
  },
  {
    iataCode: 'CXL',
    icaoCode: 'KCXL',
    name: 'Calexico',
    country: {code: 'US', name: 'United States'},
    city: 'Calexico Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.669502,
    longitude: -115.51333,
    altitude: 1.2192
  },
  {
    iataCode: 'LIT',
    icaoCode: 'KLIT',
    name: 'Little Rock',
    country: {code: 'US', name: 'United States'},
    city: 'Adams Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.729444,
    longitude: -92.224306,
    altitude: 79.8576
  },
  {
    iataCode: 'LIS',
    icaoCode: 'LPPT',
    name: 'Lisboa',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Lisboa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Lisbon',
    utcOffset: 0,
    latitude: 38.781311,
    longitude: -9.135919,
    altitude: 113.9952
  },
  {
    iataCode: 'CXP',
    icaoCode: 'WIHL',
    name: 'Cilacap',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Tunggul Wulung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -7.645056,
    longitude: 109.033911,
    altitude: 21.0312
  },
  {
    iataCode: 'CXO',
    icaoCode: 'KCXO',
    name: 'Conroe',
    country: {code: 'US', name: 'United States'},
    city: 'Lone Star Executive',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.351833,
    longitude: -95.414467,
    altitude: 74.676
  },
  {
    iataCode: 'LIW',
    icaoCode: 'VYLK',
    name: 'Loikaw',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Loikaw Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 19.691494,
    longitude: 97.214825,
    altitude: 896.112
  },
  {
    iataCode: 'CXR',
    icaoCode: 'VVCR',
    name: 'Nha Trang',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Cam Ranh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 11.998153,
    longitude: 109.219372,
    altitude: 12.192
  },
  {
    iataCode: 'LJA',
    icaoCode: 'FZVA',
    name: 'Lodja',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Lodja Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -3.417,
    longitude: 23.45,
    altitude: 502.0056
  },
  {
    iataCode: 'LJG',
    icaoCode: 'ZPLJ',
    name: 'Lijiang',
    country: {code: 'CN', name: 'China'},
    city: 'Lijiang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 26.883333,
    longitude: 100.23333,
    altitude: 2414.9304
  },
  {
    iataCode: 'CYB',
    icaoCode: 'MWCB',
    name: 'Cayman Barac',
    country: {code: 'KY', name: 'Cayman Islands'},
    city: 'Gerrard Smith Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Cayman',
    utcOffset: -300,
    latitude: 19.686981,
    longitude: -79.882789,
    altitude: 2.4384
  },
  {
    iataCode: 'CYF',
    icaoCode: 'PACK',
    name: 'Chefornak',
    country: {code: 'US', name: 'United States'},
    city: 'Chefornak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 60.149167,
    longitude: -164.285556,
    altitude: 12.192
  },
  {
    iataCode: 'CYI',
    icaoCode: 'RCKU',
    name: 'Chiayi',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Chiayi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 23.461779,
    longitude: 120.39283,
    altitude: 25.908
  },
  {
    iataCode: 'TZX',
    icaoCode: 'LTCG',
    name: 'Trabzon',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Trabzon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.995108,
    longitude: 39.789728,
    altitude: 31.6992
  },
  {
    iataCode: 'LJU',
    icaoCode: 'LJLJ',
    name: 'Ljubliana',
    country: {code: 'SI', name: 'Slovenia'},
    city: 'Ljubljana',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Ljubljana',
    utcOffset: 60,
    latitude: 46.223686,
    longitude: 14.457611,
    altitude: 388.0104
  },
  {
    iataCode: 'CYO',
    icaoCode: 'MUCL',
    name: 'Cayo',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Vilo Acuna Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 21.616453,
    longitude: -81.545989,
    altitude: 3.048
  },
  {
    iataCode: 'CYP',
    icaoCode: 'RPVC',
    name: 'Calbayog City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Calbayog Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 12.072706,
    longitude: 124.545092,
    altitude: 3.6576
  },
  {
    iataCode: 'CYS',
    icaoCode: 'KCYS',
    name: 'Cheyenne',
    country: {code: 'US', name: 'United States'},
    city: 'Cheyenne Rgnl Jerry Olson Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.155722,
    longitude: -104.811839,
    altitude: 1876.3488
  },
  {
    iataCode: 'CYR',
    icaoCode: 'SUCA',
    name: 'Colonia',
    country: {code: 'UY', name: 'Uruguay'},
    city: 'Laguna De Los Patos International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montevideo',
    utcOffset: -240,
    latitude: -34.4564,
    longitude: -57.7706,
    altitude: 20.1168
  },
  {
    iataCode: 'CYU',
    icaoCode: 'RPLO',
    name: 'Cuyo',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Cuyo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 10.8581,
    longitude: 121.069,
    altitude: 0.0
  },
  {
    iataCode: 'CYT',
    icaoCode: 'PACY',
    name: 'Yakataga',
    country: {code: 'US', name: 'United States'},
    city: 'Yakataga Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.081901,
    longitude: -142.493611,
    altitude: 3.6576
  },
  {
    iataCode: 'CYW',
    icaoCode: 'MMCY',
    name: 'Celaya',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Captain Rogelio Castillo National Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.545994,
    longitude: -100.88655,
    altitude: 1740.1032
  },
  {
    iataCode: 'LKB',
    icaoCode: 'NFNK',
    name: 'Lakeba Island',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Lakeba Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -18.1992,
    longitude: -178.817,
    altitude: 85.344
  },
  {
    iataCode: 'CYX',
    icaoCode: 'UESS',
    name: 'Cherskiy',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Cherskiy Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Magadan',
    utcOffset: 720,
    latitude: 68.7406,
    longitude: 161.338,
    altitude: 6.096
  },
  {
    iataCode: 'CYZ',
    icaoCode: 'RPUY',
    name: 'Cauayan',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Cauayan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 16.929861,
    longitude: 121.753036,
    altitude: 60.96
  },
  {
    iataCode: 'LKE',
    icaoCode: 'KW55',
    name: 'Seattle',
    country: {code: 'US', name: 'United States'},
    city: 'Kenmore Air Harbor Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 47.629,
    longitude: -122.339,
    altitude: 4.2672
  },
  {
    iataCode: 'LKH',
    icaoCode: 'WBGL',
    name: 'Long Akah',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Long Akah Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.3,
    longitude: 114.783,
    altitude: 88.0872
  },
  {
    iataCode: 'LKG',
    icaoCode: 'HKLK',
    name: 'Lokichoggio',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Lokichoggio Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 4.204117,
    longitude: 34.348186,
    altitude: 632.1552
  },
  {
    iataCode: 'LKL',
    icaoCode: 'ENNA',
    name: 'Lakselv',
    country: {code: 'NO', name: 'Norway'},
    city: 'Banak',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.068814,
    longitude: 24.973489,
    altitude: 7.62
  },
  {
    iataCode: 'CZF',
    icaoCode: 'PACZ',
    name: 'Cape Romanzof',
    country: {code: 'US', name: 'United States'},
    city: 'Cape Romanzof Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 61.780297,
    longitude: -166.038747,
    altitude: 139.2936
  },
  {
    iataCode: 'LKN',
    icaoCode: 'ENLK',
    name: 'Leknes',
    country: {code: 'NO', name: 'Norway'},
    city: 'Leknes Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 68.1525,
    longitude: 13.6094,
    altitude: 23.7744
  },
  {
    iataCode: 'CZE',
    icaoCode: 'SVCR',
    name: 'Coro',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Jose Leonardo Chirinos',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 11.414867,
    longitude: -69.681656,
    altitude: 15.8496
  },
  {
    iataCode: 'LKO',
    icaoCode: 'VILK',
    name: 'Lucknow',
    country: {code: 'IN', name: 'India'},
    city: 'Lucknow',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.760594,
    longitude: 80.889339,
    altitude: 124.968
  },
  {
    iataCode: 'CZL',
    icaoCode: 'DABC',
    name: 'Constantine',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Mohamed Boudiaf Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.276028,
    longitude: 6.620386,
    altitude: 690.372
  },
  {
    iataCode: 'LKS',
    icaoCode: 'LKSZ',
    name: 'Sazena',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Sazena',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 50.1929,
    longitude: 14.1532,
    altitude: 71.0184
  },
  {
    iataCode: 'CZM',
    icaoCode: 'MMCZ',
    name: 'Cozumel',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Cozumel Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Cancun',
    utcOffset: -360,
    latitude: 20.522403,
    longitude: -86.925644,
    altitude: 4.572
  },
  {
    iataCode: 'CZS',
    icaoCode: 'SBCZ',
    name: 'Cruiziro Do Sul',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Cruzeiro Do Sul',
    region: '',
    terminal: '',
    timezoneId: 'America/Eirunepe',
    utcOffset: -300,
    latitude: -7.599906,
    longitude: -72.769489,
    altitude: 194.1576
  },
  {
    iataCode: 'CZU',
    icaoCode: 'SKCZ',
    name: 'Corozal',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Las Brujas',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 9.332742,
    longitude: -75.285594,
    altitude: 160.9344
  },
  {
    iataCode: 'CZX',
    icaoCode: 'ZSCG',
    name: 'Changzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Changzhou',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.941667,
    longitude: 119.711667,
    altitude: 11.8872
  },
  {
    iataCode: 'LLA',
    icaoCode: 'ESPA',
    name: 'Lulea',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kallax',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 65.543758,
    longitude: 22.121989,
    altitude: 19.812
  },
  {
    iataCode: 'LLI',
    icaoCode: 'HALL',
    name: 'Lalibella',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Lalibella',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 11.975014,
    longitude: 38.979969,
    altitude: 1957.7304
  },
  {
    iataCode: 'LLU',
    icaoCode: 'BGAP',
    name: 'Alluitsup Paa',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Alluitsup Paa Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 60.4644,
    longitude: -45.5778,
    altitude: 27.1272
  },
  {
    iataCode: 'LLW',
    icaoCode: 'FWKI',
    name: 'Lilongwe',
    country: {code: 'MW', name: 'Malawi'},
    city: 'Kamuzu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Blantyre',
    utcOffset: 120,
    latitude: -13.789378,
    longitude: 33.781,
    altitude: 1229.868
  },
  {
    iataCode: 'LME',
    icaoCode: 'LFRM',
    name: 'Le Mans',
    country: {code: 'FR', name: 'France'},
    city: 'Arnage',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.948611,
    longitude: 0.201667,
    altitude: 59.1312
  },
  {
    iataCode: 'LMN',
    icaoCode: 'WBGJ',
    name: 'Limbang',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Limbang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 4.808303,
    longitude: 115.010439,
    altitude: 4.2672
  },
  {
    iataCode: 'LMM',
    icaoCode: 'MMLM',
    name: 'Los Mochis',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Valle Del Fuerte Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 25.685194,
    longitude: -109.080806,
    altitude: 4.8768
  },
  {
    iataCode: 'LMP',
    icaoCode: 'LICD',
    name: 'Lampedusa',
    country: {code: 'IT', name: 'Italy'},
    city: 'Lampedusa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 35.497914,
    longitude: 12.618083,
    altitude: 21.336
  },
  {
    iataCode: 'LMO',
    icaoCode: 'EGQS',
    name: 'Lossiemouth',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Lossiemouth',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 57.705214,
    longitude: -3.339169,
    altitude: 12.8016
  },
  {
    iataCode: 'LMT',
    icaoCode: 'KLMT',
    name: 'Klamath Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Klamath Falls Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 42.1561,
    longitude: -121.733,
    altitude: 1248.156
  },
  {
    iataCode: 'LNA',
    icaoCode: 'KLNA',
    name: 'West Palm Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Palm Beach Co Park',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.593,
    longitude: -80.085056,
    altitude: 4.2672
  },
  {
    iataCode: 'LNB',
    icaoCode: 'NVSM',
    name: 'Lamen Bay',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Lamen Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.5842,
    longitude: 168.159,
    altitude: 2.1336
  },
  {
    iataCode: 'LNE',
    icaoCode: 'NVSO',
    name: 'Lonorore',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Lonorore Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.8656,
    longitude: 168.172,
    altitude: 13.1064
  },
  {
    iataCode: 'LNK',
    icaoCode: 'KLNK',
    name: 'Lincoln',
    country: {code: 'US', name: 'United States'},
    city: 'Lincoln',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.850971,
    longitude: -96.75925,
    altitude: 371.5512
  },
  {
    iataCode: 'LNJ',
    icaoCode: 'ZPLC',
    name: 'Lincang',
    country: {code: 'CN', name: 'China'},
    city: 'Lincang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 23.738333,
    longitude: 100.025,
    altitude: 457.2
  },
  {
    iataCode: 'LNO',
    icaoCode: 'YLEO',
    name: 'Leonora',
    country: {code: 'AU', name: 'Australia'},
    city: 'Leonora Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -28.8781,
    longitude: 121.315,
    altitude: 370.9416
  },
  {
    iataCode: 'LNS',
    icaoCode: 'KLNS',
    name: 'Lancaster',
    country: {code: 'US', name: 'United States'},
    city: 'Lancaster Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.1217,
    longitude: -76.2961,
    altitude: 122.8344
  },
  {
    iataCode: 'LNY',
    icaoCode: 'PHNY',
    name: 'Lanai',
    country: {code: 'US', name: 'United States'},
    city: 'Lanai',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.785611,
    longitude: -156.951419,
    altitude: 398.6784
  },
  {
    iataCode: 'LNZ',
    icaoCode: 'LOWL',
    name: 'Linz',
    country: {code: 'AT', name: 'Austria'},
    city: 'Linz',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 48.233219,
    longitude: 14.187511,
    altitude: 298.0944
  },
  {
    iataCode: 'LOD',
    icaoCode: 'NVSG',
    name: 'Longana',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Longana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.3067,
    longitude: 167.967,
    altitude: 50.9016
  },
  {
    iataCode: 'LOE',
    icaoCode: 'VTUL',
    name: 'Loei',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Loei',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 17.439133,
    longitude: 101.722064,
    altitude: 262.128
  },
  {
    iataCode: 'LOH',
    icaoCode: 'SETM',
    name: 'La Toma (Catamayo)',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Camilo Ponce Enriquez Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -3.99589,
    longitude: -79.3719,
    altitude: 1236.2688
  },
  {
    iataCode: 'LOK',
    icaoCode: 'HKLO',
    name: 'Lodwar',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Lodwar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 3.121967,
    longitude: 35.608692,
    altitude: 522.732
  },
  {
    iataCode: 'LOO',
    icaoCode: 'DAUL',
    name: 'Laghouat',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Laghouat',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 33.764383,
    longitude: 2.928344,
    altitude: 765.048
  },
  {
    iataCode: 'LOS',
    icaoCode: 'DNMM',
    name: 'Lagos',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Murtala Muhammed',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 6.577369,
    longitude: 3.321156,
    altitude: 41.148
  },
  {
    iataCode: 'LOV',
    icaoCode: 'MMMV',
    name: 'Monclova',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Monclova Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 26.955733,
    longitude: -101.470136,
    altitude: 568.1472
  },
  {
    iataCode: 'LOU',
    icaoCode: 'KLOU',
    name: 'Louisville',
    country: {code: 'US', name: 'United States'},
    city: 'Bowman Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Kentucky/Louisville',
    utcOffset: -300,
    latitude: 38.228,
    longitude: -85.663722,
    altitude: 166.4208
  },
  {
    iataCode: 'UAB',
    icaoCode: 'KUAB',
    name: 'Adana',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Adana-incirlik Airbase',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.00028,
    longitude: 35.41833,
    altitude: 152.4
  },
  {
    iataCode: 'LPA',
    icaoCode: 'GCLP',
    name: 'Gran Canaria',
    country: {code: 'ES', name: 'Spain'},
    city: 'Gran Canaria',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 27.931886,
    longitude: -15.386586,
    altitude: 23.7744
  },
  {
    iataCode: 'UAH',
    icaoCode: 'NTMU',
    name: 'Ua Huka',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Ua Huka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Marquesas',
    utcOffset: -540,
    latitude: -8.93611,
    longitude: -139.552,
    altitude: 48.768
  },
  {
    iataCode: 'UAK',
    icaoCode: 'BGBW',
    name: 'Narssarssuaq',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Narsarsuaq',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 61.160517,
    longitude: -45.425978,
    altitude: 34.1376
  },
  {
    iataCode: 'LPB',
    icaoCode: 'SLLP',
    name: 'La Paz',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'El Alto Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -16.513339,
    longitude: -68.192256,
    altitude: 4061.46
  },
  {
    iataCode: 'UAM',
    icaoCode: 'PGUA',
    name: 'Andersen',
    country: {code: 'GU', name: 'Guam'},
    city: 'Andersen Afb',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guam',
    utcOffset: 600,
    latitude: 13.583953,
    longitude: 144.930025,
    altitude: 191.1096
  },
  {
    iataCode: 'LPD',
    icaoCode: 'SKLP',
    name: 'La Pedrera',
    country: {code: 'CO', name: 'Colombia'},
    city: 'La Pedrera Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -240,
    latitude: -1.33,
    longitude: -69.58,
    altitude: 179.832
  },
  {
    iataCode: 'LPG',
    icaoCode: 'SADL',
    name: 'La Plata',
    country: {code: 'AR', name: 'Argentina'},
    city: 'La Plata',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -34.972222,
    longitude: -57.894694,
    altitude: 21.9456
  },
  {
    iataCode: 'LPI',
    icaoCode: 'ESSL',
    name: 'Linkoeping',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Saab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.40615,
    longitude: 15.680508,
    altitude: 52.4256
  },
  {
    iataCode: 'UAQ',
    icaoCode: 'SANU',
    name: 'San Julian',
    country: {code: 'AR', name: 'Argentina'},
    city: 'San Juan',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/San_Juan',
    utcOffset: -180,
    latitude: -31.571472,
    longitude: -68.418194,
    altitude: 597.1032
  },
  {
    iataCode: 'UAP',
    icaoCode: 'NTMP',
    name: 'Ua Pou',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Ua Pou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Marquesas',
    utcOffset: -540,
    latitude: -9.35167,
    longitude: -140.078,
    altitude: 4.8768
  },
  {
    iataCode: 'LPK',
    icaoCode: 'UUOL',
    name: 'Lipetsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Lipetsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 52.7028,
    longitude: 39.5378,
    altitude: 178.0032
  },
  {
    iataCode: 'UAS',
    icaoCode: 'HKSB',
    name: 'Samburu South',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Samburu South Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 0.530583,
    longitude: 37.5342,
    altitude: 1004.316
  },
  {
    iataCode: 'LPM',
    icaoCode: 'NVSL',
    name: 'Lamap',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Lamap Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.454,
    longitude: 167.823,
    altitude: 2.1336
  },
  {
    iataCode: 'LPL',
    icaoCode: 'EGGP',
    name: 'Liverpool',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Liverpool',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.333611,
    longitude: -2.849722,
    altitude: 24.384
  },
  {
    iataCode: 'LPQ',
    icaoCode: 'VLLB',
    name: 'Luang Prabang',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Luang Phabang Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 19.897914,
    longitude: 102.160764,
    altitude: 291.084
  },
  {
    iataCode: 'LPP',
    icaoCode: 'EFLP',
    name: 'Lappeenranta',
    country: {code: 'FI', name: 'Finland'},
    city: 'Lappeenranta',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.044553,
    longitude: 28.144397,
    altitude: 106.3752
  },
  {
    iataCode: 'LPS',
    icaoCode: 'S31',
    name: 'Lopez',
    country: {code: 'US', name: 'United States'},
    city: 'Lopez Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.4839,
    longitude: -122.938,
    altitude: 63.7032
  },
  {
    iataCode: 'LPU',
    icaoCode: 'WRLP',
    name: 'Long Apung-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Long Apung Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 0.583,
    longitude: 115.6,
    altitude: 191.1096
  },
  {
    iataCode: 'LPT',
    icaoCode: 'VTCL',
    name: 'Lampang',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Lampang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 18.270933,
    longitude: 99.504167,
    altitude: 247.1928
  },
  {
    iataCode: 'LPY',
    icaoCode: 'LFHP',
    name: 'Le Puy',
    country: {code: 'FR', name: 'France'},
    city: 'Loudes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.080689,
    longitude: 3.762889,
    altitude: 832.4088
  },
  {
    iataCode: 'UBB',
    icaoCode: 'YMAA',
    name: 'Mabuiag Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mabuiag Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 540,
    latitude: -9.95,
    longitude: 142.183,
    altitude: 0.0
  },
  {
    iataCode: 'LPX',
    icaoCode: 'EVLA',
    name: 'Liepaja',
    country: {code: 'LV', name: 'Latvia'},
    city: 'Liepaja Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Riga',
    utcOffset: 120,
    latitude: 56.5175,
    longitude: 21.096944,
    altitude: 4.8768
  },
  {
    iataCode: 'UBA',
    icaoCode: 'SBUR',
    name: 'Uberaba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Uberaba',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -19.765,
    longitude: -47.964778,
    altitude: 809.244
  },
  {
    iataCode: 'UBJ',
    icaoCode: 'RJDC',
    name: 'Yamaguchi',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yamaguchi Ube',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.93,
    longitude: 131.278611,
    altitude: 7.0104
  },
  {
    iataCode: 'UBP',
    icaoCode: 'VTUU',
    name: 'Ubon Ratchathani',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Ubon Ratchathani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 15.251278,
    longitude: 104.870231,
    altitude: 123.7488
  },
  {
    iataCode: 'DAB',
    icaoCode: 'KDAB',
    name: 'Daytona Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Daytona Beach Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 29.179917,
    longitude: -81.058056,
    altitude: 10.3632
  },
  {
    iataCode: 'DAD',
    icaoCode: 'VVDN',
    name: 'Danang',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Danang Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 16.043917,
    longitude: 108.19937,
    altitude: 10.0584
  },
  {
    iataCode: 'DAC',
    icaoCode: 'VGZR',
    name: 'Dhaka',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Zia Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 23.843333,
    longitude: 90.397781,
    altitude: 9.144
  },
  {
    iataCode: 'LQM',
    icaoCode: 'SKLG',
    name: 'Puerto Leguízamo',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Caucaya Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: -0.18,
    longitude: -74.77,
    altitude: 174.6504
  },
  {
    iataCode: 'DAL',
    icaoCode: 'KDAL',
    name: 'Dallas',
    country: {code: 'US', name: 'United States'},
    city: 'Dallas Love Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.847111,
    longitude: -96.851778,
    altitude: 148.4376
  },
  {
    iataCode: 'DAM',
    icaoCode: 'OSDI',
    name: 'Damascus',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Damascus Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 33.410636,
    longitude: 36.514267,
    altitude: 615.696
  },
  {
    iataCode: 'DAR',
    icaoCode: 'HTDA',
    name: 'Dar Es Salaam',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Mwalimu Julius K Nyerere Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -6.878111,
    longitude: 39.202625,
    altitude: 55.4736
  },
  {
    iataCode: 'DAT',
    icaoCode: 'ZBDT',
    name: 'Datong',
    country: {code: 'CN', name: 'China'},
    city: 'Datong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 40.0603,
    longitude: 113.482,
    altitude: 1049.1216
  },
  {
    iataCode: 'DAV',
    icaoCode: 'MPDA',
    name: 'David',
    country: {code: 'PA', name: 'Panama'},
    city: 'Enrique Malek Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 8.391003,
    longitude: -82.434992,
    altitude: 27.1272
  },
  {
    iataCode: 'DAU',
    icaoCode: 'AYDU',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Daru Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -9.08676,
    longitude: 143.208,
    altitude: 6.096
  },
  {
    iataCode: 'DAX',
    icaoCode: 'ZUDX',
    name: 'Dazhou',
    country: {code: 'CN', name: 'China'},
    city: 'Dachuan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 31.3,
    longitude: 107.5,
    altitude: 0.0
  },
  {
    iataCode: 'LRA',
    icaoCode: 'LGLR',
    name: 'Larissa',
    country: {code: 'GR', name: 'Greece'},
    city: 'Larisa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.650253,
    longitude: 22.4655,
    altitude: 73.4568
  },
  {
    iataCode: 'DAY',
    icaoCode: 'KDAY',
    name: 'Dayton',
    country: {code: 'US', name: 'United States'},
    city: 'James M Cox Dayton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.902375,
    longitude: -84.219375,
    altitude: 307.5432
  },
  {
    iataCode: 'LRE',
    icaoCode: 'YLRE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Longreach Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.4342,
    longitude: 144.28,
    altitude: 191.1096
  },
  {
    iataCode: 'LRD',
    icaoCode: 'KLRD',
    name: 'Laredo',
    country: {code: 'US', name: 'United States'},
    city: 'Laredo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 27.54375,
    longitude: -99.461556,
    altitude: 154.8384
  },
  {
    iataCode: 'LRF',
    icaoCode: 'KLRF',
    name: 'Jacksonville',
    country: {code: 'US', name: 'United States'},
    city: 'Little Rock Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.916944,
    longitude: -92.149722,
    altitude: 94.7928
  },
  {
    iataCode: 'DBA',
    icaoCode: 'OPDB',
    name: 'Dalbandin',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Dalbandin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 28.8783,
    longitude: 64.3998,
    altitude: 853.44
  },
  {
    iataCode: 'LRH',
    icaoCode: 'LFBH',
    name: 'La Rochelle',
    country: {code: 'FR', name: 'France'},
    city: 'La Rochelle-ile De Re',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.1792,
    longitude: -1.19528,
    altitude: 22.5552
  },
  {
    iataCode: 'LRM',
    icaoCode: 'MDLR',
    name: 'La Romana',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Casa De Campo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.450711,
    longitude: -68.911833,
    altitude: 73.152
  },
  {
    iataCode: 'DBD',
    icaoCode: 'VEDB',
    name: 'Dhanbad',
    country: {code: 'IN', name: 'India'},
    city: 'Dhanbad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.834044,
    longitude: 86.425261,
    altitude: 258.1656
  },
  {
    iataCode: 'LRL',
    icaoCode: 'DXNG',
    name: 'Niatougou',
    country: {code: 'TG', name: 'Togo'},
    city: 'Niamtougou International',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lome',
    utcOffset: 60,
    latitude: 9.767333,
    longitude: 1.09125,
    altitude: 461.772
  },
  {
    iataCode: 'LRS',
    icaoCode: 'LGLE',
    name: 'Leros',
    country: {code: 'GR', name: 'Greece'},
    city: 'Leros',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.184903,
    longitude: 26.800289,
    altitude: 11.8872
  },
  {
    iataCode: 'LRR',
    icaoCode: 'OISL',
    name: 'Lar',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Lar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 27.674725,
    longitude: 54.383278,
    altitude: 804.9768
  },
  {
    iataCode: 'DBM',
    icaoCode: 'HADM',
    name: 'Debre Marqos',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Debre Marqos',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 10.316667,
    longitude: 37.733333,
    altitude: 2267.712
  },
  {
    iataCode: 'LRU',
    icaoCode: 'KLRU',
    name: 'Las Cruces',
    country: {code: 'US', name: 'United States'},
    city: 'Las Cruces Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.289417,
    longitude: -106.921972,
    altitude: 1358.1888
  },
  {
    iataCode: 'LRT',
    icaoCode: 'LFRH',
    name: 'Lorient',
    country: {code: 'FR', name: 'France'},
    city: 'Lann Bihoue',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.760555,
    longitude: -3.44,
    altitude: 48.768
  },
  {
    iataCode: 'DBO',
    icaoCode: 'YSDU',
    name: 'Dubbo',
    country: {code: 'AU', name: 'Australia'},
    city: 'Dubbo',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.216667,
    longitude: 148.574722,
    altitude: 284.988
  },
  {
    iataCode: 'LRV',
    icaoCode: 'SVRS',
    name: 'Los Roques',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Gran Roque Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 11.95,
    longitude: -66.67,
    altitude: 5.1816
  },
  {
    iataCode: 'DBQ',
    icaoCode: 'KDBQ',
    name: 'Dubuque Ia',
    country: {code: 'US', name: 'United States'},
    city: 'Dubuque Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.402,
    longitude: -90.709472,
    altitude: 327.9648
  },
  {
    iataCode: 'UDD',
    icaoCode: 'KUDD',
    name: 'Palm Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Bermuda Dunes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 33.748438,
    longitude: -116.274813,
    altitude: 22.2504
  },
  {
    iataCode: 'DBT',
    icaoCode: 'HADT',
    name: 'Debre Tabor',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Debre Tabor Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 11.966667,
    longitude: 38.0,
    altitude: 2359.152
  },
  {
    iataCode: 'DBV',
    icaoCode: 'LDDU',
    name: 'Dubrovnik',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Dubrovnik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 42.561353,
    longitude: 18.268244,
    altitude: 160.6296
  },
  {
    iataCode: 'UDJ',
    icaoCode: 'UKLU',
    name: 'Uzhgorod',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Uzhhorod International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Uzhgorod',
    utcOffset: 120,
    latitude: 48.634278,
    longitude: 22.263356,
    altitude: 116.7384
  },
  {
    iataCode: 'UDI',
    icaoCode: 'SBUL',
    name: 'Uberlandia',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Ten Cel Av Cesar Bombonato',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -18.882844,
    longitude: -48.225594,
    altitude: 943.0512
  },
  {
    iataCode: 'LSC',
    icaoCode: 'SCSE',
    name: 'La Serena',
    country: {code: 'CL', name: 'Chile'},
    city: 'La Florida',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -29.916233,
    longitude: -71.199522,
    altitude: 146.6088
  },
  {
    iataCode: 'LSF',
    icaoCode: 'KLSF',
    name: 'Fort Benning',
    country: {code: 'US', name: 'United States'},
    city: 'Lawson Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.337322,
    longitude: -84.991283,
    altitude: 70.7136
  },
  {
    iataCode: 'LSE',
    icaoCode: 'KLSE',
    name: 'Lacrosse',
    country: {code: 'US', name: 'United States'},
    city: 'Lacrosse Municipal',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.878986,
    longitude: -91.256711,
    altitude: 199.3392
  },
  {
    iataCode: 'LSH',
    icaoCode: 'VYLS',
    name: 'Lashio',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Lashio',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 22.977881,
    longitude: 97.752183,
    altitude: 746.76
  },
  {
    iataCode: 'UDR',
    icaoCode: 'VAUD',
    name: 'Udaipur',
    country: {code: 'IN', name: 'India'},
    city: 'Udaipur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.617697,
    longitude: 73.8961,
    altitude: 513.2832
  },
  {
    iataCode: 'DCA',
    icaoCode: 'KDCA',
    name: 'Washington',
    country: {code: 'US', name: 'United States'},
    city: 'Ronald Reagan Washington Natl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.852083,
    longitude: -77.037722,
    altitude: 4.572
  },
  {
    iataCode: 'LSI',
    icaoCode: 'EGPB',
    name: 'Sumburgh',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Sumburgh',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.878889,
    longitude: -1.295556,
    altitude: 6.096
  },
  {
    iataCode: 'DCF',
    icaoCode: 'TDCF',
    name: 'Canefield',
    country: {code: 'DM', name: 'Dominica'},
    city: 'Canefield',
    region: '',
    terminal: '',
    timezoneId: 'America/Dominica',
    utcOffset: -240,
    latitude: 15.336719,
    longitude: -61.392211,
    altitude: 3.9624
  },
  {
    iataCode: 'LSP',
    icaoCode: 'SVJC',
    name: 'Paraguana',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Josefa Camejo',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 11.780775,
    longitude: -70.151497,
    altitude: 22.86
  },
  {
    iataCode: 'DCI',
    icaoCode: 'LIED',
    name: 'Decimomannu',
    country: {code: 'IT', name: 'Italy'},
    city: 'Decimomannu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 39.354222,
    longitude: 8.972481,
    altitude: 30.48
  },
  {
    iataCode: 'LSQ',
    icaoCode: 'SCGE',
    name: 'Los Angeles',
    country: {code: 'CL', name: 'Chile'},
    city: 'Maria Dolores',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -37.401731,
    longitude: -72.425444,
    altitude: 113.9952
  },
  {
    iataCode: 'LST',
    icaoCode: 'YMLT',
    name: 'Launceston',
    country: {code: 'AU', name: 'Australia'},
    city: 'Launceston',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -41.545278,
    longitude: 147.214167,
    altitude: 171.2976
  },
  {
    iataCode: 'LSS',
    icaoCode: 'TFFS',
    name: 'Les Saintes',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'Terre-de-haut Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 15.8644,
    longitude: -61.5806,
    altitude: 14.0208
  },
  {
    iataCode: 'LSV',
    icaoCode: 'KLSV',
    name: 'Las Vegas',
    country: {code: 'US', name: 'United States'},
    city: 'Nellis Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.236197,
    longitude: -115.034253,
    altitude: 569.976
  },
  {
    iataCode: 'DCM',
    icaoCode: 'LFCK',
    name: 'Castres',
    country: {code: 'FR', name: 'France'},
    city: 'Mazamet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.55625,
    longitude: 2.289183,
    altitude: 240.1824
  },
  {
    iataCode: 'LSW',
    icaoCode: 'WITM',
    name: 'Lhok Seumawe-sumatra Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Malikus Saleh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 5.226681,
    longitude: 96.950342,
    altitude: 27.432
  },
  {
    iataCode: 'LSY',
    icaoCode: 'YLIS',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lismore Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -28.8303,
    longitude: 153.26,
    altitude: 10.668
  },
  {
    iataCode: 'LTA',
    icaoCode: 'FATZ',
    name: 'Tzaneen',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Tzaneen',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -23.824417,
    longitude: 30.329306,
    altitude: 583.3872
  },
  {
    iataCode: 'LTD',
    icaoCode: 'HLTD',
    name: 'Ghadames',
    country: {code: 'LY', name: 'Libya'},
    city: 'Ghadames East',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 30.151695,
    longitude: 9.715305,
    altitude: 341.9856
  },
  {
    iataCode: 'UEL',
    icaoCode: 'FQQL',
    name: 'Quelimane',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Quelimane',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -17.8555,
    longitude: 36.869106,
    altitude: 10.9728
  },
  {
    iataCode: 'UEO',
    icaoCode: 'ROKJ',
    name: 'Kumejima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kumejima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 26.363506,
    longitude: 126.713806,
    altitude: 7.0104
  },
  {
    iataCode: 'LTI',
    icaoCode: 'ZMAT',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Altai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Hovd',
    utcOffset: 480,
    latitude: 46.3764,
    longitude: 96.2211,
    altitude: 2212.848
  },
  {
    iataCode: 'DDC',
    icaoCode: 'KDDC',
    name: 'Dodge City',
    country: {code: 'US', name: 'United States'},
    city: 'Dodge City Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.7634,
    longitude: -99.9656,
    altitude: 790.6512
  },
  {
    iataCode: 'LTK',
    icaoCode: 'OSLK',
    name: 'Latakia',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Bassel Al Assad Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 35.401094,
    longitude: 35.948681,
    altitude: 47.8536
  },
  {
    iataCode: 'LTM',
    icaoCode: 'SYLT',
    name: 'Lethem',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Lethem',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 3.372761,
    longitude: -59.789439,
    altitude: 106.9848
  },
  {
    iataCode: 'UET',
    icaoCode: 'OPQT',
    name: 'Quetta',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Quetta',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 30.251369,
    longitude: 66.937764,
    altitude: 1605.3816
  },
  {
    iataCode: 'DDG',
    icaoCode: 'ZYDD',
    name: 'Dandong',
    country: {code: 'CN', name: 'China'},
    city: 'Dandong',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 40.0255,
    longitude: 124.2866,
    altitude: 0.0
  },
  {
    iataCode: 'LTO',
    icaoCode: 'MMLT',
    name: 'Loreto',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Loreto Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 25.989194,
    longitude: -111.348361,
    altitude: 10.3632
  },
  {
    iataCode: 'LTN',
    icaoCode: 'EGGW',
    name: 'London',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Luton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.874722,
    longitude: -0.368333,
    altitude: 160.3248
  },
  {
    iataCode: 'LTQ',
    icaoCode: 'LFAT',
    name: 'Le Tourquet',
    country: {code: 'FR', name: 'France'},
    city: 'Le Touquet Paris Plage',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 50.517385,
    longitude: 1.620587,
    altitude: 10.9728
  },
  {
    iataCode: 'LTS',
    icaoCode: 'KLTS',
    name: 'Altus',
    country: {code: 'US', name: 'United States'},
    city: 'Altus Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.667067,
    longitude: -99.266681,
    altitude: 421.2336
  },
  {
    iataCode: 'LTT',
    icaoCode: 'LFTZ',
    name: 'La Môle',
    country: {code: 'FR', name: 'France'},
    city: 'La Môle Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.2054,
    longitude: 6.482,
    altitude: 17.9832
  },
  {
    iataCode: 'UFA',
    icaoCode: 'UWUU',
    name: 'Ufa',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ufa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 54.557511,
    longitude: 55.874417,
    altitude: 136.8552
  },
  {
    iataCode: 'LUA',
    icaoCode: 'VNLK',
    name: 'Lukla',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Lukla',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.687778,
    longitude: 86.731389,
    altitude: 2773.68
  },
  {
    iataCode: 'LUD',
    icaoCode: 'FYLZ',
    name: 'Luderitz',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Luderitz Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -26.6874,
    longitude: 15.2429,
    altitude: 139.2936
  },
  {
    iataCode: 'LUF',
    icaoCode: 'KLUF',
    name: 'Phoenix',
    country: {code: 'US', name: 'United States'},
    city: 'Luke Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 33.535,
    longitude: -112.38306,
    altitude: 330.708
  },
  {
    iataCode: 'LUH',
    icaoCode: 'VILD',
    name: 'Ludhiaha',
    country: {code: 'IN', name: 'India'},
    city: 'Ludhiana',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 30.854681,
    longitude: 75.952592,
    altitude: 254.2032
  },
  {
    iataCode: 'LUG',
    icaoCode: 'LSZA',
    name: 'Lugano',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Lugano',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.004275,
    longitude: 8.910578,
    altitude: 278.892
  },
  {
    iataCode: 'DEB',
    icaoCode: 'LHDC',
    name: 'Debrecen',
    country: {code: 'HU', name: 'Hungary'},
    city: 'Debrecen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Budapest',
    utcOffset: 60,
    latitude: 47.488917,
    longitude: 21.615333,
    altitude: 109.4232
  },
  {
    iataCode: 'DEA',
    icaoCode: 'OPDG',
    name: 'Dera Ghazi Khan',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Dera Ghazi Khan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 29.961011,
    longitude: 70.485925,
    altitude: 149.9616
  },
  {
    iataCode: 'DED',
    icaoCode: 'VIDN',
    name: 'Dehra Dun',
    country: {code: 'IN', name: 'India'},
    city: 'Dehradun',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 30.189689,
    longitude: 78.180256,
    altitude: 558.0888
  },
  {
    iataCode: 'DEC',
    icaoCode: 'KDEC',
    name: 'Decatur',
    country: {code: 'US', name: 'United States'},
    city: 'Decatur',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.834564,
    longitude: -88.865689,
    altitude: 207.8736
  },
  {
    iataCode: 'LUK',
    icaoCode: 'KLUK',
    name: 'Cincinnati',
    country: {code: 'US', name: 'United States'},
    city: 'Cincinnati Muni Lunken Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.103333,
    longitude: -84.418611,
    altitude: 147.2184
  },
  {
    iataCode: 'LUN',
    icaoCode: 'FLLS',
    name: 'Lusaka',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Lusaka Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -15.330817,
    longitude: 28.452628,
    altitude: 1151.8392
  },
  {
    iataCode: 'DEE',
    icaoCode: 'PADE',
    name: 'Deering',
    country: {code: 'US', name: 'United States'},
    city: 'Deering Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 66.0696,
    longitude: -162.766,
    altitude: 6.4008
  },
  {
    iataCode: 'LUM',
    icaoCode: 'ZPLX',
    name: 'Luxi',
    country: {code: 'CN', name: 'China'},
    city: 'Mangshi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 24.4011,
    longitude: 98.5317,
    altitude: 880.872
  },
  {
    iataCode: 'LUP',
    icaoCode: 'PHLU',
    name: 'Molokai',
    country: {code: 'US', name: 'United States'},
    city: 'Kalaupapa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.211,
    longitude: -156.974,
    altitude: 7.3152
  },
  {
    iataCode: 'LUO',
    icaoCode: 'FNUE',
    name: 'Luena',
    country: {code: 'AO', name: 'Angola'},
    city: 'Luena',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -11.768086,
    longitude: 19.897672,
    altitude: 1328.928
  },
  {
    iataCode: 'LUR',
    icaoCode: 'PALU',
    name: 'Cape Lisburne',
    country: {code: 'US', name: 'United States'},
    city: 'Cape Lisburne Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 68.875133,
    longitude: -166.110022,
    altitude: 3.6576
  },
  {
    iataCode: 'LUQ',
    icaoCode: 'SAOU',
    name: 'San Luis',
    country: {code: 'AR', name: 'Argentina'},
    city: 'San Luis',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/San_Luis',
    utcOffset: -180,
    latitude: -33.273192,
    longitude: -66.356422,
    altitude: 709.8792
  },
  {
    iataCode: 'DEL',
    icaoCode: 'VIDP',
    name: 'Delhi',
    country: {code: 'IN', name: 'India'},
    city: 'Indira Gandhi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 28.5665,
    longitude: 77.103088,
    altitude: 236.8296
  },
  {
    iataCode: 'DEN',
    icaoCode: 'KDEN',
    name: 'Denver',
    country: {code: 'US', name: 'United States'},
    city: 'Denver Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.861656,
    longitude: -104.673178,
    altitude: 1655.3688
  },
  {
    iataCode: 'LUV',
    icaoCode: 'WAPL',
    name: 'Langgur-seram Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Dumatumbun Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -5.661619,
    longitude: 132.731431,
    altitude: 3.048
  },
  {
    iataCode: 'DEM',
    icaoCode: 'HADD',
    name: 'Dembidollo',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Dembidollo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 8.554,
    longitude: 34.858,
    altitude: 1584.96
  },
  {
    iataCode: 'LUX',
    icaoCode: 'ELLX',
    name: 'Luxemburg',
    country: {code: 'LU', name: 'Luxembourg'},
    city: 'Luxembourg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Luxembourg',
    utcOffset: 60,
    latitude: 49.626575,
    longitude: 6.211517,
    altitude: 376.1232
  },
  {
    iataCode: 'UGA',
    icaoCode: 'ZMBN',
    name: 'Bulgan',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Bulgan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 420,
    latitude: 48.854167,
    longitude: 103.484167,
    altitude: 1180.4904
  },
  {
    iataCode: 'LUW',
    icaoCode: 'WAMW',
    name: 'Luwuk',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Bubung',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -1.038919,
    longitude: 122.771906,
    altitude: 17.0688
  },
  {
    iataCode: 'UGC',
    icaoCode: 'UTNU',
    name: 'Urgench',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Urgench Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 41.5843,
    longitude: 60.6417,
    altitude: 97.536
  },
  {
    iataCode: 'DET',
    icaoCode: 'KDET',
    name: 'Detroit',
    country: {code: 'US', name: 'United States'},
    city: 'Coleman A Young Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.409195,
    longitude: -83.009861,
    altitude: 190.8048
  },
  {
    iataCode: 'DES',
    icaoCode: 'FSDR',
    name: 'Desroches',
    country: {code: 'SC', name: 'Seychelles'},
    city: 'Desroches',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mahe',
    utcOffset: 240,
    latitude: -5.696697,
    longitude: 53.655844,
    altitude: 3.048
  },
  {
    iataCode: 'LVA',
    icaoCode: 'LFOV',
    name: 'Laval',
    country: {code: 'FR', name: 'France'},
    city: 'Entrammes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.031361,
    longitude: -0.742986,
    altitude: 100.584
  },
  {
    iataCode: 'DEZ',
    icaoCode: 'OSDZ',
    name: 'Deire Zor',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Deir Zzor',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 35.285374,
    longitude: 40.175961,
    altitude: 213.36
  },
  {
    iataCode: 'UGO',
    icaoCode: 'FNUG',
    name: 'Uige',
    country: {code: 'AO', name: 'Angola'},
    city: 'Uige',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -7.603067,
    longitude: 15.027822,
    altitude: 829.056
  },
  {
    iataCode: 'UGN',
    icaoCode: 'KUGN',
    name: 'Chicago',
    country: {code: 'US', name: 'United States'},
    city: 'Waukegan Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.422161,
    longitude: -87.867908,
    altitude: 221.5896
  },
  {
    iataCode: 'LVI',
    icaoCode: 'FLLI',
    name: 'Livingstone',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Livingstone',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -17.821756,
    longitude: 25.822692,
    altitude: 1006.4496
  },
  {
    iataCode: 'LVK',
    icaoCode: 'KLVK',
    name: 'Livermore',
    country: {code: 'US', name: 'United States'},
    city: 'Livermore Municipal',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.41362,
    longitude: -121.49133,
    altitude: 121.92
  },
  {
    iataCode: 'LVM',
    icaoCode: 'KLVM',
    name: 'Livingston-montana',
    country: {code: 'US', name: 'United States'},
    city: 'Mission Field Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 45.699389,
    longitude: -110.448306,
    altitude: 1420.368
  },
  {
    iataCode: 'LVO',
    icaoCode: 'YLTN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Laverton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -28.6136,
    longitude: 122.424,
    altitude: 466.344
  },
  {
    iataCode: 'LVS',
    icaoCode: 'KLVS',
    name: 'Las Vegas',
    country: {code: 'US', name: 'United States'},
    city: 'Las Vegas Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.654222,
    longitude: -105.142389,
    altitude: 2096.1096
  },
  {
    iataCode: 'LVR',
    icaoCode: 'SWFE',
    name: 'Lucas Do Rio Verde',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Bom Futuro Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -13.05,
    longitude: -55.910833,
    altitude: 152.4
  },
  {
    iataCode: 'DFW',
    icaoCode: 'KDFW',
    name: 'Dallas-fort Worth',
    country: {code: 'US', name: 'United States'},
    city: 'Dallas Fort Worth Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.896828,
    longitude: -97.037997,
    altitude: 185.0136
  },
  {
    iataCode: 'LWB',
    icaoCode: 'KLWB',
    name: 'Lewisburg',
    country: {code: 'US', name: 'United States'},
    city: 'Greenbrier Valley Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.858333,
    longitude: -80.399444,
    altitude: 701.6496
  },
  {
    iataCode: 'LWK',
    icaoCode: 'EGET',
    name: 'Lerwick',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Lerwick / Tingwall Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 60.1922,
    longitude: -1.24361,
    altitude: 13.1064
  },
  {
    iataCode: 'LWN',
    icaoCode: 'UDSG',
    name: 'Gyumri',
    country: {code: 'AM', name: 'Armenia'},
    city: 'Gyumri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yerevan',
    utcOffset: 240,
    latitude: 40.750369,
    longitude: 43.859342,
    altitude: 1524.0
  },
  {
    iataCode: 'DGE',
    icaoCode: 'YMDG',
    name: 'Mudgee',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mudgee Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.5625,
    longitude: 149.611,
    altitude: 470.916
  },
  {
    iataCode: 'LWO',
    icaoCode: 'UKLL',
    name: 'Lvov',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Lviv Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Uzhgorod',
    utcOffset: 120,
    latitude: 49.8125,
    longitude: 23.956111,
    altitude: 326.4408
  },
  {
    iataCode: 'LWR',
    icaoCode: 'EHLW',
    name: 'Leeuwarden',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Leeuwarden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 53.228611,
    longitude: 5.760556,
    altitude: 0.9144
  },
  {
    iataCode: 'DGL',
    icaoCode: 'KDGL',
    name: 'Douglas',
    country: {code: 'US', name: 'United States'},
    city: 'Douglas Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 31.342603,
    longitude: -109.506454,
    altitude: 1271.9304
  },
  {
    iataCode: 'LWT',
    icaoCode: 'KLWT',
    name: 'Lewistown',
    country: {code: 'US', name: 'United States'},
    city: 'Lewistown Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 47.0493,
    longitude: -109.467,
    altitude: 1271.016
  },
  {
    iataCode: 'LWS',
    icaoCode: 'KLWS',
    name: 'Lewiston',
    country: {code: 'US', name: 'United States'},
    city: 'Lewiston Nez Perce Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.3745,
    longitude: -117.015389,
    altitude: 439.5216
  },
  {
    iataCode: 'DGO',
    icaoCode: 'MMDO',
    name: 'Durango',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Durango Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 24.124194,
    longitude: -104.528014,
    altitude: 1860.4992
  },
  {
    iataCode: 'LWY',
    icaoCode: 'WBGW',
    name: 'Lawas',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Lawas Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 4.84917,
    longitude: 115.408,
    altitude: 1.524
  },
  {
    iataCode: 'UIB',
    icaoCode: 'SKUI',
    name: 'Quibdo',
    country: {code: 'CO', name: 'Colombia'},
    city: 'El Carano',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 5.690758,
    longitude: -76.641181,
    altitude: 62.1792
  },
  {
    iataCode: 'DGT',
    icaoCode: 'RPVD',
    name: 'Dumaguete',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Dumaguete',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.333714,
    longitude: 123.300472,
    altitude: 4.572
  },
  {
    iataCode: 'LXA',
    icaoCode: 'ZULS',
    name: 'Lhasa',
    country: {code: 'CN', name: 'China'},
    city: 'Lhasa-gonggar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 29.297778,
    longitude: 90.911944,
    altitude: 4003.8528
  },
  {
    iataCode: 'UII',
    icaoCode: 'MHUT',
    name: 'Utila',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Utila Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 16.091667,
    longitude: -86.8875,
    altitude: 3.048
  },
  {
    iataCode: 'UIH',
    icaoCode: 'VVPC',
    name: 'Phucat',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Phu Cat Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 13.954986,
    longitude: 109.042267,
    altitude: 24.384
  },
  {
    iataCode: 'UIK',
    icaoCode: 'UIBS',
    name: 'Ust Ilimsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ust-ilimsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Irkutsk',
    utcOffset: 480,
    latitude: 58.08,
    longitude: 108.08,
    altitude: 0.0
  },
  {
    iataCode: 'LXG',
    icaoCode: 'VLLN',
    name: 'Luang Namtha',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Luang Namtha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 20.960556,
    longitude: 101.4025,
    altitude: 599.8464
  },
  {
    iataCode: 'UIO',
    icaoCode: 'SEQU',
    name: 'Quito',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Mariscal Sucre Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -0.141144,
    longitude: -78.488214,
    altitude: 2812.6944
  },
  {
    iataCode: 'UIN',
    icaoCode: 'KUIN',
    name: 'Quincy',
    country: {code: 'US', name: 'United States'},
    city: 'Quincy Regional Baldwin Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.9427,
    longitude: -91.1946,
    altitude: 234.3912
  },
  {
    iataCode: 'DHA',
    icaoCode: 'OEDR',
    name: 'Dhahran',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Abdulaziz Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 26.265417,
    longitude: 50.152027,
    altitude: 25.6032
  },
  {
    iataCode: 'UIP',
    icaoCode: 'LFRQ',
    name: 'Quimper',
    country: {code: 'FR', name: 'France'},
    city: 'Pluguffan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.974981,
    longitude: -4.167786,
    altitude: 90.5256
  },
  {
    iataCode: 'UIT',
    icaoCode: 'N55',
    name: 'Jabor Jaluit Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Jaluit Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 5.90924,
    longitude: 169.637,
    altitude: 1.2192
  },
  {
    iataCode: 'DHI',
    icaoCode: 'VNDH',
    name: 'Dhangarhi',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Dhangarhi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.753333,
    longitude: 80.581944,
    altitude: 210.312
  },
  {
    iataCode: 'LXS',
    icaoCode: 'LGLM',
    name: 'Limnos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Limnos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.917072,
    longitude: 25.236308,
    altitude: 4.2672
  },
  {
    iataCode: 'LXR',
    icaoCode: 'HELX',
    name: 'Luxor',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Luxor Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 25.671028,
    longitude: 32.706583,
    altitude: 89.6112
  },
  {
    iataCode: 'DHM',
    icaoCode: 'VIGG',
    name: '',
    country: {code: 'IN', name: 'India'},
    city: 'Kangra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 32.1651,
    longitude: 76.2634,
    altitude: 769.62
  },
  {
    iataCode: 'DHN',
    icaoCode: 'KDHN',
    name: 'Dothan',
    country: {code: 'US', name: 'United States'},
    city: 'Dothan Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.321339,
    longitude: -85.449628,
    altitude: 122.2248
  },
  {
    iataCode: 'DHR',
    icaoCode: 'EHKD',
    name: 'De Kooy',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'De Kooy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 52.923353,
    longitude: 4.780625,
    altitude: 0.9144
  },
  {
    iataCode: 'DHT',
    icaoCode: 'KDHT',
    name: 'Dalhart',
    country: {code: 'US', name: 'United States'},
    city: 'Dalhart Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.022586,
    longitude: -102.547278,
    altitude: 1216.4568
  },
  {
    iataCode: 'UJE',
    icaoCode: 'UJAP',
    name: 'Ujae Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Ujae Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 8.92806,
    longitude: 165.762,
    altitude: 0.0
  },
  {
    iataCode: 'LYB',
    icaoCode: 'MWCL',
    name: 'Little Cayman',
    country: {code: 'KY', name: 'Cayman Islands'},
    city: 'Edward Bodden Airfield',
    region: '',
    terminal: '',
    timezoneId: 'America/Cayman',
    utcOffset: -300,
    latitude: 19.659167,
    longitude: -80.090833,
    altitude: 0.3048
  },
  {
    iataCode: 'LYA',
    icaoCode: 'ZHLY',
    name: 'Luoyang',
    country: {code: 'CN', name: 'China'},
    city: 'Luoyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 34.41,
    longitude: 112.28,
    altitude: 64.008
  },
  {
    iataCode: 'LYC',
    icaoCode: 'ESNL',
    name: 'Lycksele',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Lycksele',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 64.548322,
    longitude: 18.716219,
    altitude: 214.884
  },
  {
    iataCode: 'LYE',
    icaoCode: 'EGDL',
    name: 'Lyneham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Lyneham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.505144,
    longitude: -1.993428,
    altitude: 156.3624
  },
  {
    iataCode: 'LYH',
    icaoCode: 'KLYH',
    name: 'Lynchburg',
    country: {code: 'US', name: 'United States'},
    city: 'Lynchburg Regional Preston Glenn Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.3267,
    longitude: -79.2004,
    altitude: 285.9024
  },
  {
    iataCode: 'LYG',
    icaoCode: 'ZSLG',
    name: 'Lianyungang',
    country: {code: 'CN', name: 'China'},
    city: 'Lianyungang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 34.55,
    longitude: 119.25,
    altitude: 0.0
  },
  {
    iataCode: 'LYI',
    icaoCode: 'ZSLY',
    name: 'Linyi',
    country: {code: 'CN', name: 'China'},
    city: 'Shubuling Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 35.0461,
    longitude: 118.412,
    altitude: 0.0
  },
  {
    iataCode: 'LYN',
    icaoCode: 'LFLY',
    name: 'Lyon',
    country: {code: 'FR', name: 'France'},
    city: 'Bron',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.727172,
    longitude: 4.944275,
    altitude: 200.8632
  },
  {
    iataCode: 'DIE',
    icaoCode: 'FMNA',
    name: 'Antsiranana',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Arrachart',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -12.3494,
    longitude: 49.291747,
    altitude: 113.9952
  },
  {
    iataCode: 'LYM',
    icaoCode: 'EGMK',
    name: 'Lympne',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Ashford',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.083333,
    longitude: 1.016667,
    altitude: 106.9848
  },
  {
    iataCode: 'LYP',
    icaoCode: 'OPFA',
    name: 'Faisalabad',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Faisalabad Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 31.365014,
    longitude: 72.994842,
    altitude: 180.1368
  },
  {
    iataCode: 'DIG',
    icaoCode: 'ZPDQ',
    name: 'Shangri-la',
    country: {code: 'CN', name: 'China'},
    city: 'Diqing Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 27.7936,
    longitude: 99.6772,
    altitude: 0.0
  },
  {
    iataCode: 'DIJ',
    icaoCode: 'LFSD',
    name: 'Dijon',
    country: {code: 'FR', name: 'France'},
    city: 'Longvic',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.26889,
    longitude: 5.09,
    altitude: 221.2848
  },
  {
    iataCode: 'LYR',
    icaoCode: 'ENSB',
    name: 'Svalbard',
    country: {code: 'NO', name: 'Norway'},
    city: 'Longyear',
    region: '',
    terminal: '',
    timezoneId: 'Arctic/Longyearbyen',
    utcOffset: 60,
    latitude: 78.246111,
    longitude: 15.465556,
    altitude: 26.8224
  },
  {
    iataCode: 'DIL',
    icaoCode: 'WPDL',
    name: 'Dili',
    country: {code: 'TL', name: 'Timor-Leste'},
    city: 'Presidente Nicolau Lobato Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dili',
    utcOffset: 480,
    latitude: -8.546553,
    longitude: 125.524719,
    altitude: 7.62
  },
  {
    iataCode: 'DIK',
    icaoCode: 'KDIK',
    name: 'Dickinson',
    country: {code: 'US', name: 'United States'},
    city: 'Dickinson Theodore Roosevelt Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -300,
    latitude: 46.7975,
    longitude: -102.801944,
    altitude: 790.0416
  },
  {
    iataCode: 'LYS',
    icaoCode: 'LFLL',
    name: 'Lyon',
    country: {code: 'FR', name: 'France'},
    city: 'Saint Exupery',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.726387,
    longitude: 5.090833,
    altitude: 250.2408
  },
  {
    iataCode: 'DIN',
    icaoCode: 'VVDB',
    name: 'Dienbienphu',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Dien Bien Phu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 21.397481,
    longitude: 103.007831,
    altitude: 491.0328
  },
  {
    iataCode: 'LYU',
    icaoCode: 'KELO',
    name: 'Ely',
    country: {code: 'US', name: 'United States'},
    city: 'Ely Municipal',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.824444,
    longitude: -91.830833,
    altitude: 443.7888
  },
  {
    iataCode: 'LYX',
    icaoCode: 'EGMD',
    name: 'Lydd',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Lydd',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.956111,
    longitude: 0.939167,
    altitude: 3.9624
  },
  {
    iataCode: 'DIR',
    icaoCode: 'HADR',
    name: 'Dire Dawa',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Dire Dawa Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 9.6247,
    longitude: 41.854203,
    altitude: 1167.0792
  },
  {
    iataCode: 'UKC',
    icaoCode: 'UKLC',
    name: 'Lutsk',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Lutsk',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 50.6833,
    longitude: 25.4833,
    altitude: 235.9152
  },
  {
    iataCode: 'UKB',
    icaoCode: 'RJBE',
    name: 'Kobe',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kobe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.632778,
    longitude: 135.223889,
    altitude: 6.7056
  },
  {
    iataCode: 'DIS',
    icaoCode: 'FCPL',
    name: 'Loubomo',
    country: {code: 'CG', name: 'Congo'},
    city: 'Loubomo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Brazzaville',
    utcOffset: 60,
    latitude: -4.2,
    longitude: 12.7,
    altitude: 328.8792
  },
  {
    iataCode: 'DIU',
    icaoCode: 'VA1P',
    name: 'Diu',
    country: {code: 'IN', name: 'India'},
    city: 'Diu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 20.7131,
    longitude: 70.9211,
    altitude: 9.4488
  },
  {
    iataCode: 'LZC',
    icaoCode: 'MMLC',
    name: 'Lazard Cardenas',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Lazaro Cardenas',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 18.001731,
    longitude: -102.220525,
    altitude: 11.8872
  },
  {
    iataCode: 'UKK',
    icaoCode: 'UASK',
    name: 'Ust Kamenogorsk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Ust Kamenogorsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 50.0366,
    longitude: 82.4942,
    altitude: 286.2072
  },
  {
    iataCode: 'DIY',
    icaoCode: 'LTCC',
    name: 'Diyabakir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Diyarbakir',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.893897,
    longitude: 40.201019,
    altitude: 686.1048
  },
  {
    iataCode: 'LZH',
    icaoCode: 'ZGZH',
    name: 'Liuzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Bailian Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 24.2075,
    longitude: 109.391,
    altitude: 89.916
  },
  {
    iataCode: 'DJB',
    icaoCode: 'WIPA',
    name: 'Jambi',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sultan Thaha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -1.638017,
    longitude: 103.644378,
    altitude: 24.9936
  },
  {
    iataCode: 'DJE',
    icaoCode: 'DTTJ',
    name: 'Djerba',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Zarzis',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 33.875031,
    longitude: 10.775461,
    altitude: 5.7912
  },
  {
    iataCode: 'DJG',
    icaoCode: 'DAAJ',
    name: 'Djanet',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Tiska',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 24.292767,
    longitude: 9.452444,
    altitude: 968.0448
  },
  {
    iataCode: 'LZO',
    icaoCode: 'ZULZ',
    name: 'Luzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Luzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 28.8522,
    longitude: 105.393,
    altitude: 0.0
  },
  {
    iataCode: 'LZN',
    icaoCode: 'RCFG',
    name: 'Matsu Islands',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Matsu Nangan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 26.1598,
    longitude: 119.958,
    altitude: 70.7136
  },
  {
    iataCode: 'UKX',
    icaoCode: 'UITT',
    name: 'Ust-kut',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ust-kut',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Irkutsk',
    utcOffset: 480,
    latitude: 56.85,
    longitude: 105.7167,
    altitude: 619.6584
  },
  {
    iataCode: 'DJJ',
    icaoCode: 'WAJJ',
    name: 'Jayapura',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sentani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -2.576953,
    longitude: 140.516372,
    altitude: 88.0872
  },
  {
    iataCode: 'LZR',
    icaoCode: 'YLZI',
    name: 'Lizard Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lizard Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -14.673056,
    longitude: 145.454444,
    altitude: 21.336
  },
  {
    iataCode: 'DJO',
    icaoCode: 'DIDL',
    name: 'Daloa',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Daloa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 6.792808,
    longitude: -6.473189,
    altitude: 250.8504
  },
  {
    iataCode: 'LZY',
    icaoCode: 'ZUNZ',
    name: 'Nyingchi',
    country: {code: 'CN', name: 'China'},
    city: 'Nyingchi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 300,
    latitude: 29.3033,
    longitude: 94.3353,
    altitude: 2948.94
  },
  {
    iataCode: 'ULB',
    icaoCode: 'NVSU',
    name: 'Ambryn Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Uléi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.333,
    longitude: 168.283,
    altitude: 0.0
  },
  {
    iataCode: 'ULA',
    icaoCode: 'SAWJ',
    name: 'San Julian',
    country: {code: 'AR', name: 'Argentina'},
    city: 'San Julian',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -49.306775,
    longitude: -67.802589,
    altitude: 57.912
  },
  {
    iataCode: 'ULD',
    icaoCode: 'FAUL',
    name: 'Ulundi',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Prince Mangosuthu Buthelezi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.320586,
    longitude: 31.416519,
    altitude: 524.256
  },
  {
    iataCode: 'ULG',
    icaoCode: 'ZMUL',
    name: 'Olgii',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Olgii Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Hovd',
    utcOffset: 420,
    latitude: 48.991667,
    longitude: 89.919722,
    altitude: 1709.928
  },
  {
    iataCode: 'ULN',
    icaoCode: 'ZMUB',
    name: 'Ulan Bator',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Chinggis Khaan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    latitude: 47.843056,
    longitude: 106.766639,
    altitude: 1330.1472
  },
  {
    iataCode: 'ULP',
    icaoCode: 'YQLP',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Quilpie Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -26.6122,
    longitude: 144.253,
    altitude: 199.644
  },
  {
    iataCode: 'ULO',
    icaoCode: 'ZMUG',
    name: 'Ulaangom',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Ulaangom Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Hovd',
    utcOffset: 420,
    latitude: 49.973333,
    longitude: 92.079722,
    altitude: 1066.8
  },
  {
    iataCode: 'ULQ',
    icaoCode: 'SKUL',
    name: 'Tulua',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Farfan',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.088358,
    longitude: -76.235133,
    altitude: 954.6336
  },
  {
    iataCode: 'ULU',
    icaoCode: 'HUGU',
    name: 'Gulu',
    country: {code: 'UG', name: 'Uganda'},
    city: 'Gulu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kampala',
    utcOffset: 180,
    latitude: 2.805556,
    longitude: 32.271792,
    altitude: 1069.848
  },
  {
    iataCode: 'DKI',
    icaoCode: 'YDKI',
    name: 'Dunk Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Dunk Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -17.939722,
    longitude: 146.141944,
    altitude: 11.5824
  },
  {
    iataCode: 'ULY',
    icaoCode: 'UWLW',
    name: 'Ulyanovsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ulyanovsk East Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 240,
    latitude: 54.401,
    longitude: 48.8027,
    altitude: 76.8096
  },
  {
    iataCode: 'DKR',
    icaoCode: 'GOOY',
    name: 'Dakar',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Leopold Sedar Senghor Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 14.739708,
    longitude: -17.490225,
    altitude: 25.908
  },
  {
    iataCode: 'UME',
    icaoCode: 'ESNU',
    name: 'Umea',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Umea',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 63.791828,
    longitude: 20.282758,
    altitude: 7.3152
  },
  {
    iataCode: 'DLA',
    icaoCode: 'FKKD',
    name: 'Douala',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Douala',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 4.006081,
    longitude: 9.719481,
    altitude: 10.0584
  },
  {
    iataCode: 'DLC',
    icaoCode: 'ZYTL',
    name: 'Dalian',
    country: {code: 'CN', name: 'China'},
    city: 'Zhoushuizi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 38.965667,
    longitude: 121.5386,
    altitude: 32.6136
  },
  {
    iataCode: 'UMR',
    icaoCode: 'YPWR',
    name: 'Woomera',
    country: {code: 'AU', name: 'Australia'},
    city: 'Woomera',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -31.144167,
    longitude: 136.816944,
    altitude: 167.0304
  },
  {
    iataCode: 'DLE',
    icaoCode: 'LFGJ',
    name: 'Dole',
    country: {code: 'FR', name: 'France'},
    city: 'Tavaux',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.039014,
    longitude: 5.42725,
    altitude: 196.596
  },
  {
    iataCode: 'DLG',
    icaoCode: 'PADL',
    name: 'Dillingham',
    country: {code: 'US', name: 'United States'},
    city: 'Dillingham',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.044667,
    longitude: -158.5055,
    altitude: 22.5552
  },
  {
    iataCode: 'DLF',
    icaoCode: 'KDLF',
    name: 'Del Rio',
    country: {code: 'US', name: 'United States'},
    city: 'Laughlin Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.359486,
    longitude: -100.777975,
    altitude: 329.7936
  },
  {
    iataCode: 'DLI',
    icaoCode: 'VVDL',
    name: 'Dalat',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Dalat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 11.75,
    longitude: 108.367,
    altitude: 961.9488
  },
  {
    iataCode: 'DLH',
    icaoCode: 'KDLH',
    name: 'Duluth',
    country: {code: 'US', name: 'United States'},
    city: 'Duluth Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 46.842091,
    longitude: -92.193649,
    altitude: 435.2544
  },
  {
    iataCode: 'DLM',
    icaoCode: 'LTBS',
    name: 'Dalaman',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Dalaman',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 36.713056,
    longitude: 28.7925,
    altitude: 6.096
  },
  {
    iataCode: 'UND',
    icaoCode: 'OAUZ',
    name: 'Kunduz',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Konduz',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 36.665111,
    longitude: 68.910833,
    altitude: 444.0936
  },
  {
    iataCode: 'DLU',
    icaoCode: 'ZPDL',
    name: 'Dali',
    country: {code: 'CN', name: 'China'},
    city: 'Dali',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 25.649444,
    longitude: 100.319444,
    altitude: 2126.8944
  },
  {
    iataCode: 'UNG',
    icaoCode: 'AYKI',
    name: 'Kiunga',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Kiunga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.12571,
    longitude: 141.282,
    altitude: 26.8224
  },
  {
    iataCode: 'DLY',
    icaoCode: 'NVVD',
    name: "Dillon's Bay",
    country: {code: 'VU', name: 'Vanuatu'},
    city: "Dillon's Bay Airport",
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -18.7694,
    longitude: 169.001,
    altitude: 163.9824
  },
  {
    iataCode: 'UNI',
    icaoCode: 'TVSU',
    name: 'Union Island',
    country: {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    city: 'Union Island International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Vincent',
    utcOffset: -240,
    latitude: 12.583,
    longitude: -61.417,
    altitude: 4.8768
  },
  {
    iataCode: 'DLZ',
    icaoCode: 'ZMDZ',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Dalanzadgad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    latitude: 43.5917,
    longitude: 104.43,
    altitude: 1459.0776
  },
  {
    iataCode: 'UNK',
    icaoCode: 'PAUN',
    name: 'Unalakleet',
    country: {code: 'US', name: 'United States'},
    city: 'Unalakleet Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.888333,
    longitude: -160.798889,
    altitude: 6.4008
  },
  {
    iataCode: 'DMB',
    icaoCode: 'UADD',
    name: 'Dzhambul',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Taraz',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 42.853611,
    longitude: 71.303611,
    altitude: 665.6832
  },
  {
    iataCode: 'DMA',
    icaoCode: 'KDMA',
    name: 'Tucson',
    country: {code: 'US', name: 'United States'},
    city: 'Davis Monthan Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 32.166467,
    longitude: -110.883144,
    altitude: 824.1792
  },
  {
    iataCode: 'DMD',
    icaoCode: 'YDMG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Doomadgee Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -17.9403,
    longitude: 138.822,
    altitude: 46.6344
  },
  {
    iataCode: 'UNT',
    icaoCode: 'EGPW',
    name: 'Unst',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Unst Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 60.7472,
    longitude: -0.85385,
    altitude: 0.0
  },
  {
    iataCode: 'DME',
    icaoCode: 'UUDD',
    name: 'Moscow -',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Domodedovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.408611,
    longitude: 37.906111,
    altitude: 179.2224
  },
  {
    iataCode: 'DMK',
    icaoCode: 'VTBD',
    name: 'Bangkok',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Don Muang Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 13.912583,
    longitude: 100.60675,
    altitude: 2.7432
  },
  {
    iataCode: 'DMM',
    icaoCode: 'OEDF',
    name: 'Dammam',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Fahd Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 26.471161,
    longitude: 49.79789,
    altitude: 21.9456
  },
  {
    iataCode: 'DMT',
    icaoCode: 'SWDM',
    name: 'Diamantino',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Diamantino Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -14.408889,
    longitude: -56.445833,
    altitude: 559.9176
  },
  {
    iataCode: 'DMU',
    icaoCode: 'VEMR',
    name: '',
    country: {code: 'IN', name: 'India'},
    city: 'Dimapur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.8839,
    longitude: 93.7711,
    altitude: 148.4376
  },
  {
    iataCode: 'DNA',
    icaoCode: 'RODN',
    name: 'Kadena',
    country: {code: 'JP', name: 'Japan'},
    city: 'Kadena Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 26.355612,
    longitude: 127.767633,
    altitude: 43.5864
  },
  {
    iataCode: 'DND',
    icaoCode: 'EGPN',
    name: 'Dundee',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Dundee',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 56.452499,
    longitude: -3.025833,
    altitude: 5.1816
  },
  {
    iataCode: 'DNH',
    icaoCode: 'ZLDH',
    name: 'Dunhuang',
    country: {code: 'CN', name: 'China'},
    city: 'Dunhuang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 40.094,
    longitude: 94.4818,
    altitude: 1124.1024
  },
  {
    iataCode: 'DNK',
    icaoCode: 'UKDD',
    name: 'Dnepropetrovsk',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Dnipropetrovsk Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 48.357222,
    longitude: 35.100556,
    altitude: 146.6088
  },
  {
    iataCode: 'DNM',
    icaoCode: 'YSHK',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Shark Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -25.8939,
    longitude: 113.577,
    altitude: 33.8328
  },
  {
    iataCode: 'DNP',
    icaoCode: 'VNDG',
    name: 'Dang',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Tulsipur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.111111,
    longitude: 82.294167,
    altitude: 640.08
  },
  {
    iataCode: 'DNR',
    icaoCode: 'LFRD',
    name: 'Dinard',
    country: {code: 'FR', name: 'France'},
    city: 'Pleurtuit',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.587683,
    longitude: -2.079958,
    altitude: 66.7512
  },
  {
    iataCode: 'UPG',
    icaoCode: 'WAAA',
    name: 'Ujung Pandang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Hasanuddin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -5.061631,
    longitude: 119.554042,
    altitude: 14.3256
  },
  {
    iataCode: 'DNZ',
    icaoCode: 'LTAY',
    name: 'Denizli',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Cardak',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.785567,
    longitude: 29.701297,
    altitude: 851.916
  },
  {
    iataCode: 'UPN',
    icaoCode: 'MMPN',
    name: 'Uruapan',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Licenciado Y Gen Ignacio Lopez Rayon',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.396692,
    longitude: -102.039056,
    altitude: 1602.6384
  },
  {
    iataCode: 'UPP',
    icaoCode: 'PHUP',
    name: 'Opolu',
    country: {code: 'US', name: 'United States'},
    city: 'Upolu',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.265256,
    longitude: -155.859989,
    altitude: 29.2608
  },
  {
    iataCode: 'DOD',
    icaoCode: 'HTDO',
    name: 'Dodoma',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Dodoma',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -6.170436,
    longitude: 35.752578,
    altitude: 1108.5576
  },
  {
    iataCode: 'DOH',
    icaoCode: 'OTBD',
    name: 'Doha',
    country: {code: 'QA', name: 'Qatar'},
    city: 'Doha Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Qatar',
    utcOffset: 240,
    latitude: 25.261125,
    longitude: 51.565056,
    altitude: 10.668
  },
  {
    iataCode: 'DOG',
    icaoCode: 'HSDN',
    name: 'Dongola',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Dongola',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 19.153867,
    longitude: 30.430094,
    altitude: 235.6104
  },
  {
    iataCode: 'DOL',
    icaoCode: 'LFRG',
    name: 'Deauville',
    country: {code: 'FR', name: 'France'},
    city: 'St Gatien',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.365339,
    longitude: 0.154306,
    altitude: 145.9992
  },
  {
    iataCode: 'DOK',
    icaoCode: 'UKCC',
    name: 'Donetsk',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Donetsk Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 48.073611,
    longitude: 37.739722,
    altitude: 241.0968
  },
  {
    iataCode: 'DOM',
    icaoCode: 'TDPD',
    name: 'Dominica',
    country: {code: 'DM', name: 'Dominica'},
    city: 'Melville Hall',
    region: '',
    terminal: '',
    timezoneId: 'America/Dominica',
    utcOffset: -240,
    latitude: 15.547028,
    longitude: -61.3,
    altitude: 22.2504
  },
  {
    iataCode: 'DOP',
    icaoCode: 'VNDP',
    name: 'Dolpa',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Dolpa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.985718,
    longitude: 82.819145,
    altitude: 2499.36
  },
  {
    iataCode: 'DOV',
    icaoCode: 'KDOV',
    name: 'Dover',
    country: {code: 'US', name: 'United States'},
    city: 'Dover Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 39.129539,
    longitude: -75.465958,
    altitude: 8.5344
  },
  {
    iataCode: 'DOU',
    icaoCode: 'SSDO',
    name: 'Dourados',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Dourados Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Campo_Grande',
    utcOffset: -180,
    latitude: -22.220833,
    longitude: -54.805833,
    altitude: 436.7784
  },
  {
    iataCode: 'MAA',
    icaoCode: 'VOMM',
    name: 'Madras',
    country: {code: 'IN', name: 'India'},
    city: 'Chennai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 12.994414,
    longitude: 80.180517,
    altitude: 15.8496
  },
  {
    iataCode: 'DOY',
    icaoCode: 'ZSDY',
    name: 'Dongying',
    country: {code: 'CN', name: 'China'},
    city: 'Dongying Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 37.2716,
    longitude: 118.2819,
    altitude: 26.2128
  },
  {
    iataCode: 'MAB',
    icaoCode: 'SBMA',
    name: 'Maraba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Maraba',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -5.368589,
    longitude: -49.138025,
    altitude: 108.8136
  },
  {
    iataCode: 'MAD',
    icaoCode: 'LEMD',
    name: 'Madrid',
    country: {code: 'ES', name: 'Spain'},
    city: 'Barajas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 40.493556,
    longitude: -3.566764,
    altitude: 609.6
  },
  {
    iataCode: 'MAG',
    icaoCode: 'AYMD',
    name: 'Madang',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Madang',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.207083,
    longitude: 145.7887,
    altitude: 6.096
  },
  {
    iataCode: 'MAF',
    icaoCode: 'KMAF',
    name: 'Midland',
    country: {code: 'US', name: 'United States'},
    city: 'Midland Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.942528,
    longitude: -102.201914,
    altitude: 875.0808
  },
  {
    iataCode: 'DPA',
    icaoCode: 'KDPA',
    name: 'West Chicago',
    country: {code: 'US', name: 'United States'},
    city: 'Dupage',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.907778,
    longitude: -88.248611,
    altitude: 231.0384
  },
  {
    iataCode: 'MAH',
    icaoCode: 'LEMH',
    name: 'Menorca',
    country: {code: 'ES', name: 'Spain'},
    city: 'Menorca',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 39.862597,
    longitude: 4.218647,
    altitude: 90.8304
  },
  {
    iataCode: 'MAK',
    icaoCode: 'HSSM',
    name: 'Malakal',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Malakal',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Juba',
    utcOffset: 120,
    latitude: 9.558969,
    longitude: 31.652242,
    altitude: 393.4968
  },
  {
    iataCode: 'MAJ',
    icaoCode: 'PKMJ',
    name: 'Majuro',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Marshall Islands Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 7.064758,
    longitude: 171.272022,
    altitude: 1.8288
  },
  {
    iataCode: 'MAM',
    icaoCode: 'MMMA',
    name: 'Matamoros',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Servando Canales Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Matamoros',
    utcOffset: -360,
    latitude: 25.769894,
    longitude: -97.525311,
    altitude: 7.62
  },
  {
    iataCode: 'MAO',
    icaoCode: 'SBEG',
    name: 'Manaus',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Eduardo Gomes Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -3.038611,
    longitude: -60.049721,
    altitude: 80.4672
  },
  {
    iataCode: 'MAN',
    icaoCode: 'EGCC',
    name: 'Manchester',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Manchester',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.353744,
    longitude: -2.27495,
    altitude: 78.3336
  },
  {
    iataCode: 'MAQ',
    icaoCode: 'VTPM',
    name: 'Tak',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Mae Sot Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 16.699856,
    longitude: 98.545056,
    altitude: 210.312
  },
  {
    iataCode: 'MAS',
    icaoCode: 'AYMO',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Momote Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -2.06189,
    longitude: 147.424,
    altitude: 3.6576
  },
  {
    iataCode: 'MAR',
    icaoCode: 'SVMC',
    name: 'Maracaibo',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'La Chinita Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.558208,
    longitude: -71.727856,
    altitude: 71.628
  },
  {
    iataCode: 'MAU',
    icaoCode: 'NTTP',
    name: 'Maupiti',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Maupiti',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.426486,
    longitude: -152.243669,
    altitude: 4.572
  },
  {
    iataCode: 'DPL',
    icaoCode: 'RPMG',
    name: 'Dipolog',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Dipolog',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 8.601261,
    longitude: 123.334481,
    altitude: 3.6576
  },
  {
    iataCode: 'MAT',
    icaoCode: 'FZAM',
    name: 'Matadi',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Tshimpi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -5.79961,
    longitude: 13.4404,
    altitude: 339.852
  },
  {
    iataCode: 'DPO',
    icaoCode: 'YDPO',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Devonport Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -41.1697,
    longitude: 146.43,
    altitude: 10.0584
  },
  {
    iataCode: 'MAX',
    icaoCode: 'GOSM',
    name: 'Matam',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Ouro Sogui Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 15.593611,
    longitude: -13.322778,
    altitude: 25.908
  },
  {
    iataCode: 'URA',
    icaoCode: 'UARR',
    name: 'Uralsk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Uralsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Oral',
    utcOffset: 300,
    latitude: 51.150833,
    longitude: 51.543056,
    altitude: 38.1
  },
  {
    iataCode: 'DPS',
    icaoCode: 'WADD',
    name: 'Denpasar',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Bali Ngurah Rai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.748169,
    longitude: 115.167172,
    altitude: 4.2672
  },
  {
    iataCode: 'MAZ',
    icaoCode: 'TJMZ',
    name: 'Mayaguez',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Eugenio Maria De Hostos',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.255694,
    longitude: -67.148472,
    altitude: 8.5344
  },
  {
    iataCode: 'URC',
    icaoCode: 'ZWWW',
    name: 'Urumqi',
    country: {code: 'CN', name: 'China'},
    city: 'Diwopu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 43.907106,
    longitude: 87.474244,
    altitude: 647.7
  },
  {
    iataCode: 'URE',
    icaoCode: 'EEKE',
    name: 'Kuressaare',
    country: {code: 'EE', name: 'Estonia'},
    city: 'Kuressaare',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tallinn',
    utcOffset: 120,
    latitude: 58.229883,
    longitude: 22.509494,
    altitude: 4.2672
  },
  {
    iataCode: 'URG',
    icaoCode: 'SBUG',
    name: 'Uruguaiana',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Rubem Berta',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -29.782178,
    longitude: -57.038189,
    altitude: 78.0288
  },
  {
    iataCode: 'URJ',
    icaoCode: 'USHU',
    name: 'Uraj',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Uraj',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 60.1,
    longitude: 64.83,
    altitude: 56.6928
  },
  {
    iataCode: 'MBA',
    icaoCode: 'HKMO',
    name: 'Mombasa',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Mombasa Moi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -4.034833,
    longitude: 39.59425,
    altitude: 60.96
  },
  {
    iataCode: 'MBD',
    icaoCode: 'FAMM',
    name: 'Mafeking',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Mmabatho International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.798444,
    longitude: 25.548028,
    altitude: 1274.3688
  },
  {
    iataCode: 'MBE',
    icaoCode: 'RJEB',
    name: 'Monbetsu',
    country: {code: 'JP', name: 'Japan'},
    city: 'Monbetsu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 44.303914,
    longitude: 143.404028,
    altitude: 24.384
  },
  {
    iataCode: 'MBH',
    icaoCode: 'YMYB',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Maryborough Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -25.5133,
    longitude: 152.715,
    altitude: 11.5824
  },
  {
    iataCode: 'URO',
    icaoCode: 'LFOP',
    name: 'Rouen',
    country: {code: 'FR', name: 'France'},
    city: 'Vallee De Seine',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.384172,
    longitude: 1.1748,
    altitude: 156.0576
  },
  {
    iataCode: 'MBJ',
    icaoCode: 'MKJS',
    name: 'Montego Bay',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Sangster Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 18.503717,
    longitude: -77.913358,
    altitude: 1.2192
  },
  {
    iataCode: 'MBL',
    icaoCode: 'KMBL',
    name: 'Manistee',
    country: {code: 'US', name: 'United States'},
    city: 'Manistee County-blacker Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 44.2725,
    longitude: -86.246944,
    altitude: 189.2808
  },
  {
    iataCode: 'URT',
    icaoCode: 'VTSB',
    name: 'Surat Thani',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Surat Thani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 9.1325,
    longitude: 99.135556,
    altitude: 87.1728
  },
  {
    iataCode: 'URS',
    icaoCode: 'UUOK',
    name: 'Kursk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kursk East Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 51.7506,
    longitude: 36.2956,
    altitude: 209.0928
  },
  {
    iataCode: 'URY',
    icaoCode: 'OEGT',
    name: 'Guriat',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Guriat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 31.411942,
    longitude: 37.279469,
    altitude: 509.6256
  },
  {
    iataCode: 'MBT',
    icaoCode: 'RPVJ',
    name: 'Masbate',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Masbate Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 12.3694,
    longitude: 123.629,
    altitude: 7.9248
  },
  {
    iataCode: 'MBS',
    icaoCode: 'KMBS',
    name: 'Saginaw',
    country: {code: 'US', name: 'United States'},
    city: 'Mbs Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 43.532913,
    longitude: -84.079647,
    altitude: 203.6064
  },
  {
    iataCode: 'MBU',
    icaoCode: 'AGGI',
    name: 'Mbambanakira',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Babanakira Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.7475,
    longitude: 159.839,
    altitude: 0.0
  },
  {
    iataCode: 'MBX',
    icaoCode: 'LJMB',
    name: 'Maribor',
    country: {code: 'SI', name: 'Slovenia'},
    city: 'Maribor',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Ljubljana',
    utcOffset: 60,
    latitude: 46.479861,
    longitude: 15.686131,
    altitude: 267.0048
  },
  {
    iataCode: 'MBW',
    icaoCode: 'YMMB',
    name: 'Melbourne',
    country: {code: 'AU', name: 'Australia'},
    city: 'Melbourne Moorabbin',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -37.975833,
    longitude: 145.102222,
    altitude: 15.24
  },
  {
    iataCode: 'MBZ',
    icaoCode: 'SWMW',
    name: 'Maues',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Maues Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -3.383611,
    longitude: -57.718611,
    altitude: 152.4
  },
  {
    iataCode: 'USI',
    icaoCode: 'SYMB',
    name: 'Mabaruma',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Mabaruma Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 8.2,
    longitude: -59.783333,
    altitude: 3.048
  },
  {
    iataCode: 'USH',
    icaoCode: 'SAWH',
    name: 'Ushuaia',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Ushuaia Malvinas Argentinas',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Ushuaia',
    utcOffset: -180,
    latitude: -54.843278,
    longitude: -68.29575,
    altitude: 21.6408
  },
  {
    iataCode: 'MCC',
    icaoCode: 'KMCC',
    name: 'Sacramento',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Clellan Afld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.667639,
    longitude: -121.400611,
    altitude: 22.86
  },
  {
    iataCode: 'MCE',
    icaoCode: 'KMCE',
    name: 'Merced',
    country: {code: 'US', name: 'United States'},
    city: 'Merced Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.284722,
    longitude: -120.513889,
    altitude: 47.5488
  },
  {
    iataCode: 'USM',
    icaoCode: 'VTSM',
    name: 'Ko Samui',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Samui',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 9.547794,
    longitude: 100.062272,
    altitude: 19.5072
  },
  {
    iataCode: 'MCG',
    icaoCode: 'PAMC',
    name: 'Mcgrath',
    country: {code: 'US', name: 'United States'},
    city: 'Mcgrath Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.9529,
    longitude: -155.606,
    altitude: 103.0224
  },
  {
    iataCode: 'MCF',
    icaoCode: 'KMCF',
    name: 'Tampa',
    country: {code: 'US', name: 'United States'},
    city: 'Macdill Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.849339,
    longitude: -82.521214,
    altitude: 4.2672
  },
  {
    iataCode: 'USN',
    icaoCode: 'RKPU',
    name: 'Ulsan',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Ulsan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.593494,
    longitude: 129.351722,
    altitude: 13.716
  },
  {
    iataCode: 'MCI',
    icaoCode: 'KMCI',
    name: 'Kansas City',
    country: {code: 'US', name: 'United States'},
    city: 'Kansas City Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.297606,
    longitude: -94.713905,
    altitude: 312.7248
  },
  {
    iataCode: 'USQ',
    icaoCode: 'LTBO',
    name: 'Usak',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Usak Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.681478,
    longitude: 29.471675,
    altitude: 883.0056
  },
  {
    iataCode: 'MCH',
    icaoCode: 'SEMH',
    name: 'Machala',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'General Manuel Serrano',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -3.268903,
    longitude: -79.961572,
    altitude: 3.3528
  },
  {
    iataCode: 'MCK',
    icaoCode: 'KMCK',
    name: 'Mccook',
    country: {code: 'US', name: 'United States'},
    city: 'Mccook Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.206389,
    longitude: -100.592222,
    altitude: 787.2984
  },
  {
    iataCode: 'DRB',
    icaoCode: 'YDBY',
    name: 'Derby',
    country: {code: 'AU', name: 'Australia'},
    city: 'Derby Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -17.39,
    longitude: 123.68,
    altitude: 7.9248
  },
  {
    iataCode: 'USU',
    icaoCode: 'RPVV',
    name: 'Busuanga',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Busuanga',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 12.121458,
    longitude: 120.100031,
    altitude: 45.1104
  },
  {
    iataCode: 'UST',
    icaoCode: 'KSGJ',
    name: 'St. Augustine Airport',
    country: {code: 'US', name: 'United States'},
    city: 'St. Augustine Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 29.959167,
    longitude: -81.339722,
    altitude: 3.048
  },
  {
    iataCode: 'MCO',
    icaoCode: 'KMCO',
    name: 'Orlando',
    country: {code: 'US', name: 'United States'},
    city: 'Orlando Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.429394,
    longitude: -81.308994,
    altitude: 29.2608
  },
  {
    iataCode: 'MCN',
    icaoCode: 'KMCN',
    name: 'Macon',
    country: {code: 'US', name: 'United States'},
    city: 'Middle Georgia Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.69285,
    longitude: -83.649211,
    altitude: 107.8992
  },
  {
    iataCode: 'DRI',
    icaoCode: 'KDRI',
    name: 'Deridder',
    country: {code: 'US', name: 'United States'},
    city: 'Beauregard Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.831722,
    longitude: -93.339917,
    altitude: 61.5696
  },
  {
    iataCode: 'MCP',
    icaoCode: 'SBMQ',
    name: 'Macapa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Macapa',
    region: '',
    terminal: '',
    timezoneId: 'America/Belem',
    utcOffset: -180,
    latitude: 0.050664,
    longitude: -51.072178,
    altitude: 17.0688
  },
  {
    iataCode: 'DRK',
    icaoCode: 'MRDK',
    name: 'Puntarenas',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Drake Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 8.71889,
    longitude: -83.6417,
    altitude: 3.6576
  },
  {
    iataCode: 'DRJ',
    icaoCode: 'SMDA',
    name: 'Drietabbetje',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Drietabbetje Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 4.11667,
    longitude: -54.66667,
    altitude: 3.048
  },
  {
    iataCode: 'MCU',
    icaoCode: 'LFLT',
    name: 'Montlucon',
    country: {code: 'FR', name: 'France'},
    city: 'Domerat',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.352525,
    longitude: 2.570486,
    altitude: 235.0008
  },
  {
    iataCode: 'MCT',
    icaoCode: 'OOMS',
    name: 'Muscat',
    country: {code: 'OM', name: 'Oman'},
    city: 'Seeb Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Muscat',
    utcOffset: 240,
    latitude: 23.593278,
    longitude: 58.284444,
    altitude: 14.6304
  },
  {
    iataCode: 'DRO',
    icaoCode: 'KDRO',
    name: 'Durango',
    country: {code: 'US', name: 'United States'},
    city: 'Durango La Plata Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.151516,
    longitude: -107.75377,
    altitude: 2037.588
  },
  {
    iataCode: 'MCW',
    icaoCode: 'KMCW',
    name: 'Mason City',
    country: {code: 'US', name: 'United States'},
    city: 'Mason City Municipal',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 43.2247,
    longitude: -93.4067,
    altitude: 378.8664
  },
  {
    iataCode: 'MCV',
    icaoCode: 'YMHU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mcarthur River Mine Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -16.4425,
    longitude: 136.084,
    altitude: 39.9288
  },
  {
    iataCode: 'MCY',
    icaoCode: 'YBMC',
    name: 'Maroochydore',
    country: {code: 'AU', name: 'Australia'},
    city: 'Maroochydore Sunshine Coast',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -26.603333,
    longitude: 153.091111,
    altitude: 4.572
  },
  {
    iataCode: 'MCX',
    icaoCode: 'URML',
    name: 'Makhachkala',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Uytash',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 42.816822,
    longitude: 47.652294,
    altitude: 3.6576
  },
  {
    iataCode: 'DRS',
    icaoCode: 'EDDC',
    name: 'Dresden',
    country: {code: 'DE', name: 'Germany'},
    city: 'Dresden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.132767,
    longitude: 13.767161,
    altitude: 230.124
  },
  {
    iataCode: 'MCZ',
    icaoCode: 'SBMO',
    name: 'Maceio',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Zumbi Dos Palmares',
    region: '',
    terminal: '',
    timezoneId: 'America/Maceio',
    utcOffset: -180,
    latitude: -9.510808,
    longitude: -35.791678,
    altitude: 117.9576
  },
  {
    iataCode: 'UTC',
    icaoCode: 'EHSB',
    name: 'Soesterberg',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Soesterberg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 52.1273,
    longitude: 5.27619,
    altitude: 20.1168
  },
  {
    iataCode: 'DRT',
    icaoCode: 'KDRT',
    name: 'Del Rio',
    country: {code: 'US', name: 'United States'},
    city: 'Del Rio Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.374208,
    longitude: -100.927158,
    altitude: 305.4096
  },
  {
    iataCode: 'DRW',
    icaoCode: 'YPDN',
    name: 'Darwin',
    country: {code: 'AU', name: 'Australia'},
    city: 'Darwin Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.408333,
    longitude: 130.87266,
    altitude: 31.3944
  },
  {
    iataCode: 'UTH',
    icaoCode: 'VTUD',
    name: 'Udon Thani',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Udon Thani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 17.386436,
    longitude: 102.788247,
    altitude: 176.4792
  },
  {
    iataCode: 'MDC',
    icaoCode: 'WAMM',
    name: 'Manado',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sam Ratulangi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 1.549447,
    longitude: 124.925878,
    altitude: 80.4672
  },
  {
    iataCode: 'UTK',
    icaoCode: '03N',
    name: 'Utirik Island',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Utirik Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 11.222,
    longitude: 169.852,
    altitude: 1.2192
  },
  {
    iataCode: 'UTN',
    icaoCode: 'FAUP',
    name: 'Upington',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Upington',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.399097,
    longitude: 21.260239,
    altitude: 847.9536
  },
  {
    iataCode: 'MDE',
    icaoCode: 'SKRG',
    name: 'Rio Negro',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Jose Maria Cordova',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 6.164536,
    longitude: -75.423119,
    altitude: 2119.884
  },
  {
    iataCode: 'UTP',
    icaoCode: 'VTBU',
    name: 'Pattaya',
    country: {code: 'TH', name: 'Thailand'},
    city: 'U Taphao Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 12.679944,
    longitude: 101.005028,
    altitude: 12.8016
  },
  {
    iataCode: 'UTO',
    icaoCode: 'PAIM',
    name: 'Indian Mountains',
    country: {code: 'US', name: 'United States'},
    city: 'Indian Mountain Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 65.992794,
    longitude: -153.704289,
    altitude: 371.856
  },
  {
    iataCode: 'MDI',
    icaoCode: 'DNMK',
    name: 'Makurdi',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Makurdi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 7.703883,
    longitude: 8.613939,
    altitude: 113.0808
  },
  {
    iataCode: 'DSD',
    icaoCode: 'TFFA',
    name: 'Grande Anse',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'La Désirade Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 16.2969,
    longitude: -61.0844,
    altitude: 3.048
  },
  {
    iataCode: 'MDL',
    icaoCode: 'VYMD',
    name: 'Mandalay',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Mandalay Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 21.702156,
    longitude: 95.977928,
    altitude: 91.44
  },
  {
    iataCode: 'UTT',
    icaoCode: 'FAUT',
    name: 'Umtata',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Mthatha',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -31.547903,
    longitude: 28.674289,
    altitude: 731.52
  },
  {
    iataCode: 'MDK',
    icaoCode: 'FZEA',
    name: 'Mbandaka',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Mbandaka',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: 0.0226,
    longitude: 18.288744,
    altitude: 316.992
  },
  {
    iataCode: 'DSE',
    icaoCode: 'HADC',
    name: 'Dessie',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Combolcha Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 11.0825,
    longitude: 39.7114,
    altitude: 1864.4616
  },
  {
    iataCode: 'MDO',
    icaoCode: 'SUPE',
    name: 'Maldonado',
    country: {code: 'UY', name: 'Uruguay'},
    city: 'El Jaguel / Punta Del Este Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montevideo',
    utcOffset: -240,
    latitude: -34.917,
    longitude: -54.917,
    altitude: 20.1168
  },
  {
    iataCode: 'UTW',
    icaoCode: 'FAQT',
    name: 'Queenstown',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Queenstown',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -31.920197,
    longitude: 26.882206,
    altitude: 1108.5576
  },
  {
    iataCode: 'MDQ',
    icaoCode: 'SAZM',
    name: 'Mar Del Plata',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Mar Del Plata',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -37.934167,
    longitude: -57.573333,
    altitude: 21.6408
  },
  {
    iataCode: 'MDT',
    icaoCode: 'KMDT',
    name: 'Harrisburg',
    country: {code: 'US', name: 'United States'},
    city: 'Harrisburg Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.193494,
    longitude: -76.763403,
    altitude: 94.488
  },
  {
    iataCode: 'DSK',
    icaoCode: 'OPDI',
    name: 'Dera Ismael Khan',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Dera Ismael Khan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 31.909422,
    longitude: 70.896639,
    altitude: 181.0512
  },
  {
    iataCode: 'MDS',
    icaoCode: 'MBMC',
    name: 'Middle Caicos',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'Middle Caicos Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.833,
    longitude: -71.817,
    altitude: 2.7432
  },
  {
    iataCode: 'DSN',
    icaoCode: 'ZBDS',
    name: 'Dongsheng',
    country: {code: 'CN', name: 'China'},
    city: 'Dongsheng',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 39.85,
    longitude: 110.033,
    altitude: 0.0
  },
  {
    iataCode: 'DSM',
    icaoCode: 'KDSM',
    name: 'Des Moines',
    country: {code: 'US', name: 'United States'},
    city: 'Des Moines Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.533972,
    longitude: -93.663083,
    altitude: 291.9984
  },
  {
    iataCode: 'MDU',
    icaoCode: 'AYMN',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Mendi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.14774,
    longitude: 143.657,
    altitude: 1731.264
  },
  {
    iataCode: 'UUA',
    icaoCode: 'UWKB',
    name: 'Bugulma',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Bugulma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 300,
    latitude: 54.64,
    longitude: 52.8017,
    altitude: 302.0568
  },
  {
    iataCode: 'MDW',
    icaoCode: 'KMDW',
    name: 'Chicago',
    country: {code: 'US', name: 'United States'},
    city: 'Chicago Midway Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.785972,
    longitude: -87.752417,
    altitude: 188.976
  },
  {
    iataCode: 'MDZ',
    icaoCode: 'SAME',
    name: 'Mendoza',
    country: {code: 'AR', name: 'Argentina'},
    city: 'El Plumerillo',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Mendoza',
    utcOffset: -180,
    latitude: -32.831717,
    longitude: -68.792856,
    altitude: 704.088
  },
  {
    iataCode: 'MDY',
    icaoCode: 'PMDY',
    name: 'Midway',
    country: {code: '', name: 'Midway Islands'},
    city: 'Midway Atoll',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Midway',
    utcOffset: -660,
    latitude: 28.201725,
    longitude: -177.380636,
    altitude: 3.9624
  },
  {
    iataCode: 'UUD',
    icaoCode: 'UIUU',
    name: 'Ulan-ude',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Mukhino',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Irkutsk',
    utcOffset: 480,
    latitude: 51.807764,
    longitude: 107.437644,
    altitude: 515.112
  },
  {
    iataCode: 'MEA',
    icaoCode: 'SBME',
    name: 'Macaé',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Macaé Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.343,
    longitude: -41.766,
    altitude: 2.4384
  },
  {
    iataCode: 'MEC',
    icaoCode: 'SEMT',
    name: 'Manta',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Eloy Alfaro Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -0.946078,
    longitude: -80.678808,
    altitude: 14.6304
  },
  {
    iataCode: 'UUK',
    icaoCode: 'PAKU',
    name: 'Kuparuk',
    country: {code: 'US', name: 'United States'},
    city: 'Ugnu-kuparuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.330833,
    longitude: -149.5975,
    altitude: 20.4216
  },
  {
    iataCode: 'MEB',
    icaoCode: 'YMEN',
    name: 'Melbourne',
    country: {code: 'AU', name: 'Australia'},
    city: 'Melbourne Essendon',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -37.728056,
    longitude: 144.901944,
    altitude: 85.9536
  },
  {
    iataCode: 'MEE',
    icaoCode: 'NWWR',
    name: 'Mare',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Mare',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -21.481678,
    longitude: 168.037508,
    altitude: 42.9768
  },
  {
    iataCode: 'MED',
    icaoCode: 'OEMA',
    name: 'Madinah',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Prince Mohammad Bin Abdulaziz',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 24.553422,
    longitude: 39.705061,
    altitude: 655.6248
  },
  {
    iataCode: 'MEG',
    icaoCode: 'FNMA',
    name: 'Malanje',
    country: {code: 'AO', name: 'Angola'},
    city: 'Malanje',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -9.525086,
    longitude: 16.312406,
    altitude: 1178.9664
  },
  {
    iataCode: 'DTA',
    icaoCode: 'KDTA',
    name: 'Delta',
    country: {code: 'US', name: 'United States'},
    city: 'Delta Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 39.380639,
    longitude: -112.507715,
    altitude: 1450.5432
  },
  {
    iataCode: 'MEI',
    icaoCode: 'KMEI',
    name: 'Meridian',
    country: {code: 'US', name: 'United States'},
    city: 'Key Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.332624,
    longitude: -88.751868,
    altitude: 90.5256
  },
  {
    iataCode: 'MEH',
    icaoCode: 'ENMR',
    name: 'Mehamn',
    country: {code: 'NO', name: 'Norway'},
    city: 'Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 71.029722,
    longitude: 27.826667,
    altitude: 12.4968
  },
  {
    iataCode: 'MEK',
    icaoCode: 'GMFM',
    name: 'Meknes',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Bassatine',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 33.879067,
    longitude: -5.515125,
    altitude: 576.072
  },
  {
    iataCode: 'UUS',
    icaoCode: 'UHSS',
    name: 'Yuzhno-sakhalinsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Khomutovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Sakhalin',
    utcOffset: 600,
    latitude: 46.888672,
    longitude: 142.717531,
    altitude: 17.9832
  },
  {
    iataCode: 'MEM',
    icaoCode: 'KMEM',
    name: 'Memphis',
    country: {code: 'US', name: 'United States'},
    city: 'Memphis Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.042417,
    longitude: -89.976667,
    altitude: 103.9368
  },
  {
    iataCode: 'DTD',
    icaoCode: 'WALJ',
    name: 'Datadawai-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Datadawai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 0.717,
    longitude: 116.483,
    altitude: 69.7992
  },
  {
    iataCode: 'MEL',
    icaoCode: 'YMML',
    name: 'Melbourne',
    country: {code: 'AU', name: 'Australia'},
    city: 'Melbourne Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -37.673333,
    longitude: 144.843333,
    altitude: 132.2832
  },
  {
    iataCode: 'MEN',
    icaoCode: 'LFNB',
    name: 'Mende',
    country: {code: 'FR', name: 'France'},
    city: 'Brenoux',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.502108,
    longitude: 3.532819,
    altitude: 1024.7376
  },
  {
    iataCode: 'MES',
    icaoCode: 'WIMM',
    name: 'Medan',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Polonia',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 3.558056,
    longitude: 98.671722,
    altitude: 34.7472
  },
  {
    iataCode: 'MER',
    icaoCode: 'KMER',
    name: 'Merced',
    country: {code: 'US', name: 'United States'},
    city: 'Castle',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.380481,
    longitude: -120.568189,
    altitude: 57.6072
  },
  {
    iataCode: 'DTM',
    icaoCode: 'EDLW',
    name: 'Dortmund',
    country: {code: 'DE', name: 'Germany'},
    city: 'Dortmund',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.518314,
    longitude: 7.612242,
    altitude: 129.54
  },
  {
    iataCode: 'MEY',
    icaoCode: 'VNMG',
    name: 'Meghauli',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Meghauli Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.583,
    longitude: 84.233,
    altitude: 182.88
  },
  {
    iataCode: 'MEX',
    icaoCode: 'MMMX',
    name: 'Mexico City',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Licenciado Benito Juarez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.436303,
    longitude: -99.072097,
    altitude: 2229.9168
  },
  {
    iataCode: 'DTS',
    icaoCode: 'KDTS',
    name: 'Destin',
    country: {code: 'US', name: 'United States'},
    city: 'Destin',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 30.400061,
    longitude: -86.471477,
    altitude: 7.0104
  },
  {
    iataCode: 'MEZ',
    icaoCode: 'FAMS',
    name: 'Messina',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Morningside Farm',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.704458,
    longitude: 26.908978,
    altitude: 1295.7048
  },
  {
    iataCode: 'UVF',
    icaoCode: 'TLPL',
    name: 'Hewandorra',
    country: {code: 'LC', name: 'Saint Lucia'},
    city: 'Hewanorra Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Lucia',
    utcOffset: -240,
    latitude: 13.733194,
    longitude: -60.952597,
    altitude: 4.2672
  },
  {
    iataCode: 'UVE',
    icaoCode: 'NWWV',
    name: 'Ouvea',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Ouvea',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -20.640556,
    longitude: 166.572778,
    altitude: 7.0104
  },
  {
    iataCode: 'DTW',
    icaoCode: 'KDTW',
    name: 'Detroit',
    country: {code: 'US', name: 'United States'},
    city: 'Detroit Metro Wayne Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.212444,
    longitude: -83.353389,
    altitude: 196.596
  },
  {
    iataCode: 'MFA',
    icaoCode: 'HTMA',
    name: 'Mafia Island',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Mafia',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -7.913889,
    longitude: 39.665,
    altitude: 18.288
  },
  {
    iataCode: 'MFD',
    icaoCode: 'KMFD',
    name: 'Mansfield',
    country: {code: 'US', name: 'United States'},
    city: 'Mansfield Lahm Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.821417,
    longitude: -82.516639,
    altitude: 395.3256
  },
  {
    iataCode: 'MFE',
    icaoCode: 'KMFE',
    name: 'Mcallen',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Allen Miller Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 26.175833,
    longitude: -98.238611,
    altitude: 32.6136
  },
  {
    iataCode: 'MFG',
    icaoCode: 'OPMF',
    name: 'Muzaffarabad',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Muzaffarabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 34.339022,
    longitude: 73.508639,
    altitude: 820.2168
  },
  {
    iataCode: 'DUB',
    icaoCode: 'EIDW',
    name: 'Dublin',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Dublin',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.421333,
    longitude: -6.270075,
    altitude: 73.7616
  },
  {
    iataCode: 'MFJ',
    icaoCode: 'NFMO',
    name: 'Moala',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Moala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -18.5667,
    longitude: 179.951,
    altitude: 3.9624
  },
  {
    iataCode: 'DUD',
    icaoCode: 'NZDN',
    name: 'Dunedin',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Dunedin',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -45.928055,
    longitude: 170.198333,
    altitude: 1.2192
  },
  {
    iataCode: 'MFK',
    icaoCode: 'RCMT',
    name: 'Matsu Islands',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Matsu Beigan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 26.224153,
    longitude: 120.00275,
    altitude: 12.4968
  },
  {
    iataCode: 'MFN',
    icaoCode: 'NZMF',
    name: 'Milford Sound',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Milford Sound Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -44.67333,
    longitude: 167.92333,
    altitude: 3.048
  },
  {
    iataCode: 'DUE',
    icaoCode: 'FNDU',
    name: '',
    country: {code: 'AO', name: 'Angola'},
    city: 'Dundo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -7.40089,
    longitude: 20.8185,
    altitude: 747.0648
  },
  {
    iataCode: 'MFM',
    icaoCode: 'VMMC',
    name: 'Macau',
    country: {code: 'MO', name: 'Macao'},
    city: 'Macau Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Macau',
    utcOffset: 480,
    latitude: 22.149556,
    longitude: 113.591558,
    altitude: 6.096
  },
  {
    iataCode: 'DUH',
    icaoCode: 'FAOH',
    name: 'Oudtshoorn',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Oudtshoorn',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -33.606967,
    longitude: 22.188978,
    altitude: 324.0024
  },
  {
    iataCode: 'DUG',
    icaoCode: 'KDUG',
    name: 'Douglas',
    country: {code: 'US', name: 'United States'},
    city: 'Bisbee Douglas Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 31.469028,
    longitude: -109.603667,
    altitude: 1266.1392
  },
  {
    iataCode: 'DUJ',
    icaoCode: 'KDUJ',
    name: 'Du Bois',
    country: {code: 'US', name: 'United States'},
    city: 'Dubois Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.1783,
    longitude: -78.8987,
    altitude: 553.8216
  },
  {
    iataCode: 'MFR',
    icaoCode: 'KMFR',
    name: 'Medford',
    country: {code: 'US', name: 'United States'},
    city: 'Rogue Valley Intl Medford',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 42.374228,
    longitude: -122.8735,
    altitude: 406.908
  },
  {
    iataCode: 'MFQ',
    icaoCode: 'DRRM',
    name: 'Maradi',
    country: {code: 'NE', name: 'Niger'},
    city: 'Maradi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Niamey',
    utcOffset: 60,
    latitude: 13.502531,
    longitude: 7.126753,
    altitude: 377.952
  },
  {
    iataCode: 'DUM',
    icaoCode: 'WIBD',
    name: 'Dumai',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pinang Kampai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 1.609194,
    longitude: 101.433558,
    altitude: 16.764
  },
  {
    iataCode: 'MFU',
    icaoCode: 'FLMF',
    name: 'Mfuwe',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Mfuwe',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -13.258878,
    longitude: 31.936581,
    altitude: 564.7944
  },
  {
    iataCode: 'DUR',
    icaoCode: 'FADN',
    name: 'Durban',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Durban Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.970089,
    longitude: 30.950519,
    altitude: 10.0584
  },
  {
    iataCode: 'DUT',
    icaoCode: 'PADU',
    name: 'Unalaska',
    country: {code: 'US', name: 'United States'},
    city: 'Unalaska',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 53.900139,
    longitude: -166.5435,
    altitude: 6.7056
  },
  {
    iataCode: 'DUS',
    icaoCode: 'EDDL',
    name: 'Duesseldorf',
    country: {code: 'DE', name: 'Germany'},
    city: 'Dusseldorf',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.289453,
    longitude: 6.766775,
    altitude: 44.8056
  },
  {
    iataCode: 'MGA',
    icaoCode: 'MNMG',
    name: 'Managua',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Managua Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 12.141494,
    longitude: -86.168178,
    altitude: 59.1312
  },
  {
    iataCode: 'MGC',
    icaoCode: 'KMGC',
    name: 'Michigan City',
    country: {code: 'US', name: 'United States'},
    city: 'Michigan City Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 41.7033,
    longitude: -86.8211,
    altitude: 152.4
  },
  {
    iataCode: 'MGB',
    icaoCode: 'YMTG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mount Gambier Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -37.7456,
    longitude: 140.785,
    altitude: 64.6176
  },
  {
    iataCode: 'MGE',
    icaoCode: 'KMGE',
    name: 'Marietta',
    country: {code: 'US', name: 'United States'},
    city: 'Dobbins Arb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.915382,
    longitude: -84.516319,
    altitude: 325.5264
  },
  {
    iataCode: 'MGF',
    icaoCode: 'SBMG',
    name: 'Maringa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Regional De Maringa Silvio Name Junior',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.476392,
    longitude: -52.016406,
    altitude: 544.9824
  },
  {
    iataCode: 'MGH',
    icaoCode: 'FAMG',
    name: 'Margate',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Margate',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -30.857408,
    longitude: 30.343019,
    altitude: 150.876
  },
  {
    iataCode: 'MGM',
    icaoCode: 'KMGM',
    name: 'Montgomery',
    country: {code: 'US', name: 'United States'},
    city: 'Montgomery Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.300639,
    longitude: -86.393972,
    altitude: 67.3608
  },
  {
    iataCode: 'MGL',
    icaoCode: 'EDLN',
    name: 'Moenchengladbach',
    country: {code: 'DE', name: 'Germany'},
    city: 'Monchengladbach',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.230356,
    longitude: 6.504494,
    altitude: 38.1
  },
  {
    iataCode: 'MGN',
    icaoCode: 'SKMG',
    name: 'Magangue',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Baracoa',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 9.284739,
    longitude: -74.846092,
    altitude: 54.2544
  },
  {
    iataCode: 'MGQ',
    icaoCode: 'HCMM',
    name: 'Mogadishu',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Aden Adde International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 2.01444,
    longitude: 45.3047,
    altitude: 8.8392
  },
  {
    iataCode: 'MGS',
    icaoCode: 'NCMG',
    name: 'Mangaia Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Mangaia Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -21.8956,
    longitude: -157.905,
    altitude: 13.716
  },
  {
    iataCode: 'DVL',
    icaoCode: 'KDVL',
    name: 'Devils Lake',
    country: {code: 'US', name: 'United States'},
    city: 'Devils Lake Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.114444,
    longitude: -98.908611,
    altitude: 440.436
  },
  {
    iataCode: 'MGT',
    icaoCode: 'YMGB',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Milingimbi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.0944,
    longitude: 134.894,
    altitude: 16.1544
  },
  {
    iataCode: 'DVO',
    icaoCode: 'RPMD',
    name: 'Davao',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Francisco Bangoy International',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 7.9,
    longitude: 125.37,
    altitude: 29.2608
  },
  {
    iataCode: 'MGW',
    icaoCode: 'KMGW',
    name: 'Morgantown',
    country: {code: 'US', name: 'United States'},
    city: 'Morgantown Muni Walter L Bill Hart Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.642908,
    longitude: -79.916314,
    altitude: 380.3904
  },
  {
    iataCode: 'MGZ',
    icaoCode: 'VYME',
    name: 'Myeik',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Myeik',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 12.439797,
    longitude: 98.621478,
    altitude: 22.86
  },
  {
    iataCode: 'MHA',
    icaoCode: 'SYMD',
    name: 'Mahdia',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Mahdia Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 5.266667,
    longitude: -59.15,
    altitude: 3.048
  },
  {
    iataCode: 'MHH',
    icaoCode: 'MYAM',
    name: 'Marsh Harbor',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Marsh Harbour',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 26.511406,
    longitude: -77.083472,
    altitude: 1.8288
  },
  {
    iataCode: 'MHG',
    icaoCode: 'EDFM',
    name: 'Mannheim',
    country: {code: 'DE', name: 'Germany'},
    city: 'Mannheim City',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.472706,
    longitude: 8.514264,
    altitude: 94.1832
  },
  {
    iataCode: 'DWB',
    icaoCode: 'FMNO',
    name: 'Soalala',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Soalala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -16.083,
    longitude: 45.367,
    altitude: 42.9768
  },
  {
    iataCode: 'DWA',
    icaoCode: 'KDWA',
    name: 'Davis-woodland-winters',
    country: {code: 'US', name: 'United States'},
    city: 'Yolo County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 38.579389,
    longitude: -121.856944,
    altitude: 30.48
  },
  {
    iataCode: 'DWD',
    icaoCode: 'OEDW',
    name: 'Dawadmi',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Dawadmi Domestic Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 24.5,
    longitude: 44.4,
    altitude: 1045.1592
  },
  {
    iataCode: 'MHK',
    icaoCode: 'KMHK',
    name: 'Manhattan',
    country: {code: 'US', name: 'United States'},
    city: 'Manhattan Reigonal',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.140972,
    longitude: -96.670833,
    altitude: 322.1736
  },
  {
    iataCode: 'MHM',
    icaoCode: 'PAMH',
    name: 'Lake Minchumina',
    country: {code: 'US', name: 'United States'},
    city: 'Minchumina Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.886111,
    longitude: -152.301944,
    altitude: 206.6544
  },
  {
    iataCode: 'DWH',
    icaoCode: 'KDWH',
    name: 'Houston',
    country: {code: 'US', name: 'United States'},
    city: 'David Wayne Hooks Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.063746,
    longitude: -95.554276,
    altitude: 46.3296
  },
  {
    iataCode: 'MHP',
    icaoCode: 'UMMM',
    name: 'Minsk',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Minsk 1',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 53.864472,
    longitude: 27.539683,
    altitude: 227.9904
  },
  {
    iataCode: 'MHR',
    icaoCode: 'KMHR',
    name: 'Sacramento',
    country: {code: 'US', name: 'United States'},
    city: 'Sacramento Mather',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.553897,
    longitude: -121.297592,
    altitude: 29.2608
  },
  {
    iataCode: 'MHQ',
    icaoCode: 'EFMA',
    name: 'Mariehamn',
    country: {code: 'FI', name: 'Finland'},
    city: 'Mariehamn',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Mariehamn',
    utcOffset: 60,
    latitude: 60.122203,
    longitude: 19.898156,
    altitude: 5.1816
  },
  {
    iataCode: 'MHT',
    icaoCode: 'KMHT',
    name: 'Manchester Nh',
    country: {code: 'US', name: 'United States'},
    city: 'Manchester Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.932556,
    longitude: -71.435667,
    altitude: 81.0768
  },
  {
    iataCode: 'MHV',
    icaoCode: 'KMHV',
    name: 'Mojave',
    country: {code: 'US', name: 'United States'},
    city: 'Mojave',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.059364,
    longitude: -118.151856,
    altitude: 850.6968
  },
  {
    iataCode: 'MHU',
    icaoCode: 'YHOT',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mount Hotham Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -37.0475,
    longitude: 147.334,
    altitude: 1298.448
  },
  {
    iataCode: 'MHX',
    icaoCode: 'NCMH',
    name: 'Manihiki Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Manihiki Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -10.3767,
    longitude: -161.002,
    altitude: 0.0
  },
  {
    iataCode: 'MHZ',
    icaoCode: 'EGUN',
    name: 'Mildenhall',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Mildenhall',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.361933,
    longitude: 0.486406,
    altitude: 10.0584
  },
  {
    iataCode: 'MIA',
    icaoCode: 'KMIA',
    name: 'Miami',
    country: {code: 'US', name: 'United States'},
    city: 'Miami Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 25.79325,
    longitude: -80.290556,
    altitude: 2.4384
  },
  {
    iataCode: 'MIB',
    icaoCode: 'KMIB',
    name: 'Minot',
    country: {code: 'US', name: 'United States'},
    city: 'Minot Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.415572,
    longitude: -101.357661,
    altitude: 508.4064
  },
  {
    iataCode: 'MID',
    icaoCode: 'MMMD',
    name: 'Merida',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Licenciado Manuel Crescencio Rejon Int',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 20.936981,
    longitude: -89.657672,
    altitude: 11.5824
  },
  {
    iataCode: 'UYL',
    icaoCode: 'HSNN',
    name: 'Nyala',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Nyala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 12.0535,
    longitude: 24.9562,
    altitude: 641.9088
  },
  {
    iataCode: 'MIG',
    icaoCode: 'ZUMY',
    name: 'Mianyang',
    country: {code: 'CN', name: 'China'},
    city: 'Mianyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 31.4281,
    longitude: 104.741,
    altitude: 0.0
  },
  {
    iataCode: 'UYN',
    icaoCode: 'ZLYL',
    name: 'Yulin',
    country: {code: 'CN', name: 'China'},
    city: 'Yulin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 38.2692,
    longitude: 109.731,
    altitude: 0.0
  },
  {
    iataCode: 'MII',
    icaoCode: 'SBML',
    name: 'Marília',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Marília Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.196892,
    longitude: -49.9264,
    altitude: 646.7856
  },
  {
    iataCode: 'MIK',
    icaoCode: 'EFMI',
    name: 'Mikkeli',
    country: {code: 'FI', name: 'Finland'},
    city: 'Mikkeli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.6866,
    longitude: 27.201794,
    altitude: 100.2792
  },
  {
    iataCode: 'DXB',
    icaoCode: 'OMDB',
    name: 'Dubai',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Dubai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 25.252778,
    longitude: 55.364444,
    altitude: 18.8976
  },
  {
    iataCode: 'MIJ',
    icaoCode: 'MLIP',
    name: 'Mili Island',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Mili Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 6.08333,
    longitude: 171.733,
    altitude: 1.2192
  },
  {
    iataCode: 'MIM',
    icaoCode: 'YMER',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Merimbula Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -36.9086,
    longitude: 149.901,
    altitude: 2.1336
  },
  {
    iataCode: 'MIS',
    icaoCode: 'AYMS',
    name: 'Misima Island',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Misima Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -10.6892,
    longitude: 152.838,
    altitude: 7.9248
  },
  {
    iataCode: 'MIR',
    icaoCode: 'DTMB',
    name: 'Monastir',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Habib Bourguiba Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 35.758056,
    longitude: 10.754722,
    altitude: 2.7432
  },
  {
    iataCode: 'MIU',
    icaoCode: 'DNMA',
    name: 'Maiduguri',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Maiduguri',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 11.855347,
    longitude: 13.08095,
    altitude: 334.9752
  },
  {
    iataCode: 'MIV',
    icaoCode: 'KMIV',
    name: 'Millville',
    country: {code: 'US', name: 'United States'},
    city: 'Millville Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.367806,
    longitude: -75.072222,
    altitude: 25.908
  },
  {
    iataCode: 'MJB',
    icaoCode: 'Q30',
    name: 'Mejit Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Mejit Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 10.2833,
    longitude: 170.883,
    altitude: 1.524
  },
  {
    iataCode: 'MJA',
    icaoCode: 'FMSJ',
    name: 'Manja',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Manja Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -21.417,
    longitude: 44.317,
    altitude: 239.8776
  },
  {
    iataCode: 'MJD',
    icaoCode: 'OPMJ',
    name: 'Moenjodaro',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Moenjodaro',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 27.335156,
    longitude: 68.143053,
    altitude: 46.9392
  },
  {
    iataCode: 'MJC',
    icaoCode: 'DIMN',
    name: 'Man',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Man',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 7.272069,
    longitude: -7.587364,
    altitude: 331.9272
  },
  {
    iataCode: 'MJF',
    icaoCode: 'ENMS',
    name: 'Mosjoen',
    country: {code: 'NO', name: 'Norway'},
    city: 'Kjaerstad',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 65.783997,
    longitude: 13.214914,
    altitude: 72.2376
  },
  {
    iataCode: 'MJI',
    icaoCode: 'HLLM',
    name: 'Tripoli',
    country: {code: 'LY', name: 'Libya'},
    city: 'Mitiga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 32.8941,
    longitude: 13.276,
    altitude: 10.9728
  },
  {
    iataCode: 'MJL',
    icaoCode: 'FOGM',
    name: 'Mouila',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Mouilla Ville Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -1.84514,
    longitude: 11.0567,
    altitude: 89.916
  },
  {
    iataCode: 'MJN',
    icaoCode: 'FMNM',
    name: 'Mahajanga',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Philibert Tsiranana',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -15.667144,
    longitude: 46.351828,
    altitude: 26.5176
  },
  {
    iataCode: 'MJM',
    icaoCode: 'FZWA',
    name: 'Mbuji-mayi',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Mbuji Mayi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -6.121236,
    longitude: 23.569008,
    altitude: 676.9608
  },
  {
    iataCode: 'DYG',
    icaoCode: 'ZGDY',
    name: 'Dayong',
    country: {code: 'CN', name: 'China'},
    city: 'Dayong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.1028,
    longitude: 110.443,
    altitude: 210.9216
  },
  {
    iataCode: 'MJT',
    icaoCode: 'LGMT',
    name: 'Mytilini',
    country: {code: 'GR', name: 'Greece'},
    city: 'Mitilini',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.056667,
    longitude: 26.598333,
    altitude: 18.288
  },
  {
    iataCode: 'MJV',
    icaoCode: 'LELC',
    name: 'Murcia',
    country: {code: 'ES', name: 'Spain'},
    city: 'Murcia San Javier',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 37.774972,
    longitude: -0.812389,
    altitude: 3.3528
  },
  {
    iataCode: 'DYR',
    icaoCode: 'UHMA',
    name: 'Anadyr',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Ugolny Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Anadyr',
    utcOffset: 720,
    latitude: 64.73495,
    longitude: 177.741483,
    altitude: 59.1312
  },
  {
    iataCode: 'MJZ',
    icaoCode: 'UERR',
    name: 'Mirnyj',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Mirny',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 62.534689,
    longitude: 114.038928,
    altitude: 352.3488
  },
  {
    iataCode: 'DYS',
    icaoCode: 'KDYS',
    name: 'Abilene',
    country: {code: 'US', name: 'United States'},
    city: 'Dyess Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.420756,
    longitude: -99.8546,
    altitude: 545.2872
  },
  {
    iataCode: 'DYU',
    icaoCode: 'UTDD',
    name: 'Dushanbe',
    country: {code: 'TJ', name: 'Tajikistan'},
    city: 'Dushanbe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dushanbe',
    utcOffset: 300,
    latitude: 38.543333,
    longitude: 68.825,
    altitude: 784.86
  },
  {
    iataCode: 'MKC',
    icaoCode: 'KMKC',
    name: 'Kansas City',
    country: {code: 'US', name: 'United States'},
    city: 'Downtown',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.1275,
    longitude: -94.598889,
    altitude: 231.3432
  },
  {
    iataCode: 'MKE',
    icaoCode: 'KMKE',
    name: 'Milwaukee',
    country: {code: 'US', name: 'United States'},
    city: 'General Mitchell Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.947222,
    longitude: -87.896583,
    altitude: 220.3704
  },
  {
    iataCode: 'MKG',
    icaoCode: 'KMKG',
    name: 'Muskegon',
    country: {code: 'US', name: 'United States'},
    city: 'Muskegon County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 43.1695,
    longitude: -86.2382,
    altitude: 191.4144
  },
  {
    iataCode: 'DZA',
    icaoCode: 'FMCZ',
    name: 'Dzaoudzi',
    country: {code: 'YT', name: 'Mayotte'},
    city: 'Dzaoudzi Pamandzi',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mayotte',
    utcOffset: 180,
    latitude: -12.804722,
    longitude: 45.281113,
    altitude: 7.0104
  },
  {
    iataCode: 'MKK',
    icaoCode: 'PHMK',
    name: 'Molokai',
    country: {code: 'US', name: 'United States'},
    city: 'Molokai',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.152886,
    longitude: -157.096256,
    altitude: 138.3792
  },
  {
    iataCode: 'MKM',
    icaoCode: 'WBGK',
    name: 'Mukah',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Mukah Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 2.90639,
    longitude: 112.08,
    altitude: 3.9624
  },
  {
    iataCode: 'MKL',
    icaoCode: 'KMKL',
    name: 'Jackson',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Kellar Sipes Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.599889,
    longitude: -88.915611,
    altitude: 132.2832
  },
  {
    iataCode: 'MKO',
    icaoCode: 'KMKO',
    name: 'Muskogee',
    country: {code: 'US', name: 'United States'},
    city: 'Davis Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.656489,
    longitude: -95.366656,
    altitude: 186.5376
  },
  {
    iataCode: 'MKQ',
    icaoCode: 'WAKK',
    name: 'Merauke',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Mopah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -8.520294,
    longitude: 140.418453,
    altitude: 3.048
  },
  {
    iataCode: 'MKP',
    icaoCode: 'NTGM',
    name: 'Makemo',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Makemo',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.583919,
    longitude: -143.658369,
    altitude: 0.9144
  },
  {
    iataCode: 'MKS',
    icaoCode: 'HAMA',
    name: 'Mekane Selam',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Mekane Salam Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 10.633333,
    longitude: 38.783333,
    altitude: 914.4
  },
  {
    iataCode: 'MKR',
    icaoCode: 'YMEK',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Meekatharra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -26.6117,
    longitude: 118.548,
    altitude: 522.1224
  },
  {
    iataCode: 'MKU',
    icaoCode: 'FOOK',
    name: 'Makokou',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Makokou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: 0.579211,
    longitude: 12.890908,
    altitude: 526.0848
  },
  {
    iataCode: 'MKW',
    icaoCode: 'WASR',
    name: 'Manokwari',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Rendani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -0.891833,
    longitude: 134.049183,
    altitude: 7.0104
  },
  {
    iataCode: 'DZN',
    icaoCode: 'UAKD',
    name: 'Zhezkazgan',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Zhezkazgan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 47.708333,
    longitude: 67.733333,
    altitude: 381.0
  },
  {
    iataCode: 'MKY',
    icaoCode: 'YBMK',
    name: 'Mackay',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mackay',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -21.171667,
    longitude: 149.179722,
    altitude: 5.7912
  },
  {
    iataCode: 'MKZ',
    icaoCode: 'WMKM',
    name: 'Malacca',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Malacca',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 2.263361,
    longitude: 102.251553,
    altitude: 10.668
  },
  {
    iataCode: 'MLB',
    icaoCode: 'KMLB',
    name: 'Melbourne',
    country: {code: 'US', name: 'United States'},
    city: 'Melbourne Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.102753,
    longitude: -80.645258,
    altitude: 10.0584
  },
  {
    iataCode: 'MLA',
    icaoCode: 'LMML',
    name: 'Malta',
    country: {code: 'MT', name: 'Malta'},
    city: 'Luqa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Malta',
    utcOffset: 60,
    latitude: 35.857497,
    longitude: 14.4775,
    altitude: 91.44
  },
  {
    iataCode: 'MLD',
    icaoCode: 'KMLD',
    name: 'Malad City',
    country: {code: 'US', name: 'United States'},
    city: 'Malad City',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 42.17,
    longitude: -112.289,
    altitude: 1372.5144
  },
  {
    iataCode: 'MLC',
    icaoCode: 'KMLC',
    name: 'Mcalester',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Alester Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.882403,
    longitude: -95.783463,
    altitude: 234.696
  },
  {
    iataCode: 'MLE',
    icaoCode: 'VRMM',
    name: 'Male',
    country: {code: 'MV', name: 'Maldives'},
    city: 'Male Intl',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Maldives',
    utcOffset: 300,
    latitude: 4.191833,
    longitude: 73.529128,
    altitude: 1.8288
  },
  {
    iataCode: 'MLH',
    icaoCode: 'LFSB',
    name: 'Mulhouse',
    country: {code: 'FR', name: 'France'},
    city: 'Bale Mulhouse',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.589583,
    longitude: 7.529914,
    altitude: 269.748
  },
  {
    iataCode: 'MLG',
    icaoCode: 'WARA',
    name: 'Malang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Abdul Rachman Saleh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -7.926556,
    longitude: 112.714514,
    altitude: 526.0848
  },
  {
    iataCode: 'MLI',
    icaoCode: 'KMLI',
    name: 'Moline',
    country: {code: 'US', name: 'United States'},
    city: 'Quad City Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.448528,
    longitude: -90.507539,
    altitude: 179.832
  },
  {
    iataCode: 'MLL',
    icaoCode: 'PADM',
    name: 'Marshall',
    country: {code: 'US', name: 'United States'},
    city: 'Marshall Don Hunter Sr. Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 61.864642,
    longitude: -162.026111,
    altitude: 31.3944
  },
  {
    iataCode: 'MLN',
    icaoCode: 'GEML',
    name: 'Melilla',
    country: {code: 'ES', name: 'Spain'},
    city: 'Melilla',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ceuta',
    utcOffset: 0,
    latitude: 35.279817,
    longitude: -2.956256,
    altitude: 47.5488
  },
  {
    iataCode: 'MLM',
    icaoCode: 'MMMM',
    name: 'Morelia',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Francisco J Mujica Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.849944,
    longitude: -101.0255,
    altitude: 1838.8584
  },
  {
    iataCode: 'MLO',
    icaoCode: 'LGML',
    name: 'Milos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Milos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.696111,
    longitude: 24.4775,
    altitude: 3.6576
  },
  {
    iataCode: 'MLT',
    icaoCode: 'KMLT',
    name: 'Millinocket',
    country: {code: 'US', name: 'United States'},
    city: 'Millinocket Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 45.647836,
    longitude: -68.685561,
    altitude: 124.3584
  },
  {
    iataCode: 'MLS',
    icaoCode: 'KMLS',
    name: 'Miles City',
    country: {code: 'US', name: 'United States'},
    city: 'Frank Wiley Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 46.428,
    longitude: -105.886,
    altitude: 801.624
  },
  {
    iataCode: 'MLU',
    icaoCode: 'KMLU',
    name: 'Monroe',
    country: {code: 'US', name: 'United States'},
    city: 'Monroe Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.510864,
    longitude: -92.037689,
    altitude: 24.0792
  },
  {
    iataCode: 'MLX',
    icaoCode: 'LTAT',
    name: 'Malatya',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Erhac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.435347,
    longitude: 38.091006,
    altitude: 861.9744
  },
  {
    iataCode: 'MLW',
    icaoCode: 'GLMR',
    name: 'Monrovia',
    country: {code: 'LR', name: 'Liberia'},
    city: 'Monrovia Spriggs Payne',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Monrovia',
    utcOffset: 0,
    latitude: 6.289061,
    longitude: -10.758722,
    altitude: 7.62
  },
  {
    iataCode: 'MLY',
    icaoCode: 'PAML',
    name: 'Manley Hot Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Manley Hot Springs Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.9975,
    longitude: -150.644167,
    altitude: 82.296
  },
  {
    iataCode: 'MMB',
    icaoCode: 'RJCM',
    name: 'Memanbetsu',
    country: {code: 'JP', name: 'Japan'},
    city: 'Memanbetsu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 43.880606,
    longitude: 144.164053,
    altitude: 41.148
  },
  {
    iataCode: 'MME',
    icaoCode: 'EGNV',
    name: 'Teesside',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Durham Tees Valley Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.509189,
    longitude: -1.429406,
    altitude: 36.576
  },
  {
    iataCode: 'MMD',
    icaoCode: 'ROMD',
    name: 'Minami Daito',
    country: {code: 'JP', name: 'Japan'},
    city: 'Minami Daito',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 25.846533,
    longitude: 131.263494,
    altitude: 50.9016
  },
  {
    iataCode: 'MMG',
    icaoCode: 'YMOG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mount Magnet Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -28.1161,
    longitude: 117.842,
    altitude: 412.6992
  },
  {
    iataCode: 'MMH',
    icaoCode: 'KMMH',
    name: 'Mammoth Lakes',
    country: {code: 'US', name: 'United States'},
    city: 'Mammoth Yosemite Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.624049,
    longitude: -118.837772,
    altitude: 2172.6144
  },
  {
    iataCode: 'MMK',
    icaoCode: 'ULMM',
    name: 'Murmansk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Murmansk',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 68.781672,
    longitude: 32.750822,
    altitude: 81.0768
  },
  {
    iataCode: 'MMJ',
    icaoCode: 'RJAF',
    name: 'Matsumoto',
    country: {code: 'JP', name: 'Japan'},
    city: 'Matsumoto',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 36.166758,
    longitude: 137.922669,
    altitude: 665.0736
  },
  {
    iataCode: 'MMO',
    icaoCode: 'GVMA',
    name: 'Maio',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Maio',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 15.155928,
    longitude: -23.213703,
    altitude: 10.9728
  },
  {
    iataCode: 'MMU',
    icaoCode: 'KMMU',
    name: 'Morristown',
    country: {code: 'US', name: 'United States'},
    city: 'Morristown Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 40.79935,
    longitude: -74.414875,
    altitude: 56.9976
  },
  {
    iataCode: 'MMV',
    icaoCode: 'KMMV',
    name: 'Mackminnville',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Minnville Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 45.194444,
    longitude: -123.135944,
    altitude: 49.6824
  },
  {
    iataCode: 'MMY',
    icaoCode: 'ROMY',
    name: 'Miyako',
    country: {code: 'JP', name: 'Japan'},
    city: 'Miyako',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 24.782833,
    longitude: 125.295111,
    altitude: 45.72
  },
  {
    iataCode: 'MMX',
    icaoCode: 'ESMS',
    name: 'Malmoe',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Sturup',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 55.530193,
    longitude: 13.371639,
    altitude: 71.9328
  },
  {
    iataCode: 'MMZ',
    icaoCode: 'OAMN',
    name: 'Maimama',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Maimana',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 35.930789,
    longitude: 64.760917,
    altitude: 836.0664
  },
  {
    iataCode: 'MNB',
    icaoCode: 'FZAG',
    name: 'Muanda',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Muanda',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -5.930858,
    longitude: 12.351789,
    altitude: 27.1272
  },
  {
    iataCode: 'MNC',
    icaoCode: 'FQNC',
    name: 'Nacala',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Nacala',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -14.488233,
    longitude: 40.71225,
    altitude: 124.968
  },
  {
    iataCode: 'MNF',
    icaoCode: 'NFMA',
    name: 'Mana Island',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Mana Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.6731,
    longitude: 177.098,
    altitude: 0.0
  },
  {
    iataCode: 'MNG',
    icaoCode: 'YMGD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Maningrida Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.0561,
    longitude: 134.234,
    altitude: 37.4904
  },
  {
    iataCode: 'MNJ',
    icaoCode: 'FMSM',
    name: 'Mananjary',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Mananjary',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -21.201772,
    longitude: 48.358317,
    altitude: 6.096
  },
  {
    iataCode: 'MNI',
    icaoCode: 'TRPG',
    name: 'Geralds',
    country: {code: 'MS', name: 'Montserrat'},
    city: 'John A. Osborne Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montserrat',
    utcOffset: 60,
    latitude: 16.791389,
    longitude: -62.193333,
    altitude: 12.192
  },
  {
    iataCode: 'MNL',
    icaoCode: 'RPLL',
    name: 'Manila',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Ninoy Aquino Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 14.508647,
    longitude: 121.019581,
    altitude: 22.86
  },
  {
    iataCode: 'MNK',
    icaoCode: 'NGMA',
    name: 'Maiana',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Maiana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: 0.933333,
    longitude: 173.0,
    altitude: 3.048
  },
  {
    iataCode: 'MNM',
    icaoCode: 'KMNM',
    name: 'Macon',
    country: {code: 'US', name: 'United States'},
    city: 'Menominee Marinette Twin Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Menominee',
    utcOffset: -300,
    latitude: 45.12665,
    longitude: -87.638443,
    altitude: 190.5
  },
  {
    iataCode: 'MNU',
    icaoCode: 'VYMM',
    name: 'Mawlamyine',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Mawlamyine Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 16.444747,
    longitude: 97.660669,
    altitude: 15.8496
  },
  {
    iataCode: 'MNY',
    icaoCode: 'AGGO',
    name: 'Stirling Island',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Mono Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 600,
    latitude: -7.41694,
    longitude: 155.565,
    altitude: 0.0
  },
  {
    iataCode: 'MOA',
    icaoCode: 'MUMO',
    name: 'Moa',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Orestes Acosta',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.654114,
    longitude: -74.922114,
    altitude: 4.8768
  },
  {
    iataCode: 'MOC',
    icaoCode: 'SBMK',
    name: 'Montes Claros',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Mario Ribeiro',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -16.706925,
    longitude: -43.8189,
    altitude: 667.8168
  },
  {
    iataCode: 'MOB',
    icaoCode: 'KMOB',
    name: 'Mobile',
    country: {code: 'US', name: 'United States'},
    city: 'Mobile Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.691231,
    longitude: -88.242814,
    altitude: 66.7512
  },
  {
    iataCode: 'MOD',
    icaoCode: 'KMOD',
    name: 'Modesto',
    country: {code: 'US', name: 'United States'},
    city: 'Modesto City Co Harry Sham',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.625817,
    longitude: -120.954422,
    altitude: 29.5656
  },
  {
    iataCode: 'MOG',
    icaoCode: 'VYMS',
    name: 'Mong Hsat',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Mong Hsat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 20.516758,
    longitude: 99.256825,
    altitude: 571.5
  },
  {
    iataCode: 'MOF',
    icaoCode: 'WATC',
    name: 'Maumere',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Wai Oti',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.640647,
    longitude: 122.236889,
    altitude: 35.052
  },
  {
    iataCode: 'MOI',
    icaoCode: 'NCMR',
    name: 'Mitiaro Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Mitiaro Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -19.8425,
    longitude: -157.703,
    altitude: 7.62
  },
  {
    iataCode: 'MOH',
    icaoCode: 'VEMN',
    name: 'Mohanbari',
    country: {code: 'IN', name: 'India'},
    city: 'Dibrugarh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 27.483853,
    longitude: 95.016922,
    altitude: 110.3376
  },
  {
    iataCode: 'MOL',
    icaoCode: 'ENML',
    name: 'Molde',
    country: {code: 'NO', name: 'Norway'},
    city: 'Aro',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 62.744722,
    longitude: 7.2625,
    altitude: 3.048
  },
  {
    iataCode: 'MON',
    icaoCode: 'NZGT',
    name: 'Glentanner',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Glentanner',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -43.906666,
    longitude: 170.128333,
    altitude: 555.9552
  },
  {
    iataCode: 'MOQ',
    icaoCode: 'FMMV',
    name: 'Morondava',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Morondava',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -20.28475,
    longitude: 44.317614,
    altitude: 9.144
  },
  {
    iataCode: 'MOU',
    icaoCode: 'PAMO',
    name: 'Mountain Village',
    country: {code: 'US', name: 'United States'},
    city: 'Mountain Village Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 62.0954,
    longitude: -163.682,
    altitude: 102.7176
  },
  {
    iataCode: 'MOT',
    icaoCode: 'KMOT',
    name: 'Minot',
    country: {code: 'US', name: 'United States'},
    city: 'Minot Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.259378,
    longitude: -101.280333,
    altitude: 523.0368
  },
  {
    iataCode: 'MOV',
    icaoCode: 'YMRB',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Moranbah Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -22.0578,
    longitude: 148.077,
    altitude: 234.696
  },
  {
    iataCode: 'VAA',
    icaoCode: 'EFVA',
    name: 'Vaasa',
    country: {code: 'FI', name: 'Finland'},
    city: 'Vaasa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 63.05065,
    longitude: 21.762175,
    altitude: 5.7912
  },
  {
    iataCode: 'VAD',
    icaoCode: 'KVAD',
    name: 'Valdosta',
    country: {code: 'US', name: 'United States'},
    city: 'Moody Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.967833,
    longitude: -83.193,
    altitude: 71.0184
  },
  {
    iataCode: 'MOZ',
    icaoCode: 'NTTM',
    name: 'Moorea',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Moorea',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -17.489972,
    longitude: -149.761869,
    altitude: 2.7432
  },
  {
    iataCode: 'VAF',
    icaoCode: 'LFLU',
    name: 'Valence',
    country: {code: 'FR', name: 'France'},
    city: 'Chabeuil',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.921594,
    longitude: 4.9699,
    altitude: 160.02
  },
  {
    iataCode: 'VAG',
    icaoCode: 'SBVG',
    name: 'Varginha',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Major Brigadeiro Trompowsky',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.590067,
    longitude: -45.473342,
    altitude: 922.9344
  },
  {
    iataCode: 'MPA',
    icaoCode: 'FYKM',
    name: 'Mpacha',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Katima Mulilo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -17.6344,
    longitude: 24.1767,
    altitude: 958.2912
  },
  {
    iataCode: 'VAI',
    icaoCode: 'AYVN',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Vanimo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -2.69717,
    longitude: 141.302,
    altitude: 3.048
  },
  {
    iataCode: 'VAL',
    icaoCode: 'SNVB',
    name: 'Valenca',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Valenca Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: 180,
    latitude: -13.2965,
    longitude: -38.9924,
    altitude: 6.4008
  },
  {
    iataCode: 'VAK',
    icaoCode: 'PAVA',
    name: 'Chevak',
    country: {code: 'US', name: 'United States'},
    city: 'Chevak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 61.5338,
    longitude: -165.584,
    altitude: 22.86
  },
  {
    iataCode: 'VAN',
    icaoCode: 'LTCI',
    name: 'Van',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Van',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.468219,
    longitude: 43.3323,
    altitude: 1670.304
  },
  {
    iataCode: 'MPH',
    icaoCode: 'RPXE',
    name: 'Caticlan',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Godofredo P',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.9215,
    longitude: 121.953,
    altitude: 6.096
  },
  {
    iataCode: 'VAO',
    icaoCode: 'AGGV',
    name: 'Suavanao',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Suavanao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -7.58556,
    longitude: 158.731,
    altitude: 0.0
  },
  {
    iataCode: 'VAR',
    icaoCode: 'LBWN',
    name: 'Varna',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Varna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 43.232072,
    longitude: 27.825106,
    altitude: 70.104
  },
  {
    iataCode: 'MPI',
    icaoCode: 'KMPI',
    name: 'Mariposa',
    country: {code: 'US', name: 'United States'},
    city: 'Mariposayosemite',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.3039,
    longitude: -120.0222,
    altitude: 747.9792
  },
  {
    iataCode: 'MPL',
    icaoCode: 'LFMT',
    name: 'Montpellier',
    country: {code: 'FR', name: 'France'},
    city: 'Mediterranee',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.576194,
    longitude: 3.963014,
    altitude: 5.1816
  },
  {
    iataCode: 'MPK',
    icaoCode: 'RKJM',
    name: 'Mokpo',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Mokpo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 34.758906,
    longitude: 126.379872,
    altitude: 7.0104
  },
  {
    iataCode: 'VAS',
    icaoCode: 'LTAR',
    name: 'Sivas',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Sivas',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.813828,
    longitude: 36.903497,
    altitude: 1595.9328
  },
  {
    iataCode: 'MPN',
    icaoCode: 'EGYP',
    name: 'Mount Pleasant',
    country: {code: 'FK', name: 'Falkland Islands (Malvinas)'},
    city: 'Mount Pleasant',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Stanley',
    utcOffset: -240,
    latitude: -51.822777,
    longitude: -58.447222,
    altitude: 74.3712
  },
  {
    iataCode: 'VAV',
    icaoCode: 'NFTV',
    name: "Vava'u",
    country: {code: 'TO', name: 'Tonga'},
    city: 'Vavau Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tongatapu',
    utcOffset: 780,
    latitude: -18.585336,
    longitude: -173.961717,
    altitude: 71.9328
  },
  {
    iataCode: 'MPM',
    icaoCode: 'FQMA',
    name: 'Maputo',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Maputo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -25.920836,
    longitude: 32.572606,
    altitude: 44.196
  },
  {
    iataCode: 'VAW',
    icaoCode: 'ENSS',
    name: 'Vardø',
    country: {code: 'NO', name: 'Norway'},
    city: 'Svartnes Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.355392,
    longitude: 31.044889,
    altitude: 12.8016
  },
  {
    iataCode: 'MPV',
    icaoCode: 'KMPV',
    name: 'Montpelier',
    country: {code: 'US', name: 'United States'},
    city: 'Edward F Knapp State',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.203503,
    longitude: -72.562328,
    altitude: 355.092
  },
  {
    iataCode: 'MPW',
    icaoCode: 'UKCM',
    name: '',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Mariupol International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 47.0761,
    longitude: 37.4496,
    altitude: 76.5048
  },
  {
    iataCode: 'VBG',
    icaoCode: 'KVBG',
    name: 'Lompoc',
    country: {code: 'US', name: 'United States'},
    city: 'Vandenberg Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.737333,
    longitude: -120.584306,
    altitude: 112.4712
  },
  {
    iataCode: 'MQF',
    icaoCode: 'USCM',
    name: 'Magnetiogorsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Magnitogorsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 53.393131,
    longitude: 58.755661,
    altitude: 436.1688
  },
  {
    iataCode: 'EAA',
    icaoCode: 'PAEG',
    name: 'Eagle',
    country: {code: 'US', name: 'United States'},
    city: 'Eagle Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.778056,
    longitude: -141.149722,
    altitude: 276.7584
  },
  {
    iataCode: 'MQH',
    icaoCode: 'SBMC',
    name: 'Minacu',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Minacu Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -13.526944,
    longitude: -48.220556,
    altitude: 320.9544
  },
  {
    iataCode: 'VBS',
    icaoCode: 'LIPO',
    name: 'Montichiari',
    country: {code: 'IT', name: 'Italy'},
    city: 'Montichiari',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.428889,
    longitude: 10.330556,
    altitude: 108.5088
  },
  {
    iataCode: 'EAE',
    icaoCode: 'NVSE',
    name: 'Sangafa',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Sangafa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -17.0903,
    longitude: 168.343,
    altitude: 2.1336
  },
  {
    iataCode: 'MQM',
    icaoCode: 'LTCR',
    name: '',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Mardin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.2233,
    longitude: 40.6317,
    altitude: 526.9992
  },
  {
    iataCode: 'MQL',
    icaoCode: 'YMIA',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mildura Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -34.2292,
    longitude: 142.086,
    altitude: 50.9016
  },
  {
    iataCode: 'MQN',
    icaoCode: 'ENRA',
    name: 'Mo I Rana',
    country: {code: 'NO', name: 'Norway'},
    city: 'Røssvoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 66.3639,
    longitude: 14.3014,
    altitude: 69.7992
  },
  {
    iataCode: 'VBV',
    icaoCode: 'NFVB',
    name: 'Vanua Balavu',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Vanua Balavu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.269,
    longitude: -178.976,
    altitude: 23.1648
  },
  {
    iataCode: 'MQQ',
    icaoCode: 'FTTD',
    name: 'Moundou',
    country: {code: 'TD', name: 'Chad'},
    city: 'Moundou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 8.624406,
    longitude: 16.071419,
    altitude: 428.8536
  },
  {
    iataCode: 'VBY',
    icaoCode: 'ESSV',
    name: 'Visby',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Visby',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.662797,
    longitude: 18.346211,
    altitude: 49.9872
  },
  {
    iataCode: 'MQP',
    icaoCode: 'FAKN',
    name: 'Mpumalanga',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Kruger Mpumalanga International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.3832,
    longitude: 31.1056,
    altitude: 862.2792
  },
  {
    iataCode: 'MQS',
    icaoCode: 'TVSM',
    name: 'Mustique',
    country: {code: 'VC', name: 'Saint Vincent and the Grenadines'},
    city: 'Mustique',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Vincent',
    utcOffset: -240,
    latitude: 12.887947,
    longitude: -61.180161,
    altitude: 2.4384
  },
  {
    iataCode: 'EAM',
    icaoCode: 'OENG',
    name: 'Nejran',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Nejran',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 17.611436,
    longitude: 44.419169,
    altitude: 1213.7136
  },
  {
    iataCode: 'MQT',
    icaoCode: 'KMQT',
    name: 'Marquette',
    country: {code: 'US', name: 'United States'},
    city: 'Sawyer International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 46.353611,
    longitude: -87.395278,
    altitude: 372.1608
  },
  {
    iataCode: 'MQX',
    icaoCode: 'HAMK',
    name: 'Makale',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Makale',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 13.467367,
    longitude: 39.533464,
    altitude: 2257.3488
  },
  {
    iataCode: 'VCA',
    icaoCode: 'VVCT',
    name: 'Can Tho',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Trà Nóc Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 10.085119,
    longitude: 105.711922,
    altitude: 2.7432
  },
  {
    iataCode: 'EAS',
    icaoCode: 'LESO',
    name: 'San Sebastian',
    country: {code: 'ES', name: 'Spain'},
    city: 'San Sebastian',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 43.356519,
    longitude: -1.790611,
    altitude: 4.572
  },
  {
    iataCode: 'VCD',
    icaoCode: 'YVRD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Victoria River Downs Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -16.4033,
    longitude: 131.002,
    altitude: 27.1272
  },
  {
    iataCode: 'EAR',
    icaoCode: 'EAR',
    name: 'Kearney',
    country: {code: 'US', name: 'United States'},
    city: 'Kearney Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.727,
    longitude: -99.0068,
    altitude: 649.5288
  },
  {
    iataCode: 'EAU',
    icaoCode: 'KEAU',
    name: 'Eau Claire',
    country: {code: 'US', name: 'United States'},
    city: 'Chippewa Valley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.8658,
    longitude: -91.4843,
    altitude: 278.2824
  },
  {
    iataCode: 'EAT',
    icaoCode: 'KEAT',
    name: 'Wenatchee',
    country: {code: 'US', name: 'United States'},
    city: 'Pangborn Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.398,
    longitude: -120.206,
    altitude: 380.6952
  },
  {
    iataCode: 'VCE',
    icaoCode: 'LIPZ',
    name: 'Venice',
    country: {code: 'IT', name: 'Italy'},
    city: 'Venezia Tessera',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.505278,
    longitude: 12.351944,
    altitude: 2.1336
  },
  {
    iataCode: 'MRB',
    icaoCode: 'KMRB',
    name: 'Martinsburg',
    country: {code: 'US', name: 'United States'},
    city: 'Eastern Wv Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.2407,
    longitude: -77.591,
    altitude: 168.8592
  },
  {
    iataCode: 'MRD',
    icaoCode: 'SVMD',
    name: 'Merida',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Alberto Carnevalli',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.582294,
    longitude: -71.161186,
    altitude: 1526.1336
  },
  {
    iataCode: 'VCL',
    icaoCode: 'VVCA',
    name: 'Chu Lai',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Chu Lai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 15.405944,
    longitude: 108.705889,
    altitude: 3.048
  },
  {
    iataCode: 'VCP',
    icaoCode: 'SBKP',
    name: 'Campinas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Viracopos',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.0074,
    longitude: -47.1345,
    altitude: 661.416
  },
  {
    iataCode: 'EBB',
    icaoCode: 'HUEN',
    name: 'Entebbe',
    country: {code: 'UG', name: 'Uganda'},
    city: 'Entebbe Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kampala',
    utcOffset: 180,
    latitude: 0.042386,
    longitude: 32.443503,
    altitude: 1152.7536
  },
  {
    iataCode: 'EBA',
    icaoCode: 'LIRJ',
    name: 'Marina Di Campo',
    country: {code: 'IT', name: 'Italy'},
    city: 'Marina Di Campo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 42.760277,
    longitude: 10.239445,
    altitude: 9.4488
  },
  {
    iataCode: 'MRI',
    icaoCode: 'PAMR',
    name: 'Anchorage',
    country: {code: 'US', name: 'United States'},
    city: 'Merrill Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.213544,
    longitude: -149.844447,
    altitude: 41.7576
  },
  {
    iataCode: 'EBD',
    icaoCode: 'HSOB',
    name: 'El Obeid',
    country: {code: 'SD', name: 'Sudan'},
    city: 'El Obeid',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 13.153219,
    longitude: 30.232675,
    altitude: 587.3496
  },
  {
    iataCode: 'VCT',
    icaoCode: 'KVCT',
    name: 'Victoria',
    country: {code: 'US', name: 'United States'},
    city: 'Victoria Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 28.8526,
    longitude: -96.9185,
    altitude: 35.052
  },
  {
    iataCode: 'VCS',
    icaoCode: 'VVCS',
    name: 'Conson',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Co Ong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 8.731831,
    longitude: 106.632589,
    altitude: 6.096
  },
  {
    iataCode: 'VCV',
    icaoCode: 'KVCV',
    name: 'Victorville',
    country: {code: 'US', name: 'United States'},
    city: 'Southern California Logistics',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.597453,
    longitude: -117.382997,
    altitude: 879.348
  },
  {
    iataCode: 'EBG',
    icaoCode: 'SKEB',
    name: 'El Bagre',
    country: {code: 'CO', name: 'Colombia'},
    city: 'El Bagre Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 7.596389,
    longitude: -74.808889,
    altitude: 54.864
  },
  {
    iataCode: 'MRO',
    icaoCode: 'NZMS',
    name: 'Masterton',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Masterton',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -40.973333,
    longitude: 175.633611,
    altitude: 110.9472
  },
  {
    iataCode: 'EBJ',
    icaoCode: 'EKEB',
    name: 'Esbjerg',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Esbjerg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.525942,
    longitude: 8.553403,
    altitude: 29.5656
  },
  {
    iataCode: 'MRQ',
    icaoCode: 'RPUW',
    name: 'Gasan',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Marinduque Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 13.360967,
    longitude: 121.825583,
    altitude: 9.7536
  },
  {
    iataCode: 'EBL',
    icaoCode: 'ORER',
    name: 'Erbil',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Erbil Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 36.237611,
    longitude: 43.963158,
    altitude: 408.7368
  },
  {
    iataCode: 'MRS',
    icaoCode: 'LFML',
    name: 'Marseille',
    country: {code: 'FR', name: 'France'},
    city: 'Provence',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.435555,
    longitude: 5.213611,
    altitude: 22.5552
  },
  {
    iataCode: 'MRV',
    icaoCode: 'URMM',
    name: 'Mineralnye Vody',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Mineralnyye Vody',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 44.225072,
    longitude: 43.081889,
    altitude: 321.2592
  },
  {
    iataCode: 'EBM',
    icaoCode: 'DTTR',
    name: 'El Borma',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'El Borma',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 31.704281,
    longitude: 9.254619,
    altitude: 252.0696
  },
  {
    iataCode: 'MRU',
    icaoCode: 'FIMP',
    name: 'Plaisance',
    country: {code: 'MU', name: 'Mauritius'},
    city: 'Sir Seewoosagur Ramgoolam Intl',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mauritius',
    utcOffset: 300,
    latitude: -20.430235,
    longitude: 57.6836,
    altitude: 56.6928
  },
  {
    iataCode: 'MRX',
    icaoCode: 'OIAM',
    name: 'Bandar Mahshahr',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Mahshahr',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 30.556192,
    longitude: 49.151879,
    altitude: 2.4384
  },
  {
    iataCode: 'VDA',
    icaoCode: 'LLOV',
    name: 'Ovda',
    country: {code: 'IL', name: 'Israel'},
    city: 'Ovda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 29.94025,
    longitude: 34.93585,
    altitude: 454.7616
  },
  {
    iataCode: 'MRZ',
    icaoCode: 'YMOR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Moree Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -29.4989,
    longitude: 149.845,
    altitude: 213.6648
  },
  {
    iataCode: 'VDC',
    icaoCode: 'SBQV',
    name: 'Vitória Da Conquista',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Vitória Da Conquista Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -14.862761,
    longitude: -40.863106,
    altitude: 913.7904
  },
  {
    iataCode: 'MRY',
    icaoCode: 'KMRY',
    name: 'Monterey',
    country: {code: 'US', name: 'United States'},
    city: 'Monterey Peninsula',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.587,
    longitude: -121.842944,
    altitude: 78.3336
  },
  {
    iataCode: 'VDB',
    icaoCode: 'ENFG',
    name: 'Fagernes',
    country: {code: 'NO', name: 'Norway'},
    city: 'Leirin',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 61.015556,
    longitude: 9.288056,
    altitude: 822.0456
  },
  {
    iataCode: 'VDE',
    icaoCode: 'GCHI',
    name: 'Hierro',
    country: {code: 'ES', name: 'Spain'},
    city: 'Hierro',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 27.814847,
    longitude: -17.887056,
    altitude: 31.3944
  },
  {
    iataCode: 'EBU',
    icaoCode: 'LFMH',
    name: 'St.-etienne',
    country: {code: 'FR', name: 'France'},
    city: 'Boutheon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.540554,
    longitude: 4.296389,
    altitude: 403.86
  },
  {
    iataCode: 'MSA',
    icaoCode: 'CZMD',
    name: 'Muskrat Dam',
    country: {code: 'CA', name: 'Canada'},
    city: 'Muskrat Dam Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.4414,
    longitude: -91.7628,
    altitude: 277.6728
  },
  {
    iataCode: 'MSE',
    icaoCode: 'EGMH',
    name: 'Manston',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Manston',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.342222,
    longitude: 1.346111,
    altitude: 54.2544
  },
  {
    iataCode: 'VDM',
    icaoCode: 'SAVV',
    name: 'Viedma',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Gobernador Castello',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -40.869222,
    longitude: -63.000389,
    altitude: 6.096
  },
  {
    iataCode: 'MSH',
    icaoCode: 'OOMA',
    name: 'Masirah',
    country: {code: 'OM', name: 'Oman'},
    city: 'Masirah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Muscat',
    utcOffset: 240,
    latitude: 20.675434,
    longitude: 58.890467,
    altitude: 19.5072
  },
  {
    iataCode: 'VDP',
    icaoCode: 'SVVP',
    name: 'Valle De La Pascua',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Valle De La Pascua',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.222028,
    longitude: -65.993583,
    altitude: 124.968
  },
  {
    iataCode: 'VDS',
    icaoCode: 'ENVD',
    name: 'Vadso',
    country: {code: 'NO', name: 'Norway'},
    city: 'Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.065,
    longitude: 29.844,
    altitude: 38.7096
  },
  {
    iataCode: 'MSJ',
    icaoCode: 'RJSM',
    name: 'Misawa',
    country: {code: 'JP', name: 'Japan'},
    city: 'Misawa Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 40.703222,
    longitude: 141.368364,
    altitude: 36.2712
  },
  {
    iataCode: 'VDR',
    icaoCode: 'SAOD',
    name: 'Villa Dolores',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Villa Dolores',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -31.945183,
    longitude: -65.146283,
    altitude: 583.692
  },
  {
    iataCode: 'MSL',
    icaoCode: 'KMSL',
    name: 'Muscle Shoals',
    country: {code: 'US', name: 'United States'},
    city: 'Northwest Alabama Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.7453,
    longitude: -87.6102,
    altitude: 167.64
  },
  {
    iataCode: 'ECG',
    icaoCode: 'KECG',
    name: 'Elizabeth City',
    country: {code: 'US', name: 'United States'},
    city: 'Elizabeth City Cgas Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.260581,
    longitude: -76.174572,
    altitude: 3.6576
  },
  {
    iataCode: 'MSO',
    icaoCode: 'KMSO',
    name: 'Missoula',
    country: {code: 'US', name: 'United States'},
    city: 'Missoula Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 46.916306,
    longitude: -114.090556,
    altitude: 976.884
  },
  {
    iataCode: 'MSN',
    icaoCode: 'KMSN',
    name: 'Madison',
    country: {code: 'US', name: 'United States'},
    city: 'Dane Co Rgnl Truax Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.139858,
    longitude: -89.337514,
    altitude: 270.3576
  },
  {
    iataCode: 'MSQ',
    icaoCode: 'UMMS',
    name: 'Minsk 2',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Minsk 2',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 53.882469,
    longitude: 28.030731,
    altitude: 204.216
  },
  {
    iataCode: 'MSP',
    icaoCode: 'KMSP',
    name: 'Minneapolis',
    country: {code: 'US', name: 'United States'},
    city: 'Minneapolis St Paul Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.881956,
    longitude: -93.221767,
    altitude: 256.3368
  },
  {
    iataCode: 'MSS',
    icaoCode: 'KMSS',
    name: 'Massena',
    country: {code: 'US', name: 'United States'},
    city: 'Massena Intl Richards Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.935833,
    longitude: -74.845547,
    altitude: 65.532
  },
  {
    iataCode: 'MSR',
    icaoCode: 'LTCK',
    name: 'Mus',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Mus Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.747769,
    longitude: 41.661236,
    altitude: 1267.0536
  },
  {
    iataCode: 'VDZ',
    icaoCode: 'PAVD',
    name: 'Valdez',
    country: {code: 'US', name: 'United States'},
    city: 'Valdez Pioneer Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.133949,
    longitude: -146.248342,
    altitude: 35.9664
  },
  {
    iataCode: 'MSU',
    icaoCode: 'FXMM',
    name: 'Maseru',
    country: {code: 'LS', name: 'Lesotho'},
    city: 'Moshoeshoe I Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maseru',
    utcOffset: 120,
    latitude: -29.462256,
    longitude: 27.552503,
    altitude: 1630.0704
  },
  {
    iataCode: 'MST',
    icaoCode: 'EHBK',
    name: 'Maastricht',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Maastricht',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 50.911658,
    longitude: 5.770144,
    altitude: 114.3
  },
  {
    iataCode: 'MSW',
    icaoCode: 'HHMS',
    name: 'Massawa',
    country: {code: 'ER', name: 'Eritrea'},
    city: 'Massawa Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Asmara',
    utcOffset: 180,
    latitude: 15.669989,
    longitude: 39.370103,
    altitude: 59.1312
  },
  {
    iataCode: 'ECN',
    icaoCode: 'LCEN',
    name: 'Nicosia',
    country: {code: 'CY', name: 'Cyprus'},
    city: 'Ercan International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Nicosia',
    utcOffset: 120,
    latitude: 35.1547,
    longitude: 33.4961,
    altitude: 123.1392
  },
  {
    iataCode: 'MSY',
    icaoCode: 'KMSY',
    name: 'New Orleans',
    country: {code: 'US', name: 'United States'},
    city: 'Louis Armstrong New Orleans Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.993389,
    longitude: -90.258028,
    altitude: 1.2192
  },
  {
    iataCode: 'ECP',
    icaoCode: 'KECP',
    name: 'Panama City',
    country: {code: 'US', name: 'United States'},
    city: "Nw Florida Beaches Int\\\\'l",
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.3417,
    longitude: -85.7973,
    altitude: 21.0312
  },
  {
    iataCode: 'MSZ',
    icaoCode: 'FNMO',
    name: 'Mocamedes',
    country: {code: 'AO', name: 'Angola'},
    city: 'Namibe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -15.261222,
    longitude: 12.146756,
    altitude: 64.008
  },
  {
    iataCode: 'VEE',
    icaoCode: 'PAVE',
    name: 'Venetie',
    country: {code: 'US', name: 'United States'},
    city: 'Venetie Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 67.008611,
    longitude: -146.366389,
    altitude: 174.9552
  },
  {
    iataCode: 'VEL',
    icaoCode: 'KVEL',
    name: 'Vernal',
    country: {code: 'US', name: 'United States'},
    city: 'Vernal Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 40.440833,
    longitude: -109.51,
    altitude: 1608.7344
  },
  {
    iataCode: 'MTC',
    icaoCode: 'KMTC',
    name: 'Mount Clemens',
    country: {code: 'US', name: 'United States'},
    city: 'Selfridge Angb',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.608333,
    longitude: -82.8355,
    altitude: 176.784
  },
  {
    iataCode: 'MTF',
    icaoCode: 'HAMT',
    name: 'Mizan Teferi',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Mizan Teferi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 6.967,
    longitude: 35.533,
    altitude: 1339.9008
  },
  {
    iataCode: 'MTH',
    icaoCode: 'KMTH',
    name: 'Marathon',
    country: {code: 'US', name: 'United States'},
    city: 'Florida Keys Marathon Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 24.726111,
    longitude: -81.051389,
    altitude: 2.1336
  },
  {
    iataCode: 'MTJ',
    icaoCode: 'KMTJ',
    name: 'Montrose Co',
    country: {code: 'US', name: 'United States'},
    city: 'Montrose Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.509794,
    longitude: -107.894242,
    altitude: 1755.3432
  },
  {
    iataCode: 'VER',
    icaoCode: 'MMVR',
    name: 'Vera Cruz',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Heriberto Jara Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.145931,
    longitude: -96.187267,
    altitude: 27.432
  },
  {
    iataCode: 'MTL',
    icaoCode: 'YMND',
    name: 'Maitland',
    country: {code: 'AU', name: 'Australia'},
    city: 'Maitland Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.7033,
    longitude: 151.488,
    altitude: 22.86
  },
  {
    iataCode: 'MTK',
    icaoCode: 'NGMN',
    name: 'Makin',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Makin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: 3.383333,
    longitude: 173.0,
    altitude: 3.048
  },
  {
    iataCode: 'EDF',
    icaoCode: 'PAED',
    name: 'Anchorage',
    country: {code: 'US', name: 'United States'},
    city: 'Elmendorf Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.250986,
    longitude: -149.806503,
    altitude: 64.6176
  },
  {
    iataCode: 'MTM',
    icaoCode: 'PAMM',
    name: 'Metakatla',
    country: {code: 'US', name: 'United States'},
    city: 'Metlakatla Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Metlakatla',
    utcOffset: -540,
    latitude: 55.131111,
    longitude: -131.578056,
    altitude: 0.0
  },
  {
    iataCode: 'MTR',
    icaoCode: 'SKMR',
    name: 'Monteria',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Los Garzones',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 8.823744,
    longitude: -75.825831,
    altitude: 10.9728
  },
  {
    iataCode: 'EDI',
    icaoCode: 'EGPH',
    name: 'Edinburgh',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Edinburgh',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.95,
    longitude: -3.3725,
    altitude: 41.148
  },
  {
    iataCode: 'VEY',
    icaoCode: 'BIVM',
    name: 'Vestmannaeyjar',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Vestmannaeyjar',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 63.424303,
    longitude: -20.278875,
    altitude: 99.3648
  },
  {
    iataCode: 'EDL',
    icaoCode: 'HKEL',
    name: 'Eldoret',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Eldoret Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 0.404458,
    longitude: 35.238928,
    altitude: 2115.6168
  },
  {
    iataCode: 'MTT',
    icaoCode: 'MMMT',
    name: 'Minatitlan',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Minatitlan',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 18.103419,
    longitude: -94.580681,
    altitude: 10.9728
  },
  {
    iataCode: 'MTS',
    icaoCode: 'FDMS',
    name: 'Manzini',
    country: {code: 'SZ', name: 'Swaziland'},
    city: 'Matsapha',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mbabane',
    utcOffset: 120,
    latitude: -26.529022,
    longitude: 31.307519,
    altitude: 632.46
  },
  {
    iataCode: 'MTV',
    icaoCode: 'NVSA',
    name: 'Ablow',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Mota Lava Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -13.666,
    longitude: 167.712,
    altitude: 19.2024
  },
  {
    iataCode: 'EDM',
    icaoCode: 'LFRI',
    name: 'La Roche-sur-yon',
    country: {code: 'FR', name: 'France'},
    city: 'Les Ajoncs',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.701944,
    longitude: -1.378625,
    altitude: 91.1352
  },
  {
    iataCode: 'VFA',
    icaoCode: 'FVFA',
    name: 'Victoria Falls',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Victoria Falls Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -18.095881,
    longitude: 25.839006,
    altitude: 1063.752
  },
  {
    iataCode: 'EDO',
    icaoCode: 'LTFD',
    name: '',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Balikesir Korfez Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.5546,
    longitude: 27.0138,
    altitude: 15.24
  },
  {
    iataCode: 'EDR',
    icaoCode: 'YPMP',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Pormpuraaw Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -14.8967,
    longitude: 141.609,
    altitude: 3.048
  },
  {
    iataCode: 'MTY',
    icaoCode: 'MMMY',
    name: 'Monterrey',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Mariano Escobedo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 25.778489,
    longitude: -100.106878,
    altitude: 389.5344
  },
  {
    iataCode: 'MUA',
    icaoCode: 'AGGM',
    name: '',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Munda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.32797,
    longitude: 157.263,
    altitude: 3.048
  },
  {
    iataCode: 'EDW',
    icaoCode: 'KEDW',
    name: 'Edwards Afb',
    country: {code: 'US', name: 'United States'},
    city: 'Edwards Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.905417,
    longitude: -117.883739,
    altitude: 701.6496
  },
  {
    iataCode: 'MUC',
    icaoCode: 'EDDM',
    name: 'Munich',
    country: {code: 'DE', name: 'Germany'},
    city: 'Franz Josef Strauss',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.353783,
    longitude: 11.786086,
    altitude: 453.2376
  },
  {
    iataCode: 'MUB',
    icaoCode: 'FBMN',
    name: 'Maun',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Maun',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -19.972564,
    longitude: 23.431086,
    altitude: 942.7464
  },
  {
    iataCode: 'MUE',
    icaoCode: 'PHMU',
    name: 'Kamuela',
    country: {code: 'US', name: 'United States'},
    city: 'Waimea Kohala',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.001328,
    longitude: -155.668108,
    altitude: 814.1208
  },
  {
    iataCode: 'MUI',
    icaoCode: 'KMUI',
    name: 'Muir',
    country: {code: 'US', name: 'United States'},
    city: 'Muir Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.434811,
    longitude: -76.569411,
    altitude: 149.0472
  },
  {
    iataCode: 'MUH',
    icaoCode: 'HEMM',
    name: 'Mersa-matruh',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Mersa Matruh',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 31.325356,
    longitude: 27.221689,
    altitude: 28.6512
  },
  {
    iataCode: 'MUK',
    icaoCode: 'NCMK',
    name: 'Mauke Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Mauke Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -20.1361,
    longitude: -157.345,
    altitude: 7.9248
  },
  {
    iataCode: 'MUO',
    icaoCode: 'KMUO',
    name: 'Mountain Home',
    country: {code: 'US', name: 'United States'},
    city: 'Mountain Home Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 43.043603,
    longitude: -115.872431,
    altitude: 913.1808
  },
  {
    iataCode: 'MUN',
    icaoCode: 'SVMT',
    name: 'Maturin',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Maturin',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.749067,
    longitude: -63.1534,
    altitude: 68.2752
  },
  {
    iataCode: 'EEK',
    icaoCode: 'PAEE',
    name: 'Eek',
    country: {code: 'US', name: 'United States'},
    city: 'Eek Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 60.213611,
    longitude: -162.043889,
    altitude: 4.572
  },
  {
    iataCode: 'MUR',
    icaoCode: 'WBGM',
    name: 'Marudi',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Marudi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 4.1775,
    longitude: 114.321944,
    altitude: 31.3944
  },
  {
    iataCode: 'MUW',
    icaoCode: 'DAOV',
    name: 'Ghriss',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Ghriss',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.207725,
    longitude: 0.147142,
    altitude: 513.8928
  },
  {
    iataCode: 'MUX',
    icaoCode: 'OPMT',
    name: 'Multan',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Multan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 30.203222,
    longitude: 71.419111,
    altitude: 122.8344
  },
  {
    iataCode: 'VGA',
    icaoCode: 'VOBZ',
    name: 'Vijayawada',
    country: {code: 'IN', name: 'India'},
    city: 'Vijayawada',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 16.530433,
    longitude: 80.796847,
    altitude: 24.9936
  },
  {
    iataCode: 'VGD',
    icaoCode: 'ULWW',
    name: 'Vologda',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Vologda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 59.281667,
    longitude: 39.946667,
    altitude: 117.9576
  },
  {
    iataCode: 'MUZ',
    icaoCode: 'HTMU',
    name: 'Musoma',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Musoma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -1.483,
    longitude: 33.8,
    altitude: 1160.0688
  },
  {
    iataCode: 'MVB',
    icaoCode: 'FOON',
    name: 'Franceville',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Mvengue',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -1.656156,
    longitude: 13.438036,
    altitude: 441.96
  },
  {
    iataCode: 'MVA',
    icaoCode: 'BIRL',
    name: 'Myvatn',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Reykjahlid Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: -60,
    latitude: 65.6558,
    longitude: -16.9181,
    altitude: 313.944
  },
  {
    iataCode: 'MVD',
    icaoCode: 'SUMU',
    name: 'Montevideo',
    country: {code: 'UY', name: 'Uruguay'},
    city: 'Carrasco Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Montevideo',
    utcOffset: -180,
    latitude: -34.838417,
    longitude: -56.030806,
    altitude: 32.004
  },
  {
    iataCode: 'MVF',
    icaoCode: 'SBMW',
    name: 'Mossoro',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Dix Sept Rosado Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Fortaleza',
    utcOffset: -180,
    latitude: -5.20192,
    longitude: -37.3643,
    altitude: 23.1648
  },
  {
    iataCode: 'VGO',
    icaoCode: 'LEVX',
    name: 'Vigo',
    country: {code: 'ES', name: 'Spain'},
    city: 'Vigo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.2318,
    longitude: -8.626775,
    altitude: 260.604
  },
  {
    iataCode: 'EFD',
    icaoCode: 'KEFD',
    name: 'Houston',
    country: {code: 'US', name: 'United States'},
    city: 'Ellington Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.607333,
    longitude: -95.15875,
    altitude: 9.7536
  },
  {
    iataCode: 'MVP',
    icaoCode: 'SKMU',
    name: 'Mitu',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Fabio Alberto Leon Bentley',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 1.253664,
    longitude: -70.233878,
    altitude: 207.264
  },
  {
    iataCode: 'MVR',
    icaoCode: 'FKKL',
    name: 'Maroua',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Salak',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 10.451392,
    longitude: 14.257361,
    altitude: 423.672
  },
  {
    iataCode: 'VGZ',
    icaoCode: 'SKVG',
    name: 'Villa Garzon',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Villa Garzon Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 0.978889,
    longitude: -76.605556,
    altitude: 380.3904
  },
  {
    iataCode: 'MVQ',
    icaoCode: 'UMOO',
    name: 'Mogilev',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Mogilev Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 53.9549,
    longitude: 30.0951,
    altitude: 194.1576
  },
  {
    iataCode: 'EFL',
    icaoCode: 'LGKF',
    name: 'Keffallinia',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kefallinia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.120069,
    longitude: 20.500481,
    altitude: 17.9832
  },
  {
    iataCode: 'MVT',
    icaoCode: 'NTGV',
    name: 'Mataiva',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Mataiva',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.868055,
    longitude: -148.717225,
    altitude: 3.3528
  },
  {
    iataCode: 'MVS',
    icaoCode: 'SNMU',
    name: 'Mucuri',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Mucuri Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -18.0489,
    longitude: -39.8642,
    altitude: 84.1248
  },
  {
    iataCode: 'MVZ',
    icaoCode: 'FVMV',
    name: 'Masvingo',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Masvingo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -20.055333,
    longitude: 30.859111,
    altitude: 1095.756
  },
  {
    iataCode: 'VHC',
    icaoCode: 'FNSA',
    name: 'Saurimo',
    country: {code: 'AO', name: 'Angola'},
    city: 'Saurimo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -9.689067,
    longitude: 20.431875,
    altitude: 1092.4032
  },
  {
    iataCode: 'MVY',
    icaoCode: 'KMVY',
    name: 'Vineyard Haven Ma',
    country: {code: 'US', name: 'United States'},
    city: "Martha\\\\'s Vineyard",
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.391667,
    longitude: -70.615278,
    altitude: 20.4216
  },
  {
    iataCode: 'MWA',
    icaoCode: 'KMWA',
    name: 'Marion',
    country: {code: 'US', name: 'United States'},
    city: 'Williamson Country Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 37.754957,
    longitude: -89.011094,
    altitude: 143.8656
  },
  {
    iataCode: 'VHM',
    icaoCode: 'ESNV',
    name: 'Vilhelmina',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Vilhelmina',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 64.579083,
    longitude: 16.833575,
    altitude: 347.472
  },
  {
    iataCode: 'MWF',
    icaoCode: 'NVSN',
    name: 'Maewo Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Naone Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.0,
    longitude: 168.083,
    altitude: 0.0
  },
  {
    iataCode: 'MWH',
    icaoCode: 'KMWH',
    name: 'Grant County Airport',
    country: {code: 'US', name: 'United States'},
    city: 'Grant Co Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.207708,
    longitude: -119.32019,
    altitude: 361.188
  },
  {
    iataCode: 'EGC',
    icaoCode: 'LFBE',
    name: 'Bergerac',
    country: {code: 'FR', name: 'France'},
    city: 'Roumaniere',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.825279,
    longitude: 0.518611,
    altitude: 52.1208
  },
  {
    iataCode: 'MWK',
    icaoCode: 'WIOM',
    name: 'Anambas Islands',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Matak Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 3.348119,
    longitude: 106.25805,
    altitude: 3.048
  },
  {
    iataCode: 'EGE',
    icaoCode: 'KEGE',
    name: 'Vail',
    country: {code: 'US', name: 'United States'},
    city: 'Eagle Co Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.642556,
    longitude: -106.917694,
    altitude: 1993.392
  },
  {
    iataCode: 'MWL',
    icaoCode: 'KMWL',
    name: 'Mineral Wells',
    country: {code: 'US', name: 'United States'},
    city: 'Mineral Wells',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.781606,
    longitude: -98.060175,
    altitude: 296.8752
  },
  {
    iataCode: 'MWQ',
    icaoCode: 'VYMW',
    name: 'Magwe',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Magwe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 20.165453,
    longitude: 94.941185,
    altitude: 83.82
  },
  {
    iataCode: 'VHY',
    icaoCode: 'LFLV',
    name: 'Vichy',
    country: {code: 'FR', name: 'France'},
    city: 'Charmeil',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.169689,
    longitude: 3.403736,
    altitude: 249.0216
  },
  {
    iataCode: 'EGM',
    icaoCode: 'AGGS',
    name: 'Sege',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Sege Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.57889,
    longitude: 157.876,
    altitude: 0.0
  },
  {
    iataCode: 'EGO',
    icaoCode: 'UUOB',
    name: 'Belgorod',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Belgorod International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 50.6438,
    longitude: 36.5901,
    altitude: 224.028
  },
  {
    iataCode: 'EGN',
    icaoCode: 'HSGN',
    name: 'Geneina',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Geneina Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 13.4817,
    longitude: 22.4672,
    altitude: 807.72
  },
  {
    iataCode: 'MWX',
    icaoCode: 'RKJB',
    name: 'Muan',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Muan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 34.991389,
    longitude: 126.382778,
    altitude: 15.5448
  },
  {
    iataCode: 'EGS',
    icaoCode: 'BIEG',
    name: 'Egilsstadir',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Egilsstadir',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.283333,
    longitude: -14.401389,
    altitude: 23.1648
  },
  {
    iataCode: 'MWZ',
    icaoCode: 'HTMW',
    name: 'Mwanza',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Mwanza',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -2.444486,
    longitude: 32.932667,
    altitude: 1146.9624
  },
  {
    iataCode: 'VIC',
    icaoCode: 'LIPT',
    name: 'Vicenza',
    country: {code: 'IT', name: 'Italy'},
    city: 'Vicenza',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.573411,
    longitude: 11.52955,
    altitude: 39.0144
  },
  {
    iataCode: 'VIE',
    icaoCode: 'LOWW',
    name: 'Vienna',
    country: {code: 'AT', name: 'Austria'},
    city: 'Schwechat',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 48.110278,
    longitude: 16.569722,
    altitude: 182.88
  },
  {
    iataCode: 'EGV',
    icaoCode: 'KEGV',
    name: 'Eagle River',
    country: {code: 'US', name: 'United States'},
    city: 'Eagle River',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.932333,
    longitude: -89.268283,
    altitude: 500.4816
  },
  {
    iataCode: 'VIG',
    icaoCode: 'SVVG',
    name: 'El Vigía',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Juan Pablo Pérez Alfonso Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.6241,
    longitude: -71.672819,
    altitude: 76.2
  },
  {
    iataCode: 'VIJ',
    icaoCode: 'TUPW',
    name: 'Spanish Town',
    country: {code: 'VG', name: 'British Virgin Islands'},
    city: 'Virgin Gorda Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Tortola',
    utcOffset: -240,
    latitude: 18.4464,
    longitude: -64.4275,
    altitude: 2.7432
  },
  {
    iataCode: 'EGX',
    icaoCode: 'PAII',
    name: 'Egegik',
    country: {code: 'US', name: 'United States'},
    city: 'Egegik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 58.185556,
    longitude: -157.375556,
    altitude: 28.0416
  },
  {
    iataCode: 'VII',
    icaoCode: 'VVVH',
    name: 'Vinh',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Vinh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 18.737569,
    longitude: 105.670764,
    altitude: 7.0104
  },
  {
    iataCode: 'VIL',
    icaoCode: 'GMMH',
    name: 'Dakhla',
    country: {code: 'EH', name: 'Western Sahara'},
    city: 'Dakhla Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/El_Aaiun',
    utcOffset: 0,
    latitude: 23.7183,
    longitude: -15.932,
    altitude: 10.9728
  },
  {
    iataCode: 'MXF',
    icaoCode: 'KMXF',
    name: 'Montgomery',
    country: {code: 'US', name: 'United States'},
    city: 'Maxwell Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.382944,
    longitude: -86.365778,
    altitude: 52.1208
  },
  {
    iataCode: 'MXH',
    icaoCode: 'AYMR',
    name: 'Moro',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Moro Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.36333,
    longitude: 143.238,
    altitude: 835.152
  },
  {
    iataCode: 'MXJ',
    icaoCode: 'DNMN',
    name: 'Minna',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Minna New',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 9.652172,
    longitude: 6.462256,
    altitude: 254.2032
  },
  {
    iataCode: 'VIR',
    icaoCode: 'FAVG',
    name: 'Durban',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Virginia',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.770606,
    longitude: 31.058406,
    altitude: 6.096
  },
  {
    iataCode: 'MXL',
    icaoCode: 'MMML',
    name: 'Mexicali',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Rodolfo Sanchez Taboada Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tijuana',
    utcOffset: -480,
    latitude: 32.630634,
    longitude: -115.241637,
    altitude: 22.5552
  },
  {
    iataCode: 'VIT',
    icaoCode: 'LEVT',
    name: 'Vitoria',
    country: {code: 'ES', name: 'Spain'},
    city: 'Vitoria',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.882836,
    longitude: -2.724469,
    altitude: 512.6736
  },
  {
    iataCode: 'VIS',
    icaoCode: 'KVIS',
    name: 'Visalia',
    country: {code: 'US', name: 'United States'},
    city: 'Visalia Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.318611,
    longitude: -119.392778,
    altitude: 89.916
  },
  {
    iataCode: 'MXN',
    icaoCode: 'LFRU',
    name: 'Morlaix',
    country: {code: 'FR', name: 'France'},
    city: 'Ploujean',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.603222,
    longitude: -3.815783,
    altitude: 82.9056
  },
  {
    iataCode: 'MXM',
    icaoCode: 'FMSR',
    name: 'Morombe',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Morombe',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -21.753867,
    longitude: 43.375533,
    altitude: 4.8768
  },
  {
    iataCode: 'MXP',
    icaoCode: 'LIMC',
    name: 'Milano',
    country: {code: 'IT', name: 'Italy'},
    city: 'Malpensa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.630606,
    longitude: 8.728111,
    altitude: 233.7816
  },
  {
    iataCode: 'VIX',
    icaoCode: 'SBVT',
    name: 'Vitoria',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Goiabeiras',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -20.258056,
    longitude: -40.286389,
    altitude: 3.3528
  },
  {
    iataCode: 'VIY',
    icaoCode: 'FABE',
    name: 'Bisho',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Bhisho',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -32.89715,
    longitude: 27.279111,
    altitude: 594.36
  },
  {
    iataCode: 'EHL',
    icaoCode: 'SAVB',
    name: 'El Bolson',
    country: {code: 'AR', name: 'Argentina'},
    city: 'El Bolson',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -41.943189,
    longitude: -71.532289,
    altitude: 344.7288
  },
  {
    iataCode: 'MXT',
    icaoCode: 'FMMO',
    name: 'Maintirano',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Maintirano Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.05,
    longitude: 44.033,
    altitude: 28.956
  },
  {
    iataCode: 'MXS',
    icaoCode: 'NSMA',
    name: 'Savaii Island',
    country: {code: 'WS', name: 'Samoa'},
    city: 'Maota Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Apia',
    utcOffset: -660,
    latitude: -13.733,
    longitude: -172.3,
    altitude: 0.0
  },
  {
    iataCode: 'MXV',
    icaoCode: 'ZMMN',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Muren Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    latitude: 49.6633,
    longitude: 100.099,
    altitude: 1302.1056
  },
  {
    iataCode: 'EHM',
    icaoCode: 'PAEH',
    name: 'Cape Newenham',
    country: {code: 'US', name: 'United States'},
    city: 'Cape Newenham Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 58.646428,
    longitude: -162.062778,
    altitude: 164.8968
  },
  {
    iataCode: 'MXX',
    icaoCode: 'ESKM',
    name: 'Mora',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Mora',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 60.957908,
    longitude: 14.511383,
    altitude: 193.2432
  },
  {
    iataCode: 'MXZ',
    icaoCode: 'ZGMX',
    name: 'Meixian',
    country: {code: 'CN', name: 'China'},
    city: 'Meixian Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 24.35,
    longitude: 116.133,
    altitude: 0.0
  },
  {
    iataCode: 'MYA',
    icaoCode: 'YMRY',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Moruya Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -35.8978,
    longitude: 150.144,
    altitude: 4.2672
  },
  {
    iataCode: 'MYC',
    icaoCode: 'SVBS',
    name: 'Maracay',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Escuela Mariscal Sucre Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.249978,
    longitude: -67.649419,
    altitude: 407.8224
  },
  {
    iataCode: 'MYB',
    icaoCode: 'FOOY',
    name: 'Mayumba',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Mayumba Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -3.416667,
    longitude: 10.65,
    altitude: 152.4
  },
  {
    iataCode: 'MYE',
    icaoCode: 'RJTQ',
    name: '',
    country: {code: 'JP', name: 'Japan'},
    city: 'Miyakejima Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.0736,
    longitude: 139.56,
    altitude: 20.4216
  },
  {
    iataCode: 'MYD',
    icaoCode: 'HKML',
    name: 'Malindi',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Malindi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -3.22931,
    longitude: 40.1017,
    altitude: 24.384
  },
  {
    iataCode: 'MYG',
    icaoCode: 'MYMM',
    name: 'Mayaguana',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Mayaguana',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 22.379528,
    longitude: -73.0135,
    altitude: 3.3528
  },
  {
    iataCode: 'MYI',
    icaoCode: 'YMUI',
    name: 'Murray Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Murray Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -9.91667,
    longitude: 144.055,
    altitude: 0.0
  },
  {
    iataCode: 'MYJ',
    icaoCode: 'RJOM',
    name: 'Matsuyama',
    country: {code: 'JP', name: 'Japan'},
    city: 'Matsuyama',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.827222,
    longitude: 132.699722,
    altitude: 7.62
  },
  {
    iataCode: 'EIE',
    icaoCode: 'UNII',
    name: 'Yeniseysk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Yeniseysk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 58.4742,
    longitude: 92.1125,
    altitude: 77.1144
  },
  {
    iataCode: 'MYL',
    icaoCode: 'KMYL',
    name: 'Mccall',
    country: {code: 'US', name: 'United States'},
    city: 'Mccall Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 44.889722,
    longitude: -116.101389,
    altitude: 1530.4008
  },
  {
    iataCode: 'MYP',
    icaoCode: 'UTAM',
    name: 'Mary',
    country: {code: 'TM', name: 'Turkmenistan'},
    city: 'Mary Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ashgabat',
    utcOffset: 300,
    latitude: 37.6194,
    longitude: 61.8967,
    altitude: 221.8944
  },
  {
    iataCode: 'MYR',
    icaoCode: 'KMYR',
    name: 'Myrtle Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Myrtle Beach Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.67975,
    longitude: -78.928333,
    altitude: 7.62
  },
  {
    iataCode: 'MYU',
    icaoCode: 'PAMY',
    name: 'Mekoryuk',
    country: {code: 'US', name: 'United States'},
    city: 'Mekoryuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 60.3714,
    longitude: -166.271,
    altitude: 14.6304
  },
  {
    iataCode: 'EIL',
    icaoCode: 'PAEI',
    name: 'Fairbanks',
    country: {code: 'US', name: 'United States'},
    city: 'Eielson Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.665667,
    longitude: -147.1015,
    altitude: 167.0304
  },
  {
    iataCode: 'MYT',
    icaoCode: 'VYMK',
    name: 'Myitkyina',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Myitkyina',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 25.383636,
    longitude: 97.351919,
    altitude: 144.78
  },
  {
    iataCode: 'MYW',
    icaoCode: 'HTMT',
    name: 'Mtwara',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Mtwara',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 120,
    latitude: -10.339058,
    longitude: 40.181781,
    altitude: 113.0808
  },
  {
    iataCode: 'EIN',
    icaoCode: 'EHEH',
    name: 'Eindhoven',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Eindhoven',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 51.450139,
    longitude: 5.374528,
    altitude: 22.5552
  },
  {
    iataCode: 'MYY',
    icaoCode: 'WBGR',
    name: 'Miri',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Miri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 4.322014,
    longitude: 113.986806,
    altitude: 17.9832
  },
  {
    iataCode: 'EIS',
    icaoCode: 'TUPJ',
    name: 'Roadtown/beef Island',
    country: {code: 'VG', name: 'British Virgin Islands'},
    city: 'Terrance B Lettsome Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tortola',
    utcOffset: -240,
    latitude: 18.444834,
    longitude: -64.542975,
    altitude: 4.572
  },
  {
    iataCode: 'VKG',
    icaoCode: 'VVRG',
    name: 'Rach Gia',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Rach Gia',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 9.949676,
    longitude: 105.133659,
    altitude: 2.1336
  },
  {
    iataCode: 'MZB',
    icaoCode: 'FQMP',
    name: 'Mocimboa Da Praia',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Mocimboa Da Praia',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -11.361789,
    longitude: 40.354875,
    altitude: 27.1272
  },
  {
    iataCode: 'MZH',
    icaoCode: 'LTAP',
    name: 'Merzifon',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Merzifon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.829375,
    longitude: 35.521992,
    altitude: 535.8384
  },
  {
    iataCode: 'MZG',
    icaoCode: 'RCQC',
    name: 'Makung',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Magong',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 23.568669,
    longitude: 119.628311,
    altitude: 31.3944
  },
  {
    iataCode: 'VKO',
    icaoCode: 'UUWW',
    name: 'Moscow',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Vnukovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.591531,
    longitude: 37.261486,
    altitude: 208.788
  },
  {
    iataCode: 'MZJ',
    icaoCode: 'KMZJ',
    name: 'Pinal Park',
    country: {code: 'US', name: 'United States'},
    city: 'Marana',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 32.5097222,
    longitude: -111.3252778,
    altitude: 577.0
  },
  {
    iataCode: 'EJA',
    icaoCode: 'SKEJ',
    name: 'Barrancabermeja',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Yariguies',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 7.024331,
    longitude: -73.8068,
    altitude: 125.5776
  },
  {
    iataCode: 'MZI',
    icaoCode: 'GAMB',
    name: 'Mopti',
    country: {code: 'ML', name: 'Mali'},
    city: 'Ambodedjo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bamako',
    utcOffset: 0,
    latitude: 14.512803,
    longitude: -4.079561,
    altitude: 276.1488
  },
  {
    iataCode: 'MZL',
    icaoCode: 'SKMZ',
    name: 'Manizales',
    country: {code: 'CO', name: 'Colombia'},
    city: 'La Nubia',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 5.029597,
    longitude: -75.464708,
    altitude: 2094.2808
  },
  {
    iataCode: 'VKT',
    icaoCode: 'UUYW',
    name: 'Vorkuta',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Vorkuta Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 300,
    latitude: 67.4886,
    longitude: 63.9931,
    altitude: 184.0992
  },
  {
    iataCode: 'MZK',
    icaoCode: 'NGMK',
    name: 'Marakei',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Marakei Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 720,
    latitude: 2.050278,
    longitude: 173.266667,
    altitude: 3.048
  },
  {
    iataCode: 'MZM',
    icaoCode: 'LFSF',
    name: 'Metz',
    country: {code: 'FR', name: 'France'},
    city: 'Frescaty',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.071667,
    longitude: 6.131667,
    altitude: 191.7192
  },
  {
    iataCode: 'EJH',
    icaoCode: 'OEWJ',
    name: 'Wejh',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Wejh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 26.198553,
    longitude: 36.476381,
    altitude: 20.1168
  },
  {
    iataCode: 'MZO',
    icaoCode: 'MUMZ',
    name: 'Manzanillo',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Sierra Maestra',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.288172,
    longitude: -77.0893,
    altitude: 34.1376
  },
  {
    iataCode: 'MZR',
    icaoCode: 'OAMS',
    name: 'Mazar-i-sharif',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Mazar I Sharif',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 36.706914,
    longitude: 67.209678,
    altitude: 391.3632
  },
  {
    iataCode: 'MZT',
    icaoCode: 'MMMZ',
    name: 'Mazatlan',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Rafael Buelna Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mazatlan',
    utcOffset: -420,
    latitude: 23.161356,
    longitude: -106.266072,
    altitude: 11.5824
  },
  {
    iataCode: 'VLC',
    icaoCode: 'LEVC',
    name: 'Valencia',
    country: {code: 'ES', name: 'Spain'},
    city: 'Valencia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 39.489314,
    longitude: -0.481625,
    altitude: 68.58
  },
  {
    iataCode: 'VLD',
    icaoCode: 'KVLD',
    name: 'Valdosta',
    country: {code: 'US', name: 'United States'},
    city: 'Valdosta Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.7825,
    longitude: -83.2767,
    altitude: 61.8744
  },
  {
    iataCode: 'VLG',
    icaoCode: 'SAZV',
    name: 'Villa Gesell',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Villa Gesell',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -37.235408,
    longitude: -57.029239,
    altitude: 9.7536
  },
  {
    iataCode: 'VLI',
    icaoCode: 'NVVV',
    name: 'Port-vila',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Port Vila Bauerfield',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -17.699325,
    longitude: 168.319794,
    altitude: 21.336
  },
  {
    iataCode: 'VLL',
    icaoCode: 'LEVD',
    name: 'Valladolid',
    country: {code: 'ES', name: 'Spain'},
    city: 'Valladolid',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.706111,
    longitude: -4.851944,
    altitude: 845.82
  },
  {
    iataCode: 'VLN',
    icaoCode: 'SVVA',
    name: 'Valencia',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Arturo Michelena Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.149733,
    longitude: -67.9284,
    altitude: 431.9016
  },
  {
    iataCode: 'VLS',
    icaoCode: 'NVSV',
    name: 'Valesdir',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Valesdir Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.7961,
    longitude: 168.177,
    altitude: 3.048
  },
  {
    iataCode: 'VLV',
    icaoCode: 'SVVL',
    name: 'Valera',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Dr Antonio Nicolas Briceno',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.340797,
    longitude: -70.584089,
    altitude: 576.9864
  },
  {
    iataCode: 'EKO',
    icaoCode: 'KEKO',
    name: 'Elko',
    country: {code: 'US', name: 'United States'},
    city: 'Elko Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 40.8249,
    longitude: -115.792,
    altitude: 1566.672
  },
  {
    iataCode: 'EKN',
    icaoCode: 'KEKN',
    name: 'Elkins',
    country: {code: 'US', name: 'United States'},
    city: 'Elkins Randolph Co Jennings Randolph',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.889444,
    longitude: -79.857139,
    altitude: 605.6376
  },
  {
    iataCode: 'ELA',
    icaoCode: 'VYEL',
    name: 'Naypyidaw',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Naypyidaw',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 19.6235,
    longitude: 96.201028,
    altitude: 92.0496
  },
  {
    iataCode: 'ELD',
    icaoCode: 'KELD',
    name: 'El Dorado',
    country: {code: 'US', name: 'United States'},
    city: 'South Arkansas Rgnl At Goodwin Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.220972,
    longitude: -92.813278,
    altitude: 84.4296
  },
  {
    iataCode: 'ELC',
    icaoCode: 'YELD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Elcho Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.0194,
    longitude: 135.571,
    altitude: 30.7848
  },
  {
    iataCode: 'ELF',
    icaoCode: 'HSFS',
    name: 'El Fasher',
    country: {code: 'SD', name: 'Sudan'},
    city: 'El Fashir',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 13.614892,
    longitude: 25.32465,
    altitude: 729.3864
  },
  {
    iataCode: 'ELH',
    icaoCode: 'MYEH',
    name: 'North Eleuthera',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'North Eleuthera',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.474861,
    longitude: -76.683489,
    altitude: 3.9624
  },
  {
    iataCode: 'ELG',
    icaoCode: 'DAUE',
    name: 'El Golea',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'El Golea',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 30.571294,
    longitude: 2.859586,
    altitude: 398.0688
  },
  {
    iataCode: 'ELI',
    icaoCode: 'PFEL',
    name: 'Elim',
    country: {code: 'US', name: 'United States'},
    city: 'Elim Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 64.615,
    longitude: -162.270556,
    altitude: 49.3776
  },
  {
    iataCode: 'ELM',
    icaoCode: 'KELM',
    name: 'Elmira',
    country: {code: 'US', name: 'United States'},
    city: 'Elmira Corning Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.159889,
    longitude: -76.891611,
    altitude: 290.7792
  },
  {
    iataCode: 'ELP',
    icaoCode: 'KELP',
    name: 'El Paso',
    country: {code: 'US', name: 'United States'},
    city: 'El Paso Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 31.80725,
    longitude: -106.377583,
    altitude: 1206.3984
  },
  {
    iataCode: 'ELQ',
    icaoCode: 'OEGS',
    name: 'Gassim',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Gassim',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 26.302822,
    longitude: 43.773911,
    altitude: 648.0048
  },
  {
    iataCode: 'ELT',
    icaoCode: 'HETR',
    name: 'El-tor',
    country: {code: 'EG', name: 'Egypt'},
    city: 'El Tor',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 28.209028,
    longitude: 33.645517,
    altitude: 35.052
  },
  {
    iataCode: 'VNE',
    icaoCode: 'LFRV',
    name: 'Vannes',
    country: {code: 'FR', name: 'France'},
    city: 'Meucon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.723303,
    longitude: -2.718561,
    altitude: 134.112
  },
  {
    iataCode: 'ELS',
    icaoCode: 'FAEL',
    name: 'East London',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'East London',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -33.035569,
    longitude: 27.825939,
    altitude: 132.588
  },
  {
    iataCode: 'ELV',
    icaoCode: 'PAEL',
    name: 'Elfin Cove',
    country: {code: 'US', name: 'United States'},
    city: 'Elfin Cove Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 58.195278,
    longitude: -136.3475,
    altitude: 0.0
  },
  {
    iataCode: 'ELU',
    icaoCode: 'DAUO',
    name: '',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Guemar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 33.5114,
    longitude: 6.77679,
    altitude: 61.8744
  },
  {
    iataCode: 'ELY',
    icaoCode: 'KELY',
    name: 'Ely',
    country: {code: 'US', name: 'United States'},
    city: 'Ely Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 39.299722,
    longitude: -114.841944,
    altitude: 1907.7432
  },
  {
    iataCode: 'VNO',
    icaoCode: 'EYVI',
    name: 'Vilnius',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Vilnius Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 54.634133,
    longitude: 25.285767,
    altitude: 196.9008
  },
  {
    iataCode: 'EMA',
    icaoCode: 'EGNX',
    name: 'East Midlands',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Nottingham East Midlands',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.831111,
    longitude: -1.328056,
    altitude: 93.2688
  },
  {
    iataCode: 'VNS',
    icaoCode: 'VIBN',
    name: 'Varanasi',
    country: {code: 'IN', name: 'India'},
    city: 'Varanasi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.452358,
    longitude: 82.859342,
    altitude: 81.0768
  },
  {
    iataCode: 'EME',
    icaoCode: 'EDWE',
    name: 'Emden',
    country: {code: 'DE', name: 'Germany'},
    city: 'Emden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.391186,
    longitude: 7.227408,
    altitude: 0.6096
  },
  {
    iataCode: 'EMD',
    icaoCode: 'YEML',
    name: 'Emerald',
    country: {code: 'AU', name: 'Australia'},
    city: 'Emerald',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.5675,
    longitude: 148.179167,
    altitude: 190.1952
  },
  {
    iataCode: 'VNY',
    icaoCode: 'KVNY',
    name: 'Van Nuys',
    country: {code: 'US', name: 'United States'},
    city: 'Van Nuys',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.209811,
    longitude: -118.489972,
    altitude: 244.4496
  },
  {
    iataCode: 'VNX',
    icaoCode: 'FQVL',
    name: 'Vilankulu',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Vilankulo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -22.018431,
    longitude: 35.313297,
    altitude: 14.0208
  },
  {
    iataCode: 'EMK',
    icaoCode: 'PAEM',
    name: 'Emmonak',
    country: {code: 'US', name: 'United States'},
    city: 'Emmonak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 62.786111,
    longitude: -164.490833,
    altitude: 3.9624
  },
  {
    iataCode: 'EMN',
    icaoCode: 'GQNI',
    name: 'Nema',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Nema',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 16.622,
    longitude: -7.316567,
    altitude: 231.0384
  },
  {
    iataCode: 'VOH',
    icaoCode: 'FMNV',
    name: 'Vohemar',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Vohimarina',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -13.375834,
    longitude: 50.002777,
    altitude: 5.7912
  },
  {
    iataCode: 'VOG',
    icaoCode: 'URWW',
    name: 'Volgograd',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Gumrak',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 300,
    latitude: 48.782528,
    longitude: 44.345544,
    altitude: 146.9136
  },
  {
    iataCode: 'VOL',
    icaoCode: 'LGBL',
    name: 'Nea Anghialos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Nea Anchialos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.219619,
    longitude: 22.794339,
    altitude: 25.2984
  },
  {
    iataCode: 'VOK',
    icaoCode: 'KVOK',
    name: 'Camp Douglas',
    country: {code: 'US', name: 'United States'},
    city: 'Volk Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.938956,
    longitude: -90.253433,
    altitude: 277.9776
  },
  {
    iataCode: 'ENA',
    icaoCode: 'PAEN',
    name: 'Kenai',
    country: {code: 'US', name: 'United States'},
    city: 'Kenai Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.573111,
    longitude: -151.245,
    altitude: 30.1752
  },
  {
    iataCode: 'END',
    icaoCode: 'KEND',
    name: 'Enid',
    country: {code: 'US', name: 'United States'},
    city: 'Vance Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.339167,
    longitude: -97.9165,
    altitude: 398.3736
  },
  {
    iataCode: 'ENC',
    icaoCode: 'LFSN',
    name: 'Nancy',
    country: {code: 'FR', name: 'France'},
    city: 'Essey',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.692069,
    longitude: 6.230458,
    altitude: 228.9048
  },
  {
    iataCode: 'ENF',
    icaoCode: 'EFET',
    name: 'Enontekio',
    country: {code: 'FI', name: 'Finland'},
    city: 'Enontekio',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 68.362586,
    longitude: 23.424322,
    altitude: 306.324
  },
  {
    iataCode: 'ENE',
    icaoCode: 'WATE',
    name: 'Ende',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'H Hasan Aroeboesman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.849294,
    longitude: 121.660644,
    altitude: 14.9352
  },
  {
    iataCode: 'ENH',
    icaoCode: 'ZHES',
    name: 'Enshi',
    country: {code: 'CN', name: 'China'},
    city: 'Enshi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 30.3203,
    longitude: 109.485,
    altitude: 0.0
  },
  {
    iataCode: 'VOZ',
    icaoCode: 'UUOO',
    name: 'Voronezh',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Chertovitskoye',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 51.814211,
    longitude: 39.229589,
    altitude: 156.6672
  },
  {
    iataCode: 'ENK',
    icaoCode: 'EGAB',
    name: 'Enniskillen',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'St Angelo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 54.398889,
    longitude: -7.651667,
    altitude: 47.244
  },
  {
    iataCode: 'ENT',
    icaoCode: 'PKMA',
    name: 'Eniwetok Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Eniwetok Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 11.34075,
    longitude: 162.327861,
    altitude: 3.9624
  },
  {
    iataCode: 'VPE',
    icaoCode: 'FNGI',
    name: 'Ondjiva',
    country: {code: 'AO', name: 'Angola'},
    city: 'Ondjiva Pereira Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -17.043464,
    longitude: 15.683822,
    altitude: 1086.9168
  },
  {
    iataCode: 'ENS',
    icaoCode: 'EHTW',
    name: 'Enschede',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Twenthe',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 52.27,
    longitude: 6.874167,
    altitude: 34.7472
  },
  {
    iataCode: 'ENV',
    icaoCode: 'KENV',
    name: 'Wendover',
    country: {code: 'US', name: 'United States'},
    city: 'Wendover',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 40.718694,
    longitude: -114.030889,
    altitude: 1291.4376
  },
  {
    iataCode: 'ENU',
    icaoCode: 'DNEN',
    name: 'Enugu',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Enugu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 6.474272,
    longitude: 7.561961,
    altitude: 142.0368
  },
  {
    iataCode: 'ENY',
    icaoCode: 'ZLYA',
    name: "Yan'an",
    country: {code: 'CN', name: 'China'},
    city: "Yan'an Airport",
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 36.6369,
    longitude: 109.554,
    altitude: 0.0
  },
  {
    iataCode: 'VPN',
    icaoCode: 'BIVO',
    name: 'Vopnafjörður',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Vopnafjörður Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.7206,
    longitude: -14.8506,
    altitude: 0.0
  },
  {
    iataCode: 'VPS',
    icaoCode: 'KVPS',
    name: 'Valparaiso',
    country: {code: 'US', name: 'United States'},
    city: 'Eglin Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.48325,
    longitude: -86.5254,
    altitude: 26.5176
  },
  {
    iataCode: 'EOI',
    icaoCode: 'EGED',
    name: 'Eday',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Eday Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.1906,
    longitude: -2.77222,
    altitude: 3.048
  },
  {
    iataCode: 'VPY',
    icaoCode: 'FQCH',
    name: 'Chimoio',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Chimoio Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -19.151267,
    longitude: 33.428958,
    altitude: 697.0776
  },
  {
    iataCode: 'EOH',
    icaoCode: 'SKMD',
    name: 'Medellin',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Olaya Herrera',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 6.219958,
    longitude: -75.590519,
    altitude: 1505.712
  },
  {
    iataCode: 'NAA',
    icaoCode: 'YNBR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Narrabri Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -30.3192,
    longitude: 149.827,
    altitude: 240.1824
  },
  {
    iataCode: 'NAH',
    icaoCode: 'WAMH',
    name: 'Naha',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Naha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 3.683214,
    longitude: 125.528019,
    altitude: 4.8768
  },
  {
    iataCode: 'NAG',
    icaoCode: 'VANP',
    name: 'Nagpur',
    country: {code: 'IN', name: 'India'},
    city: 'Dr Ambedkar Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.092192,
    longitude: 79.047183,
    altitude: 314.8584
  },
  {
    iataCode: 'NAJ',
    icaoCode: 'UBBN',
    name: 'Nakhchivan',
    country: {code: 'AZ', name: 'Azerbaijan'},
    city: 'Nakhchivan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baku',
    utcOffset: 180,
    latitude: 39.1888,
    longitude: 45.4584,
    altitude: 872.6424
  },
  {
    iataCode: 'NAI',
    icaoCode: 'SYAN',
    name: 'Annai',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Annai Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 3.95,
    longitude: -59.133333,
    altitude: 3.048
  },
  {
    iataCode: 'NAL',
    icaoCode: 'URMN',
    name: 'Nalchik',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Nalchik Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 43.5129,
    longitude: 43.6366,
    altitude: 445.3128
  },
  {
    iataCode: 'NAK',
    icaoCode: 'VTUQ',
    name: 'Nakhon Ratchasima',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Nakhon Ratchasima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 14.949497,
    longitude: 102.312736,
    altitude: 233.172
  },
  {
    iataCode: 'VQS',
    icaoCode: 'TJCG',
    name: 'Vieques Island',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Vieques Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.1158,
    longitude: -65.4227,
    altitude: 5.7912
  },
  {
    iataCode: 'NAN',
    icaoCode: 'NFFN',
    name: 'Nandi',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Nadi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.755392,
    longitude: 177.443378,
    altitude: 17.9832
  },
  {
    iataCode: 'NAP',
    icaoCode: 'LIRN',
    name: 'Naples',
    country: {code: 'IT', name: 'Italy'},
    city: 'Capodichino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.886033,
    longitude: 14.290781,
    altitude: 89.6112
  },
  {
    iataCode: 'NAO',
    icaoCode: 'ZUNC',
    name: 'Nanchong',
    country: {code: 'CN', name: 'China'},
    city: 'Nanchong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 30.754,
    longitude: 106.062,
    altitude: 0.0
  },
  {
    iataCode: 'NAQ',
    icaoCode: 'BGQQ',
    name: 'Qaanaaq',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Qaanaaq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Thule',
    utcOffset: -240,
    latitude: 77.4886,
    longitude: -69.3887,
    altitude: 15.5448
  },
  {
    iataCode: 'EPL',
    icaoCode: 'LFSG',
    name: 'Epinal',
    country: {code: 'FR', name: 'France'},
    city: 'Mirecourt',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.324961,
    longitude: 6.069983,
    altitude: 330.4032
  },
  {
    iataCode: 'NAT',
    icaoCode: 'SBNT',
    name: 'Natal',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Augusto Severo',
    region: '',
    terminal: '',
    timezoneId: 'America/Recife',
    utcOffset: -180,
    latitude: -5.911417,
    longitude: -35.247717,
    altitude: 51.5112
  },
  {
    iataCode: 'NAS',
    icaoCode: 'MYNN',
    name: 'Nassau',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Lynden Pindling Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.038958,
    longitude: -77.466231,
    altitude: 4.8768
  },
  {
    iataCode: 'NAV',
    icaoCode: 'LTAZ',
    name: 'Nevsehir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Kapadokya',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.771867,
    longitude: 34.53455,
    altitude: 944.88
  },
  {
    iataCode: 'VRA',
    icaoCode: 'MUVR',
    name: 'Varadero',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Juan Gualberto Gomez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 23.034445,
    longitude: -81.435278,
    altitude: 64.008
  },
  {
    iataCode: 'NAW',
    icaoCode: 'VTSC',
    name: 'Narathiwat',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Narathiwat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 6.519922,
    longitude: 101.7434,
    altitude: 4.8768
  },
  {
    iataCode: 'EPR',
    icaoCode: 'YESP',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Esperance Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -33.6844,
    longitude: 121.823,
    altitude: 143.256
  },
  {
    iataCode: 'VRC',
    icaoCode: 'RPUV',
    name: 'Virac',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Virac Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 13.576439,
    longitude: 124.205672,
    altitude: 36.8808
  },
  {
    iataCode: 'NAY',
    icaoCode: 'ZBBB',
    name: 'Beijing',
    country: {code: 'CN', name: 'China'},
    city: 'Beijing Nanyuan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 39.9608,
    longitude: 116.257,
    altitude: 0.0
  },
  {
    iataCode: 'VRB',
    icaoCode: 'KVRB',
    name: 'Vero Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Vero Beach Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.655556,
    longitude: -80.417944,
    altitude: 7.3152
  },
  {
    iataCode: 'EPS',
    icaoCode: 'MDAB',
    name: 'Samana',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Arroyo Barril Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 19.198586,
    longitude: -69.429772,
    altitude: 17.3736
  },
  {
    iataCode: 'EPU',
    icaoCode: 'EEPU',
    name: 'Parnu',
    country: {code: 'EE', name: 'Estonia'},
    city: 'Parnu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Tallinn',
    utcOffset: 120,
    latitude: 58.419044,
    longitude: 24.472819,
    altitude: 14.3256
  },
  {
    iataCode: 'NBC',
    icaoCode: 'KNBC',
    name: 'Beaufort',
    country: {code: 'US', name: 'United States'},
    city: 'Beaufort Mcas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.477411,
    longitude: -80.723161,
    altitude: 11.2776
  },
  {
    iataCode: 'VRK',
    icaoCode: 'EFVR',
    name: 'Varkaus',
    country: {code: 'FI', name: 'Finland'},
    city: 'Varkaus',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 62.171111,
    longitude: 27.868611,
    altitude: 87.1728
  },
  {
    iataCode: 'NBB',
    icaoCode: 'USHB',
    name: 'Berezovo',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Berezovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 63.9241,
    longitude: 65.0487,
    altitude: 29.8704
  },
  {
    iataCode: 'NBE',
    icaoCode: 'DTNZ',
    name: 'Enfidha',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Enfidha - Zine El Abidine Ben Ali International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 36.075833,
    longitude: 10.438611,
    altitude: 152.4
  },
  {
    iataCode: 'VRL',
    icaoCode: 'LPVR',
    name: 'Vila Real',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Vila Real',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Lisbon',
    utcOffset: 0,
    latitude: 41.274334,
    longitude: -7.720472,
    altitude: 550.164
  },
  {
    iataCode: 'NBG',
    icaoCode: 'KNBG',
    name: 'New Orleans',
    country: {code: 'US', name: 'United States'},
    city: 'New Orleans Nas Jrb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.825333,
    longitude: -90.035,
    altitude: 0.9144
  },
  {
    iataCode: 'VRN',
    icaoCode: 'LIPX',
    name: 'Villafranca',
    country: {code: 'IT', name: 'Italy'},
    city: 'Villafranca',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.395706,
    longitude: 10.888533,
    altitude: 72.8472
  },
  {
    iataCode: 'VRU',
    icaoCode: 'FAVB',
    name: 'Vryburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Vryburg',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -26.982408,
    longitude: 24.728756,
    altitude: 1194.816
  },
  {
    iataCode: 'NBO',
    icaoCode: 'HKJK',
    name: 'Nairobi',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Jomo Kenyatta International',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -1.319167,
    longitude: 36.9275,
    altitude: 1623.6696
  },
  {
    iataCode: 'VRY',
    icaoCode: 'ENVR',
    name: 'Værøy',
    country: {code: 'NO', name: 'Norway'},
    city: 'Værøy Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 67.6667,
    longitude: 12.6833,
    altitude: 10.9728
  },
  {
    iataCode: 'NBW',
    icaoCode: 'UWKE',
    name: 'Nizhnekamsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Begishevo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 55.34,
    longitude: 52.06,
    altitude: 15.24
  },
  {
    iataCode: 'NBX',
    icaoCode: 'WABI',
    name: 'Nabire',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Nabire',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -3.368183,
    longitude: 135.496406,
    altitude: 6.096
  },
  {
    iataCode: 'VSA',
    icaoCode: 'MMVA',
    name: 'Villahermosa',
    country: {code: 'MX', name: 'Mexico'},
    city: 'C P A Carlos Rovirosa Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Merida',
    utcOffset: -360,
    latitude: 17.997,
    longitude: -92.817361,
    altitude: 14.0208
  },
  {
    iataCode: 'EQS',
    icaoCode: 'SAVE',
    name: 'Esquel',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Esquel',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -42.90795,
    longitude: -71.139472,
    altitude: 798.8808
  },
  {
    iataCode: 'VSG',
    icaoCode: 'UKCW',
    name: '',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Luhansk International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 48.4174,
    longitude: 39.3741,
    altitude: 193.8528
  },
  {
    iataCode: 'NCA',
    icaoCode: 'MBNC',
    name: 'North Caicos',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'North Caicos',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.917475,
    longitude: -71.939561,
    altitude: 3.048
  },
  {
    iataCode: 'NCE',
    icaoCode: 'LFMN',
    name: 'Nice',
    country: {code: 'FR', name: 'France'},
    city: 'Cote D Azur',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.658411,
    longitude: 7.215872,
    altitude: 3.6576
  },
  {
    iataCode: 'NCL',
    icaoCode: 'EGNT',
    name: 'Newcastle',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Newcastle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.0375,
    longitude: -1.691667,
    altitude: 81.0768
  },
  {
    iataCode: 'VST',
    icaoCode: 'ESOW',
    name: 'Vasteras',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Vasteras',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.589444,
    longitude: 16.633611,
    altitude: 6.4008
  },
  {
    iataCode: 'ERC',
    icaoCode: 'LTCD',
    name: 'Erzincan',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Erzincan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.710203,
    longitude: 39.527003,
    altitude: 1153.0584
  },
  {
    iataCode: 'ERF',
    icaoCode: 'EDDE',
    name: 'Erfurt',
    country: {code: 'DE', name: 'Germany'},
    city: 'Erfurt',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.979811,
    longitude: 10.958106,
    altitude: 315.7728
  },
  {
    iataCode: 'NCN',
    icaoCode: 'PFCB',
    name: 'Chenega',
    country: {code: 'US', name: 'United States'},
    city: 'Chenega Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.077222,
    longitude: -147.991944,
    altitude: 21.9456
  },
  {
    iataCode: 'ERH',
    icaoCode: 'GMFK',
    name: 'Er-rachidia',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Moulay Ali Cherif',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 31.9475,
    longitude: -4.398333,
    altitude: 1044.8544
  },
  {
    iataCode: 'ERI',
    icaoCode: 'KERI',
    name: 'Erie',
    country: {code: 'US', name: 'United States'},
    city: 'Erie Intl Tom Ridge Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.082022,
    longitude: -80.176217,
    altitude: 223.4184
  },
  {
    iataCode: 'NCS',
    icaoCode: 'FANC',
    name: 'Newcastle',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Newcastle',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -27.770586,
    longitude: 29.976894,
    altitude: 1241.7552
  },
  {
    iataCode: 'ERM',
    icaoCode: 'SSER',
    name: 'Erechim',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Erechim Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -27.6619,
    longitude: -52.2683,
    altitude: 761.3904
  },
  {
    iataCode: 'NCU',
    icaoCode: 'UTNN',
    name: 'Nukus',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Nukus Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 42.4884,
    longitude: 59.6233,
    altitude: 74.9808
  },
  {
    iataCode: 'NCY',
    icaoCode: 'LFLP',
    name: 'Annecy',
    country: {code: 'FR', name: 'France'},
    city: 'Meythet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.929233,
    longitude: 6.098764,
    altitude: 463.6008
  },
  {
    iataCode: 'VTB',
    icaoCode: 'UMII',
    name: 'Vitebsk',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Vitebsk',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 55.1265,
    longitude: 30.349639,
    altitude: 208.1784
  },
  {
    iataCode: 'VTE',
    icaoCode: 'VLVT',
    name: 'Vientiane',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Wattay Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 17.988322,
    longitude: 102.563256,
    altitude: 171.9072
  },
  {
    iataCode: 'ERS',
    icaoCode: 'FYWE',
    name: 'Windhoek',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Eros Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -22.6122,
    longitude: 17.0804,
    altitude: 1699.26
  },
  {
    iataCode: 'ERZ',
    icaoCode: 'LTCE',
    name: 'Erzurum',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Erzurum',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.956501,
    longitude: 41.170166,
    altitude: 1756.5624
  },
  {
    iataCode: 'NDC',
    icaoCode: 'VAND',
    name: '',
    country: {code: 'IN', name: 'India'},
    city: 'Nanded Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 19.1833,
    longitude: 77.3167,
    altitude: 381.0
  },
  {
    iataCode: 'NDB',
    icaoCode: 'GQPP',
    name: 'Nouadhibou',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Nouadhibou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 20.933067,
    longitude: -17.029956,
    altitude: 4.8768
  },
  {
    iataCode: 'NDG',
    icaoCode: 'ZYQQ',
    name: 'Qiqihar',
    country: {code: 'CN', name: 'China'},
    city: 'Qiqihar Sanjiazi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 47.239628,
    longitude: 123.918131,
    altitude: 145.3896
  },
  {
    iataCode: 'ESC',
    icaoCode: 'KESC',
    name: 'Escanaba',
    country: {code: 'US', name: 'United States'},
    city: 'Delta County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 45.722778,
    longitude: -87.093611,
    altitude: 185.6232
  },
  {
    iataCode: 'NDK',
    icaoCode: '3N0',
    name: 'Namorik Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Namorik Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 5.63167,
    longitude: 168.125,
    altitude: 4.572
  },
  {
    iataCode: 'VTS',
    icaoCode: 'EVVA',
    name: 'Ventspils',
    country: {code: 'LV', name: 'Latvia'},
    city: 'Ventspils International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Riga',
    utcOffset: 120,
    latitude: 57.357778,
    longitude: 21.544167,
    altitude: 5.7912
  },
  {
    iataCode: 'ESB',
    icaoCode: 'LTAC',
    name: 'Ankara',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Esenboga',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.128082,
    longitude: 32.995083,
    altitude: 952.5
  },
  {
    iataCode: 'NDJ',
    icaoCode: 'FTTJ',
    name: "N'djamena",
    country: {code: 'TD', name: 'Chad'},
    city: 'Ndjamena Hassan Djamous',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 12.133689,
    longitude: 15.034019,
    altitude: 295.0464
  },
  {
    iataCode: 'ESE',
    icaoCode: 'MMES',
    name: 'Ensenada',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ensenada',
    region: '',
    terminal: '',
    timezoneId: 'America/Tijuana',
    utcOffset: -480,
    latitude: 31.795281,
    longitude: -116.602772,
    altitude: 20.1168
  },
  {
    iataCode: 'VTU',
    icaoCode: 'MUVT',
    name: 'Las Tunas',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Hermanos Ameijeiras',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.987642,
    longitude: -76.9358,
    altitude: 99.9744
  },
  {
    iataCode: 'ESD',
    icaoCode: 'KORS',
    name: 'Eastsound',
    country: {code: 'US', name: 'United States'},
    city: 'Orcas Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.708056,
    longitude: -122.910556,
    altitude: 9.4488
  },
  {
    iataCode: 'ESF',
    icaoCode: 'KESF',
    name: 'Alexandria',
    country: {code: 'US', name: 'United States'},
    city: 'Esler Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.394903,
    longitude: -92.295772,
    altitude: 34.1376
  },
  {
    iataCode: 'ESH',
    icaoCode: 'EGKA',
    name: 'Shoreham By Sea',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Shoreham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.835556,
    longitude: -0.297222,
    altitude: 2.1336
  },
  {
    iataCode: 'ESK',
    icaoCode: 'LTBI',
    name: 'Eskisehir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Eskisehir',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.784138,
    longitude: 30.582111,
    altitude: 786.6888
  },
  {
    iataCode: 'NDR',
    icaoCode: 'GMMW',
    name: '',
    country: {code: 'MA', name: 'Morocco'},
    city: 'El Aroui Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 34.9888,
    longitude: -3.02821,
    altitude: 174.9552
  },
  {
    iataCode: 'VTZ',
    icaoCode: 'VEVZ',
    name: 'Vishakhapatnam',
    country: {code: 'IN', name: 'India'},
    city: 'Vishakhapatnam',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 360,
    latitude: 17.721167,
    longitude: 83.224483,
    altitude: 4.572
  },
  {
    iataCode: 'ESM',
    icaoCode: 'SETN',
    name: 'Tachina',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'General Rivadeneira Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: 0.978519,
    longitude: -79.6266,
    altitude: 9.7536
  },
  {
    iataCode: 'NDU',
    icaoCode: 'FYRU',
    name: 'Rundu',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Rundu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -17.956461,
    longitude: 19.719439,
    altitude: 1105.5096
  },
  {
    iataCode: 'ESL',
    icaoCode: 'URWI',
    name: 'Elista',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Elista Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 46.3739,
    longitude: 44.3309,
    altitude: 152.7048
  },
  {
    iataCode: 'NDY',
    icaoCode: 'EGES',
    name: 'Sanday',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Sanday Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.2503,
    longitude: -2.57667,
    altitude: 20.7264
  },
  {
    iataCode: 'ESS',
    icaoCode: 'EDLE',
    name: 'Essen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Essen Mulheim',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.402333,
    longitude: 6.937333,
    altitude: 129.2352
  },
  {
    iataCode: 'ESR',
    icaoCode: 'SCES',
    name: 'El Salvador',
    country: {code: 'CL', name: 'Chile'},
    city: 'Ricardo García Posada Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -26.3111,
    longitude: -69.7652,
    altitude: 1597.152
  },
  {
    iataCode: 'NDZ',
    icaoCode: 'KNDZ',
    name: 'Cuxhaven',
    country: {code: 'DE', name: 'Germany'},
    city: 'Cuxhaven Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.768612,
    longitude: 8.644722,
    altitude: 22.86
  },
  {
    iataCode: 'ESU',
    icaoCode: 'GMMI',
    name: 'Essadouira',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Mogador Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 31.3975,
    longitude: -9.681667,
    altitude: 117.0432
  },
  {
    iataCode: 'NEC',
    icaoCode: 'SAZO',
    name: 'Necochea',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Necochea Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -38.483056,
    longitude: -58.817222,
    altitude: 21.9456
  },
  {
    iataCode: 'VUP',
    icaoCode: 'SKVP',
    name: 'Valledupar',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Alfonso Lopez Pumarejo',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 10.435042,
    longitude: -73.249506,
    altitude: 138.9888
  },
  {
    iataCode: 'NEG',
    icaoCode: 'MKNG',
    name: 'Negril',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Negril Aerodrome',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 18.34,
    longitude: -78.335556,
    altitude: 2.7432
  },
  {
    iataCode: 'NEL',
    icaoCode: 'KNEL',
    name: 'Lakehurst',
    country: {code: 'US', name: 'United States'},
    city: 'Lakehurst Naes',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.033333,
    longitude: -74.353333,
    altitude: 31.3944
  },
  {
    iataCode: 'ETH',
    icaoCode: 'LLET',
    name: 'Elat',
    country: {code: 'IL', name: 'Israel'},
    city: 'Eilat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 29.561281,
    longitude: 34.960081,
    altitude: 12.8016
  },
  {
    iataCode: 'NER',
    icaoCode: 'NULL',
    name: 'Neryungri',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Neryungri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 600,
    latitude: 56.9,
    longitude: 124.8833,
    altitude: 304.8
  },
  {
    iataCode: 'NEV',
    icaoCode: 'TKPN',
    name: 'Charlestown',
    country: {code: 'KN', name: 'Saint Kitts and Nevis'},
    city: 'Vance Winkworth Amory International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Kitts',
    utcOffset: -240,
    latitude: 17.205678,
    longitude: -62.589869,
    altitude: 4.2672
  },
  {
    iataCode: 'VVC',
    icaoCode: 'SKVV',
    name: 'Villavicencio',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Vanguardia',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.167875,
    longitude: -73.613761,
    altitude: 424.8912
  },
  {
    iataCode: 'VVI',
    icaoCode: 'SLVR',
    name: 'Santa Cruz',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Viru Viru Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -17.644756,
    longitude: -63.135364,
    altitude: 373.38
  },
  {
    iataCode: 'ETZ',
    icaoCode: 'LFJL',
    name: 'Metz',
    country: {code: 'FR', name: 'France'},
    city: 'Metz Nancy Lorraine',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.982142,
    longitude: 6.251319,
    altitude: 265.176
  },
  {
    iataCode: 'NFG',
    icaoCode: 'USRN',
    name: 'Nefteyugansk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Nefteyugansk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 61.1083,
    longitude: 72.65,
    altitude: 35.052
  },
  {
    iataCode: 'VVO',
    icaoCode: 'UHWW',
    name: 'Vladivostok',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Knevichi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vladivostok',
    utcOffset: 600,
    latitude: 43.398953,
    longitude: 132.148017,
    altitude: 14.0208
  },
  {
    iataCode: 'EUA',
    icaoCode: 'NFTE',
    name: 'Eua Island',
    country: {code: 'TO', name: 'Tonga'},
    city: 'Kaufana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tongatapu',
    utcOffset: 780,
    latitude: -21.3783,
    longitude: -174.958,
    altitude: 99.06
  },
  {
    iataCode: 'NFL',
    icaoCode: 'KNFL',
    name: 'Fallon',
    country: {code: 'US', name: 'United States'},
    city: 'Fallon Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 39.416584,
    longitude: -118.70098,
    altitude: 1199.0832
  },
  {
    iataCode: 'EUG',
    icaoCode: 'KEUG',
    name: 'Eugene',
    country: {code: 'US', name: 'United States'},
    city: 'Mahlon Sweet Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 44.124583,
    longitude: -123.211972,
    altitude: 113.9952
  },
  {
    iataCode: 'VVZ',
    icaoCode: 'DAAP',
    name: 'Illizi',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Illizi Takhamalt',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 26.723536,
    longitude: 8.622653,
    altitude: 541.9344
  },
  {
    iataCode: 'F70',
    icaoCode: 'KF70',
    name: 'Murrieta-temecula',
    country: {code: 'US', name: 'United States'},
    city: 'French Valley Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.574179,
    longitude: -117.128473,
    altitude: 411.48
  },
  {
    iataCode: 'EUN',
    icaoCode: 'GMML',
    name: 'El Aaiún',
    country: {code: 'EH', name: 'Western Sahara'},
    city: 'Hassan I Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/El_Aaiun',
    utcOffset: 0,
    latitude: 27.1517,
    longitude: -13.2192,
    altitude: 63.0936
  },
  {
    iataCode: 'NGB',
    icaoCode: 'ZSNB',
    name: 'Ninbo',
    country: {code: 'CN', name: 'China'},
    city: 'Lishe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.826683,
    longitude: 121.461906,
    altitude: 3.9624
  },
  {
    iataCode: 'EUX',
    icaoCode: 'TNCE',
    name: 'Oranjestad',
    country: {code: '', name: 'Netherlands Antilles'},
    city: 'F D Roosevelt',
    region: '',
    terminal: '',
    timezoneId: 'America/Kralendijk',
    utcOffset: -240,
    latitude: 17.496492,
    longitude: -62.979439,
    altitude: 39.3192
  },
  {
    iataCode: 'NGF',
    icaoCode: 'PHNG',
    name: 'Kaneohe Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Kaneohe Bay Mcaf',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.450453,
    longitude: -157.768,
    altitude: 7.3152
  },
  {
    iataCode: 'NGE',
    icaoCode: 'FKKN',
    name: "N'gaoundere",
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Ngaoundere',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 7.357011,
    longitude: 13.559242,
    altitude: 1114.044
  },
  {
    iataCode: 'NGI',
    icaoCode: 'NFNG',
    name: 'Ngau',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Ngau Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -18.1156,
    longitude: 179.34,
    altitude: 15.24
  },
  {
    iataCode: 'EVE',
    icaoCode: 'ENEV',
    name: 'Harstad/narvik',
    country: {code: 'NO', name: 'Norway'},
    city: 'Evenes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 68.4913,
    longitude: 16.678108,
    altitude: 25.6032
  },
  {
    iataCode: 'NGP',
    icaoCode: 'KNGP',
    name: 'Corpus Christi',
    country: {code: 'US', name: 'United States'},
    city: 'Corpus Christi Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 27.692701,
    longitude: -97.290376,
    altitude: 5.4864
  },
  {
    iataCode: 'EVG',
    icaoCode: 'ESND',
    name: 'Sveg',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Sveg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 62.047811,
    longitude: 14.42295,
    altitude: 359.0544
  },
  {
    iataCode: 'NGO',
    icaoCode: 'RJGG',
    name: 'Nagoya',
    country: {code: 'JP', name: 'Japan'},
    city: 'Chubu Centrair Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.858414,
    longitude: 136.805408,
    altitude: 4.572
  },
  {
    iataCode: '4U9',
    icaoCode: 'K4U9',
    name: 'Dell',
    country: {code: 'US', name: 'United States'},
    city: 'Dell Flight Strip',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.735748,
    longitude: -112.720013,
    altitude: 1830.9336
  },
  {
    iataCode: 'NGS',
    icaoCode: 'RJFU',
    name: 'Nagasaki',
    country: {code: 'JP', name: 'Japan'},
    city: 'Nagasaki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 32.916944,
    longitude: 129.913611,
    altitude: 4.572
  },
  {
    iataCode: 'EVN',
    icaoCode: 'UDYZ',
    name: 'Yerevan',
    country: {code: 'AM', name: 'Armenia'},
    city: 'Zvartnots',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yerevan',
    utcOffset: 240,
    latitude: 40.147275,
    longitude: 44.395881,
    altitude: 865.0224
  },
  {
    iataCode: 'NGU',
    icaoCode: 'KNGU',
    name: 'Norfolk',
    country: {code: 'US', name: 'United States'},
    city: 'Norfolk Ns',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.937644,
    longitude: -76.289289,
    altitude: 4.572
  },
  {
    iataCode: 'NGX',
    icaoCode: 'VNMA',
    name: 'Manang',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Manang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.633,
    longitude: 84.0,
    altitude: 3352.8
  },
  {
    iataCode: 'VXC',
    icaoCode: 'FQLC',
    name: 'Lichinga',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Lichinga',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -13.273986,
    longitude: 35.266262,
    altitude: 1373.124
  },
  {
    iataCode: 'VXE',
    icaoCode: 'GVSV',
    name: 'Sao Vicente Island',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Sao Pedro',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 16.833689,
    longitude: -25.054661,
    altitude: 20.1168
  },
  {
    iataCode: 'EVV',
    icaoCode: 'KEVV',
    name: 'Evansville',
    country: {code: 'US', name: 'United States'},
    city: 'Evansville Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.036997,
    longitude: -87.532364,
    altitude: 127.4064
  },
  {
    iataCode: 'NHA',
    icaoCode: 'VVNT',
    name: 'Nhatrang',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Nhatrang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 12.227467,
    longitude: 109.192322,
    altitude: 6.096
  },
  {
    iataCode: 'VXO',
    icaoCode: 'ESMX',
    name: 'Vaxjo',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kronoberg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 56.929144,
    longitude: 14.727994,
    altitude: 185.928
  },
  {
    iataCode: 'NHK',
    icaoCode: 'KNHK',
    name: 'Patuxent River',
    country: {code: 'US', name: 'United States'},
    city: 'Patuxent River Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.285981,
    longitude: -76.411781,
    altitude: 11.8872
  },
  {
    iataCode: 'EWB',
    icaoCode: 'KEWB',
    name: 'New Bedford',
    country: {code: 'US', name: 'United States'},
    city: 'New Bedford Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.6761,
    longitude: -70.9569,
    altitude: 24.384
  },
  {
    iataCode: 'EWD',
    icaoCode: 'OEWD',
    name: 'Wadi-al-dawasir',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Wadi Al Dawasir Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 20.504275,
    longitude: 45.199556,
    altitude: 628.4976
  },
  {
    iataCode: 'NHT',
    icaoCode: 'EGWU',
    name: 'Northolt',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Northolt',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.553,
    longitude: -0.418167,
    altitude: 37.7952
  },
  {
    iataCode: 'EWN',
    icaoCode: 'KEWN',
    name: 'New Bern',
    country: {code: 'US', name: 'United States'},
    city: 'Craven Co Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.072972,
    longitude: -77.042944,
    altitude: 5.4864
  },
  {
    iataCode: 'NHV',
    icaoCode: 'NTMD',
    name: 'Nuku Hiva',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Nuku Hiva',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -8.795603,
    longitude: -140.228789,
    altitude: 67.056
  },
  {
    iataCode: 'EWR',
    icaoCode: 'KEWR',
    name: 'Newark',
    country: {code: 'US', name: 'United States'},
    city: 'Newark Liberty Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.6925,
    longitude: -74.168667,
    altitude: 5.4864
  },
  {
    iataCode: 'NIB',
    icaoCode: 'PAFS',
    name: 'Nikolai',
    country: {code: 'US', name: 'United States'},
    city: 'Nikolai Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.010833,
    longitude: -154.383889,
    altitude: 130.1496
  },
  {
    iataCode: 'NID',
    icaoCode: 'KNID',
    name: 'China',
    country: {code: 'US', name: 'United States'},
    city: 'China Lake Naws',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.685422,
    longitude: -117.692039,
    altitude: 695.8584
  },
  {
    iataCode: 'NIG',
    icaoCode: 'NGNU',
    name: 'Nikunau',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Nikunau Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: -1.35,
    longitude: 176.45,
    altitude: 3.048
  },
  {
    iataCode: 'NIM',
    icaoCode: 'DRRN',
    name: 'Niamey',
    country: {code: 'NE', name: 'Niger'},
    city: 'Diori Hamani',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Niamey',
    utcOffset: 60,
    latitude: 13.481547,
    longitude: 2.183614,
    altitude: 223.1136
  },
  {
    iataCode: 'NIP',
    icaoCode: 'KNIP',
    name: 'Jacksonville',
    country: {code: 'US', name: 'United States'},
    city: 'Jacksonville Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.235834,
    longitude: -81.680556,
    altitude: 6.7056
  },
  {
    iataCode: 'NIO',
    icaoCode: 'FZBI',
    name: 'Nioki',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Nioki Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -2.7175,
    longitude: 17.6847,
    altitude: 317.9064
  },
  {
    iataCode: 'NIT',
    icaoCode: 'LFBN',
    name: 'Niort',
    country: {code: 'FR', name: 'France'},
    city: 'Souche',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.311303,
    longitude: -0.401503,
    altitude: 61.8744
  },
  {
    iataCode: 'NIU',
    icaoCode: 'NTKN',
    name: 'Niau',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Niau',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.119037,
    longitude: -146.368406,
    altitude: 14.9352
  },
  {
    iataCode: 'EXT',
    icaoCode: 'EGTE',
    name: 'Exeter',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Exeter',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.734444,
    longitude: -3.413889,
    altitude: 31.0896
  },
  {
    iataCode: 'NJC',
    icaoCode: 'USNN',
    name: 'Nizhnevartovsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Nizhnevartovsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 60.949272,
    longitude: 76.483617,
    altitude: 53.9496
  },
  {
    iataCode: 'NJF',
    icaoCode: 'ORNI',
    name: 'Najaf',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Al Najaf International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 31.991667,
    longitude: 44.404167,
    altitude: 152.4
  },
  {
    iataCode: 'NJK',
    icaoCode: 'KNJK',
    name: 'El Centro',
    country: {code: 'US', name: 'United States'},
    city: 'El Centro Naf',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.829222,
    longitude: -115.671667,
    altitude: -12.8016
  },
  {
    iataCode: 'EYP',
    icaoCode: 'SKYP',
    name: 'Yopal',
    country: {code: 'CO', name: 'Colombia'},
    city: 'El Alcaraván Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 5.319114,
    longitude: -72.383975,
    altitude: 313.3344
  },
  {
    iataCode: 'EYW',
    icaoCode: 'KEYW',
    name: 'Key West',
    country: {code: 'US', name: 'United States'},
    city: 'Key West Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 24.556111,
    longitude: -81.759556,
    altitude: 0.9144
  },
  {
    iataCode: 'NKC',
    icaoCode: 'GQNN',
    name: 'Nouakschott',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Nouakchott',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 18.097856,
    longitude: -15.947956,
    altitude: 2.1336
  },
  {
    iataCode: 'NKG',
    icaoCode: 'ZSNJ',
    name: 'Nanjing',
    country: {code: 'CN', name: 'China'},
    city: 'Lukou',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.742042,
    longitude: 118.862025,
    altitude: 14.9352
  },
  {
    iataCode: 'EZE',
    icaoCode: 'SAEZ',
    name: 'Buenos Aires',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Ministro Pistarini',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -34.822222,
    longitude: -58.535833,
    altitude: 20.4216
  },
  {
    iataCode: 'NKM',
    icaoCode: 'RJNA',
    name: 'Nagoya',
    country: {code: 'JP', name: 'Japan'},
    city: 'Nagoya Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.255,
    longitude: 136.924,
    altitude: 15.8496
  },
  {
    iataCode: 'NKT',
    icaoCode: 'KNKT',
    name: 'Cherry Point',
    country: {code: 'US', name: 'United States'},
    city: 'Cherry Point Mcas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.900872,
    longitude: -76.880733,
    altitude: 8.8392
  },
  {
    iataCode: 'NKX',
    icaoCode: 'KNKX',
    name: 'Miramar',
    country: {code: 'US', name: 'United States'},
    city: 'Miramar Mcas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.867694,
    longitude: -117.14175,
    altitude: 145.6944
  },
  {
    iataCode: 'EZS',
    icaoCode: 'LTCA',
    name: 'Elazig',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Elazig',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.606925,
    longitude: 39.291417,
    altitude: 892.1496
  },
  {
    iataCode: 'NLA',
    icaoCode: 'FLND',
    name: 'Ndola',
    country: {code: 'ZM', name: 'Zambia'},
    city: 'Ndola',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lusaka',
    utcOffset: 120,
    latitude: -12.998139,
    longitude: 28.664944,
    altitude: 1270.1016
  },
  {
    iataCode: 'NLC',
    icaoCode: 'KNLC',
    name: 'Lemoore',
    country: {code: 'US', name: 'United States'},
    city: 'Lemoore Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.333012,
    longitude: -119.95208,
    altitude: 71.3232
  },
  {
    iataCode: 'NLD',
    icaoCode: 'MMNL',
    name: 'Nuevo Laredo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Quetzalcoatl Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Matamoros',
    utcOffset: -360,
    latitude: 27.443918,
    longitude: -99.57046,
    altitude: 147.5232
  },
  {
    iataCode: 'NLG',
    icaoCode: 'PAOU',
    name: 'Nelson Lagoon',
    country: {code: 'US', name: 'United States'},
    city: 'Nelson Lagoon',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 56.0075,
    longitude: -161.160278,
    altitude: 4.2672
  },
  {
    iataCode: 'NLF',
    icaoCode: 'YDNI',
    name: 'Darnley Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Darnley Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -9.58333,
    longitude: 143.767,
    altitude: 0.0
  },
  {
    iataCode: 'NLK',
    icaoCode: 'YSNF',
    name: 'Norfolk Island',
    country: {code: 'NF', name: 'Norfolk Island'},
    city: 'Norfolk Island Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Norfolk',
    utcOffset: 660,
    latitude: -29.041625,
    longitude: 167.938742,
    altitude: 113.0808
  },
  {
    iataCode: 'NLO',
    icaoCode: 'FZAB',
    name: 'Kinshasa',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Ndolo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -4.326689,
    longitude: 15.327342,
    altitude: 278.892
  },
  {
    iataCode: 'NLP',
    icaoCode: 'FANS',
    name: 'Nelspruit',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Nelspruit Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.5,
    longitude: 30.9138,
    altitude: 876.3
  },
  {
    iataCode: 'NLV',
    icaoCode: 'UKON',
    name: 'Nikolayev',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Mykolaiv International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 47.0579,
    longitude: 31.9198,
    altitude: 56.0832
  },
  {
    iataCode: 'NMB',
    icaoCode: 'VADN',
    name: 'Daman',
    country: {code: 'IN', name: 'India'},
    city: 'Daman',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 20.434364,
    longitude: 72.843206,
    altitude: 10.0584
  },
  {
    iataCode: 'NMA',
    icaoCode: 'UTKN',
    name: 'Namangan',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Namangan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tashkent',
    utcOffset: 300,
    latitude: 40.9846,
    longitude: 71.5567,
    altitude: 473.964
  },
  {
    iataCode: 'NMM',
    icaoCode: 'KNMM',
    name: 'Meridian',
    country: {code: 'US', name: 'United States'},
    city: 'Meridian Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.552083,
    longitude: -88.555557,
    altitude: 96.6216
  },
  {
    iataCode: 'NNA',
    icaoCode: 'GMMY',
    name: 'Kentira',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Kenitra',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 34.298864,
    longitude: -6.595878,
    altitude: 4.8768
  },
  {
    iataCode: 'NNB',
    icaoCode: 'AGGT',
    name: 'Santa Ana',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Santa Ana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -10.848056,
    longitude: 162.454167,
    altitude: 1.8288
  },
  {
    iataCode: 'NNG',
    icaoCode: 'ZGNN',
    name: 'Nanning',
    country: {code: 'CN', name: 'China'},
    city: 'Wuxu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 22.608267,
    longitude: 108.172442,
    altitude: 128.3208
  },
  {
    iataCode: 'NNM',
    icaoCode: 'ULAM',
    name: 'Naryan-mar',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Naryan-mar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 67.380537,
    longitude: 53.051016,
    altitude: 6.096
  },
  {
    iataCode: 'NNL',
    icaoCode: 'PANO',
    name: 'Nondalton',
    country: {code: 'US', name: 'United States'},
    city: 'Nondalton Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.966944,
    longitude: -154.851667,
    altitude: 79.8576
  },
  {
    iataCode: 'NNR',
    icaoCode: 'EICA',
    name: 'Indreabhan',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Connemara Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.2303,
    longitude: -9.46778,
    altitude: 0.0
  },
  {
    iataCode: 'NNT',
    icaoCode: 'VTCN',
    name: 'Nan',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Nan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 18.807914,
    longitude: 100.783419,
    altitude: 208.788
  },
  {
    iataCode: 'NNY',
    icaoCode: 'ZHNY',
    name: 'Nanyang',
    country: {code: 'CN', name: 'China'},
    city: 'Nanyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 32.9808,
    longitude: 112.615,
    altitude: 0.0
  },
  {
    iataCode: 'NNX',
    icaoCode: 'WRLF',
    name: 'Nunukan-nunukan Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Nunukan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 4.13653,
    longitude: 117.667,
    altitude: 15.8496
  },
  {
    iataCode: 'NOB',
    icaoCode: 'MRNS',
    name: 'Nosara Beach',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Nosara',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.97649,
    longitude: -85.653,
    altitude: 10.0584
  },
  {
    iataCode: 'NOA',
    icaoCode: 'YSNW',
    name: 'Nowra',
    country: {code: 'AU', name: 'Australia'},
    city: 'Nowra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.9489,
    longitude: 150.537,
    altitude: 121.92
  },
  {
    iataCode: 'NOC',
    icaoCode: 'EIKN',
    name: 'Connaught',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Connaught',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.910297,
    longitude: -8.818492,
    altitude: 202.692
  },
  {
    iataCode: 'NOE',
    icaoCode: 'EDWS',
    name: 'Norden',
    country: {code: 'DE', name: 'Germany'},
    city: 'Sonderlandeplatz Norden-norddeich',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.632221,
    longitude: 7.191389,
    altitude: 0.9144
  },
  {
    iataCode: 'NOG',
    icaoCode: 'MMNG',
    name: 'Nogales',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Nogales Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 31.226083,
    longitude: -110.975831,
    altitude: 1216.152
  },
  {
    iataCode: 'NOJ',
    icaoCode: 'USRO',
    name: 'Noyabrsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Noyabrsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 63.110079,
    longitude: 75.162243,
    altitude: 6.096
  },
  {
    iataCode: 'NON',
    icaoCode: 'NGTO',
    name: 'Nonouti',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Nonouti Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: -0.616667,
    longitude: 174.366667,
    altitude: 3.048
  },
  {
    iataCode: 'NOS',
    icaoCode: 'FMNN',
    name: 'Nosy-be',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Fascene',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -13.312067,
    longitude: 48.314822,
    altitude: 10.9728
  },
  {
    iataCode: 'NOV',
    icaoCode: 'FNHU',
    name: 'Huambo',
    country: {code: 'AO', name: 'Angola'},
    city: 'Huambo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -12.808878,
    longitude: 15.760547,
    altitude: 1702.9176
  },
  {
    iataCode: 'NOU',
    icaoCode: 'NWWW',
    name: 'Noumea',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'La Tontouta',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -22.014553,
    longitude: 166.212972,
    altitude: 15.8496
  },
  {
    iataCode: 'WAA',
    icaoCode: 'PAIW',
    name: 'Wales',
    country: {code: 'US', name: 'United States'},
    city: 'Wales Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 65.6225,
    longitude: -168.095,
    altitude: 6.7056
  },
  {
    iataCode: 'NOW',
    icaoCode: 'KNOW',
    name: 'Port Angeles',
    country: {code: 'US', name: 'United States'},
    city: 'Port Angeles Cgas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.141481,
    longitude: -123.414075,
    altitude: 3.9624
  },
  {
    iataCode: 'NOZ',
    icaoCode: 'UNWW',
    name: 'Novokuznetsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Spichenkovo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Novokuznetsk',
    utcOffset: 420,
    latitude: 53.8114,
    longitude: 86.8772,
    altitude: 312.1152
  },
  {
    iataCode: 'WAG',
    icaoCode: 'NZWU',
    name: 'Wanganui',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Wanganui',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -39.962222,
    longitude: 175.025278,
    altitude: 8.2296
  },
  {
    iataCode: 'NPA',
    icaoCode: 'KNPA',
    name: 'Pensacola',
    country: {code: 'US', name: 'United States'},
    city: 'Pensacola Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.352656,
    longitude: -87.318647,
    altitude: 8.5344
  },
  {
    iataCode: 'WAI',
    icaoCode: 'FMNW',
    name: 'Antsohihy',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ambalabe',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -14.89875,
    longitude: 47.993894,
    altitude: 28.0416
  },
  {
    iataCode: 'NPE',
    icaoCode: 'NZNR',
    name: 'Napier',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Napier',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -39.465833,
    longitude: 176.87,
    altitude: 1.8288
  },
  {
    iataCode: 'WAM',
    icaoCode: 'FMMZ',
    name: 'Ambatondrazaka',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ambatondrazaka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -17.8,
    longitude: 48.433,
    altitude: 765.9624
  },
  {
    iataCode: 'WAL',
    icaoCode: 'KWAL',
    name: 'Wallops Island',
    country: {code: 'US', name: 'United States'},
    city: 'Wallops Flight Facility',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.940194,
    longitude: -75.466389,
    altitude: 12.192
  },
  {
    iataCode: 'WAQ',
    icaoCode: 'FMMG',
    name: 'Antsalova',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Antsalova Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.7,
    longitude: 44.617,
    altitude: 167.9448
  },
  {
    iataCode: 'NPL',
    icaoCode: 'NZNP',
    name: 'New Plymouth',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'New Plymouth',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -39.008611,
    longitude: 174.179167,
    altitude: 29.5656
  },
  {
    iataCode: 'WAT',
    icaoCode: 'EIWF',
    name: 'Waterford',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Waterford',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 52.1872,
    longitude: -7.086964,
    altitude: 36.2712
  },
  {
    iataCode: 'WAW',
    icaoCode: 'EPWA',
    name: 'Warsaw',
    country: {code: 'PL', name: 'Poland'},
    city: 'Okecie',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 52.16575,
    longitude: 20.967122,
    altitude: 110.3376
  },
  {
    iataCode: 'NQA',
    icaoCode: 'KNQA',
    name: 'Millington',
    country: {code: 'US', name: 'United States'},
    city: 'Millington Rgnl Jetport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.356667,
    longitude: -89.870278,
    altitude: 97.536
  },
  {
    iataCode: 'WBM',
    icaoCode: 'AYWD',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Wapenamanda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.6433,
    longitude: 143.895,
    altitude: 1794.9672
  },
  {
    iataCode: 'FAB',
    icaoCode: 'EGLF',
    name: 'Farnborough',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Farnborough',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.275833,
    longitude: -0.776333,
    altitude: 72.5424
  },
  {
    iataCode: 'FAA',
    icaoCode: 'GUFH',
    name: 'Faranah',
    country: {code: 'GN', name: 'Guinea'},
    city: 'Faranah',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Conakry',
    utcOffset: 0,
    latitude: 10.035467,
    longitude: -10.769825,
    altitude: 449.8848
  },
  {
    iataCode: 'NQI',
    icaoCode: 'KNQI',
    name: 'Kingsville',
    country: {code: 'US', name: 'United States'},
    city: 'Kingsville Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 27.507223,
    longitude: -97.809723,
    altitude: 15.24
  },
  {
    iataCode: 'WBQ',
    icaoCode: 'PAWB',
    name: 'Beaver',
    country: {code: 'US', name: 'United States'},
    city: 'Beaver Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.362222,
    longitude: -147.406667,
    altitude: 109.4232
  },
  {
    iataCode: 'FAF',
    icaoCode: 'KFAF',
    name: 'Fort Eustis',
    country: {code: 'US', name: 'United States'},
    city: 'Felker Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.1325,
    longitude: -76.608841,
    altitude: 3.6576
  },
  {
    iataCode: 'NQN',
    icaoCode: 'SAZN',
    name: 'Neuquen',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Presidente Peron',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -38.949,
    longitude: -68.155711,
    altitude: 272.796
  },
  {
    iataCode: 'FAE',
    icaoCode: 'EKVG',
    name: 'Vagar',
    country: {code: 'FO', name: 'Faroe Islands'},
    city: 'Vagar',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Faroe',
    utcOffset: 0,
    latitude: 62.063628,
    longitude: -7.277219,
    altitude: 85.344
  },
  {
    iataCode: 'FAJ',
    icaoCode: 'TJFA',
    name: 'Fajardo',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Diego Jimenez Torres',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.308889,
    longitude: -65.661861,
    altitude: 19.5072
  },
  {
    iataCode: 'FAI',
    icaoCode: 'PAFA',
    name: 'Fairbanks',
    country: {code: 'US', name: 'United States'},
    city: 'Fairbanks Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.815114,
    longitude: -147.856267,
    altitude: 132.2832
  },
  {
    iataCode: 'NQT',
    icaoCode: 'EGBN',
    name: 'Nottingham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Nottingham Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.92,
    longitude: -1.079167,
    altitude: 42.0624
  },
  {
    iataCode: 'FAN',
    icaoCode: 'ENLI',
    name: 'Farsund',
    country: {code: 'NO', name: 'Norway'},
    city: 'Lista',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 58.099486,
    longitude: 6.62605,
    altitude: 8.8392
  },
  {
    iataCode: 'NQU',
    icaoCode: 'SKNQ',
    name: 'Nuquí',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Reyes Murillo Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 5.7,
    longitude: -77.28,
    altitude: 3.6576
  },
  {
    iataCode: 'NQX',
    icaoCode: 'KNQX',
    name: 'Key West',
    country: {code: 'US', name: 'United States'},
    city: 'Key West Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 24.575834,
    longitude: -81.688889,
    altitude: 1.8288
  },
  {
    iataCode: 'FAO',
    icaoCode: 'LPFR',
    name: 'Faro',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Faro',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Lisbon',
    utcOffset: 0,
    latitude: 37.014425,
    longitude: -7.965911,
    altitude: 7.3152
  },
  {
    iataCode: 'FAR',
    icaoCode: 'KFAR',
    name: 'Fargo',
    country: {code: 'US', name: 'United States'},
    city: 'Hector International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 46.92065,
    longitude: -96.815764,
    altitude: 274.9296
  },
  {
    iataCode: 'NQY',
    icaoCode: 'EGDG',
    name: 'Newquai',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'St Mawgan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.440558,
    longitude: -4.995408,
    altitude: 118.872
  },
  {
    iataCode: 'FAT',
    icaoCode: 'KFAT',
    name: 'Fresno',
    country: {code: 'US', name: 'United States'},
    city: 'Fresno Yosemite Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.776194,
    longitude: -119.71814,
    altitude: 102.4128
  },
  {
    iataCode: 'FAV',
    icaoCode: 'NTGF',
    name: 'Fakarava',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Fakarava',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.05415,
    longitude: -145.656994,
    altitude: 3.9624
  },
  {
    iataCode: 'NRA',
    icaoCode: 'YNAR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Narrandera Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.7022,
    longitude: 146.512,
    altitude: 144.4752
  },
  {
    iataCode: 'WCH',
    icaoCode: 'SCTN',
    name: 'Chaiten',
    country: {code: 'CL', name: 'Chile'},
    city: 'Chaiten',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -42.932808,
    longitude: -72.699114,
    altitude: 3.9624
  },
  {
    iataCode: 'FAY',
    icaoCode: 'KFAY',
    name: 'Fayetteville',
    country: {code: 'US', name: 'United States'},
    city: 'Fayetteville Regional Grannis Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.9912,
    longitude: -78.8803,
    altitude: 57.6072
  },
  {
    iataCode: 'NRD',
    icaoCode: 'EDWY',
    name: 'Norderney',
    country: {code: 'DE', name: 'Germany'},
    city: 'Norderney',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.706822,
    longitude: 7.230247,
    altitude: 1.8288
  },
  {
    iataCode: 'NRK',
    icaoCode: 'ESSP',
    name: 'Norrkoeping',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Kungsangen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.586253,
    longitude: 16.250622,
    altitude: 9.7536
  },
  {
    iataCode: 'FBD',
    icaoCode: 'OAFZ',
    name: 'Faizabad',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Faizabad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 240,
    latitude: 37.1211,
    longitude: 70.5181,
    altitude: 1180.1856
  },
  {
    iataCode: 'NRL',
    icaoCode: 'EGEN',
    name: 'North Ronaldsay',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'North Ronaldsay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.3675,
    longitude: -2.43444,
    altitude: 12.192
  },
  {
    iataCode: 'NRN',
    icaoCode: 'EDLV',
    name: 'Weeze',
    country: {code: 'DE', name: 'Germany'},
    city: 'Niederrhein',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.602222,
    longitude: 6.141944,
    altitude: 32.3088
  },
  {
    iataCode: 'FBK',
    icaoCode: 'PAFB',
    name: 'Fort Wainwright',
    country: {code: 'US', name: 'United States'},
    city: 'Ladd Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.8375,
    longitude: -147.614444,
    altitude: 138.3792
  },
  {
    iataCode: 'FBM',
    icaoCode: 'FZQA',
    name: 'Lubumashi',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Lubumbashi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -11.591333,
    longitude: 27.530889,
    altitude: 1309.116
  },
  {
    iataCode: 'NRT',
    icaoCode: 'RJAA',
    name: 'Tokyo',
    country: {code: 'JP', name: 'Japan'},
    city: 'Narita Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.764722,
    longitude: 140.386389,
    altitude: 42.9768
  },
  {
    iataCode: 'FBU',
    icaoCode: 'ENFB',
    name: 'Oslo',
    country: {code: 'NO', name: 'Norway'},
    city: 'Fornebu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.883333,
    longitude: 10.616667,
    altitude: 17.0688
  },
  {
    iataCode: 'WDH',
    icaoCode: 'FYWV',
    name: 'Windhoek',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Windhoek Hosea Kutako International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -22.486667,
    longitude: 17.4625,
    altitude: 1719.072
  },
  {
    iataCode: 'NSE',
    icaoCode: 'KNSE',
    name: 'Milton',
    country: {code: 'US', name: 'United States'},
    city: 'Whiting Fld Nas North',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.724167,
    longitude: -87.021944,
    altitude: 60.6552
  },
  {
    iataCode: 'NSH',
    icaoCode: 'OINN',
    name: 'Noshahr',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Noshahr Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 36.663333,
    longitude: 51.464722,
    altitude: -18.5928
  },
  {
    iataCode: 'FCA',
    icaoCode: 'KFCA',
    name: 'Kalispell',
    country: {code: 'US', name: 'United States'},
    city: 'Glacier Park Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 48.310472,
    longitude: -114.256,
    altitude: 907.3896
  },
  {
    iataCode: 'NSI',
    icaoCode: 'FKYS',
    name: 'Yaounde',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Yaounde Nsimalen',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 3.722556,
    longitude: 11.553269,
    altitude: 694.3344
  },
  {
    iataCode: 'NSK',
    icaoCode: 'UOOO',
    name: 'Norilsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Alykel',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 69.311053,
    longitude: 87.332183,
    altitude: 181.356
  },
  {
    iataCode: 'NSN',
    icaoCode: 'NZNS',
    name: 'Nelson',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Nelson',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -41.298333,
    longitude: 173.221111,
    altitude: 5.1816
  },
  {
    iataCode: 'NSO',
    icaoCode: 'YSCO',
    name: 'Scone',
    country: {code: 'AU', name: 'Australia'},
    city: 'Scone Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.0372,
    longitude: 150.832,
    altitude: 227.076
  },
  {
    iataCode: 'NST',
    icaoCode: 'VTSF',
    name: 'Nakhon Si Thammarat',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Nakhon Si Thammarat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 8.539617,
    longitude: 99.944725,
    altitude: 3.9624
  },
  {
    iataCode: 'FCO',
    icaoCode: 'LIRF',
    name: 'Rome',
    country: {code: 'IT', name: 'Italy'},
    city: 'Fiumicino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 41.804475,
    longitude: 12.250797,
    altitude: 4.572
  },
  {
    iataCode: 'NSY',
    icaoCode: 'LICZ',
    name: 'Sigonella',
    country: {code: 'IT', name: 'Italy'},
    city: 'Sigonella',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 37.401664,
    longitude: 14.922358,
    altitude: 24.0792
  },
  {
    iataCode: 'FCS',
    icaoCode: 'KFCS',
    name: 'Fort Carson',
    country: {code: 'US', name: 'United States'},
    city: 'Butts Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.678394,
    longitude: -104.756581,
    altitude: 1779.4224
  },
  {
    iataCode: 'WEF',
    icaoCode: 'ZSWF',
    name: 'Weifang',
    country: {code: 'CN', name: 'China'},
    city: 'Weifang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 36.6467,
    longitude: 119.119,
    altitude: 0.0
  },
  {
    iataCode: 'WEI',
    icaoCode: 'YBWP',
    name: 'Weipa',
    country: {code: 'AU', name: 'Australia'},
    city: 'Weipa',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -12.678611,
    longitude: 141.925278,
    altitude: 19.2024
  },
  {
    iataCode: 'WEH',
    icaoCode: 'ZSWH',
    name: 'Weihai',
    country: {code: 'CN', name: 'China'},
    city: 'Weihai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 37.1871,
    longitude: 122.229,
    altitude: 44.196
  },
  {
    iataCode: 'NTE',
    icaoCode: 'LFRS',
    name: 'Nantes',
    country: {code: 'FR', name: 'France'},
    city: 'Nantes Atlantique',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.153189,
    longitude: -1.610725,
    altitude: 27.432
  },
  {
    iataCode: 'NTD',
    icaoCode: 'KNTD',
    name: 'Point Mugu',
    country: {code: 'US', name: 'United States'},
    city: 'Point Mugu Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.120285,
    longitude: -119.12094,
    altitude: 3.9624
  },
  {
    iataCode: 'WEL',
    icaoCode: 'FAWM',
    name: 'Welkom',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Welkom',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -27.998,
    longitude: 26.669586,
    altitude: 1340.8152
  },
  {
    iataCode: 'NTL',
    icaoCode: 'YWLM',
    name: 'Newcastle',
    country: {code: 'AU', name: 'Australia'},
    city: 'Newcastle Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -32.78,
    longitude: 151.83,
    altitude: 9.4488
  },
  {
    iataCode: 'FDF',
    icaoCode: 'TFFF',
    name: 'Fort-de-france',
    country: {code: 'MQ', name: 'Martinique'},
    city: 'Le Lamentin',
    region: '',
    terminal: '',
    timezoneId: 'America/Martinique',
    utcOffset: -240,
    latitude: 14.591033,
    longitude: -61.003175,
    altitude: 4.8768
  },
  {
    iataCode: 'NTN',
    icaoCode: 'YNTN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Normanton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -17.6836,
    longitude: 141.07,
    altitude: 22.2504
  },
  {
    iataCode: 'NTQ',
    icaoCode: 'RJNW',
    name: 'Wajima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Noto',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 37.293097,
    longitude: 136.961853,
    altitude: 218.8464
  },
  {
    iataCode: 'FDH',
    icaoCode: 'EDNY',
    name: 'Friedrichshafen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Friedrichshafen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 47.671317,
    longitude: 9.511486,
    altitude: 416.6616
  },
  {
    iataCode: 'NTR',
    icaoCode: 'MMAN',
    name: 'Monterrey',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Del Norte Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 25.865572,
    longitude: -100.237239,
    altitude: 449.8848
  },
  {
    iataCode: 'NTU',
    icaoCode: 'KNTU',
    name: 'Oceana',
    country: {code: 'US', name: 'United States'},
    city: 'Oceana Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.820703,
    longitude: -76.033542,
    altitude: 6.7056
  },
  {
    iataCode: 'NTT',
    icaoCode: 'NFTP',
    name: 'Niuatoputapu',
    country: {code: 'TO', name: 'Tonga'},
    city: 'Kuini Lavenia Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tongatapu',
    utcOffset: 780,
    latitude: -15.9767,
    longitude: -173.755,
    altitude: 0.0
  },
  {
    iataCode: 'NTY',
    icaoCode: 'FAPN',
    name: 'Pilanesberg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Pilanesberg Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.333822,
    longitude: 27.173358,
    altitude: 1039.9776
  },
  {
    iataCode: 'NTX',
    icaoCode: 'WION',
    name: 'Ranai-natuna Besar Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Ranai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 3.908714,
    longitude: 108.387897,
    altitude: 2.1336
  },
  {
    iataCode: 'FDU',
    icaoCode: 'FZBO',
    name: 'Bandoundu',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Bandundu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -3.311319,
    longitude: 17.381683,
    altitude: 320.9544
  },
  {
    iataCode: 'WFI',
    icaoCode: 'FMSF',
    name: 'Fianarantsoa',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Fianarantsoa',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -21.441558,
    longitude: 47.111736,
    altitude: 1114.9584
  },
  {
    iataCode: 'WFK',
    icaoCode: 'KFVE',
    name: 'Frenchville',
    country: {code: 'US', name: 'United States'},
    city: 'Northern Aroostook Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 47.285556,
    longitude: -68.312778,
    altitude: 301.1424
  },
  {
    iataCode: 'NUE',
    icaoCode: 'EDDN',
    name: 'Nuernberg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Nurnberg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.4987,
    longitude: 11.066897,
    altitude: 318.8208
  },
  {
    iataCode: 'NUI',
    icaoCode: 'PAQT',
    name: 'Nuiqsut',
    country: {code: 'US', name: 'United States'},
    city: 'Nuiqsut Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.21,
    longitude: -151.005556,
    altitude: 11.5824
  },
  {
    iataCode: 'NUL',
    icaoCode: 'PANU',
    name: 'Nulato',
    country: {code: 'US', name: 'United States'},
    city: 'Nulato Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.729444,
    longitude: -158.074167,
    altitude: 121.6152
  },
  {
    iataCode: 'FEG',
    icaoCode: 'UTKF',
    name: 'Fergana',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Fergana Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tashkent',
    utcOffset: 300,
    latitude: 40.3588,
    longitude: 71.745,
    altitude: 603.504
  },
  {
    iataCode: 'NUQ',
    icaoCode: 'KNUQ',
    name: 'Mountain View',
    country: {code: 'US', name: 'United States'},
    city: 'Moffett Federal Afld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.416142,
    longitude: -122.049139,
    altitude: 9.7536
  },
  {
    iataCode: 'FEL',
    icaoCode: 'ETSF',
    name: 'Fuerstenfeldbruck',
    country: {code: 'DE', name: 'Germany'},
    city: 'Furstenfeldbruck',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.205667,
    longitude: 11.267,
    altitude: 519.0744
  },
  {
    iataCode: 'NUS',
    icaoCode: 'NVSP',
    name: 'Norsup',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Norsup Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.0797,
    longitude: 167.401,
    altitude: 7.0104
  },
  {
    iataCode: 'FEN',
    icaoCode: 'SBFN',
    name: 'Fernando Do Noronha',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Fernando De Noronha',
    region: '',
    terminal: '',
    timezoneId: 'America/Noronha',
    utcOffset: -120,
    latitude: -3.854928,
    longitude: -32.423336,
    altitude: 58.8264
  },
  {
    iataCode: 'WGA',
    icaoCode: 'YSWG',
    name: 'Wagga Wagga',
    country: {code: 'AU', name: 'Australia'},
    city: 'Wagga Wagga',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -35.165278,
    longitude: 147.466389,
    altitude: 220.6752
  },
  {
    iataCode: 'NUW',
    icaoCode: 'KNUW',
    name: 'Whidbey Island',
    country: {code: 'US', name: 'United States'},
    city: 'Whidbey Island Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.351803,
    longitude: -122.655906,
    altitude: 14.3256
  },
  {
    iataCode: 'WGE',
    icaoCode: 'YWLG',
    name: 'Walgett',
    country: {code: 'AU', name: 'Australia'},
    city: 'Walgett Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -30.0318,
    longitude: 148.1222,
    altitude: 133.8072
  },
  {
    iataCode: 'NVA',
    icaoCode: 'SKNV',
    name: 'Neiva',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Benito Salas',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.95015,
    longitude: -75.294,
    altitude: 446.2272
  },
  {
    iataCode: 'FEZ',
    icaoCode: 'GMFF',
    name: 'Fez',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Saiss',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 33.927261,
    longitude: -4.977958,
    altitude: 579.12
  },
  {
    iataCode: 'FFA',
    icaoCode: 'KFFA',
    name: 'Kill Devil Hills',
    country: {code: 'US', name: 'United States'},
    city: 'First Flight Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 36.02,
    longitude: -75.67,
    altitude: 3.9624
  },
  {
    iataCode: 'NVI',
    icaoCode: 'UTSA',
    name: 'Navoi',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Navoi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 40.1172,
    longitude: 65.1708,
    altitude: 0.0
  },
  {
    iataCode: 'WGP',
    icaoCode: 'WADW',
    name: 'Waingapu',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Mau Hau',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -9.669217,
    longitude: 120.302006,
    altitude: 10.0584
  },
  {
    iataCode: 'NVK',
    icaoCode: 'ENNK',
    name: 'Narvik',
    country: {code: 'NO', name: 'Norway'},
    city: 'Framnes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 68.435833,
    longitude: 17.388056,
    altitude: 29.5656
  },
  {
    iataCode: 'FFD',
    icaoCode: 'EGVA',
    name: 'Fairford',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Fairford',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.682167,
    longitude: -1.790028,
    altitude: 87.1728
  },
  {
    iataCode: 'NVP',
    icaoCode: 'SWNA',
    name: 'Novo Aripuana',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Novo Aripuana Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -5.121389,
    longitude: -60.380556,
    altitude: 152.4
  },
  {
    iataCode: 'NVS',
    icaoCode: 'LFQG',
    name: 'Nevers',
    country: {code: 'FR', name: 'France'},
    city: 'Fourchambault',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.002625,
    longitude: 3.113333,
    altitude: 183.4896
  },
  {
    iataCode: 'NVT',
    icaoCode: 'SBNF',
    name: 'Navegantes',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Ministro Victor Konder Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -26.879999,
    longitude: -48.65139,
    altitude: 5.4864
  },
  {
    iataCode: 'FFO',
    icaoCode: 'KFFO',
    name: 'Dayton',
    country: {code: 'US', name: 'United States'},
    city: 'Wright Patterson Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.826111,
    longitude: -84.048332,
    altitude: 250.8504
  },
  {
    iataCode: 'WHF',
    icaoCode: 'HSSW',
    name: 'Wadi Halfa',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Wadi Halfa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 180,
    latitude: 21.800278,
    longitude: 31.516389,
    altitude: 457.2
  },
  {
    iataCode: 'NWA',
    icaoCode: 'FMCI',
    name: 'Moheli',
    country: {code: 'KM', name: 'Comoros'},
    city: 'Bandaressalam',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Comoro',
    utcOffset: 180,
    latitude: -12.298108,
    longitude: 43.7664,
    altitude: 14.0208
  },
  {
    iataCode: 'WHK',
    icaoCode: 'NZWK',
    name: 'Whakatane',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Whakatane',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -37.920556,
    longitude: 176.914167,
    altitude: 6.096
  },
  {
    iataCode: 'NWI',
    icaoCode: 'EGSH',
    name: 'Norwich',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Norwich',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 52.675833,
    longitude: 1.282778,
    altitude: 35.6616
  },
  {
    iataCode: 'FGI',
    icaoCode: 'NSFI',
    name: 'Apia',
    country: {code: 'WS', name: 'Samoa'},
    city: "Fagali\\\\'i",
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Apia',
    utcOffset: -660,
    latitude: -13.848611,
    longitude: -171.740833,
    altitude: 7.62
  },
  {
    iataCode: 'WIC',
    icaoCode: 'EGPC',
    name: 'Wick',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Wick',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 58.458889,
    longitude: -3.093056,
    altitude: 38.4048
  },
  {
    iataCode: 'WIL',
    icaoCode: 'HKNW',
    name: 'Nairobi',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Nairobi Wilson',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -1.321719,
    longitude: 36.814833,
    altitude: 1690.4208
  },
  {
    iataCode: 'WIN',
    icaoCode: 'YWTN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Winton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -22.3636,
    longitude: 143.086,
    altitude: 194.4624
  },
  {
    iataCode: 'NXP',
    icaoCode: 'KNXP',
    name: 'Twenty Nine Palms',
    country: {code: 'US', name: 'United States'},
    city: 'Twentynine Palms Eaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.296161,
    longitude: -116.162203,
    altitude: 625.1448
  },
  {
    iataCode: 'NXX',
    icaoCode: 'KNXX',
    name: 'Willow Grove',
    country: {code: 'US', name: 'United States'},
    city: 'Willow Grove Nas Jrb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.199833,
    longitude: -75.148167,
    altitude: 109.1184
  },
  {
    iataCode: 'FHU',
    icaoCode: 'KFHU',
    name: 'Fort Huachuca',
    country: {code: 'US', name: 'United States'},
    city: 'Sierra Vista Muni Libby Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 31.588472,
    longitude: -110.344389,
    altitude: 1438.3512
  },
  {
    iataCode: 'NYA',
    icaoCode: 'USHN',
    name: 'Nyagan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Nyagan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 62.11,
    longitude: 65.615,
    altitude: 110.0328
  },
  {
    iataCode: 'NYG',
    icaoCode: 'KNYG',
    name: 'Quantico',
    country: {code: 'US', name: 'United States'},
    city: 'Quantico Mcaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.501683,
    longitude: -77.305333,
    altitude: 3.3528
  },
  {
    iataCode: 'WJR',
    icaoCode: 'HKWJ',
    name: 'Wajir',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Wajir',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: 1.733239,
    longitude: 40.091606,
    altitude: 234.696
  },
  {
    iataCode: 'NYI',
    icaoCode: 'DGSN',
    name: 'Sunyani',
    country: {code: 'GH', name: 'Ghana'},
    city: 'Sunyani',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Accra',
    utcOffset: 0,
    latitude: 7.361828,
    longitude: -2.328756,
    altitude: 309.0672
  },
  {
    iataCode: 'NYK',
    icaoCode: 'HKNY',
    name: 'Nanyuki',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Nanyuki Civil Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -0.067,
    longitude: 37.033,
    altitude: 1905.0
  },
  {
    iataCode: 'FIE',
    icaoCode: 'EGEF',
    name: 'Fair Isle',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Fair Isle Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.5358,
    longitude: -1.62806,
    altitude: 67.9704
  },
  {
    iataCode: 'NYM',
    icaoCode: 'USMM',
    name: 'Nadym',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Nadym Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 65.4809,
    longitude: 72.6989,
    altitude: 14.9352
  },
  {
    iataCode: 'WJU',
    icaoCode: 'RKNW',
    name: 'Wonju',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Wonju Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.438081,
    longitude: 127.960383,
    altitude: 100.2792
  },
  {
    iataCode: 'FIH',
    icaoCode: 'FZAA',
    name: 'Kinshasa',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Ndjili Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -4.38575,
    longitude: 15.444569,
    altitude: 313.0296
  },
  {
    iataCode: 'FIG',
    icaoCode: 'GUFA',
    name: 'Fira',
    country: {code: 'GN', name: 'Guinea'},
    city: 'Fria',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Conakry',
    utcOffset: 0,
    latitude: 10.350556,
    longitude: -13.569167,
    altitude: 152.0952
  },
  {
    iataCode: 'NYO',
    icaoCode: 'ESKN',
    name: 'Stockholm',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Skavsta',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 58.788636,
    longitude: 16.912189,
    altitude: 42.672
  },
  {
    iataCode: 'NYU',
    icaoCode: 'VYBR',
    name: 'Nyuang U',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Bagan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 21.173833,
    longitude: 94.924667,
    altitude: 88.392
  },
  {
    iataCode: 'WKA',
    icaoCode: 'NZWF',
    name: 'Wanaka',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Wanaka',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -44.722222,
    longitude: 169.245556,
    altitude: 348.0816
  },
  {
    iataCode: 'NZA',
    icaoCode: 'SPZA',
    name: 'Nazca',
    country: {code: 'PE', name: 'Peru'},
    city: 'Maria Reiche Neuman Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -14.854192,
    longitude: -74.961811,
    altitude: 566.928
  },
  {
    iataCode: 'FIZ',
    icaoCode: 'YFTZ',
    name: 'Fitzroy Crossing',
    country: {code: 'AU', name: 'Australia'},
    city: 'Fitzroy Crossing Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -18.178,
    longitude: 125.591,
    altitude: 113.9952
  },
  {
    iataCode: 'NZC',
    icaoCode: 'KVQQ',
    name: 'Jacksonville',
    country: {code: 'US', name: 'United States'},
    city: 'Cecil Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.2187,
    longitude: -81.8767,
    altitude: 24.6888
  },
  {
    iataCode: 'WKK',
    icaoCode: '5A8',
    name: 'Aleknagik',
    country: {code: 'US', name: 'United States'},
    city: 'Aleknagik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.2826,
    longitude: -158.618,
    altitude: 20.1168
  },
  {
    iataCode: 'WKJ',
    icaoCode: 'RJCW',
    name: 'Wakkanai',
    country: {code: 'JP', name: 'Japan'},
    city: 'Wakkanai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 45.404167,
    longitude: 141.800833,
    altitude: 9.144
  },
  {
    iataCode: 'WKM',
    icaoCode: 'FVWN',
    name: 'Hwange National Park',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Hwange National Park',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -18.629872,
    longitude: 27.021042,
    altitude: 1079.9064
  },
  {
    iataCode: 'WKL',
    icaoCode: 'HI07',
    name: 'Waikoloa Village',
    country: {code: 'US', name: 'United States'},
    city: 'Waikoloa Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 19.9136,
    longitude: -155.864,
    altitude: 33.2232
  },
  {
    iataCode: 'NZJ',
    icaoCode: 'KNZJ',
    name: 'Santa Ana',
    country: {code: 'US', name: 'United States'},
    city: 'El Toro',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.676132,
    longitude: -117.731164,
    altitude: 116.7384
  },
  {
    iataCode: 'NZY',
    icaoCode: 'KNZY',
    name: 'San Diego',
    country: {code: 'US', name: 'United States'},
    city: 'North Island Nas',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.699219,
    longitude: -117.21531,
    altitude: 7.9248
  },
  {
    iataCode: 'FJR',
    icaoCode: 'OMFJ',
    name: 'Fujeirah',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Fujairah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 25.112225,
    longitude: 56.323964,
    altitude: 46.3296
  },
  {
    iataCode: 'WLH',
    icaoCode: 'NVSW',
    name: 'Walaha',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Walaha Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.412,
    longitude: 167.691,
    altitude: 46.0248
  },
  {
    iataCode: 'WLG',
    icaoCode: 'NZWN',
    name: 'Wellington',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Wellington Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -41.327221,
    longitude: 174.805278,
    altitude: 12.4968
  },
  {
    iataCode: 'WLK',
    icaoCode: 'PASK',
    name: 'Selawik',
    country: {code: 'US', name: 'United States'},
    city: 'Selawik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.6,
    longitude: -159.985833,
    altitude: 5.1816
  },
  {
    iataCode: 'FKB',
    icaoCode: 'EDSB',
    name: 'Karlsruhe-baden',
    country: {code: 'DE', name: 'Germany'},
    city: 'Baden Airpark',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.467,
    longitude: 8.043,
    altitude: 124.0536
  },
  {
    iataCode: 'WLS',
    icaoCode: 'NLWW',
    name: 'Wallis',
    country: {code: 'WF', name: 'Wallis and Futuna'},
    city: 'Wallis',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Wallis',
    utcOffset: 720,
    latitude: -13.238281,
    longitude: -176.199228,
    altitude: 24.0792
  },
  {
    iataCode: 'FKI',
    icaoCode: 'FZIA',
    name: 'Kisangani',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kisangani Simisini',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: 0.5175,
    longitude: 25.155014,
    altitude: 392.8872
  },
  {
    iataCode: 'FKL',
    icaoCode: 'KFKL',
    name: 'Franklin',
    country: {code: 'US', name: 'United States'},
    city: 'Franklin',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.377874,
    longitude: -79.860362,
    altitude: 469.392
  },
  {
    iataCode: 'WMA',
    icaoCode: 'FMNX',
    name: 'Mandritsara',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Mandritsara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -15.817,
    longitude: 48.833,
    altitude: 306.9336
  },
  {
    iataCode: 'FKQ',
    icaoCode: 'WASF',
    name: 'Fak Fak',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Fak Fak',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -2.920192,
    longitude: 132.267031,
    altitude: 140.8176
  },
  {
    iataCode: 'WME',
    icaoCode: 'YMNE',
    name: 'Mount Keith',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mount Keith',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -27.286389,
    longitude: 120.554722,
    altitude: 546.2016
  },
  {
    iataCode: 'FKS',
    icaoCode: 'RJSF',
    name: '',
    country: {code: 'JP', name: 'Japan'},
    city: 'Fukushima Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 37.2274,
    longitude: 140.431,
    altitude: 372.1608
  },
  {
    iataCode: 'WMO',
    icaoCode: 'PAWM',
    name: 'White Mountain',
    country: {code: 'US', name: 'United States'},
    city: 'White Mountain Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 64.689167,
    longitude: -163.412778,
    altitude: 81.3816
  },
  {
    iataCode: 'WMN',
    icaoCode: 'FMNR',
    name: 'Maroantsetra',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Maroantsetra',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -15.436666,
    longitude: 49.688332,
    altitude: 3.9624
  },
  {
    iataCode: 'FLA',
    icaoCode: 'SKFL',
    name: 'Florencia',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Gustavo Artunduaga Paredes',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 1.589189,
    longitude: -75.564372,
    altitude: 244.7544
  },
  {
    iataCode: 'WMP',
    icaoCode: 'FMNP',
    name: 'Mampikony',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Mampikony Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -16.049,
    longitude: 47.622,
    altitude: 0.0
  },
  {
    iataCode: 'WMR',
    icaoCode: 'FMNC',
    name: 'Mananara',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Avaratra',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -16.1639,
    longitude: 49.773753,
    altitude: 2.7432
  },
  {
    iataCode: 'FLG',
    icaoCode: 'KFLG',
    name: 'Flagstaff',
    country: {code: 'US', name: 'United States'},
    city: 'Flagstaff Pulliam Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: 480,
    latitude: 35.140318,
    longitude: -111.669239,
    altitude: 2138.172
  },
  {
    iataCode: 'WMX',
    icaoCode: 'WAJW',
    name: 'Wamena',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Wamena',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -4.102511,
    longitude: 138.957372,
    altitude: 1549.908
  },
  {
    iataCode: 'FLL',
    icaoCode: 'KFLL',
    name: 'Fort Lauderdale',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Lauderdale Hollywood Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.072583,
    longitude: -80.15275,
    altitude: 2.7432
  },
  {
    iataCode: 'FLO',
    icaoCode: 'KFLO',
    name: 'Florence',
    country: {code: 'US', name: 'United States'},
    city: 'Florence Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.185361,
    longitude: -79.723889,
    altitude: 44.5008
  },
  {
    iataCode: 'FLN',
    icaoCode: 'SBFL',
    name: 'Florianopolis',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Hercilio Luz',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -27.670489,
    longitude: -48.547181,
    altitude: 6.096
  },
  {
    iataCode: 'FLS',
    icaoCode: 'YFLI',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Flinders Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -40.0917,
    longitude: 147.993,
    altitude: 3.048
  },
  {
    iataCode: 'FLR',
    icaoCode: 'LIRQ',
    name: 'Firenze',
    country: {code: 'IT', name: 'Italy'},
    city: 'Firenze',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 43.809953,
    longitude: 11.2051,
    altitude: 43.2816
  },
  {
    iataCode: 'FLW',
    icaoCode: 'LPFL',
    name: 'Flores',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Flores',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 39.455272,
    longitude: -31.131361,
    altitude: 34.1376
  },
  {
    iataCode: 'FLV',
    icaoCode: 'KFLV',
    name: 'Fort Leavenworth',
    country: {code: 'US', name: 'United States'},
    city: 'Sherman Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.368332,
    longitude: -94.914686,
    altitude: 235.3056
  },
  {
    iataCode: 'WNN',
    icaoCode: 'CKL3',
    name: 'Wunnumin Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wunnumin Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -300,
    latitude: 52.8939,
    longitude: -89.2892,
    altitude: 249.6312
  },
  {
    iataCode: 'WNP',
    icaoCode: 'RPUN',
    name: 'Naga',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Naga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 13.584886,
    longitude: 123.270239,
    altitude: 43.2816
  },
  {
    iataCode: 'WNR',
    icaoCode: 'YWDH',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Windorah Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -25.4131,
    longitude: 142.667,
    altitude: 137.7696
  },
  {
    iataCode: 'FMA',
    icaoCode: 'SARF',
    name: 'Formosa',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Formosa',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -26.212722,
    longitude: -58.228111,
    altitude: 59.1312
  },
  {
    iataCode: 'WNS',
    icaoCode: 'OPNH',
    name: 'Nawabshah',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Nawabshah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 26.219442,
    longitude: 68.390053,
    altitude: 28.956
  },
  {
    iataCode: 'FME',
    icaoCode: 'KFME',
    name: 'Fort Meade',
    country: {code: 'US', name: 'United States'},
    city: 'Tipton',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -300,
    latitude: 39.085386,
    longitude: -76.759414,
    altitude: 45.72
  },
  {
    iataCode: 'FMH',
    icaoCode: 'KFMH',
    name: 'Falmouth',
    country: {code: 'US', name: 'United States'},
    city: 'Otis Angb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.658439,
    longitude: -70.521417,
    altitude: 39.9288
  },
  {
    iataCode: 'WNZ',
    icaoCode: 'ZSWZ',
    name: 'Wenzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Wenzhou Yongqiang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 27.9122,
    longitude: 120.852,
    altitude: 0.0
  },
  {
    iataCode: 'FMI',
    icaoCode: 'FZRF',
    name: 'Kalemie',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Kalemie',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -5.875556,
    longitude: 29.25,
    altitude: 783.0312
  },
  {
    iataCode: 'FMN',
    icaoCode: 'KFMN',
    name: 'Farmington',
    country: {code: 'US', name: 'United States'},
    city: 'Four Corners Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 36.74125,
    longitude: -108.229944,
    altitude: 1678.2288
  },
  {
    iataCode: 'FMM',
    icaoCode: 'EDJA',
    name: 'Memmingen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Allgau',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 47.988758,
    longitude: 10.2395,
    altitude: 633.0696
  },
  {
    iataCode: 'FMO',
    icaoCode: 'EDDG',
    name: 'Muenster/osnabrueck',
    country: {code: 'DE', name: 'Germany'},
    city: 'Munster Osnabruck',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.134642,
    longitude: 7.684831,
    altitude: 48.768
  },
  {
    iataCode: 'WOE',
    icaoCode: 'EHWO',
    name: 'Woensdrecht',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Woensdrecht',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 51.449092,
    longitude: 4.342031,
    altitude: 19.2024
  },
  {
    iataCode: 'FMY',
    icaoCode: 'KFMY',
    name: 'Fort Myers',
    country: {code: 'US', name: 'United States'},
    city: 'Page Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.586611,
    longitude: -81.86325,
    altitude: 5.1816
  },
  {
    iataCode: 'WOL',
    icaoCode: 'YWOL',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Wollongong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.5611,
    longitude: 150.789,
    altitude: 9.4488
  },
  {
    iataCode: 'FNA',
    icaoCode: 'GFLL',
    name: 'Freetown',
    country: {code: 'SL', name: 'Sierra Leone'},
    city: 'Freetown Lungi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Freetown',
    utcOffset: 0,
    latitude: 8.616444,
    longitude: -13.195489,
    altitude: 25.6032
  },
  {
    iataCode: 'FNC',
    icaoCode: 'LPMA',
    name: 'Funchal',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Madeira',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Madeira',
    utcOffset: 0,
    latitude: 32.697889,
    longitude: -16.774453,
    altitude: 58.5216
  },
  {
    iataCode: 'WOT',
    icaoCode: 'RCWA',
    name: 'Wang An',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Wang An',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 23.370833,
    longitude: 119.494444,
    altitude: 35.052
  },
  {
    iataCode: 'FNI',
    icaoCode: 'LFTW',
    name: 'Nimes',
    country: {code: 'FR', name: 'France'},
    city: 'Garons',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.757444,
    longitude: 4.416347,
    altitude: 94.1832
  },
  {
    iataCode: 'FNJ',
    icaoCode: 'ZKPY',
    name: 'Pyongyang',
    country: {code: 'KP', name: "Democratic People's Republic of Korea"},
    city: 'Pyongyang Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pyongyang',
    utcOffset: 540,
    latitude: 39.224061,
    longitude: 125.67015,
    altitude: 35.6616
  },
  {
    iataCode: 'FNL',
    icaoCode: 'KFNL',
    name: 'F',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Collins Loveland Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 40.451828,
    longitude: -105.011336,
    altitude: 1528.8768
  },
  {
    iataCode: 'WPB',
    icaoCode: 'FMNG',
    name: 'Port Bergé',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Port Bergé Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -15.583,
    longitude: 47.617,
    altitude: 64.9224
  },
  {
    iataCode: 'FNR',
    icaoCode: 'PANR',
    name: 'Funter Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Funter Bay Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 58.254444,
    longitude: -134.897778,
    altitude: 0.0
  },
  {
    iataCode: 'FNT',
    icaoCode: 'KFNT',
    name: 'Flint',
    country: {code: 'US', name: 'United States'},
    city: 'Bishop International',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.965424,
    longitude: -83.743629,
    altitude: 238.3536
  },
  {
    iataCode: 'FOD',
    icaoCode: 'KFOD',
    name: 'Fort Dodge',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Dodge Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.5512,
    longitude: -94.191842,
    altitude: 352.6536
  },
  {
    iataCode: 'FOC',
    icaoCode: 'ZSFZ',
    name: 'Fuzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Changle',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 25.935064,
    longitude: 119.663272,
    altitude: 14.0208
  },
  {
    iataCode: 'FOE',
    icaoCode: 'KFOE',
    name: 'Topeka',
    country: {code: 'US', name: 'United States'},
    city: 'Forbes Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.950944,
    longitude: -95.663611,
    altitude: 328.5744
  },
  {
    iataCode: 'FOG',
    icaoCode: 'LIBF',
    name: 'Foggia',
    country: {code: 'IT', name: 'Italy'},
    city: 'Gino Lisa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 41.432917,
    longitude: 15.535028,
    altitude: 81.0768
  },
  {
    iataCode: 'FOK',
    icaoCode: 'KFOK',
    name: 'West Hampton Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Francis S Gabreski',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.843656,
    longitude: -72.631789,
    altitude: 20.4216
  },
  {
    iataCode: 'FON',
    icaoCode: 'MRAN',
    name: 'La Fortuna/san Carlos',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Arenal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.478,
    longitude: -84.6345,
    altitude: 104.2416
  },
  {
    iataCode: 'FOM',
    icaoCode: 'FKKM',
    name: 'Foumban',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Foumban Nkounja',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 5.636919,
    longitude: 10.750817,
    altitude: 1207.9224
  },
  {
    iataCode: 'FOR',
    icaoCode: 'SBFZ',
    name: 'Fortaleza',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Pinto Martins Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Fortaleza',
    utcOffset: -180,
    latitude: -3.776283,
    longitude: -38.532556,
    altitude: 24.9936
  },
  {
    iataCode: 'OAG',
    icaoCode: 'YORG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Orange Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.3817,
    longitude: 149.133,
    altitude: 949.452
  },
  {
    iataCode: 'OAK',
    icaoCode: 'KOAK',
    name: 'Oakland',
    country: {code: 'US', name: 'United States'},
    city: 'Metropolitan Oakland Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.721278,
    longitude: -122.220722,
    altitude: 2.7432
  },
  {
    iataCode: 'OAJ',
    icaoCode: 'KOAJ',
    name: 'Jacksonville Nc',
    country: {code: 'US', name: 'United States'},
    city: 'Albert J Ellis',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.829164,
    longitude: -77.612139,
    altitude: 28.6512
  },
  {
    iataCode: 'OAM',
    icaoCode: 'NZOU',
    name: 'Oamaru',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Oamaru',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -44.97,
    longitude: 171.081667,
    altitude: 30.1752
  },
  {
    iataCode: 'OAR',
    icaoCode: 'KOAR',
    name: 'Fort Ord',
    country: {code: 'US', name: 'United States'},
    city: 'Marina Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.681878,
    longitude: -121.762347,
    altitude: 40.8432
  },
  {
    iataCode: 'FPO',
    icaoCode: 'MYGF',
    name: 'Freeport',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Grand Bahama Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 26.558686,
    longitude: -78.695553,
    altitude: 2.1336
  },
  {
    iataCode: 'WRB',
    icaoCode: 'KWRB',
    name: 'Macon',
    country: {code: 'US', name: 'United States'},
    city: 'Robins Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.640144,
    longitude: -83.59185,
    altitude: 89.6112
  },
  {
    iataCode: 'OAX',
    icaoCode: 'MMOX',
    name: 'Oaxaca',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Xoxocotlan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 16.999906,
    longitude: -96.726639,
    altitude: 1520.6472
  },
  {
    iataCode: 'WRE',
    icaoCode: 'NZWR',
    name: 'Whangarei',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Whangarei',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -35.768333,
    longitude: 174.365,
    altitude: 40.5384
  },
  {
    iataCode: 'WRG',
    icaoCode: 'PAWG',
    name: 'Wrangell',
    country: {code: 'US', name: 'United States'},
    city: 'Wrangell Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sitka',
    utcOffset: -540,
    latitude: 56.4843,
    longitude: -132.37,
    altitude: 13.4112
  },
  {
    iataCode: 'WRI',
    icaoCode: 'KWRI',
    name: 'Wrightstown',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Guire Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.015556,
    longitude: -74.591667,
    altitude: 39.9288
  },
  {
    iataCode: 'WRL',
    icaoCode: 'KWRL',
    name: 'Worland',
    country: {code: 'US', name: 'United States'},
    city: 'Worland Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 43.9657,
    longitude: -107.951,
    altitude: 1288.3896
  },
  {
    iataCode: 'OBF',
    icaoCode: 'EDMO',
    name: 'Oberpfaffenhofen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Oberpfaffenhofen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.081364,
    longitude: 11.283067,
    altitude: 593.4456
  },
  {
    iataCode: 'WRO',
    icaoCode: 'EPWR',
    name: 'Wroclaw',
    country: {code: 'PL', name: 'Poland'},
    city: 'Strachowice',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 51.102683,
    longitude: 16.885836,
    altitude: 123.1392
  },
  {
    iataCode: 'OBN',
    icaoCode: 'EGEO',
    name: 'North Connel',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Oban Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 56.464,
    longitude: -5.4,
    altitude: 7.0104
  },
  {
    iataCode: 'OBO',
    icaoCode: 'RJCB',
    name: 'Obihiro',
    country: {code: 'JP', name: 'Japan'},
    city: 'Obihiro',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 42.733333,
    longitude: 143.217222,
    altitude: 153.924
  },
  {
    iataCode: 'WRZ',
    icaoCode: 'VCCW',
    name: 'Wirawila',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Wirawila Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 6.254494,
    longitude: 81.235189,
    altitude: 15.24
  },
  {
    iataCode: 'WRY',
    icaoCode: 'EGEW',
    name: 'Westray',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Westray Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.3503,
    longitude: -2.95,
    altitude: 8.8392
  },
  {
    iataCode: 'OBS',
    icaoCode: 'LFHO',
    name: 'Aubenas-vals-lanas',
    country: {code: 'FR', name: 'France'},
    city: 'Ardeche Meridionale',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.544236,
    longitude: 4.372192,
    altitude: 281.3304
  },
  {
    iataCode: 'OBU',
    icaoCode: 'PAOB',
    name: 'Kobuk',
    country: {code: 'US', name: 'United States'},
    city: 'Kobuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.912222,
    longitude: -156.897222,
    altitude: 41.7576
  },
  {
    iataCode: 'WSD',
    icaoCode: 'KWSD',
    name: 'White Sands',
    country: {code: 'US', name: 'United States'},
    city: 'Condron Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.341484,
    longitude: -106.40277,
    altitude: 1199.0832
  },
  {
    iataCode: 'OCC',
    icaoCode: 'SECO',
    name: 'Coca',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Francisco De Orellana',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -0.462886,
    longitude: -76.986842,
    altitude: 254.2032
  },
  {
    iataCode: 'WSN',
    icaoCode: 'PFWS',
    name: 'South Naknek',
    country: {code: 'US', name: 'United States'},
    city: 'South Naknek Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 58.702222,
    longitude: -157.0025,
    altitude: 49.3776
  },
  {
    iataCode: 'FRA',
    icaoCode: 'EDDF',
    name: 'Frankfurt',
    country: {code: 'DE', name: 'Germany'},
    city: 'Frankfurt Main',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.026421,
    longitude: 8.543125,
    altitude: 110.9472
  },
  {
    iataCode: 'WSP',
    icaoCode: 'MNWP',
    name: 'Waspam',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Waspam Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 14.737778,
    longitude: -83.975833,
    altitude: 35.052
  },
  {
    iataCode: 'FRC',
    icaoCode: 'SIMK',
    name: 'Franca',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Franca Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -20.538611,
    longitude: -47.400833,
    altitude: 316.992
  },
  {
    iataCode: 'OCJ',
    icaoCode: 'MKBS',
    name: 'Ocho Rios',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Boscobel',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 18.404247,
    longitude: -76.969017,
    altitude: 27.432
  },
  {
    iataCode: 'FRE',
    icaoCode: 'AGGF',
    name: 'Fera Island',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Fera/maringe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.1075,
    longitude: 159.577,
    altitude: 0.0
  },
  {
    iataCode: 'FRD',
    icaoCode: 'KFHR',
    name: 'Friday Harbor',
    country: {code: 'US', name: 'United States'},
    city: 'Friday Harbor Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 48.521944,
    longitude: -123.024444,
    altitude: 34.4424
  },
  {
    iataCode: 'WST',
    icaoCode: 'EIWT',
    name: 'Leixlip',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Weston Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.351333,
    longitude: -6.4875,
    altitude: 45.72
  },
  {
    iataCode: 'FRI',
    icaoCode: 'KFRI',
    name: 'Fort Riley',
    country: {code: 'US', name: 'United States'},
    city: 'Marshall Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 39.055275,
    longitude: -96.764453,
    altitude: 324.0024
  },
  {
    iataCode: 'WSY',
    icaoCode: 'YWHI',
    name: 'Airlie Beach',
    country: {code: 'AU', name: 'Australia'},
    city: 'Whitsunday Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.276,
    longitude: 148.755,
    altitude: 18.288
  },
  {
    iataCode: 'FRJ',
    icaoCode: 'LFTU',
    name: 'Frejus',
    country: {code: 'FR', name: 'France'},
    city: 'Frejus Saint Raphael',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.416667,
    longitude: 6.733333,
    altitude: 2.1336
  },
  {
    iataCode: 'WSZ',
    icaoCode: 'NZWS',
    name: 'Westport',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Westport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -41.738056,
    longitude: 171.580833,
    altitude: 3.9624
  },
  {
    iataCode: 'FRL',
    icaoCode: 'LIPK',
    name: 'Forli',
    country: {code: 'IT', name: 'Italy'},
    city: 'Forli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.194753,
    longitude: 12.070094,
    altitude: 29.5656
  },
  {
    iataCode: 'FRO',
    icaoCode: 'ENFL',
    name: 'Floro',
    country: {code: 'NO', name: 'Norway'},
    city: 'Floro',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 61.583611,
    longitude: 5.024722,
    altitude: 11.2776
  },
  {
    iataCode: 'FRN',
    icaoCode: 'PAFR',
    name: 'Fort Richardson',
    country: {code: 'US', name: 'United States'},
    city: 'Bryant Ahp',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.266381,
    longitude: -149.653119,
    altitude: 115.2144
  },
  {
    iataCode: 'OCV',
    icaoCode: 'SKOC',
    name: 'Ocana',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Aguas Claras',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 8.315061,
    longitude: -73.358331,
    altitude: 1173.48
  },
  {
    iataCode: 'WTA',
    icaoCode: 'FMMU',
    name: 'Tambohorano',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Tambohorano Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -17.4761,
    longitude: 43.9728,
    altitude: 7.0104
  },
  {
    iataCode: 'FRS',
    icaoCode: 'MGTK',
    name: 'Flores',
    country: {code: 'GT', name: 'Guatemala'},
    city: 'Mundo Maya International',
    region: '',
    terminal: '',
    timezoneId: 'America/Guatemala',
    utcOffset: -360,
    latitude: 16.913819,
    longitude: -89.866383,
    altitude: 130.1496
  },
  {
    iataCode: 'FRU',
    icaoCode: 'UAFM',
    name: 'Bishkek',
    country: {code: 'KG', name: 'Kyrgyzstan'},
    city: 'Manas',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bishkek',
    utcOffset: 300,
    latitude: 43.061306,
    longitude: 74.477556,
    altitude: 627.2784
  },
  {
    iataCode: 'WTE',
    icaoCode: 'N36',
    name: 'Wotje Atoll',
    country: {code: 'MH', name: 'Marshall Islands'},
    city: 'Wotje Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Majuro',
    utcOffset: 720,
    latitude: 9.46667,
    longitude: 170.233,
    altitude: 1.2192
  },
  {
    iataCode: 'FRW',
    icaoCode: 'FBFT',
    name: 'Francistown',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Francistown',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -21.159597,
    longitude: 27.474525,
    altitude: 1000.6584
  },
  {
    iataCode: 'ODB',
    icaoCode: 'LEBA',
    name: 'Cordoba',
    country: {code: 'ES', name: 'Spain'},
    city: 'Cordoba',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 37.842006,
    longitude: -4.848878,
    altitude: 90.5256
  },
  {
    iataCode: 'WTK',
    icaoCode: 'PAWN',
    name: 'Noatak',
    country: {code: 'US', name: 'United States'},
    city: 'Noatak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 67.5661,
    longitude: -162.975,
    altitude: 26.8224
  },
  {
    iataCode: 'WTN',
    icaoCode: 'EGXW',
    name: 'Waddington',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Waddington',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.166167,
    longitude: -0.523811,
    altitude: 70.4088
  },
  {
    iataCode: 'ODE',
    icaoCode: 'EKOD',
    name: 'Odense',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Odense',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.476664,
    longitude: 10.330933,
    altitude: 17.0688
  },
  {
    iataCode: 'ODH',
    icaoCode: 'EGVO',
    name: 'Odiham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Odiham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.234139,
    longitude: -0.942825,
    altitude: 123.444
  },
  {
    iataCode: 'FSD',
    icaoCode: 'KFSD',
    name: 'Sioux Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Sioux Falls',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.582014,
    longitude: -96.741914,
    altitude: 435.5592
  },
  {
    iataCode: 'FSC',
    icaoCode: 'LFKF',
    name: 'Figari',
    country: {code: 'FR', name: 'France'},
    city: 'Sud Corse',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 41.500557,
    longitude: 9.097777,
    altitude: 26.5176
  },
  {
    iataCode: 'WTS',
    icaoCode: 'FMMX',
    name: 'Tsiroanomandidy',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Tsiroanomandidy Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -18.75,
    longitude: 46.05,
    altitude: 846.1248
  },
  {
    iataCode: 'ODN',
    icaoCode: 'WBGI',
    name: 'Long Seridan',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Long Seridan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 3.967,
    longitude: 115.05,
    altitude: 185.0136
  },
  {
    iataCode: 'WTZ',
    icaoCode: 'NZWT',
    name: 'Whitianga',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Whitianga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -36.835833,
    longitude: 175.700278,
    altitude: 152.4
  },
  {
    iataCode: 'FSI',
    icaoCode: 'KFSI',
    name: 'Fort Sill',
    country: {code: 'US', name: 'United States'},
    city: 'Henry Post Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.649833,
    longitude: -98.402167,
    altitude: 362.4072
  },
  {
    iataCode: 'ODS',
    icaoCode: 'UKOO',
    name: 'Odessa',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Odesa Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Simferopol',
    utcOffset: 120,
    latitude: 46.426767,
    longitude: 30.676464,
    altitude: 52.4256
  },
  {
    iataCode: 'FSM',
    icaoCode: 'KFSM',
    name: 'Fort Smith',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Smith Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.336583,
    longitude: -94.367444,
    altitude: 142.9512
  },
  {
    iataCode: 'FSP',
    icaoCode: 'LFVP',
    name: 'St.-pierre',
    country: {code: 'PM', name: 'Saint Pierre and Miquelon'},
    city: 'St Pierre',
    region: '',
    terminal: '',
    timezoneId: 'America/Miquelon',
    utcOffset: -240,
    latitude: 46.762904,
    longitude: -56.173088,
    altitude: 8.2296
  },
  {
    iataCode: 'WUA',
    icaoCode: 'ZBUH',
    name: 'Wuhai',
    country: {code: 'CN', name: 'China'},
    city: 'Wuhai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 39.794444,
    longitude: 106.799444,
    altitude: 0.0
  },
  {
    iataCode: 'ODY',
    icaoCode: 'VLOS',
    name: 'Muang Xay',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Oudomxay',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 20.6827,
    longitude: 101.994,
    altitude: 420.624
  },
  {
    iataCode: 'FST',
    icaoCode: 'KFST',
    name: 'Fort Stockton',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Stockton Pecos Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.915667,
    longitude: -102.916139,
    altitude: 917.7528
  },
  {
    iataCode: 'WUH',
    icaoCode: 'ZHHH',
    name: 'Wuhan',
    country: {code: 'CN', name: 'China'},
    city: 'Tianhe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 30.783758,
    longitude: 114.2081,
    altitude: 34.4424
  },
  {
    iataCode: 'FSZ',
    icaoCode: 'RJNS',
    name: 'Shizuoka',
    country: {code: 'JP', name: 'Japan'},
    city: 'Mt. Fuji Shizuoka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.796111,
    longitude: 138.189444,
    altitude: 131.9784
  },
  {
    iataCode: 'WUN',
    icaoCode: 'YWLU',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Wiluna Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -26.6292,
    longitude: 120.221,
    altitude: 502.6152
  },
  {
    iataCode: 'FTA',
    icaoCode: 'NVVF',
    name: 'Futuna Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Futuna Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -19.5164,
    longitude: 170.232,
    altitude: 0.0
  },
  {
    iataCode: 'WUS',
    icaoCode: 'ZSWY',
    name: 'Wuyishan',
    country: {code: 'CN', name: 'China'},
    city: 'Nanping Wuyishan Airport Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 27.7019,
    longitude: 118.001,
    altitude: 187.1472
  },
  {
    iataCode: 'FTE',
    icaoCode: 'SAWC',
    name: 'El Calafate',
    country: {code: 'AR', name: 'Argentina'},
    city: 'El Calafate',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -50.280322,
    longitude: -72.053103,
    altitude: 203.9112
  },
  {
    iataCode: 'OEM',
    icaoCode: 'SMPA',
    name: 'Paloemeu',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Vincent Fayks Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.811111,
    longitude: -55.190833,
    altitude: 3.048
  },
  {
    iataCode: 'FTI',
    icaoCode: 'NSFQ',
    name: "Fiti\\\\'uta",
    country: {code: 'AS', name: 'American Samoa'},
    city: 'Fitiuta Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Pago_Pago',
    utcOffset: -660,
    latitude: -14.216111,
    longitude: -169.423611,
    altitude: 33.528
  },
  {
    iataCode: 'WUX',
    icaoCode: 'ZSWX',
    name: 'Wuxi',
    country: {code: 'CN', name: 'China'},
    city: 'Wuxi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.4944,
    longitude: 120.429,
    altitude: 0.0
  },
  {
    iataCode: 'FTK',
    icaoCode: 'KFTK',
    name: 'Fort Knox',
    country: {code: 'US', name: 'United States'},
    city: 'Godman Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -360,
    latitude: 37.907058,
    longitude: -85.972106,
    altitude: 230.4288
  },
  {
    iataCode: 'OES',
    icaoCode: 'SAVN',
    name: 'San Antonio Oeste',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Antoine De St Exupery Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -40.7512,
    longitude: -65.0343,
    altitude: 25.908
  },
  {
    iataCode: 'OER',
    icaoCode: 'ESNO',
    name: 'Ornskoldsvik',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Ornskoldsvik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 63.408339,
    longitude: 18.990039,
    altitude: 107.8992
  },
  {
    iataCode: 'WUZ',
    icaoCode: 'ZGWZ',
    name: 'Wuzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Changzhoudao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 23.4567,
    longitude: 111.248,
    altitude: 27.1272
  },
  {
    iataCode: 'WVB',
    icaoCode: 'FYWB',
    name: 'Walvis Bay',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Walvis Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -22.9799,
    longitude: 14.6453,
    altitude: 91.1352
  },
  {
    iataCode: 'FTU',
    icaoCode: 'FMSD',
    name: 'Tolagnaro',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Tolagnaro',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -25.038056,
    longitude: 46.956111,
    altitude: 8.8392
  },
  {
    iataCode: 'FTW',
    icaoCode: 'KFTW',
    name: 'Fort Worth',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Worth Meacham Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.819778,
    longitude: -97.362444,
    altitude: 216.408
  },
  {
    iataCode: 'FTY',
    icaoCode: 'KFTY',
    name: 'Atlanta',
    country: {code: 'US', name: 'United States'},
    city: 'Fulton County Airport Brown Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.7791,
    longitude: -84.5214,
    altitude: 256.3368
  },
  {
    iataCode: 'FTX',
    icaoCode: 'FCOO',
    name: 'Owando',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Owando',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Brazzaville',
    utcOffset: 60,
    latitude: -0.53135,
    longitude: 15.950094,
    altitude: 370.0272
  },
  {
    iataCode: 'WVK',
    icaoCode: 'FMSK',
    name: 'Manakara',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Manakara',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -22.119722,
    longitude: 48.021667,
    altitude: 10.0584
  },
  {
    iataCode: 'OFF',
    icaoCode: 'KOFF',
    name: 'Omaha',
    country: {code: 'US', name: 'United States'},
    city: 'Offutt Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.118332,
    longitude: -95.912511,
    altitude: 320.6496
  },
  {
    iataCode: 'WVN',
    icaoCode: 'EDWI',
    name: 'Wilhelmshaven',
    country: {code: 'DE', name: 'Germany'},
    city: 'Wilhelmshaven Mariensiel',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.504833,
    longitude: 8.053333,
    altitude: 4.8768
  },
  {
    iataCode: 'FUE',
    icaoCode: 'GCFV',
    name: 'Fuerteventura',
    country: {code: 'ES', name: 'Spain'},
    city: 'Fuerteventura',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.452717,
    longitude: -13.863761,
    altitude: 25.2984
  },
  {
    iataCode: 'FUG',
    icaoCode: 'ZSFY',
    name: 'Fuyang',
    country: {code: 'CN', name: 'China'},
    city: 'Fuyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 32.9,
    longitude: 115.816667,
    altitude: 152.4
  },
  {
    iataCode: 'FUJ',
    icaoCode: 'RJFE',
    name: 'Fukue',
    country: {code: 'JP', name: 'Japan'},
    city: 'Fukue',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 32.666269,
    longitude: 128.832808,
    altitude: 83.2104
  },
  {
    iataCode: 'FUL',
    icaoCode: 'KFUL',
    name: 'Fullerton',
    country: {code: 'US', name: 'United States'},
    city: 'Fullerton Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 33.521925,
    longitude: -117.584722,
    altitude: 29.2608
  },
  {
    iataCode: 'FUK',
    icaoCode: 'RJFF',
    name: 'Fukuoka',
    country: {code: 'JP', name: 'Japan'},
    city: 'Fukuoka',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.585942,
    longitude: 130.450686,
    altitude: 9.7536
  },
  {
    iataCode: 'FUN',
    icaoCode: 'NGFU',
    name: 'Funafuti',
    country: {code: 'TV', name: 'Tuvalu'},
    city: 'Funafuti International',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Funafuti',
    utcOffset: 720,
    latitude: -8.525,
    longitude: 179.196389,
    altitude: 2.7432
  },
  {
    iataCode: 'FUT',
    icaoCode: 'NLWF',
    name: 'Futuna Island',
    country: {code: 'WF', name: 'Wallis and Futuna'},
    city: 'Pointe Vele Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Wallis',
    utcOffset: 720,
    latitude: -14.3114,
    longitude: -178.066,
    altitude: 6.096
  },
  {
    iataCode: 'FUS',
    icaoCode: 'FUSS',
    name: 'Fussen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Fussen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 47.585,
    longitude: 10.6866,
    altitude: 243.84
  },
  {
    iataCode: 'WWD',
    icaoCode: 'KWWD',
    name: 'Wildwood',
    country: {code: 'US', name: 'United States'},
    city: 'Cape May Co',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.008507,
    longitude: -74.908275,
    altitude: 7.0104
  },
  {
    iataCode: 'WWK',
    icaoCode: 'AYWK',
    name: 'Wewak',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Wewak Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -3.583828,
    longitude: 143.669186,
    altitude: 5.7912
  },
  {
    iataCode: 'OGG',
    icaoCode: 'PHOG',
    name: 'Kahului',
    country: {code: 'US', name: 'United States'},
    city: 'Kahului',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.89865,
    longitude: -156.430458,
    altitude: 16.4592
  },
  {
    iataCode: 'OGL',
    icaoCode: 'SYGO',
    name: 'Georgetown',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Ogle',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 6.806944,
    longitude: -58.104444,
    altitude: 3.048
  },
  {
    iataCode: 'OGN',
    icaoCode: 'ROYN',
    name: 'Yonaguni Jima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yonaguni',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 24.466944,
    longitude: 122.977778,
    altitude: 21.336
  },
  {
    iataCode: 'OGS',
    icaoCode: 'KOGS',
    name: 'Ogdensburg',
    country: {code: 'US', name: 'United States'},
    city: 'Ogdensburg Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.681854,
    longitude: -75.4655,
    altitude: 90.5256
  },
  {
    iataCode: 'OGX',
    icaoCode: 'DAUU',
    name: 'Ouargla',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Ouargla',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 31.917223,
    longitude: 5.412778,
    altitude: 149.9616
  },
  {
    iataCode: 'OGZ',
    icaoCode: 'URMO',
    name: 'Beslan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Beslan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 43.2051,
    longitude: 44.6066,
    altitude: 509.9304
  },
  {
    iataCode: 'OHD',
    icaoCode: 'LWOH',
    name: 'Ohrid',
    country: {code: 'MK', name: 'The Former Yugoslav Republic of Macedonia'},
    city: 'Ohrid',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Skopje',
    utcOffset: 60,
    latitude: 41.179956,
    longitude: 20.742325,
    altitude: 705.0024
  },
  {
    iataCode: 'WXN',
    icaoCode: 'ZUWX',
    name: 'Wanxian',
    country: {code: 'CN', name: 'China'},
    city: 'Wanxian Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 30.8361,
    longitude: 108.406,
    altitude: 0.0
  },
  {
    iataCode: 'FWA',
    icaoCode: 'KFWA',
    name: 'Fort Wayne',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Wayne',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 40.978472,
    longitude: -85.195139,
    altitude: 248.412
  },
  {
    iataCode: 'OHO',
    icaoCode: 'UHOO',
    name: 'Okhotsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Okhotsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vladivostok',
    utcOffset: 600,
    latitude: 59.4101,
    longitude: 143.057,
    altitude: 0.0
  },
  {
    iataCode: 'WYA',
    icaoCode: 'YWHA',
    name: 'Whyalla',
    country: {code: 'AU', name: 'Australia'},
    city: 'Whyalla Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -33.0589,
    longitude: 137.514,
    altitude: 12.4968
  },
  {
    iataCode: 'WYS',
    icaoCode: 'KWYS',
    name: 'West Yellowstone',
    country: {code: 'US', name: 'United States'},
    city: 'Yellowstone Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.688333,
    longitude: -111.1175,
    altitude: 2025.0912
  },
  {
    iataCode: 'FXE',
    icaoCode: 'KFXE',
    name: 'Fort Lauderdale',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Lauderdale Executive',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.197281,
    longitude: -80.170706,
    altitude: 3.9624
  },
  {
    iataCode: 'OIM',
    icaoCode: 'RJTO',
    name: 'Oshima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Oshima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.782033,
    longitude: 139.360306,
    altitude: 39.624
  },
  {
    iataCode: 'OIR',
    icaoCode: 'RJEO',
    name: 'Okushiri',
    country: {code: 'JP', name: 'Japan'},
    city: 'Okushiri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 42.071667,
    longitude: 139.432911,
    altitude: 49.0728
  },
  {
    iataCode: 'OIT',
    icaoCode: 'RJFO',
    name: 'Oita',
    country: {code: 'JP', name: 'Japan'},
    city: 'Oita',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.479444,
    longitude: 131.737222,
    altitude: 5.7912
  },
  {
    iataCode: 'FYT',
    icaoCode: 'FTTY',
    name: 'Faya-largeau',
    country: {code: 'TD', name: 'Chad'},
    city: 'Faya Largeau',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 17.917053,
    longitude: 19.111078,
    altitude: 235.0008
  },
  {
    iataCode: 'FYV',
    icaoCode: 'KFYV',
    name: 'Fayetteville',
    country: {code: 'US', name: 'United States'},
    city: 'Drake Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.005094,
    longitude: -94.170059,
    altitude: 381.3048
  },
  {
    iataCode: 'FYU',
    icaoCode: 'PFYU',
    name: 'Fort Yukon',
    country: {code: 'US', name: 'United States'},
    city: 'Fort Yukon',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.571492,
    longitude: -145.250417,
    altitude: 131.9784
  },
  {
    iataCode: 'OKA',
    icaoCode: 'ROAH',
    name: 'Okinawa',
    country: {code: 'JP', name: 'Japan'},
    city: 'Naha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 26.195814,
    longitude: 127.645869,
    altitude: 3.6576
  },
  {
    iataCode: 'OKC',
    icaoCode: 'KOKC',
    name: 'Oklahoma City',
    country: {code: 'US', name: 'United States'},
    city: 'Will Rogers World',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.393089,
    longitude: -97.600733,
    altitude: 394.716
  },
  {
    iataCode: 'OKB',
    icaoCode: 'KOKB',
    name: 'Fraser Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Orchid Beach',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -600,
    latitude: -24.95841,
    longitude: 153.3145,
    altitude: 1.8288
  },
  {
    iataCode: 'OKD',
    icaoCode: 'RJCO',
    name: 'Sapporo',
    country: {code: 'JP', name: 'Japan'},
    city: 'Okadama Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 43.1161,
    longitude: 141.38,
    altitude: 7.62
  },
  {
    iataCode: 'OKI',
    icaoCode: 'RJNO',
    name: 'Oki Island',
    country: {code: 'JP', name: 'Japan'},
    city: 'Oki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 36.181125,
    longitude: 133.324844,
    altitude: 94.7928
  },
  {
    iataCode: 'OKJ',
    icaoCode: 'RJOB',
    name: 'Okayama',
    country: {code: 'JP', name: 'Japan'},
    city: 'Okayama',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.756944,
    longitude: 133.855278,
    altitude: 245.6688
  },
  {
    iataCode: 'OKO',
    icaoCode: 'RJTY',
    name: 'Yokota',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yokota Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.748492,
    longitude: 139.348483,
    altitude: 141.1224
  },
  {
    iataCode: 'OKN',
    icaoCode: 'FOGQ',
    name: 'Okondja',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Okondja',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -0.665214,
    longitude: 13.673133,
    altitude: 403.86
  },
  {
    iataCode: 'OKR',
    icaoCode: 'YYKI',
    name: 'Yorke Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Yorke Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -9.75703,
    longitude: 143.411,
    altitude: 0.0
  },
  {
    iataCode: 'FZO',
    icaoCode: 'EGTG',
    name: 'Bristol',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Bristol Filton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.519444,
    longitude: -2.590833,
    altitude: 68.8848
  },
  {
    iataCode: 'OKY',
    icaoCode: 'YBOK',
    name: 'Oakey',
    country: {code: 'AU', name: 'Australia'},
    city: 'Oakey Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -27.411389,
    longitude: 151.735278,
    altitude: 406.908
  },
  {
    iataCode: 'OLB',
    icaoCode: 'LIEO',
    name: 'Olbia',
    country: {code: 'IT', name: 'Italy'},
    city: 'Olbia Costa Smeralda',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.898661,
    longitude: 9.517628,
    altitude: 11.2776
  },
  {
    iataCode: 'OLA',
    icaoCode: 'ENOL',
    name: 'Orland',
    country: {code: 'NO', name: 'Norway'},
    city: 'Orland',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 63.698908,
    longitude: 9.604003,
    altitude: 8.5344
  },
  {
    iataCode: 'OLF',
    icaoCode: 'KOLF',
    name: 'Wolf Point',
    country: {code: 'US', name: 'United States'},
    city: 'Lm Clayton Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 48.094444,
    longitude: -105.575,
    altitude: 605.3328
  },
  {
    iataCode: 'OLM',
    icaoCode: 'KOLM',
    name: 'Olympia',
    country: {code: 'US', name: 'United States'},
    city: 'Olympia Regional Airpor',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 46.969404,
    longitude: -122.902545,
    altitude: 63.7032
  },
  {
    iataCode: 'OLP',
    icaoCode: 'YOLD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Olympic Dam Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -30.485,
    longitude: 136.877,
    altitude: 104.5464
  },
  {
    iataCode: 'OLS',
    icaoCode: 'KOLS',
    name: 'Nogales',
    country: {code: 'US', name: 'United States'},
    city: 'Nogales Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 31.417722,
    longitude: -110.84789,
    altitude: 1205.484
  },
  {
    iataCode: 'OLZ',
    icaoCode: 'NVSZ',
    name: 'Olpoi',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'North West Santo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -14.8817,
    longitude: 166.558,
    altitude: 0.0
  },
  {
    iataCode: 'OMA',
    icaoCode: 'KOMA',
    name: 'Omaha',
    country: {code: 'US', name: 'United States'},
    city: 'Eppley Afld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.303167,
    longitude: -95.894069,
    altitude: 299.9232
  },
  {
    iataCode: 'OMC',
    icaoCode: 'RPVO',
    name: 'Ormoc City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Ormoc Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.057997,
    longitude: 124.565322,
    altitude: 25.2984
  },
  {
    iataCode: 'OMB',
    icaoCode: 'FOOH',
    name: 'Omboue Hospial',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Omboue Hopital',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -1.574733,
    longitude: 9.262694,
    altitude: 10.0584
  },
  {
    iataCode: 'OME',
    icaoCode: 'PAOM',
    name: 'Nome',
    country: {code: 'US', name: 'United States'},
    city: 'Nome',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 64.512203,
    longitude: -165.445247,
    altitude: 11.2776
  },
  {
    iataCode: 'OMD',
    icaoCode: 'FYOG',
    name: 'Oranjemund',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Oranjemund Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 120,
    latitude: -28.5847,
    longitude: 16.4467,
    altitude: 4.2672
  },
  {
    iataCode: 'OMF',
    icaoCode: 'OJMF',
    name: 'Mafraq',
    country: {code: 'JO', name: 'Jordan'},
    city: 'King Hussein',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Amman',
    utcOffset: 120,
    latitude: 32.356353,
    longitude: 36.259181,
    altitude: 682.752
  },
  {
    iataCode: 'OMH',
    icaoCode: 'OITR',
    name: '',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Uromiyeh Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 37.6681,
    longitude: 45.0687,
    altitude: 1323.7464
  },
  {
    iataCode: 'OMO',
    icaoCode: 'LQMO',
    name: 'Mostar',
    country: {code: 'BA', name: 'Bosnia and Herzegovina'},
    city: 'Mostar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sarajevo',
    utcOffset: 60,
    latitude: 43.2829,
    longitude: 17.845878,
    altitude: 47.5488
  },
  {
    iataCode: 'OMS',
    icaoCode: 'UNOO',
    name: 'Omsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Omsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Omsk',
    utcOffset: 360,
    latitude: 54.967042,
    longitude: 73.310514,
    altitude: 94.7928
  },
  {
    iataCode: 'OMR',
    icaoCode: 'LROD',
    name: 'Oradea',
    country: {code: 'RO', name: 'Romania'},
    city: 'Oradea',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 47.025278,
    longitude: 21.9025,
    altitude: 141.732
  },
  {
    iataCode: 'OND',
    icaoCode: 'FYOA',
    name: 'Ondangwa',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Ondangwa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -17.8782,
    longitude: 15.9526,
    altitude: 1096.9752
  },
  {
    iataCode: 'ONG',
    icaoCode: 'YMTI',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mornington Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 540,
    latitude: -16.6625,
    longitude: 139.178,
    altitude: 10.0584
  },
  {
    iataCode: 'ONJ',
    icaoCode: 'RJSR',
    name: '',
    country: {code: 'JP', name: 'Japan'},
    city: 'Odate Noshiro Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 40.1919,
    longitude: 140.371,
    altitude: 89.0016
  },
  {
    iataCode: 'ONP',
    icaoCode: 'KNOP',
    name: 'Newport',
    country: {code: 'US', name: 'United States'},
    city: 'Newport Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: 480,
    latitude: 44.580361,
    longitude: -124.057917,
    altitude: 48.768
  },
  {
    iataCode: 'ONT',
    icaoCode: 'KONT',
    name: 'Ontario',
    country: {code: 'US', name: 'United States'},
    city: 'Ontario Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.056,
    longitude: -117.601194,
    altitude: 287.7312
  },
  {
    iataCode: 'ONX',
    icaoCode: 'MPEJ',
    name: 'Colón',
    country: {code: 'PA', name: 'Panama'},
    city: 'Enrique Adolfo Jimenez Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 9.35664,
    longitude: -79.8674,
    altitude: 7.62
  },
  {
    iataCode: 'OOM',
    icaoCode: 'YCOM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Cooma Snowy Mountains Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -36.3006,
    longitude: 148.974,
    altitude: 941.2224
  },
  {
    iataCode: 'OOL',
    icaoCode: 'YBCG',
    name: 'Coolangatta',
    country: {code: 'AU', name: 'Australia'},
    city: 'Gold Coast',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -28.164444,
    longitude: 153.504722,
    altitude: 6.4008
  },
  {
    iataCode: 'XAC',
    icaoCode: 'LFCH',
    name: 'Arcachon',
    country: {code: 'FR', name: 'France'},
    city: 'La Teste De Buch',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.59639,
    longitude: -1.110833,
    altitude: 14.9352
  },
  {
    iataCode: 'OPF',
    icaoCode: 'KOPF',
    name: 'Miami',
    country: {code: 'US', name: 'United States'},
    city: 'Opa Locka',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 25.907,
    longitude: -80.278389,
    altitude: 2.4384
  },
  {
    iataCode: 'XAP',
    icaoCode: 'SBCH',
    name: 'Chapeco',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Chapeco',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -27.134219,
    longitude: -52.656553,
    altitude: 654.1008
  },
  {
    iataCode: 'XAU',
    icaoCode: 'SOOS',
    name: 'Saul',
    country: {code: 'GF', name: 'French Guiana'},
    city: 'Saul Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cayenne',
    utcOffset: -180,
    latitude: 3.61361,
    longitude: -53.2042,
    altitude: 199.9488
  },
  {
    iataCode: 'OPO',
    icaoCode: 'LPPR',
    name: 'Porto',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Porto',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Lisbon',
    utcOffset: 0,
    latitude: 41.248055,
    longitude: -8.681389,
    altitude: 69.4944
  },
  {
    iataCode: 'OPS',
    icaoCode: 'SWSI',
    name: 'Sinop',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Sinop Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -11.85,
    longitude: -55.46,
    altitude: 3.048
  },
  {
    iataCode: 'XBE',
    icaoCode: 'CNE3',
    name: 'Bearskin Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bearskin Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.9656,
    longitude: -91.0272,
    altitude: 243.84
  },
  {
    iataCode: 'XBK',
    icaoCode: 'LFHS',
    name: 'Bourg',
    country: {code: 'FR', name: 'France'},
    city: 'Ceyzeriat',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.20089,
    longitude: 5.292028,
    altitude: 261.2136
  },
  {
    iataCode: 'XBJ',
    icaoCode: 'OIMB',
    name: 'Birjand',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Birjand',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 32.898056,
    longitude: 59.266111,
    altitude: 1509.3696
  },
  {
    iataCode: 'GAE',
    icaoCode: 'DTTG',
    name: 'Gabes',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Gabes',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 33.876919,
    longitude: 10.103333,
    altitude: 7.9248
  },
  {
    iataCode: 'GAF',
    icaoCode: 'DTTF',
    name: 'Gafsa',
    country: {code: 'TN', name: 'Tunisia'},
    city: 'Gafsa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tunis',
    utcOffset: 60,
    latitude: 34.422022,
    longitude: 8.822503,
    altitude: 323.088
  },
  {
    iataCode: 'GAJ',
    icaoCode: 'RJSC',
    name: 'Yamagata',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yamagata',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 38.411894,
    longitude: 140.371331,
    altitude: 107.5944
  },
  {
    iataCode: 'GAM',
    icaoCode: 'PAGM',
    name: 'Gambell',
    country: {code: 'US', name: 'United States'},
    city: 'Gambell Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 63.7668,
    longitude: -171.733,
    altitude: 8.2296
  },
  {
    iataCode: 'GAL',
    icaoCode: 'PAGA',
    name: 'Galena',
    country: {code: 'US', name: 'United States'},
    city: 'Edward G Pitka Sr',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.736178,
    longitude: -156.937417,
    altitude: 46.3296
  },
  {
    iataCode: 'GAO',
    icaoCode: 'MUGT',
    name: 'Guantanamo',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Mariana Grajales',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.085419,
    longitude: -75.158328,
    altitude: 17.0688
  },
  {
    iataCode: 'GAN',
    icaoCode: 'VRMG',
    name: '',
    country: {code: 'MV', name: 'Maldives'},
    city: 'Gan Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Maldives',
    utcOffset: 300,
    latitude: -0.693342,
    longitude: 73.1556,
    altitude: 1.8288
  },
  {
    iataCode: 'GAQ',
    icaoCode: 'GAGO',
    name: 'Gao',
    country: {code: 'ML', name: 'Mali'},
    city: 'Gao',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bamako',
    utcOffset: 0,
    latitude: 16.248433,
    longitude: -0.005456,
    altitude: 265.176
  },
  {
    iataCode: 'XCD',
    icaoCode: 'LFLH',
    name: 'Chalon',
    country: {code: 'FR', name: 'France'},
    city: 'Champforgeuil',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.826108,
    longitude: 4.817633,
    altitude: 189.8904
  },
  {
    iataCode: 'GAU',
    icaoCode: 'VEGT',
    name: 'Guwahati',
    country: {code: 'IN', name: 'India'},
    city: 'Lokpriya Gopinath Bordoloi International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.106092,
    longitude: 91.585939,
    altitude: 49.3776
  },
  {
    iataCode: 'XCH',
    icaoCode: 'YPXM',
    name: 'Christmas Island',
    country: {code: 'CX', name: 'Christmas Island'},
    city: 'Christmas Island',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Christmas',
    utcOffset: 420,
    latitude: -10.450556,
    longitude: 105.690278,
    altitude: 279.1968
  },
  {
    iataCode: 'GAY',
    icaoCode: 'VEGY',
    name: 'Gaya',
    country: {code: 'IN', name: 'India'},
    city: 'Gaya',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.744308,
    longitude: 84.951175,
    altitude: 115.824
  },
  {
    iataCode: 'ORB',
    icaoCode: 'ESOE',
    name: 'Orebro',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Orebro',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.223733,
    longitude: 15.037956,
    altitude: 57.3024
  },
  {
    iataCode: 'ORA',
    icaoCode: 'SASO',
    name: 'Oran',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Oran',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -23.152779,
    longitude: -64.32917,
    altitude: 356.0064
  },
  {
    iataCode: 'ORD',
    icaoCode: 'KORD',
    name: 'Chicago',
    country: {code: 'US', name: 'United States'},
    city: 'Chicago Ohare Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.978603,
    longitude: -87.904842,
    altitude: 203.6064
  },
  {
    iataCode: 'ORF',
    icaoCode: 'KORF',
    name: 'Norfolk',
    country: {code: 'US', name: 'United States'},
    city: 'Norfolk Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.894611,
    longitude: -76.201222,
    altitude: 7.9248
  },
  {
    iataCode: 'ORE',
    icaoCode: 'LFOJ',
    name: 'Orleans',
    country: {code: 'FR', name: 'France'},
    city: 'Bricy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.987778,
    longitude: 1.760556,
    altitude: 125.5776
  },
  {
    iataCode: 'ORH',
    icaoCode: 'KORH',
    name: 'Worcester',
    country: {code: 'US', name: 'United States'},
    city: 'Worcester Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.2673,
    longitude: -71.8757,
    altitude: 307.5432
  },
  {
    iataCode: 'ORG',
    icaoCode: 'SMZO',
    name: 'Paramaribo',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Zorg En Hoop Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.81108,
    longitude: -55.1907,
    altitude: 3.048
  },
  {
    iataCode: 'ORJ',
    icaoCode: 'SYOR',
    name: 'Orinduik',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Orinduik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 4.7,
    longitude: -60.016667,
    altitude: 3.048
  },
  {
    iataCode: 'ORL',
    icaoCode: 'KORL',
    name: 'Orlando',
    country: {code: 'US', name: 'United States'},
    city: 'Executive',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.545464,
    longitude: -81.332936,
    altitude: 34.4424
  },
  {
    iataCode: 'ORK',
    icaoCode: 'EICK',
    name: 'Cork',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Cork',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 51.841269,
    longitude: -8.491111,
    altitude: 153.0096
  },
  {
    iataCode: 'ORN',
    icaoCode: 'DAOO',
    name: 'Oran',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Es Senia',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 35.623858,
    longitude: -0.621183,
    altitude: 89.916
  },
  {
    iataCode: 'GBE',
    icaoCode: 'FBSK',
    name: 'Gaberone',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Sir Seretse Khama Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -24.555225,
    longitude: 25.918208,
    altitude: 1005.5352
  },
  {
    iataCode: 'ORP',
    icaoCode: 'FBOR',
    name: '',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Orapa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -21.2667,
    longitude: 25.3167,
    altitude: 944.88
  },
  {
    iataCode: 'GBJ',
    icaoCode: 'TFFM',
    name: 'Grand Bourg',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'Les Bases Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 15.86875,
    longitude: -61.270022,
    altitude: 4.8768
  },
  {
    iataCode: 'ORT',
    icaoCode: 'PAOR',
    name: 'Northway',
    country: {code: 'US', name: 'United States'},
    city: 'Northway',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.961334,
    longitude: -141.929136,
    altitude: 523.0368
  },
  {
    iataCode: 'GBN',
    icaoCode: 'KGBD',
    name: 'Great Bend',
    country: {code: 'US', name: 'United States'},
    city: 'Great Bend Municipal',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 38.344167,
    longitude: -98.859167,
    altitude: 575.1576
  },
  {
    iataCode: 'ORV',
    icaoCode: 'PFNO',
    name: 'Noorvik',
    country: {code: 'US', name: 'United States'},
    city: 'Robert Curtis Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.8175,
    longitude: -161.022222,
    altitude: 16.764
  },
  {
    iataCode: 'ORW',
    icaoCode: 'OPOR',
    name: 'Ormara Raik',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Ormara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.2747,
    longitude: 64.586,
    altitude: 3.048
  },
  {
    iataCode: 'ORY',
    icaoCode: 'LFPO',
    name: 'Paris',
    country: {code: 'FR', name: 'France'},
    city: 'Orly',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.725278,
    longitude: 2.359444,
    altitude: 88.6968
  },
  {
    iataCode: 'GBT',
    icaoCode: 'OING',
    name: 'Gorgan',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Gorgan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 36.909381,
    longitude: 54.401339,
    altitude: -7.3152
  },
  {
    iataCode: 'GBZ',
    icaoCode: 'NZGB',
    name: 'Claris',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Great Barrier Island',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 660,
    latitude: -36.1429,
    longitude: 175.2819,
    altitude: 3.048
  },
  {
    iataCode: 'OSC',
    icaoCode: 'KOSC',
    name: 'Oscoda',
    country: {code: 'US', name: 'United States'},
    city: 'Oscoda Wurtsmith',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 44.451558,
    longitude: -83.394053,
    altitude: 193.2432
  },
  {
    iataCode: 'OSB',
    icaoCode: 'ORBM',
    name: 'Mosul',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Mosul International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 240,
    latitude: 36.305833,
    longitude: 43.1475,
    altitude: 219.1512
  },
  {
    iataCode: 'OSD',
    icaoCode: 'ESNZ',
    name: 'Östersund',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Östersund Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 63.1944,
    longitude: 14.5003,
    altitude: 375.8184
  },
  {
    iataCode: 'OSI',
    icaoCode: 'LDOS',
    name: 'Osijek',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Osijek',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 45.462667,
    longitude: 18.810156,
    altitude: 88.392
  },
  {
    iataCode: 'OSH',
    icaoCode: 'KOSH',
    name: 'Oshkosh',
    country: {code: 'US', name: 'United States'},
    city: 'Wittman Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 44.024983,
    longitude: -88.551336,
    altitude: 240.792
  },
  {
    iataCode: 'GCC',
    icaoCode: 'KGCC',
    name: 'Gillette',
    country: {code: 'US', name: 'United States'},
    city: 'Gillette-campbell County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.348917,
    longitude: -105.539361,
    altitude: 1330.452
  },
  {
    iataCode: 'OSK',
    icaoCode: 'ESMO',
    name: 'Oskarshamn',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Oskarshamn',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.350453,
    longitude: 16.497972,
    altitude: 29.2608
  },
  {
    iataCode: 'OSL',
    icaoCode: 'ENGM',
    name: 'Oslo',
    country: {code: 'NO', name: 'Norway'},
    city: 'Gardermoen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 60.193917,
    longitude: 11.100361,
    altitude: 207.5688
  },
  {
    iataCode: 'OSO',
    icaoCode: 'ORSU',
    name: 'Sulaymaniyah',
    country: {code: 'IQ', name: 'Iraq'},
    city: 'Sulaymaniyah International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baghdad',
    utcOffset: 180,
    latitude: 35.5608,
    longitude: 45.3147,
    altitude: 760.1712
  },
  {
    iataCode: 'OSN',
    icaoCode: 'RKSO',
    name: 'Osan',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Osan Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.090617,
    longitude: 127.029594,
    altitude: 12.192
  },
  {
    iataCode: 'GCI',
    icaoCode: 'EGJB',
    name: 'Guernsey',
    country: {code: 'GG', name: 'Guernsey'},
    city: 'Guernsey',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Guernsey',
    utcOffset: 0,
    latitude: 49.434956,
    longitude: -2.601969,
    altitude: 102.4128
  },
  {
    iataCode: 'OSP',
    icaoCode: 'EPSK',
    name: 'Slupsk',
    country: {code: 'PL', name: 'Poland'},
    city: 'Redzikowo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 54.478889,
    longitude: 17.1075,
    altitude: 66.1416
  },
  {
    iataCode: 'GCK',
    icaoCode: 'KGCK',
    name: 'Garden City',
    country: {code: 'US', name: 'United States'},
    city: 'Garden City Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.927528,
    longitude: -100.724417,
    altitude: 881.1768
  },
  {
    iataCode: 'OSS',
    icaoCode: 'UAFO',
    name: 'Osh',
    country: {code: 'KG', name: 'Kyrgyzstan'},
    city: 'Osh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bishkek',
    utcOffset: 300,
    latitude: 40.608989,
    longitude: 72.793269,
    altitude: 892.1496
  },
  {
    iataCode: 'GCJ',
    icaoCode: 'FAGC',
    name: 'Johannesburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Grand Central',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.986267,
    longitude: 28.140061,
    altitude: 1623.06
  },
  {
    iataCode: 'OSR',
    icaoCode: 'LKMT',
    name: 'Ostrava',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Mosnov',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 49.696292,
    longitude: 18.111053,
    altitude: 257.2512
  },
  {
    iataCode: 'GCM',
    icaoCode: 'MWCR',
    name: 'Georgetown',
    country: {code: 'KY', name: 'Cayman Islands'},
    city: 'Owen Roberts Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Cayman',
    utcOffset: -300,
    latitude: 19.292778,
    longitude: -81.35775,
    altitude: 2.4384
  },
  {
    iataCode: 'OSU',
    icaoCode: 'KOSU',
    name: 'Columbus',
    country: {code: 'US', name: 'United States'},
    city: 'Ohio State University Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.0798,
    longitude: -83.073,
    altitude: 275.844
  },
  {
    iataCode: 'OST',
    icaoCode: 'EBOS',
    name: 'Ostend',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Oostende',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 51.198889,
    longitude: 2.862222,
    altitude: 3.9624
  },
  {
    iataCode: 'OSW',
    icaoCode: 'UWOR',
    name: 'Orsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Orsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 51.0725,
    longitude: 58.5956,
    altitude: 277.0632
  },
  {
    iataCode: 'GCN',
    icaoCode: 'KGCN',
    name: 'Grand Canyon',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Canyon National Park Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 35.952361,
    longitude: -112.146972,
    altitude: 2014.4232
  },
  {
    iataCode: 'OSY',
    icaoCode: 'ENNM',
    name: 'Namsos',
    country: {code: 'NO', name: 'Norway'},
    city: 'Namsos Høknesøra Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 64.4722,
    longitude: 11.5786,
    altitude: 2.1336
  },
  {
    iataCode: 'OSZ',
    icaoCode: 'EPKO',
    name: 'Koszalin',
    country: {code: 'PL', name: 'Poland'},
    city: 'Koszalin - Zegrze Pomorskie Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 54.2,
    longitude: 16.15,
    altitude: 33.8328
  },
  {
    iataCode: 'OTH',
    icaoCode: 'KOTH',
    name: 'North Bend',
    country: {code: 'US', name: 'United States'},
    city: 'Southwest Oregon Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 43.4171,
    longitude: -124.246,
    altitude: 5.1816
  },
  {
    iataCode: 'OTI',
    icaoCode: 'WAMR',
    name: 'Morotai Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pitu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: 2.045992,
    longitude: 128.324708,
    altitude: 14.9352
  },
  {
    iataCode: 'GDE',
    icaoCode: 'HAGO',
    name: 'Gode',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Gode Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 5.935128,
    longitude: 43.578567,
    altitude: 254.2032
  },
  {
    iataCode: 'OTP',
    icaoCode: 'LROP',
    name: 'Bucharest',
    country: {code: 'RO', name: 'Romania'},
    city: 'Henri Coanda',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 44.572161,
    longitude: 26.102178,
    altitude: 95.7072
  },
  {
    iataCode: 'OTR',
    icaoCode: 'MRCC',
    name: 'Coto 47',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Coto 47',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -300,
    latitude: 8.601556,
    longitude: -82.968614,
    altitude: 7.9248
  },
  {
    iataCode: 'GDL',
    icaoCode: 'MMGL',
    name: 'Guadalajara',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Don Miguel Hidalgo Y Costilla Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.5218,
    longitude: -103.311167,
    altitude: 1528.8768
  },
  {
    iataCode: 'GDN',
    icaoCode: 'EPGD',
    name: 'Gdansk',
    country: {code: 'PL', name: 'Poland'},
    city: 'Lecha Walesy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 54.377569,
    longitude: 18.466222,
    altitude: 149.0472
  },
  {
    iataCode: 'OTU',
    icaoCode: 'SKOT',
    name: 'Otu',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Otu',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 7.010369,
    longitude: -74.715497,
    altitude: 627.888
  },
  {
    iataCode: 'OTZ',
    icaoCode: 'PAOT',
    name: 'Kotzebue',
    country: {code: 'US', name: 'United States'},
    city: 'Ralph Wien Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 66.884678,
    longitude: -162.59855,
    altitude: 3.3528
  },
  {
    iataCode: 'GDQ',
    icaoCode: 'HAGN',
    name: 'Gondar',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Gondar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 12.5199,
    longitude: 37.434047,
    altitude: 1993.6968
  },
  {
    iataCode: 'GDT',
    icaoCode: 'MBGT',
    name: 'Cockburn Town',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'Jags Mccartney International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.4445,
    longitude: -71.1423,
    altitude: 3.9624
  },
  {
    iataCode: 'GDV',
    icaoCode: 'KGDV',
    name: 'Glendive',
    country: {code: 'US', name: 'United States'},
    city: 'Dawson Community Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 47.138611,
    longitude: -104.807222,
    altitude: 228.2952
  },
  {
    iataCode: 'GDX',
    icaoCode: 'UHMM',
    name: 'Magadan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Sokol',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Magadan',
    utcOffset: 660,
    latitude: 59.910989,
    longitude: 150.720439,
    altitude: 174.9552
  },
  {
    iataCode: 'OUA',
    icaoCode: 'DFFD',
    name: 'Ouagadougou',
    country: {code: 'BF', name: 'Burkina Faso'},
    city: 'Ouagadougou',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ouagadougou',
    utcOffset: 0,
    latitude: 12.353194,
    longitude: -1.512417,
    altitude: 316.0776
  },
  {
    iataCode: 'OUE',
    icaoCode: 'FCOU',
    name: 'Ouesso',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Ouesso',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Brazzaville',
    utcOffset: 60,
    latitude: 1.615994,
    longitude: 16.037917,
    altitude: 352.9584
  },
  {
    iataCode: 'OUD',
    icaoCode: 'GMFO',
    name: 'Oujda',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Angads',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 60,
    latitude: 34.78715,
    longitude: -1.923986,
    altitude: 467.868
  },
  {
    iataCode: 'XFN',
    icaoCode: 'ZHXF',
    name: 'Xiangfan',
    country: {code: 'CN', name: 'China'},
    city: 'Xiangfan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 32.1506,
    longitude: 112.291,
    altitude: 0.0
  },
  {
    iataCode: 'GEA',
    icaoCode: 'NWWM',
    name: 'Noumea',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Magenta',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -22.258278,
    longitude: 166.472806,
    altitude: 3.048
  },
  {
    iataCode: 'OUI',
    icaoCode: 'VLHS',
    name: 'Huay Xai',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Ban Huoeisay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 20.2573,
    longitude: 100.437,
    altitude: 420.624
  },
  {
    iataCode: 'GED',
    icaoCode: 'KGED',
    name: 'Georgetown',
    country: {code: 'US', name: 'United States'},
    city: 'Sussex Co',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.689194,
    longitude: -75.358889,
    altitude: 15.24
  },
  {
    iataCode: 'OUL',
    icaoCode: 'EFOU',
    name: 'Oulu',
    country: {code: 'FI', name: 'Finland'},
    city: 'Oulu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 64.930061,
    longitude: 25.354564,
    altitude: 14.3256
  },
  {
    iataCode: 'GEG',
    icaoCode: 'KGEG',
    name: 'Spokane',
    country: {code: 'US', name: 'United States'},
    city: 'Spokane Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.619861,
    longitude: -117.533833,
    altitude: 724.2048
  },
  {
    iataCode: 'XFW',
    icaoCode: 'EDHI',
    name: 'Hamburg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Hamburg Finkenwerder',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.535886,
    longitude: 9.837025,
    altitude: 6.7056
  },
  {
    iataCode: 'GEL',
    icaoCode: 'SBNM',
    name: 'Santo Angelo',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santo Angelo',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -28.281683,
    longitude: -54.169139,
    altitude: 321.8688
  },
  {
    iataCode: 'GEO',
    icaoCode: 'SYCJ',
    name: 'Georgetown',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Cheddi Jagan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 6.498553,
    longitude: -58.254119,
    altitude: 28.956
  },
  {
    iataCode: 'GES',
    icaoCode: 'RPMB',
    name: 'General Santos City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'General Santos International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 6.106439,
    longitude: 125.2353,
    altitude: 8.5344
  },
  {
    iataCode: 'GER',
    icaoCode: 'MUNG',
    name: 'Nueva Gerona',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Rafael Cabrera',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 21.834689,
    longitude: -82.783819,
    altitude: 24.0792
  },
  {
    iataCode: 'GET',
    icaoCode: 'YGEL',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Geraldton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -28.7961,
    longitude: 114.707,
    altitude: 36.8808
  },
  {
    iataCode: 'GEV',
    icaoCode: 'ESNG',
    name: 'Gallivare',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Gallivare',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 67.132408,
    longitude: 20.814636,
    altitude: 313.0296
  },
  {
    iataCode: 'OVB',
    icaoCode: 'UNNT',
    name: 'Novosibirsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Tolmachevo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Novosibirsk',
    utcOffset: 360,
    latitude: 55.012622,
    longitude: 82.650656,
    altitude: 111.252
  },
  {
    iataCode: 'GEX',
    icaoCode: 'YGLG',
    name: 'Geelong',
    country: {code: 'AU', name: 'Australia'},
    city: 'Geelong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -38.225,
    longitude: 144.333,
    altitude: 13.1064
  },
  {
    iataCode: 'OVD',
    icaoCode: 'LEAS',
    name: 'Aviles',
    country: {code: 'ES', name: 'Spain'},
    city: 'Asturias',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 43.563567,
    longitude: -6.034622,
    altitude: 126.7968
  },
  {
    iataCode: 'XGN',
    icaoCode: 'FNXA',
    name: 'Xangongo',
    country: {code: 'AO', name: 'Angola'},
    city: 'Xangongo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -16.755417,
    longitude: 14.965344,
    altitude: 1107.948
  },
  {
    iataCode: 'XGR',
    icaoCode: 'CYLU',
    name: 'Kangiqsualujjuaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kangiqsualujjuaq (Georges River) Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 58.7114,
    longitude: -65.9928,
    altitude: 65.532
  },
  {
    iataCode: 'GFF',
    icaoCode: 'YGTH',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Griffith Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.2508,
    longitude: 146.067,
    altitude: 133.8072
  },
  {
    iataCode: 'GFK',
    icaoCode: 'KGFK',
    name: 'Grand Forks',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Forks Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.949256,
    longitude: -97.176111,
    altitude: 257.556
  },
  {
    iataCode: 'OVS',
    icaoCode: 'USHS',
    name: 'Sovetskiy',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Sovetsky Tyumenskaya Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 61.32,
    longitude: 63.6044,
    altitude: 106.9848
  },
  {
    iataCode: 'GFN',
    icaoCode: 'YGFN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Grafton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -29.7594,
    longitude: 153.03,
    altitude: 33.528
  },
  {
    iataCode: 'GFY',
    icaoCode: 'FYGF',
    name: 'Grootfontein',
    country: {code: 'NA', name: 'Namibia'},
    city: 'Grootfontein',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Windhoek',
    utcOffset: 60,
    latitude: -19.602167,
    longitude: 18.122667,
    altitude: 1413.0528
  },
  {
    iataCode: 'OWB',
    icaoCode: 'KOWB',
    name: 'Owensboro',
    country: {code: 'US', name: 'United States'},
    city: 'Owensboro Daviess County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.7401,
    longitude: -87.1668,
    altitude: 123.7488
  },
  {
    iataCode: 'GGG',
    icaoCode: 'KGGG',
    name: 'Longview',
    country: {code: 'US', name: 'United States'},
    city: 'East Texas Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.384014,
    longitude: -94.711486,
    altitude: 111.252
  },
  {
    iataCode: 'GGS',
    icaoCode: 'SAWR',
    name: 'Gobernador Gregores',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Gobernador Gregores Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -48.7831,
    longitude: -70.15,
    altitude: 108.5088
  },
  {
    iataCode: 'XIC',
    icaoCode: 'ZUXC',
    name: 'Xichang',
    country: {code: 'CN', name: 'China'},
    city: 'Qingshan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 27.989083,
    longitude: 102.184361,
    altitude: 1558.1376
  },
  {
    iataCode: 'GGT',
    icaoCode: 'MYEF',
    name: 'Great Exuma',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Exuma Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 23.562631,
    longitude: -75.877958,
    altitude: 2.7432
  },
  {
    iataCode: 'GGW',
    icaoCode: 'KGGW',
    name: 'Glasgow',
    country: {code: 'US', name: 'United States'},
    city: 'Wokal Field Glasgow International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 48.2125,
    longitude: -106.615,
    altitude: 699.8208
  },
  {
    iataCode: 'OXB',
    icaoCode: 'GGOV',
    name: 'Bissau',
    country: {code: 'GW', name: 'Guinea-Bissau'},
    city: 'Osvaldo Vieira International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Bissau',
    utcOffset: 0,
    latitude: 11.89485,
    longitude: -15.653681,
    altitude: 39.3192
  },
  {
    iataCode: 'XIL',
    icaoCode: 'ZBXH',
    name: 'Xilinhot',
    country: {code: 'CN', name: 'China'},
    city: 'Xilinhot Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 43.9156,
    longitude: 115.964,
    altitude: 0.0
  },
  {
    iataCode: 'OXF',
    icaoCode: 'EGTK',
    name: 'Oxford',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Kidlington',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.836944,
    longitude: -1.32,
    altitude: 82.296
  },
  {
    iataCode: 'GHB',
    icaoCode: 'MYEM',
    name: "Governor's Harbor",
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Governors Harbour',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.284706,
    longitude: -76.331011,
    altitude: 7.9248
  },
  {
    iataCode: 'GHA',
    icaoCode: 'DAUG',
    name: 'Ghardaia',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Noumerat',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 32.384106,
    longitude: 3.794114,
    altitude: 460.8576
  },
  {
    iataCode: 'GHF',
    icaoCode: 'ETEU',
    name: 'Giebelstadt',
    country: {code: 'DE', name: 'Germany'},
    city: 'Giebelstadt Aaf',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.648131,
    longitude: 9.966494,
    altitude: 298.704
  },
  {
    iataCode: 'OXR',
    icaoCode: 'KOXR',
    name: 'Oxnard',
    country: {code: 'US', name: 'United States'},
    city: 'Oxnard - Ventura County',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.200833,
    longitude: -119.207222,
    altitude: 4.572
  },
  {
    iataCode: 'XIY',
    icaoCode: 'ZLXY',
    name: "Xi'an",
    country: {code: 'CN', name: 'China'},
    city: 'Xianyang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 34.447119,
    longitude: 108.751592,
    altitude: 479.1456
  },
  {
    iataCode: 'GHT',
    icaoCode: 'HLGT',
    name: 'Ghat',
    country: {code: 'LY', name: 'Libya'},
    city: 'Ghat',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 25.145564,
    longitude: 10.142647,
    altitude: 699.8208
  },
  {
    iataCode: 'GHU',
    icaoCode: 'SAAG',
    name: 'Gualeguaychu',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Gualeguaychu',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -33.010278,
    longitude: -58.613056,
    altitude: 22.86
  },
  {
    iataCode: 'OYE',
    icaoCode: 'FOGO',
    name: 'Oyem',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Oyem',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: 1.543108,
    longitude: 11.581361,
    altitude: 657.7584
  },
  {
    iataCode: 'GIC',
    icaoCode: 'YBOI',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Boigu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -9.23278,
    longitude: 142.218,
    altitude: 7.0104
  },
  {
    iataCode: 'GIB',
    icaoCode: 'LXGB',
    name: 'Gibraltar',
    country: {code: 'GI', name: 'Gibraltar'},
    city: 'Gibraltar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Gibraltar',
    utcOffset: 60,
    latitude: 36.151219,
    longitude: -5.349664,
    altitude: 4.572
  },
  {
    iataCode: 'GIG',
    icaoCode: 'SBGL',
    name: 'Rio De Janeiro',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Galeao Antonio Carlos Jobim',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.808903,
    longitude: -43.243647,
    altitude: 8.5344
  },
  {
    iataCode: 'GIL',
    icaoCode: 'OPGT',
    name: 'Gilgit',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Gilgit',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 35.918786,
    longitude: 74.333644,
    altitude: 1461.8208
  },
  {
    iataCode: 'GIS',
    icaoCode: 'NZGS',
    name: 'Gisborne',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Gisborne',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -38.663333,
    longitude: 177.978333,
    altitude: 4.572
  },
  {
    iataCode: 'XKH',
    icaoCode: 'VLXK',
    name: 'Phon Savan',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Xieng Khouang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 19.449997,
    longitude: 103.158333,
    altitude: 1050.036
  },
  {
    iataCode: 'OZA',
    icaoCode: 'KOZA',
    name: 'Ozona',
    country: {code: 'US', name: 'United States'},
    city: 'Ozona Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.735281,
    longitude: -101.202972,
    altitude: 725.7288
  },
  {
    iataCode: 'GIZ',
    icaoCode: 'OEGN',
    name: 'Gizan',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Abdullah Bin Abdulaziz',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 16.901111,
    longitude: 42.585833,
    altitude: 6.096
  },
  {
    iataCode: 'OZC',
    icaoCode: 'RPMO',
    name: 'Ozamis',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Ozamis',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 8.178508,
    longitude: 123.841731,
    altitude: 22.86
  },
  {
    iataCode: 'OZH',
    icaoCode: 'UKDE',
    name: '',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Zaporizhzhia International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 47.867,
    longitude: 35.3157,
    altitude: 113.6904
  },
  {
    iataCode: 'GJA',
    icaoCode: 'MHNJ',
    name: 'Guanaja',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Guanaja',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 16.445367,
    longitude: -85.906611,
    altitude: 14.9352
  },
  {
    iataCode: 'XKS',
    icaoCode: 'CYAQ',
    name: 'Kasabonika',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kasabonika Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -300,
    latitude: 53.5247,
    longitude: -88.6428,
    altitude: 204.8256
  },
  {
    iataCode: 'OZP',
    icaoCode: 'LEMO',
    name: 'Sevilla',
    country: {code: 'ES', name: 'Spain'},
    city: 'Moron Ab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 37.174917,
    longitude: -5.615944,
    altitude: 86.868
  },
  {
    iataCode: 'GJL',
    icaoCode: 'DAAV',
    name: 'Jijel',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Jijel',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.795136,
    longitude: 5.873608,
    altitude: 10.9728
  },
  {
    iataCode: 'GJR',
    icaoCode: 'BIGJ',
    name: 'Gjogur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Gjogur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.995278,
    longitude: -21.326944,
    altitude: 29.8704
  },
  {
    iataCode: 'OZZ',
    icaoCode: 'GMMZ',
    name: 'Ouarzazate',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Ouarzazate',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 30.939053,
    longitude: -6.909431,
    altitude: 1152.7536
  },
  {
    iataCode: 'XLB',
    icaoCode: 'CZWH',
    name: 'Lac Brochet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lac Brochet Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 58.614167,
    longitude: -101.468889,
    altitude: 369.4176
  },
  {
    iataCode: 'GJT',
    icaoCode: 'KGJT',
    name: 'Grand Junction',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Junction Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.122413,
    longitude: -108.526735,
    altitude: 1480.7184
  },
  {
    iataCode: 'GKA',
    icaoCode: 'AYGA',
    name: 'Goroka',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Goroka',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -6.081689,
    longitude: 145.391881,
    altitude: 1609.9536
  },
  {
    iataCode: 'XLS',
    icaoCode: 'GOSS',
    name: 'St. Louis',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Saint Louis',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 16.050761,
    longitude: -16.463172,
    altitude: 2.7432
  },
  {
    iataCode: 'GKE',
    icaoCode: 'ETNG',
    name: 'Geilenkirchen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Geilenkirchen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.960817,
    longitude: 6.042422,
    altitude: 90.2208
  },
  {
    iataCode: 'GKN',
    icaoCode: 'PAGK',
    name: 'Gulkana',
    country: {code: 'US', name: 'United States'},
    city: 'Gulkana',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.154888,
    longitude: -145.456639,
    altitude: 481.584
  },
  {
    iataCode: 'XMH',
    icaoCode: 'NTGI',
    name: 'Manihi',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Manihi',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.436764,
    longitude: -146.070056,
    altitude: 4.2672
  },
  {
    iataCode: 'XMN',
    icaoCode: 'ZSAM',
    name: 'Xiamen',
    country: {code: 'CN', name: 'China'},
    city: 'Gaoqi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 24.544036,
    longitude: 118.127739,
    altitude: 17.9832
  },
  {
    iataCode: 'GLA',
    icaoCode: 'EGPF',
    name: 'Glasgow',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Glasgow',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.871944,
    longitude: -4.433056,
    altitude: 7.9248
  },
  {
    iataCode: 'GLD',
    icaoCode: 'KGLD',
    name: 'Goodland',
    country: {code: 'US', name: 'United States'},
    city: 'Renner Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 39.370621,
    longitude: -101.698992,
    altitude: 1114.3488
  },
  {
    iataCode: 'XMS',
    icaoCode: 'SEMC',
    name: 'Macas',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Coronel E Carvajal',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -2.299167,
    longitude: -78.12075,
    altitude: 1052.1696
  },
  {
    iataCode: 'GLF',
    icaoCode: 'MRGF',
    name: 'Golfito',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Golfito',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 8.653775,
    longitude: -83.180544,
    altitude: 14.9352
  },
  {
    iataCode: 'XMU',
    icaoCode: 'LFHY',
    name: 'Moulins',
    country: {code: 'FR', name: 'France'},
    city: 'Montbeugny',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.534581,
    longitude: 3.423725,
    altitude: 278.892
  },
  {
    iataCode: 'GLH',
    icaoCode: 'GLH',
    name: 'Greenville',
    country: {code: 'US', name: 'United States'},
    city: 'Mid Delta Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.4829,
    longitude: -90.9856,
    altitude: 39.9288
  },
  {
    iataCode: '6K8',
    icaoCode: 'PFTO',
    name: 'Tok',
    country: {code: 'US', name: 'United States'},
    city: 'Tok Junction Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.329444,
    longitude: -142.953611,
    altitude: 499.5672
  },
  {
    iataCode: 'GLJ',
    icaoCode: 'SKGZ',
    name: 'Garzón',
    country: {code: 'CO', name: 'Colombia'},
    city: 'La Jagua Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.17,
    longitude: -75.67,
    altitude: 798.576
  },
  {
    iataCode: 'XMY',
    icaoCode: 'YYMI',
    name: 'Yam Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Yam Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -9.90111,
    longitude: 142.776,
    altitude: 0.0
  },
  {
    iataCode: 'GLK',
    icaoCode: 'HCMR',
    name: 'Galcaio',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Galcaio Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 6.78083,
    longitude: 47.4547,
    altitude: 297.18
  },
  {
    iataCode: 'XNA',
    icaoCode: 'KXNA',
    name: 'Bentonville',
    country: {code: 'US', name: 'United States'},
    city: 'Nw Arkansas Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.281869,
    longitude: -94.306811,
    altitude: 392.2776
  },
  {
    iataCode: 'GLO',
    icaoCode: 'EGBJ',
    name: 'Golouchestershire',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Gloucestershire',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.894167,
    longitude: -2.167222,
    altitude: 30.7848
  },
  {
    iataCode: 'GLT',
    icaoCode: 'YGLA',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Gladstone Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.8697,
    longitude: 151.223,
    altitude: 19.5072
  },
  {
    iataCode: 'GLS',
    icaoCode: 'KGLS',
    name: 'Galveston',
    country: {code: 'US', name: 'United States'},
    city: 'Scholes Intl At Galveston',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.265322,
    longitude: -94.860406,
    altitude: 1.8288
  },
  {
    iataCode: 'GLV',
    icaoCode: 'PAGL',
    name: 'Golovin',
    country: {code: 'US', name: 'United States'},
    city: 'Golovin Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 64.550556,
    longitude: -163.007222,
    altitude: 17.9832
  },
  {
    iataCode: 'XNN',
    icaoCode: 'ZLXN',
    name: 'Xining',
    country: {code: 'CN', name: 'China'},
    city: 'Xining Caojiabu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 36.5275,
    longitude: 102.043,
    altitude: 0.0
  },
  {
    iataCode: 'GMA',
    icaoCode: 'FZFK',
    name: 'Gemena',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Gemena',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: 3.235369,
    longitude: 19.771258,
    altitude: 420.0144
  },
  {
    iataCode: 'GMB',
    icaoCode: 'HAGM',
    name: 'Gambella',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Gambella',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 8.128764,
    longitude: 34.563131,
    altitude: 539.8008
  },
  {
    iataCode: 'GME',
    icaoCode: 'UMGG',
    name: 'Gomel',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Gomel',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 52.527022,
    longitude: 31.016692,
    altitude: 143.5608
  },
  {
    iataCode: 'GMP',
    icaoCode: 'RKSS',
    name: 'Seoul',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Gimpo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.558311,
    longitude: 126.790586,
    altitude: 17.6784
  },
  {
    iataCode: 'GMR',
    icaoCode: 'NTGJ',
    name: 'Totegegie',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Totegegie',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Gambier',
    utcOffset: -540,
    latitude: -23.079861,
    longitude: -134.890333,
    altitude: 2.1336
  },
  {
    iataCode: 'GMZ',
    icaoCode: 'GCGM',
    name: '',
    country: {code: 'ES', name: 'Spain'},
    city: 'La Gomera Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.0296,
    longitude: -17.2146,
    altitude: 218.8464
  },
  {
    iataCode: 'GNB',
    icaoCode: 'LFLS',
    name: 'Grenoble',
    country: {code: 'FR', name: 'France'},
    city: 'Saint Geoirs',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.362944,
    longitude: 5.329375,
    altitude: 396.8496
  },
  {
    iataCode: 'GNA',
    icaoCode: 'UMMG',
    name: 'Hrodna',
    country: {code: 'BY', name: 'Belarus'},
    city: 'Hrodno Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Minsk',
    utcOffset: 120,
    latitude: 53.602,
    longitude: 24.0538,
    altitude: 135.0264
  },
  {
    iataCode: 'GND',
    icaoCode: 'TGPY',
    name: 'Point Salines',
    country: {code: 'GD', name: 'Grenada'},
    city: 'Point Salines Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Grenada',
    utcOffset: -240,
    latitude: 12.004247,
    longitude: -61.786192,
    altitude: 12.4968
  },
  {
    iataCode: 'GNI',
    icaoCode: 'RCGI',
    name: 'Green Island',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Lyudao',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.673853,
    longitude: 121.466481,
    altitude: 8.5344
  },
  {
    iataCode: 'GNM',
    icaoCode: 'SNGI',
    name: 'Guanambi',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Guanambi Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -14.216667,
    longitude: -42.783333,
    altitude: 152.4
  },
  {
    iataCode: 'GNT',
    icaoCode: 'KGNT',
    name: 'Grants',
    country: {code: 'US', name: 'United States'},
    city: 'Grants Milan Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.167286,
    longitude: -107.901989,
    altitude: 1992.4776
  },
  {
    iataCode: 'GNS',
    icaoCode: 'WIMB',
    name: 'Gunung Sitoli',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Binaka',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 1.166381,
    longitude: 97.704681,
    altitude: 6.096
  },
  {
    iataCode: 'GNV',
    icaoCode: 'KGNV',
    name: 'Gainesville',
    country: {code: 'US', name: 'United States'},
    city: 'Gainesville Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 29.690056,
    longitude: -82.271778,
    altitude: 46.3296
  },
  {
    iataCode: 'GNZ',
    icaoCode: 'FBGZ',
    name: 'Ghanzi',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Ghanzi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -21.6925,
    longitude: 21.6581,
    altitude: 1136.904
  },
  {
    iataCode: 'GOA',
    icaoCode: 'LIMJ',
    name: 'Genoa',
    country: {code: 'IT', name: 'Italy'},
    city: 'Genova Sestri',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.413333,
    longitude: 8.8375,
    altitude: 3.9624
  },
  {
    iataCode: 'GOB',
    icaoCode: 'HAGB',
    name: 'Goba',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Robe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 6.733333,
    longitude: 44.266667,
    altitude: 1219.2
  },
  {
    iataCode: 'GOI',
    icaoCode: 'VAGO',
    name: 'Goa',
    country: {code: 'IN', name: 'India'},
    city: 'Goa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 15.380833,
    longitude: 73.831422,
    altitude: 56.0832
  },
  {
    iataCode: 'GOH',
    icaoCode: 'BGGH',
    name: 'Godthaab',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Nuuk',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 64.190922,
    longitude: -51.678064,
    altitude: 86.2584
  },
  {
    iataCode: 'GOJ',
    icaoCode: 'UWGG',
    name: 'Nizhniy Novgorod',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Strigino',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 56.230119,
    longitude: 43.784042,
    altitude: 78.0288
  },
  {
    iataCode: 'GOM',
    icaoCode: 'FZNA',
    name: 'Goma',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Goma',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: -1.670814,
    longitude: 29.238464,
    altitude: 1551.1272
  },
  {
    iataCode: 'GON',
    icaoCode: 'KGON',
    name: 'Groton Ct',
    country: {code: 'US', name: 'United States'},
    city: 'Groton New London',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.330056,
    longitude: -72.045139,
    altitude: 2.7432
  },
  {
    iataCode: 'GOQ',
    icaoCode: 'ZLGM',
    name: 'Golmud',
    country: {code: 'CN', name: 'China'},
    city: 'Golmud Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 34.633,
    longitude: 98.867,
    altitude: 0.0
  },
  {
    iataCode: 'GOP',
    icaoCode: 'VEGK',
    name: 'Gorakhpur',
    country: {code: 'IN', name: 'India'},
    city: 'Gorakhpur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.739708,
    longitude: 83.449708,
    altitude: 78.9432
  },
  {
    iataCode: 'GOR',
    icaoCode: 'HAGR',
    name: 'Gore',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Gore Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 8.167,
    longitude: 35.55,
    altitude: 2005.584
  },
  {
    iataCode: 'GOU',
    icaoCode: 'FKKR',
    name: 'Garoua',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Garoua',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 9.335892,
    longitude: 13.370103,
    altitude: 242.0112
  },
  {
    iataCode: 'GOT',
    icaoCode: 'ESGG',
    name: 'Gothenborg',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Landvetter',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.662836,
    longitude: 12.279819,
    altitude: 154.2288
  },
  {
    iataCode: 'GOV',
    icaoCode: 'YPGV',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Gove Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.2694,
    longitude: 136.818,
    altitude: 58.5216
  },
  {
    iataCode: 'GOY',
    icaoCode: 'VCCG',
    name: 'Galoya',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Amparai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 7.337081,
    longitude: 81.625881,
    altitude: 45.72
  },
  {
    iataCode: 'PAB',
    icaoCode: 'VABI',
    name: 'Bilaspur',
    country: {code: 'IN', name: 'India'},
    city: 'Bilaspur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.9884,
    longitude: 82.110983,
    altitude: 274.0152
  },
  {
    iataCode: 'PAD',
    icaoCode: 'EDLP',
    name: 'Paderborn',
    country: {code: 'DE', name: 'Germany'},
    city: 'Paderborn Lippstadt',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.614089,
    longitude: 8.616317,
    altitude: 213.0552
  },
  {
    iataCode: 'GOZ',
    icaoCode: 'LBGO',
    name: 'Gorna Orechovica',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Gorna Oryahovitsa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 43.151444,
    longitude: 25.712889,
    altitude: 86.868
  },
  {
    iataCode: 'PAC',
    icaoCode: 'MPMG',
    name: 'Panama',
    country: {code: 'PA', name: 'Panama'},
    city: 'Marcos A Gelabert Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 8.973339,
    longitude: -79.555583,
    altitude: 9.4488
  },
  {
    iataCode: 'PAF',
    icaoCode: 'HUPA',
    name: 'Pakuba',
    country: {code: 'UG', name: 'Uganda'},
    city: 'Pakuba Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kampala',
    utcOffset: 180,
    latitude: 2.3275,
    longitude: 31.5,
    altitude: 700.1256
  },
  {
    iataCode: 'PAE',
    icaoCode: 'KPAE',
    name: 'Everett',
    country: {code: 'US', name: 'United States'},
    city: 'Snohomish Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.906342,
    longitude: -122.281564,
    altitude: 184.7088
  },
  {
    iataCode: 'PAH',
    icaoCode: 'KPAH',
    name: 'Paducah',
    country: {code: 'US', name: 'United States'},
    city: 'Barkley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.060288,
    longitude: -88.772958,
    altitude: 124.968
  },
  {
    iataCode: 'XQP',
    icaoCode: 'MRQP',
    name: 'Quepos',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'La Managua',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.443164,
    longitude: -84.129772,
    altitude: 25.908
  },
  {
    iataCode: 'PAG',
    icaoCode: 'RPMP',
    name: '',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Pagadian',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 7.827197,
    longitude: 123.458294,
    altitude: 1.524
  },
  {
    iataCode: 'GPB',
    icaoCode: 'SBGU',
    name: 'Guarapuava',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Tancredo Thomaz De Faria Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -25.383333,
    longitude: -51.45,
    altitude: 60.96
  },
  {
    iataCode: 'PAJ',
    icaoCode: 'OPPC',
    name: 'Parachinar',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Parachinar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 33.9021,
    longitude: 70.0716,
    altitude: 1767.84
  },
  {
    iataCode: 'GPA',
    icaoCode: 'LGRX',
    name: 'Patras',
    country: {code: 'GR', name: 'Greece'},
    city: 'Araxos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.151111,
    longitude: 21.425556,
    altitude: 14.0208
  },
  {
    iataCode: 'PAN',
    icaoCode: 'VTSK',
    name: 'Pattani',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Pattani',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 6.785458,
    longitude: 101.153569,
    altitude: 2.4384
  },
  {
    iataCode: 'PAM',
    icaoCode: 'KPAM',
    name: 'Panama City',
    country: {code: 'US', name: 'United States'},
    city: 'Tyndall Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.069567,
    longitude: -85.575417,
    altitude: 5.1816
  },
  {
    iataCode: 'PAP',
    icaoCode: 'MTPP',
    name: 'Port-au-prince',
    country: {code: 'HT', name: 'Haiti'},
    city: 'Toussaint Louverture Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Port-au-Prince',
    utcOffset: -300,
    latitude: 18.58005,
    longitude: -72.292542,
    altitude: 37.1856
  },
  {
    iataCode: 'GPI',
    icaoCode: 'SKGP',
    name: 'Guapi',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Juan Casiano',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.570133,
    longitude: -77.8986,
    altitude: 49.9872
  },
  {
    iataCode: 'PAQ',
    icaoCode: 'PAAQ',
    name: 'Palmer',
    country: {code: 'US', name: 'United States'},
    city: 'Palmer Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.594914,
    longitude: -149.088711,
    altitude: 73.7616
  },
  {
    iataCode: 'PAT',
    icaoCode: 'VEPT',
    name: 'Patina',
    country: {code: 'IN', name: 'India'},
    city: 'Patna',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.591317,
    longitude: 85.087992,
    altitude: 51.816
  },
  {
    iataCode: 'PAS',
    icaoCode: 'LGPA',
    name: 'Paros',
    country: {code: 'GR', name: 'Greece'},
    city: 'Paros',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.010278,
    longitude: 25.127778,
    altitude: 36.8808
  },
  {
    iataCode: 'PAV',
    icaoCode: 'SBUF',
    name: 'Paulo Alfonso',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Paulo Afonso',
    region: '',
    terminal: '',
    timezoneId: 'America/Maceio',
    utcOffset: -180,
    latitude: -9.400878,
    longitude: -38.250575,
    altitude: 269.1384
  },
  {
    iataCode: 'PAX',
    icaoCode: 'MTPX',
    name: 'Port-de-paix',
    country: {code: 'HT', name: 'Haiti'},
    city: 'Port-de-paix Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Port-au-Prince',
    utcOffset: -300,
    latitude: 19.9336,
    longitude: -72.8486,
    altitude: 2.7432
  },
  {
    iataCode: 'PAZ',
    icaoCode: 'MMPA',
    name: 'Poza Rico',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Tajin',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.602671,
    longitude: -97.460839,
    altitude: 151.4856
  },
  {
    iataCode: 'GPT',
    icaoCode: 'KGPT',
    name: 'Gulfport',
    country: {code: 'US', name: 'United States'},
    city: 'Gulfport-biloxi',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.407278,
    longitude: -89.070111,
    altitude: 8.5344
  },
  {
    iataCode: 'GPS',
    icaoCode: 'SEGS',
    name: 'Galapagos',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Seymour',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Galapagos',
    utcOffset: -360,
    latitude: -0.453758,
    longitude: -90.265914,
    altitude: 63.0936
  },
  {
    iataCode: 'GPZ',
    icaoCode: 'KGPZ',
    name: 'Grand Rapids Mn',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Rapids Itasca County',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.211111,
    longitude: -93.509722,
    altitude: 125.8824
  },
  {
    iataCode: 'PBC',
    icaoCode: 'MMPB',
    name: 'Puebla',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Hermanos Serdan Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.158144,
    longitude: -98.371447,
    altitude: 2243.6328
  },
  {
    iataCode: 'PBD',
    icaoCode: 'VAPR',
    name: 'Porbandar',
    country: {code: 'IN', name: 'India'},
    city: 'Porbandar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.648675,
    longitude: 69.657219,
    altitude: 7.0104
  },
  {
    iataCode: 'PBG',
    icaoCode: 'KPBG',
    name: 'Plattsburgh',
    country: {code: 'US', name: 'United States'},
    city: 'Plattsburgh Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.650944,
    longitude: -73.468139,
    altitude: 71.3232
  },
  {
    iataCode: 'PBF',
    icaoCode: 'KPBF',
    name: 'Pine Bluff',
    country: {code: 'US', name: 'United States'},
    city: 'Grider Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.173142,
    longitude: -91.935597,
    altitude: 62.7888
  },
  {
    iataCode: 'PBI',
    icaoCode: 'KPBI',
    name: 'West Palm Beach',
    country: {code: 'US', name: 'United States'},
    city: 'Palm Beach Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.683161,
    longitude: -80.095589,
    altitude: 5.7912
  },
  {
    iataCode: 'PBH',
    icaoCode: 'VQPR',
    name: 'Thimphu',
    country: {code: 'BT', name: 'Bhutan'},
    city: 'Paro',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Thimphu',
    utcOffset: 360,
    latitude: 27.403192,
    longitude: 89.424606,
    altitude: 2234.7936
  },
  {
    iataCode: 'PBJ',
    icaoCode: 'NVSI',
    name: 'Paama Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Tavie Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -16.439,
    longitude: 168.257,
    altitude: 0.0
  },
  {
    iataCode: 'PBM',
    icaoCode: 'SMJP',
    name: 'Zandery',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Johan A Pengel Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.452831,
    longitude: -55.187783,
    altitude: 17.9832
  },
  {
    iataCode: 'PBL',
    icaoCode: 'SVPC',
    name: 'Puerto Cabello',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'General Bartolome Salom Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.4805,
    longitude: -68.073025,
    altitude: 9.7536
  },
  {
    iataCode: 'PBO',
    icaoCode: 'YPBO',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Paraburdoo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -23.1711,
    longitude: 117.745,
    altitude: 428.5488
  },
  {
    iataCode: 'PBN',
    icaoCode: 'FNPA',
    name: 'Porto Amboim',
    country: {code: 'AO', name: 'Angola'},
    city: 'Porto Amboim',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -10.721956,
    longitude: 13.765528,
    altitude: 4.8768
  },
  {
    iataCode: 'XRY',
    icaoCode: 'LEJR',
    name: 'Jerez',
    country: {code: 'ES', name: 'Spain'},
    city: 'Jerez',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 36.744622,
    longitude: -6.060111,
    altitude: 28.3464
  },
  {
    iataCode: 'PBP',
    icaoCode: 'MRIA',
    name: 'Nandayure',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Islita Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.85611,
    longitude: -85.3708,
    altitude: 2.1336
  },
  {
    iataCode: 'PBR',
    icaoCode: 'MGPB',
    name: 'Puerto Barrios',
    country: {code: 'GT', name: 'Guatemala'},
    city: 'Puerto Barrios Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guatemala',
    utcOffset: -360,
    latitude: 15.730878,
    longitude: -88.583767,
    altitude: 10.0584
  },
  {
    iataCode: 'PBU',
    icaoCode: 'VYPT',
    name: 'Putao',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Putao',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 27.329922,
    longitude: 97.426269,
    altitude: 457.2
  },
  {
    iataCode: 'PBV',
    icaoCode: 'PAPB',
    name: 'Point Barrow',
    country: {code: 'US', name: 'United States'},
    city: 'St George',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -660,
    latitude: 56.578344,
    longitude: -169.661611,
    altitude: 38.1
  },
  {
    iataCode: 'XSC',
    icaoCode: 'MBSC',
    name: 'South Caicos',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'South Caicos',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.515739,
    longitude: -71.528528,
    altitude: 1.8288
  },
  {
    iataCode: 'PCB',
    icaoCode: 'WIHP',
    name: 'Jakarta',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pondok Cabe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.336964,
    longitude: 106.764561,
    altitude: 60.96
  },
  {
    iataCode: 'PCA',
    icaoCode: 'MMPC',
    name: 'Pachuca',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ingeniero Juan Guillermo Villasana',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.0772,
    longitude: -98.782814,
    altitude: 2316.48
  },
  {
    iataCode: 'XSI',
    icaoCode: 'CZSN',
    name: 'South Indian Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'South Indian Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 56.792778,
    longitude: -98.907222,
    altitude: 289.8648
  },
  {
    iataCode: 'XSP',
    icaoCode: 'WSSL',
    name: 'Singapore',
    country: {code: 'SG', name: 'Singapore'},
    city: 'Seletar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Singapore',
    utcOffset: 480,
    latitude: 1.41695,
    longitude: 103.867653,
    altitude: 10.9728
  },
  {
    iataCode: 'GRB',
    icaoCode: 'KGRB',
    name: 'Green Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Austin Straubel Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.485072,
    longitude: -88.129589,
    altitude: 211.836
  },
  {
    iataCode: 'PCL',
    icaoCode: 'SPCL',
    name: 'Pucallpa',
    country: {code: 'PE', name: 'Peru'},
    city: 'Cap Fap David Abenzur Rengifo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -8.377939,
    longitude: -74.574297,
    altitude: 156.3624
  },
  {
    iataCode: 'GRF',
    icaoCode: 'KGRF',
    name: 'Fort Lewis',
    country: {code: 'US', name: 'United States'},
    city: 'Gray Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.079217,
    longitude: -122.580783,
    altitude: 92.0496
  },
  {
    iataCode: 'PCN',
    icaoCode: 'NZPN',
    name: 'Picton',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Koromiko',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -41.348333,
    longitude: 173.955278,
    altitude: 18.288
  },
  {
    iataCode: 'PCP',
    icaoCode: 'FPPR',
    name: 'Principe',
    country: {code: 'ST', name: 'Sao Tome and Principe'},
    city: 'Principe',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Sao_Tome',
    utcOffset: 0,
    latitude: 1.662936,
    longitude: 7.411742,
    altitude: 180.1368
  },
  {
    iataCode: 'GRJ',
    icaoCode: 'FAGG',
    name: 'George',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'George',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -34.005553,
    longitude: 22.378889,
    altitude: 197.5104
  },
  {
    iataCode: 'PCR',
    icaoCode: 'SKPC',
    name: 'Puerto Carreno',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Puerto Carreno',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -240,
    latitude: 6.184717,
    longitude: -67.493164,
    altitude: 53.9496
  },
  {
    iataCode: 'GRI',
    icaoCode: 'KGRI',
    name: 'Grand Island',
    country: {code: 'US', name: 'United States'},
    city: 'Central Nebraska Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.9675,
    longitude: -98.3096,
    altitude: 562.9656
  },
  {
    iataCode: 'GRK',
    icaoCode: 'KGRK',
    name: 'Killeen',
    country: {code: 'US', name: 'United States'},
    city: 'Robert Gray Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.06725,
    longitude: -97.828917,
    altitude: 309.372
  },
  {
    iataCode: 'GRM',
    icaoCode: 'KCKC',
    name: 'Grand Marais',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Marais Cook County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.838333,
    longitude: -90.382944,
    altitude: 548.3352
  },
  {
    iataCode: 'GRP',
    icaoCode: 'SWGI',
    name: 'Gurupi',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Gurupi Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -11.728889,
    longitude: -49.068889,
    altitude: 3.048
  },
  {
    iataCode: 'GRO',
    icaoCode: 'LEGE',
    name: 'Gerona',
    country: {code: 'ES', name: 'Spain'},
    city: 'Girona',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.900969,
    longitude: 2.760547,
    altitude: 142.6464
  },
  {
    iataCode: 'GRR',
    icaoCode: 'KGRR',
    name: 'Grand Rapids',
    country: {code: 'US', name: 'United States'},
    city: 'Gerald R Ford Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.880833,
    longitude: -85.522806,
    altitude: 242.0112
  },
  {
    iataCode: 'GRQ',
    icaoCode: 'EHGG',
    name: 'Groningen',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Eelde',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 53.11972,
    longitude: 6.579444,
    altitude: 5.1816
  },
  {
    iataCode: 'GRS',
    icaoCode: 'LIRS',
    name: 'Grosseto',
    country: {code: 'IT', name: 'Italy'},
    city: 'Grosseto',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 42.759747,
    longitude: 11.071897,
    altitude: 4.572
  },
  {
    iataCode: 'GRV',
    icaoCode: 'URMG',
    name: 'Grozny',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Grozny Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 43.2981,
    longitude: 45.7841,
    altitude: 167.0304
  },
  {
    iataCode: 'XTG',
    icaoCode: 'YTGM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Thargomindah Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -27.9864,
    longitude: 143.811,
    altitude: 131.9784
  },
  {
    iataCode: 'GRU',
    icaoCode: 'SBGR',
    name: 'Sao Paulo',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Guarulhos Gov Andre Franco Montouro',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -23.432075,
    longitude: -46.469511,
    altitude: 749.5032
  },
  {
    iataCode: 'GRX',
    icaoCode: 'LEGR',
    name: 'Granada',
    country: {code: 'ES', name: 'Spain'},
    city: 'Granada',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 37.188731,
    longitude: -3.777356,
    altitude: 566.928
  },
  {
    iataCode: 'PDA',
    icaoCode: 'SKPD',
    name: 'Puerto Inírida',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Obando Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 3.85,
    longitude: -67.91,
    altitude: 140.208
  },
  {
    iataCode: 'GRW',
    icaoCode: 'LPGR',
    name: 'Graciosa',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Graciosa',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 39.092169,
    longitude: -28.029847,
    altitude: 26.2128
  },
  {
    iataCode: 'GRZ',
    icaoCode: 'LOWG',
    name: 'Graz',
    country: {code: 'AT', name: 'Austria'},
    city: 'Graz',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 46.991067,
    longitude: 15.439628,
    altitude: 339.852
  },
  {
    iataCode: 'GRY',
    icaoCode: 'BIGR',
    name: 'Grímsey',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Grímsey Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 66.5547,
    longitude: -18.0175,
    altitude: 20.1168
  },
  {
    iataCode: 'XTL',
    icaoCode: 'CYBQ',
    name: 'Tadoule Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tadoule Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 58.706111,
    longitude: -98.512222,
    altitude: 281.3304
  },
  {
    iataCode: 'PDG',
    icaoCode: 'WIMG',
    name: 'Padang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Tabing',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -0.874989,
    longitude: 100.351881,
    altitude: 2.7432
  },
  {
    iataCode: 'PDK',
    icaoCode: 'KPDK',
    name: 'Atlanta',
    country: {code: 'US', name: 'United States'},
    city: 'Dekalb-peachtree Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 33.875611,
    longitude: -84.301972,
    altitude: 305.7144
  },
  {
    iataCode: 'GSB',
    icaoCode: 'KGSB',
    name: 'Goldsboro',
    country: {code: 'US', name: 'United States'},
    city: 'Seymour Johnson Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.339383,
    longitude: -77.960589,
    altitude: 33.528
  },
  {
    iataCode: 'GSE',
    icaoCode: 'ESGP',
    name: 'Gothenborg',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Save',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.774722,
    longitude: 11.870372,
    altitude: 17.9832
  },
  {
    iataCode: 'PDL',
    icaoCode: 'LPPD',
    name: 'Ponta Delgada',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Ponta Delgada',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 37.741184,
    longitude: -25.69787,
    altitude: 78.9432
  },
  {
    iataCode: 'PDO',
    icaoCode: 'WIPQ',
    name: 'Talang Gudang-sumatra Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pendopo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -3.286069,
    longitude: 103.8796,
    altitude: 56.0832
  },
  {
    iataCode: 'PDP',
    icaoCode: 'SULS',
    name: 'Punta Del Este',
    country: {code: 'UY', name: 'Uruguay'},
    city: 'Capitan Corbeta C A Curbelo International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montevideo',
    utcOffset: -180,
    latitude: -34.855139,
    longitude: -55.094278,
    altitude: 28.956
  },
  {
    iataCode: 'PDS',
    icaoCode: 'MMPG',
    name: 'Piedras Negras',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Piedras Negras Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Matamoros',
    utcOffset: -360,
    latitude: 28.627394,
    longitude: -100.535211,
    altitude: 274.6248
  },
  {
    iataCode: 'PDT',
    icaoCode: 'KPDT',
    name: 'Pendleton',
    country: {code: 'US', name: 'United States'},
    city: 'Eastern Oregon Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 45.695,
    longitude: -118.841389,
    altitude: 456.2856
  },
  {
    iataCode: 'GSO',
    icaoCode: 'KGSO',
    name: 'Greensboro',
    country: {code: 'US', name: 'United States'},
    city: 'Piedmont Triad',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.09775,
    longitude: -79.937306,
    altitude: 281.94
  },
  {
    iataCode: 'PDV',
    icaoCode: 'LBPD',
    name: 'Plovdiv',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Plovdiv',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 42.067806,
    longitude: 24.850833,
    altitude: 181.9656
  },
  {
    iataCode: 'GSQ',
    icaoCode: 'HEOW',
    name: 'Sharq Al-owainat',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Sharq Al-owainat Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 22.5806,
    longitude: 28.7207,
    altitude: 261.8232
  },
  {
    iataCode: 'GSP',
    icaoCode: 'KGSP',
    name: 'Greenville',
    country: {code: 'US', name: 'United States'},
    city: 'Greenville-spartanburg International',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.895556,
    longitude: -82.218889,
    altitude: 293.8272
  },
  {
    iataCode: 'PDX',
    icaoCode: 'KPDX',
    name: 'Portland',
    country: {code: 'US', name: 'United States'},
    city: 'Portland Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 45.588722,
    longitude: -122.5975,
    altitude: 9.144
  },
  {
    iataCode: 'GST',
    icaoCode: 'PAGS',
    name: 'Gustavus',
    country: {code: 'US', name: 'United States'},
    city: 'Gustavus Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 58.4253,
    longitude: -135.707,
    altitude: 10.3632
  },
  {
    iataCode: 'PED',
    icaoCode: 'LKPD',
    name: 'Pardubice',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Pardubice',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 50.013419,
    longitude: 15.738647,
    altitude: 225.8568
  },
  {
    iataCode: 'PEE',
    icaoCode: 'USPP',
    name: 'Perm',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Bolshoye Savino',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 57.914517,
    longitude: 56.021214,
    altitude: 123.1392
  },
  {
    iataCode: '6S0',
    icaoCode: 'K6S0',
    name: 'Big Timber',
    country: {code: 'US', name: 'United States'},
    city: 'Big Timber Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 45.806389,
    longitude: -109.981111,
    altitude: 1369.1616
  },
  {
    iataCode: 'PEG',
    icaoCode: 'LIRZ',
    name: 'Perugia',
    country: {code: 'IT', name: 'Italy'},
    city: 'Perugia',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 43.095906,
    longitude: 12.513222,
    altitude: 211.2264
  },
  {
    iataCode: 'GTB',
    icaoCode: 'KGTB',
    name: 'Fort Drum',
    country: {code: 'US', name: 'United States'},
    city: 'Wheeler Sack Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.055619,
    longitude: -75.719458,
    altitude: 210.312
  },
  {
    iataCode: 'PEI',
    icaoCode: 'SKPE',
    name: 'Pereira',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Matecana',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.812675,
    longitude: -75.739519,
    altitude: 1345.9968
  },
  {
    iataCode: 'PEK',
    icaoCode: 'ZBAA',
    name: 'Beijing',
    country: {code: 'CN', name: 'China'},
    city: 'Capital Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 40.080111,
    longitude: 116.584556,
    altitude: 35.3568
  },
  {
    iataCode: 'GTF',
    icaoCode: 'KGTF',
    name: 'Great Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Great Falls Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 47.482,
    longitude: -111.370689,
    altitude: 1120.7496
  },
  {
    iataCode: 'PEN',
    icaoCode: 'WMKP',
    name: 'Penang',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Penang Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 5.297139,
    longitude: 100.276864,
    altitude: 3.3528
  },
  {
    iataCode: 'GTE',
    icaoCode: 'YGTE',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Groote Eylandt Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -13.975,
    longitude: 136.46,
    altitude: 16.1544
  },
  {
    iataCode: 'PEM',
    icaoCode: 'SPTU',
    name: 'Puerto Maldonado',
    country: {code: 'PE', name: 'Peru'},
    city: 'Padre Aldamiz',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -12.613611,
    longitude: -69.228611,
    altitude: 200.8632
  },
  {
    iataCode: 'PER',
    icaoCode: 'YPPH',
    name: 'Perth',
    country: {code: 'AU', name: 'Australia'},
    city: 'Perth Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -31.940278,
    longitude: 115.966944,
    altitude: 20.4216
  },
  {
    iataCode: 'XUZ',
    icaoCode: 'ZSXZ',
    name: 'Xuzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Xuzhou Guanyin Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 34.16,
    longitude: 117.11,
    altitude: 42.672
  },
  {
    iataCode: 'GTI',
    icaoCode: 'EDCG',
    name: 'Ruegen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Guettin Mecklenburgvorpommern Germany',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 54.383333,
    longitude: 13.325278,
    altitude: 21.0312
  },
  {
    iataCode: 'PET',
    icaoCode: 'SBPK',
    name: 'Pelotas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Pelotas',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -31.718353,
    longitude: -52.327689,
    altitude: 17.9832
  },
  {
    iataCode: 'PES',
    icaoCode: 'ULPB',
    name: 'Petrozavodsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Petrozavodsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 61.8852,
    longitude: 34.1547,
    altitude: 46.0248
  },
  {
    iataCode: 'GTN',
    icaoCode: 'NZMC',
    name: 'Mount Cook',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Mount Cook',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -43.764999,
    longitude: 170.133333,
    altitude: 656.2344
  },
  {
    iataCode: 'PEV',
    icaoCode: 'LHPP',
    name: 'Pécs-pogány',
    country: {code: 'HU', name: 'Hungary'},
    city: 'Pécs-pogány Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Budapest',
    utcOffset: 60,
    latitude: 45.990928,
    longitude: 18.240983,
    altitude: 304.8
  },
  {
    iataCode: 'PEU',
    icaoCode: 'MHPL',
    name: 'Puerto Lempira',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Puerto Lempira Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.2622,
    longitude: -83.7812,
    altitude: 7.62
  },
  {
    iataCode: 'GTO',
    icaoCode: 'WAMG',
    name: 'Gorontalo',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Jalaluddin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: 0.637119,
    longitude: 122.849858,
    altitude: 32.004
  },
  {
    iataCode: 'PEW',
    icaoCode: 'OPPS',
    name: 'Peshawar',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Peshawar Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 33.993911,
    longitude: 71.514581,
    altitude: 352.9584
  },
  {
    iataCode: 'GTR',
    icaoCode: 'KGTR',
    name: 'Columbus Mississippi',
    country: {code: 'US', name: 'United States'},
    city: 'Golden Triangle Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.450333,
    longitude: -88.591361,
    altitude: 80.4672
  },
  {
    iataCode: 'PEZ',
    icaoCode: 'UWPP',
    name: 'Penza',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Penza Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 53.1106,
    longitude: 45.0211,
    altitude: 187.1472
  },
  {
    iataCode: 'XVF',
    icaoCode: 'LFHV',
    name: 'Vilefrance',
    country: {code: 'FR', name: 'France'},
    city: 'Tarare',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.901947,
    longitude: 4.634906,
    altitude: 327.9648
  },
  {
    iataCode: 'PFB',
    icaoCode: 'SBPF',
    name: 'Passo Fundo',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Lauro Kurtz',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -28.243989,
    longitude: -52.326558,
    altitude: 724.2048
  },
  {
    iataCode: 'GUA',
    icaoCode: 'MGGT',
    name: 'Guatemala City',
    country: {code: 'GT', name: 'Guatemala'},
    city: 'La Aurora',
    region: '',
    terminal: '',
    timezoneId: 'America/Guatemala',
    utcOffset: -360,
    latitude: 14.583272,
    longitude: -90.527475,
    altitude: 1509.3696
  },
  {
    iataCode: 'GUC',
    icaoCode: 'KGUC',
    name: 'Gunnison',
    country: {code: 'US', name: 'United States'},
    city: 'Gunnison - Crested Butte',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.533889,
    longitude: -106.933056,
    altitude: 2340.2544
  },
  {
    iataCode: 'GUB',
    icaoCode: 'MMGR',
    name: 'Guerrero Negro',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Guerrero Negro Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santa_Isabel',
    utcOffset: -420,
    latitude: 28.0261,
    longitude: -114.024,
    altitude: 17.9832
  },
  {
    iataCode: 'PFJ',
    icaoCode: 'BIPA',
    name: 'Patreksfjordur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Patreksfjordur',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.555833,
    longitude: -23.965,
    altitude: 3.3528
  },
  {
    iataCode: 'PFO',
    icaoCode: 'LCPH',
    name: 'Paphos',
    country: {code: 'CY', name: 'Cyprus'},
    city: 'Pafos Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Nicosia',
    utcOffset: 120,
    latitude: 34.718039,
    longitude: 32.485731,
    altitude: 12.4968
  },
  {
    iataCode: 'PFN',
    icaoCode: 'KPFN',
    name: 'Panama City',
    country: {code: 'US', name: 'United States'},
    city: 'Panama City Bay Co Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.212083,
    longitude: -85.682806,
    altitude: 6.096
  },
  {
    iataCode: 'GUI',
    icaoCode: 'SVGI',
    name: 'Guiria',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Guiria',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.574078,
    longitude: -62.312667,
    altitude: 12.8016
  },
  {
    iataCode: 'PFQ',
    icaoCode: 'OITP',
    name: 'Parsabad',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Parsabade Moghan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 39.603606,
    longitude: 47.8815,
    altitude: 76.5048
  },
  {
    iataCode: 'PFR',
    icaoCode: 'FZVS',
    name: 'Ilebo',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Ilebo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 60,
    latitude: -4.333,
    longitude: 20.583,
    altitude: 441.96
  },
  {
    iataCode: 'GUM',
    icaoCode: 'PGUM',
    name: 'Agana',
    country: {code: 'GU', name: 'Guam'},
    city: 'Guam Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guam',
    utcOffset: 600,
    latitude: 13.48345,
    longitude: 144.795983,
    altitude: 90.8304
  },
  {
    iataCode: 'GUL',
    icaoCode: 'YGLB',
    name: 'Goulburn',
    country: {code: 'AU', name: 'Australia'},
    city: 'Goulburn Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -34.8103,
    longitude: 149.726,
    altitude: 652.5768
  },
  {
    iataCode: 'GUQ',
    icaoCode: 'SVGU',
    name: 'Guanare',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Guanare',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.026944,
    longitude: -69.75515,
    altitude: 184.7088
  },
  {
    iataCode: 'GUP',
    icaoCode: 'KGUP',
    name: 'Gallup',
    country: {code: 'US', name: 'United States'},
    city: 'Gallup Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.511058,
    longitude: -108.789308,
    altitude: 1972.6656
  },
  {
    iataCode: 'GUS',
    icaoCode: 'KGUS',
    name: 'Peru',
    country: {code: 'US', name: 'United States'},
    city: 'Grissom Arb',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 40.648094,
    longitude: -86.152119,
    altitude: 247.4976
  },
  {
    iataCode: 'GUR',
    icaoCode: 'AYGN',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Gurney Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -10.3115,
    longitude: 150.334,
    altitude: 26.8224
  },
  {
    iataCode: 'GUT',
    icaoCode: 'ETUO',
    name: 'Guetersloh',
    country: {code: 'DE', name: 'Germany'},
    city: 'Gutersloh',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.922833,
    longitude: 8.306333,
    altitude: 71.9328
  },
  {
    iataCode: 'GUW',
    icaoCode: 'UATG',
    name: 'Atyrau',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Atyrau',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Oral',
    utcOffset: 300,
    latitude: 47.121944,
    longitude: 51.821389,
    altitude: 0.0
  },
  {
    iataCode: 'PGA',
    icaoCode: 'KPGA',
    name: 'Page',
    country: {code: 'US', name: 'United States'},
    city: 'Page Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 36.9261,
    longitude: -111.4483,
    altitude: 1315.5168
  },
  {
    iataCode: 'PGD',
    icaoCode: 'KPGD',
    name: 'Punta Gorda',
    country: {code: 'US', name: 'United States'},
    city: 'Charlotte County-punta Gorda Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.919722,
    longitude: -81.990556,
    altitude: 7.9248
  },
  {
    iataCode: 'PGF',
    icaoCode: 'LFMP',
    name: 'Perpignan',
    country: {code: 'FR', name: 'France'},
    city: 'Rivesaltes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 42.740442,
    longitude: 2.870667,
    altitude: 43.8912
  },
  {
    iataCode: 'PGH',
    icaoCode: 'VIPT',
    name: 'Nainital',
    country: {code: 'IN', name: 'India'},
    city: 'Pantnagar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 29.033408,
    longitude: 79.473744,
    altitude: 234.3912
  },
  {
    iataCode: 'GVA',
    icaoCode: 'LSGG',
    name: 'Geneva',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Geneva Cointrin',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.238064,
    longitude: 6.10895,
    altitude: 430.0728
  },
  {
    iataCode: 'PGK',
    icaoCode: 'WIPK',
    name: 'Pangkal Pinang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Depati Amir',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -2.1622,
    longitude: 106.139064,
    altitude: 33.2232
  },
  {
    iataCode: 'PGV',
    icaoCode: 'KPGV',
    name: 'Greenville',
    country: {code: 'US', name: 'United States'},
    city: 'Pitt-greenville Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.635278,
    longitude: -77.385278,
    altitude: 8.2296
  },
  {
    iataCode: 'PGU',
    icaoCode: 'OIBP',
    name: 'Khalije Fars',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Persian Gulf Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 27.379444,
    longitude: 52.7375,
    altitude: 8.2296
  },
  {
    iataCode: 'PGX',
    icaoCode: 'LFBX',
    name: 'Perigueux',
    country: {code: 'FR', name: 'France'},
    city: 'Bassillac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.198055,
    longitude: 0.815556,
    altitude: 99.9744
  },
  {
    iataCode: 'GVR',
    icaoCode: 'SBGV',
    name: 'Governador Valadares',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Governador Valadares Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -18.8952,
    longitude: -41.9822,
    altitude: 170.9928
  },
  {
    iataCode: 'GVT',
    icaoCode: 'KGVT',
    name: 'Greenvile',
    country: {code: 'US', name: 'United States'},
    city: 'Majors',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.067839,
    longitude: -96.065333,
    altitude: 163.068
  },
  {
    iataCode: 'GVX',
    icaoCode: 'ESSK',
    name: 'Gavle',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Gavle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 60.593333,
    longitude: 16.951389,
    altitude: 68.2752
  },
  {
    iataCode: 'PHA',
    icaoCode: 'VVPR',
    name: 'Phan Rang',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Phan Rang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 11.6335,
    longitude: 108.952,
    altitude: 30.7848
  },
  {
    iataCode: 'PHC',
    icaoCode: 'DNPO',
    name: 'Port Hartcourt',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Port Harcourt Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 5.015494,
    longitude: 6.949594,
    altitude: 26.5176
  },
  {
    iataCode: 'PHE',
    icaoCode: 'YPPD',
    name: 'Port Hedland',
    country: {code: 'AU', name: 'Australia'},
    city: 'Port Hedland Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -20.377778,
    longitude: 118.626389,
    altitude: 10.0584
  },
  {
    iataCode: 'PHF',
    icaoCode: 'KPHF',
    name: 'Newport News',
    country: {code: 'US', name: 'United States'},
    city: 'Newport News Williamsburg Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.131894,
    longitude: -76.492989,
    altitude: 13.1064
  },
  {
    iataCode: 'GWE',
    icaoCode: 'FVTL',
    name: 'Gwert',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Gweru Thornhill',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -19.436394,
    longitude: 29.861911,
    altitude: 1426.464
  },
  {
    iataCode: 'GWD',
    icaoCode: 'OPGD',
    name: 'Gwadar',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Gwadar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.233308,
    longitude: 62.329494,
    altitude: 10.9728
  },
  {
    iataCode: 'PHL',
    icaoCode: 'KPHL',
    name: 'Philadelphia',
    country: {code: 'US', name: 'United States'},
    city: 'Philadelphia Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.871944,
    longitude: -75.241139,
    altitude: 10.9728
  },
  {
    iataCode: 'PHO',
    icaoCode: 'PPHO',
    name: 'Point Hope',
    country: {code: 'US', name: 'United States'},
    city: 'Point Hope Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 68.3488,
    longitude: -166.799,
    altitude: 3.6576
  },
  {
    iataCode: 'PHN',
    icaoCode: 'KPHN',
    name: 'Port Huron',
    country: {code: 'US', name: 'United States'},
    city: 'St Clair Co Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.910957,
    longitude: -82.528862,
    altitude: 198.12
  },
  {
    iataCode: 'PHS',
    icaoCode: 'VTPP',
    name: 'Phitsanulok',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Phitsanulok',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 16.782939,
    longitude: 100.279122,
    altitude: 46.9392
  },
  {
    iataCode: 'GWL',
    icaoCode: 'VIGR',
    name: 'Gwalior',
    country: {code: 'IN', name: 'India'},
    city: 'Gwalior',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.293336,
    longitude: 78.227753,
    altitude: 188.0616
  },
  {
    iataCode: 'GWO',
    icaoCode: 'KGWO',
    name: 'Greenwood',
    country: {code: 'US', name: 'United States'},
    city: 'Greenwood Leflore',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.494328,
    longitude: -90.084706,
    altitude: 49.3776
  },
  {
    iataCode: 'PHW',
    icaoCode: 'FAPH',
    name: 'Phalaborwa',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Phalaborwa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -23.937166,
    longitude: 31.15539,
    altitude: 436.4736
  },
  {
    iataCode: 'PHY',
    icaoCode: 'VTPB',
    name: 'Phetchabun',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Phetchabun',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 16.676028,
    longitude: 101.195108,
    altitude: 137.16
  },
  {
    iataCode: 'PHX',
    icaoCode: 'KPHX',
    name: 'Phoenix',
    country: {code: 'US', name: 'United States'},
    city: 'Phoenix Sky Harbor Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 33.434278,
    longitude: -112.011583,
    altitude: 345.948
  },
  {
    iataCode: 'XYA',
    icaoCode: 'AGGY',
    name: 'Yandina',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Yandina Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.086111,
    longitude: 159.218889,
    altitude: 1.8288
  },
  {
    iataCode: 'GWT',
    icaoCode: 'EDXW',
    name: 'Westerland',
    country: {code: 'DE', name: 'Germany'},
    city: 'Westerland Sylt',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 54.91325,
    longitude: 8.340472,
    altitude: 15.5448
  },
  {
    iataCode: 'GWY',
    icaoCode: 'EICM',
    name: 'Galway',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Galway',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.300175,
    longitude: -8.941592,
    altitude: 24.6888
  },
  {
    iataCode: 'PIB',
    icaoCode: 'KPIB',
    name: 'Hattiesburg/laurel',
    country: {code: 'US', name: 'United States'},
    city: 'Hattiesburg Laurel Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.4671,
    longitude: -89.3371,
    altitude: 90.8304
  },
  {
    iataCode: 'PIA',
    icaoCode: 'KPIA',
    name: 'Peoria',
    country: {code: 'US', name: 'United States'},
    city: 'Peoria Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.664203,
    longitude: -89.693258,
    altitude: 201.168
  },
  {
    iataCode: 'PID',
    icaoCode: 'MYPI',
    name: 'Nassau',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Nassau Paradise Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.083,
    longitude: -77.3,
    altitude: 0.0
  },
  {
    iataCode: 'PIF',
    icaoCode: 'RCDC',
    name: 'Pingtung',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Pingtung South',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.672367,
    longitude: 120.461728,
    altitude: 23.7744
  },
  {
    iataCode: 'PIE',
    icaoCode: 'KPIE',
    name: 'St. Petersburg',
    country: {code: 'US', name: 'United States'},
    city: 'St Petersburg Clearwater Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 27.910167,
    longitude: -82.687389,
    altitude: 3.048
  },
  {
    iataCode: 'PIH',
    icaoCode: 'KPIH',
    name: 'Pocatello',
    country: {code: 'US', name: 'United States'},
    city: 'Pocatello Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 42.9098,
    longitude: -112.596,
    altitude: 1356.9696
  },
  {
    iataCode: 'PIK',
    icaoCode: 'EGPK',
    name: 'Prestwick',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Prestwick',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.509444,
    longitude: -4.586667,
    altitude: 19.812
  },
  {
    iataCode: 'GXF',
    icaoCode: 'OYSY',
    name: 'Sayun Intl',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Sayun International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 15.966111,
    longitude: 48.7883,
    altitude: 639.1656
  },
  {
    iataCode: 'PIN',
    icaoCode: 'SWPI',
    name: 'Parintins',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Julio Belem Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -2.627778,
    longitude: -56.735833,
    altitude: 152.4
  },
  {
    iataCode: 'PIP',
    icaoCode: 'PAPN',
    name: 'Pilot Point',
    country: {code: 'US', name: 'United States'},
    city: 'Pilot Point Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 57.585393,
    longitude: -157.571944,
    altitude: 17.3736
  },
  {
    iataCode: 'GXG',
    icaoCode: 'FNNG',
    name: 'Negage',
    country: {code: 'AO', name: 'Angola'},
    city: 'Negage',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Luanda',
    utcOffset: 60,
    latitude: -7.754506,
    longitude: 15.287728,
    altitude: 1251.204
  },
  {
    iataCode: 'PIO',
    icaoCode: 'SPSO',
    name: 'Pisco',
    country: {code: 'PE', name: 'Peru'},
    city: 'Pisco Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -13.744864,
    longitude: -76.220284,
    altitude: 11.8872
  },
  {
    iataCode: 'PIR',
    icaoCode: 'KPIR',
    name: 'Pierre',
    country: {code: 'US', name: 'United States'},
    city: 'Pierre Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.3827,
    longitude: -100.286,
    altitude: 530.9616
  },
  {
    iataCode: 'PIT',
    icaoCode: 'KPIT',
    name: 'Pittsburgh (Pennsylva)',
    country: {code: 'US', name: 'United States'},
    city: 'Pittsburgh Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.491467,
    longitude: -80.232872,
    altitude: 366.9792
  },
  {
    iataCode: 'PIS',
    icaoCode: 'LFBI',
    name: 'Poitiers',
    country: {code: 'FR', name: 'France'},
    city: 'Biard',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.587745,
    longitude: 0.306666,
    altitude: 128.9304
  },
  {
    iataCode: 'PIU',
    icaoCode: 'SPUR',
    name: 'Piura',
    country: {code: 'PE', name: 'Peru'},
    city: 'Capitan Fap Guillermo Concha Iberico',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -5.20575,
    longitude: -80.616444,
    altitude: 36.576
  },
  {
    iataCode: 'PIX',
    icaoCode: 'LPPI',
    name: 'Pico',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Pico',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 38.554333,
    longitude: -28.441333,
    altitude: 33.2232
  },
  {
    iataCode: 'PIZ',
    icaoCode: 'PPIZ',
    name: 'Point Lay',
    country: {code: 'US', name: 'United States'},
    city: 'Point Lay Lrrs',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 69.732875,
    longitude: -163.005342,
    altitude: 7.62
  },
  {
    iataCode: 'GXQ',
    icaoCode: 'SCCY',
    name: 'Coyhaique',
    country: {code: 'CL', name: 'Chile'},
    city: 'Teniente Vidal',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -45.594211,
    longitude: -72.106133,
    altitude: 310.896
  },
  {
    iataCode: 'PJA',
    icaoCode: 'ESUP',
    name: '',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Pajala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 67.2456,
    longitude: 23.0689,
    altitude: 165.2016
  },
  {
    iataCode: 'PJC',
    icaoCode: 'SGPJ',
    name: 'Pedro Juan Caballero',
    country: {code: 'PY', name: 'Paraguay'},
    city: 'Dr. Augusto Roberto Fuster International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Asuncion',
    utcOffset: -180,
    latitude: -22.641389,
    longitude: -55.829722,
    altitude: 570.8904
  },
  {
    iataCode: 'PJG',
    icaoCode: 'OPPG',
    name: 'Panjgur',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Panjgur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 26.954547,
    longitude: 64.132517,
    altitude: 1002.4872
  },
  {
    iataCode: 'GYA',
    icaoCode: 'SLGY',
    name: 'Guayaramerín',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Capitán De Av. Emilio Beltrán Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -10.8206,
    longitude: -65.3456,
    altitude: 169.7736
  },
  {
    iataCode: 'GYE',
    icaoCode: 'SEGU',
    name: 'Guayaquil',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Jose Joaquin De Olmedo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -2.157419,
    longitude: -79.883558,
    altitude: 5.7912
  },
  {
    iataCode: 'PJM',
    icaoCode: 'MRPJ',
    name: 'Puerto Jimenez',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Puerto Jimenez Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 8.53333,
    longitude: -83.3,
    altitude: 2.1336
  },
  {
    iataCode: 'GYD',
    icaoCode: 'UBBB',
    name: 'Baku',
    country: {code: 'AZ', name: 'Azerbaijan'},
    city: 'Heydar Aliyev',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Baku',
    utcOffset: 240,
    latitude: 40.4675,
    longitude: 50.046667,
    altitude: 3.048
  },
  {
    iataCode: 'GYI',
    icaoCode: 'HRYG',
    name: 'Gisenyi',
    country: {code: 'RW', name: 'Rwanda'},
    city: 'Gisenyi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kigali',
    utcOffset: 120,
    latitude: -1.677203,
    longitude: 29.258875,
    altitude: 1548.9936
  },
  {
    iataCode: 'GYM',
    icaoCode: 'MMGM',
    name: 'Guaymas',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Jose Maria Yanez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 27.968983,
    longitude: -110.925169,
    altitude: 17.9832
  },
  {
    iataCode: 'GYL',
    icaoCode: 'YARG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Argyle Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -16.6369,
    longitude: 128.451,
    altitude: 159.1056
  },
  {
    iataCode: 'GYN',
    icaoCode: 'SBGO',
    name: 'Goiania',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santa Genoveva',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -16.632033,
    longitude: -49.220686,
    altitude: 746.76
  },
  {
    iataCode: 'GYS',
    icaoCode: 'ZUGU',
    name: 'Guangyuan',
    country: {code: 'CN', name: 'China'},
    city: 'Guangyuan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 32.3911,
    longitude: 105.702,
    altitude: 0.0
  },
  {
    iataCode: 'GYY',
    icaoCode: 'KGYY',
    name: 'Gary',
    country: {code: 'US', name: 'United States'},
    city: 'Gary Chicago International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.6163,
    longitude: -87.4128,
    altitude: 180.1368
  },
  {
    iataCode: 'PKB',
    icaoCode: 'KPKB',
    name: 'Parkersburg',
    country: {code: 'US', name: 'United States'},
    city: 'Mid-ohio Valley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.345104,
    longitude: -81.439203,
    altitude: 261.5184
  },
  {
    iataCode: 'PKC',
    icaoCode: 'UHPP',
    name: 'Petropavlovsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Yelizovo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kamchatka',
    utcOffset: 720,
    latitude: 53.167889,
    longitude: 158.453669,
    altitude: 39.9288
  },
  {
    iataCode: 'PKE',
    icaoCode: 'YPKS',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Parkes Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.1314,
    longitude: 148.239,
    altitude: 325.8312
  },
  {
    iataCode: 'PKH',
    icaoCode: 'LGHL',
    name: 'Porto Heli',
    country: {code: 'GR', name: 'Greece'},
    city: 'Alexion',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.298792,
    longitude: 23.148986,
    altitude: 677.8752
  },
  {
    iataCode: 'PKG',
    icaoCode: 'WMPA',
    name: 'Pangkor Island',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Pulau Pangkor Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 4.24472,
    longitude: 100.553,
    altitude: 5.7912
  },
  {
    iataCode: 'GZA',
    icaoCode: 'LVGZ',
    name: 'Gaza',
    country: {code: 'PS', name: 'State of Palestine'},
    city: 'Yasser Arafat Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Gaza',
    utcOffset: 120,
    latitude: 31.246389,
    longitude: 34.276111,
    altitude: 97.536
  },
  {
    iataCode: 'PKK',
    icaoCode: 'VYPU',
    name: 'Pakhokku',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Pakhokku Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 21.3333,
    longitude: 95.1,
    altitude: 46.0248
  },
  {
    iataCode: 'PKN',
    icaoCode: 'WAOI',
    name: 'Pangkalan Bun',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Iskandar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: -2.705197,
    longitude: 111.673208,
    altitude: 22.86
  },
  {
    iataCode: 'PKP',
    icaoCode: 'NTGP',
    name: 'Puka Puka',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Puka Puka',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.809458,
    longitude: -138.812811,
    altitude: 1.524
  },
  {
    iataCode: 'PKR',
    icaoCode: 'VNPK',
    name: 'Pokhara',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Pokhara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.200881,
    longitude: 83.982056,
    altitude: 826.6176
  },
  {
    iataCode: 'PKV',
    icaoCode: 'ULOO',
    name: 'Pskov',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kresty',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 57.783917,
    longitude: 28.395614,
    altitude: 46.9392
  },
  {
    iataCode: 'GZM',
    icaoCode: 'LMMG',
    name: 'Gozo',
    country: {code: 'MT', name: 'Malta'},
    city: 'Xewkija Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Malta',
    utcOffset: 60,
    latitude: 36.027222,
    longitude: 14.272778,
    altitude: 91.44
  },
  {
    iataCode: 'PKU',
    icaoCode: 'WIBB',
    name: 'Pekanbaru',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sultan Syarif Kasim Ii',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 0.460786,
    longitude: 101.444539,
    altitude: 31.0896
  },
  {
    iataCode: 'GZO',
    icaoCode: 'AGGN',
    name: 'Gizo',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Nusatupe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.09778,
    longitude: 156.864,
    altitude: 3.9624
  },
  {
    iataCode: 'PKW',
    icaoCode: 'FBSP',
    name: 'Selebi-phikwe',
    country: {code: 'BW', name: 'Botswana'},
    city: 'Selebi Phikwe',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Gaborone',
    utcOffset: 120,
    latitude: -22.05835,
    longitude: 27.828767,
    altitude: 891.54
  },
  {
    iataCode: 'PKZ',
    icaoCode: 'VLPS',
    name: 'Pakse',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Pakse',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 15.132053,
    longitude: 105.781417,
    altitude: 106.9848
  },
  {
    iataCode: 'PKY',
    icaoCode: 'WAOP',
    name: 'Palangkaraya',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Tjilik Riwut',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: -2.225128,
    longitude: 113.942661,
    altitude: 24.9936
  },
  {
    iataCode: 'GZT',
    icaoCode: 'LTAJ',
    name: 'Gaziantep',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Oguzeli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 36.947183,
    longitude: 37.478683,
    altitude: 705.612
  },
  {
    iataCode: 'PLD',
    icaoCode: 'MRSR',
    name: 'Playa Samara',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Playa Samara Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 10.25,
    longitude: -85.417,
    altitude: 3.048
  },
  {
    iataCode: 'PLH',
    icaoCode: 'EGHD',
    name: 'Plymouth',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Plymouth',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.422778,
    longitude: -4.105833,
    altitude: 145.0848
  },
  {
    iataCode: 'PLM',
    icaoCode: 'WIPP',
    name: 'Palembang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Sultan Mahmud Badaruddin Ii',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -2.89825,
    longitude: 104.699903,
    altitude: 14.9352
  },
  {
    iataCode: 'PLO',
    icaoCode: 'YPLC',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Port Lincoln Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -34.6053,
    longitude: 135.88,
    altitude: 10.9728
  },
  {
    iataCode: 'PLN',
    icaoCode: 'KPLN',
    name: 'Pellston',
    country: {code: 'US', name: 'United States'},
    city: 'Pellston Regional Airport Of Emmet County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 45.5709,
    longitude: -84.7967,
    altitude: 219.456
  },
  {
    iataCode: 'PLQ',
    icaoCode: 'EYPA',
    name: 'Palanga',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Palanga Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 55.973228,
    longitude: 21.093856,
    altitude: 10.0584
  },
  {
    iataCode: 'PLP',
    icaoCode: 'MPLP',
    name: 'La Palma',
    country: {code: 'PA', name: 'Panama'},
    city: 'Captain Ramon Xatruch Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 8.40667,
    longitude: -78.1417,
    altitude: 9.144
  },
  {
    iataCode: 'PLS',
    icaoCode: 'MBPV',
    name: 'Providenciales',
    country: {code: 'TC', name: 'Turks and Caicos Islands'},
    city: 'Providenciales',
    region: '',
    terminal: '',
    timezoneId: 'America/Grand_Turk',
    utcOffset: -300,
    latitude: 21.773625,
    longitude: -72.265886,
    altitude: 4.572
  },
  {
    iataCode: 'PLU',
    icaoCode: 'SBBH',
    name: 'Belo Horizonte',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Pampulha Carlos Drummond De Andrade',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -19.851181,
    longitude: -43.950628,
    altitude: 789.1272
  },
  {
    iataCode: 'PLW',
    icaoCode: 'WAML',
    name: 'Palu',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Mutiara',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -0.918542,
    longitude: 119.909642,
    altitude: 86.5632
  },
  {
    iataCode: 'PLX',
    icaoCode: 'UASS',
    name: 'Semiplatinsk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Semipalatinsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 50.3513,
    longitude: 80.2344,
    altitude: 231.9528
  },
  {
    iataCode: 'PLZ',
    icaoCode: 'FAPE',
    name: 'Port Elizabeth',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Port Elizabeth Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -33.984919,
    longitude: 25.617275,
    altitude: 68.8848
  },
  {
    iataCode: 'PMB',
    icaoCode: 'KPMB',
    name: 'Pembina',
    country: {code: 'US', name: 'United States'},
    city: 'Pembina Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.942501,
    longitude: -97.240833,
    altitude: 242.316
  },
  {
    iataCode: 'PMA',
    icaoCode: 'HTPE',
    name: 'Pemba',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Pemba',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -5.257264,
    longitude: 39.811417,
    altitude: 24.384
  },
  {
    iataCode: 'PMD',
    icaoCode: 'KPMD',
    name: 'Palmdale',
    country: {code: 'US', name: 'United States'},
    city: 'Palmdale Rgnl Usaf Plt 42',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.629391,
    longitude: -118.08456,
    altitude: 775.1064
  },
  {
    iataCode: 'PMC',
    icaoCode: 'SCTE',
    name: 'Puerto Montt',
    country: {code: 'CL', name: 'Chile'},
    city: 'El Tepual Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -41.438886,
    longitude: -73.093953,
    altitude: 89.6112
  },
  {
    iataCode: 'PMF',
    icaoCode: 'LIMP',
    name: 'Parma',
    country: {code: 'IT', name: 'Italy'},
    city: 'Parma',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.824483,
    longitude: 10.296367,
    altitude: 49.0728
  },
  {
    iataCode: 'PMG',
    icaoCode: 'SBPP',
    name: 'Ponta Pora',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Ponta Pora',
    region: '',
    terminal: '',
    timezoneId: 'America/Campo_Grande',
    utcOffset: -240,
    latitude: -22.549639,
    longitude: -55.702614,
    altitude: 657.1488
  },
  {
    iataCode: 'PMI',
    icaoCode: 'LEPA',
    name: 'Palma De Mallorca',
    country: {code: 'ES', name: 'Spain'},
    city: 'Son Sant Joan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 39.55361,
    longitude: 2.727778,
    altitude: 7.3152
  },
  {
    iataCode: 'PML',
    icaoCode: 'PAAL',
    name: 'Cold Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Port Moller Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 56.006111,
    longitude: -160.560833,
    altitude: 6.096
  },
  {
    iataCode: 'PMK',
    icaoCode: 'YPAM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Palm Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -18.7553,
    longitude: 146.581,
    altitude: 8.5344
  },
  {
    iataCode: 'PMO',
    icaoCode: 'LICJ',
    name: 'Palermo',
    country: {code: 'IT', name: 'Italy'},
    city: 'Palermo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 38.175958,
    longitude: 13.091019,
    altitude: 19.812
  },
  {
    iataCode: 'PMR',
    icaoCode: 'NZPM',
    name: 'Palmerston North',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Palmerston North',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -40.320556,
    longitude: 175.616944,
    altitude: 46.0248
  },
  {
    iataCode: 'PMQ',
    icaoCode: 'SAWP',
    name: 'Perito Moreno',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Perito Moreno',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -46.537911,
    longitude: -70.978689,
    altitude: 429.768
  },
  {
    iataCode: 'PMS',
    icaoCode: 'OSPR',
    name: 'Palmyra',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Palmyra',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 34.557361,
    longitude: 38.316889,
    altitude: 402.9456
  },
  {
    iataCode: 'PMV',
    icaoCode: 'SVMG',
    name: 'Porlamar',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Del Caribe Intl Gen Santiago Marino',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 10.912926,
    longitude: -63.967581,
    altitude: 22.5552
  },
  {
    iataCode: 'PMW',
    icaoCode: 'SBPJ',
    name: 'Palmas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Palmas',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -10.241667,
    longitude: -48.35278,
    altitude: 235.9152
  },
  {
    iataCode: 'PMZ',
    icaoCode: 'MRPM',
    name: 'Palmar Sur',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Palmar Sur',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 8.951025,
    longitude: -83.468583,
    altitude: 14.9352
  },
  {
    iataCode: 'PMY',
    icaoCode: 'SAVY',
    name: 'Puerto Madryn',
    country: {code: 'AR', name: 'Argentina'},
    city: 'El Tehuelche',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -42.759161,
    longitude: -65.102725,
    altitude: 129.8448
  },
  {
    iataCode: 'PNA',
    icaoCode: 'LEPP',
    name: 'Pamplona',
    country: {code: 'ES', name: 'Spain'},
    city: 'Pamplona',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.770039,
    longitude: -1.646331,
    altitude: 458.4192
  },
  {
    iataCode: 'PNC',
    icaoCode: 'KPNC',
    name: 'Ponca City',
    country: {code: 'US', name: 'United States'},
    city: 'Ponca City Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.731958,
    longitude: -97.099781,
    altitude: 306.9336
  },
  {
    iataCode: 'PNB',
    icaoCode: 'SBPN',
    name: 'Porto Nacional',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Porto Nacional',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -10.719417,
    longitude: -48.399736,
    altitude: 265.176
  },
  {
    iataCode: 'PNE',
    icaoCode: 'KPNE',
    name: 'Philadelphia',
    country: {code: 'US', name: 'United States'},
    city: 'Northeast Philadelphia',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.081944,
    longitude: -75.010586,
    altitude: 36.8808
  },
  {
    iataCode: 'PNI',
    icaoCode: 'PTPN',
    name: 'Pohnpei',
    country: {code: 'FM', name: 'Federated States of Micronesia'},
    city: 'Pohnpei Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Pohnpei',
    utcOffset: 660,
    latitude: 6.9851,
    longitude: 158.208989,
    altitude: 3.048
  },
  {
    iataCode: 'PNH',
    icaoCode: 'VDPP',
    name: 'Phnom-penh',
    country: {code: 'KH', name: 'Cambodia'},
    city: 'Phnom Penh Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Phnom_Penh',
    utcOffset: 420,
    latitude: 11.546556,
    longitude: 104.844139,
    altitude: 12.192
  },
  {
    iataCode: 'PNK',
    icaoCode: 'WIOO',
    name: 'Pontianak',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Supadio',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: -0.150711,
    longitude: 109.403892,
    altitude: 3.048
  },
  {
    iataCode: 'PNM',
    icaoCode: 'KPNM',
    name: 'Princeton',
    country: {code: 'US', name: 'United States'},
    city: 'Princeton Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.559868,
    longitude: -93.608217,
    altitude: 298.3992
  },
  {
    iataCode: 'PNL',
    icaoCode: 'LICG',
    name: 'Pantelleria',
    country: {code: 'IT', name: 'Italy'},
    city: 'Pantelleria',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 36.816519,
    longitude: 11.968864,
    altitude: 193.548
  },
  {
    iataCode: 'PNQ',
    icaoCode: 'VAPO',
    name: 'Pune',
    country: {code: 'IN', name: 'India'},
    city: 'Pune',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 18.582111,
    longitude: 73.919697,
    altitude: 591.9216
  },
  {
    iataCode: 'PNP',
    icaoCode: 'AYGR',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Girua Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -8.80454,
    longitude: 148.309,
    altitude: 94.7928
  },
  {
    iataCode: 'PNS',
    icaoCode: 'KPNS',
    name: 'Pensacola',
    country: {code: 'US', name: 'United States'},
    city: 'Pensacola Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.473425,
    longitude: -87.186611,
    altitude: 36.8808
  },
  {
    iataCode: 'PNR',
    icaoCode: 'FCPP',
    name: 'Pointe-noire',
    country: {code: 'CG', name: 'Congo'},
    city: 'Pointe Noire',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Brazzaville',
    utcOffset: 60,
    latitude: -4.816028,
    longitude: 11.886597,
    altitude: 16.764
  },
  {
    iataCode: 'PNT',
    icaoCode: 'SCNT',
    name: 'Puerto Natales',
    country: {code: 'CL', name: 'Chile'},
    city: 'Puerto Natales',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -180,
    latitude: -51.733333,
    longitude: -72.516667,
    altitude: 2.7432
  },
  {
    iataCode: 'PNV',
    icaoCode: 'EYPP',
    name: 'Panevezys',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Pajuostis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 55.729444,
    longitude: 24.460833,
    altitude: 60.0456
  },
  {
    iataCode: 'PNZ',
    icaoCode: 'SBPL',
    name: 'Petrolina',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Senador Nilo Coelho',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -9.362411,
    longitude: -40.569097,
    altitude: 384.9624
  },
  {
    iataCode: 'POB',
    icaoCode: 'KPOB',
    name: 'Fayetteville',
    country: {code: 'US', name: 'United States'},
    city: 'Pope Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.170883,
    longitude: -79.014472,
    altitude: 66.1416
  },
  {
    iataCode: 'POA',
    icaoCode: 'SBPA',
    name: 'Porto Alegre',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Salgado Filho',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -29.994428,
    longitude: -51.171428,
    altitude: 3.3528
  },
  {
    iataCode: 'POE',
    icaoCode: 'KPOE',
    name: 'Fort Polk',
    country: {code: 'US', name: 'United States'},
    city: 'Polk Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.044833,
    longitude: -93.191667,
    altitude: 100.584
  },
  {
    iataCode: 'POG',
    icaoCode: 'FOOG',
    name: 'Port Gentil',
    country: {code: 'GA', name: 'Gabon'},
    city: 'Port Gentil',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Libreville',
    utcOffset: 60,
    latitude: -0.711739,
    longitude: 8.754383,
    altitude: 3.9624
  },
  {
    iataCode: 'POI',
    icaoCode: 'SLPO',
    name: 'Potosi',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Capitan Nicolas Rojas',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -19.543069,
    longitude: -65.723706,
    altitude: 3935.8824
  },
  {
    iataCode: 'POL',
    icaoCode: 'FQPB',
    name: 'Pemba',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Pemba',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -12.986753,
    longitude: 40.522492,
    altitude: 100.8888
  },
  {
    iataCode: 'POM',
    icaoCode: 'AYPY',
    name: 'Port Moresby',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Port Moresby Jacksons Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -9.443383,
    longitude: 147.22005,
    altitude: 44.5008
  },
  {
    iataCode: 'POP',
    icaoCode: 'MDPP',
    name: 'Puerto Plata',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Gregorio Luperon Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 19.7579,
    longitude: -70.570033,
    altitude: 4.572
  },
  {
    iataCode: 'POO',
    icaoCode: 'SBPC',
    name: 'Pocos De Caldas',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Pocos De Caldas',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.843014,
    longitude: -46.567917,
    altitude: 1260.348
  },
  {
    iataCode: 'POR',
    icaoCode: 'EFPO',
    name: 'Pori',
    country: {code: 'FI', name: 'Finland'},
    city: 'Pori',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 61.461686,
    longitude: 21.799983,
    altitude: 13.4112
  },
  {
    iataCode: 'POT',
    icaoCode: 'MKKJ',
    name: 'Port Antonio',
    country: {code: 'JM', name: 'Jamaica'},
    city: 'Ken Jones',
    region: '',
    terminal: '',
    timezoneId: 'America/Jamaica',
    utcOffset: -300,
    latitude: 18.198806,
    longitude: -76.534528,
    altitude: 6.096
  },
  {
    iataCode: 'POS',
    icaoCode: 'TTPP',
    name: 'Port-of-spain',
    country: {code: 'TT', name: 'Trinidad and Tobago'},
    city: 'Piarco',
    region: '',
    terminal: '',
    timezoneId: 'America/Port_of_Spain',
    utcOffset: -240,
    latitude: 10.595369,
    longitude: -61.337242,
    altitude: 17.6784
  },
  {
    iataCode: 'POX',
    icaoCode: 'LFPT',
    name: 'Pontoise',
    country: {code: 'FR', name: 'France'},
    city: 'Cormeilles En Vexin',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.096647,
    longitude: 2.040833,
    altitude: 99.06
  },
  {
    iataCode: 'YAA',
    icaoCode: 'CAJ4',
    name: 'Anahim Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Anahim Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 52.4525,
    longitude: -125.303,
    altitude: 1107.948
  },
  {
    iataCode: 'POW',
    icaoCode: 'LJPZ',
    name: 'Portoroz',
    country: {code: 'SI', name: 'Slovenia'},
    city: 'Portoroz',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Ljubljana',
    utcOffset: 60,
    latitude: 45.473353,
    longitude: 13.614978,
    altitude: 2.1336
  },
  {
    iataCode: 'POZ',
    icaoCode: 'EPPO',
    name: 'Poznan',
    country: {code: 'PL', name: 'Poland'},
    city: 'Lawica',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 52.421031,
    longitude: 16.826325,
    altitude: 93.8784
  },
  {
    iataCode: 'YAC',
    icaoCode: 'CYAC',
    name: 'Cat Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cat Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 51.7272,
    longitude: -91.8244,
    altitude: 409.6512
  },
  {
    iataCode: 'YAG',
    icaoCode: 'CYAG',
    name: 'Fort Frances',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Frances Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 48.6542,
    longitude: -93.4397,
    altitude: 342.9
  },
  {
    iataCode: 'YAK',
    icaoCode: 'PAYA',
    name: 'Yakutat',
    country: {code: 'US', name: 'United States'},
    city: 'Yakutat',
    region: '',
    terminal: '',
    timezoneId: 'America/Yakutat',
    utcOffset: -540,
    latitude: 59.3012,
    longitude: -139.3937,
    altitude: 10.0584
  },
  {
    iataCode: 'PPB',
    icaoCode: 'SBDN',
    name: 'President Prudente',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Presidente Prudente',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.175056,
    longitude: -51.424639,
    altitude: 450.1896
  },
  {
    iataCode: 'PPE',
    icaoCode: 'MMPE',
    name: 'Punta Penasco',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Puerto Penasco',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 31.351878,
    longitude: -113.525728,
    altitude: 9.144
  },
  {
    iataCode: 'YAM',
    icaoCode: 'CYAM',
    name: 'Sault Sainte Marie',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sault Ste Marie',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 46.485001,
    longitude: -84.509445,
    altitude: 192.024
  },
  {
    iataCode: 'PPG',
    icaoCode: 'NSTU',
    name: 'Pago Pago',
    country: {code: 'AS', name: 'American Samoa'},
    city: 'Pago Pago Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Pago_Pago',
    utcOffset: -660,
    latitude: -14.331,
    longitude: -170.7105,
    altitude: 9.7536
  },
  {
    iataCode: 'YAO',
    icaoCode: 'FKKY',
    name: 'Yaounde',
    country: {code: 'CM', name: 'Cameroon'},
    city: 'Yaounde Ville',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Douala',
    utcOffset: 60,
    latitude: 3.836039,
    longitude: 11.523461,
    altitude: 751.0272
  },
  {
    iataCode: 'YAP',
    icaoCode: 'PTYA',
    name: 'Yap',
    country: {code: 'FM', name: 'Federated States of Micronesia'},
    city: 'Yap Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Chuuk',
    utcOffset: 600,
    latitude: 9.498911,
    longitude: 138.082497,
    altitude: 27.7368
  },
  {
    iataCode: 'PPK',
    icaoCode: 'UACP',
    name: 'Petropavlosk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Petropavlosk South Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 54.7747,
    longitude: 69.1839,
    altitude: 138.0744
  },
  {
    iataCode: 'PPL',
    icaoCode: 'VNPL',
    name: 'Phaplu',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Phaplu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.517,
    longitude: 86.6,
    altitude: 2413.4064
  },
  {
    iataCode: 'YAT',
    icaoCode: 'CYAT',
    name: 'Attawapiskat',
    country: {code: 'CA', name: 'Canada'},
    city: 'Attawapiskat Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.9275,
    longitude: -82.4319,
    altitude: 9.4488
  },
  {
    iataCode: 'YAW',
    icaoCode: 'CYAW',
    name: 'Halifax',
    country: {code: 'CA', name: 'Canada'},
    city: 'Shearwater',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 44.639721,
    longitude: -63.499444,
    altitude: 50.9016
  },
  {
    iataCode: 'PPN',
    icaoCode: 'SKPP',
    name: 'Popayan',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Guillermo Leon Valencia',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 2.4544,
    longitude: -76.609319,
    altitude: 1733.3976
  },
  {
    iataCode: 'YAV',
    icaoCode: 'CYAV',
    name: 'Winnipeg',
    country: {code: 'CA', name: 'Canada'},
    city: 'Winnipeg St Andrews',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 50.056389,
    longitude: -97.0325,
    altitude: 231.648
  },
  {
    iataCode: 'PPQ',
    icaoCode: 'NZPP',
    name: 'Paraparaumu',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Paraparaumu',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -40.904722,
    longitude: 174.989167,
    altitude: 6.7056
  },
  {
    iataCode: 'YAY',
    icaoCode: 'CYAY',
    name: 'St. Anthony',
    country: {code: 'CA', name: 'Canada'},
    city: 'St Anthony',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 51.391944,
    longitude: -56.083056,
    altitude: 32.9184
  },
  {
    iataCode: 'PPP',
    icaoCode: 'YBPN',
    name: 'Prosserpine',
    country: {code: 'AU', name: 'Australia'},
    city: 'Proserpine Whitsunday Coast',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.495,
    longitude: 148.552222,
    altitude: 24.9936
  },
  {
    iataCode: 'YAX',
    icaoCode: 'CKB6',
    name: 'Angling Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wapekeka Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -300,
    latitude: 53.8492,
    longitude: -89.5794,
    altitude: 217.0176
  },
  {
    iataCode: 'PPS',
    icaoCode: 'RPVP',
    name: 'Puerto Princesa',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Puerto Princesa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 9.742119,
    longitude: 118.758731,
    altitude: 21.6408
  },
  {
    iataCode: 'YAZ',
    icaoCode: 'CYAZ',
    name: 'Tofino',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tofino',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.082222,
    longitude: -125.7725,
    altitude: 24.384
  },
  {
    iataCode: 'PPT',
    icaoCode: 'NTAA',
    name: 'Papeete',
    country: {code: 'PF', name: 'French Polynesia'},
    city: "Faa\\\\'a International",
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -17.556667,
    longitude: -149.611389,
    altitude: 2.1336
  },
  {
    iataCode: 'PPW',
    icaoCode: 'EGEP',
    name: 'Papa Westray',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Papa Westray Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 59.3517,
    longitude: -2.90028,
    altitude: 27.7368
  },
  {
    iataCode: 'YBB',
    icaoCode: 'CYBB',
    name: 'Pelly Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kugaaruk',
    region: '',
    terminal: '',
    timezoneId: 'America/Cambridge_Bay',
    utcOffset: -360,
    latitude: 68.534444,
    longitude: -89.808056,
    altitude: 17.0688
  },
  {
    iataCode: 'YBC',
    icaoCode: 'CYBC',
    name: 'Baie Comeau',
    country: {code: 'CA', name: 'Canada'},
    city: 'Baie Comeau',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 49.1325,
    longitude: -68.204444,
    altitude: 21.6408
  },
  {
    iataCode: 'YBE',
    icaoCode: 'CYBE',
    name: 'Uranium City',
    country: {code: 'CA', name: 'Canada'},
    city: 'Uranium City Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 59.5614,
    longitude: -108.481,
    altitude: 318.2112
  },
  {
    iataCode: 'YBG',
    icaoCode: 'CYBG',
    name: 'Bagotville',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bagotville',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.330555,
    longitude: -70.996391,
    altitude: 159.1056
  },
  {
    iataCode: 'YBL',
    icaoCode: 'CYBL',
    name: 'Campbell River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Campbell River',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.950832,
    longitude: -125.270833,
    altitude: 105.4608
  },
  {
    iataCode: 'YBK',
    icaoCode: 'CYBK',
    name: 'Baker Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Baker Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 64.298889,
    longitude: -96.077778,
    altitude: 17.9832
  },
  {
    iataCode: 'YBP',
    icaoCode: 'ZUYB',
    name: 'Yibin',
    country: {code: 'CN', name: 'China'},
    city: 'Yibin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 28.800278,
    longitude: 104.544444,
    altitude: 304.8
  },
  {
    iataCode: 'YBR',
    icaoCode: 'CYBR',
    name: 'Brandon',
    country: {code: 'CA', name: 'Canada'},
    city: 'Brandon Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 49.91,
    longitude: -99.951944,
    altitude: 409.3464
  },
  {
    iataCode: 'HAA',
    icaoCode: 'ENHK',
    name: 'Hasvik',
    country: {code: 'NO', name: 'Norway'},
    city: 'Hasvik',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.486675,
    longitude: 22.139744,
    altitude: 6.4008
  },
  {
    iataCode: 'PQI',
    icaoCode: 'KPQI',
    name: 'Presque Isle',
    country: {code: 'US', name: 'United States'},
    city: 'Northern Maine Rgnl At Presque Isle',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 46.688958,
    longitude: -68.044797,
    altitude: 162.7632
  },
  {
    iataCode: 'HAD',
    icaoCode: 'ESMT',
    name: 'Halmstad',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Halmstad',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 56.691128,
    longitude: 12.820211,
    altitude: 30.7848
  },
  {
    iataCode: 'YBT',
    icaoCode: 'CYBT',
    name: 'Brochet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Brochet Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 57.889444,
    longitude: -101.679167,
    altitude: 344.7288
  },
  {
    iataCode: 'HAC',
    icaoCode: 'RJTH',
    name: 'Hachijojima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Hachijojima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.115,
    longitude: 139.785833,
    altitude: 92.3544
  },
  {
    iataCode: 'YBV',
    icaoCode: 'CYBV',
    name: 'Berens River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Berens River',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.358889,
    longitude: -97.018333,
    altitude: 221.8944
  },
  {
    iataCode: 'HAH',
    icaoCode: 'FMCH',
    name: 'Moroni',
    country: {code: 'KM', name: 'Comoros'},
    city: 'Prince Said Ibrahim',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Comoro',
    utcOffset: 180,
    latitude: -11.533661,
    longitude: 43.27185,
    altitude: 28.3464
  },
  {
    iataCode: 'YBX',
    icaoCode: 'CYBX',
    name: 'Lourdes-de-blanc-sablon',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lourdes De Blanc Sablon Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Blanc-Sablon',
    utcOffset: -240,
    latitude: 51.4436,
    longitude: -57.1853,
    altitude: 36.8808
  },
  {
    iataCode: 'HAJ',
    icaoCode: 'EDDV',
    name: 'Hannover',
    country: {code: 'DE', name: 'Germany'},
    city: 'Hannover',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.461056,
    longitude: 9.685078,
    altitude: 55.7784
  },
  {
    iataCode: 'PQQ',
    icaoCode: 'YPMQ',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Port Macquarie Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -31.4358,
    longitude: 152.863,
    altitude: 3.6576
  },
  {
    iataCode: 'YBY',
    icaoCode: 'CYBF',
    name: 'Bonnyville',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bonnyville Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 54.304722,
    longitude: -110.741111,
    altitude: 560.5272
  },
  {
    iataCode: 'HAK',
    icaoCode: 'ZJHK',
    name: 'Haikou',
    country: {code: 'CN', name: 'China'},
    city: 'Meilan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 19.934856,
    longitude: 110.458961,
    altitude: 22.86
  },
  {
    iataCode: 'HAN',
    icaoCode: 'VVNB',
    name: 'Hanoi',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Noibai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 21.221192,
    longitude: 105.807178,
    altitude: 11.8872
  },
  {
    iataCode: 'HAM',
    icaoCode: 'EDDH',
    name: 'Hamburg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Hamburg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.630389,
    longitude: 9.988228,
    altitude: 16.1544
  },
  {
    iataCode: 'HAQ',
    icaoCode: 'VRMH',
    name: 'Haa Dhaalu Atoll',
    country: {code: 'MV', name: 'Maldives'},
    city: 'Hanimaadhoo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Maldives',
    utcOffset: 300,
    latitude: 6.74423,
    longitude: 73.1705,
    altitude: 1.2192
  },
  {
    iataCode: 'YCB',
    icaoCode: 'CYCB',
    name: 'Cambridge Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cambridge Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Cambridge_Bay',
    utcOffset: -420,
    latitude: 69.108055,
    longitude: -105.138333,
    altitude: 27.432
  },
  {
    iataCode: 'HAS',
    icaoCode: 'OEHL',
    name: 'Hail',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Hail',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 27.437917,
    longitude: 41.686292,
    altitude: 1015.2888
  },
  {
    iataCode: 'YCD',
    icaoCode: 'CYCD',
    name: 'Nanaimo',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nanaimo',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.052333,
    longitude: -123.870167,
    altitude: 28.3464
  },
  {
    iataCode: 'HAV',
    icaoCode: 'MUHA',
    name: 'Havana',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Jose Marti Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.989153,
    longitude: -82.409086,
    altitude: 64.008
  },
  {
    iataCode: 'YCG',
    icaoCode: 'CYCG',
    name: 'Castlegar',
    country: {code: 'CA', name: 'Canada'},
    city: 'Castlegar',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.296389,
    longitude: -117.6325,
    altitude: 494.9952
  },
  {
    iataCode: 'HAU',
    icaoCode: 'ENHD',
    name: 'Haugesund',
    country: {code: 'NO', name: 'Norway'},
    city: 'Karmoy',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.345267,
    longitude: 5.208364,
    altitude: 26.2128
  },
  {
    iataCode: 'PRA',
    icaoCode: 'SAAP',
    name: 'Parana',
    country: {code: 'AR', name: 'Argentina'},
    city: 'General Urquiza',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -31.794778,
    longitude: -60.480361,
    altitude: 74.0664
  },
  {
    iataCode: 'YCH',
    icaoCode: 'CYCH',
    name: 'Chatham',
    country: {code: 'CA', name: 'Canada'},
    city: 'Miramichi',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 47.007778,
    longitude: -65.449167,
    altitude: 32.9184
  },
  {
    iataCode: 'PRC',
    icaoCode: 'KPRC',
    name: 'Prescott',
    country: {code: 'US', name: 'United States'},
    city: 'Ernest A Love Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 34.654472,
    longitude: -112.419583,
    altitude: 1537.716
  },
  {
    iataCode: 'YCK',
    icaoCode: 'CEB3',
    name: 'Colville Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Colville Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 67.0333,
    longitude: -126.083,
    altitude: 259.08
  },
  {
    iataCode: 'YCL',
    icaoCode: 'CYCL',
    name: 'Charlo',
    country: {code: 'CA', name: 'Canada'},
    city: 'Charlo',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 47.990833,
    longitude: -66.330278,
    altitude: 40.2336
  },
  {
    iataCode: 'PRG',
    icaoCode: 'LKPR',
    name: 'Prague',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Ruzyne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 50.100833,
    longitude: 14.26,
    altitude: 380.0856
  },
  {
    iataCode: 'YCO',
    icaoCode: 'CYCO',
    name: 'Coppermine',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kugluktuk',
    region: '',
    terminal: '',
    timezoneId: 'America/Cambridge_Bay',
    utcOffset: -420,
    latitude: 67.816667,
    longitude: -115.143889,
    altitude: 22.5552
  },
  {
    iataCode: 'HBA',
    icaoCode: 'YMHB',
    name: 'Hobart',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hobart',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Hobart',
    utcOffset: 600,
    latitude: -42.836111,
    longitude: 147.510278,
    altitude: 3.9624
  },
  {
    iataCode: 'PRI',
    icaoCode: 'FSPP',
    name: 'Praslin',
    country: {code: 'SC', name: 'Seychelles'},
    city: 'Praslin',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mahe',
    utcOffset: 240,
    latitude: -4.319292,
    longitude: 55.691417,
    altitude: 3.048
  },
  {
    iataCode: 'PRH',
    icaoCode: 'VTCP',
    name: 'Phrae',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Phrae',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 18.132169,
    longitude: 100.164664,
    altitude: 163.9824
  },
  {
    iataCode: 'YCS',
    icaoCode: 'CYCS',
    name: 'Chesterfield Inlet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chesterfield Inlet Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 63.3469,
    longitude: -90.7311,
    altitude: 9.7536
  },
  {
    iataCode: 'YCR',
    icaoCode: 'CYCR',
    name: 'Cross Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cross Lake - Charlie Sinclair Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 54.610833,
    longitude: -97.760278,
    altitude: 215.4936
  },
  {
    iataCode: 'HBE',
    icaoCode: 'HEBA',
    name: 'Alexandria',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Borg El Arab Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 30.917669,
    longitude: 29.696408,
    altitude: 53.9496
  },
  {
    iataCode: 'YCU',
    icaoCode: 'ZBYC',
    name: 'Yuncheng',
    country: {code: 'CN', name: 'China'},
    city: 'Zhangxiao',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 35.018,
    longitude: 110.993,
    altitude: 0.0
  },
  {
    iataCode: 'YCT',
    icaoCode: 'CYCT',
    name: 'Coronation',
    country: {code: 'CA', name: 'Canada'},
    city: 'Coronation',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 52.075001,
    longitude: -111.445278,
    altitude: 790.956
  },
  {
    iataCode: 'YCW',
    icaoCode: 'CYCW',
    name: 'Chilliwack',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chilliwack',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.152779,
    longitude: -121.93889,
    altitude: 9.7536
  },
  {
    iataCode: 'PRN',
    icaoCode: 'LYPR',
    name: 'Pristina',
    country: {code: 'RS', name: 'Serbia'},
    city: 'Pristina',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Belgrade',
    utcOffset: 60,
    latitude: 42.572778,
    longitude: 21.035833,
    altitude: 545.2872
  },
  {
    iataCode: 'YCY',
    icaoCode: 'CYCY',
    name: 'Clyde River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Clyde River',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 70.486111,
    longitude: -68.516667,
    altitude: 26.5176
  },
  {
    iataCode: 'PRV',
    icaoCode: 'LKPO',
    name: 'Prerov',
    country: {code: 'CZ', name: 'Czech Republic'},
    city: 'Prerov',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Prague',
    utcOffset: 60,
    latitude: 49.425833,
    longitude: 17.404722,
    altitude: 206.0448
  },
  {
    iataCode: 'PRY',
    icaoCode: 'FAWB',
    name: 'Pretoria',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Wonderboom',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.653858,
    longitude: 28.224231,
    altitude: 1248.156
  },
  {
    iataCode: 'YDB',
    icaoCode: 'CYDB',
    name: 'Burwash',
    country: {code: 'CA', name: 'Canada'},
    city: 'Burwash',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 61.371111,
    longitude: -139.040556,
    altitude: 806.8056
  },
  {
    iataCode: 'YDA',
    icaoCode: 'CYDA',
    name: 'Dawson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Dawson City',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 64.043056,
    longitude: -139.127778,
    altitude: 370.332
  },
  {
    iataCode: 'HBR',
    icaoCode: 'KHBR',
    name: 'Hobart',
    country: {code: 'US', name: 'United States'},
    city: 'Hobart Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.991308,
    longitude: -99.051353,
    altitude: 476.7072
  },
  {
    iataCode: 'YDC',
    icaoCode: 'CYDC',
    name: 'Princeton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Princeton',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.468056,
    longitude: -120.511389,
    altitude: 700.4304
  },
  {
    iataCode: 'YDF',
    icaoCode: 'CYDF',
    name: 'Deer Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Deer Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 49.210833,
    longitude: -57.391388,
    altitude: 21.9456
  },
  {
    iataCode: 'HBT',
    icaoCode: 'OEKK',
    name: 'King Khalid Mil.city',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Khaled Military City',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 27.900917,
    longitude: 45.528194,
    altitude: 412.0896
  },
  {
    iataCode: 'HBX',
    icaoCode: 'VAHB',
    name: '',
    country: {code: 'IN', name: 'India'},
    city: 'Hubli Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 15.3617,
    longitude: 75.0849,
    altitude: 661.7208
  },
  {
    iataCode: 'PSA',
    icaoCode: 'LIRP',
    name: 'Pisa',
    country: {code: 'IT', name: 'Italy'},
    city: 'Pisa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 43.683917,
    longitude: 10.39275,
    altitude: 1.8288
  },
  {
    iataCode: 'PSD',
    icaoCode: 'HEPS',
    name: 'Port Said',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Port Said',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 31.279444,
    longitude: 32.24,
    altitude: 2.4384
  },
  {
    iataCode: 'YDL',
    icaoCode: 'CYDL',
    name: 'Dease Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Dease Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 58.422222,
    longitude: -130.032222,
    altitude: 792.48
  },
  {
    iataCode: '7AK',
    icaoCode: 'PABM',
    name: 'Big Mountain',
    country: {code: 'US', name: 'United States'},
    city: 'Big Mountain Afs',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.361247,
    longitude: -155.258822,
    altitude: 202.0824
  },
  {
    iataCode: 'PSC',
    icaoCode: 'PSC',
    name: 'Pasco',
    country: {code: 'US', name: 'United States'},
    city: 'Tri Cities Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.2647,
    longitude: -119.119,
    altitude: 124.968
  },
  {
    iataCode: 'YDN',
    icaoCode: 'CYDN',
    name: 'Dauphin',
    country: {code: 'CA', name: 'Canada'},
    city: 'Dauphin Barker',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 51.100834,
    longitude: -100.0525,
    altitude: 304.4952
  },
  {
    iataCode: 'PSE',
    icaoCode: 'TJPS',
    name: 'Ponce',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Mercedita',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.008306,
    longitude: -66.563028,
    altitude: 8.8392
  },
  {
    iataCode: 'YDP',
    icaoCode: 'CYDP',
    name: 'Nain',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nain Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 56.5492,
    longitude: -61.6803,
    altitude: 6.7056
  },
  {
    iataCode: 'PSG',
    icaoCode: 'PAPG',
    name: 'Petersburg',
    country: {code: 'US', name: 'United States'},
    city: 'Petersburg James A. Johnson',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 56.801667,
    longitude: -132.945278,
    altitude: 32.004
  },
  {
    iataCode: 'PSJ',
    icaoCode: 'WAMP',
    name: 'Poso',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Kasiguncu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -1.416753,
    longitude: 120.657669,
    altitude: 53.0352
  },
  {
    iataCode: 'PSI',
    icaoCode: 'OPPI',
    name: 'Pasni',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Pasni',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.290487,
    longitude: 63.345083,
    altitude: 10.0584
  },
  {
    iataCode: 'YDQ',
    icaoCode: 'CYDQ',
    name: 'Dawson Creek',
    country: {code: 'CA', name: 'Canada'},
    city: 'Dawson Creek',
    region: '',
    terminal: '',
    timezoneId: 'America/Dawson_Creek',
    utcOffset: -420,
    latitude: 55.742333,
    longitude: -120.183,
    altitude: 654.7104
  },
  {
    iataCode: 'YDT',
    icaoCode: 'CZBB',
    name: 'Boundary Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Boundary Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.073889,
    longitude: -123.0075,
    altitude: 1.8288
  },
  {
    iataCode: 'PSM',
    icaoCode: 'KPSM',
    name: 'Portsmouth',
    country: {code: 'US', name: 'United States'},
    city: 'Pease International Tradeport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.0779,
    longitude: -70.8233,
    altitude: 30.48
  },
  {
    iataCode: 'PSP',
    icaoCode: 'KPSP',
    name: 'Palm Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Palm Springs Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.829667,
    longitude: -116.506694,
    altitude: 145.3896
  },
  {
    iataCode: 'PSO',
    icaoCode: 'SKPS',
    name: 'Pasto',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Antonio Narino',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 1.396247,
    longitude: -77.291478,
    altitude: 1813.8648
  },
  {
    iataCode: 'PSR',
    icaoCode: 'LIBP',
    name: 'Pescara',
    country: {code: 'IT', name: 'Italy'},
    city: 'Pescara',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 42.431656,
    longitude: 14.181067,
    altitude: 14.6304
  },
  {
    iataCode: 'PSS',
    icaoCode: 'SARP',
    name: 'Posadas',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Posadas',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -27.385839,
    longitude: -55.970728,
    altitude: 131.064
  },
  {
    iataCode: 'HCN',
    icaoCode: 'RCKW',
    name: 'Hengchun',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Hengchun Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 22.041075,
    longitude: 120.730208,
    altitude: 14.0208
  },
  {
    iataCode: 'PSU',
    icaoCode: 'WIOP',
    name: 'Putussibau-borneo Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pangsuma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Pontianak',
    utcOffset: 420,
    latitude: 0.835578,
    longitude: 112.937144,
    altitude: 90.5256
  },
  {
    iataCode: 'PSX',
    icaoCode: 'KPSX',
    name: 'Palacios',
    country: {code: 'US', name: 'United States'},
    city: 'Palacios Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 28.727508,
    longitude: -96.250958,
    altitude: 4.2672
  },
  {
    iataCode: 'HCR',
    icaoCode: 'PAHC',
    name: 'Holy Cross',
    country: {code: 'US', name: 'United States'},
    city: 'Holy Cross Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.1883,
    longitude: -159.775,
    altitude: 21.336
  },
  {
    iataCode: 'PSZ',
    icaoCode: 'SLPS',
    name: 'Puerto Suarez',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Tte De Av Salvador Ogaya G',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -18.975281,
    longitude: -57.820586,
    altitude: 133.8072
  },
  {
    iataCode: 'YEC',
    icaoCode: 'RKTY',
    name: 'Yechon',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Yecheon',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 36.631933,
    longitude: 128.35485,
    altitude: 107.8992
  },
  {
    iataCode: 'HCQ',
    icaoCode: 'YHLC',
    name: 'Halls Creek',
    country: {code: 'AU', name: 'Australia'},
    city: 'Halls Creek Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -18.233889,
    longitude: 127.669722,
    altitude: 410.2608
  },
  {
    iataCode: 'PSY',
    icaoCode: 'SFAL',
    name: 'Stanley',
    country: {code: 'FK', name: 'Falkland Islands (Malvinas)'},
    city: 'Stanley Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Stanley',
    utcOffset: -240,
    latitude: -51.685672,
    longitude: -57.777644,
    altitude: 22.86
  },
  {
    iataCode: 'YEG',
    icaoCode: 'CYEG',
    name: 'Edmonton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Edmonton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 53.309723,
    longitude: -113.579722,
    altitude: 723.2904
  },
  {
    iataCode: 'YEI',
    icaoCode: 'LTBR',
    name: 'Yenisehir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Yenisehir Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.255208,
    longitude: 29.562569,
    altitude: 232.8672
  },
  {
    iataCode: 'YEK',
    icaoCode: 'CYEK',
    name: 'Eskimo Point',
    country: {code: 'CA', name: 'Canada'},
    city: 'Arviat',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 61.094166,
    longitude: -94.070833,
    altitude: 9.7536
  },
  {
    iataCode: 'PTG',
    icaoCode: 'FAPI',
    name: 'Pietersburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Pietersburg',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -23.926089,
    longitude: 29.484422,
    altitude: 1327.0992
  },
  {
    iataCode: 'YEO',
    icaoCode: 'EGDY',
    name: 'Yeovilton',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Yeovilton',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.009358,
    longitude: -2.638819,
    altitude: 22.86
  },
  {
    iataCode: 'PTF',
    icaoCode: 'NFFO',
    name: 'Malolo Lailai Island',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Malolo Lailai Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.7779,
    longitude: 177.197,
    altitude: 3.048
  },
  {
    iataCode: 'YEN',
    icaoCode: 'CYEN',
    name: 'Estevan',
    country: {code: 'CA', name: 'Canada'},
    city: 'Estevan',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 49.210278,
    longitude: -102.965833,
    altitude: 580.644
  },
  {
    iataCode: 'PTH',
    icaoCode: 'PAPH',
    name: 'Port Heiden',
    country: {code: 'US', name: 'United States'},
    city: 'Port Heiden Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 56.9591,
    longitude: -158.633,
    altitude: 28.956
  },
  {
    iataCode: 'YES',
    icaoCode: 'OISY',
    name: 'Yasuj',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Yasuj Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 30.700556,
    longitude: 51.545,
    altitude: 1810.2072
  },
  {
    iataCode: 'PTJ',
    icaoCode: 'YPOD',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Portland Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 540,
    latitude: -38.3181,
    longitude: 141.471,
    altitude: 80.772
  },
  {
    iataCode: 'YER',
    icaoCode: 'CYER',
    name: 'Fort Severn',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Severn Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 56.0189,
    longitude: -87.6761,
    altitude: 14.6304
  },
  {
    iataCode: 'YEU',
    icaoCode: 'CYEU',
    name: 'Eureka',
    country: {code: 'CA', name: 'Canada'},
    city: 'Eureka',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 79.994722,
    longitude: -85.814167,
    altitude: 78.0288
  },
  {
    iataCode: 'HDD',
    icaoCode: 'OPKD',
    name: 'Hyderabad',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Hyderabad Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.3181,
    longitude: 68.3661,
    altitude: 39.624
  },
  {
    iataCode: 'YET',
    icaoCode: 'CYET',
    name: 'Edson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Edson',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 53.578888,
    longitude: -116.465,
    altitude: 926.8968
  },
  {
    iataCode: 'HDF',
    icaoCode: 'EDAH',
    name: 'Heringsdorf',
    country: {code: 'DE', name: 'Germany'},
    city: 'Heringsdorf Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.878706,
    longitude: 14.152347,
    altitude: 28.3464
  },
  {
    iataCode: 'YEV',
    icaoCode: 'CYEV',
    name: 'Inuvik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Inuvik Mike Zubko',
    region: '',
    terminal: '',
    timezoneId: 'America/Inuvik',
    utcOffset: -420,
    latitude: 68.304167,
    longitude: -133.482778,
    altitude: 68.2752
  },
  {
    iataCode: 'HDH',
    icaoCode: 'PHDH',
    name: 'Dillingham',
    country: {code: 'US', name: 'United States'},
    city: 'Dillingham',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.579475,
    longitude: -158.197281,
    altitude: 4.2672
  },
  {
    iataCode: 'PTP',
    icaoCode: 'TFFR',
    name: 'Pointe-a-pitre',
    country: {code: 'GP', name: 'Guadeloupe'},
    city: 'Le Raizet',
    region: '',
    terminal: '',
    timezoneId: 'America/Guadeloupe',
    utcOffset: -240,
    latitude: 16.265306,
    longitude: -61.531806,
    altitude: 10.9728
  },
  {
    iataCode: 'HDM',
    icaoCode: 'OIHH',
    name: 'Hamadan',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Hamadan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 34.869167,
    longitude: 48.5525,
    altitude: 1754.124
  },
  {
    iataCode: 'PTU',
    icaoCode: 'PAPM',
    name: 'Port Moller',
    country: {code: 'US', name: 'United States'},
    city: 'Platinum',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 59.011356,
    longitude: -161.819664,
    altitude: 4.572
  },
  {
    iataCode: 'HDN',
    icaoCode: 'KHDN',
    name: 'Hayden',
    country: {code: 'US', name: 'United States'},
    city: 'Yampa Valley',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 40.481181,
    longitude: -107.21766,
    altitude: 2012.2896
  },
  {
    iataCode: 'PTY',
    icaoCode: 'MPTO',
    name: 'Panama City',
    country: {code: 'PA', name: 'Panama'},
    city: 'Tocumen Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 9.071364,
    longitude: -79.383453,
    altitude: 41.148
  },
  {
    iataCode: 'YFB',
    icaoCode: 'CYFB',
    name: 'Iqaluit',
    country: {code: 'CA', name: 'Canada'},
    city: 'Iqaluit',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 63.75639,
    longitude: -68.555832,
    altitude: 33.528
  },
  {
    iataCode: 'YFA',
    icaoCode: 'CYFA',
    name: 'Fort Albany',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Albany Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.2014,
    longitude: -81.6969,
    altitude: 14.6304
  },
  {
    iataCode: 'HDS',
    icaoCode: 'FAHS',
    name: 'Hoedspruit',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Hoedspruit Afb',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -24.368642,
    longitude: 31.048744,
    altitude: 531.2664
  },
  {
    iataCode: 'YFC',
    icaoCode: 'CYFC',
    name: 'Fredericton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fredericton',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 45.868889,
    longitude: -66.537222,
    altitude: 20.7264
  },
  {
    iataCode: 'YFH',
    icaoCode: 'CYFH',
    name: 'Fort Hope',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Hope Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 51.5619,
    longitude: -87.9078,
    altitude: 274.0152
  },
  {
    iataCode: 'HDY',
    icaoCode: 'VTSS',
    name: 'Songkhla',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Hat Yai Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 6.933206,
    longitude: 100.392975,
    altitude: 27.432
  },
  {
    iataCode: 'PUB',
    icaoCode: 'KPUB',
    name: 'Pueblo Memorial',
    country: {code: 'US', name: 'United States'},
    city: 'Pueblo Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 38.289085,
    longitude: -104.496572,
    altitude: 1440.4848
  },
  {
    iataCode: 'PUD',
    icaoCode: 'SAWD',
    name: 'Puerto Deseado',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Puerto Deseado',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -47.735292,
    longitude: -65.904097,
    altitude: 81.0768
  },
  {
    iataCode: 'PUC',
    icaoCode: 'KPUC',
    name: 'Price',
    country: {code: 'US', name: 'United States'},
    city: 'Carbon County Regional-buck Davis Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 39.609722,
    longitude: -110.75278,
    altitude: 1815.6936
  },
  {
    iataCode: 'PUF',
    icaoCode: 'LFBP',
    name: 'Pau',
    country: {code: 'FR', name: 'France'},
    city: 'Pau Pyrenees',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.38,
    longitude: -0.418611,
    altitude: 187.7568
  },
  {
    iataCode: 'PUG',
    icaoCode: 'YPAG',
    name: 'Argyle',
    country: {code: 'AU', name: 'Australia'},
    city: 'Port Augusta Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -32.506944,
    longitude: 137.716667,
    altitude: 17.0688
  },
  {
    iataCode: 'YFO',
    icaoCode: 'CYFO',
    name: 'Flin Flon',
    country: {code: 'CA', name: 'Canada'},
    city: 'Flin Flon',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 54.678055,
    longitude: -101.681667,
    altitude: 303.8856
  },
  {
    iataCode: 'PUJ',
    icaoCode: 'MDPC',
    name: 'Punta Cana',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Punta Cana Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.567367,
    longitude: -68.363431,
    altitude: 14.3256
  },
  {
    iataCode: 'YFR',
    icaoCode: 'CYFR',
    name: 'Fort Resolution',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Resolution',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 61.180832,
    longitude: -113.689722,
    altitude: 160.3248
  },
  {
    iataCode: 'HEA',
    icaoCode: 'OAHR',
    name: 'Herat',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Herat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 34.210017,
    longitude: 62.2283,
    altitude: 977.1888
  },
  {
    iataCode: 'YFS',
    icaoCode: 'CYFS',
    name: 'Fort Simpson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Simpson',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 61.760153,
    longitude: -121.236525,
    altitude: 169.164
  },
  {
    iataCode: 'HEH',
    icaoCode: 'VYHH',
    name: 'Heho',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Heho',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 20.747036,
    longitude: 96.792044,
    altitude: 1175.9184
  },
  {
    iataCode: 'YFX',
    icaoCode: 'CCK4',
    name: 'St. Lewis',
    country: {code: 'CA', name: 'Canada'},
    city: 'St. Lewis (Fox Harbour) Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 52.3728,
    longitude: -55.6739,
    altitude: 22.5552
  },
  {
    iataCode: 'PUR',
    icaoCode: 'SLPR',
    name: 'Puerto Rico/manuripi',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Puerto Rico Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -11.1077,
    longitude: -67.5512,
    altitude: 181.9656
  },
  {
    iataCode: 'HEI',
    icaoCode: 'EDXB',
    name: 'Büsum',
    country: {code: 'DE', name: 'Germany'},
    city: 'Heide-büsum Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 54.1533,
    longitude: 8.90167,
    altitude: 2.1336
  },
  {
    iataCode: 'PUQ',
    icaoCode: 'SCCI',
    name: 'Punta Arenas',
    country: {code: 'CL', name: 'Chile'},
    city: 'Carlos Ibanez Del Campo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -53.002642,
    longitude: -70.854586,
    altitude: 42.3672
  },
  {
    iataCode: 'HEL',
    icaoCode: 'EFHK',
    name: 'Helsinki',
    country: {code: 'FI', name: 'Finland'},
    city: 'Helsinki Vantaa',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 60.317222,
    longitude: 24.963333,
    altitude: 54.5592
  },
  {
    iataCode: 'HEK',
    icaoCode: 'ZYHE',
    name: 'Heihe',
    country: {code: 'CN', name: 'China'},
    city: 'Heihe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 50.25,
    longitude: 127.3,
    altitude: 2599.944
  },
  {
    iataCode: 'PUS',
    icaoCode: 'RKPK',
    name: 'Kimhae',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Gimhae Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.179528,
    longitude: 128.938222,
    altitude: 1.8288
  },
  {
    iataCode: 'HEM',
    icaoCode: 'EFHF',
    name: 'Helsinki',
    country: {code: 'FI', name: 'Finland'},
    city: 'Helsinki Malmi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 60.254558,
    longitude: 25.042828,
    altitude: 17.3736
  },
  {
    iataCode: 'PUU',
    icaoCode: 'SKAS',
    name: 'Puerto Asis',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Tres De Mayo',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 0.505228,
    longitude: -76.500836,
    altitude: 248.412
  },
  {
    iataCode: 'PUW',
    icaoCode: 'KPUW',
    name: 'Pullman',
    country: {code: 'US', name: 'United States'},
    city: 'Pullman-moscow Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.743861,
    longitude: -117.109583,
    altitude: 779.0688
  },
  {
    iataCode: 'HER',
    icaoCode: 'LGIR',
    name: 'Heraklion',
    country: {code: 'GR', name: 'Greece'},
    city: 'Nikos Kazantzakis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 35.339719,
    longitude: 25.180297,
    altitude: 35.052
  },
  {
    iataCode: 'PUZ',
    icaoCode: 'MNPC',
    name: 'Puerto Cabezas',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Puerto Cabezas',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 14.047194,
    longitude: -83.386722,
    altitude: 21.0312
  },
  {
    iataCode: 'PUY',
    icaoCode: 'LDPL',
    name: 'Pula',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Pula',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 44.893533,
    longitude: 13.922192,
    altitude: 83.5152
  },
  {
    iataCode: 'YGB',
    icaoCode: 'CYGB',
    name: 'Texada',
    country: {code: 'CA', name: 'Canada'},
    city: 'Texada Gillies Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.6942,
    longitude: -124.518,
    altitude: 99.3648
  },
  {
    iataCode: 'HET',
    icaoCode: 'ZBHH',
    name: 'Huhhot',
    country: {code: 'CN', name: 'China'},
    city: 'Baita Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 40.851422,
    longitude: 111.824103,
    altitude: 1083.8688
  },
  {
    iataCode: 'PVA',
    icaoCode: 'SKPV',
    name: 'Providencia',
    country: {code: 'CO', name: 'Colombia'},
    city: 'El Embrujo',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 13.356944,
    longitude: -81.35833,
    altitude: 3.048
  },
  {
    iataCode: 'YGH',
    icaoCode: 'CYGH',
    name: 'Fort Good Hope',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Good Hope',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 66.266667,
    longitude: -128.65,
    altitude: 65.532
  },
  {
    iataCode: 'PVC',
    icaoCode: 'KPVC',
    name: 'Provincetown',
    country: {code: 'US', name: 'United States'},
    city: 'Provincetown Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.071945,
    longitude: -70.22139,
    altitude: 2.7432
  },
  {
    iataCode: 'YGK',
    icaoCode: 'CYGK',
    name: 'Kingston',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kingston',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 44.225277,
    longitude: -76.596944,
    altitude: 92.964
  },
  {
    iataCode: 'YGJ',
    icaoCode: 'RJOH',
    name: 'Miho',
    country: {code: 'JP', name: 'Japan'},
    city: 'Miho',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.492222,
    longitude: 133.236389,
    altitude: 6.096
  },
  {
    iataCode: 'PVD',
    icaoCode: 'KPVD',
    name: 'Providence',
    country: {code: 'US', name: 'United States'},
    city: 'Theodore Francis Green State',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.732581,
    longitude: -71.420383,
    altitude: 16.764
  },
  {
    iataCode: 'YGL',
    icaoCode: 'CYGL',
    name: 'La Grande Riviere',
    country: {code: 'CA', name: 'Canada'},
    city: 'La Grande Riviere',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 53.625278,
    longitude: -77.704167,
    altitude: 194.7672
  },
  {
    iataCode: 'PVG',
    icaoCode: 'ZSPD',
    name: 'Shanghai',
    country: {code: 'CN', name: 'China'},
    city: 'Pudong',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.143378,
    longitude: 121.805214,
    altitude: 3.9624
  },
  {
    iataCode: 'YGO',
    icaoCode: 'CYGO',
    name: 'Gods Lake Narrows',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gods Lake Narrows Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 54.5589,
    longitude: -94.4914,
    altitude: 188.0616
  },
  {
    iataCode: 'HFA',
    icaoCode: 'LLHA',
    name: 'Haifa',
    country: {code: 'IL', name: 'Israel'},
    city: 'Haifa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 32.809444,
    longitude: 35.043056,
    altitude: 8.5344
  },
  {
    iataCode: 'YGQ',
    icaoCode: 'CYGQ',
    name: 'Geraldton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Geraldton Greenstone Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 49.778332,
    longitude: -86.939445,
    altitude: 348.6912
  },
  {
    iataCode: 'PVH',
    icaoCode: 'SBPV',
    name: 'Porto Velho',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Governador Jorge Teixeira De Oliveira',
    region: '',
    terminal: '',
    timezoneId: 'America/Porto_Velho',
    utcOffset: -240,
    latitude: -8.709294,
    longitude: -63.902281,
    altitude: 89.6112
  },
  {
    iataCode: 'YGP',
    icaoCode: 'CYGP',
    name: 'Gaspe',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gaspe',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.775278,
    longitude: -64.478611,
    altitude: 32.9184
  },
  {
    iataCode: 'PVK',
    icaoCode: 'LGPZ',
    name: 'Preveza',
    country: {code: 'GR', name: 'Greece'},
    city: 'Aktio',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.925467,
    longitude: 20.765311,
    altitude: 3.3528
  },
  {
    iataCode: 'YGR',
    icaoCode: 'CYGR',
    name: 'Iles De La Madeleine',
    country: {code: 'CA', name: 'Canada'},
    city: 'Iles De La Madeleine',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 47.424721,
    longitude: -61.778056,
    altitude: 10.668
  },
  {
    iataCode: 'HFE',
    icaoCode: 'ZSOF',
    name: 'Hefei',
    country: {code: 'CN', name: 'China'},
    city: 'Luogang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 31.780019,
    longitude: 117.298436,
    altitude: 32.9184
  },
  {
    iataCode: 'HFD',
    icaoCode: 'KHFD',
    name: 'Hartford',
    country: {code: 'US', name: 'United States'},
    city: 'Hartford Brainard',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.736722,
    longitude: -72.649444,
    altitude: 5.4864
  },
  {
    iataCode: 'YGT',
    icaoCode: 'CYGT',
    name: 'Igloolik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Igloolik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 69.3647,
    longitude: -81.8161,
    altitude: 53.0352
  },
  {
    iataCode: 'PVO',
    icaoCode: 'SEPV',
    name: 'Portoviejo',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Reales Tamarindos',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -1.041647,
    longitude: -80.472206,
    altitude: 39.624
  },
  {
    iataCode: 'YGW',
    icaoCode: 'CYGW',
    name: 'Kuujjuarapik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kuujjuarapik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 55.2819,
    longitude: -77.7653,
    altitude: 10.3632
  },
  {
    iataCode: 'YGV',
    icaoCode: 'CYGV',
    name: 'Havre-saint-pierre',
    country: {code: 'CA', name: 'Canada'},
    city: 'Havre Saint-pierre Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -240,
    latitude: 50.281944,
    longitude: -63.611389,
    altitude: 37.7952
  },
  {
    iataCode: 'YGX',
    icaoCode: 'CYGX',
    name: 'Gillam',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gillam Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 56.3575,
    longitude: -94.7106,
    altitude: 145.0848
  },
  {
    iataCode: 'PVS',
    icaoCode: 'UHMD',
    name: 'Provideniya Bay',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Provideniya Bay',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Anadyr',
    utcOffset: 720,
    latitude: 64.378139,
    longitude: -173.243306,
    altitude: 21.6408
  },
  {
    iataCode: 'PVR',
    icaoCode: 'MMPR',
    name: 'Puerto Vallarta',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Licenciado Gustavo Diaz Ordaz Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.680083,
    longitude: -105.254167,
    altitude: 7.0104
  },
  {
    iataCode: 'YGZ',
    icaoCode: 'CYGZ',
    name: 'Grise Fiord',
    country: {code: 'CA', name: 'Canada'},
    city: 'Grise Fiord Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 76.4261,
    longitude: -82.9092,
    altitude: 44.5008
  },
  {
    iataCode: 'PVU',
    icaoCode: 'KPVU',
    name: 'Provo',
    country: {code: 'US', name: 'United States'},
    city: 'Provo Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 40.218056,
    longitude: -111.722222,
    altitude: 1370.6856
  },
  {
    iataCode: 'HFN',
    icaoCode: 'BIHN',
    name: 'Hofn',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Hornafjordur',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 64.295556,
    longitude: -15.227222,
    altitude: 7.3152
  },
  {
    iataCode: 'YHB',
    icaoCode: 'CYHB',
    name: 'Hudson Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Hudson Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 52.816666,
    longitude: -102.31139,
    altitude: 358.14
  },
  {
    iataCode: 'YHA',
    icaoCode: 'CCP4',
    name: 'Port Hope Simpson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Port Hope Simpson Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 52.5281,
    longitude: -56.2861,
    altitude: 105.7656
  },
  {
    iataCode: 'HFS',
    icaoCode: 'ESOH',
    name: 'Hagfors',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Hagfors Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 60.020064,
    longitude: 13.578908,
    altitude: 144.4752
  },
  {
    iataCode: 'YHD',
    icaoCode: 'CYHD',
    name: 'Dryden',
    country: {code: 'CA', name: 'Canada'},
    city: 'Dryden Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 49.831667,
    longitude: -92.744167,
    altitude: 412.6992
  },
  {
    iataCode: 'YHC',
    icaoCode: 'CYHC',
    name: '',
    country: {code: 'CA', name: 'Canada'},
    city: 'Vancouver Harbour Water Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.2944,
    longitude: -123.111,
    altitude: 0.0
  },
  {
    iataCode: 'HFT',
    icaoCode: 'ENHF',
    name: 'Hammerfest',
    country: {code: 'NO', name: 'Norway'},
    city: 'Hammerfest Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.679722,
    longitude: 23.668889,
    altitude: 243.5352
  },
  {
    iataCode: 'YHI',
    icaoCode: 'CYHI',
    name: 'Holman Island',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ulukhaktok Holman',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 70.762778,
    longitude: -117.806111,
    altitude: 35.6616
  },
  {
    iataCode: 'YHK',
    icaoCode: 'CYHK',
    name: 'Gjoa Haven',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gjoa Haven',
    region: '',
    terminal: '',
    timezoneId: 'America/Cambridge_Bay',
    utcOffset: -360,
    latitude: 68.635556,
    longitude: -95.849722,
    altitude: 46.3296
  },
  {
    iataCode: 'YHM',
    icaoCode: 'CYHM',
    name: 'Hamilton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Hamilton',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.173611,
    longitude: -79.935,
    altitude: 237.744
  },
  {
    iataCode: 'YHP',
    icaoCode: 'CPV7',
    name: 'Poplar Hill',
    country: {code: 'CA', name: 'Canada'},
    city: 'Poplar Hill Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.1133,
    longitude: -94.2556,
    altitude: 333.756
  },
  {
    iataCode: 'YHO',
    icaoCode: 'CYHO',
    name: 'Hopedale',
    country: {code: 'CA', name: 'Canada'},
    city: 'Hopedale Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 55.4483,
    longitude: -60.2286,
    altitude: 11.8872
  },
  {
    iataCode: 'YHR',
    icaoCode: 'CYHR',
    name: 'Chevery',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chevery Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Blanc-Sablon',
    utcOffset: -240,
    latitude: 50.4689,
    longitude: -59.6367,
    altitude: 11.8872
  },
  {
    iataCode: 'HGA',
    icaoCode: 'HCMH',
    name: 'Hargeisa',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Egal Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 9.518167,
    longitude: 44.088758,
    altitude: 1348.1304
  },
  {
    iataCode: 'HGD',
    icaoCode: 'YHUG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hughenden Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.815,
    longitude: 144.225,
    altitude: 317.9064
  },
  {
    iataCode: 'PWM',
    icaoCode: 'KPWM',
    name: 'Portland',
    country: {code: 'US', name: 'United States'},
    city: 'Portland Intl Jetport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.646161,
    longitude: -70.309281,
    altitude: 23.4696
  },
  {
    iataCode: 'YHU',
    icaoCode: 'CYHU',
    name: 'Montreal',
    country: {code: 'CA', name: 'Canada'},
    city: 'St Hubert',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.5175,
    longitude: -73.416944,
    altitude: 27.432
  },
  {
    iataCode: 'HGH',
    icaoCode: 'ZSHC',
    name: 'Hangzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Xiaoshan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 30.229503,
    longitude: 120.434453,
    altitude: 7.0104
  },
  {
    iataCode: 'YHZ',
    icaoCode: 'CYHZ',
    name: 'Halifax',
    country: {code: 'CA', name: 'Canada'},
    city: 'Halifax Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 44.880833,
    longitude: -63.50861,
    altitude: 145.3896
  },
  {
    iataCode: 'PWQ',
    icaoCode: 'UASP',
    name: 'Pavlodar',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Pavlodar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 52.195,
    longitude: 77.073889,
    altitude: 124.968
  },
  {
    iataCode: 'YHY',
    icaoCode: 'CYHY',
    name: 'Hay River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Hay River',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 60.839722,
    longitude: -115.782778,
    altitude: 165.5064
  },
  {
    iataCode: 'HGL',
    icaoCode: 'EDXH',
    name: 'Helgoland',
    country: {code: 'DE', name: 'Germany'},
    city: 'Helgoland-düne Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 54.1853,
    longitude: 7.91583,
    altitude: 2.1336
  },
  {
    iataCode: 'PWT',
    icaoCode: 'KPWT',
    name: 'Bremerton',
    country: {code: 'US', name: 'United States'},
    city: 'Bremerton National',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 47.490244,
    longitude: -122.764814,
    altitude: 135.3312
  },
  {
    iataCode: 'HGN',
    icaoCode: 'VTCH',
    name: 'Mae Hong Son',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Mae Hong Son',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 19.301667,
    longitude: 97.975,
    altitude: 283.1592
  },
  {
    iataCode: 'HGO',
    icaoCode: 'DIKO',
    name: 'Korhogo',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Korhogo',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 9.387183,
    longitude: -5.556664,
    altitude: 370.0272
  },
  {
    iataCode: 'HGR',
    icaoCode: 'KHGR',
    name: 'Hagerstown',
    country: {code: 'US', name: 'United States'},
    city: 'Hagerstown Regional Richard A Henson Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.7079,
    longitude: -77.7295,
    altitude: 214.5792
  },
  {
    iataCode: 'YIB',
    icaoCode: 'CYIB',
    name: 'Atikokan',
    country: {code: 'CA', name: 'Canada'},
    city: 'Atikokan Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Atikokan',
    utcOffset: -360,
    latitude: 48.773888,
    longitude: -91.638611,
    altitude: 429.1584
  },
  {
    iataCode: 'HGU',
    icaoCode: 'AYMH',
    name: 'Mount Hagen',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Mount Hagen',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.826789,
    longitude: 144.295861,
    altitude: 1642.2624
  },
  {
    iataCode: 'YIF',
    icaoCode: 'CYIF',
    name: 'St-augustin',
    country: {code: 'CA', name: 'Canada'},
    city: 'St Augustin Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Blanc-Sablon',
    utcOffset: -240,
    latitude: 51.2117,
    longitude: -58.6583,
    altitude: 6.096
  },
  {
    iataCode: 'YIH',
    icaoCode: 'ZHYC',
    name: 'Yichang',
    country: {code: 'CN', name: 'China'},
    city: 'Yichang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 30.671,
    longitude: 111.441,
    altitude: 0.0
  },
  {
    iataCode: 'YIK',
    icaoCode: 'CYIK',
    name: 'Ivujivik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ivujivik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 62.4173,
    longitude: -77.9253,
    altitude: 38.4048
  },
  {
    iataCode: 'YIO',
    icaoCode: 'CYIO',
    name: 'Pond Inlet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Pond Inlet',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 72.683334,
    longitude: -77.966667,
    altitude: 55.1688
  },
  {
    iataCode: 'YIN',
    icaoCode: 'ZWYN',
    name: 'Yining',
    country: {code: 'CN', name: 'China'},
    city: 'Yining Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kashgar',
    utcOffset: 480,
    latitude: 43.9558,
    longitude: 81.3303,
    altitude: 0.0
  },
  {
    iataCode: 'YIP',
    icaoCode: 'KYIP',
    name: 'Detroit',
    country: {code: 'US', name: 'United States'},
    city: 'Willow Run',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.237928,
    longitude: -83.530408,
    altitude: 218.2368
  },
  {
    iataCode: 'PXM',
    icaoCode: 'MMPS',
    name: 'Puerto Escondido',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Puerto Escondido Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 15.876861,
    longitude: -97.089117,
    altitude: 89.6112
  },
  {
    iataCode: 'PXO',
    icaoCode: 'LPPS',
    name: 'Porto Santo',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Porto Santo',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Madeira',
    utcOffset: 0,
    latitude: 33.073386,
    longitude: -16.349975,
    altitude: 103.9368
  },
  {
    iataCode: 'YIW',
    icaoCode: 'ZSYW',
    name: 'Yiwu',
    country: {code: 'CN', name: 'China'},
    city: 'Yiwu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.3447,
    longitude: 120.032,
    altitude: 0.0
  },
  {
    iataCode: 'YIV',
    icaoCode: 'CYIV',
    name: 'Island Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Island Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.8572,
    longitude: -94.6536,
    altitude: 234.696
  },
  {
    iataCode: 'HHI',
    icaoCode: 'PHHI',
    name: 'Wheeler Afb.',
    country: {code: 'US', name: 'United States'},
    city: 'Wheeler Aaf',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.4835,
    longitude: -158.039667,
    altitude: 255.1176
  },
  {
    iataCode: 'HHH',
    icaoCode: 'KHHH',
    name: 'Hilton Head',
    country: {code: 'US', name: 'United States'},
    city: 'Hilton Head',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.216,
    longitude: -80.752,
    altitude: 3.048
  },
  {
    iataCode: 'PXU',
    icaoCode: 'VVPK',
    name: 'Pleiku',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Pleiku Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 14.004522,
    longitude: 108.017158,
    altitude: 741.8832
  },
  {
    iataCode: 'HHN',
    icaoCode: 'EDFH',
    name: 'Hahn',
    country: {code: 'DE', name: 'Germany'},
    city: 'Frankfurt Hahn',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.948672,
    longitude: 7.263892,
    altitude: 502.6152
  },
  {
    iataCode: 'HHQ',
    icaoCode: 'VTPH',
    name: 'Prachuap Khiri Khan',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Hua Hin',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 12.636225,
    longitude: 99.951533,
    altitude: 18.8976
  },
  {
    iataCode: 'HHR',
    icaoCode: 'KHHR',
    name: 'Hawthorne',
    country: {code: 'US', name: 'United States'},
    city: 'Jack Northrop Fld Hawthorne Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.922839,
    longitude: -118.335186,
    altitude: 20.1168
  },
  {
    iataCode: 'YJN',
    icaoCode: 'CYJN',
    name: 'St. Jean',
    country: {code: 'CA', name: 'Canada'},
    city: 'St Jean',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.294445,
    longitude: -73.281111,
    altitude: 41.4528
  },
  {
    iataCode: 'PYE',
    icaoCode: 'NCPY',
    name: 'Penrhyn Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Penrhyn Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -9.00667,
    longitude: -158.037,
    altitude: 2.4384
  },
  {
    iataCode: 'YJM',
    icaoCode: 'CYJM',
    name: 'Fort St. James',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort St. James - Perison Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.397222,
    longitude: -124.262778,
    altitude: 720.5472
  },
  {
    iataCode: 'PYH',
    icaoCode: 'SVPA',
    name: 'Puerto Ayacucho',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Casique Aramare',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 5.619992,
    longitude: -67.606103,
    altitude: 74.676
  },
  {
    iataCode: 'HIB',
    icaoCode: 'KHIB',
    name: 'Hibbing',
    country: {code: 'US', name: 'United States'},
    city: 'Chisholm Hibbing',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.3866,
    longitude: -92.838994,
    altitude: 412.3944
  },
  {
    iataCode: 'PYJ',
    icaoCode: 'UERP',
    name: 'Yakutia',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Poliarny Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 66.400431,
    longitude: 112.030325,
    altitude: 505.968
  },
  {
    iataCode: 'HID',
    icaoCode: 'YHID',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Horn Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -10.5864,
    longitude: 142.29,
    altitude: 13.1064
  },
  {
    iataCode: 'YJT',
    icaoCode: 'CYJT',
    name: 'Stephenville',
    country: {code: 'CA', name: 'Canada'},
    city: 'Stephenville',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 48.544167,
    longitude: -58.549999,
    altitude: 24.384
  },
  {
    iataCode: 'HIF',
    icaoCode: 'KHIF',
    name: 'Ogden',
    country: {code: 'US', name: 'United States'},
    city: 'Hill Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.123939,
    longitude: -111.973039,
    altitude: 1459.6872
  },
  {
    iataCode: 'HIJ',
    icaoCode: 'RJOA',
    name: 'Hiroshima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Hiroshima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.436111,
    longitude: 132.919444,
    altitude: 331.6224
  },
  {
    iataCode: 'PYR',
    icaoCode: 'LGAD',
    name: 'Andravida',
    country: {code: 'GR', name: 'Greece'},
    city: 'Andravida',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.920708,
    longitude: 21.292583,
    altitude: 16.764
  },
  {
    iataCode: 'HII',
    icaoCode: 'KHII',
    name: 'Lake Havasu City',
    country: {code: 'US', name: 'United States'},
    city: 'Lake Havasu City Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 34.571111,
    longitude: -114.358278,
    altitude: 238.6584
  },
  {
    iataCode: 'HIN',
    icaoCode: 'RKPS',
    name: 'Sacheon',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Sacheon Air Base',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 35.088543,
    longitude: 128.07037,
    altitude: 7.62
  },
  {
    iataCode: 'YKA',
    icaoCode: 'CYKA',
    name: 'Kamloops',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kamloops',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 50.702222,
    longitude: -120.444444,
    altitude: 345.3384
  },
  {
    iataCode: 'HIR',
    icaoCode: 'AGGH',
    name: 'Honiara',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Honiara International',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.428,
    longitude: 160.054789,
    altitude: 8.5344
  },
  {
    iataCode: 'PYY',
    icaoCode: 'VTCI',
    name: 'Pai',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Pai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 19.372,
    longitude: 98.437,
    altitude: 387.4008
  },
  {
    iataCode: 'HIS',
    icaoCode: 'YHYN',
    name: 'Hayman Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hayman Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.066668,
    longitude: 148.86667,
    altitude: 3.048
  },
  {
    iataCode: 'YKG',
    icaoCode: 'CYAS',
    name: 'Kangirsuk',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kangirsuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 60.0272,
    longitude: -69.9992,
    altitude: 122.8344
  },
  {
    iataCode: 'YKF',
    icaoCode: 'CYKF',
    name: 'Waterloo',
    country: {code: 'CA', name: 'Canada'},
    city: 'Waterloo',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.460833,
    longitude: -80.378611,
    altitude: 321.2592
  },
  {
    iataCode: 'PZB',
    icaoCode: 'FAPM',
    name: 'Pietermaritzburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Pietermaritzburg',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.648975,
    longitude: 30.398667,
    altitude: 738.5304
  },
  {
    iataCode: 'PZE',
    icaoCode: 'EGHK',
    name: 'Penzance',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Penzance Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 50.1281,
    longitude: -5.51845,
    altitude: 4.2672
  },
  {
    iataCode: 'YKM',
    icaoCode: 'KYKM',
    name: 'Yakima',
    country: {code: 'US', name: 'United States'},
    city: 'Yakima Air Terminal Mcallister Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.5682,
    longitude: -120.544,
    altitude: 333.756
  },
  {
    iataCode: 'YKL',
    icaoCode: 'CYKL',
    name: 'Schefferville',
    country: {code: 'CA', name: 'Canada'},
    city: 'Schefferville',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -240,
    latitude: 54.805278,
    longitude: -66.805278,
    altitude: 520.9032
  },
  {
    iataCode: 'PZI',
    icaoCode: 'ZUZH',
    name: 'Panzhihua',
    country: {code: 'CN', name: 'China'},
    city: 'Panzhihua',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 26.54,
    longitude: 101.799,
    altitude: 2799.8928
  },
  {
    iataCode: 'YKQ',
    icaoCode: 'CYKQ',
    name: 'Waskaganish',
    country: {code: 'CA', name: 'Canada'},
    city: 'Waskaganish Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 51.4733,
    longitude: -78.7583,
    altitude: 24.384
  },
  {
    iataCode: 'PZH',
    icaoCode: 'OPZB',
    name: 'Zhob',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Zhob',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 31.358381,
    longitude: 69.463606,
    altitude: 1441.0944
  },
  {
    iataCode: 'YKS',
    icaoCode: 'UEEE',
    name: 'Yakutsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Yakutsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 62.09325,
    longitude: 129.770672,
    altitude: 99.06
  },
  {
    iataCode: 'YKU',
    icaoCode: 'CSU2',
    name: 'Chisasibi',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chisasibi Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 53.8056,
    longitude: -78.9169,
    altitude: 13.1064
  },
  {
    iataCode: 'PZO',
    icaoCode: 'SVPR',
    name: 'Guayana',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'General Manuel Carlos Piar',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 8.288528,
    longitude: -62.760361,
    altitude: 143.8656
  },
  {
    iataCode: 'YKY',
    icaoCode: 'CYKY',
    name: 'Kindersley',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kindersley',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 51.5175,
    longitude: -109.180833,
    altitude: 694.0296
  },
  {
    iataCode: 'YKZ',
    icaoCode: 'CYKZ',
    name: 'Toronto',
    country: {code: 'CA', name: 'Canada'},
    city: 'Buttonville Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.862221,
    longitude: -79.37,
    altitude: 198.12
  },
  {
    iataCode: 'PZU',
    icaoCode: 'HSPN',
    name: 'Port Sudan',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Port Sudan New International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 19.4336,
    longitude: 37.2341,
    altitude: 41.148
  },
  {
    iataCode: 'PZY',
    icaoCode: 'LZPP',
    name: 'Piestany',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'Piestany',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 48.625247,
    longitude: 17.828444,
    altitude: 166.116
  },
  {
    iataCode: 'YLD',
    icaoCode: 'CYLD',
    name: 'Chapleau',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chapleau',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 47.82,
    longitude: -83.346667,
    altitude: 448.056
  },
  {
    iataCode: 'HJR',
    icaoCode: 'VAKJ',
    name: 'Khajuraho',
    country: {code: 'IN', name: 'India'},
    city: 'Khajuraho',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.817197,
    longitude: 79.918597,
    altitude: 221.8944
  },
  {
    iataCode: 'YLC',
    icaoCode: 'CYLC',
    name: 'Kimmirut',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kimmirut Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 62.85,
    longitude: -69.8833,
    altitude: 53.34
  },
  {
    iataCode: 'YLE',
    icaoCode: 'CEM3',
    name: 'Whatì',
    country: {code: 'CA', name: 'Canada'},
    city: 'Whatì Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 63.1317,
    longitude: -117.246,
    altitude: 268.8336
  },
  {
    iataCode: 'YLH',
    icaoCode: 'CYLH',
    name: 'Lansdowne House',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lansdowne House Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.1956,
    longitude: -87.9342,
    altitude: 254.2032
  },
  {
    iataCode: 'YLJ',
    icaoCode: 'CYLJ',
    name: 'Meadow Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Meadow Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 54.125278,
    longitude: -108.522778,
    altitude: 480.3648
  },
  {
    iataCode: 'YLL',
    icaoCode: 'CYLL',
    name: 'Lloydminster',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lloydminster',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -360,
    latitude: 53.309166,
    longitude: -110.0725,
    altitude: 668.4264
  },
  {
    iataCode: 'HKB',
    icaoCode: 'PAHV',
    name: 'Healy',
    country: {code: 'US', name: 'United States'},
    city: 'Healy River Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 63.8675,
    longitude: -148.968889,
    altitude: 384.9624
  },
  {
    iataCode: 'HKD',
    icaoCode: 'RJCH',
    name: 'Hakodate',
    country: {code: 'JP', name: 'Japan'},
    city: 'Hakodate',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 41.77,
    longitude: 140.821944,
    altitude: 46.0248
  },
  {
    iataCode: 'YLT',
    icaoCode: 'CYLT',
    name: 'Alert',
    country: {code: 'CA', name: 'Canada'},
    city: 'Alert',
    region: '',
    terminal: '',
    timezoneId: 'America/Pangnirtung',
    utcOffset: -300,
    latitude: 82.517778,
    longitude: -62.280556,
    altitude: 30.48
  },
  {
    iataCode: 'HKG',
    icaoCode: 'VHHH',
    name: 'Hong Kong',
    country: {code: 'HK', name: 'Hong Kong'},
    city: 'Hong Kong Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Hong_Kong',
    utcOffset: 480,
    latitude: 22.308919,
    longitude: 113.914603,
    altitude: 8.5344
  },
  {
    iataCode: 'YLW',
    icaoCode: 'CYLW',
    name: 'Kelowna',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kelowna',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.956112,
    longitude: -119.377778,
    altitude: 429.4632
  },
  {
    iataCode: 'HKK',
    icaoCode: 'NZHK',
    name: 'Hokitika',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Hokitika',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -42.713611,
    longitude: 170.985278,
    altitude: 44.5008
  },
  {
    iataCode: 'HKN',
    icaoCode: 'AYHK',
    name: 'Hoskins',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Kimbe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -5.46217,
    longitude: 150.405,
    altitude: 20.1168
  },
  {
    iataCode: 'YMA',
    icaoCode: 'CYMA',
    name: 'Mayo',
    country: {code: 'CA', name: 'Canada'},
    city: 'Mayo',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 63.616389,
    longitude: -135.868333,
    altitude: 503.8344
  },
  {
    iataCode: 'HKT',
    icaoCode: 'VTSP',
    name: 'Phuket',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Phuket Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 8.1132,
    longitude: 98.316872,
    altitude: 24.9936
  },
  {
    iataCode: 'HKV',
    icaoCode: 'LB14',
    name: 'Haskovo',
    country: {code: 'BG', name: 'Bulgaria'},
    city: 'Uzundzhovo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Sofia',
    utcOffset: 120,
    latitude: 41.976375,
    longitude: 25.589817,
    altitude: 48.768
  },
  {
    iataCode: 'YMH',
    icaoCode: 'CYMH',
    name: "Mary's Harbour",
    country: {code: 'CA', name: 'Canada'},
    city: "Mary's Harbour Airport",
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 52.3028,
    longitude: -55.8472,
    altitude: 11.5824
  },
  {
    iataCode: 'HKY',
    icaoCode: 'KHKY',
    name: 'Hickory',
    country: {code: 'US', name: 'United States'},
    city: 'Hickory Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.741147,
    longitude: -81.38955,
    altitude: 362.4072
  },
  {
    iataCode: 'YMJ',
    icaoCode: 'CYMJ',
    name: 'Moose Jaw',
    country: {code: 'CA', name: 'Canada'},
    city: 'Moose Jaw Air Vice Marshal C M Mcewen',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 50.330278,
    longitude: -105.559167,
    altitude: 576.6816
  },
  {
    iataCode: 'YMM',
    icaoCode: 'CYMM',
    name: 'Fort Mcmurray',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Mcmurray',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 56.653333,
    longitude: -111.221944,
    altitude: 369.1128
  },
  {
    iataCode: 'YMO',
    icaoCode: 'CYMO',
    name: 'Moosonee',
    country: {code: 'CA', name: 'Canada'},
    city: 'Moosonee',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 51.291111,
    longitude: -80.607778,
    altitude: 9.144
  },
  {
    iataCode: 'YMN',
    icaoCode: 'CYFT',
    name: 'Makkovik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Makkovik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 55.0769,
    longitude: -59.1864,
    altitude: 71.3232
  },
  {
    iataCode: 'HLA',
    icaoCode: 'FALA',
    name: 'Johannesburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Lanseria',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -25.938514,
    longitude: 27.926133,
    altitude: 1376.7816
  },
  {
    iataCode: 'YMS',
    icaoCode: 'SPMS',
    name: 'Yurimaguas',
    country: {code: 'PE', name: 'Peru'},
    city: 'Moises Benzaquen Rengifo',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -5.893772,
    longitude: -76.118211,
    altitude: 178.9176
  },
  {
    iataCode: 'HLD',
    icaoCode: 'ZBLA',
    name: 'Hailar',
    country: {code: 'CN', name: 'China'},
    city: 'Dongshan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 49.204997,
    longitude: 119.825,
    altitude: 661.1112
  },
  {
    iataCode: 'YMT',
    icaoCode: 'CYMT',
    name: 'Chibougamau',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chapais Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 49.7719,
    longitude: -74.5281,
    altitude: 387.096
  },
  {
    iataCode: 'YMW',
    icaoCode: 'CYMW',
    name: 'Maniwaki',
    country: {code: 'CA', name: 'Canada'},
    city: 'Maniwaki',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 46.272778,
    longitude: -75.990556,
    altitude: 199.9488
  },
  {
    iataCode: 'HLF',
    icaoCode: 'ESSF',
    name: 'Hultsfred',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Hultsfred',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.525833,
    longitude: 15.823333,
    altitude: 111.5568
  },
  {
    iataCode: 'HLH',
    icaoCode: 'ZBUL',
    name: 'Ulanhot',
    country: {code: 'CN', name: 'China'},
    city: 'Ulanhot Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 46.083,
    longitude: 122.017,
    altitude: 0.0
  },
  {
    iataCode: 'YMX',
    icaoCode: 'CYMX',
    name: 'Montreal',
    country: {code: 'CA', name: 'Canada'},
    city: 'Montreal Intl Mirabel',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.681944,
    longitude: -74.005278,
    altitude: 82.296
  },
  {
    iataCode: 'HLJ',
    icaoCode: 'EYSB',
    name: 'Barysiai',
    country: {code: 'LT', name: 'Lithuania'},
    city: 'Barysiai',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vilnius',
    utcOffset: 120,
    latitude: 56.070556,
    longitude: 23.558056,
    altitude: 82.296
  },
  {
    iataCode: 'HLN',
    icaoCode: 'KHLN',
    name: 'Helena',
    country: {code: 'US', name: 'United States'},
    city: 'Helena Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 46.606806,
    longitude: -111.98275,
    altitude: 1181.7096
  },
  {
    iataCode: 'YNB',
    icaoCode: 'OEYN',
    name: 'Yenbo',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Yenbo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 24.144244,
    longitude: 38.06335,
    altitude: 7.9248
  },
  {
    iataCode: 'HLP',
    icaoCode: 'WIHH',
    name: 'Jakarta',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Halim Perdanakusuma International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -6.26661,
    longitude: 106.891,
    altitude: 25.6032
  },
  {
    iataCode: 'YNA',
    icaoCode: 'CYNA',
    name: 'Natashquan',
    country: {code: 'CA', name: 'Canada'},
    city: 'Natashquan',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -240,
    latitude: 50.19,
    longitude: -61.789167,
    altitude: 11.8872
  },
  {
    iataCode: 'YND',
    icaoCode: 'CYND',
    name: 'Gatineau',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gatineau',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.521694,
    longitude: -75.563589,
    altitude: 64.3128
  },
  {
    iataCode: 'HLR',
    icaoCode: 'KHLR',
    name: 'Fort Hood',
    country: {code: 'US', name: 'United States'},
    city: 'Hood Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.138731,
    longitude: -97.714469,
    altitude: 281.6352
  },
  {
    iataCode: 'YNC',
    icaoCode: 'CYNC',
    name: 'Wemindji',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wemindji Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 53.0106,
    longitude: -78.8311,
    altitude: 20.1168
  },
  {
    iataCode: 'HLT',
    icaoCode: 'YHML',
    name: 'Hamilton',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hamilton Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -37.648889,
    longitude: 142.065278,
    altitude: 244.7544
  },
  {
    iataCode: 'YNE',
    icaoCode: 'CYNE',
    name: 'Norway House',
    country: {code: 'CA', name: 'Canada'},
    city: 'Norway House Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.9583,
    longitude: -97.8442,
    altitude: 223.7232
  },
  {
    iataCode: 'YNG',
    icaoCode: 'KYNG',
    name: 'Youngstown',
    country: {code: 'US', name: 'United States'},
    city: 'Youngstown Warren Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.260736,
    longitude: -80.679097,
    altitude: 364.5408
  },
  {
    iataCode: 'HLY',
    icaoCode: 'EGOV',
    name: 'Angelsey',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Anglesey Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.248097,
    longitude: -4.535339,
    altitude: 11.2776
  },
  {
    iataCode: 'YNJ',
    icaoCode: 'ZYYJ',
    name: 'Yanji',
    country: {code: 'CN', name: 'China'},
    city: 'Yanji Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 42.8828,
    longitude: 129.451258,
    altitude: 190.1952
  },
  {
    iataCode: 'YNL',
    icaoCode: 'CYNL',
    name: 'Points North Landing',
    country: {code: 'CA', name: 'Canada'},
    city: 'Points North Landing Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 58.2767,
    longitude: -104.082,
    altitude: 489.204
  },
  {
    iataCode: 'HLZ',
    icaoCode: 'NZHN',
    name: 'Hamilton',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Hamilton',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -37.866661,
    longitude: 175.332056,
    altitude: 52.4256
  },
  {
    iataCode: 'YNM',
    icaoCode: 'CYNM',
    name: 'Matagami',
    country: {code: 'CA', name: 'Canada'},
    city: 'Matagami',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 49.761667,
    longitude: -77.802778,
    altitude: 279.8064
  },
  {
    iataCode: 'YNO',
    icaoCode: 'CKQ3',
    name: 'North Spirit Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'North Spirit Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.49,
    longitude: -92.9711,
    altitude: 329.7936
  },
  {
    iataCode: 'HMA',
    icaoCode: 'USHH',
    name: 'Khanty-mansiysk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Khanty Mansiysk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 61.028479,
    longitude: 69.086067,
    altitude: 23.1648
  },
  {
    iataCode: 'YNT',
    icaoCode: 'ZSYT',
    name: 'Yantai',
    country: {code: 'CN', name: 'China'},
    city: 'Laishan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 37.401667,
    longitude: 121.371667,
    altitude: 17.9832
  },
  {
    iataCode: 'YNS',
    icaoCode: 'CYHH',
    name: 'Nemiscau',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nemiscau Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 51.6911,
    longitude: -76.1356,
    altitude: 244.4496
  },
  {
    iataCode: 'HME',
    icaoCode: 'DAUH',
    name: 'Hassi-messaoud',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Oued Irara',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 31.672972,
    longitude: 6.140444,
    altitude: 141.1224
  },
  {
    iataCode: 'YNZ',
    icaoCode: 'ZSYN',
    name: 'Yancheng',
    country: {code: 'CN', name: 'China'},
    city: 'Yancheng Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 33.3856,
    longitude: 120.125,
    altitude: 0.0
  },
  {
    iataCode: 'HMI',
    icaoCode: 'ZWHM',
    name: 'Hami',
    country: {code: 'CN', name: 'China'},
    city: 'Hami Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 42.8414,
    longitude: 93.6692,
    altitude: 0.0
  },
  {
    iataCode: 'YNY',
    icaoCode: 'RKNY',
    name: 'Sokcho / Gangneung',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Yangyang International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 38.061311,
    longitude: 128.669164,
    altitude: 73.4568
  },
  {
    iataCode: 'HMN',
    icaoCode: 'KHMN',
    name: 'Alamogordo',
    country: {code: 'US', name: 'United States'},
    city: 'Holloman Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.852519,
    longitude: -106.106525,
    altitude: 1247.5464
  },
  {
    iataCode: 'YOA',
    icaoCode: 'CYOA',
    name: 'Ekati',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ekati',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 64.698889,
    longitude: -110.614722,
    altitude: 469.392
  },
  {
    iataCode: 'HMO',
    icaoCode: 'MMHO',
    name: 'Hermosillo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Ignacio P Garcia Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Hermosillo',
    utcOffset: -420,
    latitude: 29.095858,
    longitude: -111.047858,
    altitude: 191.1096
  },
  {
    iataCode: 'YOC',
    icaoCode: 'CYOC',
    name: 'Old Crow',
    country: {code: 'CA', name: 'Canada'},
    city: 'Old Crow',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 67.570556,
    longitude: -139.839167,
    altitude: 251.1552
  },
  {
    iataCode: 'YOD',
    icaoCode: 'CYOD',
    name: 'Cold Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cold Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 54.404999,
    longitude: -110.279444,
    altitude: 541.02
  },
  {
    iataCode: 'HMV',
    icaoCode: 'ESUT',
    name: 'Hemavan',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Hemavan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 65.806111,
    longitude: 15.082778,
    altitude: 458.1144
  },
  {
    iataCode: 'YOG',
    icaoCode: 'CNT3',
    name: 'Ogoki Post',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ogoki Post Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 51.6586,
    longitude: -85.9017,
    altitude: 181.0512
  },
  {
    iataCode: 'YOH',
    icaoCode: 'CYOH',
    name: 'Oxford House',
    country: {code: 'CA', name: 'Canada'},
    city: 'Oxford House Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 54.9333,
    longitude: -95.2789,
    altitude: 202.0824
  },
  {
    iataCode: 'YOJ',
    icaoCode: 'CYOJ',
    name: 'High Level',
    country: {code: 'CA', name: 'Canada'},
    city: 'High Level',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 58.621389,
    longitude: -117.164722,
    altitude: 338.328
  },
  {
    iataCode: 'YOL',
    icaoCode: 'DNYO',
    name: 'Yola',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Yola',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 9.257553,
    longitude: 12.430422,
    altitude: 182.5752
  },
  {
    iataCode: 'HNA',
    icaoCode: 'RJSI',
    name: 'Hanamaki',
    country: {code: 'JP', name: 'Japan'},
    city: 'Hanamaki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 39.428611,
    longitude: 141.135278,
    altitude: 90.5256
  },
  {
    iataCode: 'YOP',
    icaoCode: 'CYOP',
    name: 'Rainbow Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rainbow Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 58.491389,
    longitude: -119.407778,
    altitude: 535.2288
  },
  {
    iataCode: 'HND',
    icaoCode: 'RJTT',
    name: 'Tokyo',
    country: {code: 'JP', name: 'Japan'},
    city: 'Tokyo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.552258,
    longitude: 139.779694,
    altitude: 10.668
  },
  {
    iataCode: 'YOW',
    icaoCode: 'CYOW',
    name: 'Ottawa',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ottawa Macdonald Cartier Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 45.3225,
    longitude: -75.669167,
    altitude: 113.9952
  },
  {
    iataCode: 'HNH',
    icaoCode: 'PAOH',
    name: 'Hoonah',
    country: {code: 'US', name: 'United States'},
    city: 'Hoonah Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 58.096111,
    longitude: -135.409722,
    altitude: 5.7912
  },
  {
    iataCode: 'HNM',
    icaoCode: 'PHHN',
    name: 'Hana',
    country: {code: 'US', name: 'United States'},
    city: 'Hana',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.795636,
    longitude: -156.014439,
    altitude: 23.7744
  },
  {
    iataCode: 'HNL',
    icaoCode: 'PHNL',
    name: 'Honolulu',
    country: {code: 'US', name: 'United States'},
    city: 'Honolulu Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 21.318681,
    longitude: -157.922428,
    altitude: 3.9624
  },
  {
    iataCode: 'YPA',
    icaoCode: 'CYPA',
    name: 'Prince Albert',
    country: {code: 'CA', name: 'Canada'},
    city: 'Prince Albert Glass Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 53.214167,
    longitude: -105.672778,
    altitude: 428.244
  },
  {
    iataCode: 'HNS',
    icaoCode: 'PAHN',
    name: 'Haines',
    country: {code: 'US', name: 'United States'},
    city: 'Haines Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 59.2438,
    longitude: -135.524,
    altitude: 4.572
  },
  {
    iataCode: 'YPC',
    icaoCode: 'CYPC',
    name: 'Paulatuk',
    country: {code: 'CA', name: 'Canada'},
    city: 'Paulatuk',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 62.35,
    longitude: -124.3333,
    altitude: 15.24
  },
  {
    iataCode: 'YPE',
    icaoCode: 'CYPE',
    name: 'Peace River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Peace River',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 56.226944,
    longitude: -117.447222,
    altitude: 570.8904
  },
  {
    iataCode: 'YPH',
    icaoCode: 'CYPH',
    name: 'Inukjuak',
    country: {code: 'CA', name: 'Canada'},
    city: 'Inukjuak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 58.4719,
    longitude: -78.0769,
    altitude: 25.2984
  },
  {
    iataCode: 'YPG',
    icaoCode: 'CYPG',
    name: 'Portage-la-prairie',
    country: {code: 'CA', name: 'Canada'},
    city: 'Southport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 49.903056,
    longitude: -98.273889,
    altitude: 269.748
  },
  {
    iataCode: 'YPJ',
    icaoCode: 'CYLA',
    name: 'Aupaluk',
    country: {code: 'CA', name: 'Canada'},
    city: 'Aupaluk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 59.2967,
    longitude: -69.5997,
    altitude: 36.2712
  },
  {
    iataCode: 'YPI',
    icaoCode: 'CYPI',
    name: 'Port Simpson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.566667,
    longitude: -130.433333,
    altitude: 0.0
  },
  {
    iataCode: 'YPL',
    icaoCode: 'CYPL',
    name: 'Pickle Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Pickle Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -360,
    latitude: 51.446388,
    longitude: -90.214167,
    altitude: 386.1816
  },
  {
    iataCode: 'YPN',
    icaoCode: 'CYPN',
    name: 'Port Menier',
    country: {code: 'CA', name: 'Canada'},
    city: 'Port Menier',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 49.836389,
    longitude: -64.288611,
    altitude: 50.9016
  },
  {
    iataCode: 'YPM',
    icaoCode: 'CYPM',
    name: 'Pikangikum',
    country: {code: 'CA', name: 'Canada'},
    city: 'Pikangikum Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 51.8197,
    longitude: -93.9733,
    altitude: 339.5472
  },
  {
    iataCode: 'YPO',
    icaoCode: 'CYPO',
    name: 'Peawanuck',
    country: {code: 'CA', name: 'Canada'},
    city: 'Peawanuck Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 54.9881,
    longitude: -85.4433,
    altitude: 52.7304
  },
  {
    iataCode: 'HOB',
    icaoCode: 'KHOB',
    name: 'Hobbs',
    country: {code: 'US', name: 'United States'},
    city: 'Lea Co Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.687528,
    longitude: -103.217028,
    altitude: 1115.8728
  },
  {
    iataCode: 'YPR',
    icaoCode: 'CYPR',
    name: 'Prince Pupert',
    country: {code: 'CA', name: 'Canada'},
    city: 'Prince Rupert',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.28611,
    longitude: -130.444722,
    altitude: 35.3568
  },
  {
    iataCode: 'YPQ',
    icaoCode: 'CYPQ',
    name: 'Peterborough',
    country: {code: 'CA', name: 'Canada'},
    city: 'Peterborough',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 44.23,
    longitude: -78.363333,
    altitude: 191.4144
  },
  {
    iataCode: 'HOD',
    icaoCode: 'OYHD',
    name: 'Hodeidah',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Hodeidah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 14.753,
    longitude: 42.976336,
    altitude: 12.4968
  },
  {
    iataCode: 'HOF',
    icaoCode: 'OEAH',
    name: 'Al-ahsa',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Al Ahsa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 25.285306,
    longitude: 49.485189,
    altitude: 179.2224
  },
  {
    iataCode: 'YPX',
    icaoCode: 'CYPX',
    name: 'Puvirnituq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Puvirnituq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 60.0506,
    longitude: -77.2869,
    altitude: 22.5552
  },
  {
    iataCode: 'HOG',
    icaoCode: 'MUHG',
    name: 'Holguin',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Frank Pais Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 20.785589,
    longitude: -76.315108,
    altitude: 110.0328
  },
  {
    iataCode: 'YPW',
    icaoCode: 'CYPW',
    name: 'Powell River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Powell River Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.8342,
    longitude: -124.5,
    altitude: 129.54
  },
  {
    iataCode: 'HOJ',
    icaoCode: 'LOIH',
    name: 'Hohenems',
    country: {code: 'AT', name: 'Austria'},
    city: 'Hohenems',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 47.385,
    longitude: 9.7,
    altitude: 125.5776
  },
  {
    iataCode: 'HOI',
    icaoCode: 'NTTO',
    name: 'Hao Island',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Hao',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -18.074814,
    longitude: -140.945886,
    altitude: 3.048
  },
  {
    iataCode: 'YPY',
    icaoCode: 'CYPY',
    name: 'Fort Chipewyan',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Chipewyan',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 58.767223,
    longitude: -111.117222,
    altitude: 231.9528
  },
  {
    iataCode: 'HOK',
    icaoCode: 'YHOO',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hooker Creek Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -18.3367,
    longitude: 130.638,
    altitude: 97.536
  },
  {
    iataCode: 'HON',
    icaoCode: 'KHON',
    name: 'Huron',
    country: {code: 'US', name: 'United States'},
    city: 'Huron Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.3852,
    longitude: -98.228542,
    altitude: 392.8872
  },
  {
    iataCode: 'HOM',
    icaoCode: 'PAHO',
    name: 'Homer',
    country: {code: 'US', name: 'United States'},
    city: 'Homer',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.645556,
    longitude: -151.476583,
    altitude: 25.6032
  },
  {
    iataCode: 'HOP',
    icaoCode: 'KHOP',
    name: 'Hopkinsville',
    country: {code: 'US', name: 'United States'},
    city: 'Campbell Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.668567,
    longitude: -87.496183,
    altitude: 174.6504
  },
  {
    iataCode: 'YQA',
    icaoCode: 'CYQA',
    name: 'Muskoka',
    country: {code: 'CA', name: 'Canada'},
    city: 'Muskoka',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 44.974722,
    longitude: -79.303333,
    altitude: 281.94
  },
  {
    iataCode: 'HOR',
    icaoCode: 'LPHR',
    name: 'Horta',
    country: {code: 'PT', name: 'Portugal'},
    city: 'Horta',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Azores',
    utcOffset: -60,
    latitude: 38.519894,
    longitude: -28.715872,
    altitude: 35.9664
  },
  {
    iataCode: 'YQC',
    icaoCode: 'CYHA',
    name: 'Quaqtaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Quaqtaq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 61.0464,
    longitude: -69.6178,
    altitude: 31.3944
  },
  {
    iataCode: 'HOQ',
    icaoCode: 'EDQM',
    name: 'Hof',
    country: {code: 'DE', name: 'Germany'},
    city: 'Hof Plauen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.288836,
    longitude: 11.854919,
    altitude: 597.408
  },
  {
    iataCode: 'YQB',
    icaoCode: 'CYQB',
    name: 'Quebec',
    country: {code: 'CA', name: 'Canada'},
    city: 'Quebec Jean Lesage Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 46.791111,
    longitude: -71.393333,
    altitude: 74.3712
  },
  {
    iataCode: 'HOT',
    icaoCode: 'KHOT',
    name: 'Hot Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Memorial Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.478,
    longitude: -93.0962,
    altitude: 164.592
  },
  {
    iataCode: 'YQD',
    icaoCode: 'CYQD',
    name: 'The Pas',
    country: {code: 'CA', name: 'Canada'},
    city: 'The Pas Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.9714,
    longitude: -101.091,
    altitude: 270.3576
  },
  {
    iataCode: 'HOV',
    icaoCode: 'ENOV',
    name: 'Orsta-volda',
    country: {code: 'NO', name: 'Norway'},
    city: 'Hovden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 62.18,
    longitude: 6.0742,
    altitude: 73.7616
  },
  {
    iataCode: 'YQG',
    icaoCode: 'CYQG',
    name: 'Windsor',
    country: {code: 'CA', name: 'Canada'},
    city: 'Windsor',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 42.275556,
    longitude: -82.955556,
    altitude: 189.5856
  },
  {
    iataCode: 'HOU',
    icaoCode: 'KHOU',
    name: 'Houston',
    country: {code: 'US', name: 'United States'},
    city: 'William P Hobby',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.645419,
    longitude: -95.278889,
    altitude: 14.0208
  },
  {
    iataCode: 'YQF',
    icaoCode: 'CYQF',
    name: 'Red Deer Industrial',
    country: {code: 'CA', name: 'Canada'},
    city: 'Red Deer Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 52.182222,
    longitude: -113.894444,
    altitude: 904.6464
  },
  {
    iataCode: 'YQI',
    icaoCode: 'CYQI',
    name: 'Yarmouth',
    country: {code: 'CA', name: 'Canada'},
    city: 'Yarmouth Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -300,
    latitude: 43.8269,
    longitude: -66.0881,
    altitude: 42.9768
  },
  {
    iataCode: 'HOW',
    icaoCode: 'MPHO',
    name: 'Howard',
    country: {code: 'PA', name: 'Panama'},
    city: 'Howard',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 8.914794,
    longitude: -79.599633,
    altitude: 15.8496
  },
  {
    iataCode: 'YQH',
    icaoCode: 'CYQH',
    name: 'Watson Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Watson Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 60.116389,
    longitude: -128.8225,
    altitude: 687.324
  },
  {
    iataCode: 'YQK',
    icaoCode: 'CYQK',
    name: 'Kenora',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kenora',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 49.788334,
    longitude: -94.363056,
    altitude: 405.9936
  },
  {
    iataCode: 'YQM',
    icaoCode: 'CYQM',
    name: 'Moncton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Greater Moncton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 46.112221,
    longitude: -64.678611,
    altitude: 70.7136
  },
  {
    iataCode: 'YQL',
    icaoCode: 'CYQL',
    name: 'Lethbridge',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lethbridge',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 49.630278,
    longitude: -112.799722,
    altitude: 928.7256
  },
  {
    iataCode: 'YQN',
    icaoCode: 'CYQN',
    name: 'Nakina',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nakina Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 50.182777,
    longitude: -86.696388,
    altitude: 322.1736
  },
  {
    iataCode: 'HPA',
    icaoCode: 'NFTL',
    name: 'Lifuka',
    country: {code: 'TO', name: 'Tonga'},
    city: 'Lifuka Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tongatapu',
    utcOffset: 780,
    latitude: -19.777,
    longitude: -174.341,
    altitude: 9.4488
  },
  {
    iataCode: 'YQQ',
    icaoCode: 'CYQQ',
    name: 'Comox',
    country: {code: 'CA', name: 'Canada'},
    city: 'Comox',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.710833,
    longitude: -124.886667,
    altitude: 25.6032
  },
  {
    iataCode: 'HPB',
    icaoCode: 'PAHP',
    name: 'Hooper Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Hooper Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 61.5239,
    longitude: -166.147,
    altitude: 2.1336
  },
  {
    iataCode: 'YQR',
    icaoCode: 'CYQR',
    name: 'Regina',
    country: {code: 'CA', name: 'Canada'},
    city: 'Regina Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 50.431944,
    longitude: -104.665833,
    altitude: 577.2912
  },
  {
    iataCode: 'YQU',
    icaoCode: 'CYQU',
    name: 'Grande Prairie',
    country: {code: 'CA', name: 'Canada'},
    city: 'Grande Prairie',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 55.179722,
    longitude: -118.885,
    altitude: 669.036
  },
  {
    iataCode: 'YQT',
    icaoCode: 'CYQT',
    name: 'Thunder Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Thunder Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Thunder_Bay',
    utcOffset: -300,
    latitude: 48.371944,
    longitude: -89.323889,
    altitude: 199.0344
  },
  {
    iataCode: 'YQW',
    icaoCode: 'CYQW',
    name: 'North Battleford',
    country: {code: 'CA', name: 'Canada'},
    city: 'North Battleford',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 52.769167,
    longitude: -108.24361,
    altitude: 548.3352
  },
  {
    iataCode: 'YQV',
    icaoCode: 'CYQV',
    name: 'Yorkton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Yorkton Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 51.264721,
    longitude: -102.461667,
    altitude: 498.348
  },
  {
    iataCode: 'YQY',
    icaoCode: 'CYQY',
    name: 'Sydney',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sydney',
    region: '',
    terminal: '',
    timezoneId: 'America/Glace_Bay',
    utcOffset: -240,
    latitude: 46.161388,
    longitude: -60.047779,
    altitude: 61.8744
  },
  {
    iataCode: 'HPH',
    icaoCode: 'VVCI',
    name: 'Haiphong',
    country: {code: 'VN', name: 'Vietnam'},
    city: 'Cat Bi International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ho_Chi_Minh',
    utcOffset: 420,
    latitude: 20.819386,
    longitude: 106.724989,
    altitude: 1.8288
  },
  {
    iataCode: 'YQX',
    icaoCode: 'CYQX',
    name: 'Gander',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gander Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 48.936944,
    longitude: -54.568056,
    altitude: 151.1808
  },
  {
    iataCode: 'QAS',
    icaoCode: 'DAOI',
    name: 'Ech-cheliff',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Ech Cheliff',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.212658,
    longitude: 1.331775,
    altitude: 141.1224
  },
  {
    iataCode: 'YQZ',
    icaoCode: 'CYQZ',
    name: 'Quesnel',
    country: {code: 'CA', name: 'Canada'},
    city: 'Quesnel',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 53.026112,
    longitude: -122.510278,
    altitude: 545.2872
  },
  {
    iataCode: 'HPN',
    icaoCode: 'KHPN',
    name: 'White Plains',
    country: {code: 'US', name: 'United States'},
    city: 'Westchester Co',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.066959,
    longitude: -73.707575,
    altitude: 133.8072
  },
  {
    iataCode: 'YRB',
    icaoCode: 'CYRB',
    name: 'Resolute',
    country: {code: 'CA', name: 'Canada'},
    city: 'Resolute Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Resolute',
    utcOffset: -360,
    latitude: 74.716944,
    longitude: -94.969444,
    altitude: 65.532
  },
  {
    iataCode: 'YRA',
    icaoCode: 'CYRA',
    name: 'Gamètì',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rae Lakes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 64.1161,
    longitude: -117.31,
    altitude: 220.3704
  },
  {
    iataCode: 'YRF',
    icaoCode: 'CYCA',
    name: 'Cartwright',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cartwright Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 53.6828,
    longitude: -57.0419,
    altitude: 12.192
  },
  {
    iataCode: 'YRG',
    icaoCode: 'CCZ2',
    name: 'Rigolet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rigolet Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 54.1797,
    longitude: -58.4575,
    altitude: 54.864
  },
  {
    iataCode: 'YRJ',
    icaoCode: 'CYRJ',
    name: 'Roberval',
    country: {code: 'CA', name: 'Canada'},
    city: 'Roberval',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.52,
    longitude: -72.265556,
    altitude: 178.6128
  },
  {
    iataCode: 'YRI',
    icaoCode: 'CYRI',
    name: 'Riviere Du Loup',
    country: {code: 'CA', name: 'Canada'},
    city: 'Riviere Du Loup',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 47.764444,
    longitude: -69.584722,
    altitude: 130.1496
  },
  {
    iataCode: 'YRL',
    icaoCode: 'CYRL',
    name: 'Red Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Red Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 51.0669,
    longitude: -93.7931,
    altitude: 385.572
  },
  {
    iataCode: 'QBC',
    icaoCode: 'CYBD',
    name: 'Bella Coola',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bella Coola Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 52.3875,
    longitude: -126.596,
    altitude: 35.6616
  },
  {
    iataCode: 'YRM',
    icaoCode: 'CYRM',
    name: 'Rocky Mountain House',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rocky Mountain House',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 52.429722,
    longitude: -114.904167,
    altitude: 988.7712
  },
  {
    iataCode: 'YRT',
    icaoCode: 'CYRT',
    name: 'Rankin Inlet',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rankin Inlet',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 62.81139,
    longitude: -92.115833,
    altitude: 28.6512
  },
  {
    iataCode: 'YRS',
    icaoCode: 'CYRS',
    name: 'Red Sucker Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Red Sucker Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 54.167222,
    longitude: -93.557222,
    altitude: 222.1992
  },
  {
    iataCode: 'YSC',
    icaoCode: 'CYSC',
    name: 'Sherbrooke',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sherbrooke',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.438611,
    longitude: -71.691389,
    altitude: 241.4016
  },
  {
    iataCode: 'YSB',
    icaoCode: 'CYSB',
    name: 'Sudbury',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sudbury',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 46.625,
    longitude: -80.798889,
    altitude: 347.7768
  },
  {
    iataCode: 'YSG',
    icaoCode: 'CYLK',
    name: "Lutselk'e",
    country: {code: 'CA', name: 'Canada'},
    city: "Lutselk'e Airport",
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 62.4183,
    longitude: -110.682,
    altitude: 181.6608
  },
  {
    iataCode: 'YSF',
    icaoCode: 'CYSF',
    name: 'Stony Rapids',
    country: {code: 'CA', name: 'Canada'},
    city: 'Stony Rapids Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 59.2503,
    longitude: -105.841,
    altitude: 245.364
  },
  {
    iataCode: 'YSK',
    icaoCode: 'CYSK',
    name: 'Sanikiluaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sanikiluaq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 56.5378,
    longitude: -79.2467,
    altitude: 31.6992
  },
  {
    iataCode: 'YSJ',
    icaoCode: 'CYSJ',
    name: 'St. John',
    country: {code: 'CA', name: 'Canada'},
    city: 'Saint John',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 45.316111,
    longitude: -65.890278,
    altitude: 108.8136
  },
  {
    iataCode: 'YSM',
    icaoCode: 'CYSM',
    name: 'Fort Smith',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Smith',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 60.020278,
    longitude: -111.961944,
    altitude: 204.5208
  },
  {
    iataCode: 'YSO',
    icaoCode: 'CCD4',
    name: 'Postville',
    country: {code: 'CA', name: 'Canada'},
    city: 'Postville Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 54.910278,
    longitude: -59.785278,
    altitude: 67.9704
  },
  {
    iataCode: 'HRB',
    icaoCode: 'ZYHB',
    name: 'Harbin',
    country: {code: 'CN', name: 'China'},
    city: 'Taiping',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 45.623403,
    longitude: 126.250328,
    altitude: 139.2936
  },
  {
    iataCode: 'YSR',
    icaoCode: 'CYSR',
    name: 'Nanisivik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nanisivik',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 72.982222,
    longitude: -84.613611,
    altitude: 641.9088
  },
  {
    iataCode: 'HRE',
    icaoCode: 'FVHA',
    name: 'Harare',
    country: {code: 'ZW', name: 'Zimbabwe'},
    city: 'Harare Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Harare',
    utcOffset: 120,
    latitude: -17.931806,
    longitude: 31.092847,
    altitude: 1489.5576
  },
  {
    iataCode: 'YSU',
    icaoCode: 'CYSU',
    name: 'Summerside',
    country: {code: 'CA', name: 'Canada'},
    city: 'Summerside',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 46.440556,
    longitude: -63.833611,
    altitude: 17.0688
  },
  {
    iataCode: 'YST',
    icaoCode: 'CYST',
    name: 'St. Theresa Point',
    country: {code: 'CA', name: 'Canada'},
    city: 'St. Theresa Point Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.8456,
    longitude: -94.8519,
    altitude: 235.6104
  },
  {
    iataCode: 'HRG',
    icaoCode: 'HEGN',
    name: 'Hurghada',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Hurghada Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 27.178317,
    longitude: 33.799436,
    altitude: 15.8496
  },
  {
    iataCode: 'YSY',
    icaoCode: 'CYSY',
    name: 'Sachs Harbour',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sachs Harbour',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 71.993889,
    longitude: -125.2425,
    altitude: 85.9536
  },
  {
    iataCode: 'HRK',
    icaoCode: 'UKHH',
    name: 'Kharkov',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Osnova International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zaporozhye',
    utcOffset: 120,
    latitude: 49.924786,
    longitude: 36.289986,
    altitude: 154.8384
  },
  {
    iataCode: 'HRJ',
    icaoCode: 'VNCJ',
    name: 'Chaurjhari',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Chaurjhari',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.0,
    longitude: 83.833,
    altitude: 1219.2
  },
  {
    iataCode: 'HRM',
    icaoCode: 'DAFH',
    name: 'Tilrempt',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Hassi R Mel',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 32.930431,
    longitude: 3.311542,
    altitude: 774.192
  },
  {
    iataCode: 'HRL',
    icaoCode: 'KHRL',
    name: 'Harlingen',
    country: {code: 'US', name: 'United States'},
    city: 'Valley Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 26.2285,
    longitude: -97.654389,
    altitude: 10.9728
  },
  {
    iataCode: 'HRO',
    icaoCode: 'KHRO',
    name: 'Harrison',
    country: {code: 'US', name: 'United States'},
    city: 'Boone Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 36.261519,
    longitude: -93.154728,
    altitude: 416.052
  },
  {
    iataCode: 'QCY',
    icaoCode: 'EGXC',
    name: 'Coningsby',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Coningsby',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.093014,
    longitude: -0.166014,
    altitude: 7.62
  },
  {
    iataCode: 'YTF',
    icaoCode: 'CYTF',
    name: 'Alma',
    country: {code: 'CA', name: 'Canada'},
    city: 'Alma Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -360,
    latitude: 48.508611,
    longitude: -71.641389,
    altitude: 136.8552
  },
  {
    iataCode: 'HRT',
    icaoCode: 'KHRT',
    name: 'Mary Esther',
    country: {code: 'US', name: 'United States'},
    city: 'Hurlburt Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.427803,
    longitude: -86.689278,
    altitude: 11.5824
  },
  {
    iataCode: 'YTE',
    icaoCode: 'CYTE',
    name: 'Cape Dorset',
    country: {code: 'CA', name: 'Canada'},
    city: 'Cape Dorset',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 64.23,
    longitude: -76.526667,
    altitude: 49.9872
  },
  {
    iataCode: 'YTH',
    icaoCode: 'CYTH',
    name: 'Thompson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Thompson',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 55.801111,
    longitude: -97.864166,
    altitude: 222.1992
  },
  {
    iataCode: 'YTL',
    icaoCode: 'CYTL',
    name: 'Big Trout Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Big Trout Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -300,
    latitude: 53.8178,
    longitude: -89.8969,
    altitude: 222.1992
  },
  {
    iataCode: 'YTM',
    icaoCode: 'CYFJ',
    name: 'Mont-tremblant',
    country: {code: 'CA', name: 'Canada'},
    city: 'Riviere Rouge - Mont-tremblant International Inc. Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 46.409444,
    longitude: -74.78,
    altitude: 252.0696
  },
  {
    iataCode: 'QDJ',
    icaoCode: 'DAFI',
    name: 'Djelfa',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Tsletsi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 34.6657,
    longitude: 3.351,
    altitude: 3.048
  },
  {
    iataCode: 'YTR',
    icaoCode: 'CYTR',
    name: 'Trenton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Trenton',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 44.118889,
    longitude: -77.528056,
    altitude: 86.2584
  },
  {
    iataCode: 'YTQ',
    icaoCode: 'CYTQ',
    name: 'Tasiujaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tasiujaq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 58.6678,
    longitude: -69.9558,
    altitude: 37.1856
  },
  {
    iataCode: 'YTS',
    icaoCode: 'CYTS',
    name: 'Timmins',
    country: {code: 'CA', name: 'Canada'},
    city: 'Timmins',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 48.569721,
    longitude: -81.376667,
    altitude: 294.7416
  },
  {
    iataCode: 'HSG',
    icaoCode: 'RJFS',
    name: 'Saga',
    country: {code: 'JP', name: 'Japan'},
    city: 'Saga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.1497,
    longitude: 130.302,
    altitude: 1.8288
  },
  {
    iataCode: 'YTZ',
    icaoCode: 'CYTZ',
    name: 'Toronto',
    country: {code: 'CA', name: 'Canada'},
    city: 'City Centre',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.627499,
    longitude: -79.396167,
    altitude: 76.5048
  },
  {
    iataCode: 'HSL',
    icaoCode: 'PAHL',
    name: 'Huslia',
    country: {code: 'US', name: 'United States'},
    city: 'Huslia Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 65.697778,
    longitude: -156.351389,
    altitude: 64.9224
  },
  {
    iataCode: 'HSK',
    icaoCode: 'LEHC',
    name: 'Huesca',
    country: {code: 'ES', name: 'Spain'},
    city: 'Huesca-pirineos Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 0,
    latitude: 42.080833,
    longitude: -0.323333,
    altitude: 538.8864
  },
  {
    iataCode: 'HSN',
    icaoCode: 'ZSZS',
    name: 'Zhoushan',
    country: {code: 'CN', name: 'China'},
    city: 'Zhoushan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.9342,
    longitude: 122.362,
    altitude: 0.0
  },
  {
    iataCode: 'YUB',
    icaoCode: 'CYUB',
    name: 'Tuktoyaktuk',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tuktoyaktuk',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 69.433334,
    longitude: -133.026389,
    altitude: 4.572
  },
  {
    iataCode: 'HST',
    icaoCode: 'KHST',
    name: 'Homestead',
    country: {code: 'US', name: 'United States'},
    city: 'Homestead Arb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 25.48855,
    longitude: -80.383567,
    altitude: 1.8288
  },
  {
    iataCode: 'YUD',
    icaoCode: 'CYMU',
    name: 'Umiujaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Umiujaq Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 56.5361,
    longitude: -76.5183,
    altitude: 76.2
  },
  {
    iataCode: 'YUM',
    icaoCode: 'KYUM',
    name: 'Yuma',
    country: {code: 'US', name: 'United States'},
    city: 'Yuma Mcas Yuma Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 32.656578,
    longitude: -114.60598,
    altitude: 65.8368
  },
  {
    iataCode: 'YUL',
    icaoCode: 'CYUL',
    name: 'Montreal',
    country: {code: 'CA', name: 'Canada'},
    city: 'Pierre Elliott Trudeau Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 45.470556,
    longitude: -73.740833,
    altitude: 35.9664
  },
  {
    iataCode: 'HTA',
    icaoCode: 'UIAA',
    name: 'Chita',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Kadala',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 52.026317,
    longitude: 113.305556,
    altitude: 692.5056
  },
  {
    iataCode: 'YUT',
    icaoCode: 'CYUT',
    name: 'Repulse Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Repulse Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 66.521389,
    longitude: -86.224722,
    altitude: 24.384
  },
  {
    iataCode: 'HTG',
    icaoCode: 'UOHH',
    name: 'Khatanga',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Khatanga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 71.978058,
    longitude: 102.490514,
    altitude: 28.956
  },
  {
    iataCode: 'HTI',
    icaoCode: 'YBHM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hamilton Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Lindeman',
    utcOffset: 600,
    latitude: -20.3581,
    longitude: 148.952,
    altitude: 4.572
  },
  {
    iataCode: 'YUY',
    icaoCode: 'CYUY',
    name: 'Rouyn',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rouyn Noranda',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.206111,
    longitude: -78.835556,
    altitude: 301.1424
  },
  {
    iataCode: 'YUX',
    icaoCode: 'CYUX',
    name: 'Hall Beach',
    country: {code: 'CA', name: 'Canada'},
    city: 'Hall Beach',
    region: '',
    terminal: '',
    timezoneId: 'America/Iqaluit',
    utcOffset: -300,
    latitude: 68.776111,
    longitude: -81.243611,
    altitude: 8.2296
  },
  {
    iataCode: 'HTL',
    icaoCode: 'KHTL',
    name: 'Houghton Lake',
    country: {code: 'US', name: 'United States'},
    city: 'Roscommon Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 44.359806,
    longitude: -84.671112,
    altitude: 350.52
  },
  {
    iataCode: 'HTN',
    icaoCode: 'ZWTN',
    name: 'Hotan',
    country: {code: 'CN', name: 'China'},
    city: 'Hotan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kashgar',
    utcOffset: 480,
    latitude: 37.038522,
    longitude: 79.864933,
    altitude: 1424.0256
  },
  {
    iataCode: 'YVB',
    icaoCode: 'CYVB',
    name: 'Bonaventure',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bonaventure Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -240,
    latitude: 48.0711,
    longitude: -65.4603,
    altitude: 37.4904
  },
  {
    iataCode: 'YVA',
    icaoCode: 'FMCN',
    name: 'Moroni',
    country: {code: 'KM', name: 'Comoros'},
    city: 'Iconi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Comoro',
    utcOffset: 180,
    latitude: -11.7108,
    longitude: 43.2439,
    altitude: 10.0584
  },
  {
    iataCode: 'HTS',
    icaoCode: 'KHTS',
    name: 'Huntington',
    country: {code: 'US', name: 'United States'},
    city: 'Tri State Milton J Ferguson Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.3667,
    longitude: -82.558,
    altitude: 252.3744
  },
  {
    iataCode: 'YVC',
    icaoCode: 'CYVC',
    name: 'La Ronge',
    country: {code: 'CA', name: 'Canada'},
    city: 'La Ronge',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 55.15139,
    longitude: -105.261944,
    altitude: 378.5616
  },
  {
    iataCode: 'YVG',
    icaoCode: 'CYVG',
    name: 'Vermillion',
    country: {code: 'CA', name: 'Canada'},
    city: 'Vermilion',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 53.355833,
    longitude: -110.82389,
    altitude: 617.22
  },
  {
    iataCode: 'HTY',
    icaoCode: 'LTDA',
    name: 'Hatay',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Hatay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 36.362778,
    longitude: 36.282222,
    altitude: 7.62
  },
  {
    iataCode: 'YVM',
    icaoCode: 'CYVM',
    name: 'Broughton Island',
    country: {code: 'CA', name: 'Canada'},
    city: 'Qikiqtarjuaq',
    region: '',
    terminal: '',
    timezoneId: 'America/Pangnirtung',
    utcOffset: -300,
    latitude: 67.545833,
    longitude: -64.031389,
    altitude: 6.4008
  },
  {
    iataCode: 'YVP',
    icaoCode: 'CYVP',
    name: 'Quujjuaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kuujjuaq',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 58.096111,
    longitude: -68.426944,
    altitude: 39.3192
  },
  {
    iataCode: 'YVO',
    icaoCode: 'CYVO',
    name: "Val D'or",
    country: {code: 'CA', name: 'Canada'},
    city: 'Val D Or',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.053333,
    longitude: -77.782778,
    altitude: 337.4136
  },
  {
    iataCode: 'YVR',
    icaoCode: 'CYVR',
    name: 'Vancouver',
    country: {code: 'CA', name: 'Canada'},
    city: 'Vancouver Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.193889,
    longitude: -123.184444,
    altitude: 4.2672
  },
  {
    iataCode: 'HUA',
    icaoCode: 'KHUA',
    name: 'Redstone',
    country: {code: 'US', name: 'United States'},
    city: 'Redstone Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.678653,
    longitude: -86.684781,
    altitude: 208.788
  },
  {
    iataCode: 'YVQ',
    icaoCode: 'CYVQ',
    name: 'Norman Wells',
    country: {code: 'CA', name: 'Canada'},
    city: 'Norman Wells',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 65.281617,
    longitude: -126.798219,
    altitude: 72.5424
  },
  {
    iataCode: 'YVT',
    icaoCode: 'CYVT',
    name: 'Buffalo Narrows',
    country: {code: 'CA', name: 'Canada'},
    city: 'Buffalo Narrows',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 55.841944,
    longitude: -108.4175,
    altitude: 440.1312
  },
  {
    iataCode: 'Z84',
    icaoCode: 'PACL',
    name: 'Clear Mews',
    country: {code: 'US', name: 'United States'},
    city: 'Clear',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.301203,
    longitude: -149.120144,
    altitude: 168.2496
  },
  {
    iataCode: 'HUF',
    icaoCode: 'KHUF',
    name: 'Terre Haute',
    country: {code: 'US', name: 'United States'},
    city: 'Terre Haute Intl Hulman Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 39.451464,
    longitude: -87.307561,
    altitude: 179.5272
  },
  {
    iataCode: 'YVV',
    icaoCode: 'CYVV',
    name: 'Wiarton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wiarton',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 44.745834,
    longitude: -81.107222,
    altitude: 222.1992
  },
  {
    iataCode: 'HUH',
    icaoCode: 'NTTH',
    name: 'Huahine Island',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Huahine',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.687242,
    longitude: -151.021667,
    altitude: 2.1336
  },
  {
    iataCode: 'YVZ',
    icaoCode: 'CYVZ',
    name: 'Deer Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Deer Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.6558,
    longitude: -94.0614,
    altitude: 332.8416
  },
  {
    iataCode: 'HUL',
    icaoCode: 'KHUL',
    name: 'Houlton',
    country: {code: 'US', name: 'United States'},
    city: 'Houlton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -240,
    latitude: 46.123083,
    longitude: -67.792056,
    altitude: 149.0472
  },
  {
    iataCode: 'HUN',
    icaoCode: 'RCYU',
    name: 'Hualien',
    country: {code: 'TW', name: 'Province of China Taiwan'},
    city: 'Hualien',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Taipei',
    utcOffset: 480,
    latitude: 24.023725,
    longitude: 121.616906,
    altitude: 15.8496
  },
  {
    iataCode: 'YWA',
    icaoCode: 'CYWA',
    name: 'Petawawa',
    country: {code: 'CA', name: 'Canada'},
    city: 'Petawawa',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 45.952221,
    longitude: -77.319168,
    altitude: 130.1496
  },
  {
    iataCode: 'YWB',
    icaoCode: 'CYKG',
    name: 'Kangiqsujuaq',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kangiqsujuaq - Wakeham Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -360,
    latitude: 61.588611,
    longitude: -71.929444,
    altitude: 152.7048
  },
  {
    iataCode: 'HUT',
    icaoCode: 'KHUT',
    name: 'Hutchinson',
    country: {code: 'US', name: 'United States'},
    city: 'Hutchinson Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.0655,
    longitude: -97.8606,
    altitude: 470.3064
  },
  {
    iataCode: 'HUS',
    icaoCode: 'PAHU',
    name: 'Hughes',
    country: {code: 'US', name: 'United States'},
    city: 'Hughes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.039167,
    longitude: -154.264722,
    altitude: 91.1352
  },
  {
    iataCode: 'HUV',
    icaoCode: 'ESNH',
    name: 'Hudiksvall',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Hudiksvall',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 61.768092,
    longitude: 17.080719,
    altitude: 28.956
  },
  {
    iataCode: 'YWG',
    icaoCode: 'CYWG',
    name: 'Winnipeg',
    country: {code: 'CA', name: 'Canada'},
    city: 'Winnipeg Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 49.910036,
    longitude: -97.239886,
    altitude: 238.6584
  },
  {
    iataCode: 'HUU',
    icaoCode: 'SPNC',
    name: 'Huánuco',
    country: {code: 'PE', name: 'Peru'},
    city: 'Alferez Fap David Figueroa Fernandini Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -9.878811,
    longitude: -76.204797,
    altitude: 1850.136
  },
  {
    iataCode: 'HUX',
    icaoCode: 'MMBT',
    name: 'Bahias Dehuatulco',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Bahias De Huatulco Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 15.775317,
    longitude: -96.262572,
    altitude: 141.4272
  },
  {
    iataCode: 'YWH',
    icaoCode: 'CYWH',
    name: 'Victoria',
    country: {code: 'CA', name: 'Canada'},
    city: 'Victoria Inner Harbour Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 48.422778,
    longitude: -123.3875,
    altitude: 0.0
  },
  {
    iataCode: 'YWK',
    icaoCode: 'CYWK',
    name: 'Wabush',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wabush',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 52.921944,
    longitude: -66.864444,
    altitude: 551.0784
  },
  {
    iataCode: 'HUY',
    icaoCode: 'EGNJ',
    name: 'Humberside',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Humberside',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 53.574444,
    longitude: -0.350833,
    altitude: 36.8808
  },
  {
    iataCode: 'YWJ',
    icaoCode: 'CYWJ',
    name: 'Deline',
    country: {code: 'CA', name: 'Canada'},
    city: 'Deline',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 65.183333,
    longitude: -125.416667,
    altitude: 167.64
  },
  {
    iataCode: 'YWM',
    icaoCode: 'CCA6',
    name: 'Williams Harbour',
    country: {code: 'CA', name: 'Canada'},
    city: 'Williams Harbour Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 52.5669,
    longitude: -55.7847,
    altitude: 21.336
  },
  {
    iataCode: 'YWL',
    icaoCode: 'CYWL',
    name: 'Williams Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Williams Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 52.183056,
    longitude: -122.054167,
    altitude: 940.308
  },
  {
    iataCode: 'HVA',
    icaoCode: 'FMNL',
    name: 'Analalava',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Analalava',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -14.629694,
    longitude: 47.763783,
    altitude: 105.156
  },
  {
    iataCode: 'YWP',
    icaoCode: 'CYWP',
    name: 'Webequie',
    country: {code: 'CA', name: 'Canada'},
    city: 'Webequie Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.9597,
    longitude: -87.3689,
    altitude: 208.788
  },
  {
    iataCode: 'YWS',
    icaoCode: 'CAE5',
    name: 'Whistler',
    country: {code: 'CA', name: 'Canada'},
    city: 'Whistler/green Lake Water Aerodrome',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 50.1436,
    longitude: -122.949,
    altitude: 640.08
  },
  {
    iataCode: 'HVB',
    icaoCode: 'YHBA',
    name: 'Hervey Bay',
    country: {code: 'AU', name: 'Australia'},
    city: 'Hervey Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -25.318889,
    longitude: 152.880278,
    altitude: 18.288
  },
  {
    iataCode: 'HVD',
    icaoCode: 'ZMKD',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Khovd Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Hovd',
    utcOffset: 480,
    latitude: 47.9541,
    longitude: 91.6282,
    altitude: 1492.9104
  },
  {
    iataCode: 'HVG',
    icaoCode: 'ENHV',
    name: 'Honningsvag',
    country: {code: 'NO', name: 'Norway'},
    city: 'Valan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 70.99,
    longitude: 25.83,
    altitude: 14.0208
  },
  {
    iataCode: 'YWY',
    icaoCode: 'CYWY',
    name: 'Wrigley',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wrigley',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 63.209444,
    longitude: -123.436667,
    altitude: 149.0472
  },
  {
    iataCode: 'HVN',
    icaoCode: 'KHVN',
    name: 'New Haven',
    country: {code: 'US', name: 'United States'},
    city: 'Tweed-new Haven Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.26375,
    longitude: -72.886806,
    altitude: 4.2672
  },
  {
    iataCode: 'QGY',
    icaoCode: 'LHPR',
    name: 'Győr',
    country: {code: 'HU', name: 'Hungary'},
    city: 'Győr-pér International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Budapest',
    utcOffset: 60,
    latitude: 47.627097,
    longitude: 17.808347,
    altitude: 129.2352
  },
  {
    iataCode: 'YXD',
    icaoCode: 'CYXD',
    name: 'Edmonton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Edmonton City Centre',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 53.5725,
    longitude: -113.520556,
    altitude: 670.56
  },
  {
    iataCode: 'HVR',
    icaoCode: 'KHVR',
    name: 'Havre',
    country: {code: 'US', name: 'United States'},
    city: 'Havre City Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 48.542983,
    longitude: -109.762342,
    altitude: 789.432
  },
  {
    iataCode: 'YXC',
    icaoCode: 'CYXC',
    name: 'Cranbrook',
    country: {code: 'CA', name: 'Canada'},
    city: 'Canadian Rockies Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 49.612222,
    longitude: -115.781944,
    altitude: 940.0032
  },
  {
    iataCode: 'YXE',
    icaoCode: 'CYXE',
    name: 'Saskatoon',
    country: {code: 'CA', name: 'Canada'},
    city: 'Saskatoon J G Diefenbaker Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 52.170834,
    longitude: -106.699722,
    altitude: 503.8344
  },
  {
    iataCode: 'YXH',
    icaoCode: 'CYXH',
    name: 'Medicine Hat',
    country: {code: 'CA', name: 'Canada'},
    city: 'Medicine Hat',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 50.01889,
    longitude: -110.720833,
    altitude: 716.8896
  },
  {
    iataCode: 'YXJ',
    icaoCode: 'CYXJ',
    name: 'Fort Saint John',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort St John',
    region: '',
    terminal: '',
    timezoneId: 'America/Dawson_Creek',
    utcOffset: -480,
    latitude: 56.238056,
    longitude: -120.740278,
    altitude: 694.944
  },
  {
    iataCode: 'YXL',
    icaoCode: 'CYXL',
    name: 'Sioux Lookout',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sioux Lookout',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 50.113889,
    longitude: -91.905278,
    altitude: 383.4384
  },
  {
    iataCode: 'YXK',
    icaoCode: 'CYXK',
    name: 'Rimouski',
    country: {code: 'CA', name: 'Canada'},
    city: 'Rimouski Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -360,
    latitude: 48.478056,
    longitude: -68.496944,
    altitude: 24.9936
  },
  {
    iataCode: 'YXN',
    icaoCode: 'CYXN',
    name: 'Whale Cove',
    country: {code: 'CA', name: 'Canada'},
    city: 'Whale Cove Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Rankin_Inlet',
    utcOffset: -360,
    latitude: 62.24,
    longitude: -92.5981,
    altitude: 12.192
  },
  {
    iataCode: 'YXP',
    icaoCode: 'CYXP',
    name: 'Pangnirtung',
    country: {code: 'CA', name: 'Canada'},
    city: 'Pangnirtung',
    region: '',
    terminal: '',
    timezoneId: 'America/Pangnirtung',
    utcOffset: -300,
    latitude: 66.145,
    longitude: -65.713611,
    altitude: 22.86
  },
  {
    iataCode: 'YXR',
    icaoCode: 'CYXR',
    name: 'Earlton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Timiskaming Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 47.695,
    longitude: -79.848889,
    altitude: 243.2304
  },
  {
    iataCode: 'YXT',
    icaoCode: 'CYXT',
    name: 'Terrace',
    country: {code: 'CA', name: 'Canada'},
    city: 'Terrace',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.468508,
    longitude: -128.576219,
    altitude: 217.3224
  },
  {
    iataCode: 'YXS',
    icaoCode: 'CYXS',
    name: 'Prince George',
    country: {code: 'CA', name: 'Canada'},
    city: 'Prince George',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 53.889444,
    longitude: -122.678889,
    altitude: 690.9816
  },
  {
    iataCode: 'YXU',
    icaoCode: 'CYXU',
    name: 'London',
    country: {code: 'CA', name: 'Canada'},
    city: 'London',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.033056,
    longitude: -81.151111,
    altitude: 277.9776
  },
  {
    iataCode: 'YXX',
    icaoCode: 'CYXX',
    name: 'Abbotsford',
    country: {code: 'CA', name: 'Canada'},
    city: 'Abbotsford',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.025278,
    longitude: -122.360556,
    altitude: 59.436
  },
  {
    iataCode: 'QHR',
    icaoCode: 'HAHM',
    name: 'Debre Zeyit',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Harar Meda Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 8.7163,
    longitude: 39.0059,
    altitude: 2697.48
  },
  {
    iataCode: 'YXY',
    icaoCode: 'CYXY',
    name: 'Whitehorse',
    country: {code: 'CA', name: 'Canada'},
    city: 'Whitehorse Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 60.709553,
    longitude: -135.067269,
    altitude: 706.2216
  },
  {
    iataCode: 'HWO',
    icaoCode: 'KHWO',
    name: 'Hollywood',
    country: {code: 'US', name: 'United States'},
    city: 'North Perry',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.001222,
    longitude: -80.240722,
    altitude: 2.4384
  },
  {
    iataCode: 'YYC',
    icaoCode: 'CYYC',
    name: 'Calgary',
    country: {code: 'CA', name: 'Canada'},
    city: 'Calgary Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 51.113888,
    longitude: -114.020278,
    altitude: 1084.1736
  },
  {
    iataCode: 'YYB',
    icaoCode: 'CYYB',
    name: 'North Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'North Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 46.363611,
    longitude: -79.422778,
    altitude: 370.332
  },
  {
    iataCode: 'YYE',
    icaoCode: 'CYYE',
    name: 'Fort Nelson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Nelson',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 58.836389,
    longitude: -122.596944,
    altitude: 381.9144
  },
  {
    iataCode: 'YYD',
    icaoCode: 'CYYD',
    name: 'Smithers',
    country: {code: 'CA', name: 'Canada'},
    city: 'Smithers',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.824722,
    longitude: -127.182778,
    altitude: 521.8176
  },
  {
    iataCode: 'YYG',
    icaoCode: 'CYYG',
    name: 'Charlottetown',
    country: {code: 'CA', name: 'Canada'},
    city: 'Charlottetown',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 46.290001,
    longitude: -63.121111,
    altitude: 48.768
  },
  {
    iataCode: 'YYF',
    icaoCode: 'CYYF',
    name: 'Penticton',
    country: {code: 'CA', name: 'Canada'},
    city: 'Penticton',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.463056,
    longitude: -119.602222,
    altitude: 344.1192
  },
  {
    iataCode: 'YYH',
    icaoCode: 'CYYH',
    name: 'Spence Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Taloyoak',
    region: '',
    terminal: '',
    timezoneId: 'America/Cambridge_Bay',
    utcOffset: -360,
    latitude: 69.546667,
    longitude: -93.576667,
    altitude: 28.0416
  },
  {
    iataCode: 'YYJ',
    icaoCode: 'CYYJ',
    name: 'Victoria',
    country: {code: 'CA', name: 'Canada'},
    city: 'Victoria Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 48.646944,
    longitude: -123.425833,
    altitude: 19.2024
  },
  {
    iataCode: 'YYL',
    icaoCode: 'CYYL',
    name: 'Lynn Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lynn Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 56.863888,
    longitude: -101.07611,
    altitude: 356.616
  },
  {
    iataCode: 'YYN',
    icaoCode: 'CYYN',
    name: 'Swift Current',
    country: {code: 'CA', name: 'Canada'},
    city: 'Swift Current',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 50.291944,
    longitude: -107.690556,
    altitude: 816.864
  },
  {
    iataCode: 'YYQ',
    icaoCode: 'CYYQ',
    name: 'Churchill',
    country: {code: 'CA', name: 'Canada'},
    city: 'Churchill',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 58.739167,
    longitude: -94.065,
    altitude: 28.6512
  },
  {
    iataCode: 'YYR',
    icaoCode: 'CYYR',
    name: 'Goose Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Goose Bay',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 53.319168,
    longitude: -60.425833,
    altitude: 48.768
  },
  {
    iataCode: 'YYU',
    icaoCode: 'CYYU',
    name: 'Kapuskasing',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kapuskasing',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 49.413889,
    longitude: -82.4675,
    altitude: 226.4664
  },
  {
    iataCode: 'YYT',
    icaoCode: 'CYYT',
    name: "St. John's",
    country: {code: 'CA', name: 'Canada'},
    city: 'St Johns Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Johns',
    utcOffset: -240,
    latitude: 47.61861,
    longitude: -52.751945,
    altitude: 140.5128
  },
  {
    iataCode: 'YYW',
    icaoCode: 'CYYW',
    name: 'Armstrong',
    country: {code: 'CA', name: 'Canada'},
    city: 'Armstrong',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 50.290279,
    longitude: -88.909721,
    altitude: 322.4784
  },
  {
    iataCode: 'YYY',
    icaoCode: 'CYYY',
    name: 'Mont Joli',
    country: {code: 'CA', name: 'Canada'},
    city: 'Mont Joli',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 48.608612,
    longitude: -68.208056,
    altitude: 52.4256
  },
  {
    iataCode: 'YYZ',
    icaoCode: 'CYYZ',
    name: 'Toronto',
    country: {code: 'CA', name: 'Canada'},
    city: 'Lester B Pearson Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.677223,
    longitude: -79.630556,
    altitude: 173.4312
  },
  {
    iataCode: 'YZD',
    icaoCode: 'CYZD',
    name: 'Toronto',
    country: {code: 'CA', name: 'Canada'},
    city: 'Downsview',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 43.7425,
    longitude: -79.465556,
    altitude: 198.7296
  },
  {
    iataCode: 'YZF',
    icaoCode: 'CYZF',
    name: 'Yellowknife',
    country: {code: 'CA', name: 'Canada'},
    city: 'Yellowknife',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 62.462778,
    longitude: -114.440278,
    altitude: 205.74
  },
  {
    iataCode: 'YZE',
    icaoCode: 'CYZE',
    name: 'Gore Bay',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gore Bay Manitoulin',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 45.885277,
    longitude: -82.567778,
    altitude: 193.548
  },
  {
    iataCode: 'YZH',
    icaoCode: 'CYZH',
    name: 'Slave Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Slave Lake',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 55.293056,
    longitude: -114.777222,
    altitude: 582.7776
  },
  {
    iataCode: 'YZG',
    icaoCode: 'CYZG',
    name: 'Salluit',
    country: {code: 'CA', name: 'Canada'},
    city: 'Salluit Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 62.1794,
    longitude: -75.6672,
    altitude: 226.4664
  },
  {
    iataCode: 'YZP',
    icaoCode: 'CYZP',
    name: 'Sandspit',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sandspit',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 53.254333,
    longitude: -131.813833,
    altitude: 6.4008
  },
  {
    iataCode: 'YZR',
    icaoCode: 'CYZR',
    name: 'Sarnia',
    country: {code: 'CA', name: 'Canada'},
    city: 'Chris Hadfield',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 42.999444,
    longitude: -82.308889,
    altitude: 181.0512
  },
  {
    iataCode: 'HYA',
    icaoCode: 'KHYA',
    name: 'Barnstable',
    country: {code: 'US', name: 'United States'},
    city: 'Barnstable Muni Boardman Polando Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.669336,
    longitude: -70.280356,
    altitude: 16.764
  },
  {
    iataCode: 'HYD',
    icaoCode: 'VOHY',
    name: 'Hyderabad',
    country: {code: 'IN', name: 'India'},
    city: 'Hyderabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 17.453117,
    longitude: 78.467586,
    altitude: 530.9616
  },
  {
    iataCode: 'YZT',
    icaoCode: 'CYZT',
    name: 'Port Hardy',
    country: {code: 'CA', name: 'Canada'},
    city: 'Port Hardy',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 50.680556,
    longitude: -127.366667,
    altitude: 21.6408
  },
  {
    iataCode: 'YZS',
    icaoCode: 'CYCZ',
    name: 'Coral Harbour',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fairmont Hot Springs',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 50.330278,
    longitude: -115.873333,
    altitude: 811.0728
  },
  {
    iataCode: 'YZV',
    icaoCode: 'CYZV',
    name: 'Sept-iles',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sept Iles',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 50.223333,
    longitude: -66.265556,
    altitude: 54.864
  },
  {
    iataCode: 'YZU',
    icaoCode: 'CYZU',
    name: 'Whitecourt',
    country: {code: 'CA', name: 'Canada'},
    city: 'Whitecourt',
    region: '',
    terminal: '',
    timezoneId: 'America/Edmonton',
    utcOffset: -420,
    latitude: 54.14389,
    longitude: -115.786667,
    altitude: 782.4216
  },
  {
    iataCode: 'YZX',
    icaoCode: 'CYZX',
    name: 'Greenwood',
    country: {code: 'CA', name: 'Canada'},
    city: 'Greenwood',
    region: '',
    terminal: '',
    timezoneId: 'America/Halifax',
    utcOffset: -240,
    latitude: 44.984444,
    longitude: -64.916944,
    altitude: 28.0416
  },
  {
    iataCode: 'HYG',
    icaoCode: 'PAHY',
    name: 'Hydaburg',
    country: {code: 'US', name: 'United States'},
    city: 'Hydaburg Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Metlakatla',
    utcOffset: -540,
    latitude: 55.206389,
    longitude: -132.828333,
    altitude: 0.0
  },
  {
    iataCode: 'YZW',
    icaoCode: 'CYZW',
    name: 'Teslin',
    country: {code: 'CA', name: 'Canada'},
    city: 'Teslin',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 60.172779,
    longitude: -132.742778,
    altitude: 705.0024
  },
  {
    iataCode: 'HYN',
    icaoCode: 'ZSLQ',
    name: 'Huangyan',
    country: {code: 'CN', name: 'China'},
    city: 'Huangyan Luqiao Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 28.5622,
    longitude: 121.429,
    altitude: 0.0
  },
  {
    iataCode: 'HYS',
    icaoCode: 'KHYS',
    name: 'Hays',
    country: {code: 'US', name: 'United States'},
    city: 'Hays Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 38.8422,
    longitude: -99.2732,
    altitude: 608.9904
  },
  {
    iataCode: 'HZG',
    icaoCode: 'ZLHZ',
    name: 'Hanzhong',
    country: {code: 'CN', name: 'China'},
    city: 'Hanzhong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 33.0636,
    longitude: 107.008,
    altitude: 0.0
  },
  {
    iataCode: 'HZK',
    icaoCode: 'BIHU',
    name: 'Husavik',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Husavik',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.952328,
    longitude: -17.425978,
    altitude: 14.6304
  },
  {
    iataCode: 'QKT',
    icaoCode: 'EBKT',
    name: 'Kortrijk-vevelgem',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Wevelgem',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 50.817222,
    longitude: 3.204722,
    altitude: 19.5072
  },
  {
    iataCode: 'QLA',
    icaoCode: 'EGHL',
    name: 'Lasham',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Lasham',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 51.187167,
    longitude: -1.0335,
    altitude: 188.3664
  },
  {
    iataCode: 'QLT',
    icaoCode: 'LIRL',
    name: 'Latina',
    country: {code: 'IT', name: 'Italy'},
    city: 'Latina',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 41.542436,
    longitude: 12.909019,
    altitude: 28.3464
  },
  {
    iataCode: 'QMJ',
    icaoCode: 'OIAI',
    name: 'Masjed Soleiman',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Shahid Asyaee',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 32.002372,
    longitude: 49.270364,
    altitude: 367.5888
  },
  {
    iataCode: 'QNC',
    icaoCode: 'LSGN',
    name: 'Neuchatel',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Neuchatel Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.9575,
    longitude: 6.86472,
    altitude: 434.9496
  },
  {
    iataCode: 'QNJ',
    icaoCode: 'LFLI',
    name: 'Annemasse',
    country: {code: 'FR', name: 'France'},
    city: 'Annemasse',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.191972,
    longitude: 6.268386,
    altitude: 493.776
  },
  {
    iataCode: 'QNV',
    icaoCode: 'SDNY',
    name: 'Nova Iguacu',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Aeroclube De Nova Iguacu',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.7453,
    longitude: -43.4603,
    altitude: 49.9872
  },
  {
    iataCode: 'QNX',
    icaoCode: 'LFLM',
    name: 'Macon',
    country: {code: 'FR', name: 'France'},
    city: 'Charnay',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.295103,
    longitude: 4.795767,
    altitude: 221.8944
  },
  {
    iataCode: 'QOW',
    icaoCode: 'DNIM',
    name: '',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Imo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 5.42706,
    longitude: 7.20603,
    altitude: 113.6904
  },
  {
    iataCode: 'ZAD',
    icaoCode: 'LDZD',
    name: 'Zadar',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Zadar',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 44.108269,
    longitude: 15.346697,
    altitude: 88.0872
  },
  {
    iataCode: 'ZAC',
    icaoCode: 'CZAC',
    name: 'York Landing',
    country: {code: 'CA', name: 'Canada'},
    city: 'York Landing Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 56.0894,
    longitude: -96.0892,
    altitude: 189.2808
  },
  {
    iataCode: 'ZAH',
    icaoCode: 'OIZH',
    name: 'Zahedan',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Zahedan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 29.475686,
    longitude: 60.906189,
    altitude: 1391.1072
  },
  {
    iataCode: 'ZAG',
    icaoCode: 'LDZA',
    name: 'Zagreb',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Zagreb',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 45.742931,
    longitude: 16.068778,
    altitude: 107.5944
  },
  {
    iataCode: 'ZAJ',
    icaoCode: 'OAZJ',
    name: 'Zaranj',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Zaranj Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 240,
    latitude: 30.969167,
    longitude: 61.866944,
    altitude: 481.8888
  },
  {
    iataCode: 'QPA',
    icaoCode: 'LIPU',
    name: 'Padova',
    country: {code: 'IT', name: 'Italy'},
    city: 'Padova',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.395767,
    longitude: 11.847903,
    altitude: 13.4112
  },
  {
    iataCode: 'ZAL',
    icaoCode: 'SCVD',
    name: 'Valdivia',
    country: {code: 'CL', name: 'Chile'},
    city: 'Pichoy',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -39.649956,
    longitude: -73.086111,
    altitude: 17.9832
  },
  {
    iataCode: 'ZAM',
    icaoCode: 'RPMZ',
    name: 'Zamboanga',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Zamboanga Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 6.922419,
    longitude: 122.059633,
    altitude: 10.0584
  },
  {
    iataCode: 'QPG',
    icaoCode: 'WSAP',
    name: 'Paya Lebar',
    country: {code: 'SG', name: 'Singapore'},
    city: 'Paya Lebar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Singapore',
    utcOffset: 480,
    latitude: 1.360417,
    longitude: 103.90953,
    altitude: 19.812
  },
  {
    iataCode: 'ZAR',
    icaoCode: 'DNZA',
    name: 'Zaria',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Zaria',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 11.130192,
    longitude: 7.685806,
    altitude: 661.416
  },
  {
    iataCode: 'ZAT',
    icaoCode: 'ZPZT',
    name: 'Zhaotong',
    country: {code: 'CN', name: 'China'},
    city: 'Zhaotong Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 27.3256,
    longitude: 103.755,
    altitude: 0.0
  },
  {
    iataCode: 'ZAZ',
    icaoCode: 'LEZG',
    name: 'Zaragoza',
    country: {code: 'ES', name: 'Spain'},
    city: 'Zaragoza Ab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.666242,
    longitude: -1.041553,
    altitude: 263.0424
  },
  {
    iataCode: 'QPS',
    icaoCode: 'SBYS',
    name: 'Piracununga',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Campo Fontenelle',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.984561,
    longitude: -47.334764,
    altitude: 599.8464
  },
  {
    iataCode: 'QPZ',
    icaoCode: 'LIMS',
    name: 'Piacenza',
    country: {code: 'IT', name: 'Italy'},
    city: 'Piacenza',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.913055,
    longitude: 9.723333,
    altitude: 138.9888
  },
  {
    iataCode: 'ZBF',
    icaoCode: 'CZBF',
    name: 'Bathurst',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bathurst Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Moncton',
    utcOffset: -240,
    latitude: 47.6297,
    longitude: -65.7389,
    altitude: 58.8264
  },
  {
    iataCode: 'IAA',
    icaoCode: 'UOII',
    name: 'Igarka',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Igarka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 67.4372,
    longitude: 86.6219,
    altitude: 24.9936
  },
  {
    iataCode: 'IAB',
    icaoCode: 'KIAB',
    name: 'Wichita',
    country: {code: 'US', name: 'United States'},
    city: 'Mc Connell Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.621853,
    longitude: -97.268192,
    altitude: 417.8808
  },
  {
    iataCode: 'ZBR',
    icaoCode: 'OIZC',
    name: 'Chah Bahar',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Chah Bahar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 25.44335,
    longitude: 60.382114,
    altitude: 13.1064
  },
  {
    iataCode: 'IAD',
    icaoCode: 'KIAD',
    name: 'Washington',
    country: {code: 'US', name: 'United States'},
    city: 'Washington Dulles Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.944533,
    longitude: -77.455811,
    altitude: 95.4024
  },
  {
    iataCode: 'IAG',
    icaoCode: 'KIAG',
    name: 'Niagara Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Niagara Falls Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.107333,
    longitude: -78.946194,
    altitude: 179.5272
  },
  {
    iataCode: 'IAH',
    icaoCode: 'KIAH',
    name: 'Houston',
    country: {code: 'US', name: 'United States'},
    city: 'George Bush Intcntl Houston',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.984433,
    longitude: -95.341442,
    altitude: 29.5656
  },
  {
    iataCode: 'IAM',
    icaoCode: 'DAUZ',
    name: 'Zarzaitine',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'In Amenas',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 28.05155,
    longitude: 9.642911,
    altitude: 562.9656
  },
  {
    iataCode: 'IAN',
    icaoCode: 'PAIK',
    name: 'Kiana',
    country: {code: 'US', name: 'United States'},
    city: 'Bob Baker Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 66.975833,
    longitude: -160.436667,
    altitude: 50.5968
  },
  {
    iataCode: 'ZCA',
    icaoCode: 'EDLA',
    name: 'Arnsberg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Arnsberg Menden',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 51.483333,
    longitude: 7.899333,
    altitude: 242.0112
  },
  {
    iataCode: 'IAS',
    icaoCode: 'LRIA',
    name: 'Iasi',
    country: {code: 'RO', name: 'Romania'},
    city: 'Iasi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 47.178492,
    longitude: 27.620631,
    altitude: 121.0056
  },
  {
    iataCode: 'IAR',
    icaoCode: 'UUDL',
    name: 'Yaroslavl',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Tunoshna',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 57.560667,
    longitude: 40.157369,
    altitude: 82.296
  },
  {
    iataCode: 'QRA',
    icaoCode: 'FAGM',
    name: 'Johannesburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Rand Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -26.242506,
    longitude: 28.151169,
    altitude: 1671.2184
  },
  {
    iataCode: 'ZCL',
    icaoCode: 'MMZC',
    name: 'Zacatecas',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Leobardo C Ruiz Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Monterrey',
    utcOffset: -360,
    latitude: 22.897112,
    longitude: -102.68689,
    altitude: 2176.5768
  },
  {
    iataCode: 'ZCN',
    icaoCode: 'ETHC',
    name: 'Celle',
    country: {code: 'DE', name: 'Germany'},
    city: 'Celle',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 52.5912,
    longitude: 10.022133,
    altitude: 39.3192
  },
  {
    iataCode: 'ZCO',
    icaoCode: 'SCTC',
    name: 'Temuco',
    country: {code: 'CL', name: 'Chile'},
    city: 'Maquehue',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -38.766819,
    longitude: -72.637097,
    altitude: 92.6592
  },
  {
    iataCode: 'IBA',
    icaoCode: 'DNIB',
    name: 'Ibadan',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Ibadan',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 7.362458,
    longitude: 3.978333,
    altitude: 220.98
  },
  {
    iataCode: 'IBE',
    icaoCode: 'SKIB',
    name: 'Ibague',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Perales',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.421608,
    longitude: -75.1333,
    altitude: 914.0952
  },
  {
    iataCode: 'QRO',
    icaoCode: 'MMQT',
    name: 'Queretaro',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Queretaro Intercontinental',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.617289,
    longitude: -100.185658,
    altitude: 1919.0208
  },
  {
    iataCode: 'QRW',
    icaoCode: 'DNSU',
    name: 'Osubi',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Warri Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 5.31,
    longitude: 5.45,
    altitude: 15.24
  },
  {
    iataCode: 'QSA',
    icaoCode: 'LELL',
    name: 'Sabadell',
    country: {code: 'ES', name: 'Spain'},
    city: 'Sabadell Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.5209,
    longitude: 2.10508,
    altitude: 0.0
  },
  {
    iataCode: 'IBZ',
    icaoCode: 'LEIB',
    name: 'Ibiza',
    country: {code: 'ES', name: 'Spain'},
    city: 'Ibiza',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 38.872858,
    longitude: 1.373117,
    altitude: 6.096
  },
  {
    iataCode: 'QSF',
    icaoCode: 'DAAS',
    name: 'Setif',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Ain Arnat Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 0,
    latitude: 36.1781,
    longitude: 5.32449,
    altitude: 1024.128
  },
  {
    iataCode: 'ICI',
    icaoCode: 'NFCI',
    name: 'Cicia',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Cicia Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -17.7433,
    longitude: -179.342,
    altitude: 3.9624
  },
  {
    iataCode: 'ICK',
    icaoCode: 'SMNI',
    name: 'Nieuw Nickerie',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Majoor Henry Fernandes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.955556,
    longitude: -57.039444,
    altitude: 2.7432
  },
  {
    iataCode: 'QSR',
    icaoCode: 'LIRI',
    name: 'Salerno',
    country: {code: 'IT', name: 'Italy'},
    city: 'Salerno Pontecagnano Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.6204,
    longitude: 14.911294,
    altitude: 37.4904
  },
  {
    iataCode: 'ICN',
    icaoCode: 'RKSI',
    name: 'Seoul',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Incheon Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 37.469075,
    longitude: 126.450517,
    altitude: 7.0104
  },
  {
    iataCode: 'ICT',
    icaoCode: 'KICT',
    name: 'Wichita',
    country: {code: 'US', name: 'United States'},
    city: 'Wichita Mid Continent',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.649944,
    longitude: -97.433056,
    altitude: 406.2984
  },
  {
    iataCode: 'ZEL',
    icaoCode: 'CYJQ',
    name: 'Bella Bella',
    country: {code: 'CA', name: 'Canada'},
    city: 'Bella Bella Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 52.139722,
    longitude: -128.063611,
    altitude: 49.3776
  },
  {
    iataCode: 'ZEM',
    icaoCode: 'CZEM',
    name: 'Eastmain River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Eastmain River Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 52.2264,
    longitude: -78.5225,
    altitude: 7.3152
  },
  {
    iataCode: 'IDA',
    icaoCode: 'KIDA',
    name: 'Idaho Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Idaho Falls Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Boise',
    utcOffset: -420,
    latitude: 43.514556,
    longitude: -112.07075,
    altitude: 1445.9712
  },
  {
    iataCode: 'IDL',
    icaoCode: 'KIDL',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'Idlewild Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.639751,
    longitude: -73.778925,
    altitude: 3.9624
  },
  {
    iataCode: 'ZFA',
    icaoCode: 'CZFA',
    name: 'Faro',
    country: {code: 'CA', name: 'Canada'},
    city: 'Faro',
    region: '',
    terminal: '',
    timezoneId: 'America/Whitehorse',
    utcOffset: -480,
    latitude: 62.2075,
    longitude: -133.375833,
    altitude: 716.5848
  },
  {
    iataCode: 'IDR',
    icaoCode: 'VAID',
    name: 'Indore',
    country: {code: 'IN', name: 'India'},
    city: 'Devi Ahilyabai Holkar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.721786,
    longitude: 75.801086,
    altitude: 563.88
  },
  {
    iataCode: 'ZFD',
    icaoCode: 'CZFD',
    name: 'Fond-du-lac',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fond-du-lac Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 59.3344,
    longitude: -107.182,
    altitude: 248.1072
  },
  {
    iataCode: 'IDY',
    icaoCode: 'LFEY',
    name: "Île D'yeu",
    country: {code: 'FR', name: 'France'},
    city: "Île D'yeu Airport",
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 0,
    latitude: 46.718611,
    longitude: -2.391111,
    altitude: 24.0792
  },
  {
    iataCode: 'ZFM',
    icaoCode: 'CZFM',
    name: 'Fort Mcpherson',
    country: {code: 'CA', name: 'Canada'},
    city: 'Fort Mcpherson',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 67.4075,
    longitude: -134.860556,
    altitude: 35.3568
  },
  {
    iataCode: 'ZFN',
    icaoCode: 'CZFN',
    name: 'Tulita',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tulita',
    region: '',
    terminal: '',
    timezoneId: 'America/Yellowknife',
    utcOffset: -420,
    latitude: 64.083333,
    longitude: -125.583333,
    altitude: 97.536
  },
  {
    iataCode: 'IEG',
    icaoCode: 'EPZG',
    name: 'Zielona Gora',
    country: {code: 'PL', name: 'Poland'},
    city: 'Babimost',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 52.138517,
    longitude: 15.798556,
    altitude: 59.1312
  },
  {
    iataCode: 'QUS',
    icaoCode: 'DNGU',
    name: 'Gusau',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Gusau',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 12.171667,
    longitude: 6.696111,
    altitude: 463.296
  },
  {
    iataCode: 'IEO',
    icaoCode: 'GQNA',
    name: 'Aioun El Atrouss',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Aioun El Atrouss',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 16.711294,
    longitude: -9.637883,
    altitude: 289.8648
  },
  {
    iataCode: 'ZGC',
    icaoCode: 'ZLLL',
    name: 'Lanzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Zhongchuan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 36.515242,
    longitude: 103.620775,
    altitude: 1947.0624
  },
  {
    iataCode: 'IEV',
    icaoCode: 'UKKK',
    name: 'Kiev',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Zhuliany Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 50.401694,
    longitude: 30.449697,
    altitude: 178.6128
  },
  {
    iataCode: 'ZGI',
    icaoCode: 'CZGI',
    name: 'Gods River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Gods River Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 54.8397,
    longitude: -94.0786,
    altitude: 191.1096
  },
  {
    iataCode: 'ZGR',
    icaoCode: 'CZGR',
    name: 'Little Grand Rapids',
    country: {code: 'CA', name: 'Canada'},
    city: 'Little Grand Rapids Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -420,
    latitude: 52.045,
    longitude: -95.466111,
    altitude: 307.2384
  },
  {
    iataCode: 'ZGU',
    icaoCode: 'NVSQ',
    name: 'Gaua Island',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Gaua Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -14.2181,
    longitude: 167.587,
    altitude: 0.0
  },
  {
    iataCode: 'IFJ',
    icaoCode: 'BIIS',
    name: 'Isafjordur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Isafjordur',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 66.058056,
    longitude: -23.135278,
    altitude: 2.4384
  },
  {
    iataCode: 'IFP',
    icaoCode: 'IFP',
    name: 'Bullhead City',
    country: {code: 'US', name: 'United States'},
    city: 'Laughlin Bullhead International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 35.1574,
    longitude: -114.56,
    altitude: 211.836
  },
  {
    iataCode: 'ZHA',
    icaoCode: 'ZGZJ',
    name: 'Zhanjiang',
    country: {code: 'CN', name: 'China'},
    city: 'Zhanjiang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 21.2144,
    longitude: 110.358,
    altitude: 0.0
  },
  {
    iataCode: 'IFO',
    icaoCode: 'UKLI',
    name: 'Ivano-frankivsk',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Ivano Frankivsk International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 48.884167,
    longitude: 24.686111,
    altitude: 280.1112
  },
  {
    iataCode: 'QVY',
    icaoCode: 'EFUT',
    name: 'Utti',
    country: {code: 'FI', name: 'Finland'},
    city: 'Utti',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 60.896394,
    longitude: 26.938353,
    altitude: 103.3272
  },
  {
    iataCode: 'IGA',
    icaoCode: 'MYIG',
    name: 'Matthew Town',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Matthew Town',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 20.975,
    longitude: -73.666862,
    altitude: 2.4384
  },
  {
    iataCode: 'IGB',
    icaoCode: 'SAVJ',
    name: 'Ingeniero Jacobacci',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Ingeniero Jacobacci',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -41.3,
    longitude: -69.5833,
    altitude: 894.8928
  },
  {
    iataCode: 'IGG',
    icaoCode: 'PAIG',
    name: 'Igiugig',
    country: {code: 'US', name: 'United States'},
    city: 'Igiugig Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.324,
    longitude: -155.902,
    altitude: 27.432
  },
  {
    iataCode: 'IGM',
    icaoCode: 'IGM',
    name: 'Kingman',
    country: {code: 'US', name: 'United States'},
    city: 'Kingman Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 35.2595,
    longitude: -113.938,
    altitude: 1051.2552
  },
  {
    iataCode: 'IGL',
    icaoCode: 'LTBL',
    name: 'Izmir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Cigli',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.513022,
    longitude: 27.010053,
    altitude: 4.8768
  },
  {
    iataCode: 'IGR',
    icaoCode: 'SARI',
    name: 'Iguazu Falls',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Cataratas Del Iguazu',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -25.737281,
    longitude: -54.473444,
    altitude: 279.1968
  },
  {
    iataCode: 'IGU',
    icaoCode: 'SBFI',
    name: 'Foz Do Iguacu',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Cataratas Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -25.59615,
    longitude: -54.487206,
    altitude: 239.8776
  },
  {
    iataCode: 'ZIH',
    icaoCode: 'MMZH',
    name: 'Zihuatanejo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Ixtapa Zihuatanejo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 17.601569,
    longitude: -101.460536,
    altitude: 7.9248
  },
  {
    iataCode: 'ZIG',
    icaoCode: 'GOGG',
    name: 'Ziguinchor',
    country: {code: 'SN', name: 'Senegal'},
    city: 'Ziguinchor',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dakar',
    utcOffset: 0,
    latitude: 12.555617,
    longitude: -16.281783,
    altitude: 22.86
  },
  {
    iataCode: 'QXB',
    icaoCode: 'LFMA',
    name: 'Aix-les-milles',
    country: {code: 'FR', name: 'France'},
    city: 'Aix Les Milles',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.505554,
    longitude: 5.367778,
    altitude: 111.8616
  },
  {
    iataCode: 'ZIN',
    icaoCode: 'LSMI',
    name: 'Interlaken',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Air Base',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.6766,
    longitude: 7.87908,
    altitude: 576.9864
  },
  {
    iataCode: 'ZJI',
    icaoCode: 'LSZL',
    name: 'Locarno',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Locarno Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 46.1608,
    longitude: 8.87861,
    altitude: 198.12
  },
  {
    iataCode: 'ZJN',
    icaoCode: 'CZJN',
    name: 'Swan River',
    country: {code: 'CA', name: 'Canada'},
    city: 'Swan River Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.1206,
    longitude: -101.236,
    altitude: 335.28
  },
  {
    iataCode: 'IIA',
    icaoCode: 'EIMN',
    name: 'Inishmaan',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Inishmaan Aerodrome',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.091944,
    longitude: -9.57,
    altitude: 3.9624
  },
  {
    iataCode: 'QYR',
    icaoCode: 'LFQB',
    name: 'Troyes',
    country: {code: 'FR', name: 'France'},
    city: 'Barberey',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.322136,
    longitude: 4.016703,
    altitude: 118.2624
  },
  {
    iataCode: 'IIL',
    icaoCode: 'OICI',
    name: 'Ilam',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Ilam Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 33.586606,
    longitude: 46.404842,
    altitude: 1342.3392
  },
  {
    iataCode: 'ZKE',
    icaoCode: 'CZKE',
    name: 'Kashechewan',
    country: {code: 'CA', name: 'Canada'},
    city: 'Kashechewan Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Toronto',
    utcOffset: -300,
    latitude: 52.2825,
    longitude: -81.6778,
    altitude: 10.668
  },
  {
    iataCode: 'IJK',
    icaoCode: 'USII',
    name: 'Izhevsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Izhevsk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Samara',
    utcOffset: 300,
    latitude: 56.8281,
    longitude: 53.4575,
    altitude: 161.8488
  },
  {
    iataCode: 'ZLO',
    icaoCode: 'MMZO',
    name: 'Manzanillo',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Playa De Oro Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.144778,
    longitude: -104.558631,
    altitude: 9.144
  },
  {
    iataCode: 'ZLT',
    icaoCode: 'CTU5',
    name: 'La Tabatière',
    country: {code: 'CA', name: 'Canada'},
    city: 'La Tabatière Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Blanc-Sablon',
    utcOffset: -240,
    latitude: 50.8308,
    longitude: -58.9756,
    altitude: 31.0896
  },
  {
    iataCode: 'IKI',
    icaoCode: 'RJDB',
    name: 'Iki',
    country: {code: 'JP', name: 'Japan'},
    city: 'Iki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 33.749027,
    longitude: 129.785417,
    altitude: 12.4968
  },
  {
    iataCode: 'IKK',
    icaoCode: 'KIKK',
    name: 'Kankakee',
    country: {code: 'US', name: 'United States'},
    city: 'Greater Kankakee',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.071389,
    longitude: -87.846278,
    altitude: 192.024
  },
  {
    iataCode: 'IKO',
    icaoCode: 'PAKO',
    name: 'Nikolski',
    country: {code: 'US', name: 'United States'},
    city: 'Nikolski Air Station',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -540,
    latitude: 52.941667,
    longitude: -168.848889,
    altitude: 23.4696
  },
  {
    iataCode: 'IKS',
    icaoCode: 'UEST',
    name: 'Tiksi',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Tiksi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yakutsk',
    utcOffset: 540,
    latitude: 71.6977,
    longitude: 128.903,
    altitude: 7.9248
  },
  {
    iataCode: 'IKR',
    icaoCode: 'KIKR',
    name: 'Kirtland A.f.b.',
    country: {code: 'US', name: 'United States'},
    city: 'Albuquerque Intl Sunport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.040222,
    longitude: -106.609194,
    altitude: 1632.204
  },
  {
    iataCode: 'IKT',
    icaoCode: 'UIII',
    name: 'Irkutsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Irkutsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Irkutsk',
    utcOffset: 480,
    latitude: 52.268028,
    longitude: 104.388975,
    altitude: 510.54
  },
  {
    iataCode: 'IKV',
    icaoCode: 'KIKV',
    name: 'Ankeny',
    country: {code: 'US', name: 'United States'},
    city: 'Ankeny Regl Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 41.691389,
    longitude: -93.566389,
    altitude: 277.368
  },
  {
    iataCode: 'ZMM',
    icaoCode: 'MMZM',
    name: 'Zamora',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Zamora',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 20.045036,
    longitude: -102.275955,
    altitude: 1566.9768
  },
  {
    iataCode: 'ZMT',
    icaoCode: 'CZMT',
    name: 'Masset',
    country: {code: 'CA', name: 'Canada'},
    city: 'Masset Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.0275,
    longitude: -132.125,
    altitude: 7.62
  },
  {
    iataCode: 'ILF',
    icaoCode: 'CZBD',
    name: 'Ilford',
    country: {code: 'CA', name: 'Canada'},
    city: 'Ilford Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 56.0614,
    longitude: -95.6139,
    altitude: 195.6816
  },
  {
    iataCode: 'ILG',
    icaoCode: 'KILG',
    name: 'Wilmington',
    country: {code: 'US', name: 'United States'},
    city: 'New Castle',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.678722,
    longitude: -75.606528,
    altitude: 24.0792
  },
  {
    iataCode: 'ILI',
    icaoCode: 'PAIL',
    name: 'Iliamna',
    country: {code: 'US', name: 'United States'},
    city: 'Iliamna',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 59.754356,
    longitude: -154.910961,
    altitude: 56.6928
  },
  {
    iataCode: 'ILM',
    icaoCode: 'KILM',
    name: 'Wilmington',
    country: {code: 'US', name: 'United States'},
    city: 'Wilmington Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.270615,
    longitude: -77.902569,
    altitude: 9.7536
  },
  {
    iataCode: 'ILP',
    icaoCode: 'NWWE',
    name: '',
    country: {code: 'NC', name: 'New Caledonia'},
    city: 'Île Des Pins Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Noumea',
    utcOffset: 660,
    latitude: -22.5889,
    longitude: 167.456,
    altitude: 96.012
  },
  {
    iataCode: 'ZNA',
    icaoCode: 'CAC8',
    name: 'Nanaimo',
    country: {code: 'CA', name: 'Canada'},
    city: 'Nanaimo Harbour Water Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 49.183333,
    longitude: -123.95,
    altitude: 0.0
  },
  {
    iataCode: 'ILO',
    icaoCode: 'RPVI',
    name: 'Iloilo',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Iloilo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 10.713044,
    longitude: 122.545297,
    altitude: 8.2296
  },
  {
    iataCode: 'ILR',
    icaoCode: 'DNIL',
    name: 'Ilorin',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Ilorin',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 8.440211,
    longitude: 4.493919,
    altitude: 343.2048
  },
  {
    iataCode: 'ZNE',
    icaoCode: 'YNWN',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Newman Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -23.4178,
    longitude: 119.803,
    altitude: 525.4752
  },
  {
    iataCode: 'ZND',
    icaoCode: 'DRZR',
    name: 'Zinder',
    country: {code: 'NE', name: 'Niger'},
    city: 'Zinder',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Niamey',
    utcOffset: 60,
    latitude: 13.778997,
    longitude: 8.983761,
    altitude: 462.0768
  },
  {
    iataCode: 'ZNF',
    icaoCode: 'ETID',
    name: 'Hanau',
    country: {code: 'DE', name: 'Germany'},
    city: 'Hanau Aaf',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.169189,
    longitude: 8.961586,
    altitude: 112.1664
  },
  {
    iataCode: 'ILZ',
    icaoCode: 'LZZI',
    name: 'Žilina',
    country: {code: 'SK', name: 'Slovakia'},
    city: 'Žilina Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bratislava',
    utcOffset: 60,
    latitude: 49.231528,
    longitude: 18.6135,
    altitude: 310.896
  },
  {
    iataCode: 'ILY',
    icaoCode: 'EGPI',
    name: 'Islay',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Islay',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 55.681944,
    longitude: -6.256667,
    altitude: 17.0688
  },
  {
    iataCode: 'IMB',
    icaoCode: 'SYIB',
    name: 'Imbaimadai',
    country: {code: 'GY', name: 'Guyana'},
    city: 'Imbaimadai Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guyana',
    utcOffset: -240,
    latitude: 5.69252,
    longitude: -60.28198,
    altitude: 3.048
  },
  {
    iataCode: 'IMF',
    icaoCode: 'VEIM',
    name: 'Imphal',
    country: {code: 'IN', name: 'India'},
    city: 'Imphal',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.75995,
    longitude: 93.896697,
    altitude: 774.192
  },
  {
    iataCode: 'ZNV',
    icaoCode: 'EDRK',
    name: 'Koblenz',
    country: {code: 'DE', name: 'Germany'},
    city: 'Koblenz Winningen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.3255,
    longitude: 7.528667,
    altitude: 195.072
  },
  {
    iataCode: 'IMK',
    icaoCode: 'VNST',
    name: 'Simikot',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Simikot',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 29.971064,
    longitude: 81.818932,
    altitude: 2818.1808
  },
  {
    iataCode: 'ZNZ',
    icaoCode: 'HTZA',
    name: 'Zanzibar',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Zanzibar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -6.222025,
    longitude: 39.224886,
    altitude: 16.4592
  },
  {
    iataCode: 'IMP',
    icaoCode: 'SBIZ',
    name: 'Imperatriz',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Prefeito Renato Moreira',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -5.531292,
    longitude: -47.46005,
    altitude: 131.3688
  },
  {
    iataCode: 'IMT',
    icaoCode: 'KIMT',
    name: 'Iron Mountain',
    country: {code: 'US', name: 'United States'},
    city: 'Ford Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Menominee',
    utcOffset: -300,
    latitude: 45.818361,
    longitude: -88.114556,
    altitude: 360.2736
  },
  {
    iataCode: 'IND',
    icaoCode: 'KIND',
    name: 'Indianapolis',
    country: {code: 'US', name: 'United States'},
    city: 'Indianapolis Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 39.717331,
    longitude: -86.294383,
    altitude: 242.9256
  },
  {
    iataCode: 'INC',
    icaoCode: 'ZLIC',
    name: 'Yinchuan',
    country: {code: 'CN', name: 'China'},
    city: 'Yinchuan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 38.481944,
    longitude: 106.009167,
    altitude: 1097.28
  },
  {
    iataCode: 'ZOS',
    icaoCode: 'SCJO',
    name: 'Osorno',
    country: {code: 'CL', name: 'Chile'},
    city: 'Canal Bajo Carlos Hott Siebert',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -40.611208,
    longitude: -73.061042,
    altitude: 56.9976
  },
  {
    iataCode: 'INH',
    icaoCode: 'FQIN',
    name: 'Inhambane',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Inhambane',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -23.876431,
    longitude: 35.408544,
    altitude: 9.144
  },
  {
    iataCode: 'ING',
    icaoCode: 'SAWA',
    name: 'El Calafate',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Lago Argentino Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -50.3361,
    longitude: -72.2486,
    altitude: 223.1136
  },
  {
    iataCode: 'INJ',
    icaoCode: 'KINJ',
    name: 'Santa Ana',
    country: {code: 'US', name: 'United States'},
    city: 'Hillsboro Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.083486,
    longitude: -97.097228,
    altitude: 208.788
  },
  {
    iataCode: 'INI',
    icaoCode: 'LYNI',
    name: 'Nis',
    country: {code: 'RS', name: 'Serbia'},
    city: 'Nis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Belgrade',
    utcOffset: 60,
    latitude: 43.337289,
    longitude: 21.853722,
    altitude: 197.5104
  },
  {
    iataCode: 'INL',
    icaoCode: 'KINL',
    name: 'International Falls',
    country: {code: 'US', name: 'United States'},
    city: 'Falls Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 48.566186,
    longitude: -93.403067,
    altitude: 361.188
  },
  {
    iataCode: 'INK',
    icaoCode: 'KINK',
    name: 'Wink',
    country: {code: 'US', name: 'United States'},
    city: 'Winkler Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.779628,
    longitude: -103.201361,
    altitude: 860.1456
  },
  {
    iataCode: 'INN',
    icaoCode: 'LOWI',
    name: 'Innsbruck',
    country: {code: 'AT', name: 'Austria'},
    city: 'Innsbruck',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Vienna',
    utcOffset: 60,
    latitude: 47.260219,
    longitude: 11.343964,
    altitude: 580.9488
  },
  {
    iataCode: 'INO',
    icaoCode: 'FZBA',
    name: 'Inongo',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Inongo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kinshasa',
    utcOffset: 60,
    latitude: -1.94722,
    longitude: 18.2858,
    altitude: 316.992
  },
  {
    iataCode: 'ZPC',
    icaoCode: 'SCPC',
    name: 'Pucon',
    country: {code: 'CL', name: 'Chile'},
    city: 'Pucón Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -39.2928,
    longitude: -71.9159,
    altitude: 259.9944
  },
  {
    iataCode: 'INQ',
    icaoCode: 'EIIR',
    name: 'Inisheer',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Inisheer',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.0647,
    longitude: -9.5109,
    altitude: 12.192
  },
  {
    iataCode: 'ZPB',
    icaoCode: 'CZPB',
    name: 'Sachigo Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sachigo Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.8911,
    longitude: -92.1964,
    altitude: 267.0048
  },
  {
    iataCode: 'INT',
    icaoCode: 'KINT',
    name: 'Winston-salem',
    country: {code: 'US', name: 'United States'},
    city: 'Smith Reynolds',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 36.133722,
    longitude: -80.222,
    altitude: 295.3512
  },
  {
    iataCode: 'INS',
    icaoCode: 'KINS',
    name: 'Indian Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Creech Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 36.587183,
    longitude: -115.673353,
    altitude: 954.9384
  },
  {
    iataCode: 'INV',
    icaoCode: 'EGPE',
    name: 'Inverness',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Inverness',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 57.5425,
    longitude: -4.0475,
    altitude: 9.4488
  },
  {
    iataCode: 'INU',
    icaoCode: 'ANYN',
    name: 'Nauru',
    country: {code: 'NR', name: 'Nauru'},
    city: 'Nauru Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Nauru',
    utcOffset: 720,
    latitude: -0.547458,
    longitude: 166.9191,
    altitude: 6.7056
  },
  {
    iataCode: 'INW',
    icaoCode: 'KINW',
    name: 'Winslow',
    country: {code: 'US', name: 'United States'},
    city: 'Winslow-lindbergh Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 35.021917,
    longitude: -110.722528,
    altitude: 1506.0168
  },
  {
    iataCode: 'INZ',
    icaoCode: 'DAUI',
    name: 'In Salah',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'In Salah',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 27.251022,
    longitude: 2.512017,
    altitude: 273.1008
  },
  {
    iataCode: 'IOA',
    icaoCode: 'LGIO',
    name: 'Ioannina',
    country: {code: 'GR', name: 'Greece'},
    city: 'Ioannina',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 39.696388,
    longitude: 20.8225,
    altitude: 474.8784
  },
  {
    iataCode: 'IOM',
    icaoCode: 'EGNS',
    name: 'Isle Of Man',
    country: {code: 'IM', name: 'Isle of Man'},
    city: 'Isle Of Man',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Isle_of_Man',
    utcOffset: 0,
    latitude: 54.083333,
    longitude: -4.623889,
    altitude: 15.8496
  },
  {
    iataCode: 'IOS',
    icaoCode: 'SBIL',
    name: 'Ilheus',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Ilheus',
    region: '',
    terminal: '',
    timezoneId: 'America/Bahia',
    utcOffset: -180,
    latitude: -14.815964,
    longitude: -39.033197,
    altitude: 4.572
  },
  {
    iataCode: 'IOR',
    icaoCode: 'EIIM',
    name: 'Inis Mor',
    country: {code: 'IE', name: 'Ireland'},
    city: 'Inishmore Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Dublin',
    utcOffset: 0,
    latitude: 53.1067,
    longitude: -9.65361,
    altitude: 7.3152
  },
  {
    iataCode: 'ZQC',
    icaoCode: 'EDRY',
    name: 'Speyer',
    country: {code: 'DE', name: 'Germany'},
    city: 'Speyer',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.302776,
    longitude: 8.451195,
    altitude: 95.0976
  },
  {
    iataCode: 'ZQF',
    icaoCode: 'EDRT',
    name: 'Trier',
    country: {code: 'DE', name: 'Germany'},
    city: 'Trier Fohren',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.8635,
    longitude: 6.788167,
    altitude: 202.692
  },
  {
    iataCode: 'RAB',
    icaoCode: 'AYTK',
    name: '',
    country: {code: 'PG', name: 'Papua New Guinea'},
    city: 'Tokua Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Port_Moresby',
    utcOffset: 600,
    latitude: -4.34046,
    longitude: 152.38,
    altitude: 9.7536
  },
  {
    iataCode: 'ZQL',
    icaoCode: 'EDTD',
    name: 'Donaueschingen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Donaueschingen Villingen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 47.973331,
    longitude: 8.522223,
    altitude: 680.0088
  },
  {
    iataCode: 'ZQN',
    icaoCode: 'NZQN',
    name: 'Queenstown International',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Queenstown',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -45.021111,
    longitude: 168.739167,
    altitude: 356.9208
  },
  {
    iataCode: 'RAE',
    icaoCode: 'OERR',
    name: 'Arar',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Arar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 30.906589,
    longitude: 41.138217,
    altitude: 552.6024
  },
  {
    iataCode: 'RAH',
    icaoCode: 'OERF',
    name: 'Rafha',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Rafha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 29.626419,
    longitude: 43.490614,
    altitude: 449.2752
  },
  {
    iataCode: 'RAJ',
    icaoCode: 'VARK',
    name: 'Rajkot',
    country: {code: 'IN', name: 'India'},
    city: 'Rajkot',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.309183,
    longitude: 70.779525,
    altitude: 134.4168
  },
  {
    iataCode: 'IPA',
    icaoCode: 'NVVI',
    name: 'Ipota',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Ipota Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -18.8783,
    longitude: 169.308,
    altitude: 7.0104
  },
  {
    iataCode: 'RAI',
    icaoCode: 'GVNP',
    name: 'Praia - Santiago Island',
    country: {code: 'CV', name: 'Cape Verde'},
    city: 'Praia International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Cape_Verde',
    utcOffset: -60,
    latitude: 14.9245,
    longitude: -23.4935,
    altitude: 70.104
  },
  {
    iataCode: 'RAL',
    icaoCode: 'KRAL',
    name: 'Riverside',
    country: {code: 'US', name: 'United States'},
    city: 'Riverside Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.951875,
    longitude: -117.445103,
    altitude: 249.3264
  },
  {
    iataCode: 'IPC',
    icaoCode: 'SCIP',
    name: 'Easter Island',
    country: {code: 'CL', name: 'Chile'},
    city: 'Mataveri Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Easter',
    utcOffset: -360,
    latitude: -27.164792,
    longitude: -109.421831,
    altitude: 69.1896
  },
  {
    iataCode: 'RAK',
    icaoCode: 'GMMX',
    name: 'Marrakech',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Menara',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 31.606886,
    longitude: -8.0363,
    altitude: 470.916
  },
  {
    iataCode: 'RAM',
    icaoCode: 'YRNG',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Ramingining Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -12.3564,
    longitude: 134.898,
    altitude: 62.7888
  },
  {
    iataCode: 'IPH',
    icaoCode: 'WMKI',
    name: 'Ipoh',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Azlan Shah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 4.567972,
    longitude: 101.092194,
    altitude: 39.624
  },
  {
    iataCode: 'RAP',
    icaoCode: 'KRAP',
    name: 'Rapid City',
    country: {code: 'US', name: 'United States'},
    city: 'Rapid City Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.045278,
    longitude: -103.057222,
    altitude: 976.5792
  },
  {
    iataCode: 'RAO',
    icaoCode: 'SBRP',
    name: 'Ribeirao Preto',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Leite Lopes',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.134167,
    longitude: -47.774189,
    altitude: 549.2496
  },
  {
    iataCode: 'RAR',
    icaoCode: 'NCRG',
    name: 'Avarua',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Rarotonga Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -21.202739,
    longitude: -159.805556,
    altitude: 5.7912
  },
  {
    iataCode: 'IPI',
    icaoCode: 'SKIP',
    name: 'Ipiales',
    country: {code: 'CO', name: 'Colombia'},
    city: 'San Luis',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 0.861925,
    longitude: -77.671764,
    altitude: 2976.372
  },
  {
    iataCode: 'IPL',
    icaoCode: 'KIPL',
    name: 'Imperial',
    country: {code: 'US', name: 'United States'},
    city: 'Imperial Co',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.834219,
    longitude: -115.578744,
    altitude: -16.4592
  },
  {
    iataCode: 'RAT',
    icaoCode: 'USNR',
    name: 'Raduzhnyi',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Raduzhny Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 62.1586,
    longitude: 77.3289,
    altitude: 76.2
  },
  {
    iataCode: 'RAS',
    icaoCode: 'OIGG',
    name: 'Rasht',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Rasht',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 37.325314,
    longitude: 49.605817,
    altitude: -12.192
  },
  {
    iataCode: 'IPN',
    icaoCode: 'SBIP',
    name: 'Ipatinga',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Usiminas',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -19.470722,
    longitude: -42.487583,
    altitude: 238.9632
  },
  {
    iataCode: 'RAZ',
    icaoCode: 'OPRT',
    name: 'Rawala Kot',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Rawalakot',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 33.849658,
    longitude: 73.798147,
    altitude: 1669.9992
  },
  {
    iataCode: 'IPT',
    icaoCode: 'KIPT',
    name: 'Williamsport',
    country: {code: 'US', name: 'United States'},
    city: 'Williamsport Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.241836,
    longitude: -76.921094,
    altitude: 161.2392
  },
  {
    iataCode: 'RBA',
    icaoCode: 'GMME',
    name: 'Rabat',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Sale',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 34.051467,
    longitude: -6.751519,
    altitude: 84.1248
  },
  {
    iataCode: 'ZRH',
    icaoCode: 'LSZH',
    name: 'Zurich',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'Zurich',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 47.464722,
    longitude: 8.549167,
    altitude: 431.5968
  },
  {
    iataCode: 'RBB',
    icaoCode: 'SWBR',
    name: 'Borba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Borba Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -4.387778,
    longitude: -59.593889,
    altitude: 152.4
  },
  {
    iataCode: 'ZRJ',
    icaoCode: 'CZRJ',
    name: 'Round Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Round Lake (Weagamow Lake) Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 52.9436,
    longitude: -91.3128,
    altitude: 296.8752
  },
  {
    iataCode: 'RBM',
    icaoCode: 'KRBM',
    name: 'Robinson',
    country: {code: 'US', name: 'United States'},
    city: 'Robinson Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.850089,
    longitude: -92.300153,
    altitude: 178.9176
  },
  {
    iataCode: 'RBQ',
    icaoCode: 'SLRQ',
    name: 'Rerrenabaque',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Rurrenabaque Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -180,
    latitude: -14.4275,
    longitude: -67.498056,
    altitude: 273.7104
  },
  {
    iataCode: 'AAA',
    icaoCode: 'NTGA',
    name: 'Anaa',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Anaa',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -17.352606,
    longitude: -145.509956,
    altitude: 3.048
  },
  {
    iataCode: 'AAC',
    icaoCode: 'HEAR',
    name: 'El Arish',
    country: {code: 'EG', name: 'Egypt'},
    city: 'El Arish International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 31.073333,
    longitude: 33.835833,
    altitude: 36.8808
  },
  {
    iataCode: 'RBR',
    icaoCode: 'SBRB',
    name: 'Rio Branco',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Presidente Medici',
    region: '',
    terminal: '',
    timezoneId: 'America/Rio_Branco',
    utcOffset: -300,
    latitude: -9.869158,
    longitude: -67.894072,
    altitude: 192.9384
  },
  {
    iataCode: 'IQM',
    icaoCode: 'ZWCM',
    name: 'Qiemo',
    country: {code: 'CN', name: 'China'},
    city: 'Qiemo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 38.1494,
    longitude: 85.5328,
    altitude: 1252.1184
  },
  {
    iataCode: 'AAE',
    icaoCode: 'DABB',
    name: 'Annaba',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Annaba',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.822225,
    longitude: 7.809167,
    altitude: 4.8768
  },
  {
    iataCode: 'IQN',
    icaoCode: 'ZLQY',
    name: 'Qingyang',
    country: {code: 'CN', name: 'China'},
    city: 'Qingyang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 35.7997,
    longitude: 107.603,
    altitude: 0.0
  },
  {
    iataCode: 'RBV',
    icaoCode: 'AGRM',
    name: 'Ramata',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Ramata Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.16806,
    longitude: 157.643,
    altitude: 0.0
  },
  {
    iataCode: 'AAH',
    icaoCode: 'EDKA',
    name: 'Aachen',
    country: {code: 'DE', name: 'Germany'},
    city: 'Flugplatz Merzbrueck',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.823194,
    longitude: 6.186389,
    altitude: 173.736
  },
  {
    iataCode: 'IQQ',
    icaoCode: 'SCDA',
    name: 'Iquique',
    country: {code: 'CL', name: 'Chile'},
    city: 'Diego Aracena Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -20.535222,
    longitude: -70.181275,
    altitude: 47.244
  },
  {
    iataCode: 'RBY',
    icaoCode: 'PARY',
    name: 'Ruby',
    country: {code: 'US', name: 'United States'},
    city: 'Ruby Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 64.7272,
    longitude: -155.47,
    altitude: 199.0344
  },
  {
    iataCode: 'RBX',
    icaoCode: 'HSMK',
    name: 'Rumbek',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Rumbek Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Juba',
    utcOffset: 180,
    latitude: 6.825,
    longitude: 29.669,
    altitude: 609.6
  },
  {
    iataCode: 'ZSA',
    icaoCode: 'MYSM',
    name: 'Cockburn Town',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'San Salvador',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.063275,
    longitude: -74.523967,
    altitude: 7.3152
  },
  {
    iataCode: 'AAK',
    icaoCode: 'NGUK',
    name: 'Buariki',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Aranuka Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 600,
    latitude: 0.185278,
    longitude: 173.636389,
    altitude: 1.8288
  },
  {
    iataCode: 'AAL',
    icaoCode: 'EKYT',
    name: 'Aalborg',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Aalborg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 57.092789,
    longitude: 9.849164,
    altitude: 3.048
  },
  {
    iataCode: 'AAM',
    icaoCode: 'FAMD',
    name: 'Malamala',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Malamala Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -24.818111,
    longitude: 31.544584,
    altitude: 342.5952
  },
  {
    iataCode: 'IQT',
    icaoCode: 'SPQT',
    name: 'Iquitos',
    country: {code: 'PE', name: 'Peru'},
    city: 'Coronel Francisco Secada Vignetta Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -3.784739,
    longitude: -73.308806,
    altitude: 93.2688
  },
  {
    iataCode: 'ZSE',
    icaoCode: 'FMEP',
    name: 'St.-pierre',
    country: {code: 'RE', name: 'Réunion'},
    city: 'St Pierre Pierrefonds',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Reunion',
    utcOffset: 300,
    latitude: -21.320039,
    longitude: 55.423581,
    altitude: 17.0688
  },
  {
    iataCode: 'AAN',
    icaoCode: 'OMAL',
    name: 'Al Ain',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Al Ain International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 24.261667,
    longitude: 55.609167,
    altitude: 264.8712
  },
  {
    iataCode: 'AAO',
    icaoCode: 'SVAN',
    name: 'Anaco',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Anaco',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.430225,
    longitude: -64.470725,
    altitude: 219.7608
  },
  {
    iataCode: 'RCB',
    icaoCode: 'FARB',
    name: "Richard's Bay",
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Richards Bay',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.741039,
    longitude: 32.092111,
    altitude: 33.2232
  },
  {
    iataCode: 'ZSJ',
    icaoCode: 'CZSJ',
    name: 'Sandy Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Sandy Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 53.0642,
    longitude: -93.3444,
    altitude: 289.8648
  },
  {
    iataCode: 'AAQ',
    icaoCode: 'URKA',
    name: 'Anapa',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Vityazevo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 45.002097,
    longitude: 37.347272,
    altitude: 53.0352
  },
  {
    iataCode: 'RCA',
    icaoCode: 'KRCA',
    name: 'Rapid City',
    country: {code: 'US', name: 'United States'},
    city: 'Ellsworth Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 44.145042,
    longitude: -103.103567,
    altitude: 999.4392
  },
  {
    iataCode: 'AAR',
    icaoCode: 'EKAH',
    name: 'Aarhus',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Aarhus',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 56.300017,
    longitude: 10.619008,
    altitude: 24.9936
  },
  {
    iataCode: 'AAT',
    icaoCode: 'ZWAT',
    name: 'Altay',
    country: {code: 'CN', name: 'China'},
    city: 'Altay Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Urumqi',
    utcOffset: 480,
    latitude: 47.866667,
    longitude: 88.116667,
    altitude: 759.2568
  },
  {
    iataCode: 'RCH',
    icaoCode: 'SKRH',
    name: 'Rio Hacha',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Almirante Padilla',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 11.526222,
    longitude: -72.925958,
    altitude: 13.1064
  },
  {
    iataCode: 'AAX',
    icaoCode: 'SBAX',
    name: 'Araxa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Araxa Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -19.563056,
    longitude: -46.960278,
    altitude: 998.5248
  },
  {
    iataCode: 'AAY',
    icaoCode: 'OYGD',
    name: 'Al Ghaidah Intl',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Al Ghaidah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 16.191667,
    longitude: 52.175,
    altitude: 40.8432
  },
  {
    iataCode: 'IRA',
    icaoCode: 'AGGK',
    name: 'Kirakira',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Ngorangora Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -10.4497,
    longitude: 161.898,
    altitude: 0.0
  },
  {
    iataCode: 'AAZ',
    icaoCode: 'MGQZ',
    name: 'Quezaltenango',
    country: {code: 'GT', name: 'Guatemala'},
    city: 'Quezaltenango Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Guatemala',
    utcOffset: -360,
    latitude: 14.8656,
    longitude: -91.502,
    altitude: 2371.0392
  },
  {
    iataCode: 'IRD',
    icaoCode: 'VGIS',
    name: 'Ishurdi',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Ishurdi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 24.1525,
    longitude: 89.049446,
    altitude: 13.716
  },
  {
    iataCode: 'RCL',
    icaoCode: 'NVSR',
    name: 'Redcliffe',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Redcliffe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -15.472,
    longitude: 167.835,
    altitude: 10.9728
  },
  {
    iataCode: 'IRC',
    icaoCode: 'PACR',
    name: 'Circle',
    country: {code: 'US', name: 'United States'},
    city: 'Circle City Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 65.827778,
    longitude: -144.076111,
    altitude: 186.8424
  },
  {
    iataCode: 'RCM',
    icaoCode: 'YSRI',
    name: 'Richmond',
    country: {code: 'AU', name: 'Australia'},
    city: 'Richmond',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -33.600556,
    longitude: 150.780833,
    altitude: 20.4216
  },
  {
    iataCode: 'IRG',
    icaoCode: 'YLHR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Lockhart River Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -12.7869,
    longitude: 143.305,
    altitude: 23.4696
  },
  {
    iataCode: 'RCO',
    icaoCode: 'LFDN',
    name: 'Rochefort',
    country: {code: 'FR', name: 'France'},
    city: 'St Agnant',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.887779,
    longitude: -0.983056,
    altitude: 18.288
  },
  {
    iataCode: 'ZSW',
    icaoCode: 'CZSW',
    name: 'Prince Rupert',
    country: {code: 'CA', name: 'Canada'},
    city: 'Seal Cove Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Vancouver',
    utcOffset: -480,
    latitude: 54.3333,
    longitude: -130.283,
    altitude: 0.0
  },
  {
    iataCode: 'ABA',
    icaoCode: 'UNAA',
    name: 'Abakan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Abakan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Krasnoyarsk',
    utcOffset: 420,
    latitude: 53.74,
    longitude: 91.385,
    altitude: 253.2888
  },
  {
    iataCode: 'IRJ',
    icaoCode: 'SANL',
    name: 'La Rioja',
    country: {code: 'AR', name: 'Argentina'},
    city: 'La Rioja',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/La_Rioja',
    utcOffset: -180,
    latitude: -29.381636,
    longitude: -66.795839,
    altitude: 437.6928
  },
  {
    iataCode: 'IRI',
    icaoCode: 'HTIR',
    name: 'Iringa',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Iringa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -7.668633,
    longitude: 35.752114,
    altitude: 1425.8544
  },
  {
    iataCode: 'ZSY',
    icaoCode: 'KSDL',
    name: 'Scottsdale',
    country: {code: 'US', name: 'United States'},
    city: 'Scottsdale Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 33.622889,
    longitude: -111.910528,
    altitude: 462.9912
  },
  {
    iataCode: 'ABD',
    icaoCode: 'OIAA',
    name: 'Abadan',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Abadan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 30.371111,
    longitude: 48.228333,
    altitude: 5.7912
  },
  {
    iataCode: 'IRK',
    icaoCode: 'KIRK',
    name: 'Kirksville',
    country: {code: 'US', name: 'United States'},
    city: 'Kirksville Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 40.0935,
    longitude: -92.5449,
    altitude: 294.4368
  },
  {
    iataCode: 'ABE',
    icaoCode: 'KABE',
    name: 'Allentown',
    country: {code: 'US', name: 'United States'},
    city: 'Lehigh Valley Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.652083,
    longitude: -75.440806,
    altitude: 119.7864
  },
  {
    iataCode: 'ABF',
    icaoCode: 'NGAB',
    name: 'Abaiang Atoll',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Abaiang Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: -720,
    latitude: 1.8,
    longitude: 173.04,
    altitude: 0.3048
  },
  {
    iataCode: 'RCU',
    icaoCode: 'SAOC',
    name: 'Rio Cuarto',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Rio Cuarto Area De Material',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -33.085128,
    longitude: -64.261314,
    altitude: 420.9288
  },
  {
    iataCode: 'IRP',
    icaoCode: 'FZJH',
    name: 'Isiro',
    country: {code: 'CD', name: 'The Democratic Republic of the Congo'},
    city: 'Matari',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lubumbashi',
    utcOffset: 120,
    latitude: 2.827606,
    longitude: 27.588253,
    altitude: 743.1024
  },
  {
    iataCode: 'ABI',
    icaoCode: 'KABI',
    name: 'Abilene',
    country: {code: 'US', name: 'United States'},
    city: 'Abilene Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.411319,
    longitude: -99.681897,
    altitude: 545.8968
  },
  {
    iataCode: 'ABJ',
    icaoCode: 'DIAP',
    name: 'Abidjan',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Abidjan Felix Houphouet Boigny Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 5.261386,
    longitude: -3.926294,
    altitude: 6.4008
  },
  {
    iataCode: 'ZTB',
    icaoCode: 'CTB6',
    name: 'Tête-à-la-baleine',
    country: {code: 'CA', name: 'Canada'},
    city: 'Tête-à-la-baleine Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Blanc-Sablon',
    utcOffset: -240,
    latitude: 50.6744,
    longitude: -59.3836,
    altitude: 32.6136
  },
  {
    iataCode: 'ABK',
    icaoCode: 'HAKD',
    name: 'Kabri Dehar',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Kabri Dehar Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 6.734,
    longitude: 44.253,
    altitude: 548.64
  },
  {
    iataCode: 'ABL',
    icaoCode: 'PAFM',
    name: 'Ambler',
    country: {code: 'US', name: 'United States'},
    city: 'Ambler Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 67.106389,
    longitude: -157.8575,
    altitude: 101.8032
  },
  {
    iataCode: 'ABM',
    icaoCode: 'YBAM',
    name: 'Amberley',
    country: {code: 'AU', name: 'Australia'},
    city: 'Bamaga Injinoo',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -10.950833,
    longitude: 142.459444,
    altitude: 10.3632
  },
  {
    iataCode: 'ABN',
    icaoCode: 'SMBN',
    name: 'Albina',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Albina Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.516667,
    longitude: -54.05,
    altitude: 3.048
  },
  {
    iataCode: 'ZTH',
    icaoCode: 'LGZA',
    name: 'Zakynthos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Dionysios Solomos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.750853,
    longitude: 20.88425,
    altitude: 4.572
  },
  {
    iataCode: 'ABQ',
    icaoCode: 'KABQ',
    name: 'Albuquerque',
    country: {code: 'US', name: 'United States'},
    city: 'Albuquerque International Sunport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.040222,
    longitude: -106.609194,
    altitude: 1632.204
  },
  {
    iataCode: 'ABR',
    icaoCode: 'KABR',
    name: 'Aberdeen',
    country: {code: 'US', name: 'United States'},
    city: 'Aberdeen Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.4491,
    longitude: -98.4218,
    altitude: 396.8496
  },
  {
    iataCode: 'ABS',
    icaoCode: 'HEBL',
    name: 'Abu Simbel',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Abu Simbel',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 22.375953,
    longitude: 31.611722,
    altitude: 187.7568
  },
  {
    iataCode: 'ZTM',
    icaoCode: 'CZTM',
    name: 'Shamattawa',
    country: {code: 'CA', name: 'Canada'},
    city: 'Shamattawa Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Winnipeg',
    utcOffset: -360,
    latitude: 55.8656,
    longitude: -92.0814,
    altitude: 88.0872
  },
  {
    iataCode: 'ABT',
    icaoCode: 'OEBA',
    name: 'El-baha',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Al Baha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 20.296139,
    longitude: 41.634277,
    altitude: 1672.1328
  },
  {
    iataCode: 'RDD',
    icaoCode: 'KRDD',
    name: 'Redding',
    country: {code: 'US', name: 'United States'},
    city: 'Redding Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 40.509,
    longitude: -122.293389,
    altitude: 153.6192
  },
  {
    iataCode: 'RDG',
    icaoCode: 'KRDG',
    name: 'Reading',
    country: {code: 'US', name: 'United States'},
    city: 'Reading Regional Carl A Spaatz Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.3785,
    longitude: -75.9652,
    altitude: 104.8512
  },
  {
    iataCode: 'ABV',
    icaoCode: 'DNAA',
    name: 'Abuja',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Nnamdi Azikiwe Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 9.006792,
    longitude: 7.263172,
    altitude: 342.2904
  },
  {
    iataCode: 'ISA',
    icaoCode: 'YBMA',
    name: 'Mount Isa',
    country: {code: 'AU', name: 'Australia'},
    city: 'Mount Isa',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.663889,
    longitude: 139.488611,
    altitude: 341.6808
  },
  {
    iataCode: 'ABX',
    icaoCode: 'YMAY',
    name: 'Albury',
    country: {code: 'AU', name: 'Australia'},
    city: 'Albury',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -36.067778,
    longitude: 146.958056,
    altitude: 164.2872
  },
  {
    iataCode: 'ABY',
    icaoCode: 'KABY',
    name: 'Albany',
    country: {code: 'US', name: 'United States'},
    city: 'Southwest Georgia Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 31.5355,
    longitude: -84.1945,
    altitude: 60.0456
  },
  {
    iataCode: 'ABZ',
    icaoCode: 'EGPD',
    name: 'Aberdeen',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Dyce',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 57.201944,
    longitude: -2.197778,
    altitude: 65.532
  },
  {
    iataCode: 'ISB',
    icaoCode: 'OPRN',
    name: 'Islamabad',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Chaklala',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 33.616653,
    longitude: 73.099233,
    altitude: 508.4064
  },
  {
    iataCode: 'ISE',
    icaoCode: 'LTFC',
    name: 'Isparta',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Isparta Süleyman Demirel Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.8554,
    longitude: 30.3684,
    altitude: 864.108
  },
  {
    iataCode: 'RDM',
    icaoCode: 'KRDM',
    name: 'Redmond-bend',
    country: {code: 'US', name: 'United States'},
    city: 'Roberts Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 44.254066,
    longitude: -121.149964,
    altitude: 937.8696
  },
  {
    iataCode: 'ISG',
    icaoCode: 'ROIG',
    name: 'Ishigaki',
    country: {code: 'JP', name: 'Japan'},
    city: 'Ishigaki',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 24.344525,
    longitude: 124.186983,
    altitude: 28.3464
  },
  {
    iataCode: 'RDN',
    icaoCode: 'WMPR',
    name: 'Redang',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Redang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 5.76528,
    longitude: 103.007,
    altitude: 3.048
  },
  {
    iataCode: 'ACA',
    icaoCode: 'MMAA',
    name: 'Acapulco',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Juan N Alvarez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 16.757061,
    longitude: -99.753953,
    altitude: 4.8768
  },
  {
    iataCode: 'ISK',
    icaoCode: 'VANR',
    name: 'Nasik Road',
    country: {code: 'IN', name: 'India'},
    city: 'Nasik Road',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 19.963739,
    longitude: 73.807644,
    altitude: 597.1032
  },
  {
    iataCode: 'ACC',
    icaoCode: 'DGAA',
    name: 'Accra',
    country: {code: 'GH', name: 'Ghana'},
    city: 'Kotoka Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Accra',
    utcOffset: 0,
    latitude: 5.605186,
    longitude: -0.166786,
    altitude: 62.484
  },
  {
    iataCode: 'ISJ',
    icaoCode: 'MMIM',
    name: 'Isla Mujeres',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Isla Mujeres',
    region: '',
    terminal: '',
    timezoneId: 'America/Cancun',
    utcOffset: -360,
    latitude: 21.245033,
    longitude: -86.739967,
    altitude: 2.1336
  },
  {
    iataCode: 'RDR',
    icaoCode: 'KRDR',
    name: 'Red River',
    country: {code: 'US', name: 'United States'},
    city: 'Grand Forks Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 47.961098,
    longitude: -97.401194,
    altitude: 278.2824
  },
  {
    iataCode: 'ACD',
    icaoCode: 'SKAD',
    name: 'Acandi',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Alcides Fernandez Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 8.516667,
    longitude: -77.3,
    altitude: 15.24
  },
  {
    iataCode: 'ISM',
    icaoCode: 'KISM',
    name: 'Kissimmee',
    country: {code: 'US', name: 'United States'},
    city: 'Kissimmee Gateway Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 28.289806,
    longitude: -81.437083,
    altitude: 24.9936
  },
  {
    iataCode: 'RDU',
    icaoCode: 'KRDU',
    name: 'Raleigh-durham',
    country: {code: 'US', name: 'United States'},
    city: 'Raleigh Durham Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.877639,
    longitude: -78.787472,
    altitude: 132.588
  },
  {
    iataCode: 'ACE',
    icaoCode: 'GCRR',
    name: 'Lanzerote',
    country: {code: 'ES', name: 'Spain'},
    city: 'Lanzarote',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Canary',
    utcOffset: 0,
    latitude: 28.945464,
    longitude: -13.605225,
    altitude: 14.3256
  },
  {
    iataCode: 'ISO',
    icaoCode: 'KISO',
    name: 'Kinston',
    country: {code: 'US', name: 'United States'},
    city: 'Kinston Regional Jetport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: 420,
    latitude: 35.331389,
    longitude: -77.608889,
    altitude: 28.6512
  },
  {
    iataCode: 'ISN',
    icaoCode: 'KISN',
    name: 'Williston',
    country: {code: 'US', name: 'United States'},
    city: 'Sloulin Fld Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -420,
    latitude: 48.177939,
    longitude: -103.642347,
    altitude: 604.1136
  },
  {
    iataCode: 'ACH',
    icaoCode: 'LSZR',
    name: 'Altenrhein',
    country: {code: 'CH', name: 'Switzerland'},
    city: 'St Gallen Altenrhein',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zurich',
    utcOffset: 60,
    latitude: 47.485033,
    longitude: 9.560775,
    altitude: 398.0688
  },
  {
    iataCode: 'ACI',
    icaoCode: 'EGJA',
    name: 'Alderney',
    country: {code: 'GG', name: 'Guernsey'},
    city: 'Alderney',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 49.706111,
    longitude: -2.214722,
    altitude: 88.392
  },
  {
    iataCode: 'ISP',
    icaoCode: 'KISP',
    name: 'Islip',
    country: {code: 'US', name: 'United States'},
    city: 'Long Island Mac Arthur',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.79525,
    longitude: -73.100222,
    altitude: 30.1752
  },
  {
    iataCode: 'ACK',
    icaoCode: 'KACK',
    name: 'Nantucket',
    country: {code: 'US', name: 'United States'},
    city: 'Nantucket Mem',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.253053,
    longitude: -70.060181,
    altitude: 14.6304
  },
  {
    iataCode: 'RDZ',
    icaoCode: 'LFCR',
    name: 'Rodez',
    country: {code: 'FR', name: 'France'},
    city: 'Marcillac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.407869,
    longitude: 2.482672,
    altitude: 582.168
  },
  {
    iataCode: 'IST',
    icaoCode: 'LTBA',
    name: 'Istanbul',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Ataturk',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.976922,
    longitude: 28.814606,
    altitude: 49.6824
  },
  {
    iataCode: 'ZUH',
    icaoCode: 'ZGSD',
    name: 'Zhuhai',
    country: {code: 'CN', name: 'China'},
    city: 'Zhuhai Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 22.0064,
    longitude: 113.376,
    altitude: 0.0
  },
  {
    iataCode: 'ACP',
    icaoCode: 'OITM',
    name: 'Maragheh',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Sahand Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 37.348017,
    longitude: 46.127903,
    altitude: 1339.9008
  },
  {
    iataCode: 'REA',
    icaoCode: 'NTGE',
    name: 'Reao',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Reao',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -18.465861,
    longitude: -136.439706,
    altitude: 3.6576
  },
  {
    iataCode: 'ACR',
    icaoCode: 'SKAC',
    name: 'Araracuara',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Araracuara Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: -0.58,
    longitude: -72.41,
    altitude: 381.0
  },
  {
    iataCode: 'REC',
    icaoCode: 'SBRF',
    name: 'Recife',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Guararapes Gilberto Freyre Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Recife',
    utcOffset: -180,
    latitude: -8.126794,
    longitude: -34.923039,
    altitude: 10.0584
  },
  {
    iataCode: 'ACT',
    icaoCode: 'KACT',
    name: 'Waco',
    country: {code: 'US', name: 'United States'},
    city: 'Waco Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.611289,
    longitude: -97.230519,
    altitude: 157.2768
  },
  {
    iataCode: 'ZUN',
    icaoCode: 'KZUN',
    name: 'Zuni Pueblo',
    country: {code: 'US', name: 'United States'},
    city: 'Black Rock',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.083228,
    longitude: -108.791778,
    altitude: 1967.1792
  },
  {
    iataCode: 'ZUM',
    icaoCode: 'CZUM',
    name: 'Churchill Falls',
    country: {code: 'CA', name: 'Canada'},
    city: 'Churchill Falls Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Goose_Bay',
    utcOffset: -240,
    latitude: 53.5619,
    longitude: -64.1064,
    altitude: 439.5216
  },
  {
    iataCode: 'ACV',
    icaoCode: 'KACV',
    name: 'Arcata Ca',
    country: {code: 'US', name: 'United States'},
    city: 'Arcata',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 40.978111,
    longitude: -124.108611,
    altitude: 67.3608
  },
  {
    iataCode: 'REG',
    icaoCode: 'LICR',
    name: 'Reggio Calabria',
    country: {code: 'IT', name: 'Italy'},
    city: 'Reggio Calabria',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 38.071206,
    longitude: 15.651556,
    altitude: 29.2608
  },
  {
    iataCode: 'ACY',
    icaoCode: 'KACY',
    name: 'Atlantic City',
    country: {code: 'US', name: 'United States'},
    city: 'Atlantic City Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.457583,
    longitude: -74.577167,
    altitude: 22.86
  },
  {
    iataCode: 'REL',
    icaoCode: 'SAVT',
    name: 'Trelew',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Almirante Zar',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -43.2105,
    longitude: -65.270319,
    altitude: 42.9768
  },
  {
    iataCode: 'REN',
    icaoCode: 'UWOO',
    name: 'Orenburg',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Orenburg',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Yekaterinburg',
    utcOffset: 300,
    latitude: 51.795786,
    longitude: 55.456744,
    altitude: 117.9576
  },
  {
    iataCode: 'ITH',
    icaoCode: 'KITH',
    name: 'Ithaca',
    country: {code: 'US', name: 'United States'},
    city: 'Ithaca Tompkins Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.491028,
    longitude: -76.458444,
    altitude: 334.9752
  },
  {
    iataCode: 'REP',
    icaoCode: 'VDSR',
    name: 'Siem-reap',
    country: {code: 'KH', name: 'Cambodia'},
    city: 'Siem Reap',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Phnom_Penh',
    utcOffset: 420,
    latitude: 13.410666,
    longitude: 103.81284,
    altitude: 18.288
  },
  {
    iataCode: 'ADA',
    icaoCode: 'LTAF',
    name: 'Adana',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Adana',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 36.982166,
    longitude: 35.280388,
    altitude: 19.812
  },
  {
    iataCode: 'ADB',
    icaoCode: 'LTBJ',
    name: 'Izmir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Adnan Menderes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.292392,
    longitude: 27.156953,
    altitude: 125.5776
  },
  {
    iataCode: 'RET',
    icaoCode: 'ENRS',
    name: '',
    country: {code: 'NO', name: 'Norway'},
    city: 'Røst Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 67.5278,
    longitude: 12.1033,
    altitude: 2.1336
  },
  {
    iataCode: 'ADD',
    icaoCode: 'HAAB',
    name: 'Addis Ababa',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Bole Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 8.977889,
    longitude: 38.799319,
    altitude: 2333.5488
  },
  {
    iataCode: 'RES',
    icaoCode: 'SARE',
    name: 'Resistencia',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Resistencia',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -27.449986,
    longitude: -59.056125,
    altitude: 52.7304
  },
  {
    iataCode: 'ADE',
    icaoCode: 'OYAA',
    name: 'Aden',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Aden Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 12.829542,
    longitude: 45.028792,
    altitude: 2.1336
  },
  {
    iataCode: 'ADF',
    icaoCode: 'LTCP',
    name: '',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Adiyaman Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.7314,
    longitude: 38.4689,
    altitude: 675.4368
  },
  {
    iataCode: 'ITM',
    icaoCode: 'RJOO',
    name: 'Osaka',
    country: {code: 'JP', name: 'Japan'},
    city: 'Osaka Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.785528,
    longitude: 135.438222,
    altitude: 15.24
  },
  {
    iataCode: 'REU',
    icaoCode: 'LERS',
    name: 'Reus',
    country: {code: 'ES', name: 'Spain'},
    city: 'Reus',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 41.147392,
    longitude: 1.167172,
    altitude: 71.3232
  },
  {
    iataCode: 'REX',
    icaoCode: 'MMRX',
    name: 'Reynosa',
    country: {code: 'MX', name: 'Mexico'},
    city: 'General Lucio Blanco Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Matamoros',
    utcOffset: -360,
    latitude: 26.008908,
    longitude: -98.228513,
    altitude: 42.3672
  },
  {
    iataCode: 'ZVA',
    icaoCode: 'FMMN',
    name: 'Miandrivazo',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Miandrivazo',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -19.562778,
    longitude: 45.450832,
    altitude: 61.8744
  },
  {
    iataCode: 'ITO',
    icaoCode: 'PHTO',
    name: 'Hilo',
    country: {code: 'US', name: 'United States'},
    city: 'Hilo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 19.721375,
    longitude: -155.048469,
    altitude: 11.5824
  },
  {
    iataCode: 'ADJ',
    icaoCode: 'OJAM',
    name: 'Amman',
    country: {code: 'JO', name: 'Jordan'},
    city: 'Marka Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Amman',
    utcOffset: 120,
    latitude: 31.972703,
    longitude: 35.991569,
    altitude: 778.764
  },
  {
    iataCode: 'REY',
    icaoCode: 'SLRY',
    name: 'Reyes',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Reyes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -14.3044,
    longitude: -67.3534,
    altitude: 284.988
  },
  {
    iataCode: 'ADK',
    icaoCode: 'PADK',
    name: 'Adak Island',
    country: {code: 'US', name: 'United States'},
    city: 'Adak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -600,
    latitude: 51.878,
    longitude: -176.646,
    altitude: 5.4864
  },
  {
    iataCode: 'ADL',
    icaoCode: 'YPAD',
    name: 'Adelaide',
    country: {code: 'AU', name: 'Australia'},
    city: 'Adelaide Intl',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Adelaide',
    utcOffset: 540,
    latitude: -34.945,
    longitude: 138.530556,
    altitude: 6.096
  },
  {
    iataCode: 'ADM',
    icaoCode: 'KADM',
    name: 'Ardmore',
    country: {code: 'US', name: 'United States'},
    city: 'Ardmore Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 34.300833,
    longitude: -97.008889,
    altitude: 232.2576
  },
  {
    iataCode: 'ADQ',
    icaoCode: 'PADQ',
    name: 'Kodiak',
    country: {code: 'US', name: 'United States'},
    city: 'Kodiak',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 57.749967,
    longitude: -152.493856,
    altitude: 23.7744
  },
  {
    iataCode: 'ZVK',
    icaoCode: 'VLSK',
    name: 'Savannakhet',
    country: {code: 'LA', name: "Lao People's Democratic Republic"},
    city: 'Savannakhet',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Vientiane',
    utcOffset: 420,
    latitude: 16.556594,
    longitude: 104.759531,
    altitude: 155.1432
  },
  {
    iataCode: 'ADS',
    icaoCode: 'KADS',
    name: 'Addison',
    country: {code: 'US', name: 'United States'},
    city: 'Addison',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -300,
    latitude: 32.968559,
    longitude: -96.836448,
    altitude: 196.2912
  },
  {
    iataCode: 'RFD',
    icaoCode: 'KRFD',
    name: 'Rockford',
    country: {code: 'US', name: 'United States'},
    city: 'Chicago Rockford International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.195361,
    longitude: -89.097222,
    altitude: 226.1616
  },
  {
    iataCode: 'ADU',
    icaoCode: 'OITL',
    name: 'Ardabil',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Ardabil Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 38.325678,
    longitude: 48.424356,
    altitude: 1315.212
  },
  {
    iataCode: 'ADW',
    icaoCode: 'KADW',
    name: 'Camp Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Andrews Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.810806,
    longitude: -76.867028,
    altitude: 85.344
  },
  {
    iataCode: 'ADX',
    icaoCode: 'EGQL',
    name: 'Leuchars',
    country: {code: 'GB', name: 'United Kingdom'},
    city: 'Leuchars',
    region: '',
    terminal: '',
    timezoneId: 'Europe/London',
    utcOffset: 0,
    latitude: 56.372889,
    longitude: -2.868444,
    altitude: 11.5824
  },
  {
    iataCode: 'ADZ',
    icaoCode: 'SKSP',
    name: 'San Andres Island',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Gustavo Rojas Pinilla',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 12.583594,
    longitude: -81.711192,
    altitude: 5.7912
  },
  {
    iataCode: 'IUE',
    icaoCode: 'NIUE',
    name: 'Alofi',
    country: {code: 'NU', name: 'Niue'},
    city: 'Niue International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Niue',
    utcOffset: -660,
    latitude: -19.080028,
    longitude: -169.925639,
    altitude: 63.7032
  },
  {
    iataCode: 'IUD',
    icaoCode: 'OTBH',
    name: 'Doha',
    country: {code: 'QA', name: 'Qatar'},
    city: 'Al Udeid Ab',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Qatar',
    utcOffset: 180,
    latitude: 25.1174,
    longitude: 51.3228,
    altitude: 39.624
  },
  {
    iataCode: 'AEA',
    icaoCode: 'NGTB',
    name: 'Abemama',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Abemama Atoll Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tarawa',
    utcOffset: 720,
    latitude: 0.490833,
    longitude: 173.828611,
    altitude: 2.4384
  },
  {
    iataCode: 'RFP',
    icaoCode: 'NTTR',
    name: 'Raiatea Island',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Raiatea',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -16.722861,
    longitude: -151.465856,
    altitude: 0.9144
  },
  {
    iataCode: 'RFS',
    icaoCode: 'MNRT',
    name: 'Rosita',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Rosita Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 13.897222,
    longitude: -84.404722,
    altitude: 63.0936
  },
  {
    iataCode: 'AEH',
    icaoCode: 'FTTC',
    name: 'Abeche',
    country: {code: 'TD', name: 'Chad'},
    city: 'Abeche',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 13.847,
    longitude: 20.844333,
    altitude: 544.9824
  },
  {
    iataCode: 'ZWA',
    icaoCode: 'FMND',
    name: 'Andapa',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Andapa',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -14.651667,
    longitude: 49.620556,
    altitude: 473.0496
  },
  {
    iataCode: 'AEP',
    icaoCode: 'SABE',
    name: 'Buenos Aires',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Aeroparque Jorge Newbery',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Buenos_Aires',
    utcOffset: -180,
    latitude: -34.559175,
    longitude: -58.415606,
    altitude: 5.4864
  },
  {
    iataCode: 'RGA',
    icaoCode: 'SAWE',
    name: 'Rio Grande',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Rio Grande',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Ushuaia',
    utcOffset: -180,
    latitude: -53.777667,
    longitude: -67.749389,
    altitude: 19.812
  },
  {
    iataCode: 'AER',
    icaoCode: 'URSS',
    name: 'Sochi',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Sochi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 43.449928,
    longitude: 39.956589,
    altitude: 27.1272
  },
  {
    iataCode: 'ZWL',
    icaoCode: 'CZWL',
    name: 'Wollaston Lake',
    country: {code: 'CA', name: 'Canada'},
    city: 'Wollaston Lake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Regina',
    utcOffset: -360,
    latitude: 58.1069,
    longitude: -103.172,
    altitude: 414.528
  },
  {
    iataCode: 'AES',
    icaoCode: 'ENAL',
    name: 'Alesund',
    country: {code: 'NO', name: 'Norway'},
    city: 'Vigra',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 62.560372,
    longitude: 6.110164,
    altitude: 21.0312
  },
  {
    iataCode: 'AET',
    icaoCode: 'PFAL',
    name: 'Allakaket',
    country: {code: 'US', name: 'United States'},
    city: 'Allakaket Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -480,
    latitude: 66.5519,
    longitude: -152.6222,
    altitude: 134.4168
  },
  {
    iataCode: 'AEX',
    icaoCode: 'KAEX',
    name: 'Alexandria',
    country: {code: 'US', name: 'United States'},
    city: 'Alexandria Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 31.3274,
    longitude: -92.549833,
    altitude: 27.1272
  },
  {
    iataCode: 'AEY',
    icaoCode: 'BIAR',
    name: 'Akureyri',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Akureyri',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.659994,
    longitude: -18.072703,
    altitude: 1.8288
  },
  {
    iataCode: 'IVA',
    icaoCode: 'FMNJ',
    name: 'Ambanja',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ambanja Airport',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -13.65,
    longitude: 48.467,
    altitude: 10.9728
  },
  {
    iataCode: 'RGI',
    icaoCode: 'NTTG',
    name: 'Rangiroa',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Rangiroa',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.954283,
    longitude: -147.6608,
    altitude: 3.048
  },
  {
    iataCode: 'RGL',
    icaoCode: 'SAWG',
    name: 'Rio Gallegos',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Rio Gallegos',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -51.608875,
    longitude: -69.312636,
    altitude: 18.5928
  },
  {
    iataCode: 'IVC',
    icaoCode: 'NZNV',
    name: 'Invercargill',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Invercargill',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -46.412408,
    longitude: 168.312992,
    altitude: 1.524
  },
  {
    iataCode: 'RGN',
    icaoCode: 'VYYY',
    name: 'Yangon',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Yangon Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 16.907305,
    longitude: 96.133222,
    altitude: 33.2232
  },
  {
    iataCode: 'AFA',
    icaoCode: 'SAMR',
    name: 'San Rafael',
    country: {code: 'AR', name: 'Argentina'},
    city: 'San Rafael',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Mendoza',
    utcOffset: -180,
    latitude: -34.588314,
    longitude: -68.403854,
    altitude: 752.856
  },
  {
    iataCode: 'IVL',
    icaoCode: 'EFIV',
    name: 'Ivalo',
    country: {code: 'FI', name: 'Finland'},
    city: 'Ivalo',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 68.607269,
    longitude: 27.405328,
    altitude: 146.6088
  },
  {
    iataCode: 'RGT',
    icaoCode: 'WIPR',
    name: 'Rengat',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Japura',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -0.352808,
    longitude: 102.334917,
    altitude: 18.8976
  },
  {
    iataCode: 'RGS',
    icaoCode: 'LEBG',
    name: 'Burgos',
    country: {code: 'ES', name: 'Spain'},
    city: 'Burgos Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.357628,
    longitude: -3.620764,
    altitude: 897.636
  },
  {
    iataCode: 'AFE',
    icaoCode: 'PAFE',
    name: 'Kake',
    country: {code: 'US', name: 'United States'},
    city: 'Kake Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 56.961389,
    longitude: -133.910278,
    altitude: 52.4256
  },
  {
    iataCode: 'ZXB',
    icaoCode: 'ENJA',
    name: 'Jan Mayen',
    country: {code: 'NO', name: 'Norway'},
    city: 'Jan Mayensfield',
    region: '',
    terminal: '',
    timezoneId: 'Arctic/Longyearbyen',
    utcOffset: 60,
    latitude: 70.961111,
    longitude: -8.575833,
    altitude: 10.0584
  },
  {
    iataCode: 'AFL',
    icaoCode: 'SBAT',
    name: 'Alta Floresta',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Alta Floresta',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -9.866092,
    longitude: -56.106206,
    altitude: 288.6456
  },
  {
    iataCode: 'AFS',
    icaoCode: 'UTSN',
    name: 'Zarafshan',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Sugraly Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Samarkand',
    utcOffset: 300,
    latitude: 41.6139,
    longitude: 64.2332,
    altitude: 425.5008
  },
  {
    iataCode: 'RHE',
    icaoCode: 'LFSR',
    name: 'Reims',
    country: {code: 'FR', name: 'France'},
    city: 'Champagne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 49.31,
    longitude: 4.05,
    altitude: 95.0976
  },
  {
    iataCode: 'AFT',
    icaoCode: 'AGAF',
    name: 'Afutara',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Afutara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.183056,
    longitude: 160.949722,
    altitude: 1.524
  },
  {
    iataCode: 'IWA',
    icaoCode: 'UUBI',
    name: 'Ivanovo',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Yuzhny',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 56.939444,
    longitude: 40.940833,
    altitude: 124.968
  },
  {
    iataCode: 'RHI',
    icaoCode: 'KRHI',
    name: 'Rhinelander',
    country: {code: 'US', name: 'United States'},
    city: 'Rhinelander Oneida County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.6312,
    longitude: -89.4675,
    altitude: 494.9952
  },
  {
    iataCode: 'AFY',
    icaoCode: 'LTAH',
    name: 'Afyon',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Afyon',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.726425,
    longitude: 30.601114,
    altitude: 1008.888
  },
  {
    iataCode: 'AFZ',
    icaoCode: 'OIMS',
    name: 'Sabzevar',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Sabzevar National Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 36.168083,
    longitude: 57.595183,
    altitude: 917.448
  },
  {
    iataCode: 'RHO',
    icaoCode: 'LGRP',
    name: 'Rhodos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Rhodes Diagoras',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.405419,
    longitude: 28.086192,
    altitude: 5.1816
  },
  {
    iataCode: 'AGA',
    icaoCode: 'GMAA',
    name: 'Agadir',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Inezgane',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 30.381353,
    longitude: -9.546311,
    altitude: 27.1272
  },
  {
    iataCode: 'RHP',
    icaoCode: 'VNRC',
    name: 'Ramechhap',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Ramechhap',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.394005,
    longitude: 86.06144,
    altitude: 473.964
  },
  {
    iataCode: 'AGB',
    icaoCode: 'EDMA',
    name: 'Augsburg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Augsburg',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 48.425158,
    longitude: 10.931764,
    altitude: 461.772
  },
  {
    iataCode: 'AGC',
    icaoCode: 'KAGC',
    name: 'Pittsburgh',
    country: {code: 'US', name: 'United States'},
    city: 'Allegheny County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.3544,
    longitude: -79.9302,
    altitude: 381.6096
  },
  {
    iataCode: 'IWJ',
    icaoCode: 'RJOW',
    name: '',
    country: {code: 'JP', name: 'Japan'},
    city: 'Iwami Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 34.6764,
    longitude: 131.79,
    altitude: 56.0832
  },
  {
    iataCode: 'AGE',
    icaoCode: 'EDWG',
    name: 'Wangerooge',
    country: {code: 'DE', name: 'Germany'},
    city: 'Wangerooge Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.7828,
    longitude: 7.91389,
    altitude: 2.1336
  },
  {
    iataCode: 'AGF',
    icaoCode: 'LFBA',
    name: 'Agen',
    country: {code: 'FR', name: 'France'},
    city: 'La Garenne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.174721,
    longitude: 0.590556,
    altitude: 62.1792
  },
  {
    iataCode: 'IWO',
    icaoCode: 'RJAW',
    name: 'Iwojima',
    country: {code: 'JP', name: 'Japan'},
    city: 'Iwo Jima',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 600,
    latitude: 24.784,
    longitude: 141.322722,
    altitude: 117.0432
  },
  {
    iataCode: 'AGH',
    icaoCode: 'ESTA',
    name: 'Ängelholm',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Ängelholm-helsingborg Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 56.2961,
    longitude: 12.8471,
    altitude: 20.7264
  },
  {
    iataCode: 'AGI',
    icaoCode: 'SMWA',
    name: 'Wageningen',
    country: {code: 'SR', name: 'Suriname'},
    city: 'Wageningen Airstrip',
    region: '',
    terminal: '',
    timezoneId: 'America/Paramaribo',
    utcOffset: -180,
    latitude: 5.76667,
    longitude: -56.63333,
    altitude: 3.048
  },
  {
    iataCode: 'AGJ',
    icaoCode: 'RORA',
    name: 'Aguni',
    country: {code: 'JP', name: 'Japan'},
    city: 'Aguni Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 26.592778,
    longitude: 127.240278,
    altitude: 11.5824
  },
  {
    iataCode: 'IWS',
    icaoCode: 'KIWS',
    name: 'Houston',
    country: {code: 'US', name: 'United States'},
    city: 'West Houston',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.818194,
    longitude: -95.672611,
    altitude: 33.8328
  },
  {
    iataCode: 'AGM',
    icaoCode: 'BGAM',
    name: 'Angmagssalik',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Tasiilaq',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -120,
    latitude: 65.612222,
    longitude: -37.618333,
    altitude: 7.3152
  },
  {
    iataCode: 'AGN',
    icaoCode: 'PAGN',
    name: 'Angoon',
    country: {code: 'US', name: 'United States'},
    city: 'Angoon Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 57.503611,
    longitude: -134.585,
    altitude: 0.0
  },
  {
    iataCode: 'AGP',
    icaoCode: 'LEMG',
    name: 'Malaga',
    country: {code: 'ES', name: 'Spain'},
    city: 'Malaga',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 36.6749,
    longitude: -4.499106,
    altitude: 15.8496
  },
  {
    iataCode: 'RIB',
    icaoCode: 'SLRI',
    name: 'Riberalta',
    country: {code: 'BO', name: 'Plurinational State of Bolivia'},
    city: 'Capitán Av. Selin Zeitun Lopez Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/La_Paz',
    utcOffset: -240,
    latitude: -11.0,
    longitude: -66.0,
    altitude: 140.8176
  },
  {
    iataCode: 'AGQ',
    icaoCode: 'LGAG',
    name: 'Agrinion',
    country: {code: 'GR', name: 'Greece'},
    city: 'Agrinion',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.602022,
    longitude: 21.351208,
    altitude: 46.9392
  },
  {
    iataCode: 'RIA',
    icaoCode: 'SBSM',
    name: 'Santa Maria',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santa Maria Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -29.711358,
    longitude: -53.688153,
    altitude: 87.4776
  },
  {
    iataCode: 'AGR',
    icaoCode: 'VIAG',
    name: 'Agra',
    country: {code: 'IN', name: 'India'},
    city: 'Agra',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 27.155831,
    longitude: 77.960892,
    altitude: 167.9448
  },
  {
    iataCode: 'ZYL',
    icaoCode: 'VGSY',
    name: 'Sylhet Osmani',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Osmany Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 24.963242,
    longitude: 91.866783,
    altitude: 15.24
  },
  {
    iataCode: 'AGS',
    icaoCode: 'KAGS',
    name: 'Bush Field',
    country: {code: 'US', name: 'United States'},
    city: 'Augusta Rgnl At Bush Fld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.369944,
    longitude: -81.9645,
    altitude: 43.8912
  },
  {
    iataCode: 'RIC',
    icaoCode: 'KRIC',
    name: 'Richmond',
    country: {code: 'US', name: 'United States'},
    city: 'Richmond Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.505167,
    longitude: -77.319667,
    altitude: 50.9016
  },
  {
    iataCode: 'AGT',
    icaoCode: 'SGES',
    name: 'Ciudad Del Este',
    country: {code: 'PY', name: 'Paraguay'},
    city: 'Ciudad Del Este',
    region: '',
    terminal: '',
    timezoneId: 'America/Asuncion',
    utcOffset: -240,
    latitude: -25.4555,
    longitude: -54.843592,
    altitude: 257.8608
  },
  {
    iataCode: 'RIF',
    icaoCode: 'KRIF',
    name: 'Richfield',
    country: {code: 'US', name: 'United States'},
    city: 'Richfield Minicipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 38.736436,
    longitude: -112.098944,
    altitude: 1615.7448
  },
  {
    iataCode: 'AGU',
    icaoCode: 'MMAS',
    name: 'Aguascalientes',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Jesus Teran Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 21.705558,
    longitude: -102.317858,
    altitude: 1862.9376
  },
  {
    iataCode: 'AGV',
    icaoCode: 'SVAC',
    name: 'Acarigua',
    country: {code: 'VE', name: 'Bolivarian Republic of Venezuela'},
    city: 'Oswaldo Guevara Mujica',
    region: '',
    terminal: '',
    timezoneId: 'America/Caracas',
    utcOffset: -240,
    latitude: 9.553422,
    longitude: -69.237536,
    altitude: 225.8568
  },
  {
    iataCode: 'RIG',
    icaoCode: 'SBRG',
    name: 'Rio Grande',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Rio Grande',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -32.082617,
    longitude: -52.166542,
    altitude: 8.2296
  },
  {
    iataCode: 'AGX',
    icaoCode: 'VOAT',
    name: 'Agatti Island',
    country: {code: 'IN', name: 'India'},
    city: 'Agatti',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 10.823656,
    longitude: 72.176042,
    altitude: 4.2672
  },
  {
    iataCode: 'IXB',
    icaoCode: 'VEBD',
    name: 'Baghdogra',
    country: {code: 'IN', name: 'India'},
    city: 'Bagdogra',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.681206,
    longitude: 88.328567,
    altitude: 125.5776
  },
  {
    iataCode: 'IXA',
    icaoCode: 'VEAT',
    name: 'Agartala',
    country: {code: 'IN', name: 'India'},
    city: 'Agartala',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.886978,
    longitude: 91.24045,
    altitude: 14.0208
  },
  {
    iataCode: 'AGZ',
    icaoCode: 'FAAG',
    name: 'Aggeneys',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Aggeneys',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.281767,
    longitude: 18.813869,
    altitude: 807.1104
  },
  {
    iataCode: 'IXD',
    icaoCode: 'VIAL',
    name: 'Allahabad',
    country: {code: 'IN', name: 'India'},
    city: 'Allahabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 25.440064,
    longitude: 81.733872,
    altitude: 98.1456
  },
  {
    iataCode: 'RIL',
    icaoCode: 'KRIL',
    name: 'Rifle',
    country: {code: 'US', name: 'United States'},
    city: 'Garfield County Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 39.526306,
    longitude: -107.726944,
    altitude: 1691.0304
  },
  {
    iataCode: 'IXC',
    icaoCode: 'VICG',
    name: 'Chandigarh',
    country: {code: 'IN', name: 'India'},
    city: 'Chandigarh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 30.673469,
    longitude: 76.788542,
    altitude: 308.4576
  },
  {
    iataCode: 'RIK',
    icaoCode: 'MRCR',
    name: 'Carrillo',
    country: {code: 'CR', name: 'Costa Rica'},
    city: 'Carrillo Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Costa_Rica',
    utcOffset: -360,
    latitude: 9.866667,
    longitude: -85.483333,
    altitude: 3.048
  },
  {
    iataCode: 'RIN',
    icaoCode: 'AGRC',
    name: 'Ringi Cove',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Ringi Cove Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.12639,
    longitude: 157.143,
    altitude: 0.0
  },
  {
    iataCode: 'IXE',
    icaoCode: 'VOML',
    name: 'Mangalore',
    country: {code: 'IN', name: 'India'},
    city: 'Mangalore',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 12.961267,
    longitude: 74.890069,
    altitude: 102.7176
  },
  {
    iataCode: 'IXH',
    icaoCode: 'VEKR',
    name: 'Kailashahar',
    country: {code: 'IN', name: 'India'},
    city: 'Kailashahar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.308192,
    longitude: 92.007156,
    altitude: 24.0792
  },
  {
    iataCode: 'IXG',
    icaoCode: 'VABM',
    name: 'Belgaum',
    country: {code: 'IN', name: 'India'},
    city: 'Belgaum',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 15.859286,
    longitude: 74.618292,
    altitude: 758.0376
  },
  {
    iataCode: 'IXJ',
    icaoCode: 'VIJU',
    name: 'Jammu',
    country: {code: 'IN', name: 'India'},
    city: 'Jammu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 32.689142,
    longitude: 74.837389,
    altitude: 313.6392
  },
  {
    iataCode: 'AHB',
    icaoCode: 'OEAB',
    name: 'Abha',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Abha',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 18.240367,
    longitude: 42.656625,
    altitude: 2090.3184
  },
  {
    iataCode: 'IXI',
    icaoCode: 'VELR',
    name: 'Lilabari',
    country: {code: 'IN', name: 'India'},
    city: 'Lilabari',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 27.295494,
    longitude: 94.09765,
    altitude: 100.584
  },
  {
    iataCode: 'IXL',
    icaoCode: 'VILH',
    name: 'Leh',
    country: {code: 'IN', name: 'India'},
    city: 'Leh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 34.135872,
    longitude: 77.546514,
    altitude: 3255.8736
  },
  {
    iataCode: 'IXK',
    icaoCode: 'VAKS',
    name: 'Keshod',
    country: {code: 'IN', name: 'India'},
    city: 'Keshod',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.317069,
    longitude: 70.270403,
    altitude: 50.9016
  },
  {
    iataCode: 'RIS',
    icaoCode: 'RJER',
    name: 'Rishiri Island',
    country: {code: 'JP', name: 'Japan'},
    city: 'Rishiri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 45.242006,
    longitude: 141.186431,
    altitude: 34.1376
  },
  {
    iataCode: 'AHE',
    icaoCode: 'NTHE',
    name: '',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Ahe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -14.4281,
    longitude: -146.257,
    altitude: 3.3528
  },
  {
    iataCode: 'RIV',
    icaoCode: 'KRIV',
    name: 'Riverside',
    country: {code: 'US', name: 'United States'},
    city: 'March Arb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.880711,
    longitude: -117.259453,
    altitude: 467.868
  },
  {
    iataCode: 'IXM',
    icaoCode: 'VOMD',
    name: 'Madurai',
    country: {code: 'IN', name: 'India'},
    city: 'Madurai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 9.834508,
    longitude: 78.093378,
    altitude: 139.9032
  },
  {
    iataCode: 'RIU',
    icaoCode: 'KRIU',
    name: 'Rancho Murieta',
    country: {code: 'US', name: 'United States'},
    city: 'Rancho Murieta',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.486778,
    longitude: -121.102778,
    altitude: 42.9768
  },
  {
    iataCode: 'IXP',
    icaoCode: 'VIPK',
    name: 'Pathankot',
    country: {code: 'IN', name: 'India'},
    city: 'Pathankot',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 32.233778,
    longitude: 75.634628,
    altitude: 309.9816
  },
  {
    iataCode: 'RIX',
    icaoCode: 'EVRA',
    name: 'Riga',
    country: {code: 'LV', name: 'Latvia'},
    city: 'Riga Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Riga',
    utcOffset: 120,
    latitude: 56.923611,
    longitude: 23.971111,
    altitude: 10.3632
  },
  {
    iataCode: 'RIW',
    icaoCode: 'KRIW',
    name: 'Riverton Wy',
    country: {code: 'US', name: 'United States'},
    city: 'Riverton Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 43.064167,
    longitude: -108.459722,
    altitude: 1684.02
  },
  {
    iataCode: 'IXR',
    icaoCode: 'VERC',
    name: 'Ranchi',
    country: {code: 'IN', name: 'India'},
    city: 'Birsa Munda',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.31425,
    longitude: 85.321675,
    altitude: 654.7104
  },
  {
    iataCode: 'RIY',
    icaoCode: 'OYRN',
    name: 'Mukalla',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Riyan',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 14.662639,
    longitude: 49.375028,
    altitude: 16.4592
  },
  {
    iataCode: 'IXS',
    icaoCode: 'VEKU',
    name: 'Silchar',
    country: {code: 'IN', name: 'India'},
    city: 'Silchar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 24.912928,
    longitude: 92.978742,
    altitude: 107.2896
  },
  {
    iataCode: 'AHN',
    icaoCode: 'KAHN',
    name: 'Athens',
    country: {code: 'US', name: 'United States'},
    city: 'Athens Ben Epps Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.9486,
    longitude: -83.3263,
    altitude: 246.2784
  },
  {
    iataCode: 'IXU',
    icaoCode: 'VAAU',
    name: 'Aurangabad',
    country: {code: 'IN', name: 'India'},
    city: 'Aurangabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 19.862728,
    longitude: 75.398114,
    altitude: 582.4728
  },
  {
    iataCode: 'AHO',
    icaoCode: 'LIEA',
    name: 'Alghero',
    country: {code: 'IT', name: 'Italy'},
    city: 'Alghero',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 40.632133,
    longitude: 8.290772,
    altitude: 26.5176
  },
  {
    iataCode: 'RJA',
    icaoCode: 'VORY',
    name: 'Rajahmundry',
    country: {code: 'IN', name: 'India'},
    city: 'Rajahmundry',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 17.110361,
    longitude: 81.818208,
    altitude: 46.0248
  },
  {
    iataCode: 'IXW',
    icaoCode: 'VEJS',
    name: 'Jamshedpur',
    country: {code: 'IN', name: 'India'},
    city: 'Jamshedpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.813211,
    longitude: 86.168844,
    altitude: 153.924
  },
  {
    iataCode: 'IXZ',
    icaoCode: 'VOPB',
    name: 'Port Blair',
    country: {code: 'IN', name: 'India'},
    city: 'Port Blair',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 11.641161,
    longitude: 92.729744,
    altitude: 4.2672
  },
  {
    iataCode: 'IXY',
    icaoCode: 'VAKE',
    name: 'Kandla',
    country: {code: 'IN', name: 'India'},
    city: 'Kandla',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.112719,
    longitude: 70.100289,
    altitude: 29.2608
  },
  {
    iataCode: 'AHS',
    icaoCode: 'MHAH',
    name: 'Ahuas',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Ahuas Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.4722,
    longitude: -84.3522,
    altitude: 29.8704
  },
  {
    iataCode: 'AHU',
    icaoCode: 'GMTA',
    name: 'Al Hociema',
    country: {code: 'MA', name: 'Morocco'},
    city: 'Cherif El Idrissi',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Casablanca',
    utcOffset: 0,
    latitude: 35.177103,
    longitude: -3.839525,
    altitude: 28.956
  },
  {
    iataCode: 'RJH',
    icaoCode: 'VGRJ',
    name: 'Rajshahi',
    country: {code: 'BD', name: 'Bangladesh'},
    city: 'Shah Mokhdum',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dhaka',
    utcOffset: 360,
    latitude: 24.437219,
    longitude: 88.616511,
    altitude: 19.5072
  },
  {
    iataCode: 'RJK',
    icaoCode: 'LDRI',
    name: 'Rijeka',
    country: {code: 'HR', name: 'Croatia'},
    city: 'Rijeka',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Zagreb',
    utcOffset: 60,
    latitude: 45.216889,
    longitude: 14.570267,
    altitude: 84.7344
  },
  {
    iataCode: 'ZZU',
    icaoCode: 'FWUU',
    name: 'Mzuzu',
    country: {code: 'MW', name: 'Malawi'},
    city: 'Mzuzu',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Blantyre',
    utcOffset: 120,
    latitude: -11.44475,
    longitude: 34.011776,
    altitude: 1254.252
  },
  {
    iataCode: 'RJL',
    icaoCode: 'LELO',
    name: '',
    country: {code: 'ES', name: 'Spain'},
    city: 'Logroño-agoncillo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 42.4542,
    longitude: -2.32083,
    altitude: 353.8728
  },
  {
    iataCode: 'RJN',
    icaoCode: 'OIKR',
    name: 'Rafsanjan',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Rafsanjan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 180,
    latitude: 30.297714,
    longitude: 56.051139,
    altitude: 1614.8304
  },
  {
    iataCode: 'AIA',
    icaoCode: 'KAIA',
    name: 'Alliance',
    country: {code: 'US', name: 'United States'},
    city: 'Alliance Municipal Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 42.053333,
    longitude: -102.803889,
    altitude: 1198.1688
  },
  {
    iataCode: 'IYK',
    icaoCode: 'KIYK',
    name: 'Inyokern',
    country: {code: 'US', name: 'United States'},
    city: 'Inyokern Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.658889,
    longitude: -117.829444,
    altitude: 748.284
  },
  {
    iataCode: '﻿1RL',
    icaoCode: 'K1RL',
    name: 'Point Roberts',
    country: {code: 'US', name: 'United States'},
    city: 'Point Roberts Airpark',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 48.979722,
    longitude: -123.078889,
    altitude: 3.048
  },
  {
    iataCode: 'AIN',
    icaoCode: 'PAWI',
    name: 'Wainwright',
    country: {code: 'US', name: 'United States'},
    city: 'Wainwright Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.638056,
    longitude: -159.994722,
    altitude: 12.4968
  },
  {
    iataCode: 'RKD',
    icaoCode: 'KRKD',
    name: 'Rockland',
    country: {code: 'US', name: 'United States'},
    city: 'Knox County Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.060111,
    longitude: -69.09923,
    altitude: 17.0688
  },
  {
    iataCode: 'AIS',
    icaoCode: 'NGTR',
    name: 'Arorae',
    country: {code: 'KI', name: 'Kiribati'},
    city: 'Arorae Island Airport',
    region: '',
    terminal: '',
    timezoneId: ' Pacific/Tarawa',
    utcOffset: 600,
    latitude: -2.633333,
    longitude: 179.816667,
    altitude: 1.8288
  },
  {
    iataCode: 'AIT',
    icaoCode: 'NCAI',
    name: 'Aitutaki',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Aitutaki',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -18.830922,
    longitude: -159.764233,
    altitude: 4.2672
  },
  {
    iataCode: 'AIU',
    icaoCode: 'NCAT',
    name: 'Atiu Island',
    country: {code: 'CK', name: 'Cook Islands'},
    city: 'Atiu Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Rarotonga',
    utcOffset: -600,
    latitude: -19.9678,
    longitude: -158.119,
    altitude: 10.9728
  },
  {
    iataCode: 'RKE',
    icaoCode: 'EKRK',
    name: 'Copenhagen',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Roskilde',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.585567,
    longitude: 12.131428,
    altitude: 44.5008
  },
  {
    iataCode: 'RKH',
    icaoCode: 'KUZA',
    name: 'Rock Hill',
    country: {code: 'US', name: 'United States'},
    city: 'Rock Hill York Co Bryant Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.9878,
    longitude: -81.0572,
    altitude: 203.3016
  },
  {
    iataCode: 'AJA',
    icaoCode: 'LFKJ',
    name: 'Ajaccio',
    country: {code: 'FR', name: 'France'},
    city: 'Campo Dell Oro',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 41.923637,
    longitude: 8.802917,
    altitude: 5.4864
  },
  {
    iataCode: 'RKT',
    icaoCode: 'OMRK',
    name: 'Ras Al Khaimah',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Ras Al Khaimah Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 25.613483,
    longitude: 55.938817,
    altitude: 31.0896
  },
  {
    iataCode: 'RKS',
    icaoCode: 'KRKS',
    name: 'Rock Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Rock Springs Sweetwater County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 41.5942,
    longitude: -109.065,
    altitude: 2060.448
  },
  {
    iataCode: 'RKV',
    icaoCode: 'BIRK',
    name: 'Reykjavik',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Reykjavik',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 64.13,
    longitude: -21.940556,
    altitude: 14.6304
  },
  {
    iataCode: 'AJF',
    icaoCode: 'OESK',
    name: 'Al-jawf',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Al-jawf Domestic Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 29.785133,
    longitude: 40.100006,
    altitude: 689.1528
  },
  {
    iataCode: 'IZO',
    icaoCode: 'RJOC',
    name: 'Izumo',
    country: {code: 'JP', name: 'Japan'},
    city: 'Izumo',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 35.413611,
    longitude: 132.89,
    altitude: 4.572
  },
  {
    iataCode: 'AJI',
    icaoCode: 'LTCO',
    name: '',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Agri Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.6546,
    longitude: 43.0271,
    altitude: 1664.8176
  },
  {
    iataCode: 'AJL',
    icaoCode: 'VEAZ',
    name: 'Aizwal',
    country: {code: 'IN', name: 'India'},
    city: 'Aizawl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.746603,
    longitude: 92.802767,
    altitude: 305.1048
  },
  {
    iataCode: 'AJN',
    icaoCode: 'FMCV',
    name: 'Anjouan',
    country: {code: 'KM', name: 'Comoros'},
    city: 'Ouani',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Comoro',
    utcOffset: 180,
    latitude: -12.131667,
    longitude: 44.430279,
    altitude: 18.8976
  },
  {
    iataCode: 'AJR',
    icaoCode: 'ESNX',
    name: 'Arvidsjaur',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Arvidsjaur',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 65.590278,
    longitude: 19.281944,
    altitude: 379.476
  },
  {
    iataCode: 'AJU',
    icaoCode: 'SBAR',
    name: 'Aracaju',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Santa Maria',
    region: '',
    terminal: '',
    timezoneId: 'America/Noronha',
    utcOffset: -180,
    latitude: -10.984,
    longitude: -37.070333,
    altitude: 7.0104
  },
  {
    iataCode: 'RLG',
    icaoCode: 'ETNL',
    name: 'Laage',
    country: {code: 'DE', name: 'Germany'},
    city: 'Laage',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 53.918167,
    longitude: 12.278333,
    altitude: 42.0624
  },
  {
    iataCode: 'AJY',
    icaoCode: 'DRZA',
    name: 'Agadez',
    country: {code: 'NE', name: 'Niger'},
    city: 'Manu Dayak',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Niamey',
    utcOffset: 60,
    latitude: 16.965997,
    longitude: 8.000114,
    altitude: 505.0536
  },
  {
    iataCode: 'AKA',
    icaoCode: 'ZLAK',
    name: 'Ankang',
    country: {code: 'CN', name: 'China'},
    city: 'Ankang Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 32.7081,
    longitude: 108.931,
    altitude: 0.0
  },
  {
    iataCode: 'AKB',
    icaoCode: 'PAAK',
    name: 'Atka',
    country: {code: 'US', name: 'United States'},
    city: 'Atka Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Adak',
    utcOffset: -540,
    latitude: 52.220278,
    longitude: -174.206389,
    altitude: 17.0688
  },
  {
    iataCode: 'AKD',
    icaoCode: 'VAAK',
    name: 'Akola',
    country: {code: 'IN', name: 'India'},
    city: 'Akola',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 20.699006,
    longitude: 77.058628,
    altitude: 304.4952
  },
  {
    iataCode: 'AKF',
    icaoCode: 'HLKF',
    name: 'Kufra',
    country: {code: 'LY', name: 'Libya'},
    city: 'Kufra',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Tripoli',
    utcOffset: 60,
    latitude: 24.178728,
    longitude: 23.313958,
    altitude: 416.6616
  },
  {
    iataCode: 'AKI',
    icaoCode: 'PFAK',
    name: 'Akiak',
    country: {code: 'US', name: 'United States'},
    city: 'Akiak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 60.902778,
    longitude: -161.230556,
    altitude: 9.144
  },
  {
    iataCode: 'AKJ',
    icaoCode: 'RJEC',
    name: 'Asahikawa',
    country: {code: 'JP', name: 'Japan'},
    city: 'Asahikawa',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 43.670833,
    longitude: 142.4475,
    altitude: 219.7608
  },
  {
    iataCode: 'AKK',
    icaoCode: 'PAKH',
    name: 'Akhiok',
    country: {code: 'US', name: 'United States'},
    city: 'Akhiok Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 56.938611,
    longitude: -154.1825,
    altitude: 13.4112
  },
  {
    iataCode: 'AKL',
    icaoCode: 'NZAA',
    name: 'Auckland',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Auckland Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -37.008056,
    longitude: 174.791667,
    altitude: 7.0104
  },
  {
    iataCode: 'AKN',
    icaoCode: 'PAKN',
    name: 'King Salmon',
    country: {code: 'US', name: 'United States'},
    city: 'King Salmon',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 58.676778,
    longitude: -156.649278,
    altitude: 20.7264
  },
  {
    iataCode: 'AKP',
    icaoCode: 'PAKP',
    name: 'Anaktuvuk Pass',
    country: {code: 'US', name: 'United States'},
    city: 'Anaktuvuk Pass Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 68.1336,
    longitude: -151.743,
    altitude: 640.9944
  },
  {
    iataCode: 'RMA',
    icaoCode: 'YROM',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Roma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -26.545,
    longitude: 148.775,
    altitude: 314.5536
  },
  {
    iataCode: 'AKR',
    icaoCode: 'DNAK',
    name: 'Akure',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Akure',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 7.246739,
    longitude: 5.301008,
    altitude: 335.28
  },
  {
    iataCode: 'AKS',
    icaoCode: 'AGGA',
    name: 'Auki',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Auki Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.70257,
    longitude: 160.682,
    altitude: 1.524
  },
  {
    iataCode: 'AKT',
    icaoCode: 'LCRA',
    name: 'Akrotiri',
    country: {code: 'CY', name: 'Cyprus'},
    city: 'Akrotiri',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Nicosia',
    utcOffset: 120,
    latitude: 34.590416,
    longitude: 32.987861,
    altitude: 23.1648
  },
  {
    iataCode: 'RMF',
    icaoCode: 'HEMA',
    name: 'Marsa Alam',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Marsa Alam Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 25.557111,
    longitude: 34.583711,
    altitude: 76.5048
  },
  {
    iataCode: 'AKU',
    icaoCode: 'ZWAK',
    name: 'Aksu',
    country: {code: 'CN', name: 'China'},
    city: 'Aksu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kashgar',
    utcOffset: 480,
    latitude: 41.2625,
    longitude: 80.2917,
    altitude: 0.0
  },
  {
    iataCode: 'RME',
    icaoCode: 'KRME',
    name: 'Rome',
    country: {code: 'US', name: 'United States'},
    city: 'Griffiss Afld',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.2338,
    longitude: -75.407033,
    altitude: 153.6192
  },
  {
    iataCode: 'AKV',
    icaoCode: 'CYKO',
    name: 'Akulivik',
    country: {code: 'CA', name: 'Canada'},
    city: 'Akulivik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Montreal',
    utcOffset: -300,
    latitude: 60.8186,
    longitude: -78.1486,
    altitude: 22.86
  },
  {
    iataCode: 'AKX',
    icaoCode: 'UATT',
    name: 'Aktyubinsk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Aktyubinsk',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aqtobe',
    utcOffset: 300,
    latitude: 50.245833,
    longitude: 57.206667,
    altitude: 224.9424
  },
  {
    iataCode: 'AKY',
    icaoCode: 'VYSW',
    name: 'Sittwe',
    country: {code: 'MM', name: 'Myanmar'},
    city: 'Sittwe',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Rangoon',
    utcOffset: 360,
    latitude: 20.132708,
    longitude: 92.872628,
    altitude: 8.2296
  },
  {
    iataCode: 'RMI',
    icaoCode: 'LIPR',
    name: 'Rimini',
    country: {code: 'IT', name: 'Italy'},
    city: 'Rimini',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.020292,
    longitude: 12.611747,
    altitude: 12.4968
  },
  {
    iataCode: 'RML',
    icaoCode: 'VCCC',
    name: 'Colombo',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Colombo Ratmalana',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 6.821994,
    longitude: 79.886208,
    altitude: 6.7056
  },
  {
    iataCode: 'ALA',
    icaoCode: 'UAAA',
    name: 'Alma-ata',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Almaty',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 360,
    latitude: 43.352072,
    longitude: 77.040508,
    altitude: 680.9232
  },
  {
    iataCode: 'ALB',
    icaoCode: 'KALB',
    name: 'Albany',
    country: {code: 'US', name: 'United States'},
    city: 'Albany Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.748267,
    longitude: -73.801692,
    altitude: 86.868
  },
  {
    iataCode: 'ALC',
    icaoCode: 'LEAL',
    name: 'Alicante',
    country: {code: 'ES', name: 'Spain'},
    city: 'Alicante',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Madrid',
    utcOffset: 60,
    latitude: 38.282169,
    longitude: -0.558156,
    altitude: 43.2816
  },
  {
    iataCode: 'RMT',
    icaoCode: 'NTAM',
    name: 'Rimatara',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Rimatara',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -22.637253,
    longitude: -152.805192,
    altitude: 17.0688
  },
  {
    iataCode: 'RMS',
    icaoCode: 'ETAR',
    name: 'Ramstein',
    country: {code: 'DE', name: 'Germany'},
    city: 'Ramstein Ab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.436911,
    longitude: 7.600283,
    altitude: 236.5248
  },
  {
    iataCode: 'ALF',
    icaoCode: 'ENAT',
    name: 'Alta',
    country: {code: 'NO', name: 'Norway'},
    city: 'Alta',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.976111,
    longitude: 23.371667,
    altitude: 2.7432
  },
  {
    iataCode: 'ALG',
    icaoCode: 'DAAG',
    name: 'Algier',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Houari Boumediene',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 36.691014,
    longitude: 3.215408,
    altitude: 24.9936
  },
  {
    iataCode: 'ALH',
    icaoCode: 'YABA',
    name: 'Albany',
    country: {code: 'AU', name: 'Australia'},
    city: 'Albany Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -34.9433,
    longitude: 117.809,
    altitude: 71.0184
  },
  {
    iataCode: 'ALI',
    icaoCode: 'KALI',
    name: 'Alice',
    country: {code: 'US', name: 'United States'},
    city: 'Alice Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 27.740889,
    longitude: -98.026944,
    altitude: 54.2544
  },
  {
    iataCode: 'ALJ',
    icaoCode: 'FAAB',
    name: 'Alexander Bay',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Alexander Bay',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -28.575001,
    longitude: 16.533333,
    altitude: 29.8704
  },
  {
    iataCode: 'ALL',
    icaoCode: 'LIMG',
    name: 'Albenga',
    country: {code: 'IT', name: 'Italy'},
    city: 'Albenga',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 44.050608,
    longitude: 8.127428,
    altitude: 45.1104
  },
  {
    iataCode: 'ALM',
    icaoCode: 'KALM',
    name: 'Alamogordo',
    country: {code: 'US', name: 'United States'},
    city: 'Alamogordo White Sands Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 32.8399,
    longitude: -105.991,
    altitude: 1280.16
  },
  {
    iataCode: 'ALO',
    icaoCode: 'KALO',
    name: 'Waterloo',
    country: {code: 'US', name: 'United States'},
    city: 'Waterloo Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 42.5571,
    longitude: -92.4003,
    altitude: 266.0904
  },
  {
    iataCode: 'RNA',
    icaoCode: 'AGAR',
    name: 'Ulawa',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Ulawa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.854722,
    longitude: 161.979167,
    altitude: 1.524
  },
  {
    iataCode: 'ALP',
    icaoCode: 'OSAP',
    name: 'Aleppo',
    country: {code: 'SY', name: 'Syrian Arab Republic'},
    city: 'Aleppo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Damascus',
    utcOffset: 120,
    latitude: 36.180675,
    longitude: 37.224358,
    altitude: 388.9248
  },
  {
    iataCode: 'ALR',
    icaoCode: 'NZLX',
    name: 'Alexandra',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Alexandra',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -45.211666,
    longitude: 169.373333,
    altitude: 229.2096
  },
  {
    iataCode: 'RNB',
    icaoCode: 'ESDF',
    name: 'Ronneby',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Ronneby',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 56.266667,
    longitude: 15.265,
    altitude: 58.2168
  },
  {
    iataCode: 'ALS',
    icaoCode: 'KALS',
    name: 'Alamosa',
    country: {code: 'US', name: 'United States'},
    city: 'San Luis Valley Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 37.435,
    longitude: -105.866667,
    altitude: 2297.8872
  },
  {
    iataCode: 'RNE',
    icaoCode: 'LFLO',
    name: 'Roanne',
    country: {code: 'FR', name: 'France'},
    city: 'Renaison',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 46.058334,
    longitude: 4.001389,
    altitude: 337.1088
  },
  {
    iataCode: 'RND',
    icaoCode: 'KRND',
    name: 'San Antonio',
    country: {code: 'US', name: 'United States'},
    city: 'Randolph Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.529675,
    longitude: -98.2789,
    altitude: 232.2576
  },
  {
    iataCode: 'ALU',
    icaoCode: 'HCMA',
    name: 'Alula',
    country: {code: 'SO', name: 'Somalia'},
    city: 'Alula Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Mogadishu',
    utcOffset: 180,
    latitude: 11.95,
    longitude: 50.733,
    altitude: 1.8288
  },
  {
    iataCode: 'ALW',
    icaoCode: 'KALW',
    name: 'Walla Walla',
    country: {code: 'US', name: 'United States'},
    city: 'Walla Walla Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.0949,
    longitude: -118.288,
    altitude: 363.0168
  },
  {
    iataCode: 'RNI',
    icaoCode: 'MNCI',
    name: 'Corn Island',
    country: {code: 'NI', name: 'Nicaragua'},
    city: 'Corn Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Managua',
    utcOffset: -360,
    latitude: 12.168611,
    longitude: -83.0675,
    altitude: 7.9248
  },
  {
    iataCode: 'ALY',
    icaoCode: 'HEAX',
    name: 'Alexandria',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Alexandria Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 31.183903,
    longitude: 29.948889,
    altitude: -1.8288
  },
  {
    iataCode: 'RNJ',
    icaoCode: 'RORY',
    name: 'Yoron',
    country: {code: 'JP', name: 'Japan'},
    city: 'Yoron',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 27.043964,
    longitude: 128.401517,
    altitude: 15.8496
  },
  {
    iataCode: 'RNL',
    icaoCode: 'AGGR',
    name: 'Rennell Island',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Rennell/tingoa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -11.5339,
    longitude: 160.063,
    altitude: 0.0
  },
  {
    iataCode: 'RNO',
    icaoCode: 'KRNO',
    name: 'Reno',
    country: {code: 'US', name: 'United States'},
    city: 'Reno Tahoe Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 39.499108,
    longitude: -119.768108,
    altitude: 1345.692
  },
  {
    iataCode: 'RNN',
    icaoCode: 'EKRN',
    name: 'Ronne',
    country: {code: 'DK', name: 'Denmark'},
    city: 'Bornholm Ronne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Copenhagen',
    utcOffset: 60,
    latitude: 55.063267,
    longitude: 14.759558,
    altitude: 15.8496
  },
  {
    iataCode: 'AMA',
    icaoCode: 'KAMA',
    name: 'Amarillo',
    country: {code: 'US', name: 'United States'},
    city: 'Rick Husband Amarillo Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.219369,
    longitude: -101.705931,
    altitude: 1099.4136
  },
  {
    iataCode: 'AMB',
    icaoCode: 'FMNE',
    name: 'Ambilobe',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Ambilobe',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -13.188431,
    longitude: 48.987978,
    altitude: 21.9456
  },
  {
    iataCode: 'RNS',
    icaoCode: 'LFRN',
    name: 'Rennes',
    country: {code: 'FR', name: 'France'},
    city: 'St Jacques',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.069508,
    longitude: -1.734794,
    altitude: 37.7952
  },
  {
    iataCode: 'AMC',
    icaoCode: 'FTTN',
    name: 'Am Timan',
    country: {code: 'TD', name: 'Chad'},
    city: 'Am Timan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ndjamena',
    utcOffset: 60,
    latitude: 11.034,
    longitude: 20.274,
    altitude: 433.1208
  },
  {
    iataCode: 'AMD',
    icaoCode: 'VAAH',
    name: 'Ahmedabad',
    country: {code: 'IN', name: 'India'},
    city: 'Ahmedabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.077242,
    longitude: 72.63465,
    altitude: 57.6072
  },
  {
    iataCode: 'AMH',
    icaoCode: 'HAAM',
    name: 'Arba Minch',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Arba Minch',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 6.039389,
    longitude: 37.590453,
    altitude: 1187.196
  },
  {
    iataCode: 'AMI',
    icaoCode: 'WADA',
    name: 'Mataram',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Selaparang',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.560708,
    longitude: 116.094656,
    altitude: 15.8496
  },
  {
    iataCode: 'AMM',
    icaoCode: 'OJAI',
    name: 'Amman',
    country: {code: 'JO', name: 'Jordan'},
    city: 'Queen Alia Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Amman',
    utcOffset: 120,
    latitude: 31.722556,
    longitude: 35.993214,
    altitude: 729.996
  },
  {
    iataCode: 'ROB',
    icaoCode: 'GLRB',
    name: 'Monrovia',
    country: {code: 'LR', name: 'Liberia'},
    city: 'Monrovia Roberts Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Monrovia',
    utcOffset: 0,
    latitude: 6.233789,
    longitude: -10.362311,
    altitude: 9.4488
  },
  {
    iataCode: 'AMQ',
    icaoCode: 'WAPP',
    name: 'Ambon',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Pattimura',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jayapura',
    utcOffset: 540,
    latitude: -3.710264,
    longitude: 128.089136,
    altitude: 10.0584
  },
  {
    iataCode: 'ROA',
    icaoCode: 'KROA',
    name: 'Roanoke Va',
    country: {code: 'US', name: 'United States'},
    city: 'Roanoke Regional',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 37.325472,
    longitude: -79.975417,
    altitude: 358.14
  },
  {
    iataCode: 'AMS',
    icaoCode: 'EHAM',
    name: 'Amsterdam',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Schiphol',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 52.308613,
    longitude: 4.763889,
    altitude: -3.3528
  },
  {
    iataCode: 'ROC',
    icaoCode: 'KROC',
    name: 'Rochester',
    country: {code: 'US', name: 'United States'},
    city: 'Greater Rochester Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.118866,
    longitude: -77.672389,
    altitude: 170.3832
  },
  {
    iataCode: 'AMV',
    icaoCode: 'ULDD',
    name: 'Amderma',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Amderma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 69.7633,
    longitude: 61.5564,
    altitude: 3.9624
  },
  {
    iataCode: 'ROI',
    icaoCode: 'VTUV',
    name: 'Roi Et',
    country: {code: 'TH', name: 'Thailand'},
    city: 'Roi Et',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bangkok',
    utcOffset: 420,
    latitude: 16.116761,
    longitude: 103.773797,
    altitude: 137.4648
  },
  {
    iataCode: 'ROK',
    icaoCode: 'YBRK',
    name: 'Rockhampton',
    country: {code: 'AU', name: 'Australia'},
    city: 'Rockhampton',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -23.381944,
    longitude: 150.475278,
    altitude: 10.3632
  },
  {
    iataCode: 'ROP',
    icaoCode: 'PGRO',
    name: 'Rota',
    country: {code: 'MP', name: 'Northern Mariana Islands'},
    city: 'Rota Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Saipan',
    utcOffset: 600,
    latitude: 14.174308,
    longitude: 145.242536,
    altitude: 185.0136
  },
  {
    iataCode: 'ROO',
    icaoCode: 'SWRD',
    name: 'Rondonopolis',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Rondonopolis Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Cuiaba',
    utcOffset: -240,
    latitude: -16.466667,
    longitude: -54.633333,
    altitude: 152.4
  },
  {
    iataCode: 'ROR',
    icaoCode: 'PTRO',
    name: 'Babelthuap',
    country: {code: 'PW', name: 'Palau'},
    city: 'Babelthuap',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Palau',
    utcOffset: 540,
    latitude: 7.367303,
    longitude: 134.544278,
    altitude: 53.6448
  },
  {
    iataCode: 'ANB',
    icaoCode: 'KANB',
    name: 'Anniston',
    country: {code: 'US', name: 'United States'},
    city: 'Anniston Metro',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 33.588167,
    longitude: -85.858111,
    altitude: 186.5376
  },
  {
    iataCode: 'ANC',
    icaoCode: 'PANC',
    name: 'Anchorage',
    country: {code: 'US', name: 'United States'},
    city: 'Ted Stevens Anchorage Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.174361,
    longitude: -149.996361,
    altitude: 46.3296
  },
  {
    iataCode: 'ROT',
    icaoCode: 'NZRO',
    name: 'Rotorua',
    country: {code: 'NZ', name: 'New Zealand'},
    city: 'Rotorua',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Auckland',
    utcOffset: 720,
    latitude: -38.109167,
    longitude: 176.317222,
    altitude: 284.988
  },
  {
    iataCode: 'AND',
    icaoCode: 'KAND',
    name: 'Andersen',
    country: {code: 'US', name: 'United States'},
    city: 'Anderson Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.494583,
    longitude: -82.709389,
    altitude: 238.3536
  },
  {
    iataCode: 'ROS',
    icaoCode: 'SAAR',
    name: 'Rosario',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Rosario',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -32.903611,
    longitude: -60.785,
    altitude: 25.908
  },
  {
    iataCode: 'ANE',
    icaoCode: 'LFJR',
    name: 'Angers/marcé',
    country: {code: 'FR', name: 'France'},
    city: 'Angers-loire Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.5603,
    longitude: -0.312222,
    altitude: 59.1312
  },
  {
    iataCode: 'ROV',
    icaoCode: 'URRR',
    name: 'Rostov',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Rostov Na Donu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 47.258208,
    longitude: 39.818089,
    altitude: 85.344
  },
  {
    iataCode: 'ANF',
    icaoCode: 'SCFA',
    name: 'Antofagasta',
    country: {code: 'CL', name: 'Chile'},
    city: 'Cerro Moreno Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -23.444478,
    longitude: -70.4451,
    altitude: 138.684
  },
  {
    iataCode: 'ANG',
    icaoCode: 'LFBU',
    name: 'Angouleme',
    country: {code: 'FR', name: 'France'},
    city: 'Brie Champniers',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.729247,
    longitude: 0.221456,
    altitude: 132.8928
  },
  {
    iataCode: 'ROW',
    icaoCode: 'KROW',
    name: 'Roswell',
    country: {code: 'US', name: 'United States'},
    city: 'Roswell Intl Air Center',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 33.301556,
    longitude: -104.530556,
    altitude: 1118.9208
  },
  {
    iataCode: 'ANI',
    icaoCode: 'PANI',
    name: 'Aniak',
    country: {code: 'US', name: 'United States'},
    city: 'Aniak Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.5816,
    longitude: -159.543,
    altitude: 26.8224
  },
  {
    iataCode: 'ANK',
    icaoCode: 'LTAD',
    name: 'Ankara',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Etimesgut',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.949831,
    longitude: 32.688622,
    altitude: 808.6344
  },
  {
    iataCode: 'ANM',
    icaoCode: 'FMNH',
    name: 'Antalaha',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Antsirabato',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -14.999411,
    longitude: 50.320233,
    altitude: 6.096
  },
  {
    iataCode: 'ANN',
    icaoCode: 'PANT',
    name: 'Annette Island',
    country: {code: 'US', name: 'United States'},
    city: 'Annette Island',
    region: '',
    terminal: '',
    timezoneId: 'America/Metlakatla',
    utcOffset: -540,
    latitude: 55.042436,
    longitude: -131.572233,
    altitude: 36.2712
  },
  {
    iataCode: 'ANR',
    icaoCode: 'EBAW',
    name: 'Antwerp',
    country: {code: 'BE', name: 'Belgium'},
    city: 'Deurne',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Brussels',
    utcOffset: 60,
    latitude: 51.189444,
    longitude: 4.460278,
    altitude: 11.8872
  },
  {
    iataCode: 'ANS',
    icaoCode: 'SPHY',
    name: 'Andahuaylas',
    country: {code: 'PE', name: 'Peru'},
    city: 'Andahuaylas',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -13.706408,
    longitude: -73.350378,
    altitude: 3444.24
  },
  {
    iataCode: 'ANU',
    icaoCode: 'TAPA',
    name: 'Antigua',
    country: {code: 'AG', name: 'Antigua and Barbuda'},
    city: 'V C Bird Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Antigua',
    utcOffset: -240,
    latitude: 17.136749,
    longitude: -61.792667,
    altitude: 18.8976
  },
  {
    iataCode: 'ANV',
    icaoCode: 'PANV',
    name: 'Anvik',
    country: {code: 'US', name: 'United States'},
    city: 'Anvik Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 62.6467,
    longitude: -160.191,
    altitude: 94.1832
  },
  {
    iataCode: 'ANX',
    icaoCode: 'ENAN',
    name: 'Andoya',
    country: {code: 'NO', name: 'Norway'},
    city: 'Andenes',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 69.2925,
    longitude: 16.144167,
    altitude: 13.1064
  },
  {
    iataCode: 'RPN',
    icaoCode: 'LLIB',
    name: 'Rosh Pina',
    country: {code: 'IL', name: 'Israel'},
    city: 'Mahanaim I Ben Yaakov',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jerusalem',
    utcOffset: 120,
    latitude: 32.981047,
    longitude: 35.571908,
    altitude: 281.0256
  },
  {
    iataCode: 'AOC',
    icaoCode: 'EDAC',
    name: 'Altenburg',
    country: {code: 'DE', name: 'Germany'},
    city: 'Altenburg Nobitz',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 50.981817,
    longitude: 12.506361,
    altitude: 195.072
  },
  {
    iataCode: 'RPR',
    icaoCode: 'VARP',
    name: 'Raipur',
    country: {code: 'IN', name: 'India'},
    city: 'Raipur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 21.180406,
    longitude: 81.738753,
    altitude: 317.2968
  },
  {
    iataCode: 'AOE',
    icaoCode: 'LTBY',
    name: 'Eskissehir',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Anadolu Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 39.809858,
    longitude: 30.519378,
    altitude: 788.8224
  },
  {
    iataCode: 'AOI',
    icaoCode: 'LIPY',
    name: 'Ancona',
    country: {code: 'IT', name: 'Italy'},
    city: 'Falconara',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 43.616389,
    longitude: 13.362222,
    altitude: 3.048
  },
  {
    iataCode: 'AOJ',
    icaoCode: 'RJSA',
    name: 'Aomori',
    country: {code: 'JP', name: 'Japan'},
    city: 'Aomori',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 40.734722,
    longitude: 140.690833,
    altitude: 202.3872
  },
  {
    iataCode: 'AOK',
    icaoCode: 'LGKP',
    name: 'Karpathos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Karpathos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 35.421408,
    longitude: 27.146008,
    altitude: 20.1168
  },
  {
    iataCode: 'AOL',
    icaoCode: 'SARL',
    name: 'Paso De Los Libres',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Paso De Los Libres',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Cordoba',
    utcOffset: -180,
    latitude: -29.689425,
    longitude: -57.152078,
    altitude: 70.104
  },
  {
    iataCode: 'AOO',
    icaoCode: 'KAOO',
    name: 'Altoona',
    country: {code: 'US', name: 'United States'},
    city: 'Altoona Blair Co',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.296372,
    longitude: -78.320022,
    altitude: 458.4192
  },
  {
    iataCode: 'AOR',
    icaoCode: 'WMKA',
    name: 'Alor Setar',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Abdul Halim',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 6.189667,
    longitude: 100.398183,
    altitude: 4.572
  },
  {
    iataCode: 'AOT',
    icaoCode: 'LIMW',
    name: 'Aosta',
    country: {code: 'IT', name: 'Italy'},
    city: 'Aosta Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 45.738456,
    longitude: 7.368719,
    altitude: 545.8968
  },
  {
    iataCode: 'JAA',
    icaoCode: 'OAJL',
    name: 'Jalalabad',
    country: {code: 'AF', name: 'Afghanistan'},
    city: 'Jalalabad',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kabul',
    utcOffset: 300,
    latitude: 34.399842,
    longitude: 70.498625,
    altitude: 552.9072
  },
  {
    iataCode: 'JAD',
    icaoCode: 'YPJT',
    name: 'Perth',
    country: {code: 'AU', name: 'Australia'},
    city: 'Perth Jandakot',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -32.0975,
    longitude: 115.881111,
    altitude: 30.1752
  },
  {
    iataCode: 'JAC',
    icaoCode: 'KJAC',
    name: 'Jacksn Hole',
    country: {code: 'US', name: 'United States'},
    city: 'Jackson Hole Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 43.607333,
    longitude: -110.73775,
    altitude: 1966.2648
  },
  {
    iataCode: 'JAF',
    icaoCode: 'VCCJ',
    name: 'Jaffna',
    country: {code: 'LK', name: 'Sri Lanka'},
    city: 'Kankesanturai',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Colombo',
    utcOffset: 360,
    latitude: 9.792331,
    longitude: 80.070089,
    altitude: 10.0584
  },
  {
    iataCode: 'APA',
    icaoCode: 'KAPA',
    name: 'Denver',
    country: {code: 'US', name: 'United States'},
    city: 'Centennial',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.570129,
    longitude: -104.849294,
    altitude: 1793.1384
  },
  {
    iataCode: 'JAI',
    icaoCode: 'VIJP',
    name: 'Jaipur',
    country: {code: 'IN', name: 'India'},
    city: 'Jaipur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.824192,
    longitude: 75.812161,
    altitude: 384.9624
  },
  {
    iataCode: 'APC',
    icaoCode: 'KAPC',
    name: 'Napa',
    country: {code: 'US', name: 'United States'},
    city: 'Napa County Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -420,
    latitude: 38.213194,
    longitude: -122.280694,
    altitude: 10.668
  },
  {
    iataCode: 'JAL',
    icaoCode: 'MMJA',
    name: 'Jalapa',
    country: {code: 'MX', name: 'Mexico'},
    city: 'Lencero Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Mexico_City',
    utcOffset: -360,
    latitude: 19.475083,
    longitude: -96.797506,
    altitude: 953.1096
  },
  {
    iataCode: 'JAN',
    icaoCode: 'KJAN',
    name: 'Jackson',
    country: {code: 'US', name: 'United States'},
    city: 'Jackson Evers Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.311167,
    longitude: -90.075889,
    altitude: 105.4608
  },
  {
    iataCode: 'APF',
    icaoCode: 'KAPF',
    name: 'Naples',
    country: {code: 'US', name: 'United States'},
    city: 'Naples Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.152619,
    longitude: -81.775294,
    altitude: 2.4384
  },
  {
    iataCode: 'APG',
    icaoCode: 'KAPG',
    name: 'Aberdeen',
    country: {code: 'US', name: 'United States'},
    city: 'Phillips Aaf',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 39.466219,
    longitude: -76.168808,
    altitude: 17.3736
  },
  {
    iataCode: 'APK',
    icaoCode: 'NTGD',
    name: 'Apataki',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Apataki Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -15.5736,
    longitude: -146.415,
    altitude: 2.4384
  },
  {
    iataCode: 'APL',
    icaoCode: 'FQNP',
    name: 'Nampula',
    country: {code: 'MZ', name: 'Mozambique'},
    city: 'Nampula',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Maputo',
    utcOffset: 120,
    latitude: -15.105611,
    longitude: 39.2818,
    altitude: 440.1312
  },
  {
    iataCode: 'JAV',
    icaoCode: 'BGJN',
    name: 'Ilulissat',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Ilulissat',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 69.23444,
    longitude: -51.05111,
    altitude: 0.6096
  },
  {
    iataCode: 'APN',
    icaoCode: 'KAPN',
    name: 'Alpena',
    country: {code: 'US', name: 'United States'},
    city: 'Alpena County Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 45.0781,
    longitude: -83.5603,
    altitude: 210.0072
  },
  {
    iataCode: 'JAX',
    icaoCode: 'KJAX',
    name: 'Jacksonville',
    country: {code: 'US', name: 'United States'},
    city: 'Jacksonville Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 30.494056,
    longitude: -81.687861,
    altitude: 9.144
  },
  {
    iataCode: 'RRG',
    icaoCode: 'FIMR',
    name: 'Rodriguez Island',
    country: {code: 'MU', name: 'Mauritius'},
    city: 'Plaine Corail',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Mauritius',
    utcOffset: 240,
    latitude: -19.757658,
    longitude: 63.360983,
    altitude: 28.956
  },
  {
    iataCode: 'APW',
    icaoCode: 'NSFA',
    name: 'Faleolo',
    country: {code: 'WS', name: 'Samoa'},
    city: 'Faleolo Intl',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Apia',
    utcOffset: -660,
    latitude: -13.829969,
    longitude: -172.008336,
    altitude: 17.6784
  },
  {
    iataCode: 'RRK',
    icaoCode: 'VERK',
    name: 'Rourkela',
    country: {code: 'IN', name: 'India'},
    city: 'Rourkela',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.25665,
    longitude: 84.814567,
    altitude: 200.8632
  },
  {
    iataCode: 'APZ',
    icaoCode: 'SAHZ',
    name: 'Zapala',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Zapala',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -38.9755,
    longitude: -70.113581,
    altitude: 1014.984
  },
  {
    iataCode: 'AQA',
    icaoCode: 'SBAQ',
    name: 'Araracuara',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Araraquara',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.812,
    longitude: -48.133028,
    altitude: 711.4032
  },
  {
    iataCode: 'RRS',
    icaoCode: 'ENRO',
    name: 'Roros',
    country: {code: 'NO', name: 'Norway'},
    city: 'Roros',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 62.578411,
    longitude: 11.342347,
    altitude: 626.0592
  },
  {
    iataCode: 'AQC',
    icaoCode: 'PAQC',
    name: 'Klawock',
    country: {code: 'US', name: 'United States'},
    city: 'Klawock Seaplane Base',
    region: '',
    terminal: '',
    timezoneId: 'America/Metlakatla',
    utcOffset: -540,
    latitude: 55.554658,
    longitude: -133.101693,
    altitude: 0.0
  },
  {
    iataCode: 'S46',
    icaoCode: 'XS46',
    name: "Port O\\\\'connor",
    country: {code: 'US', name: 'United States'},
    city: "Port O\\\\'connor Airfield",
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 28.429977,
    longitude: -96.442859,
    altitude: 3.048
  },
  {
    iataCode: 'AQG',
    icaoCode: 'ZSAQ',
    name: 'Anqing',
    country: {code: 'CN', name: 'China'},
    city: 'Anqing Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 30.5822,
    longitude: 117.0502,
    altitude: 0.0
  },
  {
    iataCode: 'JBQ',
    icaoCode: 'MDJB',
    name: 'La Isabela',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Dr Joaquin Balaguer International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 18.5725,
    longitude: -69.9856,
    altitude: 29.8704
  },
  {
    iataCode: 'AQI',
    icaoCode: 'OEPA',
    name: 'Hafr Al-batin',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'Qaisumah',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 28.335192,
    longitude: 46.125069,
    altitude: 357.8352
  },
  {
    iataCode: 'AQJ',
    icaoCode: 'OJAQ',
    name: 'Aqaba',
    country: {code: 'JO', name: 'Jordan'},
    city: 'Aqaba King Hussein Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Amman',
    utcOffset: 120,
    latitude: 29.611619,
    longitude: 35.018067,
    altitude: 53.34
  },
  {
    iataCode: 'JBR',
    icaoCode: 'KJBR',
    name: 'Jonesboro',
    country: {code: 'US', name: 'United States'},
    city: 'Jonesboro Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 35.831708,
    longitude: -90.646417,
    altitude: 79.8576
  },
  {
    iataCode: 'AQP',
    icaoCode: 'SPQU',
    name: 'Arequipa',
    country: {code: 'PE', name: 'Peru'},
    city: 'Rodriguez Ballon',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -16.341072,
    longitude: -71.583083,
    altitude: 2561.844
  },
  {
    iataCode: 'RSA',
    icaoCode: 'SAZR',
    name: 'Santa Rosa',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Santa Rosa',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Salta',
    utcOffset: -180,
    latitude: -36.588322,
    longitude: -64.275694,
    altitude: 192.024
  },
  {
    iataCode: 'RSD',
    icaoCode: 'MYER',
    name: 'Rock Sound',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Rock Sound',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.8917,
    longitude: -76.177739,
    altitude: 3.048
  },
  {
    iataCode: 'RSH',
    icaoCode: 'PARS',
    name: 'Russian Mission',
    country: {code: 'US', name: 'United States'},
    city: 'Russian Mission Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 61.775,
    longitude: -161.319444,
    altitude: 15.5448
  },
  {
    iataCode: 'JCB',
    icaoCode: 'SSJA',
    name: 'Joacaba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Joacaba Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -27.172778,
    longitude: -51.500833,
    altitude: 522.1224
  },
  {
    iataCode: 'JCH',
    icaoCode: 'BGCH',
    name: 'Qasigiannguit',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Qasigiannguit',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 68.833336,
    longitude: -51.0,
    altitude: 0.6096
  },
  {
    iataCode: 'ARA',
    icaoCode: 'KARA',
    name: 'Louisiana',
    country: {code: 'US', name: 'United States'},
    city: 'Acadiana Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.037758,
    longitude: -91.883896,
    altitude: 7.3152
  },
  {
    iataCode: 'ARC',
    icaoCode: 'PARC',
    name: 'Arctic Village',
    country: {code: 'US', name: 'United States'},
    city: 'Arctic Village Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 68.1147,
    longitude: -145.579,
    altitude: 637.6416
  },
  {
    iataCode: 'RST',
    icaoCode: 'KRST',
    name: 'Rochester',
    country: {code: 'US', name: 'United States'},
    city: 'Rochester',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 43.908283,
    longitude: -92.500014,
    altitude: 401.4216
  },
  {
    iataCode: 'ARD',
    icaoCode: 'WATM',
    name: 'Alor Island',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Mali Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.13234,
    longitude: 124.597,
    altitude: 3.048
  },
  {
    iataCode: 'JCK',
    icaoCode: 'YJLC',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Julia Creek Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -20.6683,
    longitude: 141.723,
    altitude: 123.1392
  },
  {
    iataCode: 'ARE',
    icaoCode: 'TJAB',
    name: 'Arecibo',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Antonio Juarbe Pol Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.451111,
    longitude: -66.675556,
    altitude: 7.0104
  },
  {
    iataCode: 'RSU',
    icaoCode: 'RKJY',
    name: 'Yeosu',
    country: {code: 'KR', name: 'Republic of Korea'},
    city: 'Yeosu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Seoul',
    utcOffset: 540,
    latitude: 34.842328,
    longitude: 127.61685,
    altitude: 16.1544
  },
  {
    iataCode: 'ARH',
    icaoCode: 'ULAA',
    name: 'Arkhangelsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Talagi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 64.360281,
    longitude: 40.430167,
    altitude: 5.7912
  },
  {
    iataCode: 'RSW',
    icaoCode: 'KRSW',
    name: 'Fort Myers',
    country: {code: 'US', name: 'United States'},
    city: 'Southwest Florida Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 26.536167,
    longitude: -81.755167,
    altitude: 9.144
  },
  {
    iataCode: 'ARI',
    icaoCode: 'SCAR',
    name: 'Arica',
    country: {code: 'CL', name: 'Chile'},
    city: 'Chacalluta',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -18.348531,
    longitude: -70.338742,
    altitude: 50.9016
  },
  {
    iataCode: 'ARK',
    icaoCode: 'HTAR',
    name: 'Arusha',
    country: {code: 'TZ', name: 'United Republic of Tanzania'},
    city: 'Arusha',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Dar_es_Salaam',
    utcOffset: 180,
    latitude: -3.367794,
    longitude: 36.633333,
    altitude: 1386.84
  },
  {
    iataCode: 'ARM',
    icaoCode: 'YARM',
    name: 'Armidale',
    country: {code: 'AU', name: 'Australia'},
    city: 'Armidale',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Sydney',
    utcOffset: 600,
    latitude: -30.528056,
    longitude: 151.617222,
    altitude: 1083.8688
  },
  {
    iataCode: 'ARN',
    icaoCode: 'ESSA',
    name: 'Stockholm',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Arlanda',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 59.651944,
    longitude: 17.918611,
    altitude: 41.7576
  },
  {
    iataCode: 'JCU',
    icaoCode: 'GECT',
    name: 'Ceuta',
    country: {code: 'ES', name: 'Spain'},
    city: 'Ceuta Heliport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Ceuta',
    utcOffset: 0,
    latitude: 35.8969,
    longitude: -5.29908,
    altitude: 0.0
  },
  {
    iataCode: 'RTA',
    icaoCode: 'NFNR',
    name: 'Rotuma',
    country: {code: 'FJ', name: 'Fiji'},
    city: 'Rotuma Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Fiji',
    utcOffset: 720,
    latitude: -12.4825,
    longitude: 177.071,
    altitude: 6.7056
  },
  {
    iataCode: 'ARR',
    icaoCode: 'SAVR',
    name: 'Alto Rio Senguer',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Alto Rio Senguer Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Catamarca',
    utcOffset: -180,
    latitude: -45.016667,
    longitude: -70.816667,
    altitude: 653.796
  },
  {
    iataCode: 'RTB',
    icaoCode: 'MHRO',
    name: 'Roatan',
    country: {code: 'HN', name: 'Honduras'},
    city: 'Juan Manuel Galvez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 16.316814,
    longitude: -86.522961,
    altitude: 5.4864
  },
  {
    iataCode: 'ART',
    icaoCode: 'KART',
    name: 'Watertown',
    country: {code: 'US', name: 'United States'},
    city: 'Watertown Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.991922,
    longitude: -76.021739,
    altitude: 99.06
  },
  {
    iataCode: 'ARU',
    icaoCode: 'SBAU',
    name: 'Aracatuba',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Aracatuba',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.141342,
    longitude: -50.424722,
    altitude: 414.8328
  },
  {
    iataCode: 'RTG',
    icaoCode: 'WATG',
    name: 'Ruteng',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Satar Tacik',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Makassar',
    utcOffset: 480,
    latitude: -8.597011,
    longitude: 120.477061,
    altitude: 1069.848
  },
  {
    iataCode: 'ARV',
    icaoCode: 'KARV',
    name: 'Minocqua - Woodruff',
    country: {code: 'US', name: 'United States'},
    city: 'Lakeland',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 45.927778,
    longitude: -89.730833,
    altitude: 496.5192
  },
  {
    iataCode: 'ARW',
    icaoCode: 'LRAR',
    name: 'Arad',
    country: {code: 'RO', name: 'Romania'},
    city: 'Arad',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 46.17655,
    longitude: 21.262022,
    altitude: 107.2896
  },
  {
    iataCode: 'RTM',
    icaoCode: 'EHRD',
    name: 'Rotterdam',
    country: {code: 'NL', name: 'Netherlands'},
    city: 'Rotterdam',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Amsterdam',
    utcOffset: 60,
    latitude: 51.956944,
    longitude: 4.437222,
    altitude: -4.572
  },
  {
    iataCode: 'JDF',
    icaoCode: 'SBJF',
    name: 'Juiz De Fora',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Francisco De Assis',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -21.7915,
    longitude: -43.386778,
    altitude: 911.0472
  },
  {
    iataCode: 'ASA',
    icaoCode: 'HHSB',
    name: 'Assab',
    country: {code: 'ER', name: 'Eritrea'},
    city: 'Assab Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Asmara',
    utcOffset: 180,
    latitude: 13.071783,
    longitude: 42.645006,
    altitude: 14.0208
  },
  {
    iataCode: 'JDH',
    icaoCode: 'VIJO',
    name: 'Jodhpur',
    country: {code: 'IN', name: 'India'},
    city: 'Jodhpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 26.251092,
    longitude: 73.048869,
    altitude: 218.5416
  },
  {
    iataCode: 'ASB',
    icaoCode: 'UTAA',
    name: 'Ashkhabad',
    country: {code: 'TM', name: 'Turkmenistan'},
    city: 'Ashgabat',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ashgabat',
    utcOffset: 300,
    latitude: 37.986814,
    longitude: 58.360967,
    altitude: 210.9216
  },
  {
    iataCode: 'ASD',
    icaoCode: 'MYAF',
    name: 'Andros Town',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Fresh Creek',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.698283,
    longitude: -77.795611,
    altitude: 1.524
  },
  {
    iataCode: 'ASE',
    icaoCode: 'KASE',
    name: 'Aspen',
    country: {code: 'US', name: 'United States'},
    city: 'Aspen Pitkin County Sardy Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 39.2232,
    longitude: -106.869,
    altitude: 2383.536
  },
  {
    iataCode: 'ASF',
    icaoCode: 'URWA',
    name: 'Astrakhan',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Astrakhan',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 300,
    latitude: 46.283333,
    longitude: 48.006278,
    altitude: -19.812
  },
  {
    iataCode: 'JDO',
    icaoCode: 'SBJU',
    name: 'Juazeiro Do Norte',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Orlando Bezerra De Menezes Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Fortaleza',
    utcOffset: -180,
    latitude: -7.218958,
    longitude: -39.2701,
    altitude: 424.2816
  },
  {
    iataCode: 'RTW',
    icaoCode: 'UWSS',
    name: 'Saratov',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Central',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Volgograd',
    utcOffset: 300,
    latitude: 51.334366,
    longitude: 46.022952,
    altitude: 46.3296
  },
  {
    iataCode: 'ASJ',
    icaoCode: 'RJKA',
    name: 'Amami',
    country: {code: 'JP', name: 'Japan'},
    city: 'Amami',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 28.430633,
    longitude: 129.712542,
    altitude: 8.2296
  },
  {
    iataCode: 'ASK',
    icaoCode: 'DIYO',
    name: 'Yamoussoukro',
    country: {code: 'CI', name: "Côte d'Ivoire"},
    city: 'Yamoussoukro',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Abidjan',
    utcOffset: 0,
    latitude: 6.903167,
    longitude: -5.365581,
    altitude: 213.0552
  },
  {
    iataCode: 'ASM',
    icaoCode: 'HHAS',
    name: 'Asmara',
    country: {code: 'ER', name: 'Eritrea'},
    city: 'Asmara Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Asmara',
    utcOffset: 180,
    latitude: 15.291853,
    longitude: 38.910667,
    altitude: 2335.0728
  },
  {
    iataCode: 'ASO',
    icaoCode: 'HASO',
    name: 'Asosa',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Asosa',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 10.01855,
    longitude: 34.586253,
    altitude: 1560.576
  },
  {
    iataCode: 'ASP',
    icaoCode: 'YBAS',
    name: 'Alice Springs',
    country: {code: 'AU', name: 'Australia'},
    city: 'Alice Springs',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -23.806667,
    longitude: 133.902222,
    altitude: 545.2872
  },
  {
    iataCode: 'RUA',
    icaoCode: 'HUAR',
    name: 'Arua',
    country: {code: 'UG', name: 'Uganda'},
    city: 'Arua Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Kampala',
    utcOffset: 180,
    latitude: 3.05,
    longitude: 30.917,
    altitude: 1204.2648
  },
  {
    iataCode: 'ASR',
    icaoCode: 'LTAU',
    name: 'Kayseri',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Erkilet',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 38.770386,
    longitude: 35.495428,
    altitude: 1055.5224
  },
  {
    iataCode: 'JDZ',
    icaoCode: 'ZSJD',
    name: 'Jingdezhen',
    country: {code: 'CN', name: 'China'},
    city: 'Jingdezhen Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.3386,
    longitude: 117.176,
    altitude: 34.1376
  },
  {
    iataCode: 'AST',
    icaoCode: 'KAST',
    name: 'Astoria',
    country: {code: 'US', name: 'United States'},
    city: 'Astoria Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 46.157972,
    longitude: -123.878694,
    altitude: 4.572
  },
  {
    iataCode: 'ASU',
    icaoCode: 'SGAS',
    name: 'Asuncion',
    country: {code: 'PY', name: 'Paraguay'},
    city: 'Silvio Pettirossi Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Asuncion',
    utcOffset: -240,
    latitude: -25.23985,
    longitude: -57.519133,
    altitude: 89.0016
  },
  {
    iataCode: 'ASV',
    icaoCode: 'HKAM',
    name: 'Amboseli National Park',
    country: {code: 'KE', name: 'Kenya'},
    city: 'Amboseli Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nairobi',
    utcOffset: 180,
    latitude: -2.64505,
    longitude: 37.2531,
    altitude: 1144.524
  },
  {
    iataCode: 'RUH',
    icaoCode: 'OERK',
    name: 'Riyadh',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Khaled Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 24.95764,
    longitude: 46.698776,
    altitude: 624.5352
  },
  {
    iataCode: 'ASW',
    icaoCode: 'HESN',
    name: 'Aswan',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Aswan Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 23.964356,
    longitude: 32.819975,
    altitude: 201.7776
  },
  {
    iataCode: 'JED',
    icaoCode: 'OEJN',
    name: 'Jeddah',
    country: {code: 'SA', name: 'Saudi Arabia'},
    city: 'King Abdulaziz Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Riyadh',
    utcOffset: 180,
    latitude: 21.679564,
    longitude: 39.156536,
    altitude: 14.6304
  },
  {
    iataCode: 'RUK',
    icaoCode: 'VNRK',
    name: 'Rukumkot',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Rukumkot',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.627,
    longitude: 82.195,
    altitude: 762.0
  },
  {
    iataCode: 'JEF',
    icaoCode: 'KJEF',
    name: 'Jefferson City',
    country: {code: 'US', name: 'United States'},
    city: 'Jefferson City Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 38.5912,
    longitude: -92.1561,
    altitude: 167.3352
  },
  {
    iataCode: 'RUN',
    icaoCode: 'FMEE',
    name: 'St.-denis',
    country: {code: 'RE', name: 'Réunion'},
    city: 'St Denis Gillot',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Reunion',
    utcOffset: 300,
    latitude: -20.8871,
    longitude: 55.510308,
    altitude: 20.1168
  },
  {
    iataCode: 'JEE',
    icaoCode: 'MTJE',
    name: 'Jeremie',
    country: {code: 'HT', name: 'Haiti'},
    city: 'Jeremie Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Port-au-Prince',
    utcOffset: -300,
    latitude: 18.6631,
    longitude: -74.1703,
    altitude: 44.8056
  },
  {
    iataCode: 'RUM',
    icaoCode: 'VNRT',
    name: 'Rumjatar',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Rumjatar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 27.303509,
    longitude: 86.55043,
    altitude: 1371.6
  },
  {
    iataCode: 'JEG',
    icaoCode: 'BGEM',
    name: 'Aasiaat',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Aasiaat',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 68.7,
    longitude: -52.75,
    altitude: 0.6096
  },
  {
    iataCode: 'ATA',
    icaoCode: 'SPHZ',
    name: 'Anta',
    country: {code: 'PE', name: 'Peru'},
    city: 'Comandante Fap German Arias Graziani',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -9.347444,
    longitude: -77.598392,
    altitude: 2772.7656
  },
  {
    iataCode: 'RUR',
    icaoCode: 'NTAR',
    name: 'Rurutu',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Rurutu',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -22.434069,
    longitude: -151.360614,
    altitude: 5.4864
  },
  {
    iataCode: 'ATB',
    icaoCode: 'HSAT',
    name: 'Atbara',
    country: {code: 'SD', name: 'Sudan'},
    city: 'Atbara Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Khartoum',
    utcOffset: 120,
    latitude: 17.7,
    longitude: 33.967,
    altitude: 359.9688
  },
  {
    iataCode: 'ATC',
    icaoCode: 'MYCA',
    name: "Arthur's Town",
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Arthurs Town Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 24.629417,
    longitude: -75.673775,
    altitude: 5.4864
  },
  {
    iataCode: 'RUT',
    icaoCode: 'KRUT',
    name: 'Rutland',
    country: {code: 'US', name: 'United States'},
    city: 'Rutland State Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 43.5294,
    longitude: -72.9496,
    altitude: 239.8776
  },
  {
    iataCode: 'ATD',
    icaoCode: 'AGAT',
    name: 'Atoifi',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Uru Harbour Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -8.87333,
    longitude: 161.011,
    altitude: 0.0
  },
  {
    iataCode: 'RUS',
    icaoCode: 'AGGU',
    name: 'Marau',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Marau Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -9.86167,
    longitude: 160.825,
    altitude: 0.0
  },
  {
    iataCode: 'ATF',
    icaoCode: 'SEAM',
    name: 'Ambato',
    country: {code: 'EC', name: 'Ecuador'},
    city: 'Chachoan',
    region: '',
    terminal: '',
    timezoneId: 'America/Guayaquil',
    utcOffset: -300,
    latitude: -1.212067,
    longitude: -78.574636,
    altitude: 2591.4096
  },
  {
    iataCode: 'ATH',
    icaoCode: 'LGAV',
    name: 'Athens',
    country: {code: 'GR', name: 'Greece'},
    city: 'Eleftherios Venizelos Intl',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.936358,
    longitude: 23.944467,
    altitude: 93.8784
  },
  {
    iataCode: 'JER',
    icaoCode: 'EGJJ',
    name: 'Jersey',
    country: {code: 'JE', name: 'Jersey'},
    city: 'Jersey',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Jersey',
    utcOffset: 0,
    latitude: 49.207947,
    longitude: -2.195508,
    altitude: 84.4296
  },
  {
    iataCode: 'ATK',
    icaoCode: 'PATQ',
    name: 'Atqasuk',
    country: {code: 'US', name: 'United States'},
    city: 'Atqasuk Edward Burnell Sr Memorial Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.4673,
    longitude: -157.436,
    altitude: 29.2608
  },
  {
    iataCode: 'ATL',
    icaoCode: 'KATL',
    name: 'Atlanta',
    country: {code: 'US', name: 'United States'},
    city: 'Hartsfield Jackson Atlanta Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 33.636719,
    longitude: -84.428067,
    altitude: 312.7248
  },
  {
    iataCode: 'ATM',
    icaoCode: 'SBHT',
    name: 'Altamira',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Altamira',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -180,
    latitude: -3.253906,
    longitude: -52.253978,
    altitude: 112.1664
  },
  {
    iataCode: 'RVA',
    icaoCode: 'FMSG',
    name: 'Farafangana',
    country: {code: 'MG', name: 'Madagascar'},
    city: 'Farafangana',
    region: '',
    terminal: '',
    timezoneId: 'Indian/Antananarivo',
    utcOffset: 180,
    latitude: -22.805286,
    longitude: 47.820614,
    altitude: 7.9248
  },
  {
    iataCode: 'ATQ',
    icaoCode: 'VIAR',
    name: 'Amritsar',
    country: {code: 'IN', name: 'India'},
    city: 'Amritsar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 31.709594,
    longitude: 74.797264,
    altitude: 230.4288
  },
  {
    iataCode: 'ATR',
    icaoCode: 'GQPA',
    name: 'Atar',
    country: {code: 'MR', name: 'Mauritania'},
    city: 'Atar',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Nouakchott',
    utcOffset: 0,
    latitude: 20.506828,
    longitude: -13.043194,
    altitude: 231.0384
  },
  {
    iataCode: 'RVE',
    icaoCode: 'SKSA',
    name: 'Saravena',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Los Colonizadores Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 6.916667,
    longitude: -71.9,
    altitude: 3.048
  },
  {
    iataCode: 'ATT',
    icaoCode: 'KATT',
    name: 'Austin Tx',
    country: {code: 'US', name: 'United States'},
    city: 'Camp Mabry Austin City',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.31666,
    longitude: -97.7666,
    altitude: 0.0
  },
  {
    iataCode: 'RVD',
    icaoCode: 'SWLC',
    name: 'Rio Verde',
    country: {code: 'BR', name: 'Brazil'},
    city: 'General Leite De Castro Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -17.790278,
    longitude: -50.918333,
    altitude: 683.9712
  },
  {
    iataCode: 'ATW',
    icaoCode: 'KATW',
    name: 'Appleton',
    country: {code: 'US', name: 'United States'},
    city: 'Appleton',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.257526,
    longitude: -88.507576,
    altitude: 207.264
  },
  {
    iataCode: 'ATY',
    icaoCode: 'KATY',
    name: 'Watertown',
    country: {code: 'US', name: 'United States'},
    city: 'Watertown Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 44.914,
    longitude: -97.1547,
    altitude: 532.7904
  },
  {
    iataCode: 'RVK',
    icaoCode: 'ENRM',
    name: 'Rørvik',
    country: {code: 'NO', name: 'Norway'},
    city: 'Ryum Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 64.8383,
    longitude: 11.1461,
    altitude: 4.2672
  },
  {
    iataCode: 'ATZ',
    icaoCode: 'HEAT',
    name: 'Asyut',
    country: {code: 'EG', name: 'Egypt'},
    city: 'Asyut International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Cairo',
    utcOffset: 120,
    latitude: 27.046508,
    longitude: 31.011983,
    altitude: 235.3056
  },
  {
    iataCode: 'RVN',
    icaoCode: 'EFRO',
    name: 'Rovaniemi',
    country: {code: 'FI', name: 'Finland'},
    city: 'Rovaniemi',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 66.564822,
    longitude: 25.830411,
    altitude: 195.6816
  },
  {
    iataCode: 'AUA',
    icaoCode: 'TNCA',
    name: 'Oranjestad',
    country: {code: 'AW', name: 'Aruba'},
    city: 'Reina Beatrix Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Aruba',
    utcOffset: -240,
    latitude: 12.501389,
    longitude: -70.015221,
    altitude: 18.288
  },
  {
    iataCode: 'JFK',
    icaoCode: 'KJFK',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'John F Kennedy Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.639751,
    longitude: -73.778925,
    altitude: 3.9624
  },
  {
    iataCode: 'AUC',
    icaoCode: 'SKUC',
    name: 'Arauca',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Santiago Perez',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -240,
    latitude: 7.068881,
    longitude: -70.736925,
    altitude: 128.016
  },
  {
    iataCode: 'RVR',
    icaoCode: 'TJRV',
    name: 'Ceiba',
    country: {code: 'PR', name: 'Puerto Rico'},
    city: 'Jose Aponte De La Torre Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Puerto_Rico',
    utcOffset: -240,
    latitude: 18.245278,
    longitude: -65.643333,
    altitude: 11.5824
  },
  {
    iataCode: 'RVT',
    icaoCode: 'YNRV',
    name: 'Ravensthorpe',
    country: {code: 'AU', name: 'Australia'},
    city: 'Ravensthorpe Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Perth',
    utcOffset: 480,
    latitude: -33.797222,
    longitude: 120.208056,
    altitude: 63.7032
  },
  {
    iataCode: 'AUF',
    icaoCode: 'LFLA',
    name: 'Auxerre',
    country: {code: 'FR', name: 'France'},
    city: 'Branches',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 47.850193,
    longitude: 3.497111,
    altitude: 159.4104
  },
  {
    iataCode: 'AUG',
    icaoCode: 'KAUG',
    name: 'Augusta',
    country: {code: 'US', name: 'United States'},
    city: 'Augusta State',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 44.320647,
    longitude: -69.797317,
    altitude: 107.2896
  },
  {
    iataCode: 'RVV',
    icaoCode: 'NTAV',
    name: 'Raivavae',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Raivavae Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: 600,
    latitude: -23.87,
    longitude: -147.67,
    altitude: 7.62
  },
  {
    iataCode: 'AUH',
    icaoCode: 'OMAA',
    name: 'Abu Dhabi',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Abu Dhabi Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 24.432972,
    longitude: 54.651138,
    altitude: 26.8224
  },
  {
    iataCode: 'AUK',
    icaoCode: 'PAUK',
    name: 'Alakanuk',
    country: {code: 'US', name: 'United States'},
    city: 'Alakanuk Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 62.68,
    longitude: -164.66,
    altitude: 3.048
  },
  {
    iataCode: 'JFR',
    icaoCode: 'BGFH',
    name: 'Paamiut',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Paamiut Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 61.9922,
    longitude: -49.6625,
    altitude: 0.0
  },
  {
    iataCode: 'AUQ',
    icaoCode: 'NTMN',
    name: 'Hiva-oa',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Hiva Oa-atuona Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Marquesas',
    utcOffset: -540,
    latitude: -9.768794,
    longitude: -139.011256,
    altitude: 451.4088
  },
  {
    iataCode: 'AUR',
    icaoCode: 'LFLW',
    name: 'Aurillac',
    country: {code: 'FR', name: 'France'},
    city: 'Aurillac',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 44.891388,
    longitude: 2.421944,
    altitude: 638.8608
  },
  {
    iataCode: 'AUS',
    icaoCode: 'KAUS',
    name: 'Austin',
    country: {code: 'US', name: 'United States'},
    city: 'Austin Bergstrom Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 30.194528,
    longitude: -97.669889,
    altitude: 165.2016
  },
  {
    iataCode: 'AUU',
    icaoCode: 'YAUR',
    name: '',
    country: {code: 'AU', name: 'Australia'},
    city: 'Aurukun Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -13.3539,
    longitude: 141.721,
    altitude: 9.4488
  },
  {
    iataCode: 'AUX',
    icaoCode: 'SWGN',
    name: 'Araguaina',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Araguaina Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Araguaina',
    utcOffset: -180,
    latitude: -7.228333,
    longitude: -48.240833,
    altitude: 235.0008
  },
  {
    iataCode: 'AUY',
    icaoCode: 'NVVA',
    name: 'Anelghowhat',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Anelghowhat Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -20.2492,
    longitude: 169.771,
    altitude: 2.1336
  },
  {
    iataCode: 'JGA',
    icaoCode: 'VAJM',
    name: 'Jamnagar',
    country: {code: 'IN', name: 'India'},
    city: 'Jamnagar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 22.465522,
    longitude: 70.012556,
    altitude: 21.0312
  },
  {
    iataCode: 'RWI',
    icaoCode: 'KRWI',
    name: 'Rocky Mount',
    country: {code: 'US', name: 'United States'},
    city: 'Rocky Mount Wilson Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.8563,
    longitude: -77.8919,
    altitude: 48.4632
  },
  {
    iataCode: 'RWN',
    icaoCode: 'UKLR',
    name: 'Rivne',
    country: {code: 'UA', name: 'Ukraine'},
    city: 'Rivne International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Kiev',
    utcOffset: 120,
    latitude: 50.6071,
    longitude: 26.1416,
    altitude: 230.124
  },
  {
    iataCode: 'AVB',
    icaoCode: 'LIPA',
    name: 'Aviano',
    country: {code: 'IT', name: 'Italy'},
    city: 'Aviano Ab',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 46.031889,
    longitude: 12.596472,
    altitude: 124.968
  },
  {
    iataCode: 'JGN',
    icaoCode: 'ZLJQ',
    name: 'Jiayuguan',
    country: {code: 'CN', name: 'China'},
    city: 'Jiayuguan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 39.8569,
    longitude: 98.3414,
    altitude: 0.0
  },
  {
    iataCode: 'JGO',
    icaoCode: 'BGGN',
    name: 'Qeqertarsuaq Airport',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Qeqertarsuaq Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 69.2511,
    longitude: -53.5381,
    altitude: 0.0
  },
  {
    iataCode: 'AVI',
    icaoCode: 'MUCA',
    name: 'Ciego De Avila',
    country: {code: 'CU', name: 'Cuba'},
    city: 'Maximo Gomez',
    region: '',
    terminal: '',
    timezoneId: 'America/Havana',
    utcOffset: -300,
    latitude: 22.027053,
    longitude: -78.789617,
    altitude: 102.108
  },
  {
    iataCode: 'AVK',
    icaoCode: 'ZMAH',
    name: '',
    country: {code: 'MN', name: 'Mongolia'},
    city: 'Arvaikheer Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Ulaanbaatar',
    utcOffset: 480,
    latitude: 46.2503,
    longitude: 102.802,
    altitude: 1808.0736
  },
  {
    iataCode: 'AVL',
    icaoCode: 'KAVL',
    name: 'Asheville',
    country: {code: 'US', name: 'United States'},
    city: 'Asheville Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 35.436194,
    longitude: -82.541806,
    altitude: 659.892
  },
  {
    iataCode: 'AVN',
    icaoCode: 'LFMV',
    name: 'Avignon',
    country: {code: 'FR', name: 'France'},
    city: 'Caumont',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 43.9073,
    longitude: 4.901831,
    altitude: 37.7952
  },
  {
    iataCode: 'AVP',
    icaoCode: 'KAVP',
    name: 'Scranton',
    country: {code: 'US', name: 'United States'},
    city: 'Wilkes Barre Scranton Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 41.338478,
    longitude: -75.723403,
    altitude: 293.2176
  },
  {
    iataCode: 'AVV',
    icaoCode: 'YMAV',
    name: 'Avalon',
    country: {code: 'AU', name: 'Australia'},
    city: 'Avalon',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Melbourne',
    utcOffset: 600,
    latitude: -38.039444,
    longitude: 144.469444,
    altitude: 10.668
  },
  {
    iataCode: 'AVX',
    icaoCode: 'KAVX',
    name: 'Catalina Island',
    country: {code: 'US', name: 'United States'},
    city: 'Avalon',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 33.405,
    longitude: -118.415833,
    altitude: 488.2896
  },
  {
    iataCode: 'JHB',
    icaoCode: 'WMKJ',
    name: 'Johor Bahru',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sultan Ismail',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuala_Lumpur',
    utcOffset: 480,
    latitude: 1.641308,
    longitude: 103.669619,
    altitude: 41.148
  },
  {
    iataCode: 'AWA',
    icaoCode: 'HALA',
    name: 'Awasa',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Awasa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 7.067,
    longitude: 38.5,
    altitude: 1569.4152
  },
  {
    iataCode: 'RXS',
    icaoCode: 'RPVR',
    name: 'Roxas City',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Roxas Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 11.597669,
    longitude: 122.751669,
    altitude: 3.048
  },
  {
    iataCode: 'AWD',
    icaoCode: 'NVVB',
    name: 'Aniwa',
    country: {code: 'VU', name: 'Vanuatu'},
    city: 'Aniwa Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Efate',
    utcOffset: 660,
    latitude: -19.24,
    longitude: 169.605,
    altitude: 21.0312
  },
  {
    iataCode: 'JHM',
    icaoCode: 'PHJH',
    name: 'Lahania-kapalua',
    country: {code: 'US', name: 'United States'},
    city: 'Kapalua',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Honolulu',
    utcOffset: -600,
    latitude: 20.962936,
    longitude: -156.673031,
    altitude: 78.0288
  },
  {
    iataCode: 'JHS',
    icaoCode: 'BGSS',
    name: 'Sisimiut',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Sisimiut Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 66.9513,
    longitude: -53.7293,
    altitude: 10.0584
  },
  {
    iataCode: 'AWK',
    icaoCode: 'PWAK',
    name: 'Wake Island',
    country: {code: '', name: 'Wake Island'},
    city: 'Wake Island Afld',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Wake',
    utcOffset: 480,
    latitude: 19.282067,
    longitude: 166.636444,
    altitude: 4.2672
  },
  {
    iataCode: 'JHW',
    icaoCode: 'KJHW',
    name: 'Jamestown',
    country: {code: 'US', name: 'United States'},
    city: 'Chautauqua County-jamestown',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.153333,
    longitude: -79.258056,
    altitude: 160.02
  },
  {
    iataCode: 'RYB',
    icaoCode: 'UUBK',
    name: 'Rybinsk',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Staroselye Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 58.1042,
    longitude: 38.9294,
    altitude: 128.9304
  },
  {
    iataCode: 'RYG',
    icaoCode: 'ENRZ',
    name: 'Rygge',
    country: {code: 'NO', name: 'Norway'},
    city: 'Rygge',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Oslo',
    utcOffset: 60,
    latitude: 59.378934,
    longitude: 10.785389,
    altitude: 53.34
  },
  {
    iataCode: 'JIB',
    icaoCode: 'HDAM',
    name: 'Djibouti',
    country: {code: 'DJ', name: 'Djibouti'},
    city: 'Ambouli International Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Djibouti',
    utcOffset: 180,
    latitude: 11.5472,
    longitude: 43.1594,
    altitude: 14.9352
  },
  {
    iataCode: 'AWZ',
    icaoCode: 'OIAW',
    name: 'Ahwaz',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Ahwaz',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 31.337431,
    longitude: 48.76195,
    altitude: 20.1168
  },
  {
    iataCode: 'RYK',
    icaoCode: 'OPRK',
    name: 'Rahim Yar Khan',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Sheikh Zayed',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 28.3839,
    longitude: 70.279572,
    altitude: 82.6008
  },
  {
    iataCode: 'RYN',
    icaoCode: 'LFCY',
    name: 'Royan',
    country: {code: 'FR', name: 'France'},
    city: 'Medis',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 45.628056,
    longitude: -0.9725,
    altitude: 21.9456
  },
  {
    iataCode: 'AXA',
    icaoCode: 'TQPF',
    name: 'The Valley',
    country: {code: 'AI', name: 'Anguilla'},
    city: 'Wallblake',
    region: '',
    terminal: '',
    timezoneId: 'America/Anguilla',
    utcOffset: -240,
    latitude: 18.204834,
    longitude: -63.055084,
    altitude: 38.7096
  },
  {
    iataCode: 'JIJ',
    icaoCode: 'HAJJ',
    name: 'Jijiga',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Jijiga Airport',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 9.359722,
    longitude: 42.7875,
    altitude: 609.6
  },
  {
    iataCode: 'AXD',
    icaoCode: 'LGAL',
    name: 'Alexandroupolis',
    country: {code: 'GR', name: 'Greece'},
    city: 'Dimokritos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 40.855869,
    longitude: 25.956264,
    altitude: 7.3152
  },
  {
    iataCode: 'JIK',
    icaoCode: 'LGIK',
    name: 'Ikaria',
    country: {code: 'GR', name: 'Greece'},
    city: 'Ikaria',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.682717,
    longitude: 26.347061,
    altitude: 24.0792
  },
  {
    iataCode: 'JIM',
    icaoCode: 'HAJM',
    name: 'Jimma',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Jimma',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 7.666094,
    longitude: 36.816639,
    altitude: 1702.9176
  },
  {
    iataCode: 'AXJ',
    icaoCode: 'RJDA',
    name: 'Amakusa',
    country: {code: 'JP', name: 'Japan'},
    city: 'Amakusa Airfield',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 32.482222,
    longitude: 130.158889,
    altitude: 103.632
  },
  {
    iataCode: 'RYY',
    icaoCode: 'KRYY',
    name: 'Atlanta',
    country: {code: 'US', name: 'United States'},
    city: 'Cobb County Airport-mc Collum Field',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 34.013157,
    longitude: -84.597056,
    altitude: 317.2968
  },
  {
    iataCode: 'AXK',
    icaoCode: 'OYAT',
    name: 'Ataq',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Ataq',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 14.551322,
    longitude: 46.826183,
    altitude: 1138.428
  },
  {
    iataCode: 'AXM',
    icaoCode: 'SKAR',
    name: 'Armenia',
    country: {code: 'CO', name: 'Colombia'},
    city: 'El Eden',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 4.452775,
    longitude: -75.766447,
    altitude: 1216.152
  },
  {
    iataCode: 'JIU',
    icaoCode: 'ZSJJ',
    name: 'Jiujiang',
    country: {code: 'CN', name: 'China'},
    city: 'Jiujiang Lushan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 29.733,
    longitude: 115.983,
    altitude: 0.0
  },
  {
    iataCode: 'RZA',
    icaoCode: 'SAWU',
    name: 'Santa Cruz',
    country: {code: 'AR', name: 'Argentina'},
    city: 'Santa Cruz',
    region: '',
    terminal: '',
    timezoneId: 'America/Argentina/Rio_Gallegos',
    utcOffset: -180,
    latitude: -50.01655,
    longitude: -68.579197,
    altitude: 110.9472
  },
  {
    iataCode: 'AXP',
    icaoCode: 'MYAP',
    name: 'Spring Point',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'Spring Point',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 22.441828,
    longitude: -73.970858,
    altitude: 3.3528
  },
  {
    iataCode: 'JIW',
    icaoCode: 'OPJI',
    name: 'Jiwani',
    country: {code: 'PK', name: 'Pakistan'},
    city: 'Jiwani Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Karachi',
    utcOffset: 300,
    latitude: 25.0678,
    longitude: 61.8054,
    altitude: 56.6928
  },
  {
    iataCode: 'AXR',
    icaoCode: 'NTGU',
    name: 'Arutua',
    country: {code: 'PF', name: 'French Polynesia'},
    city: 'Arutua',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Tahiti',
    utcOffset: -600,
    latitude: -15.248289,
    longitude: -146.616708,
    altitude: 2.7432
  },
  {
    iataCode: 'RZE',
    icaoCode: 'EPRZ',
    name: 'Rzeszow',
    country: {code: 'PL', name: 'Poland'},
    city: 'Jasionka',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Warsaw',
    utcOffset: 60,
    latitude: 50.109958,
    longitude: 22.019,
    altitude: 205.74
  },
  {
    iataCode: 'AXT',
    icaoCode: 'RJSK',
    name: 'Akita',
    country: {code: 'JP', name: 'Japan'},
    city: 'Akita',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tokyo',
    utcOffset: 540,
    latitude: 39.615556,
    longitude: 140.218611,
    altitude: 95.4024
  },
  {
    iataCode: 'AXU',
    icaoCode: 'HAAX',
    name: 'Axum',
    country: {code: 'ET', name: 'Ethiopia'},
    city: 'Axum',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Addis_Ababa',
    utcOffset: 180,
    latitude: 14.14675,
    longitude: 38.772833,
    altitude: 2107.692
  },
  {
    iataCode: 'JJI',
    icaoCode: 'SPJI',
    name: 'Juanjui',
    country: {code: 'PE', name: 'Peru'},
    city: 'Juanjui',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -7.1691,
    longitude: -76.728561,
    altitude: 349.9104
  },
  {
    iataCode: 'RZR',
    icaoCode: 'OINR',
    name: 'Ramsar',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Ramsar',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 36.909908,
    longitude: 50.679589,
    altitude: -21.336
  },
  {
    iataCode: 'JJN',
    icaoCode: 'ZSQZ',
    name: 'Quanzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Quanzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 24.7964,
    longitude: 118.59,
    altitude: 0.0
  },
  {
    iataCode: 'AYK',
    icaoCode: 'UAUR',
    name: 'Arkalyk',
    country: {code: 'KZ', name: 'Kazakhstan'},
    city: 'Arkalyk Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Almaty',
    utcOffset: 300,
    latitude: 50.2395,
    longitude: 66.941,
    altitude: 351.1296
  },
  {
    iataCode: 'JJU',
    icaoCode: 'BGJH',
    name: '',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Qaqortoq Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 60.7158,
    longitude: -46.0294,
    altitude: 0.0
  },
  {
    iataCode: 'AYP',
    icaoCode: 'SPHO',
    name: 'Ayacucho',
    country: {code: 'PE', name: 'Peru'},
    city: 'Coronel Fap Alfredo Mendivil Duarte',
    region: '',
    terminal: '',
    timezoneId: 'America/Lima',
    utcOffset: -300,
    latitude: -13.154819,
    longitude: -74.204417,
    altitude: 2717.9016
  },
  {
    iataCode: 'AYQ',
    icaoCode: 'YAYE',
    name: 'Ayers Rock',
    country: {code: 'AU', name: 'Australia'},
    city: 'Ayers Rock',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Darwin',
    utcOffset: 540,
    latitude: -25.186111,
    longitude: 130.975556,
    altitude: 495.6048
  },
  {
    iataCode: 'AYT',
    icaoCode: 'LTAI',
    name: 'Antalya',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Antalya',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 36.898731,
    longitude: 30.800461,
    altitude: 53.9496
  },
  {
    iataCode: 'JKH',
    icaoCode: 'LGHI',
    name: 'Chios',
    country: {code: 'GR', name: 'Greece'},
    city: 'Chios',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 38.343175,
    longitude: 26.140572,
    altitude: 4.572
  },
  {
    iataCode: 'JKG',
    icaoCode: 'ESGJ',
    name: 'Joenkoeping',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Jonkoping',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 57.757594,
    longitude: 14.068731,
    altitude: 225.8568
  },
  {
    iataCode: 'AZA',
    icaoCode: 'KIWA',
    name: 'Mesa',
    country: {code: 'US', name: 'United States'},
    city: 'Phoenix-mesa Gateway',
    region: '',
    terminal: '',
    timezoneId: 'America/Phoenix',
    utcOffset: -420,
    latitude: 33.307833,
    longitude: -111.655,
    altitude: 421.2336
  },
  {
    iataCode: 'JKL',
    icaoCode: 'LGKY',
    name: 'Kalymnos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Kalymnos Island',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 36.963333,
    longitude: 26.940556,
    altitude: 235.0008
  },
  {
    iataCode: 'AZD',
    icaoCode: 'OIYY',
    name: 'Yazd',
    country: {code: 'IR', name: 'Islamic Republic of Iran'},
    city: 'Yazd Shahid Sadooghi',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tehran',
    utcOffset: 240,
    latitude: 31.904908,
    longitude: 54.276503,
    altitude: 1235.6592
  },
  {
    iataCode: 'AZI',
    icaoCode: 'OMAD',
    name: 'Abu Dhabi',
    country: {code: 'AE', name: 'United Arab Emirates'},
    city: 'Bateen',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Dubai',
    utcOffset: 240,
    latitude: 24.428333,
    longitude: 54.458084,
    altitude: 4.8768
  },
  {
    iataCode: 'AZN',
    icaoCode: 'UTKA',
    name: 'Andizhan',
    country: {code: 'UZ', name: 'Uzbekistan'},
    city: 'Andizhan Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Tashkent',
    utcOffset: 300,
    latitude: 40.7277,
    longitude: 72.294,
    altitude: 461.772
  },
  {
    iataCode: 'AZO',
    icaoCode: 'KAZO',
    name: 'Kalamazoo',
    country: {code: 'US', name: 'United States'},
    city: 'Kalamazoo',
    region: '',
    terminal: '',
    timezoneId: 'America/Detroit',
    utcOffset: -300,
    latitude: 42.234875,
    longitude: -85.552058,
    altitude: 266.3952
  },
  {
    iataCode: 'AZR',
    icaoCode: 'DAUA',
    name: 'Adrar',
    country: {code: 'DZ', name: 'Algeria'},
    city: 'Touat Cheikh Sidi Mohamed Belkebir',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Algiers',
    utcOffset: 60,
    latitude: 27.837589,
    longitude: -0.186414,
    altitude: 280.1112
  },
  {
    iataCode: 'AZS',
    icaoCode: 'MDCY',
    name: 'Samana',
    country: {code: 'DO', name: 'Dominican Republic'},
    city: 'Samaná El Catey International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Santo_Domingo',
    utcOffset: -240,
    latitude: 19.267,
    longitude: -69.742,
    altitude: 9.144
  },
  {
    iataCode: 'JLD',
    icaoCode: 'ESML',
    name: 'Landskrona',
    country: {code: 'SE', name: 'Sweden'},
    city: 'Landskrona',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Stockholm',
    utcOffset: 60,
    latitude: 55.944444,
    longitude: 12.869444,
    altitude: 59.1312
  },
  {
    iataCode: 'JLN',
    icaoCode: 'KJLN',
    name: 'Joplin',
    country: {code: 'US', name: 'United States'},
    city: 'Joplin Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 37.151814,
    longitude: -94.498269,
    altitude: 299.0088
  },
  {
    iataCode: 'JLR',
    icaoCode: 'VAJB',
    name: 'Jabalpur',
    country: {code: 'IN', name: 'India'},
    city: 'Jabalpur',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kolkata',
    utcOffset: 300,
    latitude: 23.177817,
    longitude: 80.052047,
    altitude: 494.9952
  },
  {
    iataCode: 'JMK',
    icaoCode: 'LGMK',
    name: 'Mykonos',
    country: {code: 'GR', name: 'Greece'},
    city: 'Mikonos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.435128,
    longitude: 25.348103,
    altitude: 123.444
  },
  {
    iataCode: 'JMO',
    icaoCode: 'VNJS',
    name: 'Jomsom',
    country: {code: 'NP', name: 'Nepal'},
    city: 'Jomsom',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kathmandu',
    utcOffset: 300,
    latitude: 28.782222,
    longitude: 83.7225,
    altitude: 2682.24
  },
  {
    iataCode: 'JMS',
    icaoCode: 'KJMS',
    name: 'Jamestown',
    country: {code: 'US', name: 'United States'},
    city: 'Jamestown Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 46.9297,
    longitude: -98.6782,
    altitude: 456.5904
  },
  {
    iataCode: 'JMU',
    icaoCode: 'ZYJM',
    name: 'Jiamusi',
    country: {code: 'CN', name: 'China'},
    city: 'Jiamusi Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Harbin',
    utcOffset: 480,
    latitude: 46.843394,
    longitude: 130.465389,
    altitude: 79.8576
  },
  {
    iataCode: 'JNB',
    icaoCode: 'FAJS',
    name: 'Johannesburg',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Johannesburg Intl',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -26.139166,
    longitude: 28.246,
    altitude: 1694.0784
  },
  {
    iataCode: 'JNN',
    icaoCode: 'BGNN',
    name: 'Nanortalik',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Nanortalik Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 60.14,
    longitude: -45.2317,
    altitude: 0.0
  },
  {
    iataCode: 'JNS',
    icaoCode: 'BGNS',
    name: 'Narsaq',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Narsaq Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 60.9167,
    longitude: -46.0586,
    altitude: 0.0
  },
  {
    iataCode: 'K03',
    icaoCode: 'PAWT',
    name: 'Fort Wainwright',
    country: {code: 'US', name: 'United States'},
    city: 'Wainwright As',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.613378,
    longitude: -159.86035,
    altitude: 10.668
  },
  {
    iataCode: 'JNU',
    icaoCode: 'PAJN',
    name: 'Juneau',
    country: {code: 'US', name: 'United States'},
    city: 'Juneau Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Juneau',
    utcOffset: -540,
    latitude: 58.354972,
    longitude: -134.576278,
    altitude: 6.4008
  },
  {
    iataCode: 'JNX',
    icaoCode: 'LGNX',
    name: 'Cyclades Islands',
    country: {code: 'GR', name: 'Greece'},
    city: 'Naxos',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Athens',
    utcOffset: 120,
    latitude: 37.080556,
    longitude: 25.368056,
    altitude: 3.048
  },
  {
    iataCode: 'JNZ',
    icaoCode: 'ZYJZ',
    name: 'Jinzhou',
    country: {code: 'CN', name: 'China'},
    city: 'Jinzhou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Shanghai',
    utcOffset: 480,
    latitude: 41.1014,
    longitude: 121.062,
    altitude: 0.0
  },
  {
    iataCode: 'JOE',
    icaoCode: 'EFJO',
    name: 'Joensuu',
    country: {code: 'FI', name: 'Finland'},
    city: 'Joensuu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Helsinki',
    utcOffset: 120,
    latitude: 62.662906,
    longitude: 29.60755,
    altitude: 121.3104
  },
  {
    iataCode: 'JOG',
    icaoCode: 'WARJ',
    name: 'Yogyakarta',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Adi Sutjipto',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: -7.788181,
    longitude: 110.431758,
    altitude: 106.68
  },
  {
    iataCode: 'JOI',
    icaoCode: 'SBJV',
    name: 'Joinville',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Lauro Carneiro De Loyola',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -26.224453,
    longitude: -48.797364,
    altitude: 4.572
  },
  {
    iataCode: 'JOL',
    icaoCode: 'RPMJ',
    name: '',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Jolo Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 6.05367,
    longitude: 121.011,
    altitude: 35.9664
  },
  {
    iataCode: 'JOK',
    icaoCode: 'UWKJ',
    name: 'Yoshkar-ola',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Yoshkar-ola Airport',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Moscow',
    utcOffset: 180,
    latitude: 56.7006,
    longitude: 47.9047,
    altitude: 106.0704
  },
  {
    iataCode: 'JON',
    icaoCode: 'PJON',
    name: 'Johnston Island',
    country: {code: '', name: 'Johnston Atoll'},
    city: 'Johnston Atoll',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Johnston',
    utcOffset: -600,
    latitude: 16.7286,
    longitude: -169.534,
    altitude: 2.1336
  },
  {
    iataCode: 'JOS',
    icaoCode: 'DNJO',
    name: 'Jos',
    country: {code: 'NG', name: 'Nigeria'},
    city: 'Yakubu Gowon',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Lagos',
    utcOffset: 60,
    latitude: 9.639828,
    longitude: 8.86905,
    altitude: 1289.9136
  },
  {
    iataCode: 'SAA',
    icaoCode: 'KSAA',
    name: 'Saratoga',
    country: {code: 'US', name: 'United States'},
    city: 'Shively Field Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 41.444859,
    longitude: -106.823526,
    altitude: 2137.2576
  },
  {
    iataCode: 'SAC',
    icaoCode: 'KSAC',
    name: 'Sacramento',
    country: {code: 'US', name: 'United States'},
    city: 'Sacramento Executive',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 38.512524,
    longitude: -121.49347,
    altitude: 7.3152
  },
  {
    iataCode: 'SAB',
    icaoCode: 'TNCS',
    name: 'Saba',
    country: {code: '', name: 'Netherlands Antilles'},
    city: 'Juancho E. Yrausquin',
    region: '',
    terminal: '',
    timezoneId: 'America/Kralendijk',
    utcOffset: -240,
    latitude: 17.645278,
    longitude: -63.220556,
    altitude: 18.288
  },
  {
    iataCode: 'SAF',
    icaoCode: 'KSAF',
    name: 'Santa Fe',
    country: {code: 'US', name: 'United States'},
    city: 'Santa Fe Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -420,
    latitude: 35.617108,
    longitude: -106.089422,
    altitude: 1934.8704
  },
  {
    iataCode: 'JPA',
    icaoCode: 'SBJP',
    name: 'Joao Pessoa',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Presidente Castro Pinto',
    region: '',
    terminal: '',
    timezoneId: 'America/Recife',
    utcOffset: -180,
    latitude: -7.148381,
    longitude: -34.950681,
    altitude: 65.532
  },
  {
    iataCode: 'SAH',
    icaoCode: 'OYSN',
    name: 'Sanaa',
    country: {code: 'YE', name: 'Yemen'},
    city: 'Sanaa Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Aden',
    utcOffset: 180,
    latitude: 15.476258,
    longitude: 44.219739,
    altitude: 2199.4368
  },
  {
    iataCode: 'SAK',
    icaoCode: 'BIKR',
    name: 'Sauoarkrokur',
    country: {code: 'IS', name: 'Iceland'},
    city: 'Sauoarkrokur Airport',
    region: '',
    terminal: '',
    timezoneId: 'Atlantic/Reykjavik',
    utcOffset: 0,
    latitude: 65.731667,
    longitude: -19.572778,
    altitude: 2.4384
  },
  {
    iataCode: 'SAL',
    icaoCode: 'MSLP',
    name: 'San Salvador',
    country: {code: 'SV', name: 'El Salvador'},
    city: 'El Salvador Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/El_Salvador',
    utcOffset: -360,
    latitude: 13.440947,
    longitude: -89.055728,
    altitude: 30.7848
  },
  {
    iataCode: 'SAN',
    icaoCode: 'KSAN',
    name: 'San Diego',
    country: {code: 'US', name: 'United States'},
    city: 'San Diego Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 32.733556,
    longitude: -117.189667,
    altitude: 5.1816
  },
  {
    iataCode: 'SAQ',
    icaoCode: 'MYAN',
    name: 'San Andros',
    country: {code: 'BS', name: 'Bahamas'},
    city: 'San Andros',
    region: '',
    terminal: '',
    timezoneId: 'America/Nassau',
    utcOffset: -300,
    latitude: 25.053814,
    longitude: -78.048997,
    altitude: 1.524
  },
  {
    iataCode: 'SAP',
    icaoCode: 'MHLM',
    name: 'San Pedro Sula',
    country: {code: 'HN', name: 'Honduras'},
    city: 'La Mesa Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Tegucigalpa',
    utcOffset: -360,
    latitude: 15.452639,
    longitude: -87.923556,
    altitude: 27.7368
  },
  {
    iataCode: 'SAT',
    icaoCode: 'KSAT',
    name: 'San Antonio',
    country: {code: 'US', name: 'United States'},
    city: 'San Antonio Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 29.533694,
    longitude: -98.469778,
    altitude: 246.5832
  },
  {
    iataCode: 'SAW',
    icaoCode: 'LTFJ',
    name: 'Istanbul',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Sabiha Gokcen',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 40.898553,
    longitude: 29.309219,
    altitude: 95.0976
  },
  {
    iataCode: 'SAV',
    icaoCode: 'KSAV',
    name: 'Savannah',
    country: {code: 'US', name: 'United States'},
    city: 'Savannah Hilton Head Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.127583,
    longitude: -81.202139,
    altitude: 15.5448
  },
  {
    iataCode: 'SAY',
    icaoCode: 'LIQS',
    name: 'Siena',
    country: {code: 'IT', name: 'Italy'},
    city: 'Ampugnano',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Rome',
    utcOffset: 60,
    latitude: 43.256286,
    longitude: 11.255036,
    altitude: 193.2432
  },
  {
    iataCode: 'JPR',
    icaoCode: 'SWJI',
    name: 'Ji-paraná',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Ji-paraná Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Porto_Velho',
    utcOffset: -240,
    latitude: -10.8708,
    longitude: -61.8465,
    altitude: 182.2704
  },
  {
    iataCode: 'SBA',
    icaoCode: 'KSBA',
    name: 'Santa Barbara',
    country: {code: 'US', name: 'United States'},
    city: 'Santa Barbara Muni',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.426211,
    longitude: -119.840372,
    altitude: 3.048
  },
  {
    iataCode: 'SBD',
    icaoCode: 'KSBD',
    name: 'San Bernardino',
    country: {code: 'US', name: 'United States'},
    city: 'San Bernardino International Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 34.095352,
    longitude: -117.234872,
    altitude: 353.2632
  },
  {
    iataCode: 'SBH',
    icaoCode: 'TFFJ',
    name: 'Gustavia',
    country: {code: 'FR', name: 'France'},
    city: 'Saint Barthelemy',
    region: '',
    terminal: '',
    timezoneId: 'America/St_Barthelemy',
    utcOffset: -240,
    latitude: 17.9023,
    longitude: -62.8324,
    altitude: 15.24
  },
  {
    iataCode: 'SBG',
    icaoCode: 'WITB',
    name: 'Sabang',
    country: {code: 'ID', name: 'Indonesia'},
    city: 'Maimun Saleh',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Jakarta',
    utcOffset: 420,
    latitude: 5.874131,
    longitude: 95.339672,
    altitude: 119.7864
  },
  {
    iataCode: 'JQA',
    icaoCode: 'BGUQ',
    name: 'Uummannaq',
    country: {code: 'GL', name: 'Greenland'},
    city: 'Qaarsut Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Godthab',
    utcOffset: -180,
    latitude: 70.7342,
    longitude: -52.6962,
    altitude: 88.0872
  },
  {
    iataCode: 'SBK',
    icaoCode: 'LFRT',
    name: 'St.-brieuc Armor',
    country: {code: 'FR', name: 'France'},
    city: 'Armor',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Paris',
    utcOffset: 60,
    latitude: 48.537777,
    longitude: -2.854445,
    altitude: 138.0744
  },
  {
    iataCode: 'SBN',
    icaoCode: 'KSBN',
    name: 'South Bend',
    country: {code: 'US', name: 'United States'},
    city: 'South Bend Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/Indiana/Indianapolis',
    utcOffset: -300,
    latitude: 41.708661,
    longitude: -86.31725,
    altitude: 243.5352
  },
  {
    iataCode: 'JQE',
    icaoCode: 'MPJE',
    name: 'Jaqué',
    country: {code: 'PA', name: 'Panama'},
    city: 'Jaqué Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Panama',
    utcOffset: -300,
    latitude: 7.51778,
    longitude: -78.1572,
    altitude: 8.8392
  },
  {
    iataCode: 'SBP',
    icaoCode: 'KSBP',
    name: 'San Luis Obispo',
    country: {code: 'US', name: 'United States'},
    city: 'San Luis County Regional Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 35.2368,
    longitude: -120.642,
    altitude: 64.6176
  },
  {
    iataCode: 'SBO',
    icaoCode: 'KSBO',
    name: 'Santa Barbara',
    country: {code: 'US', name: 'United States'},
    city: 'Emanuel Co',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 32.609139,
    longitude: -82.369944,
    altitude: 99.6696
  },
  {
    iataCode: 'SBR',
    icaoCode: 'YSII',
    name: 'Saibai Island',
    country: {code: 'AU', name: 'Australia'},
    city: 'Saibai Island Airport',
    region: '',
    terminal: '',
    timezoneId: 'Australia/Brisbane',
    utcOffset: 600,
    latitude: -9.37833,
    longitude: 142.625,
    altitude: 0.0
  },
  {
    iataCode: 'BAB',
    icaoCode: 'KBAB',
    name: 'Marysville',
    country: {code: 'US', name: 'United States'},
    city: 'Beale Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 39.136089,
    longitude: -121.436567,
    altitude: 34.4424
  },
  {
    iataCode: 'BAD',
    icaoCode: 'KBAD',
    name: 'Shreveport',
    country: {code: 'US', name: 'United States'},
    city: 'Barksdale Afb',
    region: '',
    terminal: '',
    timezoneId: 'America/Chicago',
    utcOffset: -360,
    latitude: 32.50182,
    longitude: -93.662674,
    altitude: 50.5968
  },
  {
    iataCode: 'SBS',
    icaoCode: 'KSBS',
    name: 'Steamboat Springs',
    country: {code: 'US', name: 'United States'},
    city: 'Steamboat Springs Airport-bob Adams Field',
    region: '',
    terminal: '',
    timezoneId: 'America/Denver',
    utcOffset: -360,
    latitude: 40.51625,
    longitude: -106.866306,
    altitude: 2097.6336
  },
  {
    iataCode: 'SBU',
    icaoCode: 'FASB',
    name: 'Springbok',
    country: {code: 'ZA', name: 'South Africa'},
    city: 'Springbok',
    region: '',
    terminal: '',
    timezoneId: 'Africa/Johannesburg',
    utcOffset: 120,
    latitude: -29.689333,
    longitude: 17.939611,
    altitude: 819.912
  },
  {
    iataCode: 'BAG',
    icaoCode: 'RPUB',
    name: 'Baguio',
    country: {code: 'PH', name: 'Philippines'},
    city: 'Baguio',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Manila',
    utcOffset: 480,
    latitude: 16.375103,
    longitude: 120.619636,
    altitude: 1295.7048
  },
  {
    iataCode: 'BAH',
    icaoCode: 'OBBI',
    name: 'Bahrain',
    country: {code: 'BH', name: 'Bahrain'},
    city: 'Bahrain Intl',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Bahrain',
    utcOffset: 180,
    latitude: 26.270834,
    longitude: 50.63361,
    altitude: 1.8288
  },
  {
    iataCode: 'SBW',
    icaoCode: 'WBGS',
    name: 'Sibu',
    country: {code: 'MY', name: 'Malaysia'},
    city: 'Sibu',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Kuching',
    utcOffset: 480,
    latitude: 2.261603,
    longitude: 111.985322,
    altitude: 37.1856
  },
  {
    iataCode: 'SBZ',
    icaoCode: 'LRSB',
    name: 'Sibiu',
    country: {code: 'RO', name: 'Romania'},
    city: 'Sibiu',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 45.785597,
    longitude: 24.091342,
    altitude: 455.9808
  },
  {
    iataCode: 'SBY',
    icaoCode: 'KSBY',
    name: 'Salisbury',
    country: {code: 'US', name: 'United States'},
    city: 'Salisbury Ocean City Wicomico Rgnl',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 38.340525,
    longitude: -75.510289,
    altitude: 15.8496
  },
  {
    iataCode: 'BAL',
    icaoCode: 'LTCJ',
    name: 'Batman',
    country: {code: 'TR', name: 'Turkey'},
    city: 'Batman',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Istanbul',
    utcOffset: 120,
    latitude: 37.928969,
    longitude: 41.116583,
    altitude: 555.3456
  },
  {
    iataCode: 'BAQ',
    icaoCode: 'SKBQ',
    name: 'Barranquilla',
    country: {code: 'CO', name: 'Colombia'},
    city: 'Ernesto Cortissoz',
    region: '',
    terminal: '',
    timezoneId: 'America/Bogota',
    utcOffset: -300,
    latitude: 10.889589,
    longitude: -74.780819,
    altitude: 29.8704
  },
  {
    iataCode: 'SCC',
    icaoCode: 'PASC',
    name: 'Deadhorse',
    country: {code: 'US', name: 'United States'},
    city: 'Deadhorse',
    region: '',
    terminal: '',
    timezoneId: 'America/Anchorage',
    utcOffset: -540,
    latitude: 70.19475,
    longitude: -148.465167,
    altitude: 19.5072
  },
  {
    iataCode: 'BAS',
    icaoCode: 'AGGE',
    name: 'Ballalae',
    country: {code: 'SB', name: 'Solomon Islands'},
    city: 'Ballalae Airport',
    region: '',
    terminal: '',
    timezoneId: 'Pacific/Guadalcanal',
    utcOffset: 660,
    latitude: -6.967,
    longitude: 155.883,
    altitude: 1.524
  },
  {
    iataCode: 'SCE',
    icaoCode: 'KUNV',
    name: 'State College Pennsylvania',
    country: {code: 'US', name: 'United States'},
    city: 'University Park Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.849278,
    longitude: -77.848694,
    altitude: 377.6472
  },
  {
    iataCode: 'BAU',
    icaoCode: 'SBBU',
    name: 'Bauru',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Bauru',
    region: '',
    terminal: '',
    timezoneId: 'America/Sao_Paulo',
    utcOffset: -180,
    latitude: -22.345042,
    longitude: -49.0538,
    altitude: 617.22
  },
  {
    iataCode: 'BAV',
    icaoCode: 'ZBOW',
    name: 'Baotou',
    country: {code: 'CN', name: 'China'},
    city: 'Baotou Airport',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Chongqing',
    utcOffset: 480,
    latitude: 40.56,
    longitude: 109.997,
    altitude: 1012.2408
  },
  {
    iataCode: 'JRA',
    icaoCode: 'KJRA',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'West 30th St. Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.7545,
    longitude: -74.0071,
    altitude: 2.1336
  },
  {
    iataCode: 'BAX',
    icaoCode: 'UNBB',
    name: 'Barnaul',
    country: {code: 'RU', name: 'Russian Federation'},
    city: 'Barnaul',
    region: '',
    terminal: '',
    timezoneId: 'Asia/Omsk',
    utcOffset: 360,
    latitude: 53.363775,
    longitude: 83.538533,
    altitude: 255.4224
  },
  {
    iataCode: 'SCH',
    icaoCode: 'KSCH',
    name: 'Scotia Ny',
    country: {code: 'US', name: 'United States'},
    city: 'Stratton Angb - Schenectady County Airpor',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 42.852456,
    longitude: -73.928867,
    altitude: 115.2144
  },
  {
    iataCode: 'BAY',
    icaoCode: 'LRBM',
    name: 'Baia Mare',
    country: {code: 'RO', name: 'Romania'},
    city: 'Tautii Magheraus',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Bucharest',
    utcOffset: 120,
    latitude: 47.658389,
    longitude: 23.470022,
    altitude: 184.404
  },
  {
    iataCode: 'SCK',
    icaoCode: 'KSCK',
    name: 'Stockton',
    country: {code: 'US', name: 'United States'},
    city: 'Stockton Metropolitan',
    region: '',
    terminal: '',
    timezoneId: 'America/Los_Angeles',
    utcOffset: -480,
    latitude: 37.894167,
    longitude: -121.238306,
    altitude: 10.0584
  },
  {
    iataCode: 'BAZ',
    icaoCode: 'SWBC',
    name: 'Barcelos',
    country: {code: 'BR', name: 'Brazil'},
    city: 'Barcelos Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Manaus',
    utcOffset: -240,
    latitude: -0.975,
    longitude: -62.923889,
    altitude: 152.4
  },
  {
    iataCode: 'JRB',
    icaoCode: 'KJRB',
    name: 'New York',
    country: {code: 'US', name: 'United States'},
    city: 'Wall Street Heliport',
    region: '',
    terminal: '',
    timezoneId: 'America/New_York',
    utcOffset: -300,
    latitude: 40.701214,
    longitude: -74.009028,
    altitude: 2.1336
  },
  {
    iataCode: 'SCM',
    icaoCode: 'PACM',
    name: 'Scammon Bay',
    country: {code: 'US', name: 'United States'},
    city: 'Scammon Bay Airport',
    region: '',
    terminal: '',
    timezoneId: 'America/Nome',
    utcOffset: -540,
    latitude: 61.845278,
    longitude: -165.571389,
    altitude: 4.2672
  },
  {
    iataCode: 'SCL',
    icaoCode: 'SCEL',
    name: 'Santiago',
    country: {code: 'CL', name: 'Chile'},
    city: 'Arturo Merino Benitez Intl',
    region: '',
    terminal: '',
    timezoneId: 'America/Santiago',
    utcOffset: -240,
    latitude: -33.392975,
    longitude: -70.785803,
    altitude: 473.964
  },
  {
    iataCode: 'SCN',
    icaoCode: 'EDDR',
    name: 'Saarbruecken',
    country: {code: 'DE', name: 'Germany'},
    city: 'Saarbrucken',
    region: '',
    terminal: '',
    timezoneId: 'Europe/Berlin',
    utcOffset: 60,
    latitude: 49.214553,
    longitude: 7.109508,
    altitude: 322.4784
  }
];

export const filteredAirports = airports.filter((a) => AIRPORTS.includes(a.iataCode));
