import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './atd-form-schema';
import DatetimePicker from '../../../../../common/date-time-picker/DatetimePicker';
import {Grid, Stack, TextField, Typography} from '@mui/material';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import FormActions from '../resources/form-actions/FormActions';
import MVTConfirmation from '../resources/mvt-confirmation/MVTConfirmation';
import React, {useState} from 'react';
import {useCreateAtdMutation} from '../../../../../../api';
import {ROTATION_SHAPE} from '../../../../common/rotation-shapes/rotation-shapes';
import RTKQueryFeedback from '../../../../../common/RTKQueryFeedback';
import {
  formatDate,
  formatTime,
  nowTz,
  nowTzZeroized
} from '../../../../../../utils/datetime/datetime-utils';
import {actions} from '../../../../../../store/valtio';
import {determineReliableDatetime} from '../../../../../../utils/rotation/rotation-utils';

const ATDForm = ({rotation, onClose}) => {
  const [mvtBody, setMvtBody] = useState(null);
  const [mvtWasSent, setMvtWasSent] = useState(false);
  const [sendMvt, {isLoading, error}] = useCreateAtdMutation();
  const {actualTime, takeOff} = rotation.outbound.departure;
  const {economyPaxNumber, executivePaxNumber} = rotation.outbound.flightDetails;
  const paxCount = economyPaxNumber + executivePaxNumber;
  const {handleSubmit, control, trigger} = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    context:{
      reliableInbound: rotation?.inbound?.arrival ? determineReliableDatetime(rotation?.inbound?.arrival) : null
    },
    defaultValues: {
      actualTime: actualTime || nowTzZeroized(),
      takeOff: takeOff || nowTzZeroized(),
      flightDuration: formatDate(nowTz()).concat(' 00:00:00'),
      boardedPax: paxCount
    }
  });
  const submitHandler = handleSubmit((values) => {
    const flightDuration = formatTime(values.flightDuration);
    sendMvt({
      params: {preview: mvtBody ? 0 : 1},
      body: {rotationHash: rotation.hash, ...values, flightDuration}
    }).then(({data: {body}}) => {
      if (!mvtBody) {
        setMvtBody(body);
        setMvtWasSent(false);
      } else {
        setMvtBody(null);
        actions.rotations.setNotifiedRotation({
          ...rotation,
          outbound: {
            ...rotation.outbound,
            departure: {
              ...rotation.outbound.departure,
              actualTime: values.actualTime,
              takeOff: values.takeOff,
              demarcationTime: values.takeOff
            }
          }
        });
        setMvtWasSent(true);
        onClose();
      }
    });
  });

  if (mvtBody) {
    return (
      <>
        <MVTConfirmation message={mvtBody} onSubmit={submitHandler} />
        <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent} />
      </>
    );
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'actualTime'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                onChange={e =>  {
                  field.onChange(e);
                  trigger('takeOff').then();
                }}
                label={t`ATD`}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'takeOff'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                onChange={e =>  {
                  field.onChange(e);
                  trigger('actualTime').then();
                }}
                label={t`TAKE OFF`}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Controller
            name={'flightDuration'}
            control={control}
            render={({field, fieldState: {error}}) => (
              <DatetimePicker
                {...field}
                label={t`FLIGHT TIME`}
                size={'small'}
                openTo={'hours'}
                error={Boolean(error)}
                helperText={error?.message}
                wheelTimePickerOnMobile
                variant={'TIME_ONLY'}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Stack>
            <Typography variant={'caption'} fontWeight={'bold'} px={1} color={'secondary.main'}>
              {t`PAX`}
            </Typography>
            <Controller
              name={'boardedPax'}
              control={control}
              render={({field, fieldState: {error}}) => (
                <TextField
                  {...field}
                  size={'small'}
                  type={'number'}
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
      <FormActions btnText={t`Update`} onClick={submitHandler} onClose={onClose} />
      <RTKQueryFeedback isLoading={isLoading} error={error} isSuccess={mvtWasSent}/>
    </Stack>
  );
};

ATDForm.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  onClose: PropTypes.func
};

ATDForm.defaultProps = {
  onClose: () => null
};

export default ATDForm;
