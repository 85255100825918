import {Grid, Skeleton, Stack} from '@mui/material';

export const TurnaroundTasksSkeleton = () => {
  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop={4}>
        <Grid xs={6} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={6} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
        <Grid xs={3} item>
          <Skeleton variant='rectangular' width={'100%'} height={66} />
        </Grid>
      </Grid>
    </Stack>
  );
};

TurnaroundTasksSkeleton.propTypes = {};

TurnaroundTasksSkeleton.defaultProps = {
  tasks: []
};

export default TurnaroundTasksSkeleton;
