import PropTypes from 'prop-types';
import {FLIGHT_DIRECTIONS} from '../../../../utils/rotation/rotation-utils';

export const FLIGHT_SHAPE = PropTypes.shape({
  flightId: PropTypes.string,
  arrival: PropTypes.shape({
    scheduledTime: PropTypes.string,
    estimatedTime: PropTypes.string,
    actualTime: PropTypes.string,
    touchDown: PropTypes.string,
    demarcationTime: PropTypes.string,
    delays: PropTypes.array
  }),
  departure: PropTypes.shape({
    scheduledTime: PropTypes.string,
    estimatedTime: PropTypes.string,
    actualTime: PropTypes.string,
    takeOff: PropTypes.string,
    demarcationTime: PropTypes.string,
    delays: PropTypes.array
  })
});

export const ROTATION_SHAPE = PropTypes.shape({
  inbound: FLIGHT_SHAPE,
  outbound: FLIGHT_SHAPE
});

export const FLIGHT_DIRECTION = PropTypes.oneOf(Object.values(FLIGHT_DIRECTIONS));
