import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Box, Card, Checkbox, Stack, Typography} from '@mui/material';
import {formatTime} from '../../../../../../../utils/datetime/datetime-utils';

export const TurnaroundActionToggleBtnWithCheckBox = ({
  label,
  time,
  listItemsLabel,
  backgroundColor,
  checked,
  onCheckBoxChange
}) => {
  const color = !backgroundColor ? 'secondary.dark' : 'primary.contrastText';
  return (
    <Card sx={styles.card(backgroundColor)} elevation={0}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <Typography sx={styles.label} color={color}>
            {label}
          </Typography>
          <Typography sx={styles.time} color={color}>
            {time ? formatTime(time, true) : '-'}
            {listItemsLabel}
          </Typography>
        </Stack>
        <Stack direction={'row'} display={'flex'}>
          <Box>
            <Checkbox defaultChecked={checked} onChange={onCheckBoxChange} />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

const styles = {
  card: (backgroundColor) => ({
    backgroundColor: backgroundColor || 'background.odd',
    border: 'unset',
    paddingX: 1.5,
    paddingY: 0.5,
    paddingRight: 1,
    cursor: 'pointer'
  }),
  label: {
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    fontSize: '0.875em',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  time: {
    fontSize: 'typography.fontSize'
  },
  delayedDuration: {
    fontSize: 'typography.fontSize'
  },
  editToggle: {
    cursor: 'pointer',
    color: '#00285780',
    fontSize: '0.875rem',
    fontFamily: 'NeoSansProRegular'
  }
};

TurnaroundActionToggleBtnWithCheckBox.propTypes = {
  label: PropTypes.string,
  time: PropTypes.string,
  listItemsLabel: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  completed: PropTypes.bool,
  onCheckBoxChange: PropTypes.func
};

TurnaroundActionToggleBtnWithCheckBox.defaultProps = {
  label: t`DOOR OPEN`,
  time: '10:00',
  listItemsLabel: null,
  color: 'secondary.dark',
  backgroundColor: null,
  completed: false,
  onCheckBoxChange: () => null
};

export default TurnaroundActionToggleBtnWithCheckBox;
