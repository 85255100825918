import PropTypes from 'prop-types';
import useRotationTurnaroundRecordAnalyzer from '../../../../../../rotation-hooks/useRotationTurnaroundRecordAnalyzer';
import {TurnaroundActionToggleListItem} from '../../common/TurnaroundActionToggleListItem';
import {RECORD_TASK_SHAPE} from '../../common/record-shapes/record-shapes';

export const TurnaroundActionTask = ({
  record,
  selectMode,
  onCheckBoxChange,
  readOnly,
  onRemoveAction,
  onStartAction,
  onFinishAction,
  onCancelStartAction,
  onCancelFinishAction,
  onSetShowEditDialog
}) => {
  const {start, timer, stop} = useRotationTurnaroundRecordAnalyzer(record);

  const {
    action: {description}
  } = record;

  return (
    <>
      <TurnaroundActionToggleListItem
        label={description}
        start={start}
        timer={timer}
        stop={stop}
        onEdit={(_, e) => {
          onSetShowEditDialog(true);
          e.stopPropagation();
        }}
        onClickStart={() =>
          !readOnly &&
          (!start.finished && record.action.applicable
            ? onStartAction()
            : start.finished && !stop.finished && onCancelStartAction('start'))
        }
        onClickStop={() =>
          !readOnly &&
          (start.finished && record.action.applicable && !stop.finished
            ? onFinishAction()
            : start.finished && stop.finished && onCancelFinishAction('finish'))
        }
        onRemoveAction={onRemoveAction}
        isApplicable={record.action.applicable}
        selectMode={selectMode}
        checked={!record.action.applicable}
        onCheckBoxChange={onCheckBoxChange}
        isMandatory={record.action.mandatory}
        manuallyAdded={record.action.manuallyAdded}
      />
      {/*{showConfirmationDialog && (*/}
      {/*  <ConfirmDialog*/}
      {/*    variant={'success'}*/}
      {/*    title={t`Confirmation Unselect`}*/}
      {/*    onConfirm={() => (showConfirmationDialog === 'start' ? onCancelStartAction() : onCancelFinishAction())}*/}
      {/*    onCancel={() => setShowConfirmationDialog(false)}*/}
      {/*  >*/}
      {/*    {t`Are you sure you want to undo this action?`}*/}
      {/*  </ConfirmDialog>*/}
      {/*)}*/}
      {/*<AuthorizationGuard userHasSomeOf={[ROLES.SUPERVISOR, ROLES.ADMIN]}>*/}
      {/*  <Dialog open={showEditDialog}>*/}
      {/*    <TurnaroundEditRecordForm record={record} onClose={() => setShowEditDialog(false)} />*/}
      {/*  </Dialog>*/}
      {/*</AuthorizationGuard>*/}
    </>
  );
};

TurnaroundActionTask.propTypes = {
  record: RECORD_TASK_SHAPE,
  readOnly: PropTypes.bool,
  isApplicable: PropTypes.bool,
  selectIsApplicable: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onRemoveAction: PropTypes.func.isRequired,
  onStartAction: PropTypes.func.isRequired,
  onFinishAction: PropTypes.func.isRequired,
  onCancelStartAction: PropTypes.func.isRequired,
  onCancelFinishAction: PropTypes.func.isRequired
};

TurnaroundActionTask.defaultProps = {
  readOnly: false,
  isApplicable: true,
  selectIsApplicable: false,
  onCheckBoxChange: () => null
};

export default TurnaroundActionTask;
