import PropTypes from 'prop-types';
import {
    Card,
    CardActions,
    CardContent, CardHeader,
    Checkbox,
    FormControlLabel,
    IconButton,
} from '@mui/material';
import {FileOpen} from '@mui/icons-material';
import React from "react";

export const TurnaroundCheckListDialogTask = ({title, label, isChecked, file, onClick, disabled}) => {
  return (
      <Card>
          <CardContent>
              <CardHeader
                title={title}
              />
            <FormControlLabel
                label={label}
                control={<Checkbox checked={isChecked} onChange={onClick} sx={{alignSelf:'start', paddingTop: 0}}/>}
                disabled={disabled}
            />
          </CardContent>
        <CardActions sx={{justifyContent: 'right'}}>
          <IconButton size={'large'} onClick={() => window.open(file, "_blank")}>
            <FileOpen/>
          </IconButton>
        </CardActions>
      </Card>
  );
};

TurnaroundCheckListDialogTask.propTypes = {
  title: PropTypes.node,
  label: PropTypes.node,
  isChecked: PropTypes.bool,
  file: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

TurnaroundCheckListDialogTask.defaultProps = {
  title: '',
  label: PropTypes.node,
  isChecked: true,
  file: null,
  onClick: () => null,
  disabled: true
};

export default TurnaroundCheckListDialogTask;
