import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './utils/reportWebVitals';
import AzureAuthProvider from './utils/providers/AzureAuthProvider';
import ReduxProvider from './store/redux/Provider';
import ThemeProvider from './theme/ThemeProvider';
import SnackbarProvider from './utils/providers/SnackbarProvider';
import App from './App';

ReactDOM.render(
  <AzureAuthProvider>
    <ReduxProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ReduxProvider>
  </AzureAuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
