import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DialogActions, DialogContent, Grid, Stack} from '@mui/material';
import DatetimePicker from '../../../../../../common/date-time-picker/DatetimePicker';
import FormActions from '../../../rotation-view-flight-forms/resources/form-actions/FormActions';
import {schema as taskSchema} from '../turnaround-records-forms/form-update-record/update-task-record-schema';
import {RECORD_CHECKLIST_SHAPE, RECORD_TASK_SHAPE, RECORD_VERIFICATION_SHAPE} from './record-shapes/record-shapes';
import {schema as verificationSchema} from '../turnaround-records-forms/form-update-record/update-verification-record-schema';

const TurnaroundEditRecordForm = ({record, onClose, checkAll, onUpdateRecord}) => {
  const {handleSubmit, control, trigger} = useForm({
    resolver: yupResolver(record.action.type === 'TASK' ? taskSchema : verificationSchema),
    mode: 'onChange',
    defaultValues: {
      startDate: record.startDate || null,
      finishDate: record.finishDate || null
    }
  });

  const submitHandler = handleSubmit((values) => {
    record.action.type === 'CHECKLIST' && checkAll();
    onUpdateRecord({
      params: {recordId: record.recordId},
      body: values
    });
  });

  return (
    <>
      <DialogContent>
        <Stack spacing={1}>
          <Grid container spacing={2}>
            {record.action.type === 'TASK' && (
              <Grid xs={12} sm={6} item>
                <Controller
                  name={'startDate'}
                  control={control}
                  render={({field, fieldState: {error}}) => (
                    <DatetimePicker
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger('startDate').then();
                      }}
                      label={t`START`}
                      size={'small'}
                      openTo={'hours'}
                      error={Boolean(error)}
                      helperText={error?.message}
                      wheelTimePickerOnMobile
                    />
                  )}
                />
              </Grid>
            )}
            <Grid sm={record.action.type === 'TASK' ? 6 : 12} xs={12} item>
              <Controller
                name={'finishDate'}
                control={control}
                render={({field, fieldState: {error}}) => (
                  <DatetimePicker
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      trigger('finishDate').then();
                    }}
                    label={t`FINISH`}
                    size={'small'}
                    openTo={'hours'}
                    error={Boolean(error)}
                    helperText={error?.message}
                    wheelTimePickerOnMobile
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormActions btnText={t`Edit`} onClick={submitHandler} onClose={onClose} />
      </DialogActions>
    </>
  );
};

TurnaroundEditRecordForm.propTypes = {
  record: PropTypes.oneOfType([RECORD_VERIFICATION_SHAPE, RECORD_CHECKLIST_SHAPE, RECORD_TASK_SHAPE]),
  onClose: PropTypes.func,
  checkAll: PropTypes.func,
};

TurnaroundEditRecordForm.defaultProps = {
  onClose: () => null,
  checkAll: () => null
};

export default TurnaroundEditRecordForm;
