import {t} from 'ttag';
import React, {useEffect} from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import PropTypes from 'prop-types';
import RoleForm from './RoleForm';
import {useGetRoleQuery, useUpdateRoleMutation} from '../../../../api';
import {roleValidationSchema} from './helpers';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {yupResolver} from '@hookform/resolvers/yup';


const EditRoleDialog = ({role: {roleId}, open, onClose}) => {
  const {data: role, error: rolesError, isLoading: rolesIsLoading} = useGetRoleQuery(roleId);
  const [editRole, {data, error, isLoading}] = useUpdateRoleMutation();

  const methods = useForm({
    resolver: yupResolver(roleValidationSchema),
    defaultValues: role
  });

  useEffect(() => {
    if (role) {
      methods.reset(role);
    }
  }, [role, methods]);

  const handleSubmit = methods.handleSubmit(values => {
    editRole({
      ...values,
      users: values.users.map((e) => e.employeeId)
    }).then((r) => !r.error && onClose());
  })

  return (
    <FormProvider {...methods}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Typography variant='h2' component='div'>
            {t`Update`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <RoleForm/>
        </DialogContent>
        <DialogActions>
          <Box p={1} px={0.4}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
          <Box p={1} px={0.4}>
            <Button variant='contained' onClick={handleSubmit}>
              {t`Save`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <RTKQueryFeedback
        error={error || rolesError}
        isLoading={isLoading || rolesIsLoading}
        data={data}
      />
    </FormProvider>
  );
};

EditRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EditRoleDialog.defaultProps = {
  open: true
};

export default EditRoleDialog;
