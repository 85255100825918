import {Stack} from '@mui/material';
import useRotationTimer from '../../components/rotations/rotation-hooks/useRotationTimer';
import {RotationListFiltersConnected} from '../../components/rotations/rotation-filters';
import {RotationViewDialogConnected} from '../../components/rotations/rotation-view';
import {RotationList} from '../../components/rotations/rotation-list/RotationList';
import {useRotationWS} from '../../components/rotations/rotation-hooks';
import useRotationUpdater from '../../components/rotations/rotation-hooks/useRotationUpdater';


const Rotations = () => {
  useRotationTimer();
  useRotationWS();
  useRotationUpdater();

  return (
    <Stack gap={2}>
      <RotationViewDialogConnected/>
      <RotationListFiltersConnected/>
      <RotationList/>
    </Stack>
  );
};

export default Rotations;
