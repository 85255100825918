import {useState} from 'react';
import PropTypes from 'prop-types';
import MVTConfirmationAlert from './MVTConfirmationAlert';
import MVTConfirmationDialog from './MVTConfirmationDialog';

const MVTConfirmation = ({message, onSubmit}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MVTConfirmationAlert onClick={() => setOpen(true)} />
      {open && <MVTConfirmationDialog message={message} onClose={() => setOpen(false)} onSubmit={onSubmit} />}
    </>
  );
};

MVTConfirmation.propTypes = {
  message: PropTypes.string,
  onSubmit: PropTypes.func
};

export default MVTConfirmation;
