import {Stack} from '@mui/material';
import {RotationViewFlightStationBriefing} from '../rotation-view-flight-station-briefing';
import RotationViewFlightSummary from '../rotation-view-flight-summary/RotationViewFlightSummary';
import {FLIGHT_DIRECTION, ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';

export const RotationViewFlight = ({rotation, flightDirection}) => {
  return (
    <Stack direction={'column'} spacing={2}>
      <RotationViewFlightSummary rotation={rotation} flightDirection={flightDirection} />
      <RotationViewFlightStationBriefing rotation={rotation} flightDirection={flightDirection} />
    </Stack>
  );
};

RotationViewFlight.propTypes = {
  rotation: ROTATION_SHAPE.isRequired,
  flightDirection: FLIGHT_DIRECTION.isRequired
};

export default RotationViewFlight;
