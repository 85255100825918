import {t} from 'ttag';
import {Stack} from '@mui/material';
import RotationTurnAroundTimer from '../../../common/rotation-turn-around-timer/RotationTurnAroundTimer';
// import useRotationTurnaroundAnalyzer from '../../../rotation-hooks/useRotationTurnaroundAnalyzer';
import {ROTATION_SHAPE} from '../../../common/rotation-shapes/rotation-shapes';
import {useGetRecordsQuery} from '../../../../../api';

export const RotationViewTurnaroundTimer = ({rotation}) => {
  // const turnaroundInterval = useRotationTurnaroundAnalyzer(rotation);
  const {data} = useGetRecordsQuery(rotation.hash);

  return (
    <Stack>
      {t`TURNAROUND`}
      <Stack direction={{xs: 'column', sm: 'row'}} justifyContent={'center'}>
        {/*{rotation.inbound && rotation.outbound && (*/}
        {/*  <RotationTurnAroundTimer*/}
        {/*    {...turnaroundInterval}*/}
        {/*    statusColor={turnaroundInterval.color}*/}
        {/*    showIcon={Boolean(turnaroundInterval.color)}*/}
        {/*  />*/}
        {/*)}*/}
        {data?.planTurnAroundTime && <RotationTurnAroundTimer statusColor={null} time={data?.planTurnAroundTime} />}
      </Stack>
    </Stack>
  );
};

RotationViewTurnaroundTimer.propTypes = {
  rotation: ROTATION_SHAPE.isRequired
};

export default RotationViewTurnaroundTimer;
