import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {AccessTimeFilled} from '@mui/icons-material';
import React from 'react';

export const RotationTurnAroundTimer = ({icon, statusColor, time, sign, showIcon}) => (
  <Box sx={styles.box(statusColor, showIcon)}>
    {showIcon && icon}
    <Box pt={0.5}>
      {sign}
      {time}
    </Box>
  </Box>
);

const styles = {
  box: (statusColor, showIcon) => ({
    justifyContent: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    color: statusColor && statusColor !== 'transparent'? 'common.white' : 'secondary',
    gap: 0.5,
    borderRadius: 10,
    minWidth: showIcon ? '94px' : '66px',
    backgroundColor: statusColor,
    padding: '2px 6px',
    fontFamily: statusColor && statusColor !== 'transparent' ? 'NeoSansProMedium' : 'NeoSansProRegular'
  })
};

RotationTurnAroundTimer.propTypes = {
  statusColor: PropTypes.string,
  time: PropTypes.string,
  icon: PropTypes.any,
  showIcon: PropTypes.bool,
  sign: PropTypes.string
};

RotationTurnAroundTimer.defaultProps = {
  statusColor: 'transparent',
  time: '',
  icon: <AccessTimeFilled />,
  showIcon: false,
  sign: ''
};

export default RotationTurnAroundTimer;
