import React from 'react';
import PropTypes from 'prop-types';
import {TableCell, TableRow} from '@mui/material';

export const FlightLoadRow = ({label, value}) => {
  return (
    <TableRow sx={styles.tableRow}>
      <TableCell sx={styles.tableCellHeader} component='th' scope='row'>
        {label}
      </TableCell>
      <TableCell sx={styles.tableCellRow} align='right'>
        {value}
      </TableCell>
    </TableRow>
  );
};

FlightLoadRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FlightLoadRow.defaultProps = {
  label: null,
  value: null
};

export const styles = {
  tableCellHeader: {
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: 'secondary.main',
    textTransform: 'uppercase',
    opacity: 1,
    borderBottom: 'none',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },
  tableRow: {
    borderRadius: '8px',
    '&:nth-of-type(odd)': {
      borderRadius: '8px',
      background: '#F4F5F8 0% 0% no-repeat padding-box'
    }
  },
  tableCellRow: {
    textAlign: 'right',
    fontWeight: 'normal',
    letterSpacing: 0,
    color: 'secondary.main',
    textTransform: 'uppercase',
    opacity: 1,
    borderBottom: 'none',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  }
};

export default FlightLoadRow;
