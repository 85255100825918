import React from 'react';
import {Grid} from '@mui/material';
import {LocalAirportSearch} from '../../common/local-airport-search';
import RotationPatternFilter from './RotationPatternFilter';
import RotationStateFilter from './RotationStateFilter';
import RotationOperationDayTabsFilter from './RotationOperationDayTabsFilter';
import {AuthorizationGuard} from '../../../pages/common/guards';
import {actions, useStore} from '../../../store/valtio';
import {ROLES} from '../../../utils/user/user-utils';
import {t} from 'ttag';

const {setAirport, setPattern, setState, setOperationDay} = actions.rotations.filters;

export const RotationListFiltersConnected = () => {
  const {rotations: {filters: {airport, pattern, state, operationDay}}} = useStore();

  return (
    <Grid container spacing={2} justifyContent={'flex-end'}>
      <AuthorizationGuard userHasSomeOf={[ROLES.ADMIN, ROLES.OBSERVER]}>
        <Grid item xs={12} sm={4} md={3}>
          <LocalAirportSearch value={airport} label={t`Airports`} onChange={setAirport} disableClearable />
        </Grid>
      </AuthorizationGuard>
      <Grid item xs={12} sm={4} md={3}>
        <RotationPatternFilter value={pattern} onChange={setPattern} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <RotationStateFilter value={state} onChange={setState} />
      </Grid>
      <Grid item xs={12}>
        <RotationOperationDayTabsFilter value={operationDay} onChange={setOperationDay} />
      </Grid>
    </Grid>
  );
};

export default RotationListFiltersConnected;
