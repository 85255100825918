import {useEffect} from 'react';
import {io} from 'socket.io-client';
import {actions} from '../../../store/valtio';
import {SOCKET_ENDPOINT} from '../../../config/settings';
import {datetimeRotationMapperFromUtc} from '../../../api/utils/rotation-mappers';

export const useRotationWS = () => {
  useEffect(() => {
    const socket = io(SOCKET_ENDPOINT, {
      reconnectionAttempts: 3,
      reconnectionDelay: 5000
    });

    socket.on('connect', () => {
      console.info(`Connected to socket server: ${SOCKET_ENDPOINT} #${socket.id}`);
    });

    socket.on('updatedRotation', (data) => {
      try {
        const {rotation} = JSON.parse(data) || {};
        const mappedRotation = datetimeRotationMapperFromUtc(rotation);
        console.info('socket server updated rotation notify:', mappedRotation);
        actions.rotations.setNotifiedRotation(mappedRotation);
      } catch (e) {
        console.error(e);
      }
    });
    socket.on('disconnect', (reason) => {
      console.info(`Disconnected from socket server: ${SOCKET_ENDPOINT} | Reason: ${reason}`);
    });

    return () => socket.disconnect();
  }, []);
};

export default useRotationWS;
