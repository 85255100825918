import React from 'react';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {t} from 'ttag';
import {useDeleteRoleMutation} from '../../../../api';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';

const DeleteRoleDialog = ({role, open, onClose}) => {
  const [deleteRole, {data, error, isLoading, isSuccess}] = useDeleteRoleMutation();

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
        isSuccess={isSuccess}
        successMessage={t`The item was successfully deleted!`}
      />
      <Dialog title={t`Delete`} variant={'error'} open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Typography variant='h2' component={'div'} color={'error'}>
            {t`Delete`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid spacing={3} container>
            <Grid xs={12} item>
              <Typography>{t`Do you really want to remove this item?`}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box mx={1}>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={() => deleteRole(role).then((r) => !r.error && onClose())}
            >
              {t`delete`}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant={'outlined'} onClick={onClose}>
              {t`Cancel`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

DeleteRoleDialog.defaultProps = {
  open: true
};

export default DeleteRoleDialog;
